// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import { Button } from "#src/common/system/Button";
import { MarkdownEditor } from "#src/common/system/Input/MarkdownEditor";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import bugBountyServices, {
  type IBugBountyDetails,
} from "#src/services/bugBounty";

interface IBugBountyDetailsPolicyProps {
  workspaceId: string;
  bugBountyAlias: string;
  bugBountyDetails: IBugBountyDetails;
  onRefresh: (callback?: (result: IBugBountyDetails) => void) => void;
}

const BugBountyDetailsPolicy = ({
  workspaceId,
  bugBountyAlias,
  bugBountyDetails,
  onRefresh,
}: IBugBountyDetailsPolicyProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.policy",
  });

  const [policy, setPolicy] = useState<string>(bugBountyDetails.metadata.en);

  const onSubmit = () => {
    bugBountyServices
      .update_program(workspaceId, bugBountyAlias, {
        alias: bugBountyDetails.alias,
        name: bugBountyDetails.org.name,
        type: bugBountyDetails.type,
        target: bugBountyDetails.target,
        metadata: { en: policy, vi: policy },
      })
      .then((response) => {
        if (response.success) {
          onRefresh((response) => {
            setPolicy(response.metadata.en);
          });
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updatePolicy.fail"),
        });
      });
  };

  const onRevert = () => {
    setPolicy(bugBountyDetails.metadata.en);
  };

  return (
    <section className="flex flex-col gap-6 pt-6">
      <div className="flex flex-col gap-4">
        <h2>{t("title")}</h2>
        <span className="text-hard-grey">{t("info")}</span>
      </div>
      <div className="w-3/4 flex flex-col gap-6">
        <MarkdownEditor
          className="font-regular-14-body bg-bright-blue h-[42rem]"
          value={policy}
          onChange={(e) => {
            setPolicy(e.currentTarget.value);
          }}
          defaultMode="preview"
        />
        <div className="flex justify-end gap-1">
          {bugBountyDetails.metadata.en !== policy ? (
            <Button variant="warning" onClick={onRevert}>
              {t("button.cancel")}
            </Button>
          ) : null}
          <Button
            variant="secondary"
            onClick={onSubmit}
            disabled={bugBountyDetails.metadata.en === policy}
          >
            {t("button.save")}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default BugBountyDetailsPolicy;
