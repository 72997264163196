import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { pricingTableData } from "./data";
import PricingTableRow from "./TableRow";

const DataLeakAddKeywordPricingTable = () => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "addKeyword.pricingTable.content",
  });

  return (
    <div className="w-full grid grid-cols-5">
      <PricingTableRow
        rowTitle={t("numberOfUser.label")}
        content={pricingTableData
          .map((item) => item.numberOfUser)
          .map((item) => {
            if (item === "unlimited") return t("numberOfUser.unlimited");
            return null;
          })}
      />
      <PricingTableRow
        rowTitle={t("typeOfKeyword.label")}
        content={pricingTableData
          .map((item) => item.typeOfKeyword)
          .map((item) => {
            if (item === "domain") return t("typeOfKeyword.domain");
            if (item === "unlimited") return t("typeOfKeyword.unlimited");
            return null;
          })}
      />
      <PricingTableRow
        rowTitle={t("amountOfResult.label")}
        content={pricingTableData.map((item) => item.amountOfResult)}
      />
      <PricingTableRow
        rowTitle={t("checkFalsePositive.label")}
        content={pricingTableData.map((item) =>
          item.checkFalsePositive ? (
            <CheckboxCircleLine
              key={`cell-checkFalsePositive-${item.planName}`}
              className="h-6 w-6 fill-warning-safe"
            />
          ) : (
            "-"
          )
        )}
      />
      <PricingTableRow
        rowTitle={t("optimisedKeyword.label")}
        content={pricingTableData
          .map((item) => item.optimisedKeyword)
          .map((item) => {
            if (item === "self") return t("optimisedKeyword.self");
            if (item === "supported")
              return (
                <Trans
                  t={t}
                  i18nKey={"optimisedKeyword.supported"}
                  components={{ b: <b className="contents" /> }}
                />
              );
          })}
      />
      <PricingTableRow
        rowTitle={t("checkValidResult.label")}
        content={pricingTableData
          .map((item) => item.checkValidResult)
          .map((item) => {
            if (item === "self") return t("checkValidResult.self");
            if (item === "supported")
              return (
                <Trans
                  t={t}
                  i18nKey={"checkValidResult.supported"}
                  components={{ b: <b className="contents" /> }}
                />
              );
          })}
      />
      <PricingTableRow
        rowTitle={t("periodicReport.label")}
        content={pricingTableData.map((item) =>
          item.periodicReport ? (
            <CheckboxCircleLine
              key={`cell-periodicReport-${item.planName}`}
              className="h-6 w-6 fill-warning-safe"
            />
          ) : (
            "-"
          )
        )}
      />
      <PricingTableRow
        rowTitle={t("providerMonitoring.label")}
        content={pricingTableData.map((item) =>
          item.providerMonitoring ? (
            <CheckboxCircleLine
              key={`cell-providerMonitoring-${item.planName}`}
              className="h-6 w-6 fill-warning-safe"
            />
          ) : (
            "-"
          )
        )}
      />
      <PricingTableRow
        rowTitle={t("wildcardSearch.label")}
        content={pricingTableData.map((item) =>
          item.wildcardSearch ? (
            <CheckboxCircleLine
              key={`cell-wildcardSearch-${item.planName}`}
              className="h-6 w-6 fill-warning-safe"
            />
          ) : (
            "-"
          )
        )}
      />
      <PricingTableRow
        rowTitle={t("realtimeNotification.label")}
        content={pricingTableData.map((item) =>
          item.realtimeNotification ? (
            <CheckboxCircleLine
              key={`cell-realtimeNotification-${item.planName}`}
              className="h-6 w-6 fill-warning-safe"
            />
          ) : (
            "-"
          )
        )}
      />
      <PricingTableRow
        rowTitle={t("exportResult.label")}
        content={pricingTableData.map((item) =>
          item.exportResult ? (
            <CheckboxCircleLine
              key={`cell-exportResult-${item.planName}`}
              className="h-6 w-6 fill-warning-safe"
            />
          ) : (
            "-"
          )
        )}
      />
    </div>
  );
};

export default DataLeakAddKeywordPricingTable;
