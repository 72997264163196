import { Tooltip } from "@lockerpm/design";
import { AlertCircle } from "@untitled-ui/icons-react";
import { TAILWIND_COLORS, toPascalCase } from "#src/utils/common";
import { TableV2 } from "#src/commonV2/Table";
import { IEndpointDevice } from "#src/services/endpoint/devices/types";
import { useTranslation } from "react-i18next";

type Props = {
  details: IEndpointDevice;
};

export const UsersSection = (props: Props) => {
  const { details } = props;
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  if (!details.users) return null;

  // --------------- RENDER ---------------

  return (
    <section className="w-full h-auto">
      {/* Title */}
      <div className="mb-4">
        <h3 className="text-lg font-semibold text-gray-v2-900 mb-1 normal-case">
          {t("overview.users.title")}
          <Tooltip
            color={TAILWIND_COLORS["gray-v2-900"]}
            title={
              <p className="text-xs text-white font-semibold text-center">
                {t("overview.users.desc")}
              </p>
            }
          >
            <span>
              <AlertCircle className="text-gray-v2-500 h-5 w-5 ml-2 translate-y-1" />
            </span>
          </Tooltip>
        </h3>
        <p className=" text-md font-semibold text-brand-v2-600">
          {details.users.length}{" "}
          {t("overview.users.users", { count: details.users.length })}
        </p>
      </div>
      {/* Title end */}

      {/* Table */}
      <TableV2<NonNullable<IEndpointDevice["users"]>[0]>
        data={details.users}
        getRowKey={(item) => item.uid}
        columns={[
          {
            title: "UUID",
            render: (item) => (
              <p className="text-sm text-gray-v2-600 font-normal">{item.uid}</p>
            ),
            gridTemplateValue: "1fr",
          },
          {
            title: "Username",
            render: (item) => (
              <p className="text-sm text-gray-v2-900 font-medium">
                {item.username}
              </p>
            ),
            gridTemplateValue: "2fr",
          },
          {
            title: t("overview.users.group"),
            render: (item) => (
              <p className="text-sm text-gray-v2-600 font-normal">
                {item.groupname || "---"}
              </p>
            ),
            gridTemplateValue: "2fr",
          },
          {
            // TODO: no data
            title: t("overview.users.created_at"),
            render: () => (
              <p className="text-sm text-gray-v2-600 font-normal">---</p>
            ),
            gridTemplateValue: "2fr",
          },
          {
            // TODO: no data
            title: t("overview.users.removed_at"),
            render: () => (
              <p className="text-sm text-gray-v2-600 font-normal">---</p>
            ),
            gridTemplateValue: "2fr",
          },
          {
            title: t("overview.users.user_type"),
            render: (item) => (
              <p className="text-sm text-gray-v2-600 font-normal">
                {toPascalCase(item.type)}
              </p>
            ),
            gridTemplateValue: "1fr",
          },
        ]}
      />
      {/* Table end */}
    </section>
  );
};
