import { CornerDownRight } from "@untitled-ui/icons-react";
import { Dispatch, SetStateAction, useState } from "react";
import { DateTime } from "luxon";
import { DatePickerV2 } from "./DatePicker";

interface IFilterByDateSelectOneDateProps {
  selectedDate: DateTime;
  setSelectedDate: Dispatch<SetStateAction<DateTime>>;
}

export const FilterByDateSelectOneDate = ({
  selectedDate,
  setSelectedDate,
}: IFilterByDateSelectOneDateProps) => {
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);

  return (
    <div className="flex items-center justify-between gap-2">
      <CornerDownRight className="h-6 w-6 min-w-[1.5rem] text-brand-v2" />
      <DatePickerV2
        open={openDatePicker}
        onChangeOpen={(value) => {
          setOpenDatePicker(value);
        }}
        currentValue={selectedDate}
        onApply={(value) => {
          setSelectedDate(value);
        }}
      />
    </div>
  );
};
