// Libraries
import { generatePath, json, Navigate, useLoaderData } from "react-router-dom";

// General
import { pathname } from "#src/config/pathname";
import store from "#src/store";

// Components
import LoadingState from "#src/common/system/LoadingState";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import workspaceServices from "#src/services/workspace";
import authServices from "#src/services/auth";
import {
  selectWorkspace,
  updateWorkspaceList,
} from "#src/store/slices/workspace";

interface LoaderType {
  redirectTo: string;
}

export const homePageLoader = async () => {
  const selectedWorkspace = store.getState().workspace.selectedWorkspace;

  if (selectedWorkspace) {
    return json<LoaderType>({
      redirectTo: generatePath(pathname.DOMAINS, {
        workspaceId: selectedWorkspace,
      }),
    });
  }
  // fetch workspace list, if it's empty, navigate to new workspace
  // TODO: if error handler is doing its work completely, we should remove this try catch
  try {
    const workspaceList = await workspaceServices
      .list_workspaces()
      .catch(apiErrorHandler);

    // Update workspaceList on Redux store
    store.dispatch(updateWorkspaceList(workspaceList));

    if (workspaceList && workspaceList.length >= 1) {
      store.dispatch(selectWorkspace(workspaceList[0].id));
      return json<LoaderType>({
        redirectTo: generatePath(pathname.DOMAINS, {
          workspaceId: workspaceList[0].id,
        }),
      });
    } else {
      return json<LoaderType>({ redirectTo: pathname.NEW_WORKSPACE });
    }
  } catch (error) {
    // TODO: change this error handling, maybe redirect to error page (401, ...)
    apiErrorHandler(error);
  }

  return json<LoaderType>({ redirectTo: "" });
};

/** A route used for rerouting */
const HomePage = () => {
  // Ref: discussion about loader's generic type https://github.com/remix-run/remix/pull/1254#issuecomment-1034475233
  // THIS IS A LIE: data is not typed as LoaderType, it should be a Response. It's just most likely to be LoaderType.
  const data = useLoaderData() as LoaderType;
  const authToken = authServices.access_token();

  // TODO: initialize language

  if (!authToken) {
    <Navigate to={pathname.LOGIN} />;
  }

  if (!data || !data.redirectTo) return <LoadingState />;

  return <Navigate to={data.redirectTo} />;
};

export default HomePage;
