// Libraries
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Resources
import { ReactComponent as CouponLine } from "#src/assets/images/icons/coupon-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// Components
import { Button } from "#src/common/system/Button";

// API-related
import type { IPricingInfo } from "#src/services/payment";
import { expandPlanDuration } from "#src/utils/apiHelper";

interface IPaymentFillFormSummaryProps {
  pricingInfo: IPricingInfo | null;
  onApplyPromocode: (value: string) => Promise<boolean>;
  onRemovePromocode: (removeId: string) => void;
}

const PaymentFillFormSummary = ({
  pricingInfo,
  onApplyPromocode,
  onRemovePromocode,
}: IPaymentFillFormSummaryProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.fillForm.summary",
  });

  const locale = i18next.language;

  const [couponInput, setCouponInput] = useState<string>("");
  const [errorPromo, setErrorPromo] = useState<boolean>(false);

  return (
    <div className="flex flex-col border border-primary rounded-md h-fit">
      <h2 className="px-12 py-6 bg-bright-blue rounded-md">{t("title")}</h2>
      <div className="px-12 py-6 flex flex-col gap-6">
        {pricingInfo
          ? pricingInfo.product_plans
              .map(expandPlanDuration)
              .filter(<T,>(p: T): p is Exclude<T, undefined> => p !== undefined)
              .map((plan) => (
                <Fragment key={plan.id}>
                  <div className="flex justify-between gap-3">
                    <span className="font-medium-16">{t("productName")}</span>
                    {pricingInfo ? (
                      <div className="flex gap-1">
                        <span className="font-bold-16 text-right">
                          {locale === "vi"
                            ? plan.product.name.vi
                            : plan.product.name.en}
                        </span>
                        -
                        <span className="font-bold-16 text-right">
                          {locale === "vi"
                            ? plan?.name?.vi
                            : plan?.name?.en}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="flex justify-between gap-3">
                    <span className="font-medium-16">{t("cycle")}</span>
                    {pricingInfo ? (
                      <span className="font-bold-16 text-right">
                        {plan.getSpan()}
                      </span>
                    ) : null}
                  </div>
                  <div className="flex justify-between gap-3">
                    <span className="font-medium-16">{t("price")}</span>
                    {pricingInfo === null ? null : (
                      <span className="font-bold-16 text-right">
                        {/* This screen only have 1 plan. */}
                        {plan.price.toLocaleString()} {plan.currency}
                      </span>
                    )}
                  </div>
                  {plan.promo_code ? (
                    <div className="flex justify-between gap-3">
                      <span className="font-medium-16 flex items-center gap-3">
                        <CouponLine className="h-5 w-5 min-w-[1.25rem] fill-primary" />{" "}
                        {locale === "vi"
                          ? plan.promo_description.vi
                          : plan.promo_description.en}
                      </span>
                      <div className="flex items-center gap-3">
                        <span className="font-bold-16 text-right text-primary">
                          -{Number(plan.discount.toFixed()).toLocaleString()}{" "}
                          {plan.currency}
                        </span>
                        <div className="p-1">
                          <button
                            className="rounded-full border border-warning-red-40% bg-warning-red-5% h-6 w-6 p-1 flex items-center justify-center"
                            onClick={() => {
                              onRemovePromocode(plan.id);
                            }}
                          >
                            <CloseLine className="h-4 w-4 fill-warning-red-danger" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <hr className="border-t border-light-grey" />
                </Fragment>
              ))
          : null}
        <span className="font-medium-16">{t("couponCode")}</span>
        <div className="flex justify-between gap-1.5">
          <div className="border border-dashed border-primary rounded-md flex items-center gap-3 px-3">
            <CouponLine className="h-5 w-5 min-w-[1.25rem] fill-primary" />
            {/* TODO: accessibility */}
            <input
              className="w-full rounded-md outline-none caret-subcard-blue transition-color duration-150 disabled:text-medium-grey disabled:hover:outline-transparent h-11 min-h-[2.75rem]"
              value={couponInput}
              onChange={(e) => {
                setCouponInput(e.target.value.toUpperCase());
                if (errorPromo) {
                  setErrorPromo(false);
                }
              }}
            />
          </div>
          <Button
            variant="secondary"
            size="large"
            onClick={() => {
              onApplyPromocode(couponInput).then((response) => {
                if (response) {
                  setCouponInput("");
                } else {
                  setErrorPromo(true);
                }
              });
            }}
          >
            {t("button.apply")}
          </Button>
        </div>
        {errorPromo ? (
          <span className="text-warning">{t("invalidCode")}</span>
        ) : null}
        <hr className="border-t border-light-grey" />
        <div className="flex justify-between gap-3 font-bold-24">
          <span>{t("total")}</span>
          {pricingInfo ? (
            <span className="text-primary text-right">
              {Number(pricingInfo.immediate_payment.toFixed()).toLocaleString()}{" "}
              {pricingInfo.currency}
            </span>
          ) : null}
        </div>
        <a>{t("paymentPolicy")}</a>
      </div>
      <div className="p-6 bg-bright-grey-subline text-hard-grey rounded-b-md font-regular-16-body">
        {t("assureYourInfo.pleaseAssureYourProvidedInfo")}
        <b className="text-dark-blue">{t("assureYourInfo.isPrecise")}</b>
        {t("assureYourInfo.afterThisStep")}
        <b className="text-dark-blue">
          {t("assureYourInfo.notBeAbleToChange")}
        </b>
        .
      </div>
    </div>
  );
};

export default PaymentFillFormSummary;
