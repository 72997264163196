import { useTranslation } from "react-i18next";
import { BreadcrumbV2 } from "#src/commonV2/Breadcrumb";
import { generatePath, useNavigate, useParams } from "react-router";
import { pathname } from "#src/config/pathname";
import {
  operatingSystemFilterItems,
  policyStatusFilterItems,
} from "#src/components/endpoint/policies/filterItems";
import { ReactNode, useMemo, useState } from "react";
import constants from "#src/config/constants";
import { ButtonV2 } from "#src/commonV2/Button";
import { SearchboxV2 } from "#src/commonV2/Table/Searchbox";
import { endpointService } from "#src/services/endpoint";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { TableV2 } from "#src/commonV2/Table";
import { IEndpointPolicy } from "#src/services/endpoint/policies/types";
import { Dropdown, Switch } from "@lockerpm/design";
import { DotsVertical, Eye, FileCheck02 } from "@untitled-ui/icons-react";
import { TableActionLabelV2 } from "#src/commonV2/Table/TableActionLabel";
import { DropdownRenderV2 } from "#src/commonV2/antdHelpers/DropdownRender";
import { getOsIcon } from "#src/components/endpoint/utils";
import i18next from "i18next";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { EndpointPolicyDetailModal } from "#src/components/endpoint/policies/DetailModal";
import { createToast } from "#src/common/system/toasts";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";
import { createSearchParams } from "react-router-dom";
import { useFilter } from "#src/commonV2/Table/Filter/useFilter";
import { EndpointDeviceSearchParamKey } from "./devices";

enum SearchParamKey {
  OPERATING_SYSTEM = "os",
  POLICY_STATUS = "status",
}

export const EndpointPolicies = () => {
  const { t } = useTranslation("endpoint", { keyPrefix: "policies" });

  const { workspaceId } = useParams<"workspaceId">();

  if (!workspaceId) {
    return null;
  }

  const navigate = useNavigate();

  const locale = i18next.language;

  // --------------- DATA ---------------

  // Pagination
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  // Search/sort
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");

  const { value: operatingSystem, Render: OperatingSystemFilterRender } =
    useFilter({
      label: t("table.filter.operatingSystem.label"),
      selectionList: operatingSystemFilterItems,
      options: {
        searchParamKey: SearchParamKey.OPERATING_SYSTEM,
        sideEffectOnChange: () => {
          setSelectedPage(1);
        },
      },
    });

  const { value: policyStatus, Render: PolicyStatusFilterRender } = useFilter({
    label: t("table.filter.policyStatus.label"),
    selectionList: policyStatusFilterItems,
    options: {
      searchParamKey: SearchParamKey.POLICY_STATUS,
      sideEffectOnChange: () => {
        setSelectedPage(1);
      },
    },
  });

  // Selection
  const [selectedPolicyIds, setSelectedPolicyIds] = useState<number[]>([]);

  // Saving ID of clicked policy
  const [openDetailModal, setOpenDetailModal] =
    useState<IEndpointPolicy | null>(null);

  // --------------- PARAMETERS ---------------

  const fetchParams = useMemo<Parameters<typeof endpointService.listPolicies>>(
    () => [
      workspaceId,
      {
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
        sort: sortOrder ?? undefined,
        platform: operatingSystem,
        enabled: policyStatus,
        host_ids: undefined,
        tag_ids: undefined,
      },
    ],
    [
      workspaceId,
      searchKeyword,
      selectedPage,
      pageSize,
      sortOrder,
      operatingSystem,
      policyStatus,
    ]
  );

  // --------------- HOOKS ---------------

  const {
    list: policyList,
    count: policyCount,
    isLoading,
    updateData: updatePolicyList,
  } = useFetchPaginated(endpointService.listPolicies, fetchParams);

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  // --------------- METHODS ---------------

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  // Action list for kebab menu
  const getTableActions = (record: IEndpointPolicy) => {
    const actions: {
      key: string;
      label: ReactNode;
      action: () => void;
    }[] = [
      {
        key: "view-failed-devices",
        label: (
          <TableActionLabelV2
            icon={<Eye className="h-5 w-5" />}
            text={t("table.action.viewFailedDevices")}
          />
        ),
        action: () => {
          navigate({
            pathname: generatePath(pathname.ENDPOINT_DEVICES, { workspaceId }),
            search: createSearchParams({
              [EndpointDeviceSearchParamKey.FAILED_POLICY]:
                record.id.toString(),
            }).toString(),
          });
        },
      },
    ];

    // If nothing failed, disable request to comply
    if (record.statistic.failed !== 0 && canEdit) {
      actions.push({
        key: "request-to-comply",
        label: (
          <TableActionLabelV2
            icon={<FileCheck02 className="h-5 w-5" />}
            text={t("table.action.requestToComply")}
          />
        ),
        action: () => {
          endpointService
            .requestToComply(workspaceId, { ids: [record.id] })
            .then(() => {
              createToast({
                type: "success",
                message: t(
                  "table.notification.requestToComply.success.message"
                ),
                detail:
                  locale === "vi"
                    ? record.policy.name.vi
                    : record.policy.name.en,
              });
            })
            .catch(apiErrorHandler);
        },
      });
    }

    return actions;
  };

  const onToggleStatus = (record: IEndpointPolicy) => {
    // If current user is not WORKSPACE OWNER, they should not be abled to make changes.
    if (!canEdit) {
      return;
    }

    endpointService
      .updatePolicy(workspaceId, record.id.toString(), {
        enabled: record.enabled,
      })
      .then(() => {
        createToast({
          type: "success",
          message: t("table.notification.updatePolicy.success"),
        });
        updatePolicyList();
      })
      .catch(apiErrorHandler);
  };

  const onRequestToComplyMultiple = () => {
    const notCompliedPolicies = selectedPolicyIds.filter((selected) => {
      const findPolicy = policyList?.find((policy) => policy.id === selected);
      if (findPolicy && findPolicy.statistic.failed === 0) {
        return false;
      }
      return true;
    });

    if (notCompliedPolicies.length === 0) {
      createToast({
        type: "success",
        message: t(
          "table.notification.requestToComply.noActionsNeeded.message"
        ),
        detail: t("table.notification.requestToComply.noActionsNeeded.detail"),
      });
      return;
    }

    endpointService
      .requestToComply(workspaceId, { ids: notCompliedPolicies })
      .then(() => {
        createToast({
          type: "success",
          message: t("table.notification.requestToComply.success.message"),
        });
      })
      .catch(apiErrorHandler);
  };

  // --------------- EFFECTS ---------------

  // --------------- COMPUTED ---------------

  const currentUserRole = currentWorkspace?.role;

  const canEdit =
    currentUserRole === WorkspaceMemberRoleValue.OWNER ||
    currentUserRole === WorkspaceMemberRoleValue.ADMIN;

  // --------------- RENDER ---------------

  return (
    <>
      <div className="pt-5 pb-6 flex flex-col gap-5 min-h-screen">
        {/* Header (Breadcrumb + title) */}
        <div className="px-8 flex flex-col gap-4">
          <BreadcrumbV2
            levels={[
              {
                name: t("breadcrumb.levels.endpoint"),
                path: generatePath(pathname.ENDPOINT_OVERVIEW, { workspaceId }),
              },
            ]}
            currentPage={{
              name: t("breadcrumb.current.policies"),
            }}
          />
          <span className="text-display-xs font-semibold text-gray-v2-900">
            {t("title")}
          </span>
        </div>
        {/* Header (Breadcrumb + title) end */}

        <div className="px-8 flex flex-col gap-4 flex-1">
          <div className="flex justify-between items-center gap-2">
            {/* Filters */}
            <div className="flex gap-2">
              {selectedPolicyIds.length === 0 ? (
                <>
                  {OperatingSystemFilterRender}
                  {PolicyStatusFilterRender}
                </>
              ) : null}
            </div>
            {/* Filters end */}

            <div className="flex gap-2 v2">
              {/* Bulk actions */}
              {selectedPolicyIds.length > 0 && (
                <ButtonV2
                  variant="secondary"
                  size="md"
                  color="brand"
                  onClick={onRequestToComplyMultiple}
                >
                  <p>{t("table.bulkAction.requestToComply")}</p>
                </ButtonV2>
              )}
              {/* Bulk actions end  */}

              {/* Searchbox */}
              <SearchboxV2
                value={searchKeyword}
                onChange={(value) => {
                  setSearchKeyword(value);
                }}
                placeholder={t("table.filter.searchbox.placeholder")}
              />
              {/* Searchbox end */}
            </div>
          </div>

          {/* Table */}
          <TableV2<IEndpointPolicy>
            data={policyList ?? []}
            getRowKey={(item) => item.id.toString()}
            isLoading={isLoading}
            columns={[
              {
                title: t("table.header.os"),
                render: (item) => getOsIcon(item.platform),
                gridTemplateValue: "1fr",
                titleAlign: "center",
                overwriteWrapperProps: { className: "justify-center" },
              },
              {
                title: t("table.header.name"),
                render: (item) =>
                  locale === "vi" ? item.policy.name.vi : item.policy.name.en,
                gridTemplateValue: "4fr",
                overwriteWrapperProps: {
                  className: "font-medium text-gray-v2-900",
                },
              },
              {
                title: t("table.header.description"),
                render: (item) =>
                  locale === "vi"
                    ? item.policy.description.vi
                    : item.policy.description.en,
                gridTemplateValue: "7fr",
              },
              {
                title: t("table.header.status"),
                render: (item) => (
                  <Switch
                    checked={item.enabled}
                    onClick={(_, e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onToggleStatus(item);
                    }}
                    className={canEdit ? "" : "cursor-not-allowed"}
                  />
                ),
                gridTemplateValue: "2fr",
              },
              {
                title: t("table.header.compliance"),
                render: (item) => {
                  const passRate =
                    item.statistic.total === 0
                      ? 100
                      : ((item.statistic.total - item.statistic.failed) /
                          item.statistic.total) *
                        100;

                  return (
                    <div
                      className={`rounded-full py-1 flex justify-center w-full text-sm font-medium border ${
                        passRate < 50
                          ? "text-error-v2-700 bg-error-v2-50 border-error-v2-200"
                          : passRate < 85
                            ? "text-warning-v2-700 bg-warning-v2-50 border-warning-v2-200"
                            : "text-success-v2-700 bg-success-v2-50 border-success-v2-200"
                      }`}
                    >
                      {t("table.percentPassed", {
                        percent: passRate.toFixed(),
                      })}
                    </div>
                  );
                },
                gridTemplateValue: "3fr",
                sortingKey: { asc: "compliance_asc", desc: "compliance_desc" },
              },
              {
                title: null,
                render: (item) => {
                  const actions = getTableActions(item);
                  const disabled = actions.length === 0;

                  return (
                    <Dropdown
                      menu={{
                        items: actions,
                        onClick: ({ key, domEvent }) => {
                          domEvent.preventDefault();
                          domEvent.stopPropagation();
                          actions
                            .find((action) => action.key === key)
                            ?.action();
                        },
                      }}
                      dropdownRender={(menu) => (
                        <DropdownRenderV2>{menu}</DropdownRenderV2>
                      )}
                      trigger={["click"]}
                      disabled={disabled}
                    >
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <DotsVertical
                          className={`h-5 w-5 ${disabled ? "text-gray-v2-200" : "text-gray-v2-400"} transition-colors duration-300`}
                        />
                      </button>
                    </Dropdown>
                  );
                },
                overwriteWrapperProps: { className: "justify-center" },
                gridTemplateValue: "1fr",
              },
            ]}
            rowSelection={
              canEdit
                ? {
                    selectedRowKeys: selectedPolicyIds.map((item) =>
                      item.toString()
                    ),
                    onChange: (value) => {
                      setSelectedPolicyIds(value.map((item) => Number(item)));
                    },
                  }
                : undefined
            }
            sort={{
              value: sortOrder,
              onChange: (value) => {
                setSortOrder(value);
              },
            }}
            pagination={{
              count: policyCount,
              selected: selectedPage,
              onChange: onChangePage,
              size: { value: pageSize, onChange: onChangePageSize },
            }}
            options={{
              fitPageHeight: true,
            }}
            onClickRow={(item) => {
              setOpenDetailModal(item);
            }}
          />
          {/* Table end */}
        </div>
      </div>
      <EndpointPolicyDetailModal
        open={openDetailModal !== null}
        onClose={() => {
          setOpenDetailModal(null);
        }}
        policyDetail={openDetailModal}
      />
    </>
  );
};
