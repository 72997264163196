import {
  type HTMLAttributes,
  useLayoutEffect,
  useRef,
  useState,
  type ReactNode,
  useMemo,
} from "react";
import { TableHeaderV2 } from "./TableHeader";
import { TableRowWrapperV2 } from "./TableRowWrapper";
import { PaginationV2 } from "./Pagination";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@lockerpm/design";
import { TableDataCellV2 } from "./TableDataCell";
import { LoadingStateV2 } from "../LoadingState";
import { EmptyStateV2 } from "../EmptyState";
import { Accordion } from "#src/common/system/Accordion";
import { ChevronDown } from "@untitled-ui/icons-react";

// TODO: maybe change these to refs.
const TABLE_WRAPPER_ID = "table-wrapper";
const TABLE_PAGINATION_ID = "table-pagination";

interface ITableV2Props<DataType> {
  data: DataType[];
  getRowKey: (dataItem: DataType) => string;
  isLoading?: boolean;
  columns: {
    title: ReactNode;
    /** Theoretically we should let developers overwrite everything, but I've yet to find any cases we need more than this alignment. */
    titleAlign?: "left" | "center" | "right";
    render: (dataItem: DataType, index: number) => ReactNode;
    /** Literally let you overwrite any props of cell wrapper. */
    overwriteWrapperProps?: HTMLAttributes<HTMLDivElement>;
    gridTemplateValue?: string;
    /** Sorting param of this column. */
    sortingKey?: { asc: string; desc: string };
  }[];
  rowSelection?: {
    selectedRowKeys: string[];
    onChange: (newSelectedKeys: string[]) => void;
  };
  sort?: {
    value: string;
    onChange: (value: string) => void;
  };
  pagination?: {
    count: number;
    selected: number;
    onChange: (value: number) => void;
    size: { value: number; onChange?: (value: number) => void };
  };
  options?: {
    fitPageHeight?: boolean;
    maxHeight?: string;
    emptyState?: {
      title: string;
      supportingText?: string;
      type?: "featured-icon" | "illustration";
      buttons?: ReactNode;
    };
  };
  onClickRow?: (item: DataType) => void;
  expandContent?: (item: DataType) => ReactNode;
}

/** Duh, it's a data table. Aside common things, there's some options to customize more things:
 * @param fitPageHeight (optional) fit the table to the screen. Will add a max-height calculated from the table's position.
 * @param emptyState (optional) config how the empty state will be shown. Same props as EmptyState's props.
 */
export const TableV2 = <DataType,>({
  data,
  getRowKey,
  isLoading,
  columns,
  rowSelection,
  sort,
  pagination,
  options,
  onClickRow,
  expandContent,
}: ITableV2Props<DataType>) => {
  const { t } = useTranslation("common", { keyPrefix: "v2.table" });

  const tableBodyRef = useRef<HTMLDivElement>(null);

  // Calculate how much height should we left. Table height will be calculated by ()
  const [preferredBufferHeight, setPreferredBufferHeight] = useState<number>();

  const [expandedKey, setExpandedKey] = useState<string | null>(null);

  // ---------- COMPUTED ----------

  const tableMaxHeight = useMemo(() => {
    // As noted above: table height is recalculated to adjust to the screen height. `2rem` is added so that it has some spaces below.
    if (options?.fitPageHeight) {
      return preferredBufferHeight
        ? `calc(100vh - ${preferredBufferHeight}px - 2rem)`
        : // Fallback value if the useLayoutEffect bugs out
          "65vh";
    }

    // If not fitPageHeight, then we use the maxHeight option.
    return options?.maxHeight ? options.maxHeight : undefined;
  }, [options, preferredBufferHeight]);

  // ---------- EFFECTS ----------

  // Set preferredBufferHeight by rendered sizes
  useLayoutEffect(() => {
    const tableWrapperElement = document.getElementById(TABLE_WRAPPER_ID);
    const tableBodyElement = tableBodyRef.current;
    const tablePaginationElement = document.getElementById(TABLE_PAGINATION_ID);

    let totalBuffer: number = 0;

    if (tableWrapperElement) {
      totalBuffer =
        totalBuffer +
        parseInt(getComputedStyle(tableWrapperElement).marginBottom, 10);
    }

    if (tableBodyElement) {
      totalBuffer = totalBuffer + tableBodyElement.getBoundingClientRect().top;
    }

    if (tablePaginationElement) {
      totalBuffer =
        totalBuffer + tablePaginationElement.getBoundingClientRect().height;
    }

    setPreferredBufferHeight(totalBuffer);
    // When data change, we have to recalculate this since these height depends on that data content.
  }, [data]);

  // ---------- RENDER ----------
  return (
    <div
      id={TABLE_WRAPPER_ID}
      className={`border border-gray-v2-200 shadow-xs rounded-xl flex flex-col mb-2 ${isLoading || data.length === 0 ? "items-center justify-center flex-1 overflow-hidden" : ""}`}
    >
      {isLoading ? (
        <LoadingStateV2 />
      ) : data.length === 0 ? (
        <EmptyStateV2
          title={
            options && options.emptyState
              ? options.emptyState.title
              : t("emptyState")
          }
          {...options?.emptyState}
        />
      ) : (
        <>
          <div
            className="grid overflow-x-auto"
            style={{
              gridTemplateColumns:
                // The space after "3.25rem" is necessary for grid template to work properly.
                (expandContent ? "3.25rem " : "") +
                // The space after "4rem" is necessary for grid template to work properly.
                (rowSelection ? "4rem " : "") +
                columns
                  .map((column) =>
                    column.gridTemplateValue ? column.gridTemplateValue : "1fr"
                  )
                  .join(" "),
            }}
          >
            {/* Table header */}
            <TableHeaderV2
              headerProps={columns.map((column) => ({
                name: column.title,
                align: column.titleAlign ? column.titleAlign : "left",
                sortingKey: column.sortingKey,
              }))}
              sort={sort}
              allSelected={
                rowSelection
                  ? {
                      value: rowSelection
                        ? rowSelection.selectedRowKeys.length > 0
                        : false,
                      onChange: () => {
                        if (rowSelection) {
                          if (rowSelection.selectedRowKeys.length === 0) {
                            rowSelection.onChange(data.map(getRowKey));
                          } else {
                            rowSelection.onChange([]);
                          }
                        }
                      },
                      ready: !isLoading && data.length > 0,
                    }
                  : undefined
              }
              tableExpandable={Boolean(expandContent)}
            />
            {/* Table header end */}

            {/* Table body */}
            <div
              ref={tableBodyRef}
              className="grid grid-cols-subgrid col-span-full overflow-x-hidden overflow-y-auto"
              style={{
                maxHeight: tableMaxHeight,
              }}
            >
              {data.map((item, rowIndex) => {
                // For setting React key and checking selected rows
                const rowKey = getRowKey(item);

                return (
                  // This div is for the expand function. Supposedly we only need the TableRowWrapper.
                  <div
                    key={`table-${rowKey}`}
                    className="grid grid-cols-subgrid col-span-full border-b border-gray-v2-200 last:border-none"
                  >
                    <TableRowWrapperV2
                      className={`${
                        expandedKey === rowKey
                          ? "bg-gray-v2-100"
                          : rowSelection &&
                              rowSelection.selectedRowKeys.includes(rowKey)
                            ? "bg-gray-v2-50"
                            : ""
                      } ${onClickRow || expandContent ? "cursor-pointer" : ""}`}
                      onClick={() => {
                        if (expandContent) {
                          setExpandedKey((prev) =>
                            prev === rowKey ? null : rowKey
                          );
                          // Skip onClickRow if we expand on click.
                          return;
                        }
                        if (onClickRow) {
                          onClickRow(item);
                        }
                      }}
                    >
                      {expandContent ? (
                        <TableDataCellV2 className="justify-center">
                          <ChevronDown
                            className={`h-4 w-4 min-w-4 text-gray-v2-400 transition-all ${expandedKey === rowKey ? "rotate-180" : ""}`}
                          />
                        </TableDataCellV2>
                      ) : null}
                      {rowSelection ? (
                        <TableDataCellV2 className="justify-center">
                          <Checkbox
                            checked={rowSelection.selectedRowKeys.includes(
                              rowKey
                            )}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              if (
                                rowSelection.selectedRowKeys.includes(rowKey)
                              ) {
                                rowSelection.onChange(
                                  rowSelection.selectedRowKeys.filter(
                                    (k) => k != rowKey
                                  )
                                );
                              } else {
                                rowSelection.onChange([
                                  ...rowSelection.selectedRowKeys,
                                  rowKey,
                                ]);
                              }
                            }}
                          />
                        </TableDataCellV2>
                      ) : null}
                      {columns.map((column, colIndex) => (
                        <TableDataCellV2
                          key={`table-cell-${rowKey}-${colIndex}`}
                          {...column.overwriteWrapperProps}
                        >
                          {column.render(item, rowIndex)}
                        </TableDataCellV2>
                      ))}
                    </TableRowWrapperV2>
                    {expandContent ? (
                      <Accordion
                        wrapperClassName={`col-span-full bg-gray-v2-100 ${expandedKey === rowKey ? "border-t border-gray-v2-200" : ""}`}
                        expanded={expandedKey === rowKey}
                      >
                        {expandContent(item)}
                      </Accordion>
                    ) : null}
                  </div>
                );
              })}
            </div>
            {/* Table body end */}
          </div>

          {/* Pagination */}
          {pagination && data.length > 0 && !isLoading ? (
            <PaginationV2
              id={TABLE_PAGINATION_ID}
              numOfResult={pagination.count}
              selectedPage={pagination.selected}
              onChangePage={pagination.onChange}
              pageSize={pagination.size.value}
              onChangePageSize={pagination.size.onChange}
            />
          ) : null}
          {/* Pagination end */}
        </>
      )}
    </div>
  );
};
