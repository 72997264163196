import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowExpandDownLine } from "#src/assets/images/icons/arrow-expand-down-line.svg";
import { Button } from "#src/common/system/Button";
import type { ICertificateItem } from "#src/services/assets/certificates";

const CertificateHandshakeSimulation = ({
  endpointDetails,
}: {
  endpointDetails: ICertificateItem["endpoints"][number]["details"];
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.certificates",
  });

  const [viewMore, setViewMore] = useState<boolean>(false);

  return (
    <>
      <h2>{t("handshakeSimulation.title")}</h2>
      <div className="grid grid-cols-[1fr_1fr_1fr_1fr] gap-x-3">
        <div className="grid grid-cols-subgrid col-span-full bg-bright-grey-subline border-y border-y-light-grey justify-center items-center px-6">
          <div className="py-3 pl-3 text-hard-grey">
            {t("handshakeSimulation.os")}
          </div>
          <div className="py-3 text-hard-grey">
            {t("handshakeSimulation.algorithm")}
          </div>
          <div className="py-3 text-hard-grey">
            {t("handshakeSimulation.protocol")}
          </div>
          <div className="py-3 text-hard-grey">
            {t("handshakeSimulation.cipherSuite")}
          </div>
        </div>
        {(viewMore
          ? endpointDetails.sims.results
          : endpointDetails.sims.results.slice(0, 10)
        ).map((sim) => {
          if (sim.alertCode) {
            console.log(
              "Simulation alert: " + sim.client.name + " " + sim.client.version,
              "\nAlert code: " + sim.alertCode,
              "\nAlert type: " + sim.alertType
            );
          }

          const matchedProtocol = endpointDetails.protocols.find(
            (protocol) => protocol.id === sim.protocolId
          );

          if (sim.errorCode === 0 && matchedProtocol === undefined) {
            console.log(
              "unmatched protocol: cannot find protocol id " + sim.protocolId
            );
          }

          return (
            <div
              key={`handshakeSimulationTable-${sim.client.id}`}
              className="grid grid-cols-subgrid col-span-full bg-white border-b border-b-light-grey justify-center items-center px-6"
            >
              <div className="py-3 pl-3">
                {sim.client.name + " " + sim.client.version}
              </div>
              <div className="py-3">
                {sim.errorCode === 0 ? sim.sigAlg : ""}
              </div>
              <div className="py-3">
                {sim.errorCode === 0 && matchedProtocol !== undefined
                  ? matchedProtocol.name + " " + matchedProtocol.version
                  : sim.errorMessage}
              </div>
              <div className="py-3">
                {sim.errorCode === 0 ? sim.suiteName : ""}
              </div>
            </div>
          );
        })}
      </div>
      {endpointDetails.sims.results.length > 10 ? (
        <Button
          variant="text"
          onClick={() => {
            setViewMore((prev) => !prev);
          }}
        >
          {viewMore ? t("button.collapse") : t("button.viewMore")}
          <ArrowExpandDownLine className={viewMore ? "rotate-180" : ""} />
        </Button>
      ) : null}
    </>
  );
};

export default CertificateHandshakeSimulation;
