import { ReactNode } from "react";
import { ReactComponent as LogoWindows10 } from "#src/assets/images/logos/logo-windows-10.svg";
import { ReactComponent as LogoApple } from "#src/assets/images/logos/logo-apple.svg";
import { ReactComponent as LogoUbuntu } from "#src/assets/images/logos/logo-ubuntu.svg";

const iconByOs: { [os: string]: ReactNode } = {
  windows: <LogoWindows10 className="h-7 w-7" />,
  macos: <LogoApple className="h-7 w-7" />,
  linux: <LogoUbuntu className="h-7 w-7" />,
};

export const getOsIcon = (value: string) => {
  if (Object.keys(iconByOs).includes(value)) return iconByOs[value];
  return null;
};
