// Libraries
import { useState, type Dispatch, type SetStateAction } from "react";

// Components
import LoadingState from "#src/common/system/LoadingState";
import type { ContactFormSelection } from "#src/common/helper/contactSupport/contactDetailFields";
import type { FormErrorItem } from "#src/common/helper/wrapper/InputField";

// API-related
import paymentService, { type IPaymentForm } from "#src/services/payment";
import { PaymentMethodValue } from "#src/config/filter/workspace/value";

// Children
import PaymentFillFormRecheckTable from "#src/components/payment/FillForm/RecheckTable";
import PaymentFillFormCustomerInfo from "#src/components/payment/FillForm/CustomerInfo";
import PaymentFillFormMethodSelect from "#src/components/payment/FillForm/PaymentMethod";
import PaymentFillFormSummary from "#src/components/payment/FillForm/Summary";
import ReselectPlanModal from "./ReselectPlanModal";
import { expandPlanDuration } from "#src/utils/apiHelper";

interface IDataLeakKeywordFillFormProps {
  workspaceId: string;
  pricingInfo: Awaited<
    ReturnType<typeof paymentService.calculate_pricing>
  > | null;
  paymentForm: IPaymentForm;
  setPaymentForm: Dispatch<SetStateAction<IPaymentForm>>;
  jobTitle: ContactFormSelection;
  setJobTitle: Dispatch<SetStateAction<ContactFormSelection>>;
  defaultPaymentSettings: Awaited<
    ReturnType<typeof paymentService.retrieve_workspace_payment_settings>
  > | null;
  onEditPlan: (value: {
    keyword: string;
    product_plan_id: string;
    duration: string;
  }) => void;
  onApplyPromocode: (value: string) => Promise<boolean>;
  onRemovePromocode: (removeId: string) => void;
  errorObj: {
    full_name: FormErrorItem;
    phone_number: FormErrorItem;
    email: FormErrorItem;
  };
  setErrorObj: Dispatch<
    SetStateAction<{
      full_name: FormErrorItem;
      phone_number: FormErrorItem;
      email: FormErrorItem;
    }>
  >;
  invoiceErrorObj: {
    name: FormErrorItem;
    tax_code: FormErrorItem;
    identity_card: FormErrorItem;
    address: FormErrorItem;
  };
  setInvoiceErrorObj: Dispatch<
    SetStateAction<{
      name: FormErrorItem;
      tax_code: FormErrorItem;
      identity_card: FormErrorItem;
      address: FormErrorItem;
    }>
  >;
}

const DataLeakKeywordFillForm = ({
  workspaceId,
  pricingInfo,
  paymentForm,
  setPaymentForm,
  jobTitle,
  setJobTitle,
  defaultPaymentSettings,
  onEditPlan,
  onApplyPromocode,
  onRemovePromocode,
  errorObj,
  setErrorObj,
  invoiceErrorObj,
  setInvoiceErrorObj,
}: IDataLeakKeywordFillFormProps) => {
  const onChangeCustomer = (value: (typeof paymentForm)["customer"]) => {
    setPaymentForm((prev) => ({
      ...prev,
      customer: value,
    }));
  };

  const [editingItem, setEditingItem] = useState<{
    keyword: string;
    product_plan_id: string;
    duration: string;
  } | null>(null);

  return (
    <div className="flex flex-col gap-10">
      {pricingInfo === null ? (
        <LoadingState />
      ) : (
        <PaymentFillFormRecheckTable
          products={pricingInfo?.product_plans.map((item) => ({
            id: item.id,
            service: {
              en: item.meta.workspace_keyword.keyword,
              vi: item.meta.workspace_keyword.keyword,
            },
            product: item.product.category.name,
            plan: {
              en: item.name?.en || "",
              vi:  item.name?.vi || ""
            },
            cycle: () => {
              const aggregateCycle = expandPlanDuration(item);
              if (aggregateCycle) {
                return aggregateCycle.getSpan();
              }
              return "";
            },
            price: item.price,
            onClickEdit: () => {
              setEditingItem({
                keyword: item.meta.workspace_keyword.keyword,
                product_plan_id: item.id,
                duration: item.duration,
              });
            },
            nextBillingTime: item.next_billing_time,
          }))}
        />
      )}
      <div className="grid grid-cols-[3fr_2fr] gap-6">
        <div className="flex flex-col gap-6">
          <PaymentFillFormCustomerInfo
            customer={paymentForm.customer}
            onChangeCustomer={onChangeCustomer}
            jobTitle={jobTitle}
            setJobTitle={setJobTitle}
            errorObj={errorObj}
            setErrorObj={setErrorObj}
          />
          <PaymentFillFormMethodSelect
            paymentForm={paymentForm}
            setPaymentForm={setPaymentForm}
            nextCycleInfo={{
              cycle: "",
              price:
                Number(
                  pricingInfo?.immediate_payment.toFixed()
                ).toLocaleString() +
                " " +
                pricingInfo?.currency,
              date: "",
            }}
            defaultPaymentSettings={defaultPaymentSettings}
            firstPayment={true}
            lastSelectedMethod={PaymentMethodValue.BANKING}
            invoiceErrorObj={invoiceErrorObj}
            setInvoiceErrorObj={setInvoiceErrorObj}
          />
        </div>
        <PaymentFillFormSummary
          pricingInfo={pricingInfo}
          onApplyPromocode={onApplyPromocode}
          onRemovePromocode={onRemovePromocode}
        />
      </div>
      <ReselectPlanModal
        workspaceId={workspaceId}
        open={editingItem !== null}
        onChangeOpen={(value) => {
          if (!value) {
            setEditingItem(null);
          }
        }}
        editingItem={editingItem}
        onEditPlan={onEditPlan}
      />
    </div>
  );
};

export default DataLeakKeywordFillForm;
