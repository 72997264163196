// Libraries
import { Tabs } from "@lockerpm/design";
import { type ReactNode, useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import TabButtonLabel from "#src/common/helper/antdProps/Tabs/TabButtonLabel";
import { ContentSection, StickySection } from "#src/layouts/content";
import LoadingState from "#src/common/system/LoadingState";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import assetsServices, { type IAssetDetailItem } from "#src/services/assets";

// Children
import AssetsDetailsCertificates from "#src/components/assets/details/Tabs/CertificatesTab";
import AssetsDetailsOverview from "#src/components/assets/details/Tabs/OverviewTab";
import AssetsDetailsPorts from "#src/components/assets/details/Tabs/PortsTab";
import AssetsDetailsVulnerabilities from "#src/components/assets/details/Tabs/VulnerabilitiesTab";
import AssetDetailProfileSection from "#src/components/assets/details/ProfileSection";
import AssetDetailBasicInfoSection from "#src/components/assets/details/BasicInfoSection";
import EditSettingDrawer from "#src/components/assets/drawers/EditSettingDrawer";
import VerifyOwnershipDrawer from "#src/components/assets/drawers/VerifyOwnershipDrawer";
import AddScanDrawer from "#src/components/scans/all-scans/AddScanDrawer";
import { AssetDetailTabEnum } from "#src/components/assets/details/Tabs/enums";

const AssetsIpAddressDetails = () => {
  const { t } = useTranslation("assets", {
    keyPrefix: "ipAddress.page.details",
  });
  const navigate = useNavigate();

  const { workspaceId, assetId } = useParams<"workspaceId" | "assetId">();

  const [ipAddressDetails, setIpAddressDetails] =
    useState<IAssetDetailItem | null>(null);
  const [activeTab, setActiveTab] = useState<string>(
    AssetDetailTabEnum.OVERVIEW
  );

  // Drawer states
  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);
  const [openVerifyDrawer, setOpenVerifyDrawer] = useState<boolean>(false);
  const [openScanDrawer, setOpenScanDrawer] = useState<boolean>(false);

  // Loading state
  const [isLoading, setLoading] = useState<boolean>(false);

  const onChangeTab = (key: string) => {
    setActiveTab(key);
  };

  const fetchIpAddressDetails = useCallback(() => {
    if (workspaceId && assetId && !isNaN(+assetId)) {
      setLoading(true);
      assetsServices
        .retrieve_asset(workspaceId, +assetId)
        .then((response) => {
          setIpAddressDetails(response);
          setLoading(false);
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId, assetId]);

  const onDeleteThisIpAddress = () => {
    if (workspaceId && assetId && !isNaN(+assetId))
      assetsServices
        .delete_asset(+assetId, workspaceId)
        .then(() => {
          // WARNING: Might break in the case user went straight into this url.
          // TODO: check history before navigating?
          navigate(-1);
          createToast({
            type: "success",
            message: t("notification.deleteIpAddress.success"),
          });
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.deleteIpAddress.fail"),
          });
        });
  };

  const tabList: { key: string; label: ReactNode; children: ReactNode }[] =
    ipAddressDetails && workspaceId && assetId && !isNaN(+assetId)
      ? [
          {
            key: AssetDetailTabEnum.OVERVIEW,
            label: <TabButtonLabel name={t("tab.overview.title")} />,
            children: (
              <AssetsDetailsOverview
                assetDetails={ipAddressDetails}
                onChangeTab={onChangeTab}
              />
            ),
          },
          {
            key: AssetDetailTabEnum.PORTS,
            label: <TabButtonLabel name={t("tab.ports.title")} />,
            children: (
              <AssetsDetailsPorts
                portDetailsInit={ipAddressDetails.ports}
                workspaceId={workspaceId}
                assetId={+assetId}
              />
            ),
          },
          {
            key: AssetDetailTabEnum.VULNERABILITIES,
            label: (
              <TabButtonLabel
                name={t("tab.vulnerabilities.title")}
                count={ipAddressDetails.vulnerabilities.count}
              />
            ),
            children: (
              <AssetsDetailsVulnerabilities
                workspaceId={workspaceId}
                assetId={+assetId}
                refreshAssetDetails={fetchIpAddressDetails}
              />
            ),
          },
          {
            key: AssetDetailTabEnum.CERTIFICATES,
            label: <TabButtonLabel name={t("tab.certificates.title")} />,
            children: (
              <AssetsDetailsCertificates
                workspaceId={workspaceId}
                assetId={ipAddressDetails.id}
                certificateDetailsInit={ipAddressDetails.certificates}
              />
            ),
          },
        ]
      : [];

  // fetch IP address detail
  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchIpAddressDetails();
    }

    return () => {
      ignore = true;
    };
  }, [fetchIpAddressDetails]);

  return workspaceId ? (
    ipAddressDetails === null ? (
      isLoading ? (
        <div className="pt-20">
          <LoadingState />
        </div>
      ) : (
        <></>
      )
    ) : (
      <>
        <StickySection>
          <AssetDetailProfileSection
            details={ipAddressDetails}
            onOpenEditDrawer={() => {
              setOpenEditDrawer(true);
            }}
            onOpenVerifyDrawer={() => {
              setOpenVerifyDrawer(true);
            }}
            onOpenScanDrawer={() => {
              setOpenScanDrawer(true);
            }}
            onDeleteThis={onDeleteThisIpAddress}
          />
        </StickySection>
        <ContentSection>
          <AssetDetailBasicInfoSection assetDetails={ipAddressDetails} />
          <Tabs
            items={tabList.filter((tab) => {
              if (
                tab.key === AssetDetailTabEnum.CERTIFICATES &&
                ipAddressDetails.certificates === null
              )
                return false;
              return true;
            })}
            activeKey={activeTab}
            onChange={onChangeTab}
            destroyInactiveTabPane
          />
          <EditSettingDrawer
            selectedItemId={ipAddressDetails ? ipAddressDetails.id : null}
            open={openEditDrawer}
            onClose={() => {
              setOpenEditDrawer(false);
            }}
            workspaceId={workspaceId}
            onRefresh={fetchIpAddressDetails}
          />
          <VerifyOwnershipDrawer
            open={openVerifyDrawer}
            onClose={() => {
              setOpenVerifyDrawer(false);
            }}
            workspaceId={workspaceId}
            onRefresh={() => {}}
            assetName={ipAddressDetails ? ipAddressDetails.name : null}
            assetId={ipAddressDetails ? ipAddressDetails.id : null}
          />
          <AddScanDrawer
            open={openScanDrawer}
            workspaceId={workspaceId}
            onClose={() => {
              setOpenScanDrawer(false);
            }}
            onRefresh={fetchIpAddressDetails}
            assetInit={
              ipAddressDetails
                ? {
                    key: ipAddressDetails.id.toString(),
                    id: ipAddressDetails.id.toString(),
                    value: ipAddressDetails.id.toString(),
                    address: ipAddressDetails.detail.address,
                    label: ipAddressDetails.detail.address,
                  }
                : undefined
            }
          />
        </ContentSection>
      </>
    )
  ) : null;
};

export default AssetsIpAddressDetails;
