import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import {
  list_keywords,
  list_keywords_shortly,
  add_multiple_keywords,
  remove_multiple_keywords,
} from "./keyword";

export interface ILeakedDataAccountDetails {
  url?: string | null;
  password?: string | null;
  username?: string | null;
}

export interface ILeakedDataCookieDetails {
  raw?: string | null;
  name?: string | null;
  path?: string | null;
  value?: string | null;
  domain?: string | null;
  expiry?: number | null;
  secure?: boolean | null;
  include_subdomains?: boolean | null;
}

export interface ILeakedDataEmailDetails {
  email?: string | null;
}

export interface ILeakedDataSourceCodeDetails {
  path?: string | null;
  repo?: string | null;
  commit?: string | null;
  keyword?: string | null;
}

/** T: put typeof data's `details` object here */
export interface ILeakedDataItem<T> {
  id: number;
  type: string;
  created_time: number;
  updated_time: number;
  status: string;
  sub_status: string;
  // This (matches) looks like it's also deprecated
  matches: [
    {
      preview: string;
      line_number?: number;
      // DEPRECATED: will be removed soon
      offset_and_lengths?: Array<[number, number]>;
    },
  ];
  details: T;
  source: string[];
  referer: any | null;
  severity: string | null;
  vuln_id: number;
  keyword: string;
}

function list_data_leak(
  workspace_id: string,
  params: {
    from: number | undefined;
    to: number | undefined;
    filter_status: string | undefined;
    filter_sub_status: string | undefined;
    filter_severity: string | undefined;
    filter_type: string | undefined;
    filter_keyword_ids: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<ILeakedDataItem<unknown>>>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks`,
    { params }
  );
}

function update_leaked_data<T>(
  workspace_id: string,
  leaked_data_id: number,
  data: Partial<ILeakedDataItem<T>>
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/${leaked_data_id}`,
    data
  );
}

export interface IDataLeakStatistics {
  email: number;
  source_code: number;
  api_key: number;
  credential: number;
  payment: number;
  raw: number;
  customer_data: number;
  account: number;
  cookie: number;
  total: number;
}

function statistics_data_leak(
  workspace_id: string,
  params: Parameters<typeof list_data_leak>[1]
) {
  return request.get<never, IDataLeakStatistics>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/statistics`,
    { params }
  );
}

interface IDataLeakPlanRequestEnterprise {
  product_plan_id: "data_leak_enterprise";
  gender: string;
  fullname: string;
  phone_number: string;
  // TODO: should be an enum
  job_title: string;
  organization_name: string;
  industry: string;
  // TODO: should be an enum
  employee_size: string;
  email: string;
}

function request_data_leak_plan(
  workspace_id: string,
  data: IDataLeakPlanRequestEnterprise
) {
  return request.post<never, { success: true }>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/plan`,
    data
  );
}

function rescan_data_leak(workspace_id: string) {
  return request.post<never, { success: true }>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/rescan`
  );
}

interface IDataLeakScanStatus {
  is_running: boolean;
  last_scan_time: number;
  last_scan_number: number;
  scanned_number: number;
  exported_number: number;
}

function retrieve_data_leak_scan_status(workspace_id: string) {
  return request.get<never, IDataLeakScanStatus>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/scan_status`
  );
}

export interface IDataLeakOverviewBySeverity {
  CRITICAL: number;
  HIGH: number;
  INFORMATION: number;
  LOW: number;
  MEDIUM: number;
  NONE: number;
}

export interface IDataLeakOverviewByStatus {
  new: number;
  triaged: number;
  resolved: number;
  unresolved: number;
  duplicate: number;
  rejected: number;
}

export interface IDataLeakOverviewByType {
  account: number;
  cookie: number;
  email: number;
  source_code: number;
}

export interface IDataLeakOverviewTopKeyword {
  id: number;
  count: number;
  keyword: string;
}

export interface IDataLeakOverview {
  by_severity: IDataLeakOverviewBySeverity;
  by_status: IDataLeakOverviewByStatus;
  by_type: IDataLeakOverviewByType;
  top_keywords: Array<IDataLeakOverviewTopKeyword>;
}

function overview_data_leak(
  workspace_id: string,
  params: {
    from: number | null;
    to: number | null;
  }
) {
  return request.get<never, IDataLeakOverview>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/overview`,
    { params }
  );
}

export interface IDataLeakOverviewKeyword {
  created_time: number;
  updated_time: number | null;
  current_plan: {
    name: {
      en: string;
      vi: string;
    };
  };
  id: number;
  keyword: string;
  is_domain: boolean;
  verification: boolean;
  asset_id: number | null;
}

export interface IDataLeakOverviewUpdates {
  last_scan_number: number;
  last_scan_time: number;
  need_renewal_keywords: number;
  unverified_keywords: number;
}

function overview_updates_data_leak(
  workspace_id: string,
  params: {
    from: number | null;
    to: number | null;
  }
) {
  return request.get<never, IDataLeakOverviewUpdates>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/overview/updates`,
    { params }
  );
}

function list_data_leak_overview_keywords(
  workspace_id: string,
  params: {
    from: number | null;
    to: number | null;
    q?: string;
  }
) {
  return request.get<never, IDataLeakOverviewKeyword[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/overview/keywords`,
    { params }
  );
}

function retrieve_data_leak_plan_existed(workspace_id: string) {
  return request.get<never, { existed: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/plan`
  );
}

function export_data_leak(workspace_id: string, types: string[]) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/export`,
    { types }
  );
}

const dataLeakService = {
  list_data_leak,
  update_leaked_data,
  statistics_data_leak,
  list_keywords,
  list_keywords_shortly,
  add_multiple_keywords,
  remove_multiple_keywords,
  request_data_leak_plan,
  retrieve_data_leak_scan_status,
  overview_data_leak,
  overview_updates_data_leak,
  list_data_leak_overview_keywords,
  rescan_data_leak,
  retrieve_data_leak_plan_existed,
  export_data_leak,
};

export default dataLeakService;
