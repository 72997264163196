import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import type { IEndpointPolicy } from "./types";
import type { IPaginatedResponse, IPaginateParams } from "#src/@types/api";

interface IListPolicyParams {
  sort: string | undefined;
  platform: string | undefined; // included but not limited by ["windows", "linux", "darwin", "chrome"]. Can be multiple strings joined with ","
  enabled: string | undefined;
  host_ids: string | undefined; // this is actually an array of numbers being joined with ","
  tag_ids: string | undefined; // this is actually an array of numbers being joined with ","
}

export async function listPolicies(
  workspaceId: string,
  params: IPaginateParams & IListPolicyParams
) {
  return await request.get<never, IPaginatedResponse<IEndpointPolicy>>(
    `${endpoint.WORKSPACES}/${workspaceId}/host_policies`,
    { params }
  );
}

export async function listPoliciesNoPaging(
  workspaceId: string,
  params: IListPolicyParams
) {
  return await request.get<never, IEndpointPolicy[]>(
    `${endpoint.WORKSPACES}/${workspaceId}/host_policies`,
    { params: { ...params, paging: 0 } }
  );
}

export async function retrieveDetailPolicy(
  workspaceId: string,
  policyId: string
) {
  return await request.get<never, IEndpointPolicy>(
    `${endpoint.WORKSPACES}/${workspaceId}/host_policies/${policyId}`
  );
}

export async function updatePolicy(
  workspaceId: string,
  policyId: string,
  data: { enabled: boolean; hosts_tags?: { id: string; type: string }[] }
) {
  return await request.put<never, { id: number }>(
    `${endpoint.WORKSPACES}/${workspaceId}/host_policies/${policyId}`,
    data
  );
}

export async function requestToComply(
  workspaceId: string,
  data: {
    ids: number[];
  }
) {
  return await request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspaceId}/host_policies/comply`,
    data
  );
}
