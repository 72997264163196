// Libraries
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

// General
import { TAILWIND_COLORS } from "#src/utils/common";
import type { IChartPlotProps } from "#src/@types/chart";

// Components
import DonutChart from "#src/common/charts/Donut";
import ChartLegends from "#src/common/helper/charts/legends";
import { getChartTooltip } from "#src/common/helper/charts/tooltip";

import { type IDataLeakOverviewBySeverity } from "#src/services/dataLeak";

interface IOverviewLeaksBySeverityProps {
  overviewBySeverity: IDataLeakOverviewBySeverity;
}

const OverviewLeaksBySeverity = ({
  overviewBySeverity,
}: IOverviewLeaksBySeverityProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "overview.leaksBySeverity",
  });
  const { t: tCommon } = useTranslation();

  const donutChartData: IChartPlotProps[] = useMemo(() => {
    return [
      {
        id: "critical",
        name: tCommon("severity.critical"),
        value: overviewBySeverity?.CRITICAL || 0,
        fill: TAILWIND_COLORS["severity-critical-DEFAULT"],
      },
      {
        id: "high",
        name: tCommon("severity.high"),
        value: overviewBySeverity?.HIGH || 0,
        fill: TAILWIND_COLORS["severity-high-DEFAULT"],
      },
      {
        id: "medium",
        name: tCommon("severity.medium"),
        value: overviewBySeverity?.MEDIUM || 0,
        fill: TAILWIND_COLORS["severity-medium-DEFAULT"],
      },
      {
        id: "low",
        name: tCommon("severity.low"),
        value: overviewBySeverity?.LOW || 0,
        fill: TAILWIND_COLORS["severity-low-DEFAULT"],
      },
      {
        id: "information",
        name: tCommon("severity.information"),
        value: overviewBySeverity?.INFORMATION || 0,
        fill: TAILWIND_COLORS["severity-information-DEFAULT"],
      },
      {
        id: "none",
        name: tCommon("severity.none"),
        value: overviewBySeverity?.NONE || 0,
        fill: TAILWIND_COLORS["severity-none-DEFAULT"],
      },
    ];
  }, [overviewBySeverity, tCommon]);

  return (
    <div className="rounded-md bg-bright-grey px-6 py-4 w-full flex flex-col gap-8">
      <h2 className="font-medium-20">{t("title")}</h2>
      <div className="flex items-center gap-12">
        <DonutChart
          data={donutChartData}
          tooltip={getChartTooltip}
          height={"10.25rem"}
          width={"10.25rem"}
          unit={t("ticket")}
        />
        <div className="flex flex-col items-center gap-3">
          <ChartLegends
            data={donutChartData}
            keyTemplate="trustCenter-control-overviewChart"
          />
        </div>
      </div>
    </div>
  );
};

export default OverviewLeaksBySeverity;
