import type { RouteObject } from "react-router-dom";
import i18next from "i18next";
import { pathname } from "#src/config/pathname";

import AllSolutionPage from "#src/pages/solutions";

const breadcrumbTFunc = i18next.getFixedT(null, "layout", "topbar.breadcrumb");

export const allSolutionRoute: RouteObject = {
  path: pathname.SOLUTIONS,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("solutions.label"),
      path: pathname.SOLUTIONS,
    }),
  },
  children: [
    {
      index: true,
      element: <AllSolutionPage />,
    },
  ],
};
