import { Check } from "@untitled-ui/icons-react";
import type { IFilterItemV2 } from "../Table/Filter/types";

export interface IDropdownItemRenderV2Props {
  item: IFilterItemV2;
  selected: IFilterItemV2 | null;
}

export const DropdownItemRenderV2 = ({
  item,
  selected,
}: IDropdownItemRenderV2Props) => {
  return (
    <div
      className={`flex p-2 pr-2.5 gap-2 items-center justify-between text-sm font-semibold min-w-[8rem] overflow-hidden text-ellipsis rounded-md text-gray-v2-700 ${item.key === selected?.key ? "bg-gray-v2-50" : "hover:bg-gray-v2-50"}`}
    >
      {item.getLabel()}
      {item.key === selected?.key ? (
        <Check className="h-5 w-5 text-brand-v2-600" />
      ) : (
        <div className="w-5" />
      )}
    </div>
  );
};
