// Libraries
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

// Resources
import { ReactComponent as ProfileLine } from "#src/assets/images/icons/profile-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import ConfigSectionWrapper from "#src/common/helper/wrapper/ConfigSection";

// General
import { pathname } from "#src/config/pathname";

interface ITrustCenterConfigProfileTabProfileProps {
  workspaceId: string;
}

const TrustCenterConfigProfileTabProfile = ({
  workspaceId,
}: ITrustCenterConfigProfileTabProfileProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.tab.profile.profile",
  });
  const navigate = useNavigate();

  return (
    <ConfigSectionWrapper
      icon={<ProfileLine className="h-7 w-7" />}
      headerTitle={t("title")}
      headerButton={
        <Button
          variant="secondary"
          onClick={() => {
            navigate(
              generatePath(pathname.WORKSPACE_DETAILS, {
                workspaceId,
              })
            );
          }}
        >
          <EditLine />
          {t("button.update")}
        </Button>
      }
    >
      <div className="p-6">
        <span className="font-regular-16-body text-hard-grey">
          {t("description")}
        </span>
      </div>
    </ConfigSectionWrapper>
  );
};

export default TrustCenterConfigProfileTabProfile;
