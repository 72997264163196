// Libraries
import i18next from "i18next";

// Resources
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";

// Components
import { IconButton } from "#src/common/system/Button";
import { TableDataCell } from "#src/common/Table";
import { TableRowWrapperDiv } from "#src/common/Table/TableRowWrapper";
import { ObjectImage } from "#src/common/system/Object";

// API-related
import type { ITrustCenterThirdPartyItem } from "#src/services/trustCenter/thirdParty";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";
import global from "#src/config/global";

interface ITrustCenterThirdPartyTableRowProps {
  data: ITrustCenterThirdPartyItem;
  workspaceRole: string | undefined;
  onDeleteService: (service: ITrustCenterThirdPartyItem) => void;
}

const TrustCenterThirdPartyTableRow = ({
  data,
  workspaceRole,
  onDeleteService,
}: ITrustCenterThirdPartyTableRowProps) => {
  const locale = i18next.language;

  return (
    <TableRowWrapperDiv>
      <TableDataCell>
        <ObjectImage
          data={data.logo}
          className="h-6 w-6 min-w-[1.5rem] rounded-md"
        />
        {data.name}
      </TableDataCell>
      <TableDataCell>
        {data.category
          ? locale === "vi"
            ? data.category.vi
            : data.category.en
          : null}
      </TableDataCell>
      <TableDataCell className="justify-center">
        {workspaceRole === WorkspaceMemberRoleValue.OWNER ||
        workspaceRole === WorkspaceMemberRoleValue.ADMIN ? (
          <IconButton
            size={9}
            variant={"warning"}
            onClick={() => {
              global.confirm(() => onDeleteService(data));
            }}
          >
            <DeleteBin6Line height={"1.25rem"} width={"1.25rem"} />
          </IconButton>
        ) : (
          <IconButton variant="ghost" size={9} disabled>
            <MoreLine />
          </IconButton>
        )}
      </TableDataCell>
    </TableRowWrapperDiv>
  );
};

export default TrustCenterThirdPartyTableRow;
