// Libraries
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/common/helper/PaginationBar";
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import scansServices, { type IScanItem } from "#src/services/scans";
import assetsServices from "#src/services/assets";

// Children
import ScanFilter from "./Filter";
import ScanTableGrid from "./Table";
import AddScanDrawer from "../AddScanDrawer";

interface IScanTableProps {
  workspaceId: string;
  openAddScanDrawer: boolean;
  setOpenAddScanDrawer: Dispatch<SetStateAction<boolean>>;
}

const ScanTable = ({
  workspaceId,
  openAddScanDrawer,
  setOpenAddScanDrawer,
}: IScanTableProps) => {
  const { t } = useTranslation("scans", { keyPrefix: "page.allScans" });

  // Domain list to support the filter's domain choices
  const [domainList, setDomainList] = useState<IFilterItem[]>([]);

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const [domains, setDomains] = useState<IFilterItem | null>(null);
  const [status, setStatus] = useState<IFilterItem | null>(null);
  const [schedule, setSchedule] = useState<IFilterItem | null>(null);
  // const [vulnerable, setVulnerable] = useState<IFilterItem | null>(null);
  const [selectedScans, setSelectedScans] = useState<IScanItem[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);

  const fetchParams = useMemo<Parameters<typeof scansServices.list_scans>>(
    () => [
      workspaceId,
      {
        from: from ? from : undefined,
        to: to ? to : undefined,
        domain_id: domains?.value,
        status: status?.value,
        is_schedule: schedule?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      from,
      to,
      domains,
      status,
      schedule,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangeTimeConditionValue = (
    condition: string,
    from: number,
    to: number
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
    setSelectedPage(1);
  };

  const onChangeDomainsSelection = (selected: typeof domains) => {
    setDomains(selected);
    setSelectedPage(1);
  };

  const onChangeStatusSelection = (selected: typeof status) => {
    setStatus(selected);
    setSelectedPage(1);
  };

  const onChangeScheduleSelection = (selected: typeof schedule) => {
    setSchedule(selected);
    setSelectedPage(1);
  };

  // const onChangeVulnerableSelection = (selected: typeof schedule) => {
  //   setVulnerable(selected);
  //   setSelectedPage(1);
  // };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
    setSelectedScans([]);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedScans([]);
    setSelectedPage(1);
  };

  const onChangeSelectedScans = (selected: IScanItem) => {
    if (selectedScans.map((item) => item.id).includes(selected.id)) {
      setSelectedScans((prev) => [
        ...prev.filter((item) => item.id !== selected.id),
      ]);
    } else {
      setSelectedScans((prev) => [...prev, selected]);
    }
  };

  const onSelectAllScans = () => {
    if (scanList) {
      setSelectedScans(scanList);
    }
  };

  const onDeselectAllScans = () => {
    setSelectedScans([]);
  };

  const onDeleteSelectedScans = () => {
    // This has no API, we have to delete every single scans
    Promise.allSettled(
      selectedScans.map((scan) => {
        return scansServices.delete_scan(workspaceId, scan.id);
      })
    ).then(() => {
      setSelectedScans([]);
      updateScanList();
    });
  };

  const onRescanSelectedScans = () => {
    // This has no API, we have to rescan every single scans
    Promise.allSettled(
      selectedScans.map(async (scanItem) => {
        return scansServices
          .create_new_scan(workspaceId, {
            domains: scanItem.scan_profile.assets.map((domain) => ({
              domain_id: +domain.asset_id,
              include_subdomains: domain.include_subdomains,
            })),
            schedule: scanItem.scan_profile.schedule,
            activated_time:
              scanItem.scan_profile.activated_time || dayjs().unix(),
            timezone: scanItem.scan_profile.timezone,
            duration_type: scanItem.scan_profile.duration_type,
            repeat_number: scanItem.scan_profile.repeat_number,
            days: scanItem.scan_profile.days,
          })
          .then((response) => {
            if (response.success) {
              // TODO: move this to the resolve of allSettled
              updateScanList();
            }
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("notification.rescan.fail"),
            });
          });
      })
    ).then(() => {
      setSelectedScans([]);
      updateScanList();
    });
  };

  // Fetch domain list
  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      assetsServices
        .list_domains_shortly(workspaceId, {
          from: undefined,
          to: undefined,
          verification: 1,
          vulnerable: undefined,
          q: undefined,
        })
        .then((response) => {
          setDomainList([
            ...response.map((item) => ({
              key: item.id.toString(),
              value: item.id.toString(),
              getLabel: () => item.detail.address.toString(),
            })),
            {
              key: "all",
              value: undefined,
              getLabel: () => t("filter.domains.all"),
            },
          ]);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadDomainList.fail"),
          });
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, t]);

  const {
    list: scanList,
    count: scanCount,
    isLoading,
    updateData: updateScanList,
  } = useFetchPaginated(scansServices.list_scans, fetchParams);

  return (
    <>
      <ScanFilter
        // vulnerable={vulnerable}
        domains={domains}
        status={status}
        schedule={schedule}
        searchKeyword={searchKeyword}
        totalCount={scanList ? scanList.length : 0}
        selectedCount={selectedScans.length}
        scannable={selectedScans.some(
          (item) =>
            item.scan_profile.schedule === false || item.status === "stopped"
        )}
        domainItems={domainList}
        onChangeTimeConditionValue={onChangeTimeConditionValue}
        onChangeDomainsSelection={onChangeDomainsSelection}
        onChangeStatusSelection={onChangeStatusSelection}
        onChangeScheduleSelection={onChangeScheduleSelection}
        // onChangeVulnerableSelection={onChangeVulnerableSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
        onSelectAllScans={onSelectAllScans}
        onDeselectAllScans={onDeselectAllScans}
        onDeleteSelectedScans={onDeleteSelectedScans}
        onRescanSelectedScans={onRescanSelectedScans}
      />
      {scanList === null || isLoading ? (
        <LoadingState />
      ) : scanCount === 0 ? (
        <NoResultsState />
      ) : (
        <ScanTableGrid
          workspaceId={workspaceId}
          scans={scanList}
          selectedScanIds={selectedScans.map((item) => item.id)}
          onChangeSelectedScans={onChangeSelectedScans}
          refreshScanList={updateScanList}
        />
      )}
      <PaginationBar
        numOfResult={scanCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
      <AddScanDrawer
        open={openAddScanDrawer}
        onClose={() => {
          setOpenAddScanDrawer(false);
        }}
        workspaceId={workspaceId}
        onRefresh={updateScanList}
      />
    </>
  );
};

export default ScanTable;
