// Libraries
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Tooltip } from "@lockerpm/design";

// Resources
import { ReactComponent as Repeat2Line } from "#src/assets/images/icons/repeat-2-line.svg";
import { ReactComponent as BankCardLine } from "#src/assets/images/icons/bank-card-line.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";
import { ReactComponent as ExportLine } from "#src/assets/images/icons/export-line.svg";

// Components
import { SourceLabel } from "#src/common/helper/labels/SourceLabel";
import { StatusLabel } from "#src/common/helper/labels/StatusLabel";
import TableDataCell from "#src/common/Table/TableDataCell";
import { TableRowWrapperDiv } from "#src/common/Table/TableRowWrapper";
import { IconButton } from "#src/common/system/Button";

// API-related
import {
  PaymentMethodValue,
  PaymentStatusValue,
} from "#src/config/filter/workspace/value";
import { expandPlanDuration } from "#src/utils/apiHelper";
import paymentService from "#src/services/payment";

interface IBillingOrderTableRowProps {
  data: Awaited<
    ReturnType<typeof paymentService.list_payments>
  >["results"][number];
}

const BillingOrderTableRow = ({ data }: IBillingOrderTableRowProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.tab.order",
  });

  const locale = i18next.language;

  const findRemappedDuration = expandPlanDuration(data);

  return (
    <TableRowWrapperDiv>
      <TableDataCell>
        <Tooltip title={data.payment_id}>
          <div>
            <SourceLabel noEllipsis>{data.payment_id.slice(-6)}</SourceLabel>
          </div>
        </Tooltip>
      </TableDataCell>
      <TableDataCell>
        {locale === "vi" ? data.product.name.vi : data.product.name.en}
      </TableDataCell>
      <TableDataCell>
        {locale === "vi"
          ? data.product_plan.name.vi
          : data.product_plan.name.en}
      </TableDataCell>
      <TableDataCell>
        {findRemappedDuration ? findRemappedDuration.getSpan() : data.duration}
      </TableDataCell>
      <TableDataCell>
        {data.sub_total.toLocaleString() + " " + data.currency}
      </TableDataCell>
      <TableDataCell>
        {data.paid_time
          ? dayjs.unix(data.paid_time).format("HH:mm DD.MM.YYYY")
          : "-"}
      </TableDataCell>
      <TableDataCell>
        {data.payment_method === PaymentMethodValue.BANKING ? (
          <StatusLabel
            name={t("labels.paymentMethod.banking")}
            color="bg-support-blue-pastel text-support-blue"
            icon={<Repeat2Line className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : data.payment_method === PaymentMethodValue.CARD ? (
          <StatusLabel
            name={t("labels.paymentMethod.card")}
            color="bg-white text-support-blue outline outline-1 outline-support-blue"
            icon={<BankCardLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : null}
      </TableDataCell>
      <TableDataCell>
        {data.status === PaymentStatusValue.PAID ? (
          <StatusLabel
            name={t("labels.paymentStatus.paid")}
            color="bg-support-seafoam-pastel text-support-seafoam"
            icon={<CheckboxCircleLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : data.status === PaymentStatusValue.PROCESSING ? (
          <StatusLabel
            name={t("labels.paymentStatus.processing")}
            color="bg-support-fire-pastel text-policy-expire-in30days"
            icon={<TimeLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : null}
      </TableDataCell>
      <TableDataCell className="p-0 justify-center">
        {/* TODO: download invoice. */}
        <IconButton variant="secondary">
          <ExportLine className="h-5 w-5 min-w-[1.25rem]" />
        </IconButton>
      </TableDataCell>
    </TableRowWrapperDiv>
  );
};

export default BillingOrderTableRow;
