import { useState } from "react";
import { IFilterItem } from "#src/@types/common";
import { ButtonV2 } from "#src/commonV2/Button";
import { softwareVulnerableItems } from "#src/config/filterConstants";
import { Input, Popover } from "@lockerpm/design";
import { SearchLg, Plus, Check } from "@untitled-ui/icons-react";
import { useTranslation } from "react-i18next";

type Props = {
  searchKeyword: string;
  setSearchKeyword: (keyword: string) => void;
  setSelectedPage: (page: number) => void;
  vulnerable: IFilterItem | null;
  setVulnerable: (vulnerable: IFilterItem | null) => void;
};

export const SoftwareFilter = (props: Props) => {
  const {
    searchKeyword,
    setSearchKeyword,
    setSelectedPage,
    setVulnerable,
    vulnerable,
  } = props;
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // ----------------------------- PARAMS -----------------------------

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  // ----------------------------- RENDER -----------------------------

  return (
    <div className="flex flex-col sm:flex-row justify-between gap-2">
      <div>
        <Popover
          trigger="click"
          placement="bottomLeft"
          open={isFilterOpen}
          onOpenChange={setIsFilterOpen}
          content={
            <div style={{ margin: -6 }}>
              {softwareVulnerableItems.map((item) => (
                <div
                  key={item.key}
                  onClick={() => {
                    setVulnerable(item);
                    setSelectedPage(1);
                    setIsFilterOpen(false);
                  }}
                  className="cursor-pointer px-2.5 py-2 rounded-md hover:bg-gray-v2-50 transition-colors flex justify-between gap-2 items-center min-w-40"
                >
                  <p
                    className={`text-sm font-semibold ${vulnerable?.key === item.key ? "text-gray-v2-800" : "text-gray-v2-500"}`}
                  >
                    {item.getLabel()}
                  </p>
                  {vulnerable?.key === item.key && (
                    <Check className="h-5 text-brand-v2-700" />
                  )}
                </div>
              ))}
            </div>
          }
        >
          <ButtonV2
            variant="secondary"
            color="gray"
            size="md"
            onClick={() => {
              setIsFilterOpen(!isFilterOpen);
            }}
          >
            <p>{t("software.vulnerabilities")}</p>
            {vulnerable && vulnerable.key !== "all" ? (
              <p className="font-semibold text-sm text-brand-v2-700">
                {vulnerable?.getLabel()}
              </p>
            ) : (
              <Plus className="h-5 text-gray-v2-700" />
            )}
          </ButtonV2>
        </Popover>
      </div>

      <Input
        value={searchKeyword}
        onChange={(e) => {
          setSearchKeyword(e.target.value);
          setSelectedPage(1);
        }}
        placeholder={t("common.search")}
        className="h-10 rounded-lg text-md text-gray-v2-900"
        prefix={<SearchLg className="text-gray-v2-500 h-4" />}
        style={{
          width: 320,
          maxWidth: "100%",
        }}
      />
    </div>
  );
};
