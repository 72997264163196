import { useMemo, useState } from "react";
import { FilterDropdownV2 } from "./FilterDropdown";
import { IFilterItemV2 } from "./types";
import { useSearchParams } from "react-router-dom";

interface IUseFilterProps {
  label: string;
  selectionList: IFilterItemV2[];
  options?: {
    searchParamKey?: string;
    sideEffectOnChange?: Function;
    hiddenFilter?: boolean;
  };
}

export const useFilter = ({
  label,
  selectionList,
  options,
}: IUseFilterProps): { value: string | undefined; Render: JSX.Element } => {
  const [selectedState, setSelectedState] = useState<IFilterItemV2 | null>(
    null
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeSelection = (newSelection: IFilterItemV2 | null) => {
    setSelectedState(newSelection);
    setSearchParams((params) => {
      if (options && options.searchParamKey) {
        if (newSelection && newSelection.value) {
          params.set(options.searchParamKey, newSelection.value);
        } else {
          params.delete(options.searchParamKey);
        }
      }
      return params;
    });
    if (options && options.sideEffectOnChange) {
      options.sideEffectOnChange();
    }
  };

  const finalSelected: IFilterItemV2 | null = useMemo(() => {
    if (options && options.searchParamKey) {
      const searchParamValue = searchParams.get(options.searchParamKey);
      const findItem = selectionList.find((item) => {
        return item.value === searchParamValue;
      });

      if (findItem) return findItem;
      if (searchParamValue)
        return {
          key: searchParamValue,
          value: searchParamValue,
          getLabel: () => searchParamValue,
        };
      return null;
    }
    return selectedState;
  }, [options, searchParams, selectionList, selectedState]);

  const Render = (
    <FilterDropdownV2
      type={options && options.hiddenFilter ? "hidden" : "normal"}
      label={label}
      options={selectionList}
      selected={finalSelected}
      onChangeSelection={onChangeSelection}
    />
  );

  return { value: finalSelected?.value, Render };
};
