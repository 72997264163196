import { useTranslation } from "react-i18next";
import { MarkdownEditor } from "#src/common/system/Input/MarkdownEditor";

interface IAddBugBountyDescriptionProps {
  description: string;
  onChangeDescription: (value: string) => void;
  disabled?: boolean;
}

const AddBugBountyDescription = ({
  description,
  onChangeDescription,
  disabled = false,
}: IAddBugBountyDescriptionProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.addBugBounty.description",
  });

  return (
    <div className="flex flex-col gap-4 p-6">
      <span className="font-medium-18">{t("title")}</span>
      {disabled ? null : (
        <span className="text-hard-grey">{t("description")}</span>
      )}
      {/* TODO: add description in multiple languages */}
      {disabled ? (
        <span>{description ? description : "N/A"}</span>
      ) : (
        <MarkdownEditor
          className="font-regular-14-body"
          value={description}
          onChange={(e) => {
            onChangeDescription(e.currentTarget.value);
          }}
          rows={5}
          placeholder={t("placeholder")}
        />
      )}
    </div>
  );
};

export default AddBugBountyDescription;
