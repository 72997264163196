// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as Filter2Line } from "#src/assets/images/icons/filter-2-line.svg";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import { IconButton } from "#src/common/system/Button";
import { FilterDropdown, SearchBox } from "#src/common/Table/Filter";

// Children
import { protocolItems } from "#src/config/filter/asset";

interface IPortFilterProps {
  searchKeyword: string;
  protocol: IFilterItem | null;
  onChangeProtocolSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
}

const PortFilter = ({
  searchKeyword,
  protocol,
  onChangeProtocolSelection,
  onChangeSearchKeyword,
}: IPortFilterProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.ports.filter",
  });

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <IconButton disabled>
          <Filter2Line />
        </IconButton>
        <FilterDropdown
          label={t("protocol.label")}
          selected={protocol}
          options={protocolItems}
          onChangeSelection={onChangeProtocolSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default PortFilter;
