// Libraries
import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { Dropdown } from "@lockerpm/design";

// General
import patterns from "#src/config/patterns";
import { pathname } from "#src/config/pathname";
import { useAppDispatch } from "#src/store/hooks";
import { dataLeakDispatchAction } from "#src/store/slices/dataLeak";

// Components
import { Input } from "#src/common/system/Input";
import { Button } from "#src/common/system/Button";
import Loader from "#src/common/system/Loader";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { createToast } from "#src/common/system/toasts";

// Resources
import { ReactComponent as SealWarningFill } from "#src/assets/images/icons/seal-warning-fill.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";

// API-related
import paymentService from "#src/services/payment";
import assetsServices from "#src/services/assets";

// Children
import { durationItems, productPlanItems } from "./enum";
import KeywordCard, { type IKeywordPricingItem } from "./KeywordCard";

interface IDataLeakAddKeywordFindAndSelectProps {
  workspaceId: string;
}

const ErrorMessage = ({
  errorMessage,
}: {
  errorMessage: string | undefined;
}) => {
  return errorMessage ? (
    <span className="font-medium-14-forced text-warning flex items-center gap-1">
      <InformationLine className="h-5 w-5" />
      {errorMessage}
    </span>
  ) : (
    <></>
  );
};

const DataLeakAddKeywordFindAndSelect = ({
  workspaceId,
}: IDataLeakAddKeywordFindAndSelectProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "addKeyword.findAndSelect",
  });

  const { t: commonT } = useTranslation("common", {
    keyPrefix: "state.noData",
  });

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [keywordSearch, setKeywordSearch] = useState<string>("");
  const [selectedKeywords, setSelectedKeywords] = useState<
    IKeywordPricingItem[]
  >([]);
  const [isLoadingCalc, setLoadingCalc] = useState<boolean>(false);
  const [estimateTotal, setEstimateTotal] = useState<number>(0);
  const [currency, setCurrency] = useState<string>("");
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [suggestionKeywords, setSuggestionKeywords] = useState<
    Array<{ value: string; verification: boolean }>
  >([]);
  const [suggestionKeywordPage, setSuggestionKeywordPage] = useState<number>(1);
  const [suggestionKeywordTotal, setSuggestionKeywordTotal] = useState<number>(0);

  const onRecalculate = useCallback(
    (newList: typeof selectedKeywords) => {
      setLoadingCalc(true);
      paymentService
        .calculate_pricing(workspaceId, {
          currency: "VND",
          product_plans: newList.map((item) => ({
            product_plan_id: item.productPlanItem.value,
            duration: item.durationItem.value,
            keyword: item.keyword,
          })),
        })
        .then((response) => {
          setSelectedKeywords((prev) =>
            prev.map((item) => {
              const matchedPlanResponse = response.product_plans.find(
                (planItem) =>
                  planItem.meta.workspace_keyword.keyword === item.keyword
              );
              if (matchedPlanResponse)
                return {
                  ...item,
                  price:
                    matchedPlanResponse.sub_total.toLocaleString() +
                    " " +
                    matchedPlanResponse.currency,
                  verification:
                    matchedPlanResponse.meta.workspace_keyword.verification,
                } satisfies (typeof selectedKeywords)[number];
              return item;
            })
          );
          setEstimateTotal(response.total_price);
          setCurrency(response.currency);
        })
        .catch(apiErrorHandler)
        .finally(() => {
          setLoadingCalc(false);
        });
    },
    [workspaceId]
  );

  const onSelectNewKeyword = (selected: string) => {
    if (selectedKeywords.map((item) => item.keyword).includes(selected)) {
      createToast({
        type: "warning",
        message: t("keywordSearch.notification.duplicatedKeyword.message"),
        detail: t("keywordSearch.notification.duplicatedKeyword.detail"),
      });
      return;
    }

    const newSelectedList: typeof selectedKeywords = [...selectedKeywords];
    if (patterns.DOMAIN.test(selected)) {
      newSelectedList.push({
        productPlanItem: productPlanItems[0],
        durationItem: durationItems[0],
        keyword: selected,
        price: undefined,
        verification: undefined,
      });
    } else {
      newSelectedList.push({
        productPlanItem: productPlanItems[2],
        durationItem: durationItems[0],
        keyword: selected,
        price: undefined,
        verification: undefined,
      });
    }
    setSelectedKeywords(newSelectedList);
    onRecalculate(newSelectedList);
  };

  const onRemoveKeyword = (selected: string) => {
    const newSelectedList: typeof selectedKeywords = selectedKeywords.filter(
      (item) => item.keyword !== selected
    );
    setSelectedKeywords(newSelectedList);
    onRecalculate(newSelectedList);
  };

  const onChangeKeywordInfo = (
    changedKeyword: (typeof selectedKeywords)[number]
  ) => {
    const newKeywordList = selectedKeywords.map((item) =>
      item.keyword === changedKeyword.keyword
        ? ({
            ...item,
            productPlanItem: changedKeyword.productPlanItem,
            durationItem: changedKeyword.durationItem,
          } satisfies (typeof selectedKeywords)[number])
        : item
    );

    setSelectedKeywords(newKeywordList);
    onRecalculate(newKeywordList);
  };

  const onClickContinue = () => {
    if (estimateTotal === 0) {
      paymentService
        .create_subscription_banking(workspaceId, {
          product_plans: selectedKeywords.map((item) => ({
            product_plan_id: item.productPlanItem.value,
            duration: item.durationItem.value,
            keyword: item.keyword,
          })),
        })
        .then(() => {
          navigate(
            generatePath(pathname.DATA_LEAK_OVERVIEW, {
              workspaceId,
            })
          );
          dispatch(dataLeakDispatchAction.updatePlan(true));
        })
        .catch(apiErrorHandler);
    } else {
      navigate(
        generatePath(pathname.DATA_LEAK_ADD_KEYWORD_PAYMENT, {
          workspaceId,
        }),
        {
          state: selectedKeywords.map((item) => ({
            product_plan_id: item.productPlanItem.value,
            duration: item.durationItem.value,
            keyword: item.keyword,
          })),
        }
      );
    }
  };

  useEffect(() => {
    const parsedKeywordState = z
      .array(
        z.object({
          product_plan_id: z.string(),
          duration: z.string(),
          keyword: z.string(),
        })
      )
      .safeParse(state);

    if (parsedKeywordState.success) {
      const newList = parsedKeywordState.data.map((item) => {
        const matchedPlan = productPlanItems.find(
          (p) => p.value === item.product_plan_id
        );
        const matchedDuration = durationItems.find(
          (d) => d.value === item.duration
        );

        return {
          productPlanItem: matchedPlan ? matchedPlan : productPlanItems[0],
          durationItem: matchedDuration ? matchedDuration : durationItems[0],
          keyword: item.keyword,
          price: undefined,
          verification: undefined,
        };
      });
      setSelectedKeywords(newList);
      onRecalculate(newList);
    }
  }, [state, onRecalculate]);

  const fetchSuggestionKeywords = useCallback(
    async (
      searchText: string,
      page: number,
      prevKeywords: Array<{ value: string; verification: boolean }> = []
    ) => {
      await assetsServices
        .list_domains(workspaceId, {
          page: page,
          size: 10,
          q: searchText,
          is_domain: undefined,
          verification: undefined,
          from: undefined,
          to: undefined,
          vulnerable: undefined,
          sort: "verification_desc",
        })
        .then((response) => {
          setSuggestionKeywordTotal(response.count);
          setSuggestionKeywords(
            [
              ...prevKeywords,
              ...response.results.map((r) => ({
                value: r.detail.address,
                verification: r.verification,
              }))
            ]
          );
        })
        .catch(() => {
          setSuggestionKeywords([]);
        });
    },
    [workspaceId]
  );

  useEffect(() => {
    fetchSuggestionKeywords("", 1, []);
  }, [fetchSuggestionKeywords]);

  const isKeywordAlready = useMemo(() => {
    return !!selectedKeywords.find((k) => k.keyword === keywordSearch);
  }, [selectedKeywords, keywordSearch]);

  const filteredSuggestionKeywords = useMemo(() => {
    return suggestionKeywords
      .filter((keyword) => !selectedKeywords.find((k) => k.keyword === keyword.value))
  }, [suggestionKeywords, selectedKeywords])

  return (
    <div className="p-12 flex flex-col gap-12 justify-center border border-light-grey rounded-md">
      <div className="flex flex-col gap-4 items-center text-center">
        <h2>{t("keywordSearch.enterKeywordToStart")}</h2>
        <div className="text-hard-grey flex gap-0.5">
          <span className="text-primary">*</span>
          {t("keywordSearch.freePlanNote")}
        </div>
        <div className="text-hard-grey flex gap-0.5">
          <span className="text-primary">*</span>
          {t("keywordSearch.nondomainKeywordNote")}
        </div>
      </div>
      <form
        className="flex gap-1 justify-center"
        onSubmit={(e) => {
          e.preventDefault();
          onSelectNewKeyword(keywordSearch);
          setKeywordSearch("");
        }}
      >
        <Dropdown
          menu={{
            items: filteredSuggestionKeywords.slice(0, 3).map((keyword) => ({
              key: keyword.value,
              label: (
                <div className="p-3 w-full flex items-center gap-1.5">
                  <p className="font-medium-16 text-grey-blue">
                    {keyword.value}
                  </p>
                  {!keyword.verification && (
                    <SealWarningFill className="h-5 w-5 fill-severity-medium" />
                  )}
                </div>
              ),
              style: {
                borderRadius: 0,
              },
            })),
            onClick: (obj) => {
              onSelectNewKeyword(obj.key);
              // When length = 4 if select 1 item then length = 3 -> Calling api to list items without interruption -> trickCount = 4
              const trickCount = 4;
              if (filteredSuggestionKeywords.length === trickCount && (suggestionKeywordTotal / 10) > suggestionKeywordPage) {
                setSuggestionKeywordPage(suggestionKeywordPage + 1);
                fetchSuggestionKeywords(keywordSearch, suggestionKeywordPage + 1, suggestionKeywords);
              }
            },
            style: { width: "100%" },
          }}
          placement="bottomLeft"
          trigger={["click"]}
          arrow={true}
          open={dropdownOpen}
          dropdownRender={(menu) => (
            <div className="w-[15rem] bg-white rounded shadow-[0_6px_16px_0_rgba(0,_0,_0,_0.08),0_3px_6px_-4px_rgba(0,_0,_0,_0.12),0_9px_28px_8px_rgba(0,_0,_0,_0.05)]">
              <div className="px-3 py-2 bg-bright-grey">
                <p className="uppercase font-medium-14-forced text-medium-grey">
                  {t("keywordSearch.suggestedKeywords")}
                </p>
              </div>
              {menu}
              {filteredSuggestionKeywords.length === 0 && (
                <div className="p-3 w-full">
                  <p className="font-medium-16 text-grey-blue">
                    {commonT("thereAreNoData")}
                  </p>
                </div>
              )}
            </div>
          )}
          onOpenChange={() => {
            setDropdownOpen(false)
          }}
        >
          <div className="flex flex-col gap-2">
            <Input
              className="w-[32rem]"
              placeholder={t("keywordSearch.input.placeholder")}
              value={keywordSearch}
              onClick={() => {
                setTimeout(() => {
                  setDropdownOpen(true)
                }, 100);
              }}
              onChange={(e) => {
                setDropdownOpen(true);
                setKeywordSearch(e.target.value);
                fetchSuggestionKeywords(e.target.value, 1, []);
              }}
            />
            {isKeywordAlready && (
              <ErrorMessage errorMessage={t("keywordSearch.keywordAlready")} />
            )}
          </div>
        </Dropdown>
        <Button
          size="large"
          type="submit"
          disabled={keywordSearch.length === 0 || isKeywordAlready}
        >
          {t("keywordSearch.button.select")}
        </Button>
      </form>
      {selectedKeywords.length > 0 ? (
        <>
          <div className="grid grid-cols-3 gap-6">
            {selectedKeywords.map((item) => (
              <KeywordCard
                key={`selectedKeyword-${item.keyword}`}
                data={item}
                onRemoveKeyword={onRemoveKeyword}
                onChangeKeywordInfo={onChangeKeywordInfo}
              />
            ))}
          </div>
          <div className="flex items-center justify-center gap-12">
            <div className="flex items-center gap-6 font-bold-24">
              <span>{t("selectedKeywords.estimatePayment.label")}</span>
              <span className="text-primary min-w-[13rem] flex justify-center">
                {isLoadingCalc ? (
                  <Loader baseSize={1.75} />
                ) : (
                  estimateTotal.toLocaleString() + " " + currency
                )}
              </span>
            </div>
            <Button size="large" onClick={onClickContinue}>
              {t("selectedKeywords.estimatePayment.button.continue")}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DataLeakAddKeywordFindAndSelect;
