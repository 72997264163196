import { useTranslation } from "react-i18next";
import type { IFilterItem } from "#src/@types/common";
import { FilterDropdown, SearchBox } from "#src/common/Table/Filter";
import { complianceStatusItems } from "#src/config/filter/trustCenter";

interface ITrustCenterComplianceFilterProps {
  complianceStatus: IFilterItem | null;
  searchKeyword: string;
  onChangeComplianceStatusSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
}

const TrustCenterComplianceFilter = ({
  complianceStatus,
  searchKeyword,
  onChangeComplianceStatusSelection,
  onChangeSearchKeyword,
}: ITrustCenterComplianceFilterProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "compliance.filter",
  });

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 whitespace-nowrap flex-wrap">
        <FilterDropdown
          label={t("status.label")}
          selected={complianceStatus}
          options={complianceStatusItems}
          onChangeSelection={onChangeComplianceStatusSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default TrustCenterComplianceFilter;
