import { useTranslation } from "react-i18next";
import { ReactComponent as FileCopyLine } from "#src/assets/images/icons/file-copy-line.svg";
import { IconButton, type IconButtonVariant } from "./Button";
import { createToast } from "./toasts";

interface ICopyToClipboardProps {
  value: string | undefined;
  variant?: IconButtonVariant;
  inline?: boolean;
}

export const CopyToClipboardButton = ({
  value,
  variant,
  inline,
}: ICopyToClipboardProps) => {
  const { t } = useTranslation("common", { keyPrefix: "copyToClipboard" });

  const onClickCopy = () => {
    if (value) {
      navigator.clipboard.writeText(value);
      createToast({
        type: "info",
        message: t("copied.message"),
        detail: value,
      });
    } else {
      createToast({
        type: "info",
        message: t("cannotCopy.message"),
        detail: t("cannotCopy.description"),
      });
    }
  };

  return inline ? (
    <button onClick={onClickCopy} className="text-hard-grey">
      <FileCopyLine />
    </button>
  ) : (
    <IconButton
      variant={variant ? variant : "label-blue"}
      onClick={onClickCopy}
    >
      <FileCopyLine />
    </IconButton>
  );
};
