import { ObjectImage } from "#src/common/system/Object";
import { IEndpointDevice } from "#src/services/endpoint/devices/types";
import WindowsPcIllustration from "#src/assets/images/v2/windows-pc-illustration.png";
import MacPcIllustration from "#src/assets/images/v2/mac-pc-illustration.png";
import LinuxPcIllustration from "#src/assets/images/v2/linux-pc-illustration.png";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { ButtonV2 } from "#src/commonV2/Button";
import { ArrowUpRight } from "@untitled-ui/icons-react";
import { byteToGigabyte } from "#src/utils/common";
import { DateTime } from "luxon";
import i18next from "i18next";
import { Typography } from "@lockerpm/design";
import { ReactComponent as LogoWindows10 } from "#src/assets/images/logos/logo-windows-10.svg";
import { ReactComponent as LogoApple } from "#src/assets/images/logos/logo-apple.svg";
import { ReactComponent as LogoUbuntu } from "#src/assets/images/logos/logo-ubuntu.svg";
import { ReactNode } from "react";
import { pathname } from "#src/config/pathname";

const iconByOs: { [os: string]: ReactNode } = {
  windows: <LogoWindows10 className="h-6 w-6" />,
  macos: <LogoApple className="h-6 w-6" />,
  linux: <LogoUbuntu className="h-6 w-6" />,
};

const getOsIcon = (value: string) => {
  if (Object.keys(iconByOs).includes(value)) return iconByOs[value];
  return null;
};

const illustrationByOs: { [os: string]: string } = {
  windows: WindowsPcIllustration,
  macos: MacPcIllustration,
  linux: LinuxPcIllustration,
};

const getOsIllustration = (value: string) => {
  if (Object.keys(illustrationByOs).includes(value))
    return illustrationByOs[value];
  return null;
};

export const EndpointDeviceTableRowExpandContent = ({
  workspaceId,
  item,
}: {
  workspaceId: string;
  item: IEndpointDevice;
}) => {
  const { t } = useTranslation("endpoint", {
    keyPrefix: "devices.table.expandContent",
  });
  const locale = i18next.language;

  return (
    <div className="px-12 py-4 flex flex-col items-center gap-2">
      <div className="flex items-center gap-12 w-full">
        <div className="flex flex-col items-center text-center gap-1.5 w-[7.25rem] relative">
          {item.os && item.os.family ? (
            <>
              <ObjectImage
                data={getOsIllustration(item.os.family)}
                className="h-[6rem] w-[7.25rem]"
              />
              <div
                className="absolute h-10 w-10 top-1/3 left-0 bg-gradient-to-b from-[rgba(243,_252,_255,_0.75)] to-[rgba(179,_200,_217,_0.75)] rounded-full
            border-2 border-gray-v2-50 shadow-xs flex items-center justify-center"
              >
                {getOsIcon(item.os.family)}
              </div>
            </>
          ) : null}
          <Typography.Text
            ellipsis={{
              tooltip: (
                <span className="text-sm font-medium ">{item.name}</span>
              ),
            }}
          >
            <span className="text-sm font-medium">{item.name}</span>
          </Typography.Text>
        </div>
        <div className="grid grid-cols-2 gap-2 pr-6 border-r last:border-0 border-gray-v2-200 flex-1 text-xs">
          <span className="text-gray-v2-600">{t("deviceId")}</span>
          <span className="font-medium text-gray-v2-900">{item.id}</span>
          <span className="text-gray-v2-600">{t("agentVersion")}</span>
          <span className="font-medium text-gray-v2-900">
            {item.system && item.system.cystack_version
              ? item.system.cystack_version
              : "---"}
          </span>
          <span className="text-gray-v2-600">{t("lastUserLogin")}</span>
          <span className="font-medium text-gray-v2-900">
            {item.last_login_at
              ? DateTime.fromMillis(item.last_login_at)
                  .setLocale(locale)
                  .toRelative()
              : "---"}
          </span>
          <span className="text-gray-v2-600">{t("lastLocation")}</span>
          <span className="font-medium text-gray-v2-900">
            {item.location && item.location.city_country
              ? item.location.city_country
              : "---"}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-2 border-r last:border-0 border-gray-v2-200 flex-1 text-xs">
          <span className="text-gray-v2-600">{t("diskEncryption.title")}</span>
          <span className="font-medium text-gray-v2-900">
            {item.system && item.system.disk_encryption_enabled !== null
              ? item.system.disk_encryption_enabled
                ? t("diskEncryption.on")
                : t("diskEncryption.off")
              : "---"}
          </span>
          <span className="text-gray-v2-600">{t("diskSpace.title")}</span>
          <span className="font-medium text-gray-v2-900">
            {item.system && item.system.gigs_disk_space_available
              ? item.system.gigs_disk_space_available +
                " " +
                t("diskSpace.gbAvailable")
              : "---"}
          </span>
          <span className="text-gray-v2-600">{t("memory")}</span>
          <span className="font-medium text-gray-v2-900">
            {item.system && item.system.memory
              ? byteToGigabyte(item.system.memory).toFixed(1) + " GB"
              : "---"}
          </span>
          <span className="text-gray-v2-600">{t("macAddress")}</span>
          <span className="font-medium text-gray-v2-900">
            {item.system && item.system.mac_address
              ? item.system.mac_address
              : "---"}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-2 border-r last:border-0 border-gray-v2-200 flex-1 text-xs">
          <span className="text-gray-v2-600">{t("manufacturer")}</span>
          <span className="font-medium text-gray-v2-900">
            {item.system && item.system.hardware_vendor
              ? item.system.hardware_vendor
              : "---"}
          </span>
          <span className="text-gray-v2-600">{t("hardwareModel")}</span>
          <span className="font-medium text-gray-v2-900">
            {item.system && item.system.hardware_model
              ? item.system.hardware_model
              : "---"}
          </span>
          <span className="text-gray-v2-600">{t("serialNumber")}</span>
          <span className="font-medium text-gray-v2-900">
            {item.system && item.system.hardware_serial
              ? item.system.hardware_serial
              : "---"}
          </span>
          <span className="text-gray-v2-600">{t("cpuBrand")}</span>
          <Typography.Text
            ellipsis={{
              tooltip: (
                <span className="text-xs font-medium">
                  {item.system && item.system.cpu_brand
                    ? item.system.cpu_brand
                    : "---"}
                </span>
              ),
            }}
          >
            <span className="text-xs font-medium text-gray-v2-900">
              {item.system && item.system.cpu_brand
                ? item.system.cpu_brand
                : "---"}
            </span>
          </Typography.Text>
        </div>
      </div>
      <Link
        to={generatePath(pathname.ENDPOINT_DEVICES_OVERVIEW, {
          workspaceId,
          host: item.id.toString(),
        })}
      >
        <ButtonV2 variant="tertiary">
          {t("button.details")}
          <ArrowUpRight className="h-5 w-5 min-w-5" />
        </ButtonV2>
      </Link>
    </div>
  );
};
