// Libraries
import { type RefObject, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";

// Resources
import { ReactComponent as Search2Line } from "#src/assets/images/icons/search-2-line.svg";

// Components
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";
import { createToast } from "#src/common/system/toasts";

// API-related
import { tagService } from "#src/services/tags";
import { TagTypeValue } from "#src/config/filter/tag/value";

// Children
import SuggestionItem from "./SuggestionItem";

interface ITagInputProps {
  open: boolean;
  inputRef: RefObject<HTMLInputElement>;
  workspaceId: string;
  tagList: { name: string }[];
  allowCreateTag: boolean;
  type: TagTypeValue;
  onChangeTags: (tags: { name: string }[]) => void;
  onChangeOpen: (value: boolean) => void;
}

/** An input field with helpers to help user enter tags. */
export const TagInput = ({
  open,
  inputRef,
  workspaceId,
  tagList,
  allowCreateTag,
  type,
  onChangeTags,
  onChangeOpen,
}: ITagInputProps) => {
  const { t } = useTranslation("common", { keyPrefix: "tagEditor" });

  const [inputValue, setInputValue] = useState<string>("");
  const [invalidTag, setInvalidTag] = useState<boolean>(false);

  const validateTag = (newTag: string) => {
    if (
      !allowCreateTag &&
      availableTags &&
      !availableTags.map((item) => item.name).includes(newTag)
    ) {
      setInvalidTag(true);
      return false;
    } else {
      if (invalidTag) {
        setInvalidTag(false);
      }
      return true;
    }
  };

  const onAddTag = (newTag: string) => {
    if (newTag.length <= 0) {
      setInvalidTag(true);
      createToast({
        type: "error",
        message: t("error.emptyTagName.message"),
        detail: t("error.emptyTagName.description"),
      });
      return;
    }

    if (!validateTag(newTag)) {
      setInvalidTag(true);
      createToast({
        type: "error",
        message: t("error.invalidTag.message"),
        detail: t("error.invalidTag.description"),
      });
      return;
    }

    if (tagList.map((tag) => tag.name).includes(newTag)) {
      createToast({
        type: "error",
        message: t("error.duplicatedTag.message"),
        detail: t("error.duplicatedTag.description"),
      });
      return;
    }
    // add the new tag
    onChangeTags([...tagList, { name: newTag }]);
    // reset input
    setInputValue("");
  };

  const fetchParams = useMemo<Parameters<typeof tagService.listTagsNoPaging>>(
    () => [workspaceId, { type }],
    [workspaceId, type]
  );

  const { list: availableTags } = useFetchNoPaging(
    tagService.listTagsNoPaging,
    fetchParams
  );

  return (
    <Dropdown
      open={open}
      onOpenChange={onChangeOpen}
      menu={{
        items: availableTags
          ? availableTags
              .filter(
                (item) => !tagList.map((tag) => tag.name).includes(item.name)
              )
              .map((item) => ({
                key: item.name,
                value: item.name,
                label: (
                  <SuggestionItem syncWidthId="tag-editor">
                    {item.name}
                  </SuggestionItem>
                ),
              }))
          : [],
        onClick: ({ key, domEvent }) => {
          domEvent.preventDefault();
          domEvent.stopPropagation();
          onAddTag(key);
        },
        onMouseDown: (e) => {
          e.preventDefault();
        },
      }}
      placement="bottomRight"
      trigger={["click"]}
    >
      <form
        id="tag-editor"
        className="flex items-center justify-between w-full gap-3"
        onSubmit={(e) => {
          e.preventDefault();
          onAddTag(inputValue);
        }}
      >
        <input
          className="outline-none caret-primary text-hard-grey bg-transparent w-full"
          ref={inputRef}
          autoFocus
          value={inputValue}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            setInputValue(e.currentTarget.value);
          }}
          onBlur={() => {
            onChangeOpen(false);
          }}
        />
        <div className="w-4 h-4 flex items-center">
          <Search2Line className="fill-hard-grey w-4 h-4" />
        </div>
      </form>
    </Dropdown>
  );
};
