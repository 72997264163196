import type { ReactNode } from "react";

interface IConfigSectionWrapperProps {
  icon: ReactNode;
  headerTitle: string;
  headerButton?: ReactNode;
  danger?: boolean;
  children?: ReactNode;
}

const ConfigSectionWrapper = ({
  icon,
  headerTitle,
  headerButton,
  danger = false,
  children,
}: IConfigSectionWrapperProps) => {
  return (
    <div
      className={`flex flex-col border ${
        danger ? "border-warning-red-40%" : "border-light-grey"
      } rounded-md`}
    >
      <div
        className={`p-6 flex justify-between rounded-md ${
          danger ? "bg-warning-red-5%" : "bg-bright-grey"
        }`}
      >
        <div className="flex items-center gap-3">
          {icon}
          <span className="font-medium-20">{headerTitle}</span>
        </div>
        <div className="flex gap-2">{headerButton}</div>
      </div>
      {children}
    </div>
  );
};

export default ConfigSectionWrapper;
