import { useState } from "react";
import { SearchLg } from "@untitled-ui/icons-react";

interface ISearchboxV2Props {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}

export const SearchboxV2 = ({
  value,
  onChange,
  placeholder,
}: ISearchboxV2Props) => {
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <label
      className={`bg-white border border-gray-v2-300 shadow-xs rounded-lg px-3 py-2 flex items-center gap-2 outline transition-all ease-in text-md ${
        focused ? "outline-2 outline-brand-v2" : "outline-transparent"
      }`}
    >
      <SearchLg className="h-5 w-5 text-gray-v2" />
      <input
        className="focus-visible:outline-0 h-6"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        placeholder={placeholder}
      />
    </label>
  );
};
