import { forwardRef, useRef, useState, type SVGAttributes } from "react";
import { createPortal } from "react-dom";
import type { IChartPlotProps, IChartTooltipGetter } from "#src/@types/chart";
import { generateKeyString } from "#src/utils/common";

// ---------- DEFAULT SETTINGS ----------

// Size of the SVG we will draw. Usually this should be exactly the size of the circle, which means double the RADIUS below.
const DEFAULT_SVG_SIZE = 200;
// Pie chart and donut chart are circles, so we need circle's radius. This will be the size of the visible part.
const DEFAULT_RADIUS = 100;
// Donut RADIUS - Donut's hole size. Choose 0 to make a pie chart.
const DEFAULT_SMALL_RADIUS = 56;

interface IDonutChartProps
  extends Omit<SVGAttributes<SVGSVGElement>, "viewBox"> {
  data: IChartPlotProps[];
  tooltip?: IChartTooltipGetter;
  getUnit?: (count: number) => string;
  options?: { svgSize?: number; radius?: number; smallRadius?: number };
}

export const DonutChartV2 = forwardRef<SVGSVGElement, IDonutChartProps>(
  ({ data, tooltip, getUnit, options, ...props }, ref) => {
    // ---------- CALCULATE CHART SIZE ----------
    const SVG_SIZE =
      options !== undefined && options.svgSize !== undefined
        ? options.svgSize
        : DEFAULT_SVG_SIZE;
    const RADIUS =
      options !== undefined && options.radius !== undefined
        ? options.radius
        : DEFAULT_RADIUS;
    const SMALL_RADIUS =
      options !== undefined && options.smallRadius !== undefined
        ? options.smallRadius
        : DEFAULT_SMALL_RADIUS;
    // Our circle has the same size as the SVG, so this is the coordinates of its center.
    const CIRCLE_CENTER = { x: SVG_SIZE / 2, y: SVG_SIZE / 2 };

    // Make sure elements in this chart are connected properly.
    const chartId = useRef(props.id ? props.id : generateKeyString());

    // Control tooltip display
    const [tooltipProps, setTooltipProps] = useState<{
      top: number;
      left: number;
      display: "none" | "block";
      plot: IChartPlotProps | null;
    }>({
      top: 0,
      left: 0,
      display: "none",
      plot: null,
    });

    // ---------- METHODS ----------

    /** Angle starts from 12:00, then go clock-wise */
    const getCircleCoordFromAngle = (angle: number, radius: number) => {
      // coordinations in standard coordinate
      const standardX = Math.sin((angle * Math.PI) / 180);
      const standardY = -Math.cos((angle * Math.PI) / 180);

      // calculated coordinations in specific circle and svg
      const x = standardX * radius + CIRCLE_CENTER.x;
      const y = standardY * radius + CIRCLE_CENTER.y;

      return { x, y };
    };

    /** return a string as d in SVG Path Arc's parameters from angle. 0 degree is 12:00 in clock. We go clock-wise.
     * @param radius
     * @param startAngle start of sweeping angle for this arc. This related to the current coordinate of the cursor.
     * @param endAngle end of sweeping angle for this arc. If this is smaller than startAngle, the arc will be drawn counter-clockwise.
     */
    const declareArc = (
      radius: number,
      startAngle: number,
      endAngle: number
    ) => {
      // We want to draw circle so elipse's rotate angle can be whatever. We choose 0 as a default.
      const rotateAngle = 0;

      // If the angle of the arc is bigger than 180 degree, we will need the large arc, therefore this flag should be 1. Otherwise it should be 0.
      const largeArcFlag = Math.abs(startAngle - endAngle) % 360 > 180 ? 1 : 0;

      const sweepFlag = startAngle > endAngle ? 0 : 1;

      // calculated coordinations in specific circle and svg
      const { x, y } = getCircleCoordFromAngle(endAngle, radius);

      return `A ${radius} ${radius} ${rotateAngle} ${largeArcFlag} ${sweepFlag} ${x} ${y}`;
    };

    // When mouse moves, tooltip should also move.
    const handleMouseMove = (
      item: IChartPlotProps,
      mouseX: number,
      mouseY: number
    ) => {
      setTooltipProps({
        // tooltip should show a bit to the down-right direction of the mouse.
        left: mouseX + 7,
        top: mouseY + 7,
        display: "block",
        plot: item,
      });
    };

    // When mouse leave the chart, tooltip will be hidden.
    const handleMouseOut = () => {
      setTooltipProps({
        left: 0,
        top: 0,
        display: "none",
        plot: null,
      });
    };

    // ---------- COMPUTED ----------

    // Parts with 0 data will not be drawn, so we filter those out to avoid errors.
    const dataWithoutZero = data.filter((item) => item.value > 0);

    // Total value so that we can calculate percentage of each part.
    const totalValue = data.reduce((prev, cur) => {
      return prev + cur.value;
    }, 0);

    // Helper variable to draw SVG. Only used for rendering phase, so it's ok to leave it outside of React.
    let accumulatedAngle = 0;

    // ---------- RENDER ----------

    return (
      <>
        <svg
          ref={ref}
          height={"10rem"}
          width={"10rem"}
          {...props}
          viewBox={`0 0 ${SVG_SIZE} ${SVG_SIZE}`}
        >
          {dataWithoutZero.length > 1 ? (
            dataWithoutZero.map((item) => {
              const sweep = (item.value / totalValue) * 360;
              const startAngle = accumulatedAngle;
              const endAngle = startAngle + sweep;
              accumulatedAngle = endAngle;

              return (
                <path
                  key={`donut-${chartId.current}-${item.id}`}
                  fill={item.fill}
                  // We start drawing from 12:00, so first of all we move the cursor to that place.
                  d={`M ${getCircleCoordFromAngle(startAngle, RADIUS).x} ${
                    getCircleCoordFromAngle(startAngle, RADIUS).y
                  } ${declareArc(RADIUS, startAngle, endAngle)} L ${
                    getCircleCoordFromAngle(endAngle, SMALL_RADIUS).x
                  } ${
                    getCircleCoordFromAngle(endAngle, SMALL_RADIUS).y
                  } ${declareArc(SMALL_RADIUS, endAngle, startAngle)}`}
                  onMouseMove={(e) => {
                    handleMouseMove(item, e.pageX, e.pageY);
                  }}
                  onMouseOut={handleMouseOut}
                />
              );
            })
          ) : dataWithoutZero.length === 1 ? (
            <>
              {/* Draw a single donut using circle, since using path to draw circle is weirdly hard */}
              <circle
                cx={CIRCLE_CENTER.x}
                cy={CIRCLE_CENTER.y}
                r={RADIUS}
                fill={dataWithoutZero[0].fill}
                onMouseMove={(e) => {
                  handleMouseMove(dataWithoutZero[0], e.pageX, e.pageY);
                }}
                onMouseOut={handleMouseOut}
              />
              {/* Draw donut hole with another circle. This must be declared after the main circle so that we can draw the white hole over it. */}
              <circle
                cx={CIRCLE_CENTER.x}
                cy={CIRCLE_CENTER.y}
                r={SMALL_RADIUS}
                fill="white"
              />
            </>
          ) : null}
        </svg>
        {tooltip
          ? createPortal(
              <div
                style={{
                  top: tooltipProps.top,
                  left: tooltipProps.left,
                  display: tooltipProps.display,
                }}
                className="fixed z-10 pointer-events-none"
              >
                {tooltip({
                  chartPlot: tooltipProps.plot,
                  totalValue,
                  unit: getUnit
                    ? getUnit(tooltipProps.plot ? tooltipProps.plot.value : 0)
                    : "",
                })}
              </div>,
              document.body
            )
          : null}
      </>
    );
  }
);

DonutChartV2.displayName = "DonutChart";
