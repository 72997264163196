import { useMemo } from "react";
import { DateTime } from "luxon";
import { toPascalCase } from "#src/utils/common";
import { IEndpointDevice } from "#src/services/endpoint/devices/types";
import { useTranslation } from "react-i18next";

type Props = {
  details: IEndpointDevice;
};

export const ActivitiesSection = (props: Props) => {
  const { details } = props;
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- COMPUTED ---------------

  const hostStatus = toPascalCase(details.status);

  const info = useMemo(() => {
    return [
      {
        label: t("overview.activity.status"),
        value: hostStatus,
      },
      {
        label: t("overview.activity.last_user_login"),
        value: details.last_login_at
          ? formatTimeDiff(details.last_login_at)
          : "",
      },
      {
        label: t("overview.activity.last_system_boot"),
        value: details.system?.last_restarted
          ? formatTimeDiff(details.system.last_restarted)
          : "",
      },
      {
        label: t("overview.activity.last_activity"),
        value: details.last_seen ? formatTimeDiff(details.last_seen) : "",
      },
    ];
  }, [details, hostStatus, t]);

  // --------------- RENDER ---------------

  return (
    <div className="w-full xl:w-6/12 xl:pr-4">
      <section className="section-container mb-8">
        <h3>{t("overview.activity.title")}</h3>
        <div className="flex flex-col gap-4">
          {info.map((section, index) => (
            <div key={index} className="flex gap-2">
              <p className="text-sm text-gray-v2-600 flex-1">{section.label}</p>
              <p className="flex-1 text-sm text-gray-v2-900 font-medium">
                {section.value || "---"}
              </p>
            </div>
          ))}
        </div>
      </section>

      <section className="section-container">
        <h3>{t("overview.activity.last_owner_change")}</h3>
        <div className="h-40 flex items-center justify-center">
          <p className="text-sm text-gray-v2-400">{t("common.no_data")}</p>
        </div>
      </section>
    </div>
  );
};

function formatTimeDiff(timestamp: number) {
  const now = DateTime.now();
  const date = DateTime.fromSeconds(timestamp);
  const diff = now.diff(date, "days").days;

  if (diff < 7) {
    // If less than a day, show relative time
    return date.toRelative(); // e.g., "2 hours ago", "5 minutes ago"
  } else {
    // If more than a day, show formatted time
    return date.toFormat("HH:mm dd/MM/yyyy"); // e.g., "14:30 15/03/2024"
  }
}
