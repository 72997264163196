// Libraries
import { type ReactNode } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as AddFile } from "#src/assets/images/icons/add-file.svg";
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

export enum ESolutionKeys {
  SECRETS_MANAGER = "secrets_manager",
  PASSWORD_MANAGER = "password_manager",
  ENDPOINT = "endpoint",
  DATA_LEAK = "data_leak",
  VULN_SCAN = "vuln_scan",
  TRUST_CENTER = "trust_center",
  PENETRATION_TESTING = "penetration_testing",
  RED_TEAMING = "red_teaming",
  CLOUD_SECURITY_AUDIT = "cloud_security_audit",
  SOURCE_CODE_AUDIT = "source_code_audit",
  BLOCKCHAIN_PROTOCOL_AUDIT = "blockchain_protocol_audit",
  WHITEHUB_BUG_BOUNTY = "whitehub_bug_bounty",
  SECURITY_COMPLIANCE = "security_compliance",
  SECURITY_OPERATIONS_CENTER = "security_operations_center",
  VULNERABILITY_MANAGEMENT = "vulnerability_management",
}

export interface ISolution {
  key: ESolutionKeys;
  logo: ReactNode;
  title: string;
  description: string;
  url: string;
  publicUrl?: string;
  isExternal?: boolean;
  enabled: boolean;
  isCreate?: boolean;
  comingSoon?: boolean;
}

interface ISolutionCardProps {
  solutionItem: ISolution;
  role: WorkspaceMemberRoleValue | undefined;
}

export const SolutionCard = ({ solutionItem, role }: ISolutionCardProps) => {
  const { t } = useTranslation("solutions", { keyPrefix: "actions" });

  return (
    <div className="rounded-md flex flex-col justify-between gap-6 bg-bright-grey p-6 h-full">
      <div className="space-y-3">
        <div className="flex items-center gap-3">
          <div className="w-[3.75rem] h-[3.75rem] p-1.5 flex-shrink-0 flex items-center justify-center bg-white rounded-md border border-solid border-light-grey">
            {solutionItem.logo}
          </div>
          <span className="font-medium-18 text-dark-blue">
            {solutionItem.title}
          </span>
        </div>
        <p className="text-hard-grey font-regular-16-body line-clamp-3">
          {solutionItem.description}
        </p>
      </div>
      <div className="flex justify-end">
        {solutionItem.comingSoon ? (
          <Button disabled>{t("comingSoon")}</Button>
        ) : role === WorkspaceMemberRoleValue.OWNER ||
          solutionItem.publicUrl === undefined ? (
          <a
            href={solutionItem.url}
            rel="noreferrer"
            target={solutionItem.isExternal ? "_blank" : "_parent"}
          >
            {solutionItem.isCreate ? (
              <Button variant="secondary">
                <AddFile className="w-4 h-4" />
                <span>{t("explore")}</span>
              </Button>
            ) : solutionItem.enabled ? (
              <Button>{t("open")}</Button>
            ) : (
              <Button variant="secondary">
                {t("start_now")}
                <ArrowRightUpLine className="w-4 h-4" />
              </Button>
            )}
          </a>
        ) : (
          <a href={solutionItem.publicUrl} rel="noreferrer" target="_blank">
            {solutionItem.enabled ? (
              <Button>{t("open")}</Button>
            ) : (
              <Button variant="secondary">
                {t("start_now")}
                <ArrowRightUpLine className="w-4 h-4" />
              </Button>
            )}
          </a>
        )}
      </div>
    </div>
  );
};
