// Libraries
import { Drawer } from "@lockerpm/design";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

// Resources
import { pathname } from "#src/config/pathname";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";
import { ICertificateItemTableData } from "#src/services/assets/certificates";
import urls from "#src/config/urls";
import { ObjectImage } from "#src/common/system/Object";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import LoadingState from "#src/common/system/LoadingState";
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// API-related
import assetsServices, {
  IAssetItem,
  IParamsListDomainByCertificate,
} from "#src/services/assets";

interface IListLinkedDomainsDrawerProps extends IDrawerAdapterProps {
  open: boolean;
  workspaceId: string;
  selectedCertificate: ICertificateItemTableData | undefined;
  onClose: () => void;
}

const ListLinkedDomainsDrawer = ({
  open,
  workspaceId,
  selectedCertificate,
  onClose,
}: IListLinkedDomainsDrawerProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation("assets", {
    keyPrefix: "certificates.page.certificates",
  });

  const [domains, setDomains] = useState<IAssetItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    if (!selectedCertificate) return;
    setLoading(true);
    const params: IParamsListDomainByCertificate = {
      asset_type: "domain",
      shortly: 1,
      cert_id: selectedCertificate.id,
    };

    const response = await assetsServices.get_domain_by_certificate(
      workspaceId,
      params
    );
    setDomains(response.results);
    setLoading(false);
  }, [selectedCertificate, workspaceId]);

  useEffect(() => {
    if (open) {
      void fetchData();
    } else {
      setDomains([]);
    }
  }, [fetchData, open]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      closable={false}
      title={<h2>{t("linked_domains.title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onClose}>
          <CloseLine />
        </IconButton>
      }
      footer={
        <div className="flex flex-col gap-3">
          <Button
            variant="secondary"
            className="w-full"
            size="large"
            onClick={onClose}
          >
            {t("linked_domains.close")}
          </Button>
        </div>
      }
    >
      {loading ? (
        <div className="pt-20">
          <LoadingState />
        </div>
      ) : (
        <div className="flex flex-col gap-6 p-6">
          <div className="flex flex-col gap-3">
            <p className="font-regular-14 text-hard-grey">
              {t("linked_domains.list_domain_title")}
            </p>
            <div className="p-4 rounded-md bg-bright-blue flex flex-col gap-1">
              <h3 className="font-medium-20 text-dark-blue">
                {selectedCertificate?.subject_cn}
              </h3>
              <p className="font-regular-16">
                <span className="text-hard-grey">
                  {t("linked_domains.issued_by")}
                </span>
                <span className="text-dark-blue ml-1">
                  {selectedCertificate?.issuer_cn}
                </span>
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <p className="font-medium-14-forced text-hard-grey uppercase">
              {t("linked_domains.domains")}
            </p>
            <ul className="m-0 p-0 space-y-3">
              {domains.map((item) => (
                <li
                  key={item.id}
                  className="rounded-md flex items-center justify-between bg-bright-grey p-3"
                >
                  <div className="flex items-center gap-2">
                    <ObjectImage
                      data={
                        urls.IMAGE_LOGO_SERVER_URL.replace(
                          ":address",
                          item.detail.address
                        ) + "?size=36&v=1"
                      }
                      className="h-6 w-6 min-w-[1.5rem]"
                    />
                    <span className="font-regular-16 text-dark-blue">
                      {item.detail.address}
                    </span>
                  </div>
                  <Button
                    variant="text"
                    className="px-0"
                    onClick={() => {
                      navigate(
                        generatePath(pathname.DOMAIN_DETAILS, {
                          workspaceId,
                          assetId: item.id,
                        })
                      );
                    }}
                  >
                    <ArrowRightUpLine className="w-5 h-5" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default ListLinkedDomainsDrawer;
