import { DeviceAssetType } from "#src/services/endpoint/enums";
import i18next from "i18next";

const fixedTFunc = i18next.getFixedT(null, "endpoint", "devices.table.filter");

export const assetTypeFilterItems = [
  {
    key: "company-owned",
    getLabel: () => fixedTFunc("assetType.companyOwned"),
    value: DeviceAssetType.COMPANY_OWNED,
  },
  {
    key: "personal",
    getLabel: () => fixedTFunc("assetType.personal"),
    value: DeviceAssetType.PERSONAL,
  },
];
