import { generatePath, useParams } from "react-router-dom";
import { useState, useMemo } from "react";
import constants from "#src/config/constants";
import dlpService from "#src/services/endpoint/dlp";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { IDLPActivityLog } from "#src/services/endpoint/dlp/types";
import { BreadcrumbV2 } from "#src/commonV2/Breadcrumb";
import { useTranslation } from "react-i18next";
import { TableV2 } from "#src/commonV2/Table";
import { DLPAction, DLPActionStatus } from "#src/services/endpoint/dlp/enums";
import { DateTime } from "luxon";
import { InfoCircle } from "@untitled-ui/icons-react";
import { Tooltip } from "@lockerpm/design";
import { IFilterItemV2 } from "#src/commonV2/Table/Filter/types";
import { FilterDropdownV2 } from "#src/commonV2/Table/Filter/FilterDropdown";
import { SearchboxV2 } from "#src/commonV2/Table/Searchbox";
import {
  activityStatusFilterItems,
  activityTypeFilterItems,
} from "#src/components/endpoint/dlp/activity/filterItems";
import { FilterByDate } from "#src/commonV2/Table/Filter/FilterByDate";
import { pathname } from "#src/config/pathname";
import { byteToGigabyte } from "#src/utils/common";

export const DlpActivities = () => {
  const { t } = useTranslation("endpoint", { keyPrefix: "dlp.activities" });

  const { workspaceId } = useParams<"workspaceId">();

  if (!workspaceId) {
    return null;
  }

  // --------------- DATA ---------------

  // Filter
  const [activityType, setActivityType] = useState<IFilterItemV2 | null>(null);
  const [activityStatus, setActivityStatus] = useState<IFilterItemV2 | null>(
    null
  );
  const [dateRange, setDateRange] = useState<{
    from: number | null;
    to: number | null;
  } | null>(null);

  // Pagination
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  // Search/sort
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");

  // --------------- COMPUTED ---------------

  const fetchParams = useMemo<Parameters<typeof dlpService.listDLPLog>>(
    () => [
      workspaceId,
      {
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
        sort: sortOrder ?? undefined,
        type: activityType?.value,
        status: activityStatus?.value,
        // Timestamp should be integer, not float to avoid the case that backend cannot parse lol.
        from:
          dateRange && dateRange.from ? Math.round(dateRange.from) : undefined,
        to: dateRange && dateRange.to ? Math.round(dateRange.to) : undefined,
      },
    ],
    [
      searchKeyword,
      workspaceId,
      selectedPage,
      pageSize,
      sortOrder,
      activityType,
      activityStatus,
      dateRange,
    ]
  );

  // --------------- HOOKS ---------------

  const {
    list: logList,
    count: logCount,
    isLoading,
  } = useFetchPaginated(dlpService.listDLPLog, fetchParams);

  // --------------- METHODS ---------------

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  // On change filter
  const onChangeActivityType = (selected: typeof activityType) => {
    setActivityType(selected);
    setSelectedPage(1);
  };

  // On change filter
  const onChangeActivityStatus = (selected: typeof activityStatus) => {
    setActivityStatus(selected);
    setSelectedPage(1);
  };

  // On change filter
  const onChangeDateRange = (selected: typeof dateRange) => {
    setDateRange(selected);
    setSelectedPage(1);
  };

  const getStatusDisplay = (status: IDLPActivityLog["status"]) => {
    if (status === DLPActionStatus.SUCCESS) {
      return (
        <div className="border border-success-v2-200 bg-success-v2-50 px-3 py-1 rounded-full text-sm font-medium text-success-v2-600 w-[6.5rem] text-center">
          {t("table.status.success")}
        </div>
      );
    }
    if (status === DLPActionStatus.FAIL) {
      return (
        <div className="border border-error-v2-200 bg-error-v2-50 px-3 py-1 rounded-full text-sm font-medium text-error-v2-600 w-[6.5rem] text-center">
          {t("table.status.fail")}
        </div>
      );
    }
    return null;
  };

  // ---------------EFFECTS ---------------

  // --------------- RENDER ---------------

  return (
    <>
      <div className="pt-5 pb-6 flex flex-col gap-5 min-h-screen">
        {/* Header (Breadcrumb + title) */}
        <div className="px-8 flex flex-col gap-4">
          <BreadcrumbV2
            levels={[
              {
                name: t("breadcrumb.levels.endpoint"),
                path: generatePath(pathname.ENDPOINT_OVERVIEW, { workspaceId }),
              },
            ]}
            currentPage={{
              name: t("breadcrumb.current.dataLossPrevention"),
              tab: t("breadcrumb.current.activities"),
            }}
          />
          <span className="text-display-xs font-semibold text-gray-v2-900">
            {t("title")}
          </span>
        </div>
        {/* Header (Breadcrumb + title) end */}

        <div className="px-8 flex flex-col gap-4 flex-1">
          <div className="flex justify-between items-center gap-2">
            {/* Filters */}
            <div className="flex gap-2">
              <FilterDropdownV2
                label={t("table.filter.activity.label")}
                options={activityTypeFilterItems}
                selected={activityType}
                onChangeSelection={onChangeActivityType}
              />
              <FilterDropdownV2
                label={t("table.filter.status.label")}
                options={activityStatusFilterItems}
                selected={activityStatus}
                onChangeSelection={onChangeActivityStatus}
              />
              <FilterByDate
                label={t("table.filter.snapshotVersionTime.label")}
                dateRange={dateRange}
                onChangeDateRange={onChangeDateRange}
              />
            </div>
            {/* Filters end */}

            {/* Searchbox */}
            <SearchboxV2
              value={searchKeyword}
              onChange={(value) => {
                setSearchKeyword(value);
              }}
              placeholder={t("table.filter.searchbox.placeholder")}
            />
            {/* Searchbox end */}
          </div>

          {/* Table */}
          <TableV2<IDLPActivityLog>
            data={logList ?? []}
            getRowKey={(item) => item.id}
            isLoading={isLoading}
            columns={[
              {
                title: t("table.header.device"),
                render: (item) => item.drive_name,
                overwriteWrapperProps: {
                  className: "font-medium text-gray-v2-900",
                },
                gridTemplateValue: "4fr",
                sortingKey: { asc: "name_asc", desc: "name_desc" },
              },
              {
                title: t("table.header.user"),
                render: (item) => item.email,
                gridTemplateValue: "5fr",
                sortingKey: { asc: "email_asc", desc: "email_desc" },
              },
              {
                title: t("table.header.activity"),
                // Not sure if we want i18n here.
                render: (item) =>
                  item.type === DLPAction.BACKUP
                    ? t("table.activity.backup")
                    : item.type === DLPAction.RESTORE
                      ? t("table.activity.restore")
                      : item.type,
                gridTemplateValue: "2fr",
              },
              {
                title: t("table.header.snapshotVersion"),
                render: (item) => (
                  <>
                    {item.snapshot_version ? item.snapshot_version : "---"}
                    <Tooltip
                      title={
                        <div className="text-center text-xs">
                          <span className="font-semibold">
                            {t("table.tooltip.requestedTime")}
                          </span>
                          <br />
                          {DateTime.fromSeconds(item.requested_time).toFormat(
                            "HH:mm:ss\ndd/MM/yyyy"
                          )}
                        </div>
                      }
                    >
                      <InfoCircle className="h-5 w-5 min-w-[1.25rem] text-gray-v2" />
                    </Tooltip>
                  </>
                ),
                overwriteWrapperProps: { className: "justify-between" },
                gridTemplateValue: "3fr",
                sortingKey: {
                  asc: "snapshot_version_asc",
                  desc: "snapshot_version_desc",
                },
              },
              {
                title: t("table.header.snapshotSize"),
                render: (item) =>
                  `${byteToGigabyte(item.snapshot_size).toFixed(1)} GB`,
                gridTemplateValue: "2fr",
                sortingKey: {
                  asc: "snapshot_size_asc",
                  desc: "snapshot_size_desc",
                },
              },
              {
                title: t("table.header.completedTime"),
                render: (item) =>
                  item.completed_time
                    ? DateTime.fromSeconds(item.completed_time).toFormat(
                        "HH:mm:ss dd/MM/yyyy"
                      )
                    : "---",
                gridTemplateValue: "2fr",
                sortingKey: {
                  asc: "completed_time_asc",
                  desc: "completed_time_desc",
                },
              },
              {
                title: t("table.header.status"),
                render: (item) => getStatusDisplay(item.status),
                gridTemplateValue: "minmax(7rem, 2fr)",
              },
            ]}
            sort={{
              value: sortOrder,
              onChange: (value) => {
                setSortOrder(value);
              },
            }}
            pagination={{
              count: logCount,
              selected: selectedPage,
              onChange: onChangePage,
              size: { value: pageSize, onChange: onChangePageSize },
            }}
            options={{
              fitPageHeight: true,
              emptyState: {
                title: t("table.emptyState.title"),
                supportingText: t("table.emptyState.supportingText"),
              },
            }}
          />
          {/* Table end */}
        </div>
      </div>
    </>
  );
};
