// Libraries
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useLayoutEffect, useState } from "react";

// Resources
import { ReactComponent as SaveLine } from "#src/assets/images/icons/save-line.svg";
import { ReactComponent as CornerDownRightLine } from "#src/assets/images/icons/corner-down-right-line.svg";

// General
import { pathname } from "#src/config/pathname";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/common/system/Button";
import { generateKeyString } from "#src/utils/common";

// API-related
import bugBountyServices from "#src/services/bugBounty";
import {
  pentestScopeScopeItems,
  pentestScopeTypeItems,
} from "#src/config/filter/pentest";
import { BugBountyStatusResponseValue } from "#src/config/filter/bugbounty/value";

// Children
import AddBugBountyManagementMode from "#src/components/bugBounty/AddBugBounty/ManagementMode";
import AddBugBountyProgramType from "#src/components/bugBounty/AddBugBounty/ProgramType";
import AddBugBountyProgramName from "#src/components/bugBounty/AddBugBounty/ProgramName";
import AddBugBountyDescription from "#src/components/bugBounty/AddBugBounty/Description";
import AddBugBountyScope, {
  type IBugBountyScopeInputItem,
} from "#src/components/bugBounty/AddBugBounty/Scope";
import AddBugBountyRewardRange, {
  bugBountyRewardTypeItems,
  type IBugBountyRewardRangeItem,
} from "#src/components/bugBounty/AddBugBounty/RewardRange";
import AddBugBountyColorAndLogo from "#src/components/bugBounty/AddBugBounty/ColorAndLogo";
import AddBugBountyPolicy from "#src/components/bugBounty/AddBugBounty/Policy";
import {
  BugBountyManagementModeEnum,
  BugBountyProgramTypeEnum,
  BugBountyProgramTypeValueEnum,
} from "#src/components/bugBounty/enum";

const AddBugBounty = () => {
  const { t } = useTranslation("bugBounty", { keyPrefix: "page.addBugBounty" });

  const { workspaceId } = useParams<"workspaceId">();
  const navigate = useNavigate();

  const [managementMode, setManagementMode] = useState<string>(
    BugBountyManagementModeEnum.BY_PROVIDER
  );
  const [programType, setProgramType] = useState<string>(
    BugBountyProgramTypeEnum.PUBLIC
  );
  const [briefName, setBriefName] = useState<string>("");
  const [alias, setAlias] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [scope, setScope] = useState<IBugBountyScopeInputItem[]>([]);
  const [currency, setCurrency] = useState<"VND" | "USD">("VND");
  const [rewardRange, setRewardRange] = useState<IBugBountyRewardRangeItem[]>([
    {
      type: bugBountyRewardTypeItems[0],
      severity: "CRITICAL",
      point: 4,
      from: 0,
      to: 0,
    },
    {
      type: bugBountyRewardTypeItems[0],
      severity: "HIGH",
      point: 3,
      from: 0,
      to: 0,
    },
    {
      type: bugBountyRewardTypeItems[0],
      severity: "MEDIUM",
      point: 2,
      from: 0,
      to: 0,
    },
    {
      type: bugBountyRewardTypeItems[0],
      severity: "LOW",
      point: 1,
      from: 0,
      to: 0,
    },
    {
      type: bugBountyRewardTypeItems[0],
      severity: "INFORMATION",
      point: 0,
      from: 0,
      to: 0,
    },
  ]);
  // Magic string is just default color. Not anything particular
  const [color, setColor] = useState<string>("#e7e8ea");
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [policy, setPolicy] = useState<string>("");
  const [agreeTermsPolicies, setAgreeTermsPolicies] = useState<boolean>(false);

  // Check if there are any scopes with empty asset names
  const [invalidAssets, setInvalidAssets] = useState<boolean>(false);

  const onChangeManagementMode = (value: string) => {
    setManagementMode(value);
  };

  const onChangeProgramType = (value: string) => {
    setProgramType(value);
  };

  const onChangeBriefName = (value: string) => {
    setBriefName(value);
  };

  const onChangeAlias = (value: string) => {
    setAlias(value);
  };

  const onChangeDescription = (value: string) => {
    setDescription(value);
  };

  const onChangePolicy = (value: string) => {
    setPolicy(value);
  };

  const onChangeColor = (value: string) => {
    setColor(value);
  };

  const onChangeAvatarUrl = (value: string) => {
    setAvatarUrl(value);
  };

  const onClickAgreeTermsPolicies = () => {
    setAgreeTermsPolicies((prev) => !prev);
  };

  const deactivateEditScope = (id: number) => {
    if (scope.some((item, index) => !item.assets && index === id)) {
      setInvalidAssets(true);
      return;
    }

    setScope((prev) => {
      const newArr = prev.map((item, index) =>
        index === id ? { ...item, editing: false } : item
      );

      return newArr;
    });
  };

  const activateEditScope = (id: number) => {
    setScope((prev) => {
      const newArr = prev.map((item, index) =>
        index === id ? { ...item, editing: true } : item
      );

      return newArr;
    });
  };

  const addScope = () => {
    setScope((prev) => [
      ...prev,
      {
        id: generateKeyString(),
        assetType: pentestScopeTypeItems[0],
        assets: "",
        scope: pentestScopeScopeItems[0],
        editing: true,
      },
    ]);
  };

  const removeScope = (id: number) => {
    if (scope.some((item, index) => item.assets || index === id)) {
      setInvalidAssets(false);
    }

    setScope((prev) => [...prev.slice(0, id), ...prev.slice(id + 1)]);
  };

  const onChangeScopeAssetType = (id: number, key: string) => {
    const selected = pentestScopeTypeItems.find((item) => item.key === key);

    if (selected) {
      setScope((prev) =>
        prev.map((item, index) =>
          index === id ? { ...item, assetType: selected } : item
        )
      );
    }
  };

  const onChangeScopeAssets = (id: number, value: string) => {
    if (invalidAssets) {
      setInvalidAssets(false);
    }

    setScope((prev) =>
      prev.map((item, index) =>
        index === id ? { ...item, assets: value } : item
      )
    );
  };

  const onChangeScopeScope = (id: number, key: string) => {
    const selected = pentestScopeScopeItems.find((item) => item.key === key);

    if (selected) {
      setScope((prev) =>
        prev.map((item, index) =>
          index === id ? { ...item, scope: selected } : item
        )
      );
    }
  };

  const onChangeCurrency = (value: typeof currency) => {
    setCurrency(value);
  };

  const onChangeRewardRangeType = (
    severity: (typeof rewardRange)[number]["severity"],
    type: (typeof rewardRange)[number]["type"]
  ) => {
    setRewardRange((prev) =>
      prev.map((range) => {
        if (range.severity === severity) {
          if (type.value === "point") {
            return { ...range, type, from: 0, to: 0 };
          }
          return { ...range, type };
        }
        return range;
      })
    );
  };

  const onChangeRewardRangeFrom = (
    severity: (typeof rewardRange)[number]["severity"],
    from: (typeof rewardRange)[number]["from"]
  ) => {
    setRewardRange((prev) =>
      prev.map((range) =>
        range.severity === severity ? { ...range, from } : range
      )
    );
  };

  const onChangeRewardRangeTo = (
    severity: (typeof rewardRange)[number]["severity"],
    to: (typeof rewardRange)[number]["to"]
  ) => {
    setRewardRange((prev) =>
      prev.map((range) =>
        range.severity === severity ? { ...range, to } : range
      )
    );
  };

  const saveAsDraft = () => {
    if (!workspaceId) return;

    // If you change this, you should probably change createProgram too
    bugBountyServices
      .create_program(workspaceId, {
        // TODO: fetch mssp from resource and use that id instead of this hard fixed
        mssp_id:
          managementMode === BugBountyManagementModeEnum.BY_PROVIDER
            ? "bshp7g"
            : null,
        type: BugBountyProgramTypeValueEnum[programType],
        name: briefName,
        alias: alias,
        description: { en: description, vi: description },
        target: scope
          // Just in case someone make stupid changes. Do not remove this without checking the note 3 lines below and make sure Typescript is working properly.
          .filter(
            (scopeItem) =>
              scopeItem.assetType.value !== undefined &&
              scopeItem.scope.value !== undefined
          )
          .map((scopeItem) => ({
            name: scopeItem.assets,
            // These ("type" & "scope") should never be "undefined" as we have filtered it 3 lines above, so we can safely assert type.
            type: scopeItem.assetType.value as string,
            scope: scopeItem.scope.value as string,
            link_download: "",
            file: "",
          })),
        reward: rewardRange.map((range) => ({
          type: range.type.value ?? "",
          severity: range.severity,
          range_from: range.from,
          range_to: range.to,
        })),
        metadata: { en: policy, vi: policy },
        color,
        logo: avatarUrl ? avatarUrl : undefined,
      })
      .then(() => {
        navigate({
          pathname: generatePath(pathname.BUG_BOUNTY_PROGRAMS, {
            workspaceId,
          }),
        });
      });
  };

  const createProgram = () => {
    if (!workspaceId) return;

    bugBountyServices
      .create_program(workspaceId, {
        // TODO: fetch mssp from resource and use that id instead of this hard fixed
        mssp_id:
          managementMode === BugBountyManagementModeEnum.BY_PROVIDER
            ? "bshp7g"
            : null,
        type: BugBountyProgramTypeValueEnum[programType],
        name: briefName,
        alias: alias,
        description: { en: description, vi: description },
        target: scope
          // Just in case someone make stupid changes. Do not remove this without checking the note 3 lines below and make sure Typescript is working properly.
          .filter(
            (scopeItem) =>
              scopeItem.assetType.value !== undefined &&
              scopeItem.scope.value !== undefined
          )
          .map((scopeItem) => ({
            name: scopeItem.assets,
            // These ("type" & "scope") should never be "undefined" as we have filtered it 3 lines above, so we can safely assert type.
            type: scopeItem.assetType.value as string,
            scope: scopeItem.scope.value as string,
            link_download: "",
            file: "",
          })),
        reward: rewardRange.map((range) => ({
          type: range.type.value ?? "",
          severity: range.severity,
          range_from: range.from,
          range_to: range.to,
        })),
        metadata: { en: policy, vi: policy },
        color,
        logo: avatarUrl ? avatarUrl : undefined,
      })
      .then((response) => {
        bugBountyServices
          .submit_program(workspaceId, response.alias)
          .then(() => {
            navigate({
              pathname: generatePath(pathname.BUG_BOUNTY_PROGRAMS, {
                workspaceId,
              }),
            });
          });
      });
  };

  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const stickySection = document.getElementById("STICKY_SECTION");
    if (stickySection) {
      const computedStyle = getComputedStyle(stickySection);
      setHeaderHeight(
        stickySection.getBoundingClientRect().height +
          parseInt(computedStyle.marginBottom)
      );
    }
  }, []);

  return (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        <div className="flex gap-1">
          <Button size="large" variant="secondary" onClick={saveAsDraft}>
            <SaveLine className="h-5 w-5" />
            {t("button.saveAsDraft")}
          </Button>
          <Button size="large" onClick={createProgram}>
            <CornerDownRightLine className="h-5 w-5" />
            {t("button.createProgram")}
          </Button>
        </div>
      </StickySection>
      <ContentSection className="flex-row">
        <div className="flex flex-col gap-12 w-2/3">
          <AddBugBountyManagementMode
            managementMode={managementMode}
            onChangeManagementMode={onChangeManagementMode}
          />
          <AddBugBountyProgramType
            programType={programType}
            onChangeProgramType={onChangeProgramType}
          />
          <div className="flex flex-col gap-6">
            <AddBugBountyProgramName
              briefName={briefName}
              alias={alias}
              onChangeBriefName={onChangeBriefName}
              onChangeAlias={onChangeAlias}
            />
            <AddBugBountyDescription
              description={description}
              onChangeDescription={onChangeDescription}
            />
          </div>
          <AddBugBountyScope
            scope={scope}
            invalidAssets={invalidAssets}
            activateEditScope={activateEditScope}
            deactivateEditScope={deactivateEditScope}
            addScope={addScope}
            removeScope={removeScope}
            onChangeScopeAssetType={onChangeScopeAssetType}
            onChangeScopeAssets={onChangeScopeAssets}
            onChangeScopeScope={onChangeScopeScope}
          />
          <AddBugBountyRewardRange
            status={BugBountyStatusResponseValue.DRAFT}
            currency={currency}
            rewardRange={rewardRange}
            onChangeCurrency={onChangeCurrency}
            onChangeRewardRangeType={onChangeRewardRangeType}
            onChangeRewardRangeFrom={onChangeRewardRangeFrom}
            onChangeRewardRangeTo={onChangeRewardRangeTo}
          />
          <AddBugBountyPolicy
            policy={policy}
            agreeTermsPolicies={agreeTermsPolicies}
            onChangePolicy={onChangePolicy}
            onClickAgreeTermsPolicies={onClickAgreeTermsPolicies}
          />
        </div>
        <div
          style={headerHeight ? { top: headerHeight } : undefined}
          className="w-1/3 h-fit flex flex-col sticky"
        >
          <AddBugBountyColorAndLogo
            programAlias={alias}
            programName={briefName}
            description={description}
            color={color}
            avatarUrl={avatarUrl}
            onChangeColor={onChangeColor}
            onChangeAvatarUrl={onChangeAvatarUrl}
          />
        </div>
      </ContentSection>
    </>
  );
};

export default AddBugBounty;
