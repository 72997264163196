// Libraries
import i18next from "i18next";

// Resources
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
// import { ReactComponent as GlobalLine } from "#src/assets/images/icons/global-line.svg";
// import { ReactComponent as SpyLine } from "#src/assets/images/icons/spy-line.svg";
import { ReactComponent as SaveLine } from "#src/assets/images/icons/save-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as DraftLine } from "#src/assets/images/icons/draft-line.svg";

// Components
import { TableActionLabel } from "#src/common/Table";

// Children
import { TrustCenterDocumentActionEnum } from "../enum";

const fixedTFunc = i18next.getFixedT(null, "trustCenter", "document.actions");

export const trustCenterDocumentActionList = [
  {
    key: TrustCenterDocumentActionEnum.EDIT,
    getLabel: () => (
      <TableActionLabel icon={<EditLine />} text={fixedTFunc("edit")} />
    ),
  },
  // {
  //   key: TrustCenterDocumentActionEnum.MARK_AS_PRIVATE,
  //   getLabel: () => (
  //     <TableActionLabel icon={<SpyLine />} text={fixedTFunc("markAsPrivate")} />
  //   ),
  // },
  // {
  //   key: TrustCenterDocumentActionEnum.MARK_AS_PUBLIC,
  //   getLabel: () => (
  //     <TableActionLabel
  //       icon={<GlobalLine />}
  //       text={fixedTFunc("markAsPublic")}
  //     />
  //   ),
  // },
  {
    key: TrustCenterDocumentActionEnum.PUBLISH,
    getLabel: () => (
      <TableActionLabel
        icon={<CheckboxCircleLine />}
        text={fixedTFunc("publish")}
      />
    ),
  },
  {
    key: TrustCenterDocumentActionEnum.PUT_TO_DRAFT,
    getLabel: () => (
      <TableActionLabel icon={<DraftLine />} text={fixedTFunc("putToDraft")} />
    ),
  },
  {
    key: TrustCenterDocumentActionEnum.DOWNLOAD,
    getLabel: () => (
      <TableActionLabel icon={<SaveLine />} text={fixedTFunc("download")} />
    ),
  },
  {
    key: TrustCenterDocumentActionEnum.DELETE,
    getLabel: () => (
      <TableActionLabel
        icon={<DeleteBin6Line />}
        text={fixedTFunc("delete")}
        warning
      />
    ),
  },
];
