import { useTranslation } from "react-i18next";
import type { IFilterItem } from "#src/@types/common";
import { FilterDropdown, SearchBox } from "#src/common/Table/Filter";
import { paymentStatusItems } from "#src/config/filter/workspace";

interface IBillingServiceFilterProps {
  status: IFilterItem | null;
  searchKeyword: string;
  onChangeStatusSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
}

const BillingOrderFilter = ({
  status,
  searchKeyword,
  onChangeStatusSelection,
  onChangeSearchKeyword,
}: IBillingServiceFilterProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.tab.order.filter",
  });

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <FilterDropdown
          label={t("status.label")}
          selected={status}
          options={paymentStatusItems}
          onChangeSelection={onChangeStatusSelection}
        />
      </div>
      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default BillingOrderFilter;
