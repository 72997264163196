import type { RouteObject } from "react-router-dom";
import i18next from "i18next";
import { helperPathname, pathname } from "#src/config/pathname";

import DeviceControl from "#src/pages/devices/control";

const breadcrumbTFunc = i18next.getFixedT(null, "layout", "topbar.breadcrumb");

export const deviceRoute: RouteObject = {
  path: helperPathname.DEVICES,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("device.label"),
      path: pathname.CONTROL,
    }),
  },
  children: [
    {
      path: pathname.CONTROL,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("device.control.label"),
          path: pathname.CONTROL,
        }),
      },
      element: <DeviceControl />,
    },
  ],
};
