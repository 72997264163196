import { twMerge } from "tailwind-merge";

/** Label for member role or whatever this fits
 * @param name
 * @param color - this should be a valid className, preferably Tailwind's color-ralated
 */
export const RoleLabel = ({ name, color }: { name: string; color: string }) => {
  return (
    <span
      className={twMerge(
        "px-3 py-1.5 text-white rounded-md font-medium-12",
        color
      )}
    >
      {name}
    </span>
  );
};
