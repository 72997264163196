// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Dropdown } from "@lockerpm/design";
import { z } from "zod";
import { AxiosError } from "axios";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import type { IDrawerAdapterProps, IFilterItem } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import {
  type FormErrorItem,
  InputField,
} from "#src/common/helper/wrapper/InputField";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";

// API-related
import { memberRoleItems } from "#src/config/filter/workspace";
import { MemberRoleEnum } from "#src/config/filter/workspace/enum";
import workspaceServices from "#src/services/workspace";

interface IAddMemberDrawerProps extends IDrawerAdapterProps {}

const AddMemberDrawer = ({
  workspaceId,
  open,
  onClose,
  onRefresh,
}: IAddMemberDrawerProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "member.addMemberDrawer",
  });

  const [emailInput, setEmailInput] = useState<string>("");
  // This state is initialized with the first "role", which should be "member". Might have to take care of this if the role list is being changed
  const [role, setRole] = useState<IFilterItem>(memberRoleItems[0]);
  const [pending, setPending] = useState<boolean>(false);
  const [errorObj, setErrorObj] = useState<{ email: FormErrorItem }>({
    email: undefined,
  });

  const onCloseDrawerAndCleanup = () => {
    onClose();
    setEmailInput("");
    setRole(memberRoleItems[0]);
  };

  const onSelectRole = (key: string) => {
    const chosenRole = memberRoleItems.find((item) => item.key === key);
    if (chosenRole) {
      setRole(chosenRole);
    }
  };

  const onClickAddMember = () => {
    if (workspaceId && role.value) {
      setPending(true);
      workspaceServices
        .add_member(workspaceId, [
          {
            email: emailInput,
            role: role.value,
          },
        ])
        .then((response) => {
          if (response.success) {
            onRefresh();
            onCloseDrawerAndCleanup();
          }
        })
        .catch((error) => {
          if (
            error instanceof AxiosError &&
            error.response &&
            error.response.status === 400
          ) {
            const getErrorData = parseBadRequest(
              error.response.data,
              z.object({
                members: z.object({
                  email: z.optional(z.array(z.string())),
                }),
              })
            );
            setErrorObj((prev) => ({
              ...prev,
              email: getErrorData.details.members.email
                ? () => (getErrorData.details.members.email as string[])[0]
                : undefined,
            }));
          } else {
            apiErrorHandler(error, {
              toastMessage: t("notification.addMember.fail"),
            });
          }
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onCloseDrawerAndCleanup}
      closable={false}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton
          variant="label-grey"
          size={9}
          onClick={onCloseDrawerAndCleanup}
        >
          <CloseLine />
        </IconButton>
      }
      footer={
        <div className="flex flex-col gap-3">
          <Button
            className="w-full"
            size="large"
            onClick={onClickAddMember}
            disabled={emailInput.length === 0}
            pending={pending}
          >
            {t("button.addMember")}
          </Button>
          <Button
            className="w-full"
            size="large"
            onClick={onCloseDrawerAndCleanup}
            variant="secondary"
          >
            {t("button.cancel")}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-6 p-6">
        <InputField
          title={t("field.email.label")}
          placeholder={t("field.email.placeholder")}
          value={emailInput}
          onChangeValue={(value) => {
            setEmailInput(value);
          }}
          error={errorObj.email}
          onChangeError={(value) => {
            setErrorObj((prev) => ({ ...prev, email: value }));
          }}
          validation={{ required: true }}
        />
        <div className="flex flex-col gap-3">
          <h4>{t("field.role.label")}</h4>
          <Dropdown
            menu={{
              items: memberRoleItems
                .filter((item) => item.key !== MemberRoleEnum.OWNER)
                .map((item) => ({
                  key: item.key,
                  label: (
                    <DropdownItemLabel
                      selected={role.key === item.key}
                      syncWidthId="dropdown-frequency"
                    >
                      {item.getLabel()}
                    </DropdownItemLabel>
                  ),
                })),
              onClick: ({ key }) => {
                onSelectRole(key);
              },
            }}
            trigger={["click"]}
          >
            <DropdownTriggerButton id="dropdown-role">
              {role.getLabel()}
              <ArrowDownSLine />
            </DropdownTriggerButton>
          </Dropdown>
        </div>
      </div>
    </Drawer>
  );
};

export default AddMemberDrawer;
