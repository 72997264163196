// Libraries
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useState } from "react";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/common/system/Button";

// Children
import ScheduleTable from "#src/components/scans/schedules/ScheduleTable";

const ScanSchedules = () => {
  const { t } = useTranslation("scanSchedules");

  const { workspaceId } = useParams<"workspaceId">();

  const [openAddScanDrawer, setOpenAddScanDrawer] = useState<boolean>(false);

  if (!workspaceId || workspaceId === "null") {
    // TODO: Replace with empty state illustration
    // return <>{t("notification.noWorkspaceSelected")}</>;
    return <>loading</>;
  }

  return (
    <>
      <StickySection>
        <div className="flex flex-col">
          <h1>{t("title")}</h1>
        </div>
        <Button
          size="large"
          onClick={() => setOpenAddScanDrawer((prev) => !prev)}
        >
          <AddLine />
          {t("button.newSchedule")}
        </Button>
      </StickySection>
      <ContentSection>
        <ScheduleTable
          workspaceId={workspaceId}
          openAddScanDrawer={openAddScanDrawer}
          setOpenAddScanDrawer={setOpenAddScanDrawer}
        />
      </ContentSection>
    </>
  );
};

export default ScanSchedules;
