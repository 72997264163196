// Libraries
import { useTranslation } from "react-i18next";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Dropdown } from "@lockerpm/design";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { ReactComponent as ImageCircleFill } from "#src/assets/images/icons/image-circle-fill.svg";

// General
import global from "#src/config/global";

// Components
import { TableActionLabel } from "#src/common/Table";
import { ObjectImage } from "#src/common/system/Object";
import { IconButton } from "#src/common/system/Button";
import { toPascalCase } from "#src/utils/common";
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";

// API-related
import bugBountyServices, {
  type IBugBountyProgram,
} from "#src/services/bugBounty";

// Children
import { getColorByBugBountyStatus } from "#src/components/mssp/bug-bounty/color";

interface IBugBountyTableItemProps {
  data: IBugBountyProgram;
  actionList: ItemType[];
  onClickActionItems: (key: string, item: IBugBountyProgram) => void;
}

const BugBountyTableItem = ({
  data,
  actionList,
  onClickActionItems,
}: IBugBountyTableItemProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyList.card",
  });

  return (
    <Link
      to={`details/${data.alias}`}
      className="bg-bright-grey rounded-md flex-1 border border-light-grey min-w-[14rem] text-current no-underline hover:text-current"
    >
      <div className="relative h-[6.75rem]">
        <div
          className="h-[4.5rem] rounded-t-md"
          style={{ backgroundColor: data.org.color }}
        ></div>
        <div className="absolute left-0 right-0 bottom-0 mx-auto p-1 h-fit w-fit">
          <div className="bg-white border border-light-grey h-[3.75rem] w-[3.75rem] min-w-[3.75rem] flex justify-center items-center rounded-md">
            <ObjectImage
              data={data.org.logo}
              className="h-[3.75rem] w-[3.75rem] min-w-[3.75rem] rounded-md"
            >
              <ImageCircleFill className="fill-medium-grey h-10 w-10" />
            </ObjectImage>
          </div>
        </div>
        <div
          className="absolute top-4 right-4 group rounded-md hover:bg-white"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Dropdown
            menu={{
              items: actionList,
              onClick: ({ key, domEvent }) => {
                domEvent.preventDefault();
                domEvent.stopPropagation();
                onClickActionItems(key, data);
              },
            }}
            trigger={["click"]}
            placement="bottomRight"
          >
            <IconButton variant="text" size={9}>
              <MoreLine className="fill-white group-hover:fill-dark-blue" />
            </IconButton>
          </Dropdown>
        </div>
      </div>
      <div className="flex flex-col items-center pt-3 p-6 gap-6">
        <span className="font-medium-20 text-center break-all line-clamp-2 h-[3.25rem]">
          {data.alias}
        </span>
        <div className="bg-white border border-grey px-3 py-2 rounded-md font-regular-14 text-hard-grey flex items-center gap-1">
          <div
            className={`h-2 w-2 min-h-[0.5rem] min-w-[0.5rem] rotate-45 rounded-sm ${getColorByBugBountyStatus(
              data.status
            )}`}
          ></div>
          {toPascalCase(data.status)}
        </div>
        <div className="w-full flex flex-wrap justify-center items-center gap-1 min-h-[2.75rem]">
          <span className="text-hard-grey">{t("managedBy")}</span>
          <span>{data.mssp ? data.mssp.name : "---"}</span>
        </div>
        <div className="font-medium-14-forced">
          {t("createdAt")} {dayjs.unix(data.created_time).format("DD.MM.YYYY")}
        </div>
      </div>
    </Link>
  );
};

interface IBugBountyTableGridProps {
  workspaceId: string;
  bugBountyList: IBugBountyProgram[] | null;
  isLoading: boolean;
  refreshBugBountyList: () => void;
}

const BugBountyTableGrid = ({
  workspaceId,
  bugBountyList,
  isLoading,
  refreshBugBountyList,
}: IBugBountyTableGridProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyList",
  });

  const BugBountyActionEnum = {
    DELETE: "BUG_BOUNTY_ACTION_ENUM_DELETE",
  };

  const bugBountyActionList = [
    {
      key: BugBountyActionEnum.DELETE,
      label: (
        <TableActionLabel
          icon={<DeleteBin6Line />}
          text={t("actions.delete")}
          warning
        />
      ),
    },
  ];

  const onDeleteBugBountyProgram = (alias: string) => {
    bugBountyServices.delete_program(workspaceId, alias).then(() => {
      refreshBugBountyList();
    });
  };

  const onClickActionItems = (key: string, item: IBugBountyProgram) => {
    switch (key) {
      case BugBountyActionEnum.DELETE:
        global.confirm(() => onDeleteBugBountyProgram(item.alias));
        return;
    }
  };

  return (
    <>
      {isLoading || bugBountyList === null ? (
        <LoadingState />
      ) : bugBountyList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
          {bugBountyList.map((item) => (
            <BugBountyTableItem
              key={`bugBountyTable-item${item.alias}`}
              data={item}
              actionList={bugBountyActionList}
              onClickActionItems={onClickActionItems}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default BugBountyTableGrid;
