import { useTranslation } from "react-i18next";
import { IChartPlotProps } from "#src/@types/chart";
import { DonutChartV2 } from "#src/commonV2/charts/Donut";
import { ChartLegendsV2 } from "#src/commonV2/charts/helper/legends";
import { getChartTooltipV2 } from "#src/commonV2/charts/helper/tooltip";
import { EmptyStateV2 } from "#src/commonV2/EmptyState";

export const EndpointOverviewGraphWrapper = ({
  title,
  chartData,
  getUnit,
}: {
  title: string;
  chartData: IChartPlotProps[];
  getUnit: (count: number) => string;
}) => {
  const { t } = useTranslation("endpoint", {
    keyPrefix: "overview.graphs",
  });

  return (
    <div className="p-4 flex flex-col items-center text-center gap-4 bg-white shadow-xs border border-gray-v2-200 rounded-xl overflow-hidden">
      <span className="text-lg font-semibold text-gray-v2-900 min-h-[3.5rem] px-5">
        {title}
      </span>
      <div className="flex flex-col items-center gap-4">
        {chartData.reduce((prev, cur) => prev + cur.value, 0) > 0 ? (
          <>
            <DonutChartV2
              data={chartData}
              tooltip={getChartTooltipV2}
              getUnit={getUnit}
            />
            <ChartLegendsV2 data={chartData} showPercentage />
          </>
        ) : (
          <EmptyStateV2
            title={t("emptyState.title")}
            supportingText={t("emptyState.supportingText")}
            type="featured-icon"
          />
        )}
      </div>
    </div>
  );
};
