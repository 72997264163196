import { useEffect } from "react";

/** Sync search param with the value passed without forcing a refresh. Only takes 1 pair of key-value as parameters
 * This is a replacement for *setSearchParams* from *useSearchParams* hook from *react-router* in the particular use case mentioned above.
 * @see https://github.com/kentcdodds/kentcdodds.com/blob/main/app/utils/misc.tsx#L303 useUpdateQueryStringValueWithoutNavigation
 * */
export const useSyncSearchParam = (key: string, value: string) => {
  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const oldQuery = currentSearchParams.get(key) ?? "";
    if (value === oldQuery) return;

    if (value) {
      currentSearchParams.set(key, value);
    } else {
      currentSearchParams.delete(key);
    }
    const newUrl = [window.location.pathname, currentSearchParams.toString()]
      .filter(Boolean)
      .join("?");
    window.history.replaceState(null, "", newUrl);
  }, [key, value]);
};
