// Libraries
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { type Dispatch, type SetStateAction } from "react";

// Resources
import { ReactComponent as SearchEyeLine } from "#src/assets/images/icons/search-eye-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as Attachment2 } from "#src/assets/images/icons/attachment-2.svg";
import { ReactComponent as FileImageLine } from "#src/assets/images/icons/file-image-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";
import usFlag from "#src/assets/images/flags/us.png";
import vnFlag from "#src/assets/images/flags/vn.png";

// Components
import { Button } from "#src/common/system/Button";
import { ObjectImage } from "#src/common/system/Object";
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";
import { FileUploadActionValue, uploadFile } from "#src/utils/uploadFile";
import ConfigSectionWrapper from "#src/common/helper/wrapper/ConfigSection";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { Flag } from "#src/common/Flag";
import {
  type FormErrorItem,
  InputField,
} from "#src/common/helper/wrapper/InputField";

// API-related
import trustCenterServices from "#src/services/trustCenter";

export interface ITrustCenterConfigSecurityProfileStates {
  og_details: { lang: string; og_title: string; og_description: string }[];
  og_logo: string;
}

interface TrustCenterConfigProfileTabSecurityProps {
  workspaceId: string;
  originalSettings: ITrustCenterConfigDetails;
  setOriginalSettings: Dispatch<SetStateAction<ITrustCenterConfigDetails>>;
}

const TrustCenterConfigProfileTabSecurity = ({
  workspaceId,
  originalSettings,
  setOriginalSettings,
}: TrustCenterConfigProfileTabSecurityProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.tab.profile.securityProfile",
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [securityProfile, setSecurityProfile] =
    useState<ITrustCenterConfigSecurityProfileStates>({
      og_details: [],
      og_logo: "",
    });

  const [pending, setPending] = useState<boolean>(false);

  const [errorObj, setErrorObj] = useState<{
    ogTitle: FormErrorItem;
    ogDescription: FormErrorItem;
    ogLogo: FormErrorItem;
  }>({ ogTitle: undefined, ogDescription: undefined, ogLogo: undefined });

  useEffect(() => {
    setSecurityProfile({
      og_details:
        originalSettings.profile.og_details.length > 0
          ? originalSettings.profile.og_details
          : [{ lang: "en", og_title: "", og_description: "" }],
      og_logo: originalSettings.profile.og_logo,
    });
  }, [originalSettings.profile]);

  const onCancelEditProfileSecurity = () => {
    setIsEditing(false);
    setSecurityProfile({
      og_details:
        originalSettings.profile.og_details.length > 0
          ? originalSettings.profile.og_details
          : [{ lang: "en", og_title: "", og_description: "" }],
      og_logo: originalSettings.profile.og_logo,
    });
  };

  const onUploadAttachments = (files: File[]) => {
    for (const file of files) {
      const fileType = file.type.split("/")[0];
      if (fileType === "image") {
        if (file.size > 3145728) {
          setErrorObj((prev) => ({
            ...prev,
            ogLogo: () => t("errorMessage.logo.exceededMaxSize"),
          }));
          return;
        }
        uploadFile(file, FileUploadActionValue.TRUST_CENTER_OG_LOGO, {
          workspace_id: workspaceId,
        })
          .then((signedResponse) => {
            setSecurityProfile((prev) => ({
              ...prev,
              og_logo: signedResponse.signed_url,
            }));
            setErrorObj((prev) => ({ ...prev, ogLogo: undefined }));
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("notification.uploadFail"),
            });
          });
      }
    }
  };

  const onSaveEditProfileSecurity = async () => {
    setPending(true);
    trustCenterServices
      .update_trust_center_config_profile(workspaceId, {
        ...originalSettings.profile,
        og_details: securityProfile.og_details,
        og_logo: securityProfile.og_logo,
      })
      .then((response) => {
        setOriginalSettings(response);
        setSecurityProfile({
          og_details: response.profile.og_details,
          og_logo: response.profile.og_logo,
        });
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setPending(false);
        setIsEditing(false);
      });
  };

  const getAddPreviewButtonText = (lang: string) => {
    if (lang === "en") return t("addAPreviewIn.english");
    if (lang === "vi") return t("addAPreviewIn.vietnamese");
    return t("addAPreviewIn.english");
  };

  return (
    <ConfigSectionWrapper
      icon={<SearchEyeLine className="h-7 w-7" />}
      headerTitle={t("title")}
      headerButton={
        isEditing ? (
          <>
            <Button variant="secondary" onClick={onCancelEditProfileSecurity}>
              {t("button.cancel")}
            </Button>
            <Button
              variant="primary"
              disabled={securityProfile.og_details.some(
                (item) =>
                  !item.og_title ||
                  !item.og_description ||
                  item.og_title.length > 512 ||
                  item.og_description.length > 512
              )}
              pending={pending}
              className="pointer-events-auto"
              onClick={onSaveEditProfileSecurity}
            >
              {t("button.save")}
            </Button>
          </>
        ) : (
          <Button variant="secondary" onClick={() => setIsEditing(true)}>
            <EditLine />
            {t("button.edit")}
          </Button>
        )
      }
    >
      <div className="flex flex-col gap-6 p-6">
        <span className="font-regular-16-body text-hard-grey">
          {t("description")}
        </span>
        {securityProfile.og_details.length > 0
          ? securityProfile.og_details.map((item) => (
              <div
                key={`trustCenterConfig-preview-${item.lang}`}
                className="flex flex-col gap-6"
              >
                <div className="flex items-center justify-between h-5">
                  <div className="flex gap-3">
                    {item.lang === "en" ? (
                      <>
                        <Flag pngProp={usFlag} />
                        {t("language.english")}
                      </>
                    ) : item.lang === "vi" ? (
                      <>
                        <Flag pngProp={vnFlag} />
                        {t("language.vietnamese")}
                      </>
                    ) : null}
                  </div>
                  {isEditing && securityProfile.og_details.length > 1 ? (
                    <button
                      onClick={() => {
                        setSecurityProfile((prev) => ({
                          ...prev,
                          og_details: prev.og_details.filter(
                            (i) => i.lang !== item.lang
                          ),
                        }));
                      }}
                    >
                      <CloseLine className="h-5 w-5 fill-hard-grey" />
                    </button>
                  ) : null}
                </div>
                {isEditing ? (
                  <div className="flex flex-col gap-6 border border-light-grey p-6 rounded-md">
                    <InputField
                      title={t("ogTitle") + " (" + item.lang + ")"}
                      placeholder={t("ogTitleNote")}
                      value={item.og_title}
                      onChangeValue={(value) => {
                        setSecurityProfile((prev) => ({
                          ...prev,
                          og_details: prev.og_details.map((i) =>
                            i.lang === item.lang ? { ...i, og_title: value } : i
                          ),
                        }));
                      }}
                      error={errorObj.ogTitle}
                      onChangeError={(value) => {
                        setErrorObj((prev) => ({ ...prev, ogTitle: value }));
                      }}
                      validation={{ required: true, maxLength: 512 }}
                    />
                    <InputField
                      title={t("ogDescription") + " (" + item.lang + ")"}
                      type="textarea"
                      placeholder={t("ogDescriptionNote")}
                      value={item.og_description}
                      onChangeValue={(value) => {
                        setSecurityProfile((prev) => ({
                          ...prev,
                          og_details: prev.og_details.map((i) =>
                            i.lang === item.lang
                              ? { ...i, og_description: value }
                              : i
                          ),
                        }));
                      }}
                      error={errorObj.ogDescription}
                      onChangeError={(value) => {
                        setErrorObj((prev) => ({
                          ...prev,
                          ogDescription: value,
                        }));
                      }}
                      validation={{ required: true, maxLength: 512 }}
                      rows={5}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col gap-3">
                    <b>{item.og_title}</b>
                    <p className="whitespace-pre-line font-regular-16-body">
                      {item.og_description}
                    </p>
                  </div>
                )}
              </div>
            ))
          : null}
        {isEditing
          ? ["en", "vi"].map((langCode) =>
              securityProfile.og_details.some(
                (i) => i.lang === langCode
              ) ? null : (
                <Button
                  key={`trustCenter-profile-preview-addInOtherLang-${langCode}`}
                  variant="text"
                  onClick={() => {
                    setSecurityProfile((prev) => ({
                      ...prev,
                      og_details: [
                        ...prev.og_details,
                        { lang: langCode, og_title: "", og_description: "" },
                      ],
                    }));
                  }}
                >
                  <AddLine className="h-5 w-5" />
                  {getAddPreviewButtonText(langCode)}
                </Button>
              )
            )
          : null}
        <div className="flex flex-col gap-3">
          <h4>{t("ogLogo")}</h4>
          {isEditing ? (
            securityProfile.og_logo ? (
              <div className="relative p-2 w-fit flex items-center justify-center rounded-md overflow-hidden">
                <ObjectImage
                  data={securityProfile.og_logo}
                  className="w-12 h-12 rounded-md"
                />
                <div className="absolute top-0 right-0">
                  <button
                    className="m-1 h-6 w-6 flex items-center justify-center border border-warning-red-40% bg-warning-red-5% rounded-full"
                    onClick={() => {
                      setSecurityProfile((prev) => ({
                        ...prev,
                        og_logo: "",
                      }));
                    }}
                  >
                    <CloseLine className="w-4 h-4 fill-warning-red-danger" />
                  </button>
                </div>
              </div>
            ) : (
              <>
                <label className="flex-1 flex flex-col gap-3 items-center justify-center p-3 text-primary border border-dashed border-primary rounded-md cursor-pointer">
                  <input
                    id="file_uploader"
                    type="file"
                    className="hidden"
                    accept="image/*,application/pdf"
                    onChange={(e) => {
                      if (e.currentTarget.files) {
                        onUploadAttachments(Array.from(e.currentTarget.files));
                      }
                      // Reset file input.
                      e.currentTarget.value = "";
                    }}
                  />
                  <Attachment2 className="h-5 w-5" />
                  <span className="font-regular-12">
                    {t("uploadOgLogo.title")}
                  </span>
                  <span className="font-regular-12 text-hard-grey">
                    {t("uploadOgLogo.note")}
                  </span>
                </label>
                {errorObj.ogLogo ? (
                  <span className="font-medium-14-forced text-warning flex items-center gap-1">
                    <InformationLine className="h-5 w-5" />
                    {errorObj.ogLogo ? errorObj.ogLogo() : null}
                  </span>
                ) : null}
              </>
            )
          ) : securityProfile.og_logo ? (
            <ObjectImage
              data={securityProfile.og_logo}
              className="w-12 h-12 rounded-md"
            />
          ) : (
            <div className="m-2 p-3 h-12 w-12 border border-grey bg-light-grey flex items-center justify-center rounded-md">
              <FileImageLine className="fill-medium-grey h-6 w-6" />
            </div>
          )}
        </div>
      </div>
    </ConfigSectionWrapper>
  );
};

export default TrustCenterConfigProfileTabSecurity;
