// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import TimePopover from "#src/common/TimePopover";
import type { TimeConditionEnum } from "#src/common/TimePopover/TimePopoverContent";

interface IOverviewFilterProps {
  onChangeTimeConditionValue: (
    condition: string,
    from: number | null,
    to: number | null
  ) => void;
}

const OverviewFilter = ({
  onChangeTimeConditionValue,
}: IOverviewFilterProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "overview.filter",
  });

  const [timeCondition, setTimeCondition] = useState<TimeConditionEnum | null>(
    null
  );
  const [timePopoverOpen, setTimePopoverOpen] = useState<boolean>(false);
  const [timeFilterText, setTimeFilterText] = useState<string>("");

  const onChangeTimeCondition = (
    text: string,
    condition: TimeConditionEnum | null,
    from: number | null,
    to: number | null
  ) => {
    setTimeCondition(condition);
    setTimeFilterText(text);
    onChangeTimeConditionValue(text, from, to);
  };

  return (
    <div className="flex justify-between gap-1">
      <TimePopover
        label={t("timePopover.label")}
        timeCondition={timeCondition}
        textDisplay={timeFilterText}
        open={timePopoverOpen}
        setOpen={(value) => setTimePopoverOpen(value)}
        onChangeTime={onChangeTimeCondition}
      />
    </div>
  );
};

export default OverviewFilter;
