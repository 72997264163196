const constants = {
  CSPF_VERSION: "2.3.1",
  SERVICE_SCOPE: "whitehub",
  DEFAULT_PAGE_SIZE: 50,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  DEBOUNCE_TIME: 350,
  TOAST_LIFETIME: 4000,
  AVAILABLE_SOCIAL_NAME: [
    "website",
    "twitter",
    "facebook",
    "behance",
    "instagram",
    "linkedin",
    "pinterest",
    "reddit",
    "skype",
    "telegram",
    "tumblr",
    "twitch",
    "yahoo",
    "youtube",
    "xing",
  ],
  // It is ok to leave this id here without worrying about security risks. Ref: https://api.slack.com/authentication/best-practices
  SLACK_CLIENT_ID: "640194215968.642365833686",
  // TODO: move these 2 below to endpoint file
  TRUST_CENTER_URL_BASE:
    process.env.REACT_APP_ENVIRONMENT === "staging" ||
    process.env.REACT_APP_ENVIRONMENT === "dev"
      ? "https://cystack.org/trust/"
      : "https://cystack.net/trust/",
  UPLOAD_SERVICE: "r2",

  SOLUTION_LINKS: {
    LOCKER_SECRET_MANAGER: "https://secrets.locker.io",
    LOCKER_PASSWORD_MANAGER: "https://passwords.locker.io",
    SAFE_CHAIN: "https://safechain.org/",
    ENDPOINT: "https://cystack.net/vi/endpoint",
    TRUST_CENTER: "/:workspaceId/trust-center",
    DATA_LEAK: "/:workspaceId/data-leak",
    VULNSCAN: "https://cystack.net/vi/vulnscan",
    SOURCE_CODE_AUDIT: "https://cystack.net/vi/contact-sales",
    BLOCKCHAIN_AUDIT:
      "https://cystack.net/vi/services/blockchain-protocol-audit",
    PENETRATION_TESTING: "https://cystack.net/vi/services/penetration-testing",
    RED_TEAM: "https://cystack.net/vi/red-teaming",
    INFRASTRUCTURE_AUDIT: "https://cystack.net/vi/services/infrastructure",
    COMPLIANCE_AUDIT_SERVICE: "https://cystack.net/vi/contact-sales",
    SOC: "https://cystack.net/vi/contact-sales",
    VULNERABILITY_MANAGEMENT:
      "https://cystack.net/vi/services/vulnerability-management",
    WHITEHUB_BUG_BOUNTY: "https://whitehub.net/",
  },
};

export default constants;
