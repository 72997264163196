import { useState } from "react";
import { generatePath, Link, matchPath, UIMatch } from "react-router-dom";
import { Accordion } from "#src/common/system/Accordion";
import { ISidebarProductItem } from "#src/layoutV2/types";
import { ChevronDown } from "@untitled-ui/icons-react";
import { SidebarPageItemRender } from "./Page";

/** A product will always be displayed like this on sidebar, regardless which type it is. */
const SidebarProductItemLabel = ({
  product,
  active,
  expanded = false,
  onToggleExpanded,
}: {
  product: ISidebarProductItem;
  active: boolean;
  expanded?: boolean;
  onToggleExpanded?: () => void;
}) => {
  return (
    <button
      className={`flex items-center justify-between gap-3 px-3 py-2 my-0.5 rounded-md w-full transition-all ${active ? "bg-brand-v2-50" : "hover:bg-gray-v2-50"}`}
      onClick={onToggleExpanded}
    >
      <div className="flex items-center gap-2.5">
        <product.icon
          className={`h-5 w-5 min-w-[1.25rem] ${active ? "text-brand-v2-700" : "text-gray-v2"}`}
        />
        <span
          className={`text-md font-semibold text-start ${active ? "text-brand-v2-700" : "text-gray-v2-700"}`}
        >
          {product.getLabel()}
        </span>
      </div>
      {product.children && product.children.length > 0 ? (
        <ChevronDown
          className={`h-4 w-4 min-w-[1rem] text-gray-v2-400 transition-all ${expanded ? "rotate-180" : ""}`}
        />
      ) : null}
    </button>
  );
};

export const SidebarProductItemRender = ({
  product,
  workspaceId,
  pathMatches,
}: {
  product: ISidebarProductItem;
  workspaceId: string;
  pathMatches: UIMatch[];
}) => {
  const [expanded, setExpanded] = useState<boolean>(
    pathMatches.some((match) => matchPath(product.path, match.pathname))
  );

  const active = expanded
    ? Boolean(
        matchPath(product.path, pathMatches[pathMatches.length - 1].pathname)
      )
    : pathMatches.some((match) => matchPath(product.path, match.pathname));

  // If product have children page, we render the list of pages.
  if (product.children) {
    return (
      <Accordion
        label={
          <SidebarProductItemLabel
            product={product}
            active={active}
            expanded={expanded}
            onToggleExpanded={() => {
              setExpanded((prev) => !prev);
            }}
          />
        }
        expanded={expanded}
      >
        <div className="flex flex-col">
          {product.children.map((page) => (
            <SidebarPageItemRender
              key={`sidebar-item-page-${page.path}`}
              path={page.path}
              getLabel={page.getLabel}
              pathMatches={pathMatches}
            />
          ))}
        </div>
      </Accordion>
    );
  }

  // If product have only 1 page, we render the path to product.
  return (
    <Link
      // Should always work unless there's a dumb path.
      to={generatePath(product.path, { workspaceId })}
    >
      <SidebarProductItemLabel product={product} active={active} />
    </Link>
  );
};
