import { useCallback } from "react";
import { ButtonV2 } from "#src/commonV2/Button";
import { LoaderV2 } from "#src/commonV2/LoadingState";
import { Modal } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  deviceName: string;
};

export const PendingRemoteAccessModal = (props: Props) => {
  const { isOpen, onClose, deviceName } = props;
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- METHODS ---------------

  const cancelRequest = useCallback(() => {
    // TODO: send api
    onClose();
  }, [onClose]);

  // --------------- RENDER ---------------

  return (
    <Modal
      title={t("modals.remoteAccess.title", { deviceName })}
      open={isOpen}
      footer={null}
      maskClosable={false}
      onCancel={onClose}
      className="v2 max-w-[550px]"
      centered
      width="90%"
    >
      <div className="v2">
        <p className="text-md text-gray-v2-600 mb-3">
          {t("modals.remoteAccess.waiting_response", { deviceName })}
        </p>
        <p className="text-md text-gray-v2-600">
          {t("modals.remoteAccess.timeout_desc")}
        </p>
        <div className="flex justify-center mt-5">
          <LoaderV2 baseSize={2.5} />
        </div>
        <div className="flex justify-end mt-5">
          <ButtonV2
            variant="primary"
            size="lg"
            color="error"
            onClick={cancelRequest}
          >
            <p>{t("modals.remoteAccess.cancel")}</p>
          </ButtonV2>
        </div>
      </div>
    </Modal>
  );
};
