import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import type { IPaginatedResponse } from "#src/@types/api";
import constants from "#src/config/constants";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// TODO: maybe we should not use this at all? Try loader and useFetcher
/**
 * Fetching & updating data from a fetch and an array of parameters
 * @param fetch the fetch function used to fetch data
 * @param arg
 * @param fetchStatistics (optional) fetch statistics for the data loaded from `fetch`. Should have the same parameters with the `fetch` function
 */
function useFetchPaginated<GData, GStats>(
  fetch: (...arg: unknown[]) => Promise<IPaginatedResponse<GData>>,
  arg: Parameters<typeof fetch>,
  fetchStatistics?: (...arg: unknown[]) => Promise<GStats>
): {
  list: GData[] | null;
  count: number;
  isLoading: boolean;
  updateData: () => void;
  statistics: GStats | null;
} {
  const [list, setList] = useState<GData[] | null>(null);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<GStats | null>(null);

  const fetchData = useCallback(
    (
      fetch: (...arg: unknown[]) => Promise<IPaginatedResponse<GData>>,
      fetchStatistics: ((...arg: unknown[]) => Promise<GStats>) | undefined,
      ...arg: Parameters<typeof fetch>
    ) => {
      setLoading(true);
      fetch(...arg)
        .then((response) => {
          setCount(response.count);
          setList(response.results);
          setLoading(false);
        })
        .catch(apiErrorHandler);
      if (fetchStatistics) {
        fetchStatistics(...arg)
          .then((response) => {
            setStatistics(response);
          })
          .catch(apiErrorHandler);
      }
    },
    []
  );

  const debouncedFetchData = useDebouncedCallback(
    fetchData,
    constants.DEBOUNCE_TIME
  );

  useEffect(() => {
    debouncedFetchData(fetch, fetchStatistics, ...arg);
  }, [debouncedFetchData, fetch, fetchStatistics, arg]);

  return {
    list,
    count,
    isLoading: isLoading || list === null,
    updateData: () => {
      fetchData(fetch, fetchStatistics, ...arg);
    },
    statistics,
  };
}

export default useFetchPaginated;
