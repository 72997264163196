import type { IChartPlotProps } from "#src/@types/chart";

interface IChartLegendsProps {
  data: IChartPlotProps[];
  showPercentage?: boolean;
}

export const ChartLegendsV2 = ({
  data,
  showPercentage = false,
}: IChartLegendsProps) => {
  const totalCount = data.reduce((prev, cur) => {
    return prev + cur.value;
  }, 0);

  return (
    <div className="flex flex-col gap-1">
      {data.map((item) => (
        <div key={`legend${item.id}`} className="flex items-center gap-1.5">
          <div
            style={{ backgroundColor: item.fill }}
            className="rounded-full w-2.5 h-2.5"
          />
          <span className="text-sm text-gray-v2-600">
            {showPercentage && totalCount > 0
              ? ((item.value / totalCount) * 100).toFixed(0) + "% "
              : null}
            {item.name}
          </span>
        </div>
      ))}
    </div>
  );
};
