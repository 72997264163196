import i18next from "i18next";

const fixedTFunc = i18next.getFixedT(null, "endpoint", "policies.table.filter");

export const operatingSystemFilterItems = [
  {
    key: "macos",
    getLabel: () => fixedTFunc("operatingSystem.macOs"),
    value: "macos",
  },
  {
    key: "windows",
    getLabel: () => fixedTFunc("operatingSystem.windows"),
    value: "windows",
  },
  {
    key: "linux",
    getLabel: () => fixedTFunc("operatingSystem.linux"),
    value: "linux",
  },
];

export const policyStatusFilterItems = [
  {
    key: "enabled",
    getLabel: () => fixedTFunc("policyStatus.enabled"),
    value: "1",
  },
  {
    key: "disabled",
    getLabel: () => fixedTFunc("policyStatus.disabled"),
    value: "0",
  },
];
