// Libraries
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import TimePopover from "#src/common/TimePopover";
import type { TimeConditionEnum } from "#src/common/TimePopover/TimePopoverContent";
import {
  SeverityDropdownItemLabel,
  SeverityDropdownRender,
} from "#src/common/composed/SeverityDropdownHelper";
import { FilterDropdown, SearchBox } from "#src/common/Table/Filter";
import VulnerabilityStatusFilterDropdown from "#src/common/Table/Filter/StatusFilterDropdown";

// API-related
import dataLeakServices from "#src/services/dataLeak";
import {
  severityItems,
  vulnerabilityStatusItems,
} from "#src/config/filter/vulnerability";

interface IDataLeakFilterProps {
  workspaceId: string;
  severity: IFilterItem | null;
  status: IFilterItem[] | null;
  selectedKeyword: IFilterItem | null;
  searchKeyword: string;
  onChangeTimeConditionValue: (
    condition: string,
    from: number,
    to: number
  ) => void;
  onChangeSeveritySelection: (selected: IFilterItem | null) => void;
  onChangeStatusSelection: (
    selected: IFilterItem[] | IFilterItem | null
  ) => void;
  onChangeSelectedKeywordSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
}

const DataLeakFilter = ({
  workspaceId,
  severity,
  status,
  selectedKeyword,
  searchKeyword,
  onChangeTimeConditionValue,
  onChangeSeveritySelection,
  onChangeStatusSelection,
  onChangeSelectedKeywordSelection,
  onChangeSearchKeyword,
}: IDataLeakFilterProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "foundLeaks.filter",
  });

  const [keywordList, setKeywordList] = useState<IFilterItem[]>([]);

  const [timeCondition, setTimeCondition] = useState<TimeConditionEnum | null>(
    null
  );
  const [timePopoverOpen, setTimePopoverOpen] = useState<boolean>(false);
  const [timeFilterText, setTimeFilterText] = useState<string>("");

  const fetchDataLeakKeywordList = useCallback(() => {
    dataLeakServices.list_keywords_shortly(workspaceId).then((response) => {
      setKeywordList(
        response.map((item) => ({
          key: item.id.toString(),
          value: item.id.toString(),
          getLabel: () => item.keyword,
        }))
      );
    });
  }, [workspaceId]);

  const onChangeTimeCondition = (
    text: string,
    condition: TimeConditionEnum,
    from: number,
    to: number
  ) => {
    setTimeCondition(condition);
    setTimeFilterText(text);
    onChangeTimeConditionValue(text, from, to);
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchDataLeakKeywordList();
    }

    return () => {
      ignore = true;
    };
  }, [fetchDataLeakKeywordList]);

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <TimePopover
          label={t("timePopover.label")}
          timeCondition={timeCondition}
          textDisplay={timeFilterText}
          open={timePopoverOpen}
          setOpen={(value) => setTimePopoverOpen(value)}
          onChangeTime={onChangeTimeCondition}
        />
        <FilterDropdown
          label={t("severity.label")}
          selected={severity}
          options={severityItems}
          onChangeSelection={onChangeSeveritySelection}
          CustomLabel={SeverityDropdownItemLabel}
          DropdownRender={SeverityDropdownRender}
        />
        <VulnerabilityStatusFilterDropdown
          label={t("status.label")}
          selected={status}
          options={vulnerabilityStatusItems}
          onChangeSelection={onChangeStatusSelection}
        />
        {selectedKeyword ? (
          // TODO: this should let us choose multiple
          <FilterDropdown
            label={t("keyword.label")}
            selected={selectedKeyword}
            options={keywordList}
            onChangeSelection={onChangeSelectedKeywordSelection}
          />
        ) : null}
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default DataLeakFilter;
