// Libraries
import { matchPath, useMatches } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Resources=
import { ReactComponent as ArrowGoBackLine } from "#src/assets/images/icons/arrow-go-back-line.svg";

// Children
import SidebarHeader from "../sidebar/Header";
import { noSidebarPaths } from "../pathConfig";
import { LayoutTopbarBreadcrumb } from "./Breadcrumb";
import { LayoutTopbarRightGroup } from "./RightGroup";

export const LayoutTopbar = () => {
  useTranslation();

  const pathMatches = useMatches();

  const noSidebarMode = pathMatches.some((match) =>
    noSidebarPaths.some((path) => matchPath(path, match.pathname))
  );

  return (
    <>
      <div
        className={`flex-1 flex justify-center h-16 w-full border-b ${
          noSidebarMode
            ? "bg-dark-blue border-grey-blue pr-16"
            : "border-light-grey"
        }`}
      >
        <div
          className={`flex items-center justify-between w-full ${
            !noSidebarMode ? "px-16" : ""
          }`}
        >
          {noSidebarMode ? (
            <div className="flex">
              <SidebarHeader />
              <button
                className="min-w-[4rem] px-6 w-16 h-16 border border-t-0 border-grey-blue flex items-center"
                onClick={() => {
                  window.history.back();
                }}
              >
                <ArrowGoBackLine className="fill-white" />
              </button>
            </div>
          ) : (
            <LayoutTopbarBreadcrumb />
          )}
          <LayoutTopbarRightGroup />
        </div>
      </div>
    </>
  );
};
