// Libraries
import { Dropdown } from "@lockerpm/design";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import { FilterDropdown, SearchBox } from "#src/common/Table/Filter";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";
import { forceNormalCase } from "#src/utils/common";

// API-related
import resourceServices, {
  type ITrustCenterControlDomain,
} from "#src/services/resource";

// Children
import { complianceStatusItems } from "#src/config/filter/trustCenter";

interface ITrustCenterControlFilterProps {
  domainList: ITrustCenterControlDomain[];
  domain: ITrustCenterControlDomain | null;
  reference: IFilterItem | null;
  status: IFilterItem | null;
  searchKeyword: string;
  onChangeDomainSelection: (selected: ITrustCenterControlDomain) => void;
  onChangeReferenceSelection: (selected: IFilterItem | null) => void;
  onChangeStatusSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
}

const TrustCenterControlFilter = ({
  domainList,
  domain,
  reference,
  status,
  searchKeyword,
  onChangeDomainSelection,
  onChangeReferenceSelection,
  onChangeStatusSelection,
  onChangeSearchKeyword,
}: ITrustCenterControlFilterProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "control.filter" });

  const locale = i18next.language;

  const uselessParam = useMemo(() => [], []);

  const { list: complianceList } = useFetchNoPaging(
    resourceServices.list_trust_center_supported_compliance,
    uselessParam
  );

  const complianceItems = complianceList
    ? complianceList.map((item) => ({
        key: item.id,
        value: item.id,
        getLabel: () => (locale === "vi" ? item.name.vi : item.name.en),
      }))
    : [];

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between gap-1">
        <div className="flex gap-1 whitespace-nowrap flex-wrap">
          <FilterDropdown
            label={t("reference.label")}
            selected={reference}
            options={complianceItems}
            onChangeSelection={onChangeReferenceSelection}
          />
          <FilterDropdown
            label={t("status.label")}
            selected={status}
            options={complianceStatusItems}
            onChangeSelection={onChangeStatusSelection}
          />
        </div>

        <SearchBox
          searchKeyword={searchKeyword}
          onChangeSearchKeyword={onChangeSearchKeyword}
        />
      </div>
      <Dropdown
        menu={{
          items: domainList.map((item) => ({
            key: item.id,
            label: (
              <DropdownItemLabel
                syncWidthId="trustCenter-control-filter-dropdown"
                selected={item.id === domain?.id}
              >
                {forceNormalCase(locale === "vi" ? item.name.vi : item.name.en)}
              </DropdownItemLabel>
            ),
          })),
          onClick: ({ key }) => {
            const selectedDomain = domainList.find((item) => item.id === key);
            if (selectedDomain) {
              onChangeDomainSelection(selectedDomain);
            }
          },
        }}
        trigger={["click"]}
      >
        <DropdownTriggerButton
          id="trustCenter-control-filter-dropdown"
          className="w-full h-fit bg-label-blue font-medium-24 px-6 py-4"
        >
          {domain
            ? forceNormalCase(locale === "vi" ? domain.name.vi : domain.name.en)
            : null}
          <ArrowDownSLine />
        </DropdownTriggerButton>
      </Dropdown>
    </div>
  );
};

export default TrustCenterControlFilter;
