// Libraries
import { useTranslation } from "react-i18next";
import { useState } from "react";

// Components
import LoadingState from "#src/common/system/LoadingState";
import { NoVulnerabilitiesState } from "#src/common/states";
import TableHeader, {
  type ITableHeaderPropItem,
} from "#src/common/Table/TableHeader";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import dataLeakServices, {
  type ILeakedDataAccountDetails,
  type ILeakedDataItem,
} from "#src/services/dataLeak";

// Children
import DataLeakAccountTableRow from "./TableRow";
import SourceDetailsModal from "../../SourceModal";
import { createToast } from "#src/common/system/toasts";

interface IDataLeakAccountTableProps {
  workspaceId: string;
  dataLeakList: ILeakedDataItem<ILeakedDataAccountDetails>[] | null;
  isLoading: boolean;
  updateDataLeakList: () => void;
}

const DataLeakAccountTable = ({
  workspaceId,
  dataLeakList,
  isLoading,
  updateDataLeakList,
}: IDataLeakAccountTableProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "foundLeaks.tabs.account",
  });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.loginUrl"), align: "left" },
    { name: t("headers.username"), align: "left" },
    { name: t("headers.password"), align: "left" },
    { name: t("headers.sources"), align: "left" },
    { name: t("headers.severity"), align: "left" },
    { name: t("headers.matchedKeyword"), align: "left" },
    { name: t("headers.status"), align: "left" },
    { name: t("headers.firstSeen"), align: "left" },
  ];

  const [viewingSource, setViewingSource] = useState<
    ILeakedDataItem<ILeakedDataAccountDetails>["source"] | null
  >(null);

  const onCloseSourceViewModal = () => {
    setViewingSource(null);
  };

  const onClickViewSource = (value: typeof viewingSource) => {
    setViewingSource(value);
  };

  const onChangeSeverity = (leakedDataId: number, severity: string) => {
    dataLeakServices
      .update_leaked_data(workspaceId, leakedDataId, {
        severity,
      })
      .then((response) => {
        if (response.success) {
          updateDataLeakList();
          createToast({
            type: "success",
            message: t("notification.changeVulnerabilitySeverity.success"),
          });
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.changeVulnerabilitySeverity.fail"),
        });
      });
  };

  const onChangeSubStatus = (leakedDataId: number, substatus: string) => {
    dataLeakServices
      .update_leaked_data(workspaceId, leakedDataId, {
        sub_status: substatus,
      })
      .then((response) => {
        if (response.success) {
          updateDataLeakList();
          createToast({
            type: "success",
            message: t("notification.changeVulnerabilitySubstatus.success"),
          });
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.changeVulnerabilitySubstatus.fail"),
        });
      });
  };

  return (
    <div className="overflow-x-auto">
      {dataLeakList === null || isLoading ? (
        <LoadingState />
      ) : dataLeakList.length === 0 ? (
        <NoVulnerabilitiesState />
      ) : (
        <div className="grid grid-cols-8">
          <TableHeader title="dataleak-account-headers" headerProps={headers} />
          {dataLeakList.map((item) => (
            <DataLeakAccountTableRow
              key={`tdataleak-account-tr${item.id}`}
              workspaceId={workspaceId}
              data={item}
              onClickViewSource={onClickViewSource}
              onChangeSeverity={onChangeSeverity}
              onChangeSubStatus={onChangeSubStatus}
            />
          ))}
        </div>
      )}
      <SourceDetailsModal
        open={viewingSource !== null}
        onClose={onCloseSourceViewModal}
        source={viewingSource}
      />
    </div>
  );
};

export default DataLeakAccountTable;
