import { TAILWIND_COLORS } from "#src/utils/common";
import { DateTime } from "luxon";
import type { CSSProperties } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const specialDays = [DateTime.fromFormat("01/04", "dd/MM")];

const isSpecialDay = specialDays.some(
  (specialDay) =>
    DateTime.now().hasSame(specialDay, "day") &&
    DateTime.now().hasSame(specialDay, "month")
);

type LoaderType = "normal" | "cat" | "rainbow" | "heman";
const rate: { [T in LoaderType]: number } = isSpecialDay
  ? {
      normal: 1,
      cat: 1,
      rainbow: 17,
      heman: 1,
    }
  : {
      normal: 9944,
      cat: 6,
      rainbow: 50,
      heman: 0,
    };
interface ILoaderProps {
  baseSize?: number;
  particleCount?: number;
  color?: string;
}

/**
 * Loading indicator. Can be used inside other components.
 * @param baseSize - Size of the loader component. Unit for this size constant is _`rem`_. Default 5.
 * @param particleCount - Number of particles, or `loader-line`. Default 8.
 */
export const LoaderV2 = ({
  baseSize = 4,
  particleCount = 16,
  color,
}: ILoaderProps) => {
  /** Ratio of (particleHeight / the whole component size).
   * This should not be changed to make sure the image will not be distorted. */
  const particleHeightRatio = 0.1;
  /** Ratio of (particleWidth / the whole component size).
   * This should not be changed to make sure the image will not be distorted. */
  const particleWidthRatio = 0.1;

  const animationTime = 1;

  const loaderStyle: CSSProperties = {
    position: "absolute",
    height: baseSize * particleHeightRatio + "rem",
    width: baseSize * particleWidthRatio + "rem",
    left: baseSize * (0.5 - particleWidthRatio / 2) + "rem",
    top: (baseSize * (0.5 - particleHeightRatio)) / 4 + "rem",
    animation: `pseudo-rotate ${animationTime}s ease-in infinite`,
    transformOrigin:
      (baseSize * particleWidthRatio) / 2 + "rem " + baseSize * 0.45 + "rem",
  };

  return (
    <div
      className="relative"
      style={{
        height: baseSize + "rem",
        width: baseSize + "rem",
      }}
    >
      {particleCount !== 0
        ? Array(particleCount)
            .fill(undefined)
            .map((_, index) => (
              <span
                key={`loader-line-${index}`}
                className="h-2 w-2 rounded-full bg-brand-v2-600"
                style={{
                  ...loaderStyle,
                  backgroundColor: color ? color : undefined,
                  rotate: (360 / particleCount) * index + "deg",
                  animationDelay:
                    -(animationTime / particleCount) * index + "s",
                }}
              ></span>
            ))
        : null}
    </div>
  );
};

/** Loading state for CyStack Platform, with a little bit of fun. */
export const LoadingStateV2 = () => {
  const { t } = useTranslation("common", { keyPrefix: "state.loading" });

  const [showText, setShowText] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowText(true);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  const loaderType = useMemo(() => {
    let randomSeed = Math.floor(
      Math.random() * Object.values(rate).reduce((prev, cur) => cur + prev, 0)
    );

    return Object.entries(rate).find(([, value]) => {
      if (randomSeed <= value) return true;
      randomSeed = randomSeed - value;
    })?.[0];
  }, []);

  const colorfulWheel = useMemo(() => {
    if (!(loaderType === "rainbow")) return undefined;

    const colorList = [
      TAILWIND_COLORS["yellow-v2-600"],
      TAILWIND_COLORS["orange-v2-600"],
      TAILWIND_COLORS["rose-v2-600"],
      TAILWIND_COLORS["green-light-v2-600"],
      TAILWIND_COLORS["blue-v2-600"],
    ];

    const randomSeed = Math.floor(Math.random() * colorList.length);

    return colorList[randomSeed % colorList.length];
  }, [loaderType]);

  return (
    <div className="h-40 w-full flex justify-center items-center py-12">
      {loaderType === "cat" || loaderType === "heman" ? (
        <div className="relative">
          <img
            src={
              loaderType === "heman"
                ? "https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExc3J2amY4dWV5dXY1YWZ0bzc0azNqbndpNmlzNms3OHJrY3Iza2JubiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/M7gtacN7aPNsc/giphy.gif"
                : "/loading-cat.jpg"
            }
            className="object-cover max-h-40 overflow-hidden"
          />
          <div className="absolute top-[4.5rem] left-[4.5rem]">
            <LoaderV2 baseSize={1} />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-5">
          <LoaderV2 color={colorfulWheel} />
          {showText ? (
            <span className="text-lg font-medium text-gray-v2-700">
              {t("loading")}
            </span>
          ) : null}
        </div>
      )}
    </div>
  );
};
