export const EndpointOverviewStatWrapper = ({
  title,
  amount,
}: {
  title: string;
  amount: number;
}) => {
  return (
    <div className="p-4 flex flex-col gap-2 bg-white shadow-xs border border-gray-v2-200 rounded-xl">
      <span className="text-sm font-medium text-gray-v2-600">{title}</span>
      <span className="text-display-sm font-semibold text-gray-v2-900">
        {amount}
      </span>
    </div>
  );
};
