// Libraries
import type { ReactNode } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as AccountPinBoxLine } from "#src/assets/images/icons/account-pin-box-line.svg";
import { ReactComponent as CalendarLine } from "#src/assets/images/icons/calendar-line.svg";
import { ReactComponent as ServerLine } from "#src/assets/images/icons/server-line.svg";
import { ReactComponent as QrScanLine } from "#src/assets/images/icons/qr-scan-line.svg";
import { ReactComponent as UserLine } from "#src/assets/images/icons/user-line.svg";

// Components
import { RoundedSeeMoreTags, RoundedTag } from "#src/common/system/Tag";

// API-related
import type { IAssetDetailItem } from "#src/services/assets";

const OverviewCardRow = ({
  title,
  content,
}: {
  title: ReactNode;
  content: ReactNode;
}) => {
  return (
    <>
      <div className="flex gap-3 items-center h-8">{title}</div>
      <div className="flex gap-1 items-center text-dark-blue">{content}</div>
    </>
  );
};

const AssetsDetailsOverviewOverview = ({
  assetDetails,
}: {
  assetDetails: IAssetDetailItem;
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.overview.card.overview",
  });

  return (
    <>
      <div className="flex items-center mb-5">
        <h2>{t("title")}</h2>
      </div>
      <div className="grid grid-cols-2 gap-4 text-hard-grey whitespace-nowrap">
        <OverviewCardRow
          title={
            <>
              <div className="h-5 w-5">
                <AccountPinBoxLine />
              </div>
              {t("row.ipAddress.label")}
            </>
          }
          content={
            assetDetails.detail.ips.length ? (
              <>
                <RoundedTag
                  key={`OverviewCard_ip_tag-${assetDetails.detail.ips[0]}`}
                  name={assetDetails.detail.ips[0]}
                  noLegend
                />
                <RoundedSeeMoreTags
                  tagList={assetDetails.detail.ips
                    .slice(1)
                    .map((item) => ({ name: item, noLegend: true }))}
                />
              </>
            ) : (
              "--"
            )
          }
        />
        <OverviewCardRow
          title={
            <>
              <div className="h-5 w-5">
                <ServerLine />
              </div>
              {t("row.hosting.label")}
            </>
          }
          // Emitted: No returned value for "hosting" from API (last checked 25/12/2023)
          content={t("row.hosting.notDetected")}
        />
        <OverviewCardRow
          title={
            <>
              <div className="h-5 w-5">
                <QrScanLine />
              </div>
              {t("row.scans.label")}
            </>
          }
          content={
            <div className="flex flex-col">
              <span className="font-bold-16 text-primary">
                {assetDetails.scans}
              </span>
              {/* Omitted: No returned value for "last_scan" from API (last check 25/12/2023) */}
              {/* <div className="text-medium-grey text-xs">
                {t("row.scans.lastScan")}:
              </div> */}
            </div>
          }
        />
        <OverviewCardRow
          title={
            <>
              <div className="h-5 w-5">
                <UserLine />
              </div>
              {t("row.createdBy.label")}
            </>
          }
          content={
            assetDetails.created_by?.full_name
              ? assetDetails.created_by.full_name
              : "--"
          }
        />
        <OverviewCardRow
          title={
            <>
              <div className="h-5 w-5">
                <CalendarLine />
              </div>
              {t("row.createdAt.label")}
            </>
          }
          content={dayjs
            .unix(assetDetails.created_time)
            .format("HH:mm DD.MM.YYYY")}
        />
      </div>
    </>
  );
};

export default AssetsDetailsOverviewOverview;
