import {
  list_trust_center_compliance,
  retrieve_compliance_verification_status,
  retrieve_trust_center_compliance,
  update_trust_center_compliance,
} from "./compliance";
import {
  retrieve_trust_center_config,
  retrieve_trust_center_file_settings,
  update_trust_center_config_access,
  update_trust_center_config_activate,
  update_trust_center_config_domain,
  update_trust_center_config_profile,
  update_trust_center_config_analytic,
} from "./configuration";
import {
  retrieve_control_overview,
  list_trust_center_control,
  update_trust_center_control,
  retrieve_control_verification_status,
} from "./control";
import {
  create_multiple_trust_center_third_party,
  create_trust_center_third_party,
  delete_trust_center_third_party,
  list_trust_center_third_party,
  retrieve_trust_center_third_party,
} from "./thirdParty";
import {
  create_trust_center_faq,
  delete_trust_center_faq,
  list_trust_center_faq,
  list_trust_center_faq_no_paging,
  retrieve_trust_center_faq,
  update_trust_center_faq,
} from "./faq";
import {
  create_trust_center_policy,
  delete_trust_center_policy,
  list_trust_center_policy,
  retrieve_trust_center_policy,
  update_trust_center_policy,
} from "./policy";
import {
  create_trust_center_document,
  delete_trust_center_document,
  list_trust_center_document,
  retrieve_trust_center_document,
  update_trust_center_document,
} from "./document";
import {
  request_trust_center_plan,
  retrieve_trust_center_plan
} from "./plan";
import {
  create_trust_center_update,
  delete_trust_center_update,
  list_trust_center_update,
  list_trust_center_update_no_paging,
  retrieve_trust_center_update,
  update_trust_center_update,
} from "./update";

const trustCenterServices = {
  retrieve_trust_center_plan,
  request_trust_center_plan,

  list_trust_center_compliance,
  retrieve_trust_center_compliance,
  update_trust_center_compliance,
  retrieve_compliance_verification_status,

  retrieve_control_overview,
  list_trust_center_control,
  update_trust_center_control,
  retrieve_control_verification_status,

  list_trust_center_document,
  retrieve_trust_center_document,
  create_trust_center_document,
  update_trust_center_document,
  delete_trust_center_document,

  list_trust_center_third_party,
  retrieve_trust_center_third_party,
  create_trust_center_third_party,
  create_multiple_trust_center_third_party,
  delete_trust_center_third_party,

  list_trust_center_faq,
  list_trust_center_faq_no_paging,
  retrieve_trust_center_faq,
  create_trust_center_faq,
  update_trust_center_faq,
  delete_trust_center_faq,

  retrieve_trust_center_config,
  retrieve_trust_center_file_settings,
  update_trust_center_config_access,
  update_trust_center_config_domain,
  update_trust_center_config_profile,
  update_trust_center_config_activate,
  update_trust_center_config_analytic,

  list_trust_center_policy,
  retrieve_trust_center_policy,
  create_trust_center_policy,
  update_trust_center_policy,
  delete_trust_center_policy,
  
  create_trust_center_update,
  delete_trust_center_update,
  list_trust_center_update,
  list_trust_center_update_no_paging,
  retrieve_trust_center_update,
  update_trust_center_update,
};

export default trustCenterServices;
