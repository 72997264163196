// Libraries
import { Dropdown, Tooltip } from "@lockerpm/design";
import { type ReactNode } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as DraftLine } from "#src/assets/images/icons/draft-line.svg";
import usFlag from "#src/assets/images/flags/us.png";
import vnFlag from "#src/assets/images/flags/vn.png";

// Components
import { IconButton } from "#src/common/system/Button";
import { StatusLabel } from "#src/common/helper/labels/StatusLabel";
import { SourceLabel } from "#src/common/helper/labels/SourceLabel";
import { TableDataCell } from "#src/common/Table";
import { TableRowWrapperDiv } from "#src/common/Table/TableRowWrapper";
import { ObjectImage } from "#src/common/system/Object";
import { Flag } from "#src/common/Flag";

// API-related
import type { ITrustCenterPolicyItem } from "#src/services/trustCenter/policy";
import type { ITrustCenterSupportedCompliance } from "#src/services/resource";
import { TrustCenterPolicyStatusValue } from "#src/config/filter/trustCenter/value";

// Children
import { type TrustCenterPolicyActionEnum } from "../enum";

const StatusCard = ({ status }: { status: string }) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "policy" });

  return status === TrustCenterPolicyStatusValue.DRAFT ? (
    <StatusLabel
      name={t("card.draft")}
      color="bg-button-disabled text-hard-grey"
      icon={<DraftLine className="h-4 w-4 min-w-[1rem]" />}
    />
  ) : status === TrustCenterPolicyStatusValue.PUBLISHED ? (
    <StatusLabel
      name={t("card.published")}
      color="bg-support-seafoam-pastel text-support-seafoam"
      icon={<CheckboxCircleLine className="h-4 w-4 min-w-[1rem]" />}
    />
  ) : null;
};

interface ITrustCenterPolicyTableRowProps {
  data: ITrustCenterPolicyItem;
  actionList: { key: TrustCenterPolicyActionEnum; getLabel: () => ReactNode }[];
  onClickActionItems: (key: string, item: ITrustCenterPolicyItem) => void;
  supportedComplianceList: ITrustCenterSupportedCompliance[];
  onClickViewCompliance: (
    complianceList: ITrustCenterSupportedCompliance[]
  ) => void;
  onClickViewDetail: (selectedPolicy: ITrustCenterPolicyItem) => void;
}

const TrustCenterPolicyTableRow = ({
  data,
  actionList,
  onClickActionItems,
  supportedComplianceList,
  onClickViewCompliance,
  onClickViewDetail,
}: ITrustCenterPolicyTableRowProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "policy" });

  const locale = i18next.language;

  const includedCompliance = supportedComplianceList.filter((compliance) =>
    data.compliance.includes(compliance.id)
  );

  const getName: () => ReactNode = () => {
    if (data.name.en && locale === "en") {
      return data.name.en;
    }
    if (data.name.vi && locale === "vi") {
      return data.name.vi;
    }
    // Add things here when we have more than 2 languages.
    if (data.name.en) {
      return data.name.en;
    }
    if (data.name.vi) {
      return data.name.vi;
    }
    return null;
  };

  return (
    <TableRowWrapperDiv
      className="cursor-pointer"
      onClick={() => {
        onClickViewDetail(data);
      }}
    >
      <TableDataCell className="gap-3">
        {getName()}
        {data.languages.length > 0 ? (
          <div className="flex gap-1.5">
            {data.languages.includes("en") ? (
              <Tooltip title={t("tooltip.haveEnglish")}>
                <div>
                  <Flag pngProp={usFlag} />
                </div>
              </Tooltip>
            ) : null}
            {data.languages.includes("vi") ? (
              <Tooltip title={t("tooltip.haveVietnamese")}>
                <div>
                  <Flag pngProp={vnFlag} />
                </div>
              </Tooltip>
            ) : null}
          </div>
        ) : null}
      </TableDataCell>
      <TableDataCell>
        {includedCompliance.length > 0 ? (
          <span
            className={`px-3 py-2 flex items-center gap-3 bg-bright-grey-subline border border-grey rounded-3xl max-w-[12rem]`}
          >
            <ObjectImage
              data={includedCompliance[0].logo}
              className="h-6 w-6 min-w-[1.5rem] rounded-md"
            />
            <span className="overflow-y-visible overflow-x-hidden whitespace-nowrap text-ellipsis">
              {locale === "vi"
                ? includedCompliance[0].name.vi
                : includedCompliance[0].name.en}
            </span>
          </span>
        ) : null}
        {includedCompliance.length > 1 ? (
          <SourceLabel
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickViewCompliance(includedCompliance);
            }}
          >
            +{includedCompliance.length - 1}
          </SourceLabel>
        ) : null}
      </TableDataCell>
      <TableDataCell>
        <StatusCard status={data.status} />
      </TableDataCell>
      <TableDataCell>
        {dayjs
          .unix(data.updated_time ? data.updated_time : data.created_time)
          .format("HH:mm DD.MM.YYYY")}
      </TableDataCell>
      <TableDataCell className="justify-center">
        <Dropdown
          menu={{
            items: actionList.map((item) => ({
              key: item.key,
              label: item.getLabel(),
            })),
            onClick: ({ key, domEvent }) => {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              onClickActionItems(key, data);
            },
          }}
          trigger={["click"]}
          disabled={actionList.length === 0}
        >
          <IconButton
            variant="ghost"
            size={9}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <MoreLine />
          </IconButton>
        </Dropdown>
      </TableDataCell>
    </TableRowWrapperDiv>
  );
};

export default TrustCenterPolicyTableRow;
