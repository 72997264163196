// Libraries
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";
import { useDebouncedCallback } from "use-debounce";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import urls from "#src/config/urls";
import constants from "#src/config/constants";

// Components
import { IconButton } from "#src/common/system/Button";
import { ObjectImage } from "#src/common/system/Object";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import devicesServices from "#src/services/devices";

interface ISelectItem {
  key: string;
  id: string;
  name: string;
  label: string;
}

interface IHostInputProps {
  workspaceId: string;
  selectedHost: ISelectItem;
  onChangeHost: (host: ISelectItem) => void;
  onRemoveHost: (host: ISelectItem) => void;
  autofocus?: boolean;
}

const HostInput = ({
  workspaceId,
  selectedHost,
  onChangeHost,
  onRemoveHost,
  autofocus = false,
}: IHostInputProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "control.drawers.addNewControl",
  });

  const [open, setOpen] = useState<boolean>(autofocus);

  const [hostList, setHostList] = useState<ISelectItem[]>([]);
  const [hostCount, setHostCount] = useState<number>(0);

  const [loadedPages, setLoadedPages] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const fetchHostList = useCallback(
    (keyword: string | null, page?: number) => {
      setLoading(true);
      if (workspaceId) {
        devicesServices
          .list_hosts(workspaceId, {
            os: undefined,
            host_status: undefined,
            tag: undefined,
            policy_compliance: undefined,
            policy_compliance_id: undefined,
            policy_compliance_status: undefined,
            program_id: undefined,
            q: keyword ? keyword : undefined,
            page: page ? page : undefined,
            size: constants.DEFAULT_PAGE_SIZE,
          })
          .then((response) => {
            if (page) {
              setHostList((prev) => [
                ...prev,
                ...response.results.map((host) => ({
                  key: host.id.toString(),
                  id: host.id.toString(),
                  name: host.name,
                  label: host.name,
                })),
              ]);
            } else {
              setHostList(
                response.results.map((host) => ({
                  key: host.id.toString(),
                  id: host.id.toString(),
                  name: host.name,
                  label: host.name,
                }))
              );
            }
            setHostCount(response.count);
            setLoadedPages((prev) => prev + 1);
            setLoading(false);
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("notification.loadHostList.fail"),
            });
          });
      }
    },
    [workspaceId, t]
  );

  const debouncedFetchHostList = useDebouncedCallback(
    fetchHostList,
    constants.DEBOUNCE_TIME
  );

  const onChangeKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    debouncedFetchHostList(keyword);
  };

  const onScrollEvent = useDebouncedCallback((e: Event) => {
    const target = e.target as HTMLUListElement;

    if (
      target &&
      target.clientHeight + target.scrollTop > target.scrollHeight - 10 &&
      !isLoading &&
      hostCount &&
      hostCount > loadedPages * constants.DEFAULT_PAGE_SIZE
    ) {
      setLoading(true);
      fetchHostList(searchKeyword, loadedPages + 1);
    }
  });

  const onChooseHost = (key: string) => {
    const chosenHost = hostList.find((host) => host.key === key);

    if (chosenHost) {
      onChangeHost({
        key: selectedHost.key,
        id: chosenHost.id,
        name: chosenHost.name,
        label: chosenHost.label,
      });
    }
  };

  // WARNING: This is a bad way to do onScroll event, but we don't have any choices since AntD Dropdown doesn't expose onScroll event and the component did not even show up until the open state has changed AND React has rerendered once more.
  // --- This would definitely hurt performance ---
  useLayoutEffect(() => {
    const menuList = document.getElementsByClassName("ant-dropdown-menu");
    if (menuList.length > 0) {
      const menu = menuList.item(0);

      if (open) {
        if (menu !== null) {
          menu.addEventListener("scroll", onScrollEvent);
        }
      } else {
        if (menu !== null) {
          menu.removeEventListener("scroll", onScrollEvent);
        }
      }
    }
  }, [open, onScrollEvent]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchHostList(null);
    }

    return () => {
      ignore = true;
    };
  }, [fetchHostList]);

  return (
    <div className="flex gap-1">
      <Dropdown
        open={open}
        onOpenChange={(currentOpen) => {
          setOpen(currentOpen);
          if (!currentOpen && !selectedHost.id) {
            onRemoveHost(selectedHost);
          }
        }}
        menu={{
          items: hostList.map((host) => ({
            key: host.key,
            label: (
              <DropdownItemLabel selected={host.key === selectedHost.key}>
                {/* TODO: change this logo to OS's logos instead */}
                <ObjectImage
                  key={`logo-${host.label}`}
                  data={
                    urls.IMAGE_LOGO_SERVER_URL.replace(":address", host.label) +
                    "?size=36&v=1"
                  }
                  className="h-6 w-6 min-w-[1.5rem]"
                />
                {host.label}
              </DropdownItemLabel>
            ),
          })),
          onClick: ({ key }) => {
            onChooseHost(key);
            setOpen(false);
          },
        }}
        trigger={["click"]}
      >
        <div
          className={`flex p-2 text-left items-center justify-between ${
            open ? "outline outline-2 outline-primary" : "bg-bright-grey"
          } rounded-md w-full`}
        >
          <div className="flex items-center gap-2">
            {/* TODO: change this logo to OS's logos instead */}
            <ObjectImage
              key={`logo-${selectedHost.label}`}
              data={
                urls.IMAGE_LOGO_SERVER_URL.replace(
                  ":address",
                  selectedHost.label
                ) + "?size=36&v=1"
              }
              className="h-6 w-6 min-w-[1.5rem]"
            />
            {open ? (
              <input
                className="h-7 w-full outline-none caret-primary bg-transparent"
                autoFocus={autofocus}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  onChangeKeyword(e.currentTarget.value);
                }}
              />
            ) : (
              <span
                className={`h-7 flex items-center ${
                  selectedHost.label ? "text-primary" : "text-medium-grey"
                }`}
              >
                {selectedHost.label
                  ? selectedHost.label
                  : t("field.applyToHostName.search.placeholder")}
              </span>
            )}
          </div>
          {open ? (
            <button
              className="w-4 h-4 flex items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseLine className="fill-hard-grey w-4 h-4" />
            </button>
          ) : (
            <div className="w-4 h-4 flex items-center">
              <ArrowDownSLine className="fill-hard-grey w-4 h-4" />
            </div>
          )}
        </div>
      </Dropdown>
      <IconButton
        variant="warning"
        onClick={() => {
          onRemoveHost(selectedHost);
        }}
      >
        <CloseLine />
      </IconButton>
    </div>
  );
};

export default HostInput;
