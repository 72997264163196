// Libraries
import { useTranslation } from "react-i18next";
import { useState } from "react";

// Resources
import { ReactComponent as NoResultsIllustration } from "#src/assets/images/illustrations/no-results.svg";

// General
import global from "#src/config/global";

// Components
import LoadingState from "#src/common/system/LoadingState";
import EmptyState from "#src/common/helper/wrapper/EmptyState";
import { TableHeader } from "#src/common/Table";
import type { ITableHeaderPropItem } from "#src/common/Table/TableHeader";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { ITrustCenterDocumentItem } from "#src/services/trustCenter/document";
import trustCenterServices from "#src/services/trustCenter";

// Children
import TrustCenterDocumentTableRow from "./TableRow";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";
import {
  TrustCenterDocumentActionEnum,
  TrustCenterDocumentStatusValue,
} from "../enum";
import { trustCenterDocumentActionList } from "./actions";
import EditDocumentModal from "../EditDocumentModal";

interface ITrustCenterDocumentTableGridProps {
  workspaceId: string;
  documentList: ITrustCenterDocumentItem[] | null;
  isLoading: boolean;
  onRefresh: () => void;
  maxFileSize: number | null;
}

const TrustCenterDocumentTableGrid = ({
  workspaceId,
  documentList,
  isLoading,
  onRefresh,
  maxFileSize,
}: ITrustCenterDocumentTableGridProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "document" });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.documentName"), align: "left" },
    { name: t("headers.uploadedDate"), align: "left" },
    { name: t("headers.status"), align: "left" },
    { name: t("headers.attachedDocument"), align: "left" },
    { name: t("headers.actions"), align: "center" },
  ];

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  const [editingDocument, setEditingDocument] =
    useState<ITrustCenterDocumentItem | null>(null);

  const getFilteredActionList = (data: ITrustCenterDocumentItem) => {
    // Permission
    if (
      currentWorkspace?.role !== WorkspaceMemberRoleValue.OWNER &&
      currentWorkspace?.role !== WorkspaceMemberRoleValue.ADMIN
    ) {
      return [];
    }

    if (data.status === TrustCenterDocumentStatusValue.DRAFT) {
      return trustCenterDocumentActionList.filter((action) => {
        if (action.key === TrustCenterDocumentActionEnum.PUT_TO_DRAFT)
          return false;
        if (!data.url && action.key === TrustCenterDocumentActionEnum.DOWNLOAD)
          return false;
        return true;
      });
    }
    if (data.status === TrustCenterDocumentStatusValue.PUBLISHED) {
      return trustCenterDocumentActionList.filter((action) => {
        if (action.key === TrustCenterDocumentActionEnum.PUBLISH) return false;
        if (!data.url && action.key === TrustCenterDocumentActionEnum.DOWNLOAD)
          return false;
        return true;
      });
    }
    return [];
  };

  const onChangeStatus = (
    item: ITrustCenterDocumentItem,
    newStatus: TrustCenterDocumentStatusValue
  ) => {
    trustCenterServices
      .update_trust_center_document(workspaceId, item.id, {
        name: item.name,
        status: newStatus,
      })
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updateStatus.fail"),
        });
      });
  };

  const onDownloadDocument = (item: ITrustCenterDocumentItem) => {
    if (item.url) {
      let downloadElement = document.createElement("a");
      downloadElement.download = item.name;
      downloadElement.target = "_blank";
      downloadElement.href = item.url;
      downloadElement.click();
    } else {
      console.log("Download failed: this item has no url");
    }
  };

  const onDeleteDocument = (item: ITrustCenterDocumentItem) => {
    trustCenterServices
      .delete_trust_center_document(workspaceId, item.id)
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteDocument.fail"),
        });
      });
  };

  const onClickActionItems = (key: string, item: ITrustCenterDocumentItem) => {
    switch (key) {
      case TrustCenterDocumentActionEnum.EDIT:
        setEditingDocument(item);
        return;
      case TrustCenterDocumentActionEnum.PUBLISH:
        onChangeStatus(item, TrustCenterDocumentStatusValue.PUBLISHED);
        return;
      case TrustCenterDocumentActionEnum.PUT_TO_DRAFT:
        onChangeStatus(item, TrustCenterDocumentStatusValue.DRAFT);
        return;
      case TrustCenterDocumentActionEnum.DOWNLOAD:
        onDownloadDocument(item);
        return;
      case TrustCenterDocumentActionEnum.DELETE:
        global.confirm(() => {
          onDeleteDocument(item);
        });
        return;
    }
  };

  return (
    <>
      {isLoading || documentList === null ? (
        <LoadingState />
      ) : documentList.length === 0 ? (
        <EmptyState
          illustration={<NoResultsIllustration />}
          text={t("emptyState")}
        />
      ) : (
        <div className="grid grid-cols-[5fr_2fr_2fr_3fr_1fr]">
          <TableHeader title="trustCenter-document" headerProps={headers} />
          {documentList.map((item) => (
            <TrustCenterDocumentTableRow
              key={`trustCenter/document-tr${item.id}`}
              data={item}
              actionList={getFilteredActionList(item)}
              onClickActionItems={onClickActionItems}
            />
          ))}
        </div>
      )}
      <EditDocumentModal
        workspaceId={workspaceId}
        documentId={editingDocument?.id}
        open={editingDocument !== null}
        onClose={() => {
          setEditingDocument(null);
        }}
        onRefresh={onRefresh}
        maxFileSize={maxFileSize}
      />
    </>
  );
};

export default TrustCenterDocumentTableGrid;
