import { useTranslation } from "react-i18next";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { Button, IconButton } from "#src/common/system/Button";
import { Input } from "#src/common/system/Input";

interface IEditCustomHeaders {
  customHeaders: { name: string; value: string }[] | null;
  onChangeCustomHeaders: (headers: { name: string; value: string }[]) => void;
}

export const EditCustomHeaders = ({
  customHeaders,
  onChangeCustomHeaders,
}: IEditCustomHeaders) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.drawer.editSettings.section.scanProfile.customHeaders",
  });

  return (
    <div className="flex flex-col gap-6 pt-3 pb-6 px-6">
      {customHeaders?.map((header, headerId) => (
        <div key={`customHeader-${headerId}`} className="flex flex-col gap-2">
          <div className="flex justify-between items-center ml-4">
            <h4>{t("generalLabel") + " " + (headerId + 1)}</h4>
            <IconButton
              variant="warning"
              size={9}
              onClick={() => {
                onChangeCustomHeaders([
                  ...customHeaders.filter((_, id) => id !== headerId),
                ]);
              }}
            >
              <CloseLine />
            </IconButton>
          </div>
          <Input
            placeholder={t("headerName.placeholder")}
            value={header.name}
            onChange={(e) => {
              const newHeaders = [...customHeaders];
              newHeaders[headerId].name = e.target.value;
              onChangeCustomHeaders(newHeaders);
            }}
          />
          <Input
            placeholder={t("headerValue.placeholder")}
            value={header.value}
            onChange={(e) => {
              const newHeaders = [...customHeaders];
              newHeaders[headerId].value = e.target.value;
              onChangeCustomHeaders(newHeaders);
            }}
          />
        </div>
      ))}
      <Button
        variant="text"
        size="large"
        onClick={() => {
          onChangeCustomHeaders(
            customHeaders
              ? [...customHeaders, { name: "", value: "" }]
              : [{ name: "", value: "" }]
          );
        }}
      >
        <AddLine />
        {t("addHeader")}
      </Button>
    </div>
  );
};
