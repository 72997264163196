import { useRef, useState } from "react";
import { DateTime, Info as LuxonInfo } from "luxon";
import { Dropdown } from "@lockerpm/design";
import { Calendar, ChevronLeft, ChevronRight } from "@untitled-ui/icons-react";
import { ButtonV2 } from "#src/commonV2/Button";
import { useTranslation } from "react-i18next";
import { InputV2 } from "#src/commonV2/Input";

interface IDatePickerProps {
  open: boolean;
  onChangeOpen: (value: boolean) => void;
  currentValue: DateTime;
  onApply: (value: DateTime) => void;
}

// TODO: split this into a standalone component
export const DatePickerV2 = ({
  open,
  onChangeOpen,
  currentValue,
  onApply,
}: IDatePickerProps) => {
  const { t } = useTranslation("common", { keyPrefix: "v2.filterByDate" });

  // Date string input
  const inputRef = useRef<HTMLInputElement>(null);
  const [dateInput, setDateInput] = useState<string>(
    (currentValue ? currentValue : DateTime.now()).toFormat("MMM d, yyyy")
  );

  const [monthOnScreen, setMonthOnScreen] = useState<DateTime>(
    currentValue ? currentValue : DateTime.now()
  );
  const [selectedDate, setSelectedDate] = useState<DateTime>(
    currentValue ? currentValue : DateTime.now()
  );

  // --------------- METHODS ---------------

  const onChangeSelectedDate = (value: DateTime) => {
    setMonthOnScreen(value);
    setSelectedDate(value);
    setDateInput(value.toFormat("MMM d, yyyy"));
  };

  const onCloseAndCleanup = () => {
    setMonthOnScreen(currentValue ? currentValue : DateTime.now());
    onChangeSelectedDate(currentValue ? currentValue : DateTime.now());
    onChangeOpen(false);
  };

  const onClickApply = (value: DateTime) => {
    onApply(value);
    setMonthOnScreen(value);
    onChangeSelectedDate(value);
    onChangeOpen(false);
  };

  const onSubmitDateInput = () => {
    const parsedInput = DateTime.fromJSDate(new Date(dateInput));
    if (parsedInput.isValid) {
      onChangeSelectedDate(parsedInput);
    } else {
      console.log("invalid datetime");
      setDateInput(selectedDate.toFormat("MMM d, yyyy"));
    }
  };

  // --------------- RENDER ---------------
  return (
    <Dropdown
      open={open}
      onOpenChange={(value) => {
        if (value) {
          onChangeOpen(true);
        } else {
          onCloseAndCleanup();
        }
      }}
      dropdownRender={() => (
        <div
          className={`v2 bg-white shadow-lg rounded-xl flex flex-col transition-all`}
        >
          <div className="px-6 py-5 flex flex-col gap-3">
            {/* Month selector */}
            <div className="flex justify-between items-center gap-3">
              <button
                className="h-8 w-8 flex justify-center items-center"
                onClick={() => {
                  setMonthOnScreen(monthOnScreen.minus({ months: 1 }));
                }}
              >
                <ChevronLeft className="h-5 w-5 text-gray-v2-400" />
              </button>
              <p className="text-md font-semibold text-gray-v2-700">
                {monthOnScreen.toFormat("MMMM yyyy")}
              </p>
              <button
                className="h-8 w-8 flex justify-center items-center"
                onClick={() => {
                  setMonthOnScreen(monthOnScreen.plus({ months: 1 }));
                }}
              >
                <ChevronRight className="h-5 w-5 text-gray-v2-400" />
              </button>
            </div>
            {/* Month selector end */}

            <div className="flex items-center gap-3">
              {/* Current selected date */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmitDateInput();
                  inputRef.current?.blur();
                }}
              >
                <InputV2
                  ref={inputRef}
                  value={dateInput}
                  onChange={(e) => {
                    setDateInput(e.target.value);
                  }}
                  onBlur={onSubmitDateInput}
                />
              </form>
              {/* Current selected date end */}

              {/* Today button */}
              <ButtonV2
                variant="secondary"
                color="gray"
                onClick={() => {
                  onChangeSelectedDate(DateTime.now());
                  setMonthOnScreen(DateTime.now());
                }}
              >
                {t("button.today")}
              </ButtonV2>
              {/* Today button end */}
            </div>

            {/* Date selector */}
            <div className="grid grid-cols-7 gap-y-1">
              {/* Weekday names */}
              {LuxonInfo.weekdays("short").map((day) => (
                <span
                  key={`weekday-${day}`}
                  className="h-10 w-10 flex justify-center items-center text-sm font-medium text-gray-v2-700"
                >
                  {day.slice(0, 2)}
                </span>
              ))}
              {/* Weekday names end */}
              {/* Calendar */}
              {Array.from({ length: 6 }).map((_, week) =>
                // Explicitly set the array so that types can match with Luxon's
                ([1, 2, 3, 4, 5, 6, 7] as const).map((weekday) => {
                  const dateNumber = monthOnScreen
                    .startOf("month")
                    .plus({ weeks: week })
                    .set({
                      weekday: weekday,
                    });

                  // State of this square
                  const isInThisMonth =
                    dateNumber.month === monthOnScreen.month;

                  const isToday = DateTime.now().hasSame(dateNumber, "day");

                  const isSelected = selectedDate?.hasSame(dateNumber, "day");

                  // Methods
                  const onClick = () => {
                    if (!isInThisMonth) {
                      setMonthOnScreen(dateNumber);
                    }
                    onChangeSelectedDate(dateNumber);
                  };

                  // Render
                  return (
                    <button
                      key={`dayInMonth-${weekday}`}
                      className={`relative h-10 w-10 flex justify-center items-center text-sm rounded-full transition-colors ${
                        isInThisMonth ? "text-gray-v2-700" : "text-gray-v2-400"
                      } ${isSelected ? "bg-brand-v2-600 text-white font-medium" : "hover:bg-gray-v2-100"}`}
                      onClick={onClick}
                    >
                      {dateNumber.toFormat("d")}
                      {isToday ? (
                        <div
                          className={`absolute w-1 h-1 transition-colors ${
                            isSelected ? "bg-white" : "bg-brand-v2-600"
                          } rounded-full top-[2rem] left-[calc(50%_-_0.125rem)]`}
                        />
                      ) : null}
                    </button>
                  );
                })
              )}
              {/* Calendar end */}
            </div>
            {/* Date selector end */}
          </div>

          {/* Button section */}
          <div className="border-t border-gray-v2-200 p-4 flex gap-3">
            <ButtonV2
              variant="secondary"
              color="gray"
              className="flex-1"
              onClick={() => {
                onCloseAndCleanup();
              }}
            >
              {t("button.cancel")}
            </ButtonV2>
            <ButtonV2
              className="flex-1"
              onClick={() => {
                onClickApply(selectedDate);
              }}
            >
              {t("button.apply")}
            </ButtonV2>
          </div>
          {/* Button section end */}
        </div>
      )}
      arrow={false}
      placement="bottomRight"
      trigger={["click"]}
    >
      <ButtonV2
        variant="secondary"
        color="gray"
        className="outline outline-2 outline-offset-2 outline-transparent focus:outline-brand-v2-400 w-full"
      >
        <div className="flex justify-start items-center gap-1 flex-1">
          <Calendar className="h-5 w-5 text-gray-v2-700" />

          {currentValue ? (
            <span className="text-gray-v2-700">
              {currentValue.toFormat("MMM d, yyyy")}
            </span>
          ) : (
            <span className="text-gray-v2">{t("selectDate.placeholder")}</span>
          )}
        </div>
      </ButtonV2>
    </Dropdown>
  );
};
