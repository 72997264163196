// Libraries
import { useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

// Components
import { Button } from "#src/common/system/Button";
import { Input } from "#src/common/system/Input";
import { Modal } from "#src/common/system/Modal";

// API-related
import type { IWorkspacePaymentPlanItem } from "#src/services/payment";
import type { IWorkspaceItem } from "#src/services/workspace";

interface IBillingServiceUnsubModalProps {
  unsubbingService: IWorkspacePaymentPlanItem | null;
  onClose: () => void;
  currentWorkspace: IWorkspaceItem | null;
}

const BillingServiceUnsubModal = ({
  unsubbingService,
  onClose,
  currentWorkspace,
}: IBillingServiceUnsubModalProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.tab.solution.unsubModal",
  });

  const locale = i18next.language;

  const [passcode, setPasscode] = useState<string>("");

  const onClickUnsubscribe = () => {
    if (passcode === currentWorkspace?.name) {
      // TODO: unsub
    }
  };

  return (
    <Modal
      open={unsubbingService !== null}
      onChangeOpen={(value) => {
        if (!value) {
          onClose();
        }
      }}
    >
      <h2 className="text-warning">
        {t("unsubscibe")}
        {locale === "vi"
          ? unsubbingService?.product.name.vi
          : unsubbingService?.product.name.en}
      </h2>
      <div className="font-regular-16-body text-hard-grey whitespace-pre-line">
        <b className="text-dark-blue">
          {t("areYouSure")}
          {locale === "vi"
            ? unsubbingService?.product.name.vi
            : unsubbingService?.product.name.en}
          ?{" "}
        </b>
        {t("afterUnsubbing")}
        {"\n\n"}
        {t("ifYouWantRefund")}
        <a>{t("policyHere")}</a>.{"\n\n"}
        {t("toConfirm")}
        <b className="text-dark-blue">{currentWorkspace?.name}</b>
      </div>
      <Input
        value={passcode}
        onChange={(e) => {
          setPasscode(e.target.value);
        }}
      />
      <div className="flex justify-end gap-1">
        <Button variant="secondary" onClick={onClose}>
          {t("button.cancel")}
        </Button>
        <Button
          variant="danger"
          disabled={passcode !== currentWorkspace?.name}
          onClick={onClickUnsubscribe}
        >
          {t("button.unsubscribe")}
        </Button>
      </div>
    </Modal>
  );
};

export default BillingServiceUnsubModal;
