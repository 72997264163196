/** @type {import('tailwindcss').Config} */
export default {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    extend: {
      // Colors
      colors: {
        // -------------- V1 --------------

        primary: "#3C4EF8",
        "bright-grey": "#F7F9FB",
        "bright-grey-subline": "#EFEFF2",
        "light-grey": "#EEEEEE",
        grey: "#D7D7D7",
        "medium-grey": "#9595A5",
        "hard-grey": "#646478",
        "bright-blue": "#EDF5FC",
        "label-blue": "#D9EDFF",
        "subcard-blue": "#5A7FFF",
        "dark-blue": "#18181C",
        "grey-blue": "#363643",
        warning: {
          DEFAULT: "#E20D27",
          safe: "#1BC185",
          "dark-red": "#AC0C0A",
          red: {
            danger: "#E20D27",
            "40%": "#F49EA9",
            "10%": "#F8E7E9",
            "5%": "#FBF3F4",
          },
        },
        button: {
          hover: "#000F97",
          secondary: {
            DEFAULT: "#F6F6FD",
            hover: "#EBEDFD",
          },
          disabled: "#E7E8EA",
          warning: {
            bg: "#FBF3F4",
            hover: "#F8E7E9",
          },
        },
        wine: "#BB4040",
        red: "#E45B59",
        fire: "#EE9550",
        yellow: "#F1D064",
        blue: "#7FBAF6",
        silver: "#D9DAE1",
        sky: "#C5E2FF",
        green: "#B3EC7A",
        note: "#FFE2CB",
        support: {
          teal: {
            DEFAULT: "#3BA69F",
            pastel: "#E7F4F3",
          },
          pink: {
            DEFAULT: "#E391C7",
            pastel: "#FFE4E6",
          },
          purple: {
            DEFAULT: "#8766E7",
            pastel: "#F1EDFC",
          },
          blue: {
            DEFAULT: "#3699F4",
            pastel: "#E7F3FE",
          },
          "fire-pastel": "#FFF1E5",
          green: "#4A9005",
          seafoam: {
            DEFAULT: "#10A871",
            pastel: "#E8F9F3",
          },
          "yellow-pastel": "#FCF4DA",
        },
        policy: {
          failed: "#CA1816",
          expire: {
            in30days: "#DA6F1C",
            in2months: "#D8A912",
          },
        },
        "mssp-sidebar": "#2B2C32",
        status: {
          new: "#C5E2FF",
          triaged: "#7FBAF6",
          resolved: "#B3EC7A",
          unresolved: "#E45B59",
          duplicate: "#EE9550",
          rejected: "#9595A5",
        },
        severity: {
          critical: {
            DEFAULT: "#861010",
            bg: "#F2BFBF",
          },
          high: {
            DEFAULT: "#E20D27",
            bg: "#FFD9DD",
          },
          medium: {
            DEFAULT: "#D55D00",
            bg: "#FFE5D1",
          },
          low: {
            DEFAULT: "#D0980A",
            bg: "#FFF1C3",
          },
          information: {
            DEFAULT: "#3699F4",
            bg: "#E7F3FE",
          },
          none: {
            DEFAULT: "#646478",
            bg: "#EEEEEE",
          },
        },

        // -------------- V2 --------------

        // Primary colors
        "gray-v2": {
          DEFAULT: "#717680",
          25: "#FDFDFD",
          50: "#FAFAFA",
          100: "#F5F5F5",
          200: "#E9EAEB",
          300: "#D5D7DA",
          400: "#A4A7AE",
          500: "#717680",
          600: "#535862",
          700: "#414651",
          800: "#252B37",
          900: "#181D27",
          950: "#0A0D12",
        },
        "brand-v2": {
          DEFAULT: "#2970FF",
          25: "#F5F8FF",
          50: "#EFF4FF",
          100: "#D1E0FF",
          200: "#B2CCFF",
          300: "#84ADFF",
          400: "#528BFF",
          500: "#2970FF",
          600: "#155EEF",
          700: "#004EEB",
          800: "#0040C1",
          900: "#00359E",
          950: "#002266",
        },
        "error-v2": {
          DEFAULT: "#F04438",
          25: "#FFFBFA",
          50: "#FEF3F2",
          100: "#FEE4E2",
          200: "#FECDCA",
          300: "#FDA29B",
          400: "#F97066",
          500: "#F04438",
          600: "#D92D20",
          700: "#B42318",
          800: "#912018",
          900: "#7A271A",
          950: "#55160C",
        },
        "warning-v2": {
          DEFAULT: "#F79009",
          25: "#FFFCF5",
          50: "#FFFAEB",
          100: "#FEF0C7",
          200: "#FEDF89",
          300: "#FEC84B",
          400: "#FDB022",
          500: "#F79009",
          600: "#DC6803",
          700: "#B54708",
          800: "#93370D",
          900: "#7A2E0E",
          950: "#4E1D09",
        },
        "success-v2": {
          DEFAULT: "#17B26A",
          25: "#F6FEF9",
          50: "#ECFDF3",
          100: "#DCFAE6",
          200: "#A9EFC5",
          300: "#75E0A7",
          400: "#47CD89",
          500: "#17B26A",
          600: "#079455",
          700: "#067647",
          800: "#085D3A",
          900: "#074D31",
          950: "#053321",
        },
        // Secondary colors
        "gray-blue-v2": {
          DEFAULT: "#4E5BA6",
          25: "#FCFCFD",
          50: "#F8F9FC",
          100: "#EAECF5",
          200: "#D5D9EB",
          300: "#B3B8DB",
          400: "#717BBC",
          500: "#4E5BA6",
          600: "#3E4784",
          700: "#363F72",
          800: "#293056",
          900: "#101323",
          950: "#0D0F1C",
        },
        "green-light-v2": {
          DEFAULT: "#66C61C",
          25: "#FAFEF5",
          50: "#F3FEE7",
          100: "#E4FBCC",
          200: "#D0F8AB",
          300: "#A6EF67",
          400: "#85E13A",
          500: "#66C61C",
          600: "#4CA30D",
          700: "#3B7C0F",
          800: "#326212",
          900: "#2B5314",
          950: "#15290A",
        },
        "blue-light-v2": {
          DEFAULT: "#0BA5EC",
          25: "#F5FBFF",
          50: "#F0F9FF",
          100: "#E0F2FE",
          200: "#B9E6FE",
          300: "#7CD4FD",
          400: "#36BFFA",
          500: "#0BA5EC",
          600: "#0086C9",
          700: "#026AA2",
          800: "#065986",
          900: "#0B4A6F",
          950: "#062C41",
        },
        "blue-v2": {
          DEFAULT: "#2E90FA",
          25: "#F5FAFF",
          50: "#EFF8FF",
          100: "#D1E9FF",
          200: "#B2DDFF",
          300: "#84CAFF",
          400: "#53B1FD",
          500: "#2E90FA",
          600: "#1570EF",
          700: "#175CD3",
          800: "#1849A9",
          900: "#194185",
          950: "#102A56",
        },
        "blue-dark-v2": {
          DEFAULT: "#2970FF",
          25: "#F5F8FF",
          50: "#EFF4FF",
          100: "#D1E0FF",
          200: "#B2CCFF",
          300: "#84ADFF",
          400: "#528BFF",
          500: "#2970FF",
          600: "#155EEF",
          700: "#004EEB",
          800: "#0040C1",
          900: "#00359E",
          950: "#002266",
        },
        "indigo-v2": {
          DEFAULT: "#6172F3",
          25: "#F5F8FF",
          50: "#EEF4FF",
          100: "#E0EAFF",
          200: "#C7D7FE",
          300: "#A4BCFD",
          400: "#8098F9",
          500: "#6172F3",
          600: "#444CE7",
          700: "#3538CD",
          800: "#2D31A6",
          900: "#2D3282",
          950: "#1F235B",
        },
        "purple-v2": {
          DEFAULT: "#7A5AF8",
          25: "#FAFAFF",
          50: "#F4F3FF",
          100: "#EBE9FE",
          200: "#D9D6FE",
          300: "#BDB4FE",
          400: "#9B8AFB",
          500: "#7A5AF8",
          600: "#6938EF",
          700: "#5925DC",
          800: "#4A1FB8",
          900: "#3E1C96",
          950: "#27115F",
        },
        "pink-v2": {
          DEFAULT: "#EE46BC",
          25: "#FEF6FB",
          50: "#FDF2FA",
          100: "#FCE7F6",
          200: "#FCCEEE",
          300: "#FAA7E0",
          400: "#F670C7",
          500: "#EE46BC",
          600: "#DD2590",
          700: "#C11574",
          800: "#9E165F",
          900: "#851651",
          950: "#4E0D30",
        },
        "rose-v2": {
          DEFAULT: "#F63D68",
          25: "#FFF5F6",
          50: "#FFF1F3",
          100: "#FFE4E8",
          200: "#FECDD6",
          300: "#FEA3B4",
          400: "#FD6F8E",
          500: "#F63D68",
          600: "#E31B54",
          700: "#C01048",
          800: "#A11043",
          900: "#89123E",
          950: "#510B24",
        },
        "orange-v2": {
          DEFAULT: "#EF6820",
          25: "#FEFAF5",
          50: "#FEF6EE",
          100: "#FDEAD7",
          200: "#F9DBAF",
          300: "#F7B27A",
          400: "#F38744",
          500: "#EF6820",
          600: "#E04F16",
          700: "#B93815",
          800: "#932F19",
          900: "#772917",
          950: "#511C10",
        },
        "yellow-v2": {
          DEFAULT: "#EAAA08",
          25: "#FEFDF0",
          50: "#FEFBE8",
          100: "#FEF7C3",
          200: "#FEEE95",
          300: "#FDE272",
          400: "#FAC515",
          500: "#EAAA08",
          600: "#CA8504",
          700: "#A15C07",
          800: "#854A0E",
          900: "#713B12",
          950: "#542C0D",
        },
      },

      // Fonts (V2)
      fontSize: {
        "display-2xl": [
          "4.5rem", // 72px
          {
            lineHeight: "5.625rem", // 90px
            letterSpacing: "-2%",
          },
        ],
        "display-xl": [
          "3.75rem", // 60px
          {
            lineHeight: "4.5rem", // 72px
            letterSpacing: "-2%",
          },
        ],
        "display-lg": [
          "3rem", // 48px
          {
            lineHeight: "3.75rem", // 60px
            letterSpacing: "-2%",
          },
        ],
        "display-md": [
          "2.25rem", // 36px
          {
            lineHeight: "2.75rem", // 44px
            letterSpacing: "-2%",
          },
        ],
        "display-sm": ["1.875rem", "2.375rem"], // 30px - 38px
        "display-xs": ["1.5rem", "2rem"], // 24px - 32px
        xl: ["1.25rem", "1.875rem"], // 20px - 30px
        lg: ["1.125rem", "1.75rem"], // 18px - 28px
        md: ["1rem", "1.5rem"], // 16px - 24px
        "md-body": ["1rem", "2rem"], // 16px - 32px
        sm: ["0.875rem", "1.25rem"], // 14px - 20px
        xs: ["0.75rem", "1.125rem"], // 12px - 18px
      },

      // Shadows (V2)
      boxShadow: {
        xs: "0px 1px 2px rgba(10, 13, 18, 0.05)",
      },
    },
  },
  plugins: [],
  corePlugins: {
    preflight: false,
  },
};
