import { ChevronDown, Plus, XClose } from "@untitled-ui/icons-react";
import { ButtonV2 } from "#src/commonV2/Button";
import { Dropdown } from "@lockerpm/design";
import { useCallback, useState } from "react";
import {
  acceptedDateUnitList,
  DateRangeTypeEnum,
  dateRangeTypeList,
} from "./enums";
import { IFilterItemV2 } from "../types";
import { useTranslation } from "react-i18next";
import { DropdownItemRenderV2 } from "#src/commonV2/antdHelpers/DropdownItemRender";
import { FilterByDateLastXDays } from "./LastXDays";
import { DateTime } from "luxon";
import { FilterByDateSelectOneDate } from "./SelectOneDate";
import { FilterByDateSelectTwoDates } from "./SelectTwoDates";
import i18next from "i18next";

interface IFilterByDateProps {
  label: string;
  dateRange: { from: number | null; to: number | null } | null;
  onChangeDateRange: (
    value: { from: number | null; to: number | null } | null
  ) => void;
}

export const FilterByDate = ({
  label,
  dateRange,
  onChangeDateRange,
}: IFilterByDateProps) => {
  const { t } = useTranslation("common", { keyPrefix: "v2.filterByDate" });

  const locale = i18next.language;

  // --------------- STATE ---------------

  const [openFilter, setOpenFilter] = useState<boolean>(false);

  // All the states below (except this `savedRangeType` one) are used for selecting.
  // `dateRange` from props is what we consume for API communication.
  // This is used to combine with `dateRange` from props as source of the truth, to calculate the currently "saved" state.
  const [savedRangeType, setSavedRangeType] = useState<DateRangeTypeEnum>(
    DateRangeTypeEnum.EQUAL_TO
  );
  const [savedUnit, setSavedUnit] = useState<IFilterItemV2>(
    acceptedDateUnitList[0]
  );

  // What type of range selector is being used
  const [dateRangeType, setDateRangeType] = useState<IFilterItemV2>(
    dateRangeTypeList[0]
  );

  // Props for date selectors
  const [lastXDaysProps, setLastXDaysProps] = useState<{
    count: number;
    selectedUnit: IFilterItemV2;
  }>({
    count: 1,
    selectedUnit: acceptedDateUnitList[0],
  });
  // When we only need one date
  const [selected1Date, setSelected1Date] = useState<DateTime>(DateTime.now());
  // When we need to pick 2 dates
  const [selected2Dates, setSelected2Dates] = useState<{
    from: DateTime;
    to: DateTime;
  }>({
    from: DateTime.now(),
    to: DateTime.now(),
  });

  // --------------- METHODS ---------------

  const onCloseAndCleanUp = () => {
    setOpenFilter(false);
  };

  const onChangeRangeType = (selected: typeof dateRangeType) => {
    if (dateRangeType.key === selected.key) return;

    // Reset props
    setLastXDaysProps({
      count: 1,
      selectedUnit: acceptedDateUnitList[0],
    });
    setSelected1Date(DateTime.now());
    setSelected2Dates({
      from: DateTime.now().startOf("day"),
      to: DateTime.now().endOf("day"),
    });

    setDateRangeType(selected);
  };

  // Get { from, to } value that will be passed to API.
  const getRange: () => typeof dateRange = useCallback(() => {
    if (dateRangeType.key === DateRangeTypeEnum.LAST_X_UNITS) {
      const from = DateTime.now()
        .minus({
          [lastXDaysProps.selectedUnit.key === "weeks" ? "weeks" : "days"]:
            lastXDaysProps.count,
        })
        .toSeconds();
      const to = DateTime.now().toSeconds();
      return { from, to };
    }

    if (dateRangeType.key === DateRangeTypeEnum.EQUAL_TO) {
      const from = selected1Date.startOf("day").toSeconds();
      const to = selected1Date.endOf("day").toSeconds();
      return { from, to };
    }

    if (dateRangeType.key === DateRangeTypeEnum.AFTER) {
      const from = selected1Date.plus({ day: 1 }).startOf("day").toSeconds();
      return { from, to: null };
    }

    if (dateRangeType.key === DateRangeTypeEnum.STARTS_FROM) {
      const from = selected1Date.startOf("day").toSeconds();
      return { from, to: null };
    }

    if (dateRangeType.key === DateRangeTypeEnum.BEFORE) {
      const to = selected1Date.minus({ day: 1 }).endOf("day").toSeconds();
      return { from: null, to };
    }

    if (dateRangeType.key === DateRangeTypeEnum.ENDS_AT) {
      const to = selected1Date.endOf("day").toSeconds();
      return { from: null, to };
    }

    if (dateRangeType.key === DateRangeTypeEnum.BETWEEN) {
      const from = selected2Dates.from.startOf("day").toSeconds();
      const to = selected2Dates.to.endOf("day").toSeconds();
      return { from, to };
    }

    return null;
  }, [dateRangeType, lastXDaysProps, selected1Date, selected2Dates]);

  const parseDateTimeToLocale = useCallback(
    (value: DateTime) =>
      value.toLocaleString(
        {
          month: "short",
          day: "numeric",
          year: "numeric",
        },
        { locale }
      ),
    [locale]
  );

  // Return value that shows on the filter trigger button, which is the "truthful" state of what's being saved and what will be consumed.
  // This also has localization.
  const getTimeRangeToString = useCallback(() => {
    const parsedFrom =
      dateRange && dateRange.from ? DateTime.fromSeconds(dateRange.from) : null;
    const parsedTo =
      dateRange && dateRange.to ? DateTime.fromSeconds(dateRange.to) : null;

    if (savedRangeType === DateRangeTypeEnum.LAST_X_UNITS) {
      if (parsedFrom === null || parsedTo === null) {
        return "Date picker error";
      }
      const selectedUnit = savedUnit.key === "weeks" ? "weeks" : "days";
      return t("toString.lastFewUnits", {
        count: Math.round(
          parsedTo.diff(parsedFrom, selectedUnit)[selectedUnit]
        ),
        unit: savedUnit.getLabel(),
      });
    }

    if (savedRangeType === DateRangeTypeEnum.EQUAL_TO) {
      if (
        parsedFrom === null ||
        parsedTo === null ||
        !parsedFrom.hasSame(parsedTo, "day")
      ) {
        return "Date picker error";
      }
      return parseDateTimeToLocale(parsedFrom);
    }

    if (savedRangeType === DateRangeTypeEnum.BETWEEN) {
      if (parsedFrom === null || parsedTo === null) {
        return "Date picker error";
      }
      return (
        parseDateTimeToLocale(parsedFrom) +
        " - " +
        parseDateTimeToLocale(parsedTo)
      );
    }

    if (savedRangeType === DateRangeTypeEnum.AFTER) {
      if (parsedFrom === null || parsedTo !== null) {
        return "Date picker error";
      }
      return t("toString.after", {
        date: parseDateTimeToLocale(parsedFrom.minus({ day: 1 })),
      });
    }

    if (savedRangeType === DateRangeTypeEnum.STARTS_FROM) {
      if (parsedFrom === null || parsedTo !== null) {
        return "Date picker error";
      }
      return t("toString.startsFrom", {
        date: parseDateTimeToLocale(parsedFrom),
      });
    }

    if (savedRangeType === DateRangeTypeEnum.BEFORE) {
      if (parsedFrom !== null || parsedTo === null) {
        return "Date picker error";
      }
      return t("toString.before", {
        date: parseDateTimeToLocale(parsedTo.plus({ day: 1 })),
      });
    }

    if (savedRangeType === DateRangeTypeEnum.ENDS_AT) {
      if (parsedFrom !== null || parsedTo === null) {
        return "Date picker error";
      }
      return t("toString.endsAt", {
        date: parseDateTimeToLocale(parsedTo),
      });
    }

    return "Date picker null error";
  }, [savedRangeType, savedUnit, dateRange, t, parseDateTimeToLocale]);

  // onClick for the outermost Apply button
  const onClickApply = () => {
    const tempRange = getRange();

    if (tempRange === null) {
      onChangeDateRange(null);
      setSavedRangeType(DateRangeTypeEnum.EQUAL_TO);
      setSavedUnit(acceptedDateUnitList[0]);
    } else {
      onChangeDateRange(tempRange);
      setSavedRangeType(dateRangeType.value as DateRangeTypeEnum);
      setSavedUnit(lastXDaysProps.selectedUnit);
    }

    onCloseAndCleanUp();
  };

  // --------------- RENDER ---------------

  return (
    // Dropdown lets us overwrite the popover content
    <Dropdown
      open={openFilter}
      onOpenChange={(value) => {
        if (value) {
          setOpenFilter(true);
        } else {
          onCloseAndCleanUp();
        }
      }}
      dropdownRender={() => (
        <div
          className={`v2 bg-white p-6 shadow-lg rounded-xl flex flex-col gap-5 transition-all ${dateRangeType.key === DateRangeTypeEnum.BETWEEN ? "w-[25rem]" : "w-[20rem]"}`}
        >
          <p className="text-md font-semibold text-gray-v2-700">{t("title")}</p>
          <div className="flex flex-col gap-2">
            {/* Choose range selector type */}
            <Dropdown
              menu={{
                items: dateRangeTypeList.map((item) => ({
                  key: item.key,
                  value: item.value,
                  label: (
                    <DropdownItemRenderV2
                      item={item}
                      selected={dateRangeType}
                    />
                  ),
                })),
                onClick: (obj) => {
                  const newSelected = dateRangeTypeList.find(
                    (item) => item.key === obj.key
                  );
                  if (newSelected) {
                    onChangeRangeType(newSelected);
                  }
                },
              }}
              placement="bottomRight"
              trigger={["click"]}
            >
              <ButtonV2
                variant="secondary"
                color="gray"
                className="outline outline-2 outline-offset-2 outline-transparent focus:outline-brand-v2-400"
              >
                <div className="flex justify-between items-center gap-2 w-full">
                  <span>{dateRangeType.getLabel()}</span>
                  <ChevronDown className="h-5 w-5 text-gray-v2-700" />
                </div>
              </ButtonV2>
            </Dropdown>
            {/* choose range selector type end */}

            {/* Date selector */}
            {dateRangeType.key === DateRangeTypeEnum.LAST_X_UNITS ? (
              <FilterByDateLastXDays
                props={lastXDaysProps}
                setProps={setLastXDaysProps}
              />
            ) : null}
            {[
              DateRangeTypeEnum.EQUAL_TO,
              DateRangeTypeEnum.AFTER,
              DateRangeTypeEnum.STARTS_FROM,
              DateRangeTypeEnum.BEFORE,
              DateRangeTypeEnum.ENDS_AT,
            ].includes(dateRangeType.key as DateRangeTypeEnum) ? (
              <FilterByDateSelectOneDate
                selectedDate={selected1Date}
                setSelectedDate={setSelected1Date}
              />
            ) : null}
            {dateRangeType.key === DateRangeTypeEnum.BETWEEN ? (
              <FilterByDateSelectTwoDates
                selectedDates={selected2Dates}
                setSelectedDates={setSelected2Dates}
              />
            ) : null}
            {/* End date selector */}
          </div>
          <ButtonV2 onClick={onClickApply}>{t("button.apply")}</ButtonV2>
        </div>
      )}
      arrow={false}
      placement="bottomRight"
      trigger={["click"]}
    >
      <ButtonV2
        variant="secondary"
        color="gray"
        className="outline outline-2 outline-offset-2 outline-transparent focus:outline-brand-v2-400"
      >
        {dateRange === null ? (
          <>
            <span>{label}</span>
            <Plus className="h-5 w-5 text-gray-v2-700" />
          </>
        ) : (
          <>
            <span>
              {label}
              {": "}
              {getTimeRangeToString()}
            </span>
            <XClose
              className="h-5 w-5 text-gray-v2-700"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChangeDateRange(null);
              }}
            />
          </>
        )}
      </ButtonV2>
    </Dropdown>
  );
};
