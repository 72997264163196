import { ButtonV2 } from "#src/commonV2/Button";
import { Modal, Radio } from "@lockerpm/design";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  conflictCount: number;
  closeModal: () => void;
  onDeselect: () => void;
  onReplaceExisting: () => void;
};

const EnableMultipleBackupConfirmModal = (props: Props) => {
  const { isOpen, conflictCount, closeModal, onDeselect, onReplaceExisting } =
    props;

  const { t } = useTranslation("dlp");

  // --------------- DATA ---------------

  const [action, setAction] = useState<"replace" | "deselect">("replace");

  // --------------- COMPUTED ---------------

  const actions = useMemo(() => {
    const renderLabel = (text: string) => (
      <p className="text-md font-medium text-gray-v2-700">{text}</p>
    );

    return [
      {
        label: renderLabel(t("scheduleMultiple.deselect")),
        value: "deselect",
      },
      {
        label: renderLabel(t("scheduleMultiple.replace")),
        value: "replace",
      },
    ];
  }, [t]);

  // --------------- METHODS ---------------

  const confirmAction = useCallback(() => {
    if (action === "deselect") {
      onDeselect();
    } else {
      onReplaceExisting();
    }
    closeModal();
  }, [action, onDeselect, onReplaceExisting, closeModal]);

  // --------------- EFFECTS ---------------

  // --------------- RENDER ---------------

  return (
    <Modal
      title={t("scheduleModal.title")}
      open={isOpen}
      footer={null}
      maskClosable={false}
      onCancel={closeModal}
      className="v2 max-w-[600px]"
      centered
      width="90%"
    >
      <div className="v2">
        {/* Desc */}
        <p className="text-md text-gray-v2-600 mb-3">
          {t("scheduleMultiple.desc1", { count: conflictCount })}
        </p>
        <p className="text-md text-gray-v2-600 mb-5">
          {t("scheduleMultiple.desc2")}
        </p>
        {/* Desc end */}

        <Radio.Group
          value={action}
          options={actions}
          onChange={(e) => setAction(e.target.value)}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.25rem",
          }}
        />

        {/* Footer */}
        <div className="flex gap-2 justify-end mt-4">
          <ButtonV2
            variant="secondary"
            size="md"
            color="gray"
            onClick={closeModal}
          >
            <p>{t("common.cancel")}</p>
          </ButtonV2>

          <ButtonV2
            variant="primary"
            size="md"
            color="error"
            onClick={confirmAction}
          >
            <p>{t("common.continue")}</p>
          </ButtonV2>
        </div>
        {/* Footer end */}
      </div>
    </Modal>
  );
};

export default EnableMultipleBackupConfirmModal;
