// Libraries
import { useTranslation } from "react-i18next";

import { type IDataLeakOverviewByType } from "#src/services/dataLeak";

interface IOverviewLeaksByTypeProps {
  overviewByType: IDataLeakOverviewByType;
}

interface IOverviewLeakTypeCardProps {
  className?: string;
  label: string;
  value: number | string | undefined;
}

export const OverviewLeakTypeCard = ({
  className,
  label,
  value,
}: IOverviewLeakTypeCardProps) => {
  return (
    <div
      className={`${className} w-full bg-severity-information-bg flex items-center justify-between px-4 xl:px-6 rounded-md whitespace-pre-line`}
    >
      <span className="font-medium-14">{label}</span>
      <span className="relative h-full">
        {/* Marker. This should be placed on top of the value */}
        {value ? (
          <span
            className={`absolute -right-1 w-[calc(100%_+_0.5rem)] bg-severity-information h-1.5 rounded-b-full`}
          />
        ) : null}
        <span className="h-full flex items-center font-bold-20 tracking-tight tabular-nums py-3">
          {value}
        </span>
      </span>
    </div>
  );
};

const OverviewLeaksByType = ({ overviewByType }: IOverviewLeaksByTypeProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "overview.leaksByType",
  });

  return (
    <div className={`flex flex-col border border-bright-grey rounded-md`}>
      <div className={`px-6 py-4 bg-bright-grey`}>
        <span className="font-medium-20">{t("title")}</span>
      </div>
      <div className="px-6 py-4 grid grid-cols-4 gap-3">
        <OverviewLeakTypeCard
          label={t("accounts")}
          value={overviewByType?.account}
        />
        <OverviewLeakTypeCard
          label={t("cookies")}
          value={overviewByType?.cookie}
        />
        <OverviewLeakTypeCard
          label={t("emails")}
          value={overviewByType?.email}
        />
        <OverviewLeakTypeCard
          label={t("sourceCode")}
          value={overviewByType?.source_code}
        />
      </div>
    </div>
  );
};

export default OverviewLeaksByType;
