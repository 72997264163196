// Libraries
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import dayjs from "dayjs";

// General

// Components
import { Button } from "#src/common/system/Button";
import { ReactComponent as ArrowRightLine } from "#src/assets/images/icons/arrow-right-line.svg";
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";
import { NoDataState } from "#src/common/states";

import { type IDataLeakOverviewUpdates } from "#src/services/dataLeak";

interface IOverviewUpdatesProps {
  overviewUpdates: IDataLeakOverviewUpdates | null;
}

const OverviewUpdates = ({ overviewUpdates }: IOverviewUpdatesProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "overview.updates",
  });

  const isEmpty: boolean = useMemo(() => {
    return (
      !overviewUpdates?.last_scan_time &&
      !overviewUpdates?.unverified_keywords &&
      !overviewUpdates?.need_renewal_keywords
    );
  }, [overviewUpdates]);

  return (
    <div className="rounded-md border border-bright-grey px-6 py-4 w-full flex flex-col gap-6">
      <h2 className="font-medium-20">{t("title")}</h2>
      {isEmpty ? (
        <NoDataState />
      ) : (
        <div className="flex flex-col gap-3">
          {!!overviewUpdates?.last_scan_time && (
            <div className="border-b border-b-solid border-b-light-grey pb-4 flex flex-col gap-3">
              <p>
                <span className="text-dark-blue font-bold">
                  {overviewUpdates?.last_scan_number}
                </span>{" "}
                <span className="text-hard-grey">{t("newfound")}</span>
              </p>
              <div className="flex items-center text-hard-grey gap-1">
                <TimeLine height={"0.875rem"} width={"0.875rem"} />
                <span className="font-regular-12">
                  {dayjs.unix(overviewUpdates?.last_scan_time).fromNow()}
                </span>
              </div>
            </div>
          )}
          {!!overviewUpdates?.unverified_keywords && (
            <div className="border-b border-b-solid border-b-light-grey pb-4 flex items-center gap-3">
              <p>
                <span className="text-dark-blue font-bold">
                  {overviewUpdates?.unverified_keywords}
                </span>{" "}
                <span className="text-hard-grey">
                  {t("keywordsNeedVerified")}
                </span>
              </p>
            </div>
          )}
          {!!overviewUpdates?.need_renewal_keywords && (
            <div className="border-b border-b-solid border-b-light-grey pb-4 flex gap-6">
              <p>
                <span className="text-dark-blue font-bold">
                  {overviewUpdates?.need_renewal_keywords}
                </span>{" "}
                <span className="text-hard-grey">
                  {t("keywordsNeedRenewed")}
                </span>
              </p>
              <Button variant="text" className="h-5 m-0 p-0">
                {t("buttons.manageSubscriptions")}
                <ArrowRightLine className="w-4 h-4" />
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OverviewUpdates;
