// TODO: Add a system of rules for these constants and objects (uppercase/lowercase, enum used, keys, values, labels)
// TODO: put this into (somewhat) global scope

/* --- RULES FOR FILTER ENUMS AND ITEMS--- */

// 1. A filter ITEM will have the form of an object with 3 attributes: key, value and label (getLabel)
//  `key` will be used to indexing (comparing and finding) items
//  `value` will contain data for transferring & communicating with APIs
//  `getLabel` will be used for displaying purpose. We use a function instead of directly use the value since we need translation from i18next, and i18next can only work dynamically
// 2. Enums will be used as items' keys with the form of `NAME_ENUM_VALUE`

import i18next from "i18next";
import type { IFilterItem } from "#src/@types/common";

export const AssessmentTypeEnum = {
  AUTOMATION_SCANS: "ASSESSMENT_TYPE_AUTOMATION",
  PENTEST_PROJECT: "ASSESSMENT_TYPE_PENTEST",
  BUG_BOUNTY_PROGRAM: "ASSESSMENT_TYPE_BUG_BOUNTY",
  DATA_LEAK: "ASSESSMENT_TYPE_DATA_LEAK",
  ALL: "ASSESSMENT_TYPE_ALL",
};

export const assessmentTypeItems: IFilterItem[] = [
  {
    key: AssessmentTypeEnum.AUTOMATION_SCANS,
    value: "auto_scan",
    getLabel: () => i18next.t("filter.assessmentType.automationScans"),
  },
  {
    key: AssessmentTypeEnum.PENTEST_PROJECT,
    value: "pentest_project",
    getLabel: () => i18next.t("filter.assessmentType.pentestProject"),
  },
  {
    key: AssessmentTypeEnum.BUG_BOUNTY_PROGRAM,
    value: "bugbounty_program",
    getLabel: () => i18next.t("filter.assessmentType.bugBountyProgram"),
  },
  {
    key: AssessmentTypeEnum.DATA_LEAK,
    value: "data_leak",
    getLabel: () => i18next.t("filter.assessmentType.dataLeak"),
  },
  {
    key: AssessmentTypeEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const VisibilityEnum = {
  PUBLIC: "VISIBILITY_PUBLIC",
  SEMI_PRIVATE: "VISIBILITY_SEMI_PRIVATE",
  PRIVATE: "VISIBILITY_PRIVATE",
  ALL: "VISIBILITY_ALL",
};

/** Filter by item's visibility */
export const visibilityItems: IFilterItem[] = [
  {
    key: VisibilityEnum.PUBLIC,
    value: "public",
    getLabel: () => i18next.t("filter.visibility.public"),
  },
  {
    key: VisibilityEnum.SEMI_PRIVATE,
    value: "private",
    getLabel: () => i18next.t("filter.visibility.semiPrivate"),
  },
  {
    key: VisibilityEnum.PRIVATE,
    value: "private",
    getLabel: () => i18next.t("filter.visibility.private"),
  },
  {
    key: VisibilityEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const BountyEnum = {
  REWARDED: "BOUNTY_REWARDED",
  NOT_REWARDED: "BOUNTY_NOT_REWARDED",
  ALL: "BOUNTY_ALL",
};

/** Filter vulnerability by whether the bounty rewarded */
export const bountyItems: IFilterItem[] = [
  {
    key: BountyEnum.REWARDED,
    value: "1",
    getLabel: () => i18next.t("filter.bounty.rewarded"),
  },
  {
    key: BountyEnum.NOT_REWARDED,
    value: "0",
    getLabel: () => i18next.t("filter.bounty.notRewarded"),
  },
  {
    key: BountyEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const ArchivedEnum = {
  SHOW: "ARCHIVED_ENUM_SHOW",
  HIDE: "ARCHIVED_ENUM_HIDE",
};

/** Filter by whether this item has been archived or not */
export const archivedItems: IFilterItem[] = [
  {
    key: ArchivedEnum.SHOW,
    value: undefined,
    getLabel: () => i18next.t("filter.archived.show"),
  },
  {
    key: ArchivedEnum.HIDE,
    value: "0",
    getLabel: () => i18next.t("filter.archived.hide"),
  },
];

export const OsEnum = {
  WINDOWS: "OS_ENUM_WINDOWS",
  MACOS: "OS_ENUM_MACOS",
  UBUNTU: "OS_ENUM_UBUNTU",
  ALL: "OS_ENUM_ALL",
};

/** Filter device by Operating System */
export const osItems: IFilterItem[] = [
  {
    key: OsEnum.WINDOWS,
    value: "windows",
    getLabel: () => i18next.t("filter.os.windows"),
  },
  {
    key: OsEnum.MACOS,
    value: "darwin",
    getLabel: () => i18next.t("filter.os.macos"),
  },
  {
    key: OsEnum.UBUNTU,
    value: "ubuntu",
    getLabel: () => i18next.t("filter.os.ubuntu"),
  },
  {
    key: OsEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const HostStatusEnum = {
  ONLINE: "HOST_STATUS_ENUM_ONLINE",
  OFFLINE: "HOST_STATUS_ENUM_OFFLINE",
  ALL: "HOST_STATUS_ENUM_ALL",
};

/** Filter host by status */
export const hostStatusItems: IFilterItem[] = [
  {
    key: HostStatusEnum.ONLINE,
    value: "online",
    getLabel: () => i18next.t("filter.hostStatus.online"),
  },
  {
    key: HostStatusEnum.OFFLINE,
    value: "offline",
    getLabel: () => i18next.t("filter.hostStatus.offline"),
  },
  {
    key: HostStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const SoftwareVulnerableStatusEnum = {
  YES: "VULNERABLE_ENUM_YES",
  NO: "VULNERABLE_ENUM_NO",
  ALL: "VULNERABLE_ENUM_ALL",
};

export const softwareVulnerableItems: IFilterItem[] = [
  {
    key: SoftwareVulnerableStatusEnum.YES,
    value: "1",
    getLabel: () => i18next.t("filter.softwareVulnerable.vulnerable"),
  },
  {
    key: SoftwareVulnerableStatusEnum.NO,
    value: "0",
    getLabel: () => i18next.t("filter.softwareVulnerable.notVulnerable"),
  },
  {
    key: SoftwareVulnerableStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const ProgramTypeEnum = {
  PUBLIC: "PROGRAM_TYPE_ENUM_PUBLIC",
  SEMI_PRIVATE: "PROGRAM_TYPE_ENUM_SEMI_PRIVATE",
  PRIVATE: "PROGRAM_TYPE_ENUM_PRIVATE",
  ALL: "PROGRAM_TYPE_ENUM_ALL",
};

export const programTypeItems = [
  {
    key: ProgramTypeEnum.PUBLIC,
    value: "public",
    getLabel: () => i18next.t("filter.programType.public"),
  },
  {
    key: ProgramTypeEnum.SEMI_PRIVATE,
    value: "semi-private",
    getLabel: () => i18next.t("filter.programType.semiPrivate"),
  },
  {
    key: ProgramTypeEnum.PRIVATE,
    value: "private",
    getLabel: () => i18next.t("filter.programType.private"),
  },
  {
    key: ProgramTypeEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const ControlActionEnum = {
  ALLOW_NETWORK: "CONTROL_ACTION_ENUM_ALLOW_NETWORK",
  BLOCK_NETWORK: "CONTROL_ACTION_ENUM_BLOCK_NETWORK",
  ALLOW_IP: "CONTROL_ACTION_ENUM_ALLOW_IP",
  BLOCK_IP: "CONTROL_ACTION_ENUM_BLOCK_IP",
  ALLOW_PORT: "CONTROL_ACTION_ENUM_ALLOW_PORT",
  BLOCK_PORT: "CONTROL_ACTION_ENUM_BLOCK_PORT",
  ALL: "CONTROL_ACTION_ENUM_ALL",
};

export const controlNetworkActionItems = [
  {
    key: ControlActionEnum.ALLOW_NETWORK,
    value: "allow_network",
    getLabel: () => i18next.t("filter.controlAction.allow"),
  },
  {
    key: ControlActionEnum.BLOCK_NETWORK,
    value: "block_network",
    getLabel: () => i18next.t("filter.controlAction.block"),
  },
  {
    key: ControlActionEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const controlIpActionItems = [
  {
    key: ControlActionEnum.ALLOW_IP,
    value: "allow_network_ip",
    getLabel: () => i18next.t("filter.controlAction.allow"),
  },
  {
    key: ControlActionEnum.BLOCK_IP,
    value: "block_network_ip",
    getLabel: () => i18next.t("filter.controlAction.block"),
  },
  {
    key: ControlActionEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const controlPortActionItems = [
  {
    key: ControlActionEnum.ALLOW_PORT,
    value: "allow_network_port",
    getLabel: () => i18next.t("filter.controlAction.allow"),
  },
  {
    key: ControlActionEnum.BLOCK_PORT,
    value: "block_network_port",
    getLabel: () => i18next.t("filter.controlAction.block"),
  },
  {
    key: ControlActionEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];
