// Libraries
import { Dropdown } from "@lockerpm/design";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// Components
import { TableCheckbox, TableDataCell } from "#src/common/Table";
import { TableRowWrapperAnchor } from "#src/common/Table/TableRowWrapper";
import { getTwColorByStatus } from "#src/common/variants/byStatus";
import { SeverityLabel } from "#src/common/helper/labels/SeverityLabel";

// API-related
import type { IAssetVulnerabilityItem } from "#src/services/assets/vulnerabilities";
import { vulnerabilityStatusItems } from "#src/config/filter/vulnerability";

interface IVulnerabilityRowProps {
  workspaceId: string;
  data: IAssetVulnerabilityItem;
  selectedVulnerabilityIds: number[];
  onChangeSelectedVulnerabilities: (selected: IAssetVulnerabilityItem) => void;
  onChangeVulnerabilityStatus: (
    vulnerabilityId: number,
    subStatus: string | undefined
  ) => void;
}

const VulnerabilityTableRow = ({
  workspaceId,
  data,
  selectedVulnerabilityIds,
  onChangeSelectedVulnerabilities,
  onChangeVulnerabilityStatus,
}: IVulnerabilityRowProps) => {
  return (
    <TableRowWrapperAnchor
      to={`/${workspaceId}/vulnerabilities?vuln_id=${data.id}`}
    >
      <TableDataCell className="p-0 justify-center">
        <TableCheckbox
          checked={selectedVulnerabilityIds.includes(data.id)}
          onClick={() => {
            onChangeSelectedVulnerabilities(data);
          }}
        />
      </TableDataCell>
      <TableDataCell>
        <SeverityLabel severity={data.severity} />
      </TableDataCell>
      <TableDataCell>{data.title}</TableDataCell>
      <TableDataCell>{data.cvss_score}</TableDataCell>
      <TableDataCell>
        <Dropdown
          menu={{
            items: vulnerabilityStatusItems.map((item) => ({
              key: item.key,
              value: item.value,
              type: item.type,
              label: (
                <div className="px-3 py-2 bg-bright-grey text-medium-grey font-medium-14-forced w-[13.5rem]">
                  {item.getLabel()}
                </div>
              ),
              children: item.children.map((childItem) => ({
                key: childItem.key,
                value: childItem.value,
                label: (
                  <div className="flex items-center gap-2 p-3 text-medium-grey font-medium-16 w-[13.5rem]">
                    <div
                      className={`${
                        childItem.value
                          ? getTwColorByStatus(childItem.value)
                          : ""
                      } h-2 w-2 rounded-full`}
                    />
                    {childItem.getLabel()}
                  </div>
                ),
              })),
            })),
            onClick: (obj) => {
              const newSelected = vulnerabilityStatusItems
                .map((item) => item.children)
                .flat()
                .find((item) => item.key === obj.key);
              if (newSelected)
                onChangeVulnerabilityStatus(data.id, newSelected.value);
            },
          }}
          placement="bottomRight"
          arrow
          trigger={["click"]}
        >
          <div
            className="flex gap-3 bg-bright-grey p-3 w-64 items-center justify-between rounded-md text-hard-grey cursor-pointer"
            onClick={(e) => {
              // Prevent the action of the link behind, which would have redirected us into other page
              e.preventDefault();
            }}
          >
            <div className="flex gap-3 items-center">
              <div
                className={`w-2.5 h-2.5 rounded-full ${
                  data.sub_status ? getTwColorByStatus(data.sub_status) : ""
                }`}
              />
              {vulnerabilityStatusItems
                .map((item) => item.children)
                .flat()
                .find((item) => item.value === data.sub_status)
                ?.getLabel()}
            </div>
            <ArrowDownSLine />
          </div>
        </Dropdown>
      </TableDataCell>
    </TableRowWrapperAnchor>
  );
};

export default VulnerabilityTableRow;
