import { DLPAction, DLPActionStatus } from "#src/services/endpoint/dlp/enums";
import i18next from "i18next";

const fixedTFunc = i18next.getFixedT(
  null,
  "endpoint",
  "dlp.activities.table.filter"
);

export const activityTypeFilterItems = [
  {
    key: "backup",
    getLabel: () => fixedTFunc("activity.backup"),
    value: DLPAction.BACKUP,
  },
  {
    key: "restore",
    getLabel: () => fixedTFunc("activity.restore"),
    value: DLPAction.RESTORE,
  },
];

export const activityStatusFilterItems = [
  {
    key: "success",
    getLabel: () => (
      <span className="flex items-center gap-2">
        <span className="h-2 w-2 m-0.5 bg-success-v2 rounded-full" />
        {fixedTFunc("status.success")}
      </span>
    ),
    value: DLPActionStatus.SUCCESS,
  },
  {
    key: "fail",
    getLabel: () => (
      <span className="flex items-center gap-2">
        <span className="h-2 w-2 m-0.5 bg-error-v2 rounded-full" />
        {fixedTFunc("status.fail")}
      </span>
    ),
    value: DLPActionStatus.FAIL,
  },
];
