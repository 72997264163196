// Libraries
import { useTranslation } from "react-i18next";
import type { Dispatch, SetStateAction } from "react";
import { marked } from "marked";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import usFlag from "#src/assets/images/flags/us.png";
import vnFlag from "#src/assets/images/flags/vn.png";

// General
import { useAppSelector } from "#src/store/hooks";

// Components
import {
  type FormErrorItem,
  InputField,
} from "#src/common/helper/wrapper/InputField";
import { Flag } from "#src/common/Flag";
import { simpleTokenParser } from "#src/utils/common";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";

// API-related
import { TrustCenterPlanValue } from "#src/config/filter/trustCenter/value";

interface IAddPolicyLanguageItemProps {
  workspaceId: string;
  policyLangItem: { lang: string; name: string; description: string };
  policyDetail: { lang: string; name: string; description: string }[];
  setPolicyDetail: Dispatch<
    SetStateAction<{ lang: string; name: string; description: string }[]>
  >;
  errorObj: {
    policyName: FormErrorItem;
    policyDescription: FormErrorItem;
  };
  setErrorObj: Dispatch<
    SetStateAction<{
      policyName: FormErrorItem;
      policyDescription: FormErrorItem;
    }>
  >;
}

export const AddPolicyLanguageItem = ({
  workspaceId,
  policyLangItem,
  policyDetail,
  setPolicyDetail,
  errorObj,
  setErrorObj,
}: IAddPolicyLanguageItemProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "policy.addPolicy",
  });

  const trustCenterPlan = useAppSelector((state) => state.trustCenter.plan);

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  const companyShortName = currentWorkspace
    ? currentWorkspace.short_name
      ? currentWorkspace.short_name
      : currentWorkspace.name
    : undefined;

  return (
    <div className="flex flex-col gap-6">
      {/* h-6 to fit the close icon so when adding new language this div's height won't change. */}
      <div className="flex justify-between items-center h-6">
        <p className="text-hard-grey flex gap-2">
          {policyLangItem.lang === "en" ? (
            <>
              <Flag pngProp={usFlag} />
              {t("policy.language.english")}
            </>
          ) : policyLangItem.lang === "vi" ? (
            <>
              <Flag pngProp={vnFlag} />
              {t("policy.language.vietnamese")}
            </>
          ) : null}
        </p>
        {policyDetail.length > 1 ? (
          <button
            className="h-6 w-6 text-hard-grey"
            onClick={() => {
              setPolicyDetail((prev) =>
                prev.filter((item) => item.lang !== policyLangItem.lang)
              );
            }}
          >
            <CloseLine />
          </button>
        ) : null}
      </div>
      <div className="flex flex-col gap-6 border border-light-grey p-6 rounded-md">
        <InputField
          title={t("policy.name.title")}
          className="w-1/2"
          placeholder={t("policy.name.placeholder")}
          value={policyLangItem.name}
          onChangeValue={(value) => {
            setPolicyDetail((prev) =>
              prev.map((item) =>
                item.lang === policyLangItem.lang
                  ? { ...item, name: value }
                  : item
              )
            );
          }}
          validation={{
            required: true,
            maxLength: 255,
          }}
          error={errorObj.policyName}
          onChangeError={(value) => {
            setErrorObj((prev) => ({ ...prev, policyName: value }));
          }}
        />
        <InputField
          title={t("policy.description.title")}
          type="markdown"
          placeholder={t("policy.description.placeholder")}
          value={policyLangItem.description}
          onChangeValue={(value) => {
            setPolicyDetail((prev) =>
              prev.map((item) =>
                item.lang === policyLangItem.lang
                  ? { ...item, description: value }
                  : item
              )
            );
          }}
          validation={{
            required: true,
            maxLength:
              trustCenterPlan === TrustCenterPlanValue.ENTERPRISE ? 8192 : 4096,
          }}
          error={errorObj.policyDescription}
          onChangeError={(value) => {
            setErrorObj((prev) => ({ ...prev, policyDescription: value }));
          }}
          parser={(value) =>
            marked.parse(
              companyShortName
                ? simpleTokenParser(value, { companyShortName })
                : value
            ) as string
          }
        />
      </div>
    </div>
  );
};
