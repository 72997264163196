import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import i18next from "i18next";
import { pathname } from "#src/config/pathname";

// Layout
import MainLayout from "#src/layouts";
import { LayoutV2 } from "#src/layoutV2";

import Authenticate from "#src/pages/auth/authenticate";
import Login from "#src/pages/auth/login";
import HomePage, { homePageLoader } from "#src/pages/home";
import GeneralErrorPage from "#src/pages/error";
import CreateWorkspace from "#src/pages/workspace/new";

import { assetRoute } from "./routes/asset";
import { deviceRoute } from "./routes/device";
import { vulnerabilityRoute } from "./routes/vulnerability";
import { scanRoute } from "./routes/scan";
import { pentestRoute } from "./routes/pentest";
import { bugBountyRoute } from "./routes/bugBounty";
import { dataLeakRoute } from "./routes/dataLeak";
import { trustCenterRoute } from "./routes/trustCenter";
import { msspRoute } from "./routes/mssp";
import { accountRoute } from "./routes/account";
import { workspaceRoute } from "./routes/workspace";
import { allSolutionRoute } from "./routes/solutions";
import { endpointRoute } from "./routes/endpoint";

import TestPage from "#src/pages/test";

const breadcrumbTFunc = i18next.getFixedT(null, "layout", "topbar.breadcrumb");

const router = createBrowserRouter(
  [
    {
      // Root with error element
      element: <Outlet />,
      errorElement: <GeneralErrorPage />,
      children: [
        {
          path: "/test",
          element: <TestPage />,
        },
        {
          path: pathname.HOME,
          element: <HomePage />,
          loader: homePageLoader,
        },
        {
          path: pathname.AUTHENTICATE,
          element: <Authenticate />,
        },
        {
          path: pathname.LOGIN,
          element: <Login />,
        },
        {
          // Authenticated routes
          handle: {
            crumb: () => ({
              getText: () => breadcrumbTFunc("home"),
              path: pathname.DOMAINS,
            }),
          },
          element: <MainLayout />,
          children: [
            assetRoute,
            deviceRoute,
            vulnerabilityRoute,
            scanRoute,
            pentestRoute,
            bugBountyRoute,
            dataLeakRoute,
            trustCenterRoute,
            {
              path: pathname.NEW_WORKSPACE,
              element: <CreateWorkspace />,
            },
            accountRoute,
            workspaceRoute,
            msspRoute,
            allSolutionRoute,
          ],
        },
        {
          // V2 routes
          element: <LayoutV2 />,
          children: [endpointRoute],
        },
      ],
    },
  ],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

export const Router = () => {
  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  );
};
