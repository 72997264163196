import { Minus } from "@untitled-ui/icons-react";
import { Dispatch, SetStateAction, useState } from "react";
import { DateTime } from "luxon";
import { DatePickerV2 } from "./DatePicker";

interface IFilterByDateSelectTwoDatesProps {
  selectedDates: { from: DateTime; to: DateTime };
  setSelectedDates: Dispatch<SetStateAction<{ from: DateTime; to: DateTime }>>;
}

export const FilterByDateSelectTwoDates = ({
  selectedDates,
  setSelectedDates,
}: IFilterByDateSelectTwoDatesProps) => {
  const [openDatePicker1, setOpenDatePicker1] = useState<boolean>(false);
  const [openDatePicker2, setOpenDatePicker2] = useState<boolean>(false);

  return (
    <div className="flex items-center justify-between gap-2">
      <DatePickerV2
        open={openDatePicker1}
        onChangeOpen={(value) => {
          setOpenDatePicker1(value);
        }}
        currentValue={selectedDates.from}
        onApply={(value) => {
          setSelectedDates((prev) => {
            if (value && prev.to && value.toMillis() > prev.to.toMillis()) {
              return { from: value, to: value };
            }
            return { from: value, to: prev.to };
          });
        }}
      />
      <Minus className="h-5 w-5 min-w-[1.25rem] text-gray-v2-700" />
      <DatePickerV2
        open={openDatePicker2}
        onChangeOpen={(value) => {
          setOpenDatePicker2(value);
        }}
        currentValue={selectedDates.to}
        onApply={(value) => {
          setSelectedDates((prev) => {
            if (value && prev.from && prev.from.toMillis() > value.toMillis()) {
              return { from: value, to: value };
            }
            return { from: prev.from, to: value };
          });
        }}
      />
    </div>
  );
};
