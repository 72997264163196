// Libraries
import { Dropdown } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import { Input } from "#src/common/system/Input";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import { SeverityLabel } from "#src/common/helper/labels/SeverityLabel";

export interface IBugBountyRewardRangeItem {
  type: IFilterItem;
  severity: string;
  point: number;
  from: number;
  to: number;
}

const fixedTFunc = i18next.getFixedT(
  null,
  "bugBounty",
  "page.addBugBounty.rewardRange"
);

export const bugBountyRewardTypeItems: IFilterItem[] = [
  { key: "point", value: "point", getLabel: () => fixedTFunc("type.point") },
  { key: "money", value: "money", getLabel: () => fixedTFunc("type.money") },
];

interface IAddBugBountyRewardRangeProps {
  status: string;
  currency: "VND" | "USD";
  rewardRange: IBugBountyRewardRangeItem[];
  onChangeCurrency: (value: "VND" | "USD") => void;
  onChangeRewardRangeType: (
    severity: IBugBountyRewardRangeItem["severity"],
    type: IBugBountyRewardRangeItem["type"]
  ) => void;
  onChangeRewardRangeFrom: (
    severity: IBugBountyRewardRangeItem["severity"],
    type: IBugBountyRewardRangeItem["from"]
  ) => void;
  onChangeRewardRangeTo: (
    severity: IBugBountyRewardRangeItem["severity"],
    type: IBugBountyRewardRangeItem["to"]
  ) => void;
  disabled?: boolean;
}

const AddBugBountyRewardRange = ({
  status,
  currency,
  rewardRange,
  onChangeCurrency,
  onChangeRewardRangeType,
  onChangeRewardRangeFrom,
  onChangeRewardRangeTo,
  disabled = false,
}: IAddBugBountyRewardRangeProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.addBugBounty.rewardRange",
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-4">
          <h2>{t("title")}</h2>
          <a href="https://s.whitehub.net/resources/docs/what_is_a_vulnerability_worth.pdf">
            {t("instruction")}
          </a>
        </div>
        {disabled || status !== "draft" ? null : (
          <div className="flex">
            <button
              className={`px-6 py-3 h-fit font-medium-16 rounded-l-md ${
                currency === "VND"
                  ? "bg-dark-blue text-white"
                  : "bg-light-grey text-hard-grey"
              }`}
              onClick={() => {
                onChangeCurrency("VND");
              }}
            >
              VND
            </button>
            <button
              className={`px-6 py-3 h-fit font-medium-16 rounded-r-md ${
                currency === "USD"
                  ? "bg-dark-blue text-white"
                  : "bg-light-grey text-hard-grey"
              }`}
              onClick={() => {
                onChangeCurrency("USD");
              }}
            >
              USD
            </button>
          </div>
        )}
      </div>
      <div className="grid grid-cols-[4fr_2fr_6rem_3fr_3fr]">
        <div className="grid grid-cols-subgrid col-span-full bg-bright-grey text-hard-grey gap-6">
          <div className="flex justify-center items-center text-center py-5">
            {t("headers.type")}
          </div>
          <div className="flex justify-center items-center text-center py-5">
            {t("headers.severity")}
          </div>
          <div className="flex justify-center items-center text-center py-5">
            {t("headers.point")}
          </div>
          <div className="flex justify-center items-center text-center py-5">
            {t("headers.rangeFrom")}
          </div>
          <div className="flex justify-center items-center text-center py-5">
            {t("headers.rangeTo")}
          </div>
        </div>
        {rewardRange.map((range) => (
          <div
            key={`addBugbounty-reward-${range.severity}`}
            className="grid grid-cols-subgrid col-span-full gap-6"
          >
            <div className="flex items-center justify-center py-3">
              {disabled ? (
                range.type.getLabel()
              ) : (
                <Dropdown
                  menu={{
                    items: bugBountyRewardTypeItems.map((item) => ({
                      key: item.key,
                      label: (
                        <DropdownItemLabel
                          syncWidthId="bugbounty-reward-type-dropdown"
                          selected={range.type.key === item.key}
                        >
                          {item.getLabel()}
                        </DropdownItemLabel>
                      ),
                    })),
                    onClick: ({ key }) => {
                      const selected = bugBountyRewardTypeItems.find(
                        (item) => item.key === key
                      );
                      if (selected) {
                        onChangeRewardRangeType(range.severity, selected);
                      }
                    },
                  }}
                  trigger={["click"]}
                >
                  <DropdownTriggerButton
                    id="bugbounty-reward-type-dropdown"
                    className="w-full"
                  >
                    {range.type.getLabel()}
                    <ArrowDownSLine />
                  </DropdownTriggerButton>
                </Dropdown>
              )}
            </div>
            <div className="flex items-center justify-center py-3">
              <SeverityLabel severity={range.severity} />
            </div>
            <div className="flex items-center justify-center py-3">
              {range.point}
            </div>
            <div className="flex items-center justify-center text-center py-3 relative">
              {disabled ? (
                range.from + " " + currency
              ) : range.type.value === "money" ? (
                <>
                  <Input
                    type="text"
                    inputMode="numeric"
                    className="pr-12"
                    value={range.from}
                    onChange={(e) => {
                      if (!isNaN(Number(e.currentTarget.value))) {
                        onChangeRewardRangeFrom(
                          range.severity,
                          Number(e.currentTarget.value)
                        );
                      }
                    }}
                  />
                  <span className="absolute right-3 pointer-events-none text-medium-grey">
                    {currency}
                  </span>
                </>
              ) : null}
            </div>
            <div className="flex items-center justify-center text-center py-3 relative">
              {disabled ? (
                range.to + " " + currency
              ) : range.type.value === "money" ? (
                <>
                  <Input
                    type="text"
                    inputMode="numeric"
                    className="pr-12"
                    value={range.to}
                    onChange={(e) => {
                      if (!isNaN(Number(e.currentTarget.value))) {
                        onChangeRewardRangeTo(
                          range.severity,
                          Number(e.currentTarget.value)
                        );
                      }
                    }}
                  />
                  <span className="absolute right-3 pointer-events-none text-medium-grey">
                    {currency}
                  </span>
                </>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddBugBountyRewardRange;
