import {
  type InputHTMLAttributes,
  type TextareaHTMLAttributes,
  forwardRef,
} from "react";
import { twMerge } from "tailwind-merge";

export type InputVariant = "primary" | "warning";

export const variants: { [Variant in InputVariant]: string } = {
  primary: "focus:outline-brand-v2",
  warning: "",
};

const defaultStyles =
  "bg-white border border-gray-v2-300 shadow-xs rounded-lg px-3 py-2 flex items-center gap-2 outline outline-2 outline-transparent text-md transition-all ease-in";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: InputVariant;
}

export const InputV2 = forwardRef<HTMLInputElement, IInputProps>(
  ({ variant = "primary", ...props }, ref) => {
    return (
      <input
        ref={ref}
        {...props}
        placeholder={props.disabled ? undefined : props.placeholder}
        className={twMerge(defaultStyles, variants[variant], props.className)}
      />
    );
  }
);

InputV2.displayName = "InputV2";

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: InputVariant;
}

export const TextAreaV2 = forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  ({ variant = "primary", ...props }, ref) => {
    const calcRow = props.rows ? props.rows : 4;

    return (
      <textarea
        ref={ref}
        {...props}
        placeholder={props.disabled ? undefined : props.placeholder}
        className={twMerge(defaultStyles, variants[variant], props.className)}
        rows={calcRow}
        style={{ height: calcRow * 2 + 0.75 + "rem", ...props.style }}
      />
    );
  }
);

TextAreaV2.displayName = "TextAreaV2";
