// Libraries
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Resources
import { ReactComponent as GitRepositoryLine } from "#src/assets/images/icons/git-repository-line.svg";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";

// Children
import MsspPentestList from "#src/components/mssp/pentest/PentestList";

const MsspPentest = () => {
  const { t } = useTranslation("pentest");

  const { workspaceId } = useParams<"workspaceId">();

  return workspaceId ? (
    <>
      <StickySection>
        <div className="flex flex-col gap-4">
          <h1>MSSP {t("title")}</h1>
          <div className="flex gap-2">
            <GitRepositoryLine
              width="1.25rem"
              height="1.25rem"
              className="fill-primary"
            />
            <a>{t("link.learnMore")}</a>
          </div>
        </div>
      </StickySection>
      <ContentSection>
        <MsspPentestList workspaceId={workspaceId} />
      </ContentSection>
    </>
  ) : null;
};

export default MsspPentest;
