import { Typography } from "@lockerpm/design";
import { useMemo } from "react";

const tagColors = [
  "gray",
  "brand",
  "error",
  "warning",
  "success",
  "gray-blue",
  "blue-light",
  "blue",
  "indigo",
  "purple",
  "pink",
  "orange",
] as const;

const tailwindColorClasses: { [C in (typeof tagColors)[number]]: string } = {
  gray: "bg-gray-v2-50 border-gray-v2-200 text-gray-v2-700",
  brand: "bg-brand-v2-50 border-brand-v2-200 text-brand-v2-700",
  error: "bg-error-v2-50 border-error-v2-200 text-error-v2-700",
  warning: "bg-warning-v2-50 border-warning-v2-200 text-warning-v2-700",
  success: "bg-success-v2-50 border-success-v2-200 text-success-v2-700",
  "gray-blue":
    "bg-gray-blue-v2-50 border-gray-blue-v2-200 text-gray-blue-v2-700",
  "blue-light":
    "bg-blue-light-v2-50 border-blue-light-v2-200 text-blue-light-v2-700",
  blue: "bg-blue-v2-50 border-blue-v2-200 text-blue-v2-700",
  indigo: "bg-indigo-v2-50 border-indigo-v2-200 text-indigo-v2-700",
  purple: "bg-purple-v2-50 border-purple-v2-200 text-purple-v2-700",
  pink: "bg-pink-v2-50 border-pink-v2-200 text-pink-v2-700",
  orange: "bg-orange-v2-50 border-orange-v2-200 text-orange-v2-700",
};

const sizes = ["sm", "md", "lg"] as const;

const tailwindSizeClasses: { [C in (typeof sizes)[number]]: string } = {
  sm: "px-2 py-0.5 text-xs",
  md: "px-2.5 py-0.5 text-sm",
  lg: "px-3 py-1 text-sm",
};

interface ITagProps {
  name: string;
  color?: (typeof tagColors)[number];
  size?: (typeof sizes)[number];
}

/** In design system, this is called Badge. */
export const TagV2 = ({ name, color, size = "sm" }: ITagProps) => {
  // If color is not selected, get color from text's hash
  const chooseColor = useMemo(() => {
    if (color) return color;
    const hash = Array.from(name).reduce(
      (prev, cur) => prev + cur.charCodeAt(0),
      0
    );

    return tagColors[hash % tagColors.length];
  }, [name, color]);

  return (
    <div
      className={`${tailwindSizeClasses[size]} max-w-24 w-fit rounded-full font-medium border ${tailwindColorClasses[chooseColor]}`}
    >
      <Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>
    </div>
  );
};
