import { useMemo, useState } from "react";
import { IHostDetailItem } from "#src/services/devices";
import { useOutletContext } from "react-router-dom";
import { IFilterItem } from "#src/@types/common";
import constants from "#src/config/constants";
import { ActivityFilter } from "#src/components/devices/hosts/details/v2/activity/ActivityFilter";
import { ActivityTable } from "#src/components/devices/hosts/details/v2/activity/ActivityTable";

const HostActivities = () => {
  const { hostDetails, workspaceId } = useOutletContext<{
    hostDetails: IHostDetailItem;
    workspaceId: string;
    hostId: string;
  }>();

  if (!hostDetails) return null;

  // ----------------------------- DATA -----------------------------

  const [activityType, setActivityType] = useState<IFilterItem | null>(null);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  // --------------- COMPUTED ---------------

  const fetchParams = useMemo<Parameters<any>>(
    () => [
      workspaceId,
      hostDetails.id,
      {
        type: activityType?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      hostDetails,
      activityType,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  // --------------- HOOKS ---------------

  const {
    list: hostActivityList,
    count: hostActivityCount,
    isLoading,
  } = {
    isLoading: false,
    list: [],
    count: 0,
  };

  // --------------- RENDER ---------------

  return (
    <>
      {/* Filter */}
      <ActivityFilter
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        setSelectedPage={setSelectedPage}
        activityType={activityType}
        setActivityType={setActivityType}
      />
      {/* Filter end */}

      {/* Table */}
      <ActivityTable
        hostActivityList={hostActivityList || []}
        isLoading={isLoading}
        hostActivityCount={hostActivityCount}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      {/* Table end */}
    </>
  );
};

export default HostActivities;
