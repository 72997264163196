// Libraries
import { type Dispatch, type SetStateAction } from "react";

// Components
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";

// Children
import TrustCenterConfigProfileTabProfile from "./Profile";
import TrustCenterConfigProfileTabSecurity from "./Security";

interface ITrustCenterConfigProfileProps {
  workspaceId: string;
  originalSettings: ITrustCenterConfigDetails;
  setOriginalSettings: Dispatch<SetStateAction<ITrustCenterConfigDetails>>;
}

const TrustCenterConfigProfile = ({
  workspaceId,
  originalSettings,
  setOriginalSettings,
}: ITrustCenterConfigProfileProps) => {
  return (
    <div className="flex flex-col gap-6 w-2/3">
      <TrustCenterConfigProfileTabProfile
        workspaceId={workspaceId}
      />
      <TrustCenterConfigProfileTabSecurity
        workspaceId={workspaceId}
        originalSettings={originalSettings}
        setOriginalSettings={setOriginalSettings}
      />
    </div>
  );
};

export default TrustCenterConfigProfile;
