import type { RouteObject } from "react-router-dom";
import { helperPathname, pathname } from "#src/config/pathname";

import { EndpointOverview } from "#src/pages/endpoint/overview";
import { EndpointDevices } from "#src/pages/endpoint/devices";
import { EndpointPolicies } from "#src/pages/endpoint/policies";
import { DlpBackup } from "#src/pages/endpoint/dlp/backup";
import { DlpActivities } from "#src/pages/endpoint/dlp/activities";
import HostDetailsLayout from "#src/pages/devices/hosts/v2/layout";
import HostOverview from "#src/pages/devices/hosts/v2/overview";
import HostSoftware from "#src/pages/devices/hosts/v2/software";
import HostPolicies from "#src/pages/devices/hosts/v2/policies";
import HostActivities from "#src/pages/devices/hosts/v2/activities";

export const endpointRoute: RouteObject = {
  path: helperPathname.ENDPOINT,
  children: [
    {
      path: pathname.ENDPOINT_OVERVIEW,
      element: <EndpointOverview />,
    },
    {
      path: pathname.ENDPOINT_DEVICES,
      element: <EndpointDevices />,
    },
    {
      path: helperPathname.ENDPOINT_DEVICE_DETAIL,
      element: <HostDetailsLayout />,
      children: [
        {
          path: pathname.ENDPOINT_DEVICES_OVERVIEW,
          element: <HostOverview />,
        },
        {
          path: pathname.ENDPOINT_DEVICES_SOFTWARE,
          element: <HostSoftware />,
        },
        {
          path: pathname.ENDPOINT_DEVICES_POLICIES,
          element: <HostPolicies />,
        },
        {
          path: pathname.ENDPOINT_DEVICES_ACTIVITIES,
          element: <HostActivities />,
        },
      ],
    },
    {
      path: pathname.ENDPOINT_POLICIES,
      element: <EndpointPolicies />,
    },
    {
      path: helperPathname.ENDPOINT_DLP,
      children: [
        {
          path: pathname.ENDPOINT_DLP_BACKUP,
          element: <DlpBackup />,
        },
        {
          path: pathname.ENDPOINT_DLP_ACTIVITIES,
          element: <DlpActivities />,
        },
      ],
    },
  ],
};
