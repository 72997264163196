/* --- Almost exact component as AddScanDrawer on ScanTable, but different choices and have an initial state. */

// Libraries
import { useEffect, useState } from "react";
import { Collapse, Drawer, Dropdown, Switch } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import dayjs, { type Dayjs } from "dayjs";
import i18next from "i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import type { IDrawerAdapterProps, IFilterItem } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import { CollapsePanelHeading3 } from "#src/common/helper/antdProps/Collapse/PanelHeader";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import scansServices from "#src/services/scans";
import type { IScanScheduleItem } from "#src/services/scans/schedules";
import { scanFrequencyItems } from "#src/config/filter/scan";
import { ScanFrequencyEnum } from "#src/config/filter/scan/enum";

// Children
import EditSettingsDrawer from "#src/components/assets/drawers/EditSettingDrawer";
import { getScheduleSummary } from "#src/components/scans/utils";
import DomainInput, {
  type IScanDomainItem,
} from "../../all-scans/AddScanDrawer/DomainInput";
import StartTimePopover from "../../all-scans/AddScanDrawer/StartTimePopover";
import DaysOfWeekToggleGroup from "../../all-scans/AddScanDrawer/DaysOfWeekToggleGroup";

const panelKeys = {
  domains: "domains",
  schedule: "schedule",
};

interface IEditScheduleDrawerProps extends IDrawerAdapterProps {
  selectedSchedule: IScanScheduleItem;
}

const EditScheduleDrawer = ({
  open,
  workspaceId,
  onClose,
  onRefresh,
  selectedSchedule,
}: IEditScheduleDrawerProps) => {
  const { t } = useTranslation("scanSchedules", {
    keyPrefix: "drawer.editSchedule",
  });

  const defaultDomainItem: IScanDomainItem = {
    key: "0",
    id: "",
    value: "",
    address: "",
    label: "",
    includingSubdomains: false,
  };

  const [activeAuthPanel, setActiveAuthPanel] = useState<string[]>([]);
  const [selectedDomains, setSelectedDomains] = useState<IScanDomainItem[]>(
    selectedSchedule.domains.map((domain) => ({
      key: domain.id.toString(),
      id: domain.id.toString(),
      value: domain.id.toString(),
      address: domain.address,
      label: domain.name,
      includingSubdomains: domain.include_subdomains,
    }))
  );
  // Schedule has to be initiate with "Daily" instead on "On demand"
  const [frequency, setFrequency] = useState<IFilterItem>(
    scanFrequencyItems.find(
      (item) => item.key === selectedSchedule.duration_type
    ) ||
      scanFrequencyItems.find((item) => item.key === ScanFrequencyEnum.DAILY) ||
      scanFrequencyItems[1]
  );
  const [repeatNumber, setRepeatNumber] = useState<number>(
    selectedSchedule.repeat_number
  ); // repeat every 2 weeks -> repeatNumber is 2
  const [daysOfWeek, setDaysOfWeek] = useState<number[]>(
    selectedSchedule.days.map((day) => +day)
  );
  const [startTime, setStartTime] = useState<Dayjs>(
    dayjs.unix(
      selectedSchedule.activated_time || selectedSchedule.created_time || 0
    )
  );

  const [editingItem, setEditingItem] = useState<IScanDomainItem | null>(null);
  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);

  const onCloseDrawerAndCleanup = () => {
    onClose();
    setSelectedDomains([defaultDomainItem]);
    setFrequency(scanFrequencyItems[0]);
    setRepeatNumber(1);
    setDaysOfWeek([]);
    setStartTime(dayjs());
  };

  const onOpenEditDrawer = (domain: IScanDomainItem) => {
    setEditingItem(domain);
    setOpenEditDrawer(true);
  };

  const onSelectFrequency = (key: string) => {
    const chosenFrequency = scanFrequencyItems.find((item) => item.key === key);
    if (chosenFrequency) {
      setFrequency(chosenFrequency);
      setRepeatNumber(1);
      setDaysOfWeek([]);
    }
  };

  const onSelectRepeatNumber = (key: string) => {
    setRepeatNumber(+key);
  };

  const onToggleDayOfWeek = (key: number) => {
    if (daysOfWeek.includes(key)) {
      setDaysOfWeek((prev) => prev.filter((item) => item !== key));
    } else {
      setDaysOfWeek((prev) => [...prev, key]);
    }
  };

  const onChangeStartTime = (newStartTime: Dayjs) => {
    setStartTime(newStartTime);
  };

  const onClickLaunchScan = () => {
    scansServices
      .update_schedule(workspaceId, selectedSchedule.id, {
        schedule: true,
        activated_time: startTime.unix(),
        timezone: "UTC",
        // frequency items should always have value as string
        duration_type: frequency.value as string,
        repeat_number: repeatNumber,
        days: daysOfWeek.map((day) => day.toString()),
      })
      .then(() => {
        onRefresh();
        onCloseDrawerAndCleanup();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.editScan.fail"),
        });
      });
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      scansServices
        .get_detail_schedule(workspaceId, selectedSchedule.id)
        .then((response) => {
          if (response.domains.length > 0) {
            setSelectedDomains(
              response.domains.map((domain) => ({
                key: domain.id.toString(),
                id: domain.id.toString(),
                value: domain.id.toString(),
                address: domain.address,
                label: domain.name,
                includingSubdomains: domain.include_subdomains,
              }))
            );
          }
          const initFrequency = scanFrequencyItems.find(
            (item) => item.key === response.duration_type
          );
          if (initFrequency) {
            setFrequency(initFrequency);
          }
          setRepeatNumber(response.repeat_number);
          // Make sure days of week are numbers
          setDaysOfWeek(response.days.map((day) => +day));
          setStartTime(
            dayjs.unix(response.activated_time || response.created_time)
          );
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: i18next.t(
              "scanSchedules:notification.loadSchedule.fail"
            ),
          });
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, selectedSchedule.id, onClose]);

  return (
    <>
      <Drawer
        open={open}
        onClose={onCloseDrawerAndCleanup}
        closable={false}
        title={<h2>{t("title")}</h2>}
        extra={
          <IconButton
            variant="label-grey"
            size={9}
            onClick={onCloseDrawerAndCleanup}
          >
            <CloseLine />
          </IconButton>
        }
        footer={
          <div className="flex flex-col gap-3">
            <Button
              className="w-full"
              size="large"
              onClick={onClickLaunchScan}
              disabled={selectedDomains.every(
                (domain) => domain.address === ""
              )}
            >
              {t("button.saveChanges")}
            </Button>
            <Button
              variant="secondary"
              className="w-full"
              size="large"
              onClick={onCloseDrawerAndCleanup}
            >
              {t("button.cancel")}
            </Button>
          </div>
        }
      >
        <Collapse
          ghost
          defaultActiveKey={[panelKeys.schedule]}
          expandIcon={({ isActive }) => <Switch checked={isActive} />}
          expandIconPosition="end"
          onChange={(key) => {
            if (typeof key === "string") {
              key = [key];
            }

            setActiveAuthPanel(key);
          }}
        >
          <div className="flex flex-col gap-3 p-6 border border-b border-light-grey">
            <h4>{t("field.domains.title")}</h4>
            <div className="flex flex-col gap-6">
              {selectedDomains.map((domain, index) => (
                <DomainInput
                  key={`domain-${domain}-${index}`}
                  workspaceId={workspaceId}
                  selectedDomain={domain}
                  onChangeDomain={() => {}}
                  onRemoveDomain={() => {}}
                  onOpenEditDrawer={onOpenEditDrawer}
                  disabled
                />
              ))}
            </div>
          </div>
          <Collapse.Panel
            key={panelKeys.schedule}
            header={
              <CollapsePanelHeading3
                active={activeAuthPanel.includes(panelKeys.schedule)}
                title={t("schedule")}
              />
            }
          >
            <div className="flex flex-col gap-6 p-6">
              <div className="flex flex-col gap-3">
                <h4>{t("field.summary")}</h4>
                <p className="font-regular-14 text-hard-grey">
                  {/* Just to make typescript happy, "frequency.value" should never be empty. */}
                  {frequency.value
                    ? getScheduleSummary(
                        true,
                        frequency.value,
                        repeatNumber,
                        dayjs(startTime).unix(),
                        daysOfWeek
                      )
                    : "Uh oh something is wrong."}
                </p>
              </div>
              <div className="flex flex-col gap-3">
                <h4>{t("field.frequency.title")}</h4>
                <Dropdown
                  menu={{
                    items: scanFrequencyItems
                      .filter((item) => item.key !== ScanFrequencyEnum.ONCE)
                      .map((item) => ({
                        key: item.key,
                        label: (
                          <DropdownItemLabel
                            selected={frequency.key === item.key}
                            syncWidthId="dropdown-frequency"
                          >
                            {item.getLabel()}
                          </DropdownItemLabel>
                        ),
                      })),
                    onClick: ({ key }) => {
                      onSelectFrequency(key);
                    },
                  }}
                  trigger={["click"]}
                >
                  <DropdownTriggerButton id="dropdown-frequency">
                    {frequency.getLabel()}
                    <ArrowDownSLine />
                  </DropdownTriggerButton>
                </Dropdown>
              </div>
              {frequency.key === ScanFrequencyEnum.WEEKLY ||
              frequency.key === ScanFrequencyEnum.MONTHLY ? (
                <div className="flex flex-col gap-3">
                  <h4>{t("field.repeatEvery.title")}</h4>
                  <Dropdown
                    menu={{
                      items: Array(...Array(20).keys())
                        .map((i) => ({ key: i + 1, label: `${i + 1} ` }))
                        .map((item) => ({
                          key: item.key,
                          label: (
                            <DropdownItemLabel
                              selected={repeatNumber === item.key}
                              syncWidthId="dropdown-repeatNumber"
                            >
                              {item.label +
                                (frequency.key === ScanFrequencyEnum.WEEKLY
                                  ? t("field.repeatEvery.week", {
                                      count: +item.key,
                                    })
                                  : frequency.key === ScanFrequencyEnum.MONTHLY
                                    ? t("field.repeatEvery.month", {
                                        count: +item.key,
                                      })
                                    : "")}
                            </DropdownItemLabel>
                          ),
                        })),
                      onClick: ({ key }) => {
                        onSelectRepeatNumber(key);
                      },
                    }}
                    trigger={["click"]}
                  >
                    <DropdownTriggerButton id="dropdown-repeatNumber">
                      {repeatNumber +
                        " " +
                        (frequency.key === ScanFrequencyEnum.WEEKLY
                          ? t("field.repeatEvery.week", { count: repeatNumber })
                          : frequency.key === ScanFrequencyEnum.MONTHLY
                            ? t("field.repeatEvery.month", {
                                count: repeatNumber,
                              })
                            : "")}
                      <ArrowDownSLine />
                    </DropdownTriggerButton>
                  </Dropdown>
                </div>
              ) : null}
              {frequency.key === ScanFrequencyEnum.WEEKLY ? (
                <div className="flex flex-col gap-3">
                  <h4>{t("field.repeatBy.title")}</h4>
                  <DaysOfWeekToggleGroup
                    daysOfWeek={daysOfWeek}
                    onToggleDayOfWeek={onToggleDayOfWeek}
                  />
                </div>
              ) : null}

              <div className="flex flex-col gap-3">
                <h4>{t("field.startTime")}</h4>
                <StartTimePopover
                  startTime={startTime}
                  onChangeStartTime={onChangeStartTime}
                />
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>
      </Drawer>

      {/* Settings drawer */}
      {editingItem ? (
        <EditSettingsDrawer
          selectedItemId={+editingItem.id}
          open={openEditDrawer}
          onClose={() => {
            setOpenEditDrawer(false);
          }}
          workspaceId={workspaceId}
          onRefresh={() => {}}
        />
      ) : null}
    </>
  );
};

export default EditScheduleDrawer;
