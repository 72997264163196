// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import { Modal } from "#src/common/system/Modal";
import { Button } from "#src/common/system/Button";

// API-related
import type { IWorkspaceItem } from "#src/services/workspace";

// Children
import ConfirmDeleteWorkspaceModal from "./Confirm";

interface IDeleteWorkspaceModalProps {
  workspaceId: string;
  open: boolean;
  onClose: () => void;
  currentWorkspace: IWorkspaceItem;
}

const DeleteWorkspaceModal = ({
  workspaceId,
  open,
  onClose,
  currentWorkspace,
}: IDeleteWorkspaceModalProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "detail.deleteWorkspace.modal.delete",
  });

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const onProcessToNextStep = () => {
    setOpenConfirmModal(true);
    onClose();
  };

  const displayWorkspaceName = currentWorkspace.short_name
    ? currentWorkspace.short_name
    : currentWorkspace.name;

  return (
    <>
      <Modal open={open} onChangeOpen={onClose}>
        <div className="flex flex-col gap-3">
          <h2 className="text-warning">
            {t("title")} <b>{displayWorkspaceName}</b>?
          </h2>
          <p className="text-hard-grey whitespace-pre-line">
            {t("areYouSureDelete")}{" "}
            <b className="text-dark-blue">{displayWorkspaceName}</b>?{"\n\n"}
            {t("description")}
          </p>
        </div>
        <div className="flex justify-end gap-1">
          <Button variant="secondary" onClick={onClose}>
            {t("button.cancel")}
          </Button>
          <Button onClick={onProcessToNextStep}>{t("button.process")}</Button>
        </div>
      </Modal>
      <ConfirmDeleteWorkspaceModal
        workspaceId={workspaceId}
        open={openConfirmModal}
        onClose={() => {
          setOpenConfirmModal(false);
        }}
        currentWorkspace={currentWorkspace}
      />
    </>
  );
};

export default DeleteWorkspaceModal;
