import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";
import { SolutionCard, type ISolution } from "../SolutionCard";

const productKeys = ["vuln_scan"];
const serviceKeys = [
  "penetration_testing",
  "red_teaming",
  "cloud_security_audit",
  "source_code_audit",
  "blockchain_protocol_audit",
];

export const SolutionVulnscanTab = ({
  solutions,
  role,
}: {
  solutions: ISolution[];
  role: WorkspaceMemberRoleValue | undefined;
}) => {
  const { t } = useTranslation("solutions");

  const products = useMemo(() => {
    const filteredServices = solutions.filter((item) =>
      productKeys.includes(item.key)
    );
    return filteredServices.sort(
      (a, b) => productKeys.indexOf(a.key) - productKeys.indexOf(b.key)
    );
  }, [solutions]);
  const services = useMemo(() => {
    const filteredServices = solutions.filter((item) =>
      serviceKeys.includes(item.key)
    );
    return filteredServices.sort(
      (a, b) => serviceKeys.indexOf(a.key) - serviceKeys.indexOf(b.key)
    );
  }, [solutions]);

  return (
    <section className="space-y-12">
      <div className="space-y-6">
        <h3 className="normal-case font-medium-24">{t("products")}</h3>
        <div className="grid grid-cols-3 gap-6">
          {products.map((item) => (
            <div key={item.title} className="col-span-1">
              <SolutionCard solutionItem={item} role={role} />
            </div>
          ))}
        </div>
      </div>
      <div className="space-y-6">
        <h3 className="normal-case font-medium-24 mb-6">{t("services")}</h3>
        <div className="grid grid-cols-3 gap-6">
          {services.map((item) => (
            <div key={item.title} className="col-span-1">
              <SolutionCard solutionItem={item} role={role} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
