import { TableV2 } from "#src/commonV2/Table";
import { PolicyComplianceValue } from "#src/config/filter/device/value";
import { IPolicyDetailItem } from "#src/services/devices/policies";
import { TAILWIND_COLORS } from "#src/utils/common";
import { Tooltip } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

type Props = {
  hostPolicyList: IPolicyDetailItem[];
  isLoading: boolean;
  hostPolicyCount: number;
  selectedPage: number;
  setSelectedPage: (value: number) => void;
  pageSize: number;
  setPageSize: (value: number) => void;
};

export const PolicyTable = (props: Props) => {
  const {
    hostPolicyList,
    isLoading,
    hostPolicyCount,
    selectedPage,
    setSelectedPage,
    pageSize,
    setPageSize,
  } = props;
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- METHODS ---------------

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  // --------------- RENDER ---------------

  return (
    <TableV2<IPolicyDetailItem>
      options={{
        fitPageHeight: true,
      }}
      data={hostPolicyList}
      getRowKey={(item) => item.id.toString()}
      isLoading={isLoading}
      columns={[
        // Name
        {
          title: t("policies.name"),
          render: (item) => (
            <p className="text-sm font-medium text-gray-v2-900">{item.name}</p>
          ),
          gridTemplateValue: "3fr",
        },

        // Description
        {
          title: t("policies.desc"),
          render: (item) => (
            <Tooltip
              title={
                <p className="text-xs font-semibold text-white">
                  {item.description}
                </p>
              }
              color={TAILWIND_COLORS["gray-v2-900"]}
            >
              <p className="text-sm text-gray-v2-600 font-normal truncate max-w-80 cursor-help">
                {item.description}
              </p>
            </Tooltip>
          ),
          gridTemplateValue: "5fr",
        },

        // Compliance
        {
          title: "Compliance",
          render: (item) => {
            const isPassed = item.response === PolicyComplianceValue.PASSED;
            const isFailed = item.response === PolicyComplianceValue.FAILED;
            if (!isFailed && !isPassed) return null;

            return (
              <div
                className={[
                  "w-full text-center border rounded-xl py-1 px-3",
                  isPassed
                    ? "border-success-v2-200 bg-success-v2-50 text-success-v2-600"
                    : "border-error-v2-200 bg-error-v2-50 text-error-v2-600",
                ].join(" ")}
              >
                <p className="text-sm font-medium">
                  {isPassed
                    ? t("policies.compliance.passed")
                    : t("policies.compliance.failed")}
                </p>
              </div>
            );
          },
          gridTemplateValue: "2fr",
        },
      ]}
      pagination={{
        count: hostPolicyCount,
        selected: selectedPage,
        onChange: onChangePage,
        size: { value: pageSize, onChange: onChangePageSize },
      }}
    />
  );
};
