import type { RouteObject } from "react-router-dom";
import i18next from "i18next";
import { helperPathname, pathname } from "#src/config/pathname";

import { DataLeakIndexPage } from "#src/pages/dataLeak";
import DataLeakOverview from "#src/pages/dataLeak/overview";
import DataLeakFoundLeaks from "#src/pages/dataLeak/foundLeaks";
import DataLeakAddKeyword from "#src/pages/dataLeak/addKeyword";
import DataLeakAddKeywordPayment from "#src/pages/dataLeak/addKeyword/payment";

const breadcrumbTFunc = i18next.getFixedT(null, "layout", "topbar.breadcrumb");

export const dataLeakRoute: RouteObject = {
  path: helperPathname.DATA_LEAK,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("dataLeak.label"),
      path: pathname.DATA_LEAK_OVERVIEW,
    }),
  },
  element: <DataLeakIndexPage />,
  children: [
    {
      path: pathname.DATA_LEAK_OVERVIEW,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("dataLeak.overview.label"),
          path: pathname.DATA_LEAK_OVERVIEW,
        }),
      },
      element: <DataLeakOverview />,
    },
    {
      path: pathname.DATA_LEAK_FOUND_LEAKS,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("dataLeak.foundLeaks.label"),
          path: pathname.DATA_LEAK_FOUND_LEAKS,
        }),
      },
      element: <DataLeakFoundLeaks />,
    },
    {
      path: pathname.DATA_LEAK_ADD_KEYWORD,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("dataLeak.addKeyword.label"),
          path: pathname.DATA_LEAK_ADD_KEYWORD,
        }),
      },
      children: [
        {
          index: true,
          element: <DataLeakAddKeyword />,
        },
        {
          path: pathname.DATA_LEAK_ADD_KEYWORD_PAYMENT,
          handle: {
            crumb: () => ({
              getText: () =>
                breadcrumbTFunc("dataLeak.addKeyword.payment.label"),
              path: pathname.DATA_LEAK_ADD_KEYWORD_PAYMENT,
            }),
          },
          element: <DataLeakAddKeywordPayment />,
        },
      ],
    },
  ],
};
