// Libraries
import { useEffect, useMemo } from "react";
import {
  generatePath,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Cookies from "js-cookie";
import { Switch } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as RefreshLine } from "#src/assets/images/icons/refresh-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
// import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
// import usFlag from "#src/assets/images/flags/us.png";

// General
import { pathname } from "#src/config/pathname";
import global from "#src/config/global";
import urls from "#src/config/urls";
import constants from "#src/config/constants";

// Components
import useFetch from "#src/hooks/useFetch";
import { ContentSection, StickySection } from "#src/layouts/content";
import LoadingState from "#src/common/system/LoadingState";
import { Button } from "#src/common/system/Button";
// import { Flag } from "#src/components/common/Flag";
import { ObjectImage } from "#src/common/system/Object";
import { generateKeyString } from "#src/utils/common";

// Children
import msspBugBountyServices from "#src/services/mssp/bugBounty";

const MsspBugBountyDetailsIntegrationSettings = () => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.integrationSettings",
  });

  const {
    workspaceId,
    bugbounty: bugBountyAlias,
    integration: integrationId,
  } = useParams<"workspaceId" | "bugbounty" | "integration">();
  const [searchParams] = useSearchParams();

  const randomWithCrypto = generateKeyString();

  const selectedIntegrationLink = (): string => {
    const currentLocation =
      location.protocol +
      "//" +
      location.host +
      generatePath(pathname.MSSP_BUG_BOUNTY_DETAILS_INTEGRATION_SETTINGS, {
        workspaceId,
        bugbounty: bugBountyAlias,
        integration: integrationId,
      });

    const authLinkByIntegration: { [I: string]: string } = {
      slack: `${urls.SLACK_OAUTH}?scope=incoming-webhook&client_id=${constants.SLACK_CLIENT_ID}&state=${randomWithCrypto}&redirect_uri=${currentLocation}`,
      trello: ``,
    };

    return integrationId &&
      Object.keys(authLinkByIntegration).includes(integrationId)
      ? authLinkByIntegration[integrationId]
      : "";
  };

  const onToggleEnable = (value: boolean) => {
    if (workspaceId && bugBountyAlias && integrationId && integrationDetails) {
      msspBugBountyServices
        .update_mssp_program_integration(
          workspaceId,
          bugBountyAlias,
          integrationId,
          {
            ...integrationDetails,
            turn_on: value,
          }
        )
        .then((response) => {
          if (response.success) {
            updateIntegration();
          }
        });
    }
  };

  const onToggleConfig = (key: string, value: boolean) => {
    if (workspaceId && bugBountyAlias && integrationId && integrationDetails) {
      msspBugBountyServices
        .update_mssp_program_integration(
          workspaceId,
          bugBountyAlias,
          integrationId,
          {
            ...integrationDetails,
            config: { ...integrationDetails.config, [key]: value },
          }
        )
        .then((response) => {
          if (response.success) {
            updateIntegration();
          }
        });
    }
  };

  const onDeleteIntegration = () => {
    console.log("lmao we don't have delete api yet");
  };

  useEffect(() => {
    const code =
      searchParams.get("code") === "" ||
      searchParams.get("code") === "null" ||
      searchParams.get("code") === null
        ? null
        : (searchParams.get("code") as string);
    const state =
      searchParams.get("state") === "" ||
      searchParams.get("state") === "null" ||
      searchParams.get("state") === null
        ? null
        : (searchParams.get("state") as string);
    const checksumState = Cookies.get("integration-state");

    if (
      code !== null &&
      state !== null &&
      workspaceId &&
      bugBountyAlias &&
      integrationId &&
      state === checksumState
    ) {
      const currentLocation =
        location.protocol +
        "//" +
        location.host +
        generatePath(pathname.MSSP_BUG_BOUNTY_DETAILS_INTEGRATION_SETTINGS, {
          workspaceId,
          bugbounty: bugBountyAlias,
          integration: integrationId,
        });

      msspBugBountyServices.create_mssp_program_integration(
        workspaceId,
        bugBountyAlias,
        integrationId,
        {
          code: code,
          state: state,
          redirect_uri: currentLocation,
        }
      );
    }
  }, [searchParams, workspaceId, bugBountyAlias, integrationId]);

  const fetchParams = useMemo<
    Parameters<
      typeof msspBugBountyServices.retrieve_mssp_program_integration_details
    >
  >(
    () => [workspaceId ?? "", bugBountyAlias ?? "", integrationId ?? ""],
    [workspaceId, bugBountyAlias, integrationId]
  );

  const { result: integrationDetails, updateData: updateIntegration } =
    useFetch(
      msspBugBountyServices.retrieve_mssp_program_integration_details,
      fetchParams
    );

  return (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
      </StickySection>
      <ContentSection className="flex-row">
        {integrationDetails === null ? (
          <LoadingState />
        ) : (
          <>
            <div className="w-2/3 flex flex-col gap-12">
              <div className="p-6 flex justify-between items-center bg-bright-grey border border-light-grey rounded-md">
                <div className="flex gap-6 items-center">
                  <div className="h-[4.5rem] w-[4.5rem] min-h-[4.5rem] min-w-[4.5rem] p-1 flex justify-center items-center bg-white border border-light-grey rounded-md">
                    <ObjectImage
                      data={integrationDetails.integration.logo}
                      className="h-10 w-10"
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <span className="font-medium-20">
                      {integrationDetails.integration.name}
                    </span>
                    <span>{integrationDetails.integration.description.en}</span>
                  </div>
                </div>
                <div className="flex items-center gap-6">
                  <span className="font-medium-18">
                    {t("integrationStatus")}
                  </span>
                  <Switch
                    checked={integrationDetails.turn_on}
                    onClick={() => {
                      onToggleEnable(!integrationDetails.turn_on);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-6">
                <h2>
                  {t("notifyWhen", {
                    integration: integrationDetails.integration.name,
                  })}
                </h2>
                <div className="border border-light-grey rounded-md">
                  {Object.entries(integrationDetails.config).map(
                    ([configKey, configValue]) => (
                      <div
                        key={`bugbounty-integrationSettings-config-${configKey}`}
                        className={`p-6 flex justify-between border-b border-light-grey last:border-none font-medium-16 ${
                          configValue ? "" : "text-medium-grey"
                        }`}
                      >
                        {configKey.replaceAll("_", " ")}
                        <Switch
                          checked={configValue}
                          onClick={() => {
                            onToggleConfig(configKey, !configValue);
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/3 flex flex-col gap-6">
              {/* <div className="p-6 flex flex-col gap-4 border border-grey rounded-md">
                <span className="font-medium-18">{t("language")}</span>
                <button className="p-3 flex justify-between items-center bg-bright-grey rounded-md">
                  <div className="flex items-center gap-2">
                    <Flag pngProp={gbFlag} />
                    English
                  </div>
                  <ArrowDownSLine
                    className="fill-hard-grey"
                    height={"1rem"}
                    width={"1rem"}
                  />
                </button>
              </div> */}
              <div className="p-6 flex flex-col gap-6 border border-grey rounded-md">
                <h2 className="font-medium-18">
                  {t("reconfigureAuthorization.title")}
                </h2>
                <span>{t("reconfigureAuthorization.instruction")}</span>
                <Link
                  to={selectedIntegrationLink()}
                  onClick={() => {
                    console.log(selectedIntegrationLink());

                    Cookies.set("integration-state", randomWithCrypto);
                  }}
                >
                  <Button variant="secondary" size="large" className="w-full">
                    <RefreshLine height={"1.25rem"} width={"1.25rem"} />
                    {t("button.reconfigure")}
                  </Button>
                </Link>
              </div>
              <div className="p-6 flex flex-col gap-6 border border-grey rounded-md">
                <h2 className="font-medium-18">
                  {t("deleteIntegration.title")}
                </h2>
                <span>{t("deleteIntegration.instruction")}</span>
                <Button
                  variant="warning"
                  size="large"
                  onClick={() => {
                    global.confirm(onDeleteIntegration);
                  }}
                >
                  <DeleteBin6Line height={"1.25rem"} width={"1.25rem"} />
                  {t("button.deleteIntegration")}
                </Button>
              </div>
            </div>
          </>
        )}
      </ContentSection>
    </>
  );
};

export default MsspBugBountyDetailsIntegrationSettings;
