// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as PaletteLine } from "#src/assets/images/icons/palette-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";

// Component
import { ObjectImage } from "#src/common/system/Object";
import { FileUploadActionValue, uploadFile } from "#src/utils/uploadFile";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

interface IAddBugBountyColorAndLogo {
  programAlias: string;
  programName: string;
  description: string;
  color: string;
  avatarUrl: string | null;
  onChangeColor: (value: string) => void;
  onChangeAvatarUrl: (value: string) => void;
  disabled?: boolean;
}

const AddBugBountyColorAndLogo = ({
  programAlias,
  programName,
  description,
  color,
  avatarUrl,
  onChangeColor,
  onChangeAvatarUrl,
  disabled = false,
}: IAddBugBountyColorAndLogo) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.addBugBounty.colorAndLogo",
  });

  const onUploadAttachments = (files: File[]) => {
    for (const file of files) {
      const fileType = file.type.split("/")[0];
      if (fileType === "image") {
        uploadFile(file, FileUploadActionValue.PROGRAM_LOGO, {
          alias: programAlias,
        })
          .then((signedResponse) => {
            onChangeAvatarUrl(signedResponse.signed_url);
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("notification.uploadFile.fail"),
            });
          });
      }
    }
  };

  return (
    <div className="flex flex-col items-center relative">
      <div
        className="w-full h-28 rounded-md"
        style={{ backgroundColor: color }}
      ></div>
      {disabled ? null : (
        <label className="w-9 h-9 cursor-pointer absolute right-3 top-3 bg-[#F6F6FD] text-primary flex items-center justify-center rounded-full">
          <input
            type="color"
            value={color}
            onChange={(e) => {
              onChangeColor(e.currentTarget.value);
            }}
            className="hidden"
          />
          <PaletteLine className="h-5 w-5" />
        </label>
      )}
      <div className="absolute top-8 left-0 right-0 mx-auto p-1 h-fit w-fit">
        <div className="bg-white outline outline-3 outline-white rounded-md flex justify-center items-center">
          <ObjectImage
            data={avatarUrl}
            className="h-28 w-28 min-w-[7rem] rounded-md"
          >
            <div className="h-28 w-28 min-w-[7rem] rounded-md bg-button-disabled" />
          </ObjectImage>
        </div>
        {disabled ? null : (
          <label className="h-9 w-9 bg-[#F6F6FD] rounded-full absolute -bottom-2 -right-2 flex items-center justify-center text-primary cursor-pointer">
            <input
              id="avatar_uploader"
              type="file"
              className="hidden"
              accept="image/*"
              onChange={(e) => {
                if (e.currentTarget.files) {
                  onUploadAttachments(Array.from(e.currentTarget.files));
                }
              }}
            />
            <EditLine className="h-5 w-5" />
          </label>
        )}
      </div>
      <div className="flex flex-col items-center text-center gap-4 pt-12 w-full">
        <span className="font-medium-18 text-primary w-full break-words">
          {programName}
        </span>
        <span className="text-hard-grey w-full break-words">{description}</span>
      </div>
    </div>
  );
};

export default AddBugBountyColorAndLogo;
