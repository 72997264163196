// Libraries
import { Tabs } from "@lockerpm/design";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useParams } from "react-router-dom";

// Resources
import { ReactComponent as LogoCyStack } from "#src/assets/images/logos/logo-cystack.svg";
import { ReactComponent as LogoLocker } from "#src/assets/images/logos/logo-locker.svg";
import { ReactComponent as LogoWhitehub } from "#src/assets/images/logos/logo-whitehub.svg";

// General
import { helperPathname, pathname } from "#src/config/pathname";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";

// API-related
import { type ISolutionFromApi, list_solutions } from "#src/services/solutions";

// Children
import { SolutionAllTab } from "#src/components/solutions/tabs/All";
import { SolutionVulnscanTab } from "#src/components/solutions/tabs/Vulnscan";
import { SolutionSecurityOperationTab } from "#src/components/solutions/tabs/SecurityOperation";
import { SolutionDataSecurityTab } from "#src/components/solutions/tabs/DataSecurity";
import { SolutionSecurityComplianceTab } from "#src/components/solutions/tabs/SecurityCompliance";
import {
  ESolutionKeys,
  type ISolution,
} from "#src/components/solutions/SolutionCard";
import { SolutionLink } from "#src/components/solutions/const";

enum SolutionTabKey {
  ALL = "all",
  VULNSCAN = "vulnscan",
  SECURITY_OPERATION = "security_operation",
  DATA_SECURITY = "data_security",
  SECURITY_COMPLIANCE = "security_compliance",
}

const AllSolutionsIndexPage = () => {
  const { t } = useTranslation("solutions");

  const { workspaceId } = useParams<"workspaceId">();

  const [activeTab, setActiveTab] = useState<string>(SolutionTabKey.ALL);
  const [solutions, setSolutions] = useState<ISolutionFromApi[]>([]);
  const onChangeTab = (key: string) => {
    setActiveTab(key);
  };

  const pageTitle = useMemo(() => {
    switch (activeTab) {
      case SolutionTabKey.ALL:
        return t("pageTitle.all");
      case SolutionTabKey.VULNSCAN:
        return t("pageTitle.vulnscan");
      case SolutionTabKey.SECURITY_OPERATION:
        return t("pageTitle.securityOperation");
      case SolutionTabKey.DATA_SECURITY:
        return t("pageTitle.dataSecurity");
      default:
        return t("pageTitle.securityCompliance");
    }
  }, [t, activeTab]);

  const tabList = useMemo(() => {
    return [
      {
        key: SolutionTabKey.ALL,
        label: <div className="font-medium-16 py-3 px-8">{t("tabs.all")}</div>,
      },
      {
        key: SolutionTabKey.VULNSCAN,
        label: (
          <div className="font-medium-16 py-3 px-8">{t("tabs.vulnscan")}</div>
        ),
      },
      {
        key: SolutionTabKey.SECURITY_OPERATION,
        label: (
          <div className="font-medium-16 py-3 px-8">
            {t("tabs.securityOperation")}
          </div>
        ),
      },
      {
        key: SolutionTabKey.DATA_SECURITY,
        label: (
          <div className="font-medium-16 py-3 px-8">
            {t("tabs.dataSecurity")}
          </div>
        ),
      },
      {
        key: SolutionTabKey.SECURITY_COMPLIANCE,
        label: (
          <div className="font-medium-16 py-3 px-8">
            {t("tabs.securityCompliance")}
          </div>
        ),
      },
    ];
  }, [t]);

  useEffect(() => {
    const fetchSolution = async () => {
      if (!workspaceId) return;
      const response = await list_solutions(workspaceId);
      setSolutions(response);
    };
    fetchSolution();
  }, [workspaceId]);

  const defaultSolutions: Omit<ISolution, "enabled">[] = useMemo(
    () =>
      [
        {
          key: ESolutionKeys.SECRETS_MANAGER,
          logo: <LogoLocker className="h-12 w-12 min-w-[3rem]" />,
          title: t("solution.secrets_manager.title"),
          description: t("solution.secrets_manager.description"),
          url: SolutionLink.LOCKER_SECRET_MANAGER,
          publicUrl: SolutionLink.LOCKER_SECRET_ADS,
        },
        {
          key: ESolutionKeys.PASSWORD_MANAGER,
          logo: <LogoLocker className="h-12 w-12 min-w-[3rem]" />,
          title: t("solution.password_manager.title"),
          description: t("solution.password_manager.description"),
          url: SolutionLink.LOCKER_PASSWORD_MANAGER,
        },
        {
          key: ESolutionKeys.ENDPOINT,
          logo: <LogoCyStack />,
          title: t("solution.endpoint.title"),
          description: t("solution.endpoint.description"),
          url: SolutionLink.ENDPOINT,
          comingSoon: true,
        },
        {
          key: ESolutionKeys.DATA_LEAK,
          logo: <LogoCyStack />,
          title: t("solution.data_leak.title"),
          description: t("solution.data_leak.description"),
          url: workspaceId
            ? generatePath(helperPathname.DATA_LEAK, { workspaceId })
            : pathname.HOME,
          publicUrl: SolutionLink.DATA_LEAK_ADS,
          isExternal: false,
        },
        {
          key: ESolutionKeys.VULN_SCAN,
          logo: <LogoCyStack />,
          title: t("solution.vuln_scan.title"),
          description: t("solution.vuln_scan.description"),
          url: SolutionLink.VULNSCAN,
          comingSoon: true,
        },
        {
          key: ESolutionKeys.TRUST_CENTER,
          logo: <LogoCyStack />,
          title: t("solution.trust_center.title"),
          description: t("solution.trust_center.description"),
          url: workspaceId
            ? generatePath(helperPathname.TRUST_CENTER, {
                workspaceId,
              })
            : pathname.HOME,
          publicUrl: SolutionLink.TRUST_CENTER_ADS,
          isExternal: false,
          isCreate: true,
        },
        {
          key: ESolutionKeys.PENETRATION_TESTING,
          logo: <LogoCyStack />,
          title: t("solution.penetration_testing.title"),
          description: t("solution.penetration_testing.description"),
          url: SolutionLink.PENETRATION_TESTING,
          isCreate: true,
        },
        {
          key: ESolutionKeys.RED_TEAMING,
          logo: <LogoCyStack />,
          title: t("solution.red_teaming.title"),
          description: t("solution.red_teaming.description"),
          url: SolutionLink.RED_TEAM,
          isCreate: true,
        },
        {
          key: ESolutionKeys.CLOUD_SECURITY_AUDIT,
          logo: <LogoCyStack />,
          title: t("solution.cloud_security_audit.title"),
          description: t("solution.cloud_security_audit.description"),
          url: SolutionLink.INFRASTRUCTURE_AUDIT,
          isCreate: true,
        },
        {
          key: ESolutionKeys.SOURCE_CODE_AUDIT,
          logo: <LogoCyStack />,
          title: t("solution.source_code_audit.title"),
          description: t("solution.source_code_audit.description"),
          url: SolutionLink.SOURCE_CODE_AUDIT,
          isCreate: true,
        },
        {
          key: ESolutionKeys.BLOCKCHAIN_PROTOCOL_AUDIT,
          logo: <LogoCyStack />,
          title: t("solution.blockchain_protocol_audit.title"),
          description: t("solution.blockchain_protocol_audit.description"),
          url: SolutionLink.BLOCKCHAIN_AUDIT,
          isCreate: true,
        },
        {
          key: ESolutionKeys.WHITEHUB_BUG_BOUNTY,
          logo: <LogoWhitehub />,
          title: t("solution.whitehub_bug_bounty.title"),
          description: t("solution.whitehub_bug_bounty.description"),
          url: SolutionLink.WHITEHUB_BUG_BOUNTY,
          isCreate: true,
        },
        {
          key: ESolutionKeys.SECURITY_COMPLIANCE,
          logo: <LogoCyStack />,
          title: t("solution.security_compliance.title"),
          description: t("solution.security_compliance.description"),
          url: SolutionLink.COMPLIANCE_AUDIT_SERVICE,
          isCreate: true,
        },
        {
          key: ESolutionKeys.SECURITY_OPERATIONS_CENTER,
          logo: <LogoCyStack />,
          title: t("solution.security_operations_center.title"),
          description: t("solution.security_operations_center.description"),
          url: SolutionLink.SOC,
          isCreate: true,
        },
        {
          key: ESolutionKeys.VULNERABILITY_MANAGEMENT,
          logo: <LogoCyStack />,
          title: t("solution.vulnerability_management.title"),
          description: t("solution.vulnerability_management.description"),
          url: SolutionLink.VULNERABILITY_MANAGEMENT,
          isCreate: true,
        },
      ] satisfies Omit<ISolution, "enabled">[],
    [t, workspaceId]
  );

  const finalSolutions: ISolution[] = useMemo(() => {
    return defaultSolutions.map((item) => {
      const solution = solutions.find((s) => s.solution.id === item.key);
      return {
        ...item,
        enabled: solution?.enabled || false,
      };
    });
  }, [defaultSolutions, solutions]);

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  return (
    <>
      <StickySection>
        <div className="flex flex-col gap-5">
          <h1>{pageTitle}</h1>
          <Tabs
            items={tabList}
            activeKey={activeTab}
            onChange={onChangeTab}
            destroyInactiveTabPane
          />
        </div>
      </StickySection>
      <ContentSection>
        {activeTab === SolutionTabKey.ALL ? (
          <SolutionAllTab
            solutions={finalSolutions}
            role={currentWorkspace?.role}
          />
        ) : null}
        {activeTab === SolutionTabKey.VULNSCAN ? (
          <SolutionVulnscanTab
            solutions={finalSolutions}
            role={currentWorkspace?.role}
          />
        ) : null}
        {activeTab === SolutionTabKey.SECURITY_OPERATION ? (
          <SolutionSecurityOperationTab
            solutions={finalSolutions}
            role={currentWorkspace?.role}
          />
        ) : null}
        {activeTab === SolutionTabKey.DATA_SECURITY ? (
          <SolutionDataSecurityTab
            solutions={finalSolutions}
            role={currentWorkspace?.role}
          />
        ) : null}
        {activeTab === SolutionTabKey.SECURITY_COMPLIANCE ? (
          <SolutionSecurityComplianceTab
            solutions={finalSolutions}
            role={currentWorkspace?.role}
          />
        ) : null}
      </ContentSection>
    </>
  );
};

export default AllSolutionsIndexPage;
