import { useTranslation } from "react-i18next";
import { BreadcrumbV2 } from "#src/commonV2/Breadcrumb";
import { generatePath, useParams } from "react-router";
import { pathname } from "#src/config/pathname";
import { TableV2 } from "#src/commonV2/Table";
import { EndpointOverviewStatsAndGraphs } from "#src/components/endpoint/overview/StatsAndGraphs";
import { EndpointOverviewTableWrapper } from "#src/components/endpoint/overview/wrappers/TableWrapper";
import { ButtonV2 } from "#src/commonV2/Button";
import { createSearchParams, Link } from "react-router-dom";
import { useMemo } from "react";
import { endpointService } from "#src/services/endpoint";
import useFetch from "#src/hooks/useFetch";
import { IEndpointOverviewResponse } from "#src/services/endpoint/types";
import { getOsIcon } from "#src/components/endpoint/utils";
import { ArrowUpRight } from "@untitled-ui/icons-react";
import { LoadingStateV2 } from "#src/commonV2/LoadingState";
import { EndpointDeviceSearchParamKey } from "./devices";

export const EndpointOverview = () => {
  const { t } = useTranslation("endpoint", { keyPrefix: "overview" });

  const { workspaceId } = useParams<"workspaceId">();

  if (!workspaceId) {
    return null;
  }

  // --------------- DATA ---------------

  // --------------- COMPUTED ---------------

  const fetchParams = useMemo<
    Parameters<typeof endpointService.retrieveHostsOverview>
  >(() => [workspaceId], [workspaceId]);

  // --------------- HOOKS ---------------

  const { result: endpointOverviewData, isLoading } = useFetch(
    endpointService.retrieveHostsOverview,
    fetchParams
  );

  // --------------- METHODS ---------------

  // --------------- EFFECTS ---------------

  // --------------- RENDER ---------------

  return (
    <>
      <div className="flex flex-col pt-5 pb-6 gap-8 overflow-x-hidden overflow-y-auto h-full">
        {/* 1st section (Header + overview + graphs) */}
        <div className="flex flex-col gap-5">
          {/* Header (Breadcrumb + title) */}
          <div className="px-8 flex flex-col gap-4">
            <BreadcrumbV2
              levels={[
                {
                  name: t("breadcrumb.levels.endpoint"),
                  path: generatePath(pathname.ENDPOINT_OVERVIEW, {
                    workspaceId,
                  }),
                },
              ]}
              currentPage={{
                name: t("breadcrumb.current.overview"),
              }}
            />
            <span className="text-display-xs font-semibold text-gray-v2-900">
              {t("title")}
            </span>
          </div>
          {/* Header (Breadcrumb + title) end */}

          {/* Statistics & graphs */}
          {endpointOverviewData === null || isLoading ? null : (
            <EndpointOverviewStatsAndGraphs data={endpointOverviewData.count} />
          )}
          {/* Statistics & graphs end */}
        </div>
        {/* 1st section (Header + overview + graphs) end */}

        {endpointOverviewData === null || isLoading ? (
          <LoadingStateV2 />
        ) : (
          <>
            {/* 2nd section (Top 5 failed) */}
            <div className="grid grid-cols-2 gap-4 px-8">
              <EndpointOverviewTableWrapper
                title={t("tables.policyFailedDevices.title")}
                description={t("tables.policyFailedDevices.description")}
                table={
                  <TableV2<
                    IEndpointOverviewResponse["top"]["failed_policies_host"][number]
                  >
                    data={endpointOverviewData.top.failed_policies_host.slice(
                      0,
                      5
                    )}
                    getRowKey={(item) => item.asset_id.toString()}
                    columns={[
                      {
                        title: null,
                        render: (_, index) =>
                          (index + 1).toString().padStart(2, "0"),
                        overwriteWrapperProps: { className: "justify-center" },
                        gridTemplateValue: "4rem",
                      },
                      {
                        title: t("tables.policyFailedDevices.headers.os"),
                        render: (item) => getOsIcon(item.os),
                        titleAlign: "center",
                        overwriteWrapperProps: { className: "justify-center" },
                        gridTemplateValue: "4rem",
                      },
                      {
                        title: t("tables.policyFailedDevices.headers.device"),
                        render: (item) => item.asset_name,
                        gridTemplateValue: "5fr",
                      },
                      {
                        title: t(
                          "tables.policyFailedDevices.headers.failedPolicies"
                        ),
                        render: (item) =>
                          item.failed_policies + " / " + item.total_policies,
                        gridTemplateValue: "3fr",
                      },
                    ]}
                    options={{
                      emptyState: {
                        title: t("tables.emptyState.title"),
                        supportingText: t("tables.emptyState.supportingText"),
                        type: "featured-icon",
                      },
                    }}
                  />
                }
              />
              <EndpointOverviewTableWrapper
                title={t("tables.failedPolicies.title")}
                description={t("tables.failedPolicies.description")}
                table={
                  <TableV2<
                    IEndpointOverviewResponse["top"]["failed_policies_list"][number]
                  >
                    data={endpointOverviewData.top.failed_policies_list.slice(
                      0,
                      5
                    )}
                    getRowKey={(item) => item.id.toString()}
                    columns={[
                      {
                        title: null,
                        render: (_, index) =>
                          (index + 1).toString().padStart(2, "0"),
                        overwriteWrapperProps: { className: "justify-center" },
                        gridTemplateValue: "4rem",
                      },
                      {
                        title: t("tables.failedPolicies.headers.os"),
                        render: (item) => getOsIcon(item.platform),
                        titleAlign: "center",
                        overwriteWrapperProps: { className: "justify-center" },
                        gridTemplateValue: "4rem",
                      },
                      {
                        title: t("tables.failedPolicies.headers.name"),
                        render: (item) => item.name.en,
                        gridTemplateValue: "7fr",
                      },
                      {
                        title: t("tables.failedPolicies.headers.failedDevices"),
                        render: (item) => item.host_count,
                        gridTemplateValue: "3fr",
                      },
                      {
                        title: null,
                        render: (item) => (
                          <Link
                            to={{
                              pathname: generatePath(
                                pathname.ENDPOINT_DEVICES,
                                { workspaceId }
                              ),
                              search: createSearchParams({
                                [EndpointDeviceSearchParamKey.FAILED_POLICY]:
                                  item.id.toString(),
                              }).toString(),
                            }}
                          >
                            <ButtonV2 variant="tertiary">
                              {t("tables.failedPolicies.button.viewAll")}
                              <ArrowUpRight className="h-5 w-5" />
                            </ButtonV2>
                          </Link>
                        ),
                        overwriteWrapperProps: { className: "p-1.5" },
                        gridTemplateValue: "3fr",
                      },
                    ]}
                    options={{
                      emptyState: {
                        title: t("tables.emptyState.title"),
                        supportingText: t("tables.emptyState.supportingText"),
                        type: "featured-icon",
                      },
                    }}
                  />
                }
              />
            </div>
            {/* 2nd section (Top 5 failed) end */}

            {/* 3rd section (Top 5 vulnerable devices) */}
            <div className="px-8">
              <EndpointOverviewTableWrapper
                title={t("tables.vulnerableDevices.title")}
                description={t("tables.vulnerableDevices.description")}
                table={
                  <TableV2<
                    IEndpointOverviewResponse["top"]["vuln_devices"][number]
                  >
                    data={endpointOverviewData.top.vuln_devices.slice(0, 5)}
                    getRowKey={(item) => item.asset_id.toString()}
                    columns={[
                      {
                        title: null,
                        render: (_, index) =>
                          (index + 1).toString().padStart(2, "0"),
                        overwriteWrapperProps: { className: "justify-center" },
                        gridTemplateValue: "4rem",
                      },
                      {
                        title: t("tables.vulnerableDevices.headers.os"),
                        render: (item) => getOsIcon(item.os),
                        titleAlign: "center",
                        overwriteWrapperProps: { className: "justify-center" },
                        gridTemplateValue: "2fr",
                      },
                      {
                        title: t("tables.vulnerableDevices.headers.device"),
                        render: (item) => item.asset_name,
                        gridTemplateValue: "7fr",
                      },
                      {
                        title: t(
                          "tables.vulnerableDevices.headers.vulnGroupedBySeverity"
                        ),
                        render: (item) => (
                          <>
                            <div className="flex items-center justify-center h-7 min-w-[1.75rem] px-1 rounded-md text-sm font-semibold border border-rose-v2-400 bg-rose-v2-100 text-rose-v2-700">
                              {item.severity["CRITICAL"] ?? 0}
                            </div>
                            <div className="flex items-center justify-center h-7 min-w-[1.75rem] px-1 rounded-md text-sm font-semibold border border-error-v2-400 bg-error-v2-100 text-error-v2-700">
                              {item.severity["HIGH"] ?? 0}
                            </div>
                            <div className="flex items-center justify-center h-7 min-w-[1.75rem] px-1 rounded-md text-sm font-semibold border border-orange-v2-400 bg-orange-v2-100 text-orange-v2-700">
                              {item.severity["MEDIUM"] ?? 0}
                            </div>
                            <div className="flex items-center justify-center h-7 min-w-[1.75rem] px-1 rounded-md text-sm font-semibold border border-yellow-v2-400 bg-yellow-v2-100 text-yellow-v2-700">
                              {item.severity["LOW"] ?? 0}
                            </div>
                          </>
                        ),
                        gridTemplateValue: "6fr",
                      },
                    ]}
                    options={{
                      emptyState: {
                        title: t("tables.emptyState.title"),
                        supportingText: t("tables.emptyState.supportingText"),
                        type: "featured-icon",
                      },
                    }}
                  />
                }
              />
            </div>
            {/* 3rd section (Top 5 vulnerable devices) end */}

            {/* 4th section (Top 5 vulnerable softwares) */}
            <div className="px-8">
              <EndpointOverviewTableWrapper
                title={t("tables.softwareWithVuln.title")}
                description={t("tables.softwareWithVuln.description")}
                table={
                  <TableV2<
                    IEndpointOverviewResponse["top"]["vuln_programs"][number]
                  >
                    data={endpointOverviewData.top.vuln_programs.slice(0, 5)}
                    getRowKey={(item) => item.id.toString()}
                    columns={[
                      {
                        title: null,
                        render: (_, index) =>
                          (index + 1).toString().padStart(2, "0"),
                        overwriteWrapperProps: { className: "justify-center" },
                        gridTemplateValue: "4rem",
                      },
                      {
                        title: t("tables.softwareWithVuln.headers.software"),
                        render: (item) => item.name,
                        gridTemplateValue: "5fr",
                      },
                      {
                        title: t("tables.softwareWithVuln.headers.version"),
                        render: (item) => item.version,
                        gridTemplateValue: "2fr",
                      },
                      {
                        title: t(
                          "tables.softwareWithVuln.headers.vulnGroupedBySeverity"
                        ),
                        render: (item) => (
                          <>
                            <div className="flex items-center justify-center h-7 min-w-[1.75rem] px-1 rounded-md text-sm font-semibold border border-rose-v2-400 bg-rose-v2-100 text-rose-v2-700">
                              {item.severity["CRITICAL"] ?? 0}
                            </div>
                            <div className="flex items-center justify-center h-7 min-w-[1.75rem] px-1 rounded-md text-sm font-semibold border border-error-v2-400 bg-error-v2-100 text-error-v2-700">
                              {item.severity["HIGH"] ?? 0}
                            </div>
                            <div className="flex items-center justify-center h-7 min-w-[1.75rem] px-1 rounded-md text-sm font-semibold border border-orange-v2-400 bg-orange-v2-100 text-orange-v2-700">
                              {item.severity["MEDIUM"] ?? 0}
                            </div>
                            <div className="flex items-center justify-center h-7 min-w-[1.75rem] px-1 rounded-md text-sm font-semibold border border-yellow-v2-400 bg-yellow-v2-100 text-yellow-v2-700">
                              {item.severity["LOW"] ?? 0}
                            </div>
                          </>
                        ),
                        gridTemplateValue: "4fr",
                      },
                      {
                        title: t(
                          "tables.softwareWithVuln.headers.devicesInstalled"
                        ),
                        render: (item) => item.host_count,
                        gridTemplateValue: "2fr",
                      },
                      {
                        title: null,
                        render: (item) => (
                          <Link
                            to={{
                              pathname: generatePath(
                                pathname.ENDPOINT_DEVICES,
                                { workspaceId }
                              ),
                              search: createSearchParams({
                                [EndpointDeviceSearchParamKey.SOFTWARE]:
                                  item.id.toString(),
                              }).toString(),
                            }}
                          >
                            <ButtonV2 variant="tertiary">
                              {t("tables.failedPolicies.button.viewAll")}
                              <ArrowUpRight className="h-5 w-5" />
                            </ButtonV2>
                          </Link>
                        ),
                        overwriteWrapperProps: { className: "p-1.5" },
                        gridTemplateValue: "2fr",
                      },
                    ]}
                    options={{
                      emptyState: {
                        title: t("tables.emptyState.title"),
                        supportingText: t("tables.emptyState.supportingText"),
                        type: "featured-icon",
                      },
                    }}
                  />
                }
              />
            </div>
            {/* 4th section (Top 10 vulnerable softwares) end */}
          </>
        )}
      </div>
    </>
  );
};
