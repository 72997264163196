// Libraries
import { type ChangeEventHandler, type FocusEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

// Resources
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";

// Components
import { Input, TextArea } from "#src/common/system/Input";
import { MarkdownEditor } from "#src/common/system/Input/MarkdownEditor";

/** We want form error return a function so that we can trigger translate text. */
export type FormErrorItem = (() => string) | undefined;

type ValidationProps<T> = { value: T; errorMessage?: FormErrorItem } | T;

interface IInputFieldProps {
  title: string;
  type?: "input" | "textarea" | "markdown";
  disabled?: boolean;
  value: string;
  onChangeValue: (value: string) => void;
  placeholder?: string;
  error?: FormErrorItem;
  onChangeError?: (value: FormErrorItem) => void;
  validation?: {
    required?: ValidationProps<boolean>;
    pattern?: ValidationProps<RegExp>;
    maxLength?: ValidationProps<number>;
  };
  className?: string;
  // For TextArea fields
  rows?: number;
  // For markdown fields.
  parser?: (value: string) => string | Node;
}

export const InputField = ({
  title,
  type = "input",
  disabled = false,
  value,
  onChangeValue,
  placeholder,
  error,
  onChangeError,
  validation,
  className,
  rows = 3,
  parser,
}: IInputFieldProps) => {
  const { t } = useTranslation("common", { keyPrefix: "input" });

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e
  ) => {
    onChangeValue(e.target.value);
    if (error && onChangeError) {
      onChangeError(undefined);
    }
  };

  const onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e
  ) => {
    if (
      typeof onChangeError === "undefined" ||
      typeof validation === "undefined"
    )
      return;

    // If input is required:
    if (typeof validation.required === "boolean") {
      if (e.target.value.length === 0) {
        onChangeError(() => t("error.required"));
        return;
      }
    } else {
      if (
        typeof validation.required !== "undefined" &&
        validation.required.value &&
        e.target.value.length === 0
      ) {
        if (typeof validation.required.errorMessage === "string") {
          onChangeError(validation.required.errorMessage);
        } else {
          onChangeError(() => t("error.required"));
        }
        return;
      }
    }

    // If input should match a pattern
    if (validation.pattern instanceof RegExp) {
      if (!validation.pattern.test(e.target.value)) {
        onChangeError(() => t("error.invalid"));
        return;
      }
    } else {
      if (
        typeof validation.pattern !== "undefined" &&
        validation.pattern.value &&
        !validation.pattern.value.test(e.target.value)
      ) {
        if (typeof validation.pattern.errorMessage === "string") {
          onChangeError(validation.pattern?.errorMessage);
        } else {
          onChangeError(() => t("error.invalid"));
        }
        return;
      }
    }

    // If input have a max length determined
    if (typeof validation.maxLength === "number") {
      if (e.target.value.length > validation.maxLength) {
        onChangeError(() => t("error.maxLength"));
        return;
      }
    } else {
      if (
        typeof validation.maxLength !== "undefined" &&
        validation.maxLength.value &&
        e.target.value.length > validation.maxLength.value
      ) {
        if (typeof validation.maxLength.errorMessage === "string") {
          onChangeError(validation.maxLength?.errorMessage);
        } else {
          onChangeError(() => t("error.maxLength"));
        }
        return;
      }
    }
  };

  // Not all error state should be shown. For example: when input is disabled, we should hide errors.
  const displayError: boolean = Boolean(error && !disabled);

  const isRequired =
    validation &&
    (typeof validation.required === "boolean"
      ? validation.required === true
      : validation.required !== undefined &&
        validation.required.value === true);

  return (
    <div className={twMerge("flex flex-col gap-3", className)}>
      <h4>
        {isRequired ? <span className="text-primary pr-0.5">*</span> : null}
        {title}
      </h4>
      {type === "textarea" ? (
        <TextArea
          variant={displayError ? "warning" : "primary"}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          rows={rows}
        />
      ) : type === "markdown" ? (
        <MarkdownEditor
          variant={displayError ? "warning" : "primary"}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          rows={rows}
          noBox
          parser={parser}
        />
      ) : (
        <Input
          variant={displayError ? "warning" : "primary"}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      {displayError ? (
        <span className="font-medium-14-forced text-warning flex items-center gap-1">
          <InformationLine className="h-5 w-5" />
          {error ? error() : null}
        </span>
      ) : null}
    </div>
  );
};
