import { TableV2 } from "#src/commonV2/Table";
import { IHostSoftwareItem } from "#src/services/devices/softwares";
import { TAILWIND_COLORS, toPascalCase } from "#src/utils/common";
import { Tooltip } from "@lockerpm/design";
import { ArrowUpRight, Eye } from "@untitled-ui/icons-react";
import { pathname } from "#src/config/pathname";
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from "react-router-dom";
import { ReactNode } from "react";

import { ReactComponent as LogoWindows10 } from "#src/assets/images/logos/logo-windows-10.svg";
import { ReactComponent as LogoApple } from "#src/assets/images/logos/logo-apple.svg";
import { ReactComponent as LogoUbuntu } from "#src/assets/images/logos/logo-ubuntu.svg";
import { useTranslation } from "react-i18next";

type Props = {
  hostOsFamily: string;
  hostSoftwareList: IHostSoftwareItem[];
  isLoading: boolean;
  hostSoftwareCount: number;
  sortOrder: string;
  setSortOrder: (value: string) => void;
  selectedPage: number;
  setSelectedPage: (value: number) => void;
  pageSize: number;
  setPageSize: (value: number) => void;
  workspaceId: string;
  showVulnModal: (item: IHostSoftwareItem) => void;
};

export const SoftwareTable = (props: Props) => {
  const {
    hostOsFamily,
    hostSoftwareList,
    isLoading,
    hostSoftwareCount,
    sortOrder,
    setSortOrder,
    selectedPage,
    setSelectedPage,
    pageSize,
    setPageSize,
    workspaceId,
    showVulnModal,
  } = props;

  const navigate = useNavigate();
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- METHODS ---------------

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const viewAllHosts = (program: { name: string; id: string }) => {
    navigate(
      {
        pathname: generatePath(pathname.ENDPOINT_DEVICES, {
          workspaceId,
        }),
        search: createSearchParams({
          program_id: program.id,
        }).toString(),
      },
      { state: { program_name: program.name } }
    );
  };

  // --------------- RENDER ---------------

  return (
    <TableV2<IHostSoftwareItem>
      options={{
        fitPageHeight: true,
      }}
      data={hostSoftwareList || []}
      getRowKey={(item) => item.program.id.toString()}
      isLoading={isLoading}
      columns={[
        // Name
        {
          title: t("software.name"),
          render: (item) => (
            <div className="flex items-center gap-3">
              {iconByOs[hostOsFamily]}
              <p className="text-sm font-medium text-gray-v2-900">
                {item.program.name}
              </p>
            </div>
          ),
          gridTemplateValue: "5fr",
          sortingKey: { asc: "name_asc", desc: "name_desc" },
        },

        // Version
        {
          title: t("software.version"),
          render: (item) => (
            <p className="text-sm text-gray-v2-600 font-normal">
              {item.program.version}
            </p>
          ),
          gridTemplateValue: "1fr",
        },

        // Type
        {
          title: t("software.type"),
          render: (item) => (
            <p className="text-sm text-gray-v2-600 font-normal">
              {item.program.source ? formatSource(item.program.source) : "---"}
            </p>
          ),
          gridTemplateValue: "2fr",
        },

        // Vulnerabilities
        {
          title: t("software.vulnerabilities"),
          render: (item) => {
            if (!item.program.vulnerabilities.length) {
              return (
                <p className="text-sm text-gray-v2-600 font-normal">---</p>
              );
            }

            const serverityMap = item.program.vulnerabilities.reduce(
              (acc, vuln) => {
                acc[vuln.severity] = (acc[vuln.severity] || 0) + 1;
                return acc;
              },
              {} as Record<string, number>
            );
            const tooltip = Object.keys(serverityMap)
              .map(
                (severity) =>
                  `${[severity[0] + severity.slice(1).toLowerCase()]}: ${serverityMap[severity]}`
              )
              .join("; ");

            return (
              <Tooltip
                title={
                  <p className="text-xs font-semibold text-white text-center">
                    {tooltip}
                  </p>
                }
                className="cursor-pointer"
                color={TAILWIND_COLORS["gray-v2-900"]}
              >
                <div
                  className="flex items-center gap-1"
                  onClick={() => {
                    showVulnModal(item);
                  }}
                >
                  <p className="text-sm text-gray-v2-600 font-normal">
                    {item.program.vulnerabilities.length}
                  </p>

                  <Eye className="text-brand-v2-700 h-4" />
                </div>
              </Tooltip>
            );
          },
          gridTemplateValue: "2fr",
        },

        // Last used
        {
          title: t("software.last_used"),
          render: () => (
            <p className="text-sm text-gray-v2-600 font-normal">---</p>
          ),
          gridTemplateValue: "3fr",
        },

        // File path
        {
          title: t("software.file_path"),
          render: (item) => {
            if (!item.install_location.length) {
              return (
                <p className="text-sm text-gray-v2-600 font-normal">---</p>
              );
            }

            return (
              <Tooltip
                title={
                  <p className="text-xs font-semibold text-white">
                    {item.install_location.join("\n")}
                  </p>
                }
                color={TAILWIND_COLORS["gray-v2-900"]}
              >
                <p className="text-sm text-gray-v2-600 font-normal truncate max-w-80">
                  {item.install_location[0] ?? "---"}
                </p>
              </Tooltip>
            );
          },
          gridTemplateValue: "5fr",
        },

        // Action
        {
          title: "",
          render: (item) => (
            <a
              className="text-sm font-semibold text-brand-v2-700 no-underline w-20"
              onClick={() => {
                viewAllHosts({
                  name: item.program.name,
                  id: item.program.id.toString(),
                });
              }}
            >
              <Tooltip
                title={
                  <p className="text-xs font-semibold text-center">
                    {t("software.view_all_desc")}
                  </p>
                }
                color={TAILWIND_COLORS["gray-v2-900"]}
              >
                <div className="flex items-center gap-1">
                  <span>{t("software.view_all")}</span>
                  <ArrowUpRight className="h-5 w-5" />
                </div>
              </Tooltip>
            </a>
          ),
          gridTemplateValue: "5fr",
        },
      ]}
      sort={{ value: sortOrder, onChange: setSortOrder }}
      pagination={{
        count: hostSoftwareCount,
        selected: selectedPage,
        onChange: onChangePage,
        size: { value: pageSize, onChange: onChangePageSize },
      }}
    />
  );
};

// --------------- SUPPORTERS ---------------

const formatSource = (source: string) => {
  const splitted = source.split("_");

  return (
    splitted
      .slice(0, splitted.length - 1)
      .map((item) => toPascalCase(item))
      .join(" ") +
    " (" +
    splitted[splitted.length - 1] +
    ")"
  );
};

const iconByOs: { [os: string]: ReactNode } = {
  windows: <LogoWindows10 className="h-10 min-w-10" />,
  darwin: <LogoApple className="h-10 min-w-10" />,
  ubuntu: <LogoUbuntu className="h-10 min-w-10" />,
};
