// Libraries
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "#tailwind.config";
import type { ErrorResponse } from "react-router-dom";
import { marked } from "marked";
import { z } from "zod";

// General
import urls from "#src/config/urls";
import constants from "#src/config/constants";

dayjs.extend(relativeTime);

export const isStringAndNotEmpty = (value: unknown) => {
  if (typeof value !== "string") return false;
  if (value.length <= 0) return false;
  return true;
};

// DEPRECATED: currently we don't have to work with CSV
// export const downloadCSV = (rows: any = []) => {
//   let csvContent = "data:text/csv;charset=utf-8,";
//   rows.forEach(function (rowArray: any) {
//     let row = rowArray.join(",");
//     csvContent += row + "\r\n";
//   });
//   const encodedUri = encodeURI(csvContent);
//   window.open(encodedUri);
// };

const config = resolveConfig(tailwindConfig);
const colors: { [key: string]: string } = {};

const pullTwColorsToObject = (colorObj: object, prefix: string) => {
  for (const [key, value] of Object.entries(colorObj)) {
    if (typeof value === "string") {
      colors[prefix + key] = value;
    }
    if (typeof value === "object") {
      pullTwColorsToObject(value, prefix + key + "-");
    }
  }
};

if (config.theme && config.theme.colors) {
  pullTwColorsToObject(config.theme.colors, "");
}

export const TAILWIND_COLORS = colors;

/** Add <mark> html tag into the text to highlight
 * @param text - the original text
 * @param keyword - the keyword that needed to be marked
 * @return a markdown with <mark> tag added
 */
export const getHighlightedTextByKeyword = (text: string, keyword: string) => {
  if (!text) {
    console.log("Error: matches.preview is not valid", text);
    return text;
  }
  return text.replaceAll(keyword, "<mark>" + keyword + "</mark>");
};

/** Add some spans as numbering to markdown that represents a block of code
 * @deprecated recheck whether this is needed. If not, remove this.
 * @param lines - the original markdown
 * @param line_number - the first line of the code block
 */
export const addNumberingToMarkdown = (
  lines: string,
  line_number: number | undefined
) => {
  // Find longest line number, or it's just the biggest number we need to display
  const maxLine = lines.split("\n").length + (line_number ? line_number : 0);
  // Note: lengths are measured with "rem" as unit, for example `oneCharWidth = 0.5` means a character has a width equals to 0.5rem
  // As I can see, this is the with of 1 number character on our UI. Last update: 22/01/2024
  const oneCharWidth = 0.5;
  // Settings that can be changed depends on designs and customer's specs
  const numberingStyles = {
    paddingLeft: 0.75,
    paddingRight: 1.5,
    color: TAILWIND_COLORS["hard-grey"],
  };

  // Calculate the width of numbering
  const numberingWidth =
    oneCharWidth * maxLine.toString().length +
    numberingStyles.paddingLeft +
    numberingStyles.paddingRight;

  const addedSpans = lines
    .split("\n")
    .map(
      (item, index) =>
        "<span style='display: flex; white-space: nowrap'><span style='text-align: right; user-select: none; -webkit-user-select: none; width: " +
        numberingWidth +
        "rem; padding-right: " +
        numberingStyles.paddingRight +
        "rem; color: " +
        numberingStyles.color +
        ";'>" +
        // Calculated line number
        (line_number ? line_number + index : 1 + index) +
        "</span>" +
        // Parse the line with backticks to make sure these texts won't behave unexpextedly, since we can have source_sode as data
        marked.parseInline("`" + item + "`") +
        "</span>"
    )
    .join("\n");

  return addedSpans;
};

export const toPascalCase = (str: string | undefined) => {
  if (!str) return "";
  return str.toString().replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });
};

/** Typeguard for fulfilled Promises */
export const isFulfilled = <T>(
  promise: PromiseSettledResult<T>
): promise is PromiseFulfilledResult<T> => promise.status === "fulfilled";

export const disallowConcurrency = (func: Function) => {
  let inprogressPromise = Promise.resolve();

  return (...arg: unknown[]) => {
    inprogressPromise = inprogressPromise.then(() => func(...arg));

    return inprogressPromise;
  };
};

// The magic number 11644473600 is the number of seconds between
// "1st of Jan 1601" and "1st of Jan 1970"
// ref: https://www.epochconverter.com/webkit
export const convertWebkitTimestampToUnix = (timestamp: number) => {
  return Math.round(timestamp / 1000000) - 11644473600;
};

export type ManuallyThrownData = {
  error: string;
  instruction?: string;
};

/** Check if the thrown error is thrown from front-end with the form of ManuallyThrownData. */
export function isManuallyThrownData(
  data: ErrorResponse["data"]
): data is ManuallyThrownData {
  return (
    data !== null &&
    typeof data.error === "string" &&
    (typeof data.instruction === "string" ||
      typeof data.instruction === "undefined")
  );
}

/** Check if the thrown error is thrown from Zod. */
export function isZodError(error: unknown): error is z.ZodError {
  return error instanceof z.ZodError;
}

export const getLoginUrl = () =>
  `${urls.ID_CYSTACK_URL}/login?${new URLSearchParams({
    SERVICE_URL: window.location.pathname + window.location.search,
    SERVICE_SCOPE: constants.SERVICE_SCOPE,
    ENVIRONMENT: constants.ENVIRONMENT ?? "",
  }).toString()}`;

export const skipToContentTop = () => {
  const contentElement = document.getElementById("layout-content");
  if (contentElement) {
    contentElement.scrollTo({ top: 0, behavior: "instant" });
  } else {
    scrollTo({ top: 0, behavior: "instant" });
  }
};

/** A random generator function, just in case someone has to run the code in insecured contexts. */
export const generateKeyString = () => {
  return window.isSecureContext
    ? crypto.randomUUID().toString()
    : crypto.getRandomValues(new Uint32Array(1))[0].toString();
};

/** A "pre-parser" to parse simple token before putting values into Markdown parser. */
export const simpleTokenParser = (
  raw: string,
  token: Record<string, string>
) => {
  return Object.entries(token).reduce(
    (prev, [key, value]) =>
      prev.replaceAll(`{{ ${key} }}`, value).replaceAll(`{{${key}}}`, value),
    raw
  );
};

/** **NOT A GOOD SOLUTION**. This forces texts to lowercase with only the first letter being uppercased. */
export const forceNormalCase = (original: string) => {
  return original.slice(0, 1).toUpperCase() + original.slice(1).toLowerCase();
};

/** Not clean. Use with caution. */
export const getDomainFromUrl = (url: string) => {
  const protocolTrimmed = url
    .replaceAll("https://", "")
    .replaceAll("http://", "");
  const domain = protocolTrimmed.split("/")[0];
  return domain;
};

export const byteToGigabyte = (numOfByte: number) => {
  return numOfByte / 1024 ** 3;
};
