// Libraries
import type { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

// Components
import { Modal } from "#src/common/system/Modal";
import { expandPlanDuration } from "#src/utils/apiHelper";
import { Button } from "#src/common/system/Button";

// APi-related
import type { IProductPlan } from "#src/services/resource";

// Children
import PlanItem from "../ChoosePlan/PlanItem";
import OldPlanItem from "../ChoosePlan/OldPlanItem";

interface IReselectPlanModalProps {
  open: boolean;
  onChangeOpen: (value: boolean) => void;
  productPlanInfo: IProductPlan | null;
  oldPlanDuration?: string;
  onSubmit: (value: IProductPlan["recurring_prices"][number]) => void;
  tempSelectedPlan: IProductPlan["recurring_prices"][number] | null;
  setTempSelectedPlan: Dispatch<
    SetStateAction<IProductPlan["recurring_prices"][number] | null>
  >;
}

const ReselectPlanModal = ({
  open,
  onChangeOpen,
  productPlanInfo,
  oldPlanDuration,
  onSubmit,
  tempSelectedPlan,
  setTempSelectedPlan,
}: IReselectPlanModalProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.fillForm.yourCart.editPlanModal",
  });

  const planList = productPlanInfo
    ? productPlanInfo.recurring_prices
        .map(expandPlanDuration)
        .filter(<T,>(p: T): p is Exclude<T, undefined> => p !== undefined)
    : null;

  return (
    <Modal open={open} onChangeOpen={onChangeOpen} className="w-[50rem]">
      <div className="flex flex-col gap-3">
        <h2>{t("title")}</h2>
        <span className="text-hard-grey font-regular-16-body">
          {t("description")}
        </span>
      </div>
      {planList ? (
        <div className="grid grid-cols-2 gap-6">
          {planList.map((plan) =>
            oldPlanDuration === plan.duration ? (
              <OldPlanItem
                key={`productPlan-choosePlan-${plan.duration}`}
                getName={plan.getTitle}
                price={plan.monthly_vnd}
              />
            ) : (
              <PlanItem
                key={`trustCenter-registerDomain-${plan.duration}`}
                getName={plan.getTitle}
                price={plan.monthly_vnd}
                getTag={plan.getDecorator}
                selected={tempSelectedPlan?.duration === plan.duration}
                onClickItem={() => {
                  setTempSelectedPlan(plan);
                }}
              />
            )
          )}
        </div>
      ) : null}
      <div className="flex gap-1 justify-end">
        <Button
          variant="secondary"
          onClick={() => {
            onChangeOpen(false);
          }}
        >
          {t("button.cancel")}
        </Button>
        <Button
          onClick={() => {
            if (tempSelectedPlan) {
              onSubmit(tempSelectedPlan);
              onChangeOpen(false);
            }
          }}
        >
          {t("button.confirm")}
        </Button>
      </div>
    </Modal>
  );
};

export default ReselectPlanModal;
