// Libraries
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";
import { createSearchParams, useSearchParams } from "react-router-dom";

// Components
import type { ITableHeaderPropItem } from "#src/common/Table/TableHeader";
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import { TableDataCell, TableHeader, TableRowWrapper } from "#src/common/Table";

// API-related
import type { IScannedDomainItem } from "#src/services/scans/scannedDomains";

// Children
import { LinearGaugeChart } from "../../../all-scans/ScanTable/LinearGaugeChart";
import { ScanDetailsTabEnum } from "../../enums";

dayjs.extend(relativeTime);

/* @param data - data of the row, represent a Scanned Asset from selected domain */
const ScannedAssetsTableRow = ({
  data,
  onChangeTab,
}: {
  data: IScannedDomainItem;
  onChangeTab: (key: string) => void;
}) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <TableRowWrapper
      onClick={() => {
        onChangeTab(ScanDetailsTabEnum.VULNERABILITIES);
      }}
    >
      <TableDataCell>{data.asset.address}</TableDataCell>
      <TableDataCell>
        <LinearGaugeChart
          id={data.asset.id}
          vulnBySeverity={data.vulnerabilities.by_severity}
          onClickSeries={(severity: string) => {
            onChangeTab(ScanDetailsTabEnum.VULNERABILITIES);
            setSearchParams(
              createSearchParams({
                tab: ScanDetailsTabEnum.VULNERABILITIES,
                severity,
              }).toString()
            );
          }}
        />
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface IScannedAssetsTableProps {
  scannedAssetList: IScannedDomainItem[] | null;
  isLoading: boolean;
  onChangeTab: (key: string) => void;
}

const ScannedAssetsTableGrid = ({
  scannedAssetList,
  isLoading,
  onChangeTab,
}: IScannedAssetsTableProps) => {
  const { t } = useTranslation("scans", {
    keyPrefix: "page.scanDetails.tab.scannedAssets",
  });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.name"), align: "left" },
    { name: t("headers.vulnerabilities"), align: "left" },
  ];

  return scannedAssetList === null || isLoading ? (
    <LoadingState />
  ) : scannedAssetList.length === 0 ? (
    <NoResultsState />
  ) : (
    <div className="grid grid-cols-[1fr_2fr]">
      <TableHeader title="scannedDomains-subdomains" headerProps={headers} />
      {scannedAssetList.map((item) => (
        <ScannedAssetsTableRow
          key={`tscannedDomains-subdomains-tr${item.asset.address}`}
          data={item}
          onChangeTab={onChangeTab}
        />
      ))}
    </div>
  );
};

export default ScannedAssetsTableGrid;
