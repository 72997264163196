/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import patterns from "#src/config/patterns";

// Components
import { StickySection, ContentSection } from "#src/layouts/content";
import OverviewFilter from "#src/components/dataLeak/overview/Filter";
import OverviewLeaksByType from "#src/components/dataLeak/overview/LeaksByType";
import OverviewLeaksBySeverity from "#src/components/dataLeak/overview/LeaksBySeverity";
import OverviewLeaksByStatus from "#src/components/dataLeak/overview/LeaksByStatus";
import OverviewTopLeakedKeywords from "#src/components/dataLeak/overview/TopLeakedKeywords";
import OverviewKeywords from "#src/components/dataLeak/overview/Keywords";
import OverviewUpdates from "#src/components/dataLeak/overview/Updates";

import LoadingState from "#src/common/system/LoadingState";

import { apiErrorHandler } from "#src/utils/apiErrorHandler";

import dataLeakServices, {
  type IDataLeakOverview,
  type IDataLeakOverviewUpdates,
  type IDataLeakOverviewKeyword,
} from "#src/services/dataLeak";

const DataLeakOverview = () => {
  const { t } = useTranslation("dataLeak", { keyPrefix: "overview" });
  const { workspaceId } = useParams<"workspaceId">();

  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [overview, setOverview] = useState<IDataLeakOverview | null>(null);
  const [overviewKeywords, setOverviewKeywords] = useState<
    Array<IDataLeakOverviewKeyword>
  >([]);
  const [overviewUpdates, setOverviewUpdates] =
    useState<IDataLeakOverviewUpdates | null>(null);

  const onChangeTimeConditionValue = (
    condition: string,
    from: number | null,
    to: number | null
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
  };

  const fetchOverview = useCallback(
    (workspaceId: string) => {
      dataLeakServices
        .overview_data_leak(workspaceId, { from, to })
        .then((response) => {
          setOverview(response);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadOverview.fail"),
          });
        });
    },
    [t, from, to]
  );

  const fetchOverviewKeywords = useCallback(
    (workspaceId: string, keyword?: string) => {
      dataLeakServices
        .list_data_leak_overview_keywords(workspaceId, { from, to, q: keyword })
        .then((response) => {
          setOverviewKeywords(
            response.map((r) => {
              return {
                ...r,
                is_domain: patterns.DOMAIN.test(r.keyword),
              };
            })
          );
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadOverview.fail"),
          });
        });
    },
    [t, from, to]
  );

  const fetchOverviewUpdates = useCallback(
    (workspaceId: string) => {
      dataLeakServices
        .overview_updates_data_leak(workspaceId, { from, to })
        .then((response) => {
          setOverviewUpdates(response);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadOverview.fail"),
          });
        });
    },
    [t, from, to]
  );

  const fetchOverviewData = useCallback(
    (workspaceId: string) => {
      setLoading(true);
      Promise.all([
        fetchOverview(workspaceId),
        fetchOverviewKeywords(workspaceId),
        fetchOverviewUpdates(workspaceId),
      ]).finally(() => {
        setLoading(false);
      });
    },
    [fetchOverview, fetchOverviewKeywords, fetchOverviewUpdates]
  );

  useEffect(() => {
    if (workspaceId) {
      fetchOverviewData(workspaceId);
    }
  }, [workspaceId, fetchOverviewData]);

  return (
    <>
      {loading || !overview ? (
        <LoadingState />
      ) : (
        <>
          <StickySection className="flex flex-col gap-3 w-full">
            <h1>{t("title")}</h1>
            <p className="font-regular-18-body text-hard-grey">
              {t("description")}
            </p>
          </StickySection>
          <ContentSection className="flex flex-col gap-6 w-full">
            <OverviewFilter
              onChangeTimeConditionValue={onChangeTimeConditionValue}
            />
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-2 flex flex-col gap-6">
                <OverviewLeaksByType overviewByType={overview.by_type} />
                <div className="grid grid-cols-2 gap-6">
                  <OverviewLeaksBySeverity
                    overviewBySeverity={overview.by_severity}
                  />
                  <OverviewLeaksByStatus
                    overviewByStatus={overview.by_status}
                  />
                </div>
              </div>
              <OverviewKeywords
                workspaceId={workspaceId}
                overviewKeywords={overviewKeywords}
                fetchOverviewKeywords={fetchOverviewKeywords}
              />
            </div>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-2 flex flex-col gap-6">
                <OverviewTopLeakedKeywords
                  overviewTopKeywords={overview.top_keywords}
                />
              </div>
              <OverviewUpdates overviewUpdates={overviewUpdates} />
            </div>
          </ContentSection>
        </>
      )}
    </>
  );
};

export default DataLeakOverview;
