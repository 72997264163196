// Libraries
import { Modal } from "@lockerpm/design";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { AxiosError } from "axios";
import { z } from "zod";

// Resources
import { ReactComponent as Attachment2 } from "#src/assets/images/icons/attachment-2.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import {
  type FormErrorItem,
  InputField,
} from "#src/common/helper/wrapper/InputField";
import { FileUploadActionValue, uploadFile } from "#src/utils/uploadFile";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";
import { ObjectImage } from "#src/common/system/Object";
import Loader from "#src/common/system/Loader";

// Children
import trustCenterServices from "#src/services/trustCenter";

interface IAddDocumentModalProps {
  workspaceId: string;
  open: boolean;
  onClose: () => void;
  onRefresh: () => void;
  maxFileSize: number | null;
}

const AddDocumentModal = ({
  workspaceId,
  open,
  onClose,
  onRefresh,
  maxFileSize,
}: IAddDocumentModalProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "document.addDocumentModal",
  });

  const [documentName, setDocumentName] = useState<string>("");
  const [attachments, setAttachments] = useState<
    { name: string; uploadedAt: number; signedUrl: string }[]
  >([]);
  const [errorObj, setErrorObj] = useState<{
    documentName: FormErrorItem;
    attachments: FormErrorItem;
  }>({
    documentName: undefined,
    attachments: undefined,
  });

  const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);

  const onCloseAndCleanup = () => {
    onClose();
    setDocumentName("");
    setAttachments([]);
    setErrorObj({ documentName: undefined, attachments: undefined });
  };

  const onUploadAttachments = (files: File[]) => {
    for (const file of files) {
      if (maxFileSize ? file.size > maxFileSize : false) {
        setErrorObj((prev) => ({
          ...prev,
          attachments: () => t("invalid.maxSizeExceeded"),
        }));
        return;
      }

      const fileType = file.type.split("/")[0];
      if (
        fileType === "image" ||
        fileType === "video" ||
        file.type === "application/pdf"
      ) {
        setUploadingFiles(true);
        uploadFile(file, FileUploadActionValue.TRUST_CENTER_RESOURCE, {
          workspace_id: workspaceId,
        })
          .then((signedResponse) => {
            setAttachments((prev) => [
              ...prev,
              {
                name: file.name,
                uploadedAt: dayjs().unix(),
                signedUrl: signedResponse.signed_url,
              },
            ]);
            setErrorObj((prev) => ({ ...prev, attachments: undefined }));
          })
          .catch((error) => {
            if (
              error instanceof AxiosError &&
              error.response &&
              error.response.status === 400
            ) {
              const getErrorData = parseBadRequest(
                error.response.data,
                z.object({
                  file_name: z.optional(z.array(z.string())),
                })
              );
              setErrorObj((prev) => ({
                ...prev,
                attachments: getErrorData.details.file_name
                  ? () => (getErrorData.details.file_name as string[])[0]
                  : undefined,
              }));
            } else {
              apiErrorHandler(error, {
                toastMessage: t("notification.uploadFile.fail"),
              });
            }
          })
          .finally(() => {
            setUploadingFiles(false);
          });
      } else {
        setErrorObj((prev) => ({
          ...prev,
          attachments: () => t("invalid.fileType"),
        }));
      }
    }
  };

  const onCreateDocument = () => {
    if (attachments.length === 0) return;

    setPending(true);
    trustCenterServices
      .create_trust_center_document(workspaceId, {
        name: documentName,
        url: attachments[0].signedUrl,
      })
      .then(() => {
        onCloseAndCleanup();
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.addDocument.fail"),
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Modal
      open={open}
      onCancel={onCloseAndCleanup}
      title={<h2>{t("title")}</h2>}
      centered
      footer={null}
      width="44rem"
    >
      <div className="flex flex-col gap-6 pt-3">
        <InputField
          title={t("documentName.title")}
          value={documentName}
          onChangeValue={(value) => {
            setDocumentName(value);
          }}
          error={errorObj.documentName}
          onChangeError={(value) => {
            setErrorObj((prev) => ({ ...prev, documentName: value }));
          }}
          validation={{ required: true, maxLength: 255 }}
        />
        <div className="flex flex-col gap-3">
          <h4>
            <span className="text-primary pr-0.5">*</span>
            {t("attachedDocument.title")}
          </h4>
          {uploadingFiles ? (
            <div className="flex-1 flex flex-col gap-3 items-center justify-center pt-6 pb-12 text-primary border border-dashed border-primary rounded-md">
              {/* Height 3.125rem for consistency with original state. */}
              <div className="h-[3.125rem]">
                <Loader baseSize={1.125} />
              </div>
            </div>
          ) : attachments.length > 0 ? (
            attachments.map((attachment) => (
              <div
                key={`attachment-${attachment.signedUrl}`}
                className="relative bg-bright-grey rounded-md w-fit flex gap-2 p-2"
              >
                <ObjectImage
                  className="h-12 w-12 rounded-md object-cover overflow-hidden"
                  data={attachment.signedUrl}
                />
                <div className="flex flex-col gap-1 w-56">
                  <span>{attachment.name}</span>
                  <span className="font-medium-14 text-hard-grey">
                    {t("uploadedOn")}{" "}
                    {dayjs.unix(attachment.uploadedAt).format("MMM DD,YYYY")}
                  </span>
                </div>
                <div className="absolute top-1 right-1 p-1">
                  <button
                    className="rounded-full border border-warning-red-40% bg-warning-red-5% h-6 w-6 p-1 flex items-center justify-center"
                    onClick={() => {
                      setAttachments((prev) =>
                        prev.filter(
                          (item) => item.signedUrl !== attachment.signedUrl
                        )
                      );
                    }}
                  >
                    <CloseLine className="h-4 w-4 fill-warning-red-danger" />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <label className="flex-1 flex flex-col gap-3 items-center justify-center pt-6 pb-12 text-primary border border-dashed border-primary rounded-md cursor-pointer">
              <input
                id="file_uploader"
                type="file"
                className="hidden"
                accept="image/*,application/pdf"
                onChange={(e) => {
                  if (e.currentTarget.files) {
                    onUploadAttachments(Array.from(e.currentTarget.files));
                  }
                  // Reset file input.
                  e.currentTarget.value = "";
                }}
              />
              <Attachment2 className="h-5 w-5" />
              <span className="flex gap-1">
                <span className="font-regular-14">
                  {t("dragAndDropEvidenceAsImageOrPdf")}
                </span>
                {maxFileSize ? (
                  <span className="font-regular-14 text-hard-grey">
                    {t("maximum1File", { size: maxFileSize / 1024 / 1024 })}
                  </span>
                ) : null}
              </span>
            </label>
          )}
          {errorObj.attachments ? (
            <span className="font-medium-14-forced text-warning flex items-center gap-1">
              <InformationLine className="h-5 w-5" />
              {errorObj.attachments()}
            </span>
          ) : null}
        </div>
        <div className="flex justify-end">
          <div className="flex gap-1">
            <Button
              variant="secondary"
              onClick={() => {
                onCloseAndCleanup();
              }}
            >
              {t("button.cancel")}
            </Button>
            <Button
              onClick={() => {
                onCreateDocument();
              }}
              disabled={
                documentName.length === 0 ||
                documentName.length > 255 ||
                attachments.length === 0
              }
              pending={pending}
            >
              {t("button.add")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddDocumentModal;
