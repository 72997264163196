// Libraries
import { type Dispatch, type SetStateAction, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Tooltip } from "@lockerpm/design";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as VietcombankSquare } from "#src/assets/images/logos/banks/vietcombank-square.svg";
import { ReactComponent as VPBankSquare } from "#src/assets/images/logos/banks/vpbank-square.svg";
import { ReactComponent as TechcombankSquare } from "#src/assets/images/logos/banks/techcombank-square.svg";
import { ReactComponent as ACBSquare } from "#src/assets/images/logos/banks/acb-square.svg";
import { ReactComponent as VIBSquare } from "#src/assets/images/logos/banks/vib-square.svg";
import { ReactComponent as MBBankSquare } from "#src/assets/images/logos/banks/mbbank-square.svg";
import { ReactComponent as VisaSvg } from "#src/assets/images/logos/banks/visa.svg";
import { ReactComponent as MasterCardSvg } from "#src/assets/images/logos/banks/mastercard.svg";
import { ReactComponent as AmericanExpressSvg } from "#src/assets/images/logos/banks/americanexpress.svg";

// Components
import { Accordion } from "#src/common/system/Accordion";
import { MultipleChoiceBlockWrapper } from "#src/common/composed/divWrappers/MultipleChoiceBlockWrapper";
import type { FormErrorItem } from "#src/common/helper/wrapper/InputField";

// API-related
import paymentService, { type IPaymentForm } from "#src/services/payment";

// Children
import PaymentFillFormOptions from "#src/components/payment/FillForm/PaymentMethod/Options";
import { NoteWrapper } from "#src/components/payment/uiHelper/NoteWrapper";
import { PaymentMethodValue } from "#src/config/filter/workspace/value";

interface IPaymentFillFormMethodSelectProps {
  paymentForm: IPaymentForm;
  setPaymentForm: Dispatch<SetStateAction<IPaymentForm>>;
  nextCycleInfo: { cycle: string; price: string; date: string };
  defaultPaymentSettings: Awaited<
    ReturnType<typeof paymentService.retrieve_workspace_payment_settings>
  > | null;
  firstPayment: boolean;
  lastSelectedMethod: PaymentMethodValue;
  invoiceErrorObj?: {
    name: FormErrorItem;
    tax_code: FormErrorItem;
    identity_card: FormErrorItem;
    address: FormErrorItem;
  };
  setInvoiceErrorObj?: Dispatch<
    SetStateAction<{
      name: FormErrorItem;
      tax_code: FormErrorItem;
      identity_card: FormErrorItem;
      address: FormErrorItem;
    }>
  >;
}

/**
 * @param paymentForm - info entered by user to complete payment.
 * @param setPaymentForm - controller for `paymentForm`.
 * @param nextCycleInfo - print out an info card for international payment card.
 * @param defaultPaymentSettings - provide default value for VAT-related parts of the form.
 * @param firstPayment - if this is the first payment for a product plan, some features will not be allowed.
 * @param lastSelectedMethod - PaymentMethodValue
 */
const PaymentFillFormMethodSelect = ({
  paymentForm,
  setPaymentForm,
  nextCycleInfo,
  defaultPaymentSettings,
  firstPayment,
  lastSelectedMethod,
  invoiceErrorObj,
  setInvoiceErrorObj,
}: IPaymentFillFormMethodSelectProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.fillForm.paymentMethod",
  });

  const [expanded, setExpanded] = useState<boolean>(true);

  const onChangePaymentMethod = (
    value: (typeof paymentForm)["payment_method"]
  ) => {
    setPaymentForm((prev) => ({ ...prev, payment_method: value }));
  };

  return (
    <Accordion
      label={
        <h2 className="flex justify-between items-center">
          {t("title")}
          <ArrowDownSLine
            className={`h-6 w-6 ${
              expanded ? "rotate-180" : ""
            } transition-all cursor-pointer`}
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
          />
        </h2>
      }
      expanded={expanded}
    >
      <div className="flex flex-col gap-6 p-6">
        <div className="grid grid-cols-2 gap-6">
          <Tooltip
            title={
              !firstPayment &&
              lastSelectedMethod !== PaymentMethodValue.BANKING ? (
                <span className="font-medium-12 text-center align-center">
                  <Trans
                    t={t}
                    i18nKey={"tooltip.renewWithOldMethod"}
                    components={{ a: <a className="text-subcard-blue" /> }}
                  />
                </span>
              ) : null
            }
          >
            {/* We need the useless div here so that Antd Tooltip can work. */}
            <div>
              <MultipleChoiceBlockWrapper
                selected={
                  paymentForm.payment_method === PaymentMethodValue.BANKING
                }
                onSelect={() => {
                  onChangePaymentMethod(PaymentMethodValue.BANKING);
                }}
                disabled={lastSelectedMethod !== PaymentMethodValue.BANKING}
              >
                <span className="font-bold-20">{t("banking.title")}</span>
                <div className="flex flex-col gap-4">
                  <span className="text-hard-grey">{t("banking.note")}</span>
                  <div className="flex items-center justify-between gap-3">
                    <div className="flex gap-3 overflow-hidden">
                      <VietcombankSquare className="h-8 w-8" />
                      <VPBankSquare className="h-8 w-8" />
                      <TechcombankSquare className="h-8 w-8" />
                      <ACBSquare className="h-8 w-8" />
                      <VIBSquare className="h-8 w-8" />
                      <MBBankSquare className="h-8 w-8" />
                    </div>
                    {/* TODO: add the see all modal here */}
                    <a className="whitespace-nowrap">{t("banking.seeAll")}</a>
                  </div>
                </div>
              </MultipleChoiceBlockWrapper>
            </div>
          </Tooltip>
          <Tooltip
            title={
              !firstPayment &&
              lastSelectedMethod !== PaymentMethodValue.CARD ? (
                <span className="font-medium-12 text-center align-center">
                  <Trans
                    t={t}
                    i18nKey={"tooltip.renewWithOldMethod"}
                    components={{ a: <a className="text-subcard-blue" /> }}
                  />
                </span>
              ) : (
                // TODO: Remove this when we complete the international payment flow.
                <span className="font-medium-12 text-center align-center">
                  {t("tooltip.notAvailable")}
                </span>
              )
            }
          >
            {/* We need the useless div here so that Antd Tooltip can work. */}
            <div>
              <MultipleChoiceBlockWrapper
                selected={
                  paymentForm.payment_method === PaymentMethodValue.CARD
                }
                onSelect={() => {
                  onChangePaymentMethod(PaymentMethodValue.CARD);
                }}
                disabled={lastSelectedMethod !== PaymentMethodValue.CARD}
              >
                <span className="font-bold-20">{t("card.title")}</span>
                <div className="flex flex-col gap-4">
                  <span className="text-hard-grey">{t("card.note")}</span>
                  <div className="flex gap-3 overflow-hidden">
                    <VisaSvg className="h-8 w-8" />
                    <MasterCardSvg className="h-8 w-8" />
                    <AmericanExpressSvg className="h-8 w-8" />
                  </div>
                </div>
              </MultipleChoiceBlockWrapper>
            </div>
          </Tooltip>
        </div>
        <Accordion
          expanded={paymentForm.payment_method === PaymentMethodValue.CARD}
        >
          <NoteWrapper>
            <Trans
              t={t}
              i18nKey={"card.autoRenewEveryCycle"}
              components={{ b: <b className="text-dark-blue" /> }}
              values={nextCycleInfo}
            />
          </NoteWrapper>
        </Accordion>
        <span className="text-warning">{t("requestVat")}</span>
        <Accordion
          expanded={paymentForm.payment_method === PaymentMethodValue.BANKING}
        >
          <PaymentFillFormOptions
            paymentForm={paymentForm}
            setPaymentForm={setPaymentForm}
            defaultPaymentSettings={defaultPaymentSettings}
            firstPayment={firstPayment}
            invoiceErrorObj={invoiceErrorObj}
            setInvoiceErrorObj={setInvoiceErrorObj}
          />
        </Accordion>
      </div>
    </Accordion>
  );
};

export default PaymentFillFormMethodSelect;
