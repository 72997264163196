import { ButtonV2 } from "#src/commonV2/Button";
import { ModalV2 } from "#src/commonV2/Modal";
import { IEndpointPolicy } from "#src/services/endpoint/policies/types";
import { LoadingStateV2 } from "#src/commonV2/LoadingState";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

interface IEndpointPolicyDetailModalProps {
  open: boolean;
  onClose: () => void;
  policyDetail: IEndpointPolicy | null;
}

export const EndpointPolicyDetailModal = ({
  open,
  onClose,
  policyDetail,
}: IEndpointPolicyDetailModalProps) => {
  const { t } = useTranslation("endpoint", {
    keyPrefix: "policies.table.detail",
  });
  const locale = i18next.language;

  const resolutionText = useMemo(() => {
    if (!policyDetail) return "";
    if (locale === "vi") {
      if (policyDetail.policy.resolution.vi)
        return policyDetail.policy.resolution.vi;
      if (policyDetail.policy.resolution.en)
        return policyDetail.policy.resolution.en;
      return "";
    }
    if (policyDetail.policy.resolution.en)
      return policyDetail.policy.resolution.en;
    if (policyDetail.policy.resolution.vi)
      return policyDetail.policy.resolution.vi;
    return "";
  }, [policyDetail, locale]);

  return (
    <ModalV2
      open={open}
      onChangeOpen={(value) => {
        if (!value) {
          onClose();
        }
      }}
    >
      {policyDetail ? (
        <>
          <span className="text-lg font-semibold text-gray-v2-900">
            {locale === "vi"
              ? policyDetail.policy.name.vi
              : policyDetail.policy.name.en}
          </span>
          <div className="flex flex-col gap-2">
            <span className="text-lg font-semibold text-gray-v2-900">
              {t("description")}
            </span>
            <p className="text-md text-gray-v2-600">
              {locale === "vi"
                ? policyDetail.policy.description.vi
                : policyDetail.policy.description.en}
            </p>
          </div>
          {resolutionText ? (
            <div className="flex flex-col gap-2">
              <span className="text-lg font-semibold text-gray-v2-900">
                {t("resolution")}
              </span>
              <p className="text-md text-gray-v2-600">{resolutionText}</p>
            </div>
          ) : null}
          <div className="flex justify-end">
            <ButtonV2
              variant="secondary"
              color="gray"
              className="w-fit"
              onClick={onClose}
            >
              {t("button.gotIt")}
            </ButtonV2>
          </div>
        </>
      ) : (
        <LoadingStateV2 />
      )}
    </ModalV2>
  );
};
