import { helperPathname, pathname } from "#src/config/pathname";
import i18next from "i18next";
import { Award03, GitCommit, Grid01, Rows01 } from "@untitled-ui/icons-react";
import { ReactComponent as BugLine } from "#src/assets/images/icons/bug-line.svg";
import { ISidebarProductItem, ISidebarSecondMenuItem } from "../types";
import { ReactNode } from "react";

const sidebarTFunc = i18next.getFixedT(null, "layout", "sidebar.v2");

const SEPARATOR = "SEPARATOR";

export const sidebarV2Items: {
  key: string;
  getLabel: () => ReactNode;
  products: (ISidebarProductItem | typeof SEPARATOR)[];
}[] = [
  {
    key: "ungrouped",
    getLabel: () => null,
    products: [
      {
        path: pathname.SOLUTIONS,
        getLabel: () => sidebarTFunc("allSolutions.label"),
        icon: Grid01,
      },
    ],
  },
  {
    key: "attack-surface-management",
    getLabel: () => sidebarTFunc("headers.attackSurfaceManagement"),
    products: [
      {
        path: helperPathname.ASSETS,
        getLabel: () => sidebarTFunc("assets.label"),
        icon: Rows01,
        children: [
          {
            path: pathname.DOMAINS,
            getLabel: () => sidebarTFunc("assets.domains"),
          },
          {
            path: pathname.IP_ADDRESS,
            getLabel: () => sidebarTFunc("assets.ipAddress"),
          },
          {
            path: pathname.CERTIFICATES,
            getLabel: () => sidebarTFunc("assets.certificates"),
          },
        ],
      },
      {
        path: pathname.VULNERABILITIES,
        getLabel: () => sidebarTFunc("vulnerabilities.label"),
        icon: BugLine,
      },
    ],
  },
  {
    key: "data-protection",
    getLabel: () => sidebarTFunc("headers.dataProtection"),
    products: [
      {
        path: helperPathname.DATA_LEAK,
        getLabel: () => sidebarTFunc("dataLeak.label"),
        icon: Grid01,
        children: [
          {
            path: pathname.DATA_LEAK_OVERVIEW,
            getLabel: () => sidebarTFunc("dataLeak.overview"),
          },
          {
            path: pathname.DATA_LEAK_FOUND_LEAKS,
            getLabel: () => sidebarTFunc("dataLeak.foundLeaks"),
          },
        ],
      },
      SEPARATOR,
      {
        path: helperPathname.ENDPOINT,
        getLabel: () => sidebarTFunc("endpoint.label"),
        icon: GitCommit,
        children: [
          {
            path: pathname.ENDPOINT_OVERVIEW,
            getLabel: () => sidebarTFunc("endpoint.overview"),
          },
          {
            path: pathname.ENDPOINT_DEVICES,
            getLabel: () => sidebarTFunc("endpoint.devices.label"),
          },
          {
            path: pathname.ENDPOINT_POLICIES,
            getLabel: () => sidebarTFunc("endpoint.policies"),
          },
          {
            path: pathname.ENDPOINT_DLP_BACKUP,
            getLabel: () => sidebarTFunc("endpoint.dlp.label"),
          },
        ],
      },
    ],
  },
  {
    key: "security-compliance",
    getLabel: () => sidebarTFunc("headers.securityCompliance"),
    products: [
      {
        path: helperPathname.TRUST_CENTER,
        getLabel: () => sidebarTFunc("trustCenter.label"),
        icon: Award03,
        children: [
          {
            path: pathname.TRUST_CENTER_POLICY,
            getLabel: () => sidebarTFunc("trustCenter.policy"),
          },
          {
            path: pathname.TRUST_CENTER_COMPLIANCE,
            getLabel: () => sidebarTFunc("trustCenter.compliance"),
          },
          {
            path: pathname.TRUST_CENTER_CONTROL,
            getLabel: () => sidebarTFunc("trustCenter.control"),
          },
          {
            path: pathname.TRUST_CENTER_DOCUMENT,
            getLabel: () => sidebarTFunc("trustCenter.document"),
          },
          {
            path: pathname.TRUST_CENTER_THIRD_PARTY,
            getLabel: () => sidebarTFunc("trustCenter.thirdPartyService"),
          },
          {
            path: pathname.TRUST_CENTER_FAQ,
            getLabel: () => sidebarTFunc("trustCenter.faq"),
          },
          {
            path: pathname.TRUST_CENTER_UPDATE,
            getLabel: () => sidebarTFunc("trustCenter.update"),
          },
          {
            path: pathname.TRUST_CENTER_CONFIG,
            getLabel: () => sidebarTFunc("trustCenter.configuration"),
          },
        ],
      },
    ],
  },
];

export const sidebarV2SecondMenuItems: ISidebarSecondMenuItem[] = [
  {
    rootPath: helperPathname.ENDPOINT_DLP,
    backTo: {
      path: pathname.ENDPOINT_OVERVIEW,
      getLabel: () => sidebarTFunc("endpoint.label"),
    },
    children: [
      {
        path: pathname.ENDPOINT_DLP_BACKUP,
        getLabel: () => sidebarTFunc("endpoint.dlp.backup"),
      },
      {
        path: pathname.ENDPOINT_DLP_ACTIVITIES,
        getLabel: () => sidebarTFunc("endpoint.dlp.activities"),
      },
    ],
  },
  {
    rootPath: helperPathname.ENDPOINT_DEVICE_DETAIL,
    backTo: {
      path: pathname.ENDPOINT_DEVICES,
      getLabel: () => sidebarTFunc("endpoint.label"),
    },
    children: [
      {
        path: pathname.ENDPOINT_DEVICES_OVERVIEW,
        getLabel: () => sidebarTFunc("endpoint.devices.details.overview"),
      },
      {
        path: pathname.ENDPOINT_DEVICES_SOFTWARE,
        getLabel: () => sidebarTFunc("endpoint.devices.details.software"),
      },
      {
        path: pathname.ENDPOINT_DEVICES_POLICIES,
        getLabel: () => sidebarTFunc("endpoint.devices.details.policies"),
      },
      {
        path: pathname.ENDPOINT_DEVICES_ACTIVITIES,
        getLabel: () => sidebarTFunc("endpoint.devices.details.activities"),
      },
    ],
  },
];
