import { useTranslation } from "react-i18next";
import { PricingTableTitles } from "./TitleCells";
import DataLeakAddKeywordPricingTable from "./Table";

const DataLeakAddKeywordPricing = ({
  workspaceId,
}: {
  workspaceId: string;
}) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "addKeyword.pricingTable",
  });

  return (
    <div className="flex flex-col gap-6 items-center">
      <h2>{t("title")}</h2>
      <PricingTableTitles workspaceId={workspaceId} />
      <DataLeakAddKeywordPricingTable />
    </div>
  );
};

export default DataLeakAddKeywordPricing;
