import type { ReactNode } from "react";
import { TableHeaderCellV2 } from "./TableHeaderCell";
import { Checkbox } from "@lockerpm/design";

export interface ITableHeaderPropItemV2 {
  name: ReactNode;
  align?: "left" | "center" | "right";
  sortingKey?: { asc: string; desc: string };
}

interface ITableHeaderV2 {
  headerProps: ITableHeaderPropItemV2[];
  key?: string;
  sort?: { value: string; onChange: (value: string) => void };
  allSelected?: {
    value: boolean;
    onChange: () => void;
    // If data is ready to interact, we can show the checkbox
    ready: boolean;
  };
  tableExpandable?: boolean;
}

/** Same as name, just table header
 * @param key - used for React's keys
 * @param headerProps - list of header props, which will be displayed inside this header component
 * @param sort - value of, of course, sort parameter, duh
 * @param allSelected - a checkbox, checked if all rows are selected, hide if undefined
 */
export const TableHeaderV2 = ({
  headerProps,
  key,
  sort,
  allSelected,
  tableExpandable = false,
}: ITableHeaderV2) => {
  return (
    <>
      <div className="grid grid-cols-subgrid col-span-full rounded-t-xl overflow-hidden transition-all">
        {tableExpandable ? <TableHeaderCellV2></TableHeaderCellV2> : null}
        {allSelected ? (
          <TableHeaderCellV2 align={"center"}>
            {allSelected.ready ? (
              <Checkbox
                checked={allSelected.value}
                onClick={allSelected.onChange}
              />
            ) : null}
          </TableHeaderCellV2>
        ) : null}
        {headerProps.map((header, index) => {
          const calcSort: 1 | 0 | -1 | undefined =
            header.sortingKey && sort
              ? header.sortingKey.asc === sort.value
                ? 1
                : header.sortingKey.desc === sort.value
                  ? -1
                  : 0
              : undefined;

          return (
            <TableHeaderCellV2
              key={`t${key}-th${header.name}id${index}`}
              align={header.align}
              sort={calcSort}
              onClick={() => {
                if (header.sortingKey && sort?.onChange) {
                  if (calcSort === 0) {
                    sort.onChange(header.sortingKey.asc);
                    return;
                  }
                  if (calcSort === 1) {
                    sort.onChange(header.sortingKey.desc);
                    return;
                  }
                  if (calcSort === -1) {
                    sort.onChange("");
                    return;
                  }
                }
              }}
            >
              {header.name}
            </TableHeaderCellV2>
          );
        })}
      </div>
    </>
  );
};
