import { faker } from "@faker-js/faker";
import {
  IDLPActivityLog,
  IDLPDrive,
  IDLPSnapshot,
  IDLPSchedule,
} from "./types";
import {
  DLPAction,
  DLPActionStatus,
  DLPDriveBackupStatus,
  DLPDriveOnlineStatus,
} from "./enums";

const getRandomId = (start: number, end: number) =>
  Math.floor(Math.random() * (end - start)) + start;

const createFakeDrive = () => ({
  id: faker.number.int(),
  name: faker.food.dish(),
  created_time: faker.date.recent().getTime(),
  updated_time: faker.date.recent().getTime(),
  asset_type: faker.lorem.word(),
  status:
    Object.values(DLPDriveOnlineStatus)[
      getRandomId(0, Object.values(DLPDriveOnlineStatus).length)
    ],
  last_seen: faker.date.recent().getTime(),
  email: faker.internet.email(),
  drive_size: faker.number.int(),
  drive_path: faker.system.filePath(),
  snapshots: faker.number.int({ min: 0, max: 3 }),
  snapshots_total_size: faker.number.int({ max: 4096 }),
  backup_enabled: faker.datatype.boolean(),
  backup_status:
    Object.values(DLPDriveBackupStatus)[
      getRandomId(0, Object.values(DLPDriveBackupStatus).length)
    ],
});

const createFakeLog = () => ({
  id: faker.string.uuid(),
  drive_id: faker.number.int(),
  drive_name: faker.food.fruit(),
  email: faker.internet.email(),
  type: Object.values(DLPAction)[
    getRandomId(0, Object.values(DLPAction).length)
  ],
  snapshot_id: faker.string.uuid(),
  snapshot_version: faker.date.recent().toDateString(),
  snapshot_size: faker.number.int({ max: 256 }),
  requested_time: faker.date.recent().getTime(),
  started_time: faker.date.recent().getTime(),
  completed_time: faker.date.recent().getTime(),
  status:
    Object.values(DLPActionStatus)[
      getRandomId(0, Object.values(DLPActionStatus).length)
    ],
});

export const FAKE_DLP = {
  drives: faker.helpers.multiple(createFakeDrive, {
    count: 5,
  }) satisfies IDLPDrive[],

  logs: faker.helpers.multiple(createFakeLog, {
    count: 10,
  }) satisfies IDLPActivityLog[],

  schedules: [
    {
      activated: true,
      duration_type: "weekly",
      activated_time: 1740554620,
      days: [0],
      repeat_number: 1,
      timezone: "America/Chicago",
    },
  ] as IDLPSchedule[],

  snapshots: [
    {
      id: "bf255537-1b83-44ef-8489-b456129848f1",
      name: "8.1.19",
      start_time: 1718167743,
      end_time: 1771367162,
      size: 966,
    },
    {
      id: "afe6a4fa-3825-4743-86a1-7a42ec39622e",
      name: "6.14.2",
      start_time: 1737425162,
      end_time: 1754404036,
      size: 850,
    },
    {
      id: "95c7d373-e90c-4a91-a8fc-5910506da300",
      name: "4.17.13",
      start_time: 1731054926,
      end_time: 1757484835,
      size: 634,
    },
  ] as IDLPSnapshot[],
};
