import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IHostSoftwareItem {
  last_found: number;
  install_date: any | null;
  install_location: string[];
  size: number | null;
  port: number | null;
  program: {
    id: number;
    name: string;
    version: string;
    cpe: string;
    vulnerabilities: {
      cisa_known_exploit: boolean;
      cve: string;
      cve_description: string;
      cve_published: string;
      cvss_score: number;
      details_link: string;
      epss_probability: number;
      resolved_in_version: string;
      severity: string;
    }[];
    source: any | null;
  };
}

export function list_host_softwares(
  workspace_id: string,
  host_id: number,
  params: {
    vulnerable: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IHostSoftwareItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts/${host_id}/programs`,
    { params }
  );
}

interface IWorkspaceHostSoftwareItem {
  cpe: string;
  id: number;
  name: string;
  publisher: any | null;
  source: string;
  version: string;
  vulnerabilities: {
    cisa_known_exploit: boolean;
    cve: string;
    cve_description: string;
    cve_published: string;
    cvss_score: number;
    details_link: string;
    epss_probability: number;
    resolved_in_version: string;
  }[];
}

export function list_workspace_softwares(
  workspace_id: string,
  params: IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IWorkspaceHostSoftwareItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/software`,
    { params }
  );
}

interface IHostSoftwareStatistic {
  total: number;
  vulnerable: number;
  severity: {
    CRITICAL: number;
    HIGH: number;
    MEDIUM: number;
    LOW: number;
    NONE: number;
  };
}

export function get_host_softwares_statistic(
  workspace_id: string,
  host_id: string
) {
  return request.get<never, IHostSoftwareStatistic>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts/${host_id}/programs/statistic`
  );
}
