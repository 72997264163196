import { useEffect, useState } from "react";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import workspaceServices, {
  type IWorkspaceItem,
} from "#src/services/workspace";

export const useCurrentWorkspace = (workspaceId: string | undefined) => {
  const [currentWorkspace, setCurrentWorkspace] = useState<IWorkspaceItem>();

  useEffect(() => {
    if (workspaceId) {
      workspaceServices
        .retrieve_workspace(workspaceId)
        .then((response) => {
          setCurrentWorkspace(response);
        })
        .catch((error) => {
          // No i18n since this should not be something related to user flow.
          // TODO: change this into alert or different toast or something
          apiErrorHandler(error, {
            toastMessage: "cannot access workspace list",
          });
        });
    }
  }, [workspaceId]);

  return currentWorkspace;
};
