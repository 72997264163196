import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import type { IEndpointDevice } from "./types";
import type { IPaginatedResponse, IPaginateParams } from "#src/@types/api";

export async function listDevices(
  workspaceId: string,
  params: {
    sort: string | undefined;
    os: string | undefined;
    host_status: string | undefined;
    tag: string | undefined;
    policy_compliance: string | undefined;
    policy_compliance_id: string | undefined;
    policy_compliance_status: string | undefined;
    program_id: string | undefined;
    asset_type: string | undefined;
  } & IPaginateParams
): Promise<IPaginatedResponse<IEndpointDevice>> {
  return await request.get<never, IPaginatedResponse<IEndpointDevice>>(
    `${endpoint.WORKSPACES}/${workspaceId}/hosts`,
    { params }
  );
}

export async function getDeviceDetails(workspaceId: string, hostId: number) {
  return request.get<never, IEndpointDevice>(
    `${endpoint.WORKSPACES}/${workspaceId}/hosts/${hostId}`
  );
}
