import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import type { TrustCenterUpdateStatusValue } from "#src/components/trustCenter/update/enum";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface ITrustCenterUpdateItem {
  id: string;
  status: TrustCenterUpdateStatusValue;
  created_time: number;
  published_time: number | null;
  question: Record<string, string>;
  answer: Record<string, string>;
  languages: string[];
  details: { lang: string; question: string; answer: string }[];
}

export function list_trust_center_update(
  workspace_id: string,
  params: IPaginateParams
) {
  return request.get<never, IPaginatedResponse<ITrustCenterUpdateItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/updates`,
    { params }
  );
}

export function list_trust_center_update_no_paging(
  workspace_id: string,
  params: { q?: string | undefined }
) {
  return request.get<never, ITrustCenterUpdateItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/updates`,
    { params: { ...params, paging: 0 } }
  );
}

export function retrieve_trust_center_update(
  workspace_id: string,
  update_id: string
) {
  return request.get<never, ITrustCenterUpdateItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/updates/${update_id}`
  );
}

export function create_trust_center_update(
  workspace_id: string,
  data: {
    details: { lang: string; question: string; answer: string }[];
    status: TrustCenterUpdateStatusValue;
  }
) {
  return request.post<never, { id: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/updates`,
    data
  );
}

export function update_trust_center_update(
  workspace_id: string,
  update_id: string,
  data: {
    details: { lang: string; question: string; answer: string }[];
    status: TrustCenterUpdateStatusValue;
  }
) {
  return request.put<never, { id: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/updates/${update_id}`,
    data
  );
}

export function delete_trust_center_update(workspace_id: string, update_id: string) {
  return request.delete(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/updates/${update_id}`
  );
}
