// Libraries
import { type ReactNode } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// General
import urls from "#src/config/urls";

// Components
import { RoundedTag, RoundedSeeMoreTags } from "#src/common/system/Tag";
import { Button } from "#src/common/system/Button";
import { ObjectImage } from "#src/common/system/Object";
import NoResultsState from "#src/common/states/NoResultsState";

// API-related
import type { IAssetDetailItem } from "#src/services/assets";

// Children
import { AssetDetailTabEnum } from "../enums";

const SubdomainsCardHeader = ({ children }: { children: ReactNode }) => {
  return <div className="text-hard-grey h-8">{children}</div>;
};

const SubdomainsCardHeaderRow = () => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.overview.card.subdomains.headers",
  });
  return (
    <>
      <SubdomainsCardHeader>{t("domainName")}</SubdomainsCardHeader>
      <SubdomainsCardHeader>{t("ip")}</SubdomainsCardHeader>
    </>
  );
};

const SubdomainsCardRow = ({
  subdomainDetail,
}: {
  subdomainDetail: IAssetDetailItem["subdomains"]["list"][number];
}) => {
  return (
    <div className="contents text-dark-blue">
      <div className="flex gap-3 items-center">
        <ObjectImage
          data={
            urls.IMAGE_LOGO_SERVER_URL.replace(
              ":address",
              subdomainDetail.address
            ) + "?size=36&v=1"
          }
          className="h-6 w-6 min-w-[1.5rem]"
        />
        <p className="overflow-hidden text-ellipsis whitespace-nowrap">
          {subdomainDetail.address}
        </p>
      </div>
      <div className="flex items-center gap-1">
        {subdomainDetail.ips.length > 0 ? (
          <RoundedTag name={subdomainDetail.ips[0]} noLegend />
        ) : (
          "--"
        )}
        {subdomainDetail.ips.length > 1 ? (
          <RoundedSeeMoreTags
            tagList={subdomainDetail.ips
              .slice(1)
              .map((item) => ({ name: item, noLegend: true }))}
          />
        ) : null}
      </div>
    </div>
  );
};

const AssetsDetailsOverviewSubdomains = ({
  subdomainDetails,
  onChangeTab,
}: {
  subdomainDetails: IAssetDetailItem["subdomains"];
  onChangeTab: (key: string) => void;
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.overview.card.subdomains",
  });
  return (
    <>
      <div className="flex gap-3 h-9 mb-5 items-center">
        <div className="flex justify-center items-center h-8 w-8 bg-label-blue rounded-md">
          <span className="font-bold-16 text-primary">
            {subdomainDetails.count}
          </span>
        </div>
        <h2>{t("title")}</h2>
      </div>
      {subdomainDetails.count ? (
        <>
          <div className="grid grid-cols-2 gap-4 text-hard-grey">
            <SubdomainsCardHeaderRow />
            {subdomainDetails.list.slice(0, 5).map((subdomain) => (
              <SubdomainsCardRow
                key={`SubdomainCard_row-${subdomain.id}`}
                subdomainDetail={subdomain}
              />
            ))}
          </div>
          <div className="mt-6 font-sm text-primary w-full flex justify-center items-center gap-2">
            <Button
              variant="text"
              size="medium"
              onClick={() => {
                onChangeTab(AssetDetailTabEnum.SUBDOMAINS);
              }}
            >
              {t("button.viewAll")}
              <ArrowRightUpLine className="h-4 w-4" />
            </Button>
          </div>
        </>
      ) : (
        <NoResultsState />
      )}
    </>
  );
};

export default AssetsDetailsOverviewSubdomains;
