// Libraries
import { useTranslation } from "react-i18next";
import { Switch } from "@lockerpm/design";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "#src/store/hooks";
import i18next from "i18next";

// Resources
import { ReactComponent as EmailIllustration } from "#src/assets/images/illustrations/email.svg";
import { ReactComponent as Artboard2Line } from "#src/assets/images/icons/artboard-2-line.svg";
import { ReactComponent as DataLeakLine } from "#src/assets/images/icons/data-leak-line.svg";
import { ReactComponent as QrScanLine } from "#src/assets/images/icons/qr-scan-line.svg";
import { ReactComponent as DeviceLine } from "#src/assets/images/icons/device-line.svg";
import { ReactComponent as BugLine } from "#src/assets/images/icons/bug-line.svg";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import LoadingState from "#src/common/system/LoadingState";
import ConfigSectionWrapper from "#src/common/helper/wrapper/ConfigSection";
import { toPascalCase } from "#src/utils/common";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import notificationsServices, {
  type INotificationSettingItem,
} from "#src/services/notifications";

const iconByCategory = {
  assets: <Artboard2Line className="h-7 w-7" />,
  data_leak: <DataLeakLine className="h-7 w-7" />,
  scans: <QrScanLine className="h-7 w-7" />,
  devices: <DeviceLine className="h-7 w-7" />,
  vulnerability_management: <BugLine className="h-7 w-7" />,
};

const NotificationToggleItem = ({
  currentLanguage,
  data,
  onToggle,
}: {
  currentLanguage: string;
  data: INotificationSettingItem;
  onToggle: (notificationItem: INotificationSettingItem) => void;
}) => {
  return (
    <div className="flex justify-between items-center gap-3 p-6 w-full border-b border-x border-light-grey">
      <span
        className={`font-medium-16 transition-colors ${
          data.enabled ? "" : "text-medium-grey"
        }`}
      >
        {currentLanguage === "vi" ? data.category.name_vi : data.category.name}
      </span>
      <Switch
        checked={data.enabled}
        onChange={() => {
          onToggle(data);
        }}
      />
    </div>
  );
};

const NotificationSettingPage = () => {
  const { t } = useTranslation("notification");

  const locale = i18next.language;

  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const currentLanguage = i18next.language;

  const [notificationItems, setNotificationItems] = useState<
    INotificationSettingItem[]
  >([]);

  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchNotificationSettingList = useCallback(() => {
    notificationsServices
      .list_notification_settings()
      .then((response) => {
        setNotificationItems(response);
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.fetchNotificationItems.fail"),
        });
      });
  }, [t]);

  const onToggleNotificationSetting = (
    notificationItem: INotificationSettingItem
  ) => {
    // optimistic UI
    setNotificationItems((prev) => {
      const temp = prev.map((item) =>
        item.category.id === notificationItem.category.id
          ? { ...item, enabled: !item.enabled }
          : item
      );
      return temp;
    });

    notificationsServices
      .update_notification_settings(
        notificationItem.category.id,
        !notificationItem.enabled
      )
      .then((response) => {
        if (response.success) fetchNotificationSettingList();
        createToast({
          type: "success",
          message: t("notification.updateNotificationSetting.success", {
            name:
              locale === "vi"
                ? notificationItem.category.name_vi
                : notificationItem.category.name,
          }),
        });
      })
      .catch((error) => {
        // If API call is failed, undo the optimistic update
        setNotificationItems((prev) => {
          const temp = prev.map((item) =>
            item.category.id === notificationItem.category.id
              ? { ...item, enabled: !item.enabled }
              : item
          );
          return temp;
        });
        apiErrorHandler(error, {
          toastMessage: t("notification.updateNotificationSetting.fail"),
        });
      });
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      setLoading(true);
      notificationsServices
        .list_notification_settings()
        .then((response) => {
          setNotificationItems(response);
          setLoading(false);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.fetchNotificationItems.fail"),
          });
        });
    }

    return () => {
      ignore = true;
    };
  }, [t]);

  // Regrouping notificationItems, notification's categories are used as key, and the value is an array of all notifications within the category. For example: { "assets": [...] }
  let regroupedSettings: { [S in string]: typeof notificationItems } = {};
  notificationItems.forEach((item) => {
    if (regroupedSettings[item.category.category]) {
      regroupedSettings[item.category.category] = [
        ...regroupedSettings[item.category.category],
        item,
      ];
    } else {
      regroupedSettings[item.category.category] = [item];
    }
  });

  return (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
      </StickySection>
      <ContentSection>
        {isLoading ? (
          <LoadingState />
        ) : (
          <div className="grid grid-cols-[2fr_1fr] gap-6">
            <div className="flex flex-col gap-6">
              {Object.entries(regroupedSettings).map(([key, group]) => (
                <ConfigSectionWrapper
                  key={`notificationGroup-${key}`}
                  icon={
                    iconByCategory[key as keyof typeof iconByCategory] ?? (
                      <Artboard2Line className="h-7 w-7" />
                    )
                  }
                  headerTitle={toPascalCase(key.replaceAll("_", " "))}
                >
                  {group
                    .sort(
                      (a, b) =>
                        a.category.order_number - b.category.order_number
                    )
                    .map((item) => (
                      <NotificationToggleItem
                        key={`notificationSetting-${item.category.id}`}
                        currentLanguage={currentLanguage}
                        data={item}
                        onToggle={onToggleNotificationSetting}
                      />
                    ))}
                </ConfigSectionWrapper>
              ))}
            </div>
            <div>
              <div className="flex gap-6 items-center p-6 bg-bright-grey">
                <EmailIllustration className="h-fit" />
                <div className="flex flex-col gap-2">
                  <h2>{t("email.title")}</h2>
                  <span className="text-hard-grey font-regular-16-body">
                    {t("email.description")}
                    {userInfo?.email ? (
                      <span className="text-primary">{userInfo.email}</span>
                    ) : (
                      "your email"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </ContentSection>
    </>
  );
};

export default NotificationSettingPage;
