import { IPaginatedResponse, IPaginateParams } from "#src/@types/api";
import { FAKE_DLP } from "./fakeData";
import {
  IDLPDrive,
  IDLPActivityLog,
  IDLPSnapshot,
  IDLPSchedule,
} from "./types";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

// TODO: remove later
const IS_FAKE = !!process.env.REACT_APP_IS_FAKE;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

async function listDLPDrives(
  workspaceId: string,
  params: IPaginateParams & {
    backup_enabled: string | undefined;
    sort: string | undefined;
  }
): Promise<IPaginatedResponse<IDLPDrive>> {
  if (IS_FAKE) {
    console.log("calling listDLPDrives", workspaceId, params);
    const res: IPaginatedResponse<IDLPDrive> = {
      count: FAKE_DLP.drives.length,
      results: FAKE_DLP.drives,
    };
    await delay(1000);
    return res;
  }

  const res = await request.get<never, IPaginatedResponse<IDLPDrive>>(
    `${endpoint.WORKSPACES}/${workspaceId}/dlp/drives`,
    { params }
  );
  return res;
}

async function listDLPLog(
  workspaceId: string,
  params: IPaginateParams & {
    sort: string | undefined;
    type: string | undefined;
    status: string | undefined;
    from: number | undefined;
    to: number | undefined;
  }
): Promise<IPaginatedResponse<IDLPActivityLog>> {
  if (IS_FAKE) {
    console.log("calling listDLPLog", workspaceId, params);
    const res: IPaginatedResponse<IDLPActivityLog> = {
      count: FAKE_DLP.logs.length,
      results: FAKE_DLP.logs,
    };
    await delay(1000);
    return res;
  }

  const res = await request.get<never, IPaginatedResponse<IDLPActivityLog>>(
    `${endpoint.WORKSPACES}/${workspaceId}/dlp/activities`,
    { params }
  );
  return res;
}

async function getDLPSchedule(
  workspaceId: string,
  driveId: number
): Promise<IDLPSchedule> {
  if (IS_FAKE) {
    console.log("calling getDLPSchedule", workspaceId, driveId);
    const res: IDLPSchedule = FAKE_DLP.schedules[0];
    await delay(1000);
    return res;
  }

  const res = await request.get<never, IDLPSchedule>(
    `${endpoint.WORKSPACES}/${workspaceId}/dlp/drives/${driveId}/schedule`
  );
  return res;
}

async function listDLPSnapshot(
  workspaceId: string,
  driveId: number,
  params: IPaginateParams
): Promise<IPaginatedResponse<IDLPSnapshot>> {
  if (IS_FAKE) {
    console.log("calling listDLPSnapshot", workspaceId, driveId, params);
    const res: IPaginatedResponse<IDLPSnapshot> = {
      count: FAKE_DLP.snapshots.length,
      results: FAKE_DLP.snapshots,
    };
    await delay(1000);
    return res;
  }

  const res = await request.get<never, IPaginatedResponse<IDLPSnapshot>>(
    `${endpoint.WORKSPACES}/${workspaceId}/dlp/drives/${driveId}/snapshots`
  );
  return res;
}

async function deleteDLPSnapshot(
  workspaceId: string,
  driveId: number,
  snapshotId: string
): Promise<undefined> {
  if (IS_FAKE) {
    console.log("calling deleteDLPSnapshot", workspaceId, driveId, snapshotId);
    await delay(1000);
    return;
  }

  await request.delete<never, undefined>(
    `${endpoint.WORKSPACES}/${workspaceId}/dlp/drives/${driveId}/snapshots/${snapshotId}`
  );
  return;
}

async function updateDLPSchedule(
  workspaceId: string,
  driveId: number,
  data: IDLPSchedule
): Promise<{ success: boolean }> {
  if (IS_FAKE) {
    console.log("calling updateDLPSchedule", workspaceId, driveId, data);
    await delay(1000);
    return { success: true };
  }

  await request.put<never, undefined>(
    `${endpoint.WORKSPACES}/${workspaceId}/dlp/drives/${driveId}/schedule`,
    data
  );
  return { success: true };
}

async function bulkUpdateDLPSchedule(
  workspaceId: string,
  driveIds: number[],
  data: IDLPSchedule
): Promise<{ success: boolean }> {
  if (IS_FAKE) {
    console.log("calling bulkUpdateDLPSchedule", workspaceId, driveIds, data);
    await delay(1000);
    return { success: true };
  }

  await request.post<never, undefined>(
    `${endpoint.WORKSPACES}/${workspaceId}/dlp/drives/schedules`,
    { ...data, asset_ids: driveIds }
  );
  return { success: true };
}

async function removeDLPSchedule(
  workspaceId: string,
  driveId: number
): Promise<{ success: boolean }> {
  if (IS_FAKE) {
    console.log("calling removeDLPSchedule", workspaceId, driveId);
    await delay(1000);
    return { success: true };
  }

  await request.delete<never, undefined>(
    `${endpoint.WORKSPACES}/${workspaceId}/dlp/drives/${driveId}/schedule`
  );
  return { success: true };
}

async function bulkRemoveDLPSchedule(
  workspaceId: string,
  driveIds: number[]
): Promise<{ success: boolean }> {
  if (IS_FAKE) {
    console.log("calling bulkRemoveDLPSchedule", workspaceId, driveIds);
    await delay(1000);
    return { success: true };
  }

  await request.post<never, undefined>(
    `${endpoint.WORKSPACES}/${workspaceId}/dlp/drives/schedules/stop`,
    { asset_ids: driveIds }
  );
  return { success: true };
}

async function restoreDLPSnapshot(
  workspaceId: string,
  driveId: number,
  snapshotId: string
): Promise<{ success: boolean }> {
  if (IS_FAKE) {
    console.log("calling restoreDLPSnapshot", workspaceId, driveId, snapshotId);
    await delay(1000);
    return { success: true };
  }

  await request.post<never, undefined>(
    `${endpoint.WORKSPACES}/${workspaceId}/dlp/drives/${driveId}/snapshots/${snapshotId}`
  );
  return { success: true };
}

const dlpService = {
  listDLPDrives,
  listDLPLog,
  listDLPSnapshot,
  getDLPSchedule,
  updateDLPSchedule,
  bulkUpdateDLPSchedule,
  removeDLPSchedule,
  bulkRemoveDLPSchedule,
  restoreDLPSnapshot,
  deleteDLPSnapshot,
};

export default dlpService;
