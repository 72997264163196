// Libraries
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";
import { Tooltip } from "@lockerpm/design";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// General
import { pathname } from "#src/config/pathname";
import { useAppSelector } from "#src/store/hooks";

// Components
import { Button } from "#src/common/system/Button";
import { ContentSection, StickySection } from "#src/layouts/content";
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import { TrustCenterPlanValue } from "#src/config/filter/trustCenter/value";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

// Children
import TrustCenterUpdateFilter from "#src/components/trustCenter/update/UpdateTable/Filter";
import TrustCenterUpdateTableGrid from "#src/components/trustCenter/update/UpdateTable/Table";

const TrustCenterUpdates = () => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "update" });

  const { workspaceId } = useParams<"workspaceId">();

  const trustCenterPlan = useAppSelector((state) => state.trustCenter.plan);

  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const fetchParams = useMemo<
    Parameters<typeof trustCenterServices.list_trust_center_update_no_paging>
  >(
    () => [workspaceId ?? "", { q: searchKeyword }],
    [workspaceId, searchKeyword]
  );

  const {
    list: updateList,
    isLoading,
    updateData: updateUpdateList,
  } = useFetchNoPaging(
    trustCenterServices.list_trust_center_update_no_paging,
    fetchParams
  );

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  const isDisabledAddButton =
    updateList === null ||
    (trustCenterPlan === TrustCenterPlanValue.FREE && updateList.length >= 10);

  return workspaceId ? (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        {currentWorkspace?.role === WorkspaceMemberRoleValue.ADMIN ||
        currentWorkspace?.role === WorkspaceMemberRoleValue.OWNER ? (
          <Tooltip
            title={
              isDisabledAddButton
                ? t("errorMessage.updateAmountLimitReached")
                : null
            }
          >
            <Link
              to={generatePath(pathname.ADD_TRUST_CENTER_UPDATE, {
                workspaceId,
              })}
            >
              <Button size="large" disabled={isDisabledAddButton}>
                <AddLine />
                {t("button.newUpdate")}
              </Button>
            </Link>
          </Tooltip>
        ) : null}
      </StickySection>
      <ContentSection>
        <TrustCenterUpdateFilter
          searchKeyword={searchKeyword}
          onChangeSearchKeyword={onChangeSearchKeyword}
        />
        <TrustCenterUpdateTableGrid
          workspaceId={workspaceId}
          updateList={updateList}
          isLoading={isLoading}
          onRefresh={() => {
            updateUpdateList();
          }}
        />
      </ContentSection>
    </>
  ) : null;
};

export default TrustCenterUpdates
