import { ObjectImage } from "#src/common/system/Object";
import { IWorkspaceItem } from "#src/services/workspace";
import { Radio } from "@lockerpm/design";
import { type DOMAttributes, type SVGProps } from "react";

const tailwindForNavItem =
  "w-full rounded-md transition-all text-sm font-semibold text-gray-v2-700 hover:bg-gray-v2-50 hover:text-gray-v2-800";

export const WorkspaceItemWrapper = ({
  workspace,
  selected,
  onClick,
}: {
  workspace: IWorkspaceItem;
  selected: boolean;
  onClick?: DOMAttributes<HTMLLabelElement>["onClick"];
}) => {
  return (
    <label
      className={
        "p-1.5 flex justify-between items-start gap-2 " +
        (onClick ? "cursor-pointer " : "") +
        tailwindForNavItem
      }
      onClick={onClick}
    >
      <div className="flex items-center gap-2">
        <ObjectImage data={workspace.logo} className="h-10 w-10 min-w-10" />
        <span className="text-sm font-semibold text-gray-v2-900 line-clamp-2 text-left">
          {workspace.short_name ? workspace.short_name : workspace.name}
        </span>
      </div>
      <Radio checked={selected} />
    </label>
  );
};

export const MenuItemWrapper = ({
  title,
  icon,
  onClick,
}: {
  title: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  onClick?: DOMAttributes<HTMLButtonElement>["onClick"];
}) => {
  return (
    <button
      className={"flex items-center gap-2 p-2 " + tailwindForNavItem}
      onClick={onClick}
    >
      {icon({ className: "h-5 w-5 min-w-5 text-gray-v2" })}
      {title}
    </button>
  );
};
