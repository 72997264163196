// Libraries
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import i18next from "i18next";
import { AxiosError } from "axios";
import { z } from "zod";
import { Tooltip } from "@lockerpm/design";

// Resources
import { ReactComponent as CornerLeftUpLine } from "#src/assets/images/icons/corner-left-up-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import usFlag from "#src/assets/images/flags/us.png";
import vnFlag from "#src/assets/images/flags/vn.png";

// General
import { pathname } from "#src/config/pathname";
import { useAppSelector } from "#src/store/hooks";

// Components
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/common/system/Button";
import {
  type FormErrorItem,
  InputField,
} from "#src/common/helper/wrapper/InputField";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";
import { Flag } from "#src/common/Flag";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import type { ITrustCenterUpdateItem } from "#src/services/trustCenter/update";
import { TrustCenterPlanValue } from "#src/config/filter/trustCenter/value";

const EditTrustCenterUpdate = () => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "update.editUpdate",
  });

  const locale = i18next.language;

  const navigate = useNavigate();

  const trustCenterPlan = useAppSelector((state) => state.trustCenter.plan);

  const { workspaceId, update: updateId } = useParams<"workspaceId" | "update">();

  const [thisUpdate, setThisUpdate] = useState<ITrustCenterUpdateItem | null>(null);
  const [updateDetail, setUpdateDetail] = useState<
    { lang: string; question: string; answer: string }[]
  >([{ lang: locale, question: "", answer: "" }]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorObj, setErrorObj] = useState<{
    question: FormErrorItem;
    answer: FormErrorItem;
  }>({ question: undefined, answer: undefined });

  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    const fetchUpdate = async () => {
      if (workspaceId && updateId) {
        setLoading(true);
        trustCenterServices
          .retrieve_trust_center_update(workspaceId, updateId)
          .then((response) => {
            setThisUpdate(response);
            setUpdateDetail(response.details);
            setLoading(false);
          })
          .catch(apiErrorHandler);
      }
    };

    fetchUpdate();
  }, [workspaceId, updateId]);

  if (!workspaceId || workspaceId === "null") {
    return <NoResultsState />;
  }

  const onSaveUpdate = () => {
    if (workspaceId && updateId && thisUpdate) {
      setPending(true);
      trustCenterServices
        .update_trust_center_update(workspaceId, updateId, {
          details: updateDetail,
          status: thisUpdate.status,
        })
        .then(() => {
          navigate(
            generatePath(pathname.TRUST_CENTER_UPDATE, {
              workspaceId,
            })
          );
        })
        .catch((error) => {
          if (
            error instanceof AxiosError &&
            error.response &&
            error.response.status === 400
          ) {
            const getErrorData = parseBadRequest(
              error.response.data,
              z.object({
                details: z.array(
                  z.object({
                    question: z.optional(z.array(z.string())),
                    answer: z.optional(z.array(z.string())),
                  })
                ),
              })
            );
            setErrorObj((prev) => ({
              ...prev,
              question:
                getErrorData.details.details.length > 0 &&
                getErrorData.details.details[0].question
                  ? () =>
                      (getErrorData.details.details[0].question as string[])[0]
                  : undefined,
              answer:
                getErrorData.details.details.length > 0 &&
                getErrorData.details.details[0].answer
                  ? () =>
                      (getErrorData.details.details[0].answer as string[])[0]
                  : undefined,
            }));
          } else {
            apiErrorHandler(error, {
              toastMessage: t("notification.createUpdate.fail"),
            });
          }
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  const onClickAddAnotherLang = () => {
    if (updateDetail.every((item) => item.lang !== "en")) {
      setUpdateDetail((prev) => [
        ...prev,
        { lang: "en", question: "", answer: "" },
      ]);
      return;
    }
    if (updateDetail.every((item) => item.lang !== "vi")) {
      setUpdateDetail((prev) => [
        ...prev,
        { lang: "vi", question: "", answer: "" },
      ]);
      return;
    }
  };

  return thisUpdate === null || isLoading ? (
    <LoadingState />
  ) : (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        <div className="flex gap-1">
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              navigate(
                generatePath(pathname.TRUST_CENTER_UPDATE, {
                  workspaceId,
                })
              );
            }}
          >
            {t("button.cancel")}
          </Button>
          <Tooltip
            title={
              updateDetail.some(
                (updateLangItem) =>
                  updateLangItem.answer.length === 0 ||
                  updateLangItem.question.length === 0
              ) ? (
                <div className="font-medium-12 whitespace-nowrap">
                  {t("button.disabledTooltip")}
                </div>
              ) : null
            }
            placement="top"
          >
            <div>
              <Button size="large" pending={pending} onClick={onSaveUpdate}>
                <CornerLeftUpLine />
                {t("button.saveChanges")}
              </Button>
            </div>
          </Tooltip>
        </div>
      </StickySection>
      <ContentSection className="gap-12">
        <div className="flex flex-col gap-6">
          {updateDetail.map((updateLangItem) => {
            return (
              <div
                className="flex flex-col gap-6"
                key={`updateByLanguage-${updateLangItem.lang}`}
              >
                {/* h-6 to fit the close icon so when adding new language this div's height won't change. */}
                <div className="flex justify-between items-center h-6">
                  <p className="text-hard-grey flex gap-2">
                    {updateLangItem.lang === "en" ? (
                      <>
                        <Flag pngProp={usFlag} />
                        {t("language.english")}
                      </>
                    ) : updateLangItem.lang === "vi" ? (
                      <>
                        <Flag pngProp={vnFlag} />
                        {t("language.vietnamese")}
                      </>
                    ) : null}
                  </p>
                  {updateDetail.length > 1 ? (
                    <button
                      className="h-6 w-6 text-hard-grey"
                      onClick={() => {
                        setUpdateDetail((prev) =>
                          prev.filter((item) => item.lang !== updateLangItem.lang)
                        );
                      }}
                    >
                      <CloseLine />
                    </button>
                  ) : null}
                </div>
                <div className="flex flex-col gap-6 border border-light-grey p-6 rounded-md">
                  <InputField
                    title={t("question.title")}
                    placeholder={t("question.placeholder")}
                    value={updateLangItem.question}
                    onChangeValue={(value) => {
                      setUpdateDetail((prev) =>
                        prev.map((item) =>
                          item.lang === updateLangItem.lang
                            ? { ...item, question: value }
                            : item
                        )
                      );
                    }}
                    error={errorObj.question}
                    onChangeError={(value) => {
                      setErrorObj((prev) => ({ ...prev, question: value }));
                    }}
                    validation={{ required: true, maxLength: 1024 }}
                  />
                  <InputField
                    title={t("answer.title")}
                    type="markdown"
                    placeholder={t("answer.placeholder")}
                    value={updateLangItem.answer}
                    onChangeValue={(value) => {
                      setUpdateDetail((prev) =>
                        prev.map((item) =>
                          item.lang === updateLangItem.lang
                            ? { ...item, answer: value }
                            : item
                        )
                      );
                    }}
                    error={errorObj.answer}
                    onChangeError={(value) => {
                      setErrorObj((prev) => ({ ...prev, answer: value }));
                    }}
                    validation={{
                      required: true,
                      maxLength:
                        trustCenterPlan === TrustCenterPlanValue.ENTERPRISE
                          ? 4096
                          : 2048,
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {/* If every languages are used, hide the button. */}
        {["en", "vi"].every((lang) =>
          updateDetail.some((item) => item.lang === lang)
        ) ? null : (
          <Button variant="text" size="large" onClick={onClickAddAnotherLang}>
            <AddLine />
            {t("button.addAnotherLanguage")}
          </Button>
        )}
      </ContentSection>
    </>
  );
};

export default EditTrustCenterUpdate;
