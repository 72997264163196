import { Outlet } from "react-router";
import { LayoutV2Sidebar } from "./sidebar";

export const LayoutV2 = () => {
  // const [openSidebar, setOpenSidebar] = useState<boolean>(true);

  return (
    // z-index set to 0 to make sure Modal overlay can do its job.
    <div className="h-screen w-screen flex v2 overflow-hidden relative z-0">
      <LayoutV2Sidebar
        openSidebar={true}
        className="flex flex-col justify-between z-10 ease-in-out duration-500 border-r border-gray-v2-200"
      />
      {/* With new layout, we don't have a topbar, since breadcrumb should be integrated into each page,
      and buttons like translate and logout are moved to a list in the sidebar. */}
      <div className="flex-1 overflow-x-auto z-0">
        <Outlet />
      </div>
    </div>
  );
};
