// Libraries
import { useEffect, useMemo, useState } from "react";
import {
  createSearchParams,
  generatePath,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tabs } from "@lockerpm/design";

// Resources
import { ReactComponent as GitRepositoryLine } from "#src/assets/images/icons/git-repository-line.svg";
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// General
import { pathname } from "#src/config/pathname";
import type { IFilterItem } from "#src/@types/common";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import TabButtonLabel from "#src/common/helper/antdProps/Tabs/TabButtonLabel";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import useFetch from "#src/hooks/useFetch";

// API-related
import workspaceServices, {
  type IWorkspaceItem,
} from "#src/services/workspace";
import paymentService from "#src/services/payment";
import {
  contractStatusItems,
  paymentStatusItems,
} from "#src/config/filter/workspace";
import { useSyncSearchParam } from "#src/hooks/useSyncSearchParam";
import LoadingState from "#src/common/system/LoadingState";

// Children
import BillingOverviewCard from "#src/components/workspace/billing/OverviewCard";
import BillingSolutionTab from "#src/components/workspace/billing/tabs/Solution";
import BillingOrderTab from "#src/components/workspace/billing/tabs/Order";
import BillingContractTab from "#src/components/workspace/billing/tabs/Contract";
import BillingConfigTab from "#src/components/workspace/billing/tabs/Config";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

export enum BillingTabEnum {
  SOLUTION = "BILLING_TAB_SOLUTION",
  ORDER = "BILLING_TAB_ORDER",
  CONTRACT = "BILLING_TAB_CONTRACT",
  CONFIG = "BILLING_TAB_CONFIG",
}

const WorkspaceBillingPage = () => {
  const { t } = useTranslation("workspace", { keyPrefix: "billing" });

  const { workspaceId } = useParams<"workspaceId">();
  const [searchParams] = useSearchParams();

  const initTab = useMemo(() => searchParams.get("tab"), [searchParams]);

  const [activeTab, setActiveTab] = useState<BillingTabEnum>(
    initTab &&
      Object.values(BillingTabEnum)
        .map((item) => item.toString())
        .includes(initTab)
      ? (initTab as BillingTabEnum)
      : BillingTabEnum.SOLUTION
  );
  const [currentWorkspace, setCurrentWorkspace] =
    useState<IWorkspaceItem | null>(null);

  const [paymentStatus, setPaymentStatus] = useState<IFilterItem | null>(null);
  const [contractStatus, setContractStatus] = useState<IFilterItem | null>(
    null
  );

  const onChangeTab = (key: BillingTabEnum) => {
    setActiveTab(key);
    setPaymentStatus(null);
    setContractStatus(null);
  };

  const onChangePaymentStatusSelection = (selected: typeof paymentStatus) => {
    setPaymentStatus(selected);
  };

  const onChangeContractStatusSelection = (selected: typeof contractStatus) => {
    setContractStatus(selected);
  };

  const fetchOverviewParams = useMemo<
    Parameters<typeof paymentService.retrieve_workspace_payment_overview>
  >(() => [workspaceId ?? ""], [workspaceId]);

  const { result: paymentOverview } = useFetch(
    paymentService.retrieve_workspace_payment_overview,
    fetchOverviewParams
  );

  useSyncSearchParam("tab", activeTab);

  const tabList = workspaceId
    ? [
        {
          key: BillingTabEnum.SOLUTION,
          label: <TabButtonLabel name={t("tab.solution.label")} />,
          children: (
            <BillingSolutionTab
              workspaceId={workspaceId}
              currentWorkspace={currentWorkspace}
            />
          ),
        },
        {
          key: BillingTabEnum.ORDER,
          label: <TabButtonLabel name={t("tab.order.label")} />,
          children: (
            <BillingOrderTab
              workspaceId={workspaceId}
              status={paymentStatus}
              onChangeStatusSelection={onChangePaymentStatusSelection}
            />
          ),
        },
        {
          key: BillingTabEnum.CONTRACT,
          label: <TabButtonLabel name={t("tab.contract.label")} />,
          children: (
            <BillingContractTab
              workspaceId={workspaceId}
              status={contractStatus}
              onChangeStatusSelection={onChangeContractStatusSelection}
            />
          ),
        },
        {
          key: BillingTabEnum.CONFIG,
          label: <TabButtonLabel name={t("tab.config.label")} />,
          children: <BillingConfigTab workspaceId={workspaceId} />,
        },
      ]
    : [];

  useEffect(() => {
    if (workspaceId) {
      workspaceServices
        .retrieve_workspace(workspaceId)
        .then((response) => {
          setCurrentWorkspace(response);
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId]);

  return currentWorkspace === null ? (
    <LoadingState />
  ) : currentWorkspace.role === WorkspaceMemberRoleValue.OWNER ? (
    <>
      <StickySection>
        <div className="flex flex-col gap-3">
          <h1>{t("title")}</h1>
          <div className="flex items-center gap-2">
            <GitRepositoryLine className="h-5 w-5 fill-primary" />
            <a>{t("understandOurPolicies")}</a>
          </div>
        </div>
      </StickySection>
      <ContentSection>
        <div className="flex gap-6">
          <BillingOverviewCard
            title={
              <>
                {t("overviewCard.renewService.title", {
                  count: paymentOverview?.expiring_plans ?? 0,
                })}
              </>
            }
            content={
              paymentOverview && paymentOverview.expiring_plans ? (
                <Link
                  className="my-2 flex items-center gap-2 no-underline"
                  to={{
                    pathname: generatePath(pathname.WORKSPACE_BILLING_RENEW, {
                      workspaceId,
                    }),
                    search: createSearchParams({
                      expiring: "1",
                    }).toString(),
                  }}
                >
                  <span className="font-medium-14">
                    {t("overviewCard.renewService.link")}
                  </span>
                  <ArrowRightUpLine className="h-4 w-4" />
                </Link>
              ) : null
            }
            value={
              paymentOverview
                ? paymentOverview.expiring_plans.toString().padStart(2, "0")
                : "00"
            }
            bgTwColor={"bg-support-pink-pastel"}
            markerTwColor={"bg-severity-high"}
          />
          <BillingOverviewCard
            title={
              <>
                {t("overviewCard.paymentNeeded.title", {
                  count: paymentOverview?.pending_payments ?? 0,
                })}
              </>
            }
            content={
              paymentOverview && paymentOverview.pending_payments ? (
                <button
                  className="my-2 flex items-center gap-2 text-primary"
                  onClick={() => {
                    setActiveTab(BillingTabEnum.ORDER);
                    // set the filter to "processing"
                    setPaymentStatus(paymentStatusItems[2]);
                  }}
                >
                  <span className="font-medium-14">
                    {t("overviewCard.paymentNeeded.link")}
                  </span>
                  <ArrowRightUpLine className="h-4 w-4" />
                </button>
              ) : null
            }
            value={
              paymentOverview
                ? paymentOverview.pending_payments.toString().padStart(2, "0")
                : "00"
            }
            bgTwColor={"bg-support-purple-pastel"}
            markerTwColor={"bg-support-purple"}
          />
          <BillingOverviewCard
            title={
              <>
                {t("overviewCard.signingNeeded.title", {
                  count: paymentOverview?.pending_contracts ?? 0,
                })}
              </>
            }
            content={
              paymentOverview && paymentOverview.pending_contracts ? (
                <button
                  className="my-2 flex items-center gap-2 text-primary"
                  onClick={() => {
                    setActiveTab(BillingTabEnum.CONTRACT);
                    // set the filter to "not_signed"
                    setContractStatus(contractStatusItems[0]);
                  }}
                >
                  <span className="font-medium-14">
                    {t("overviewCard.signingNeeded.link")}
                  </span>
                  <ArrowRightUpLine className="h-4 w-4" />
                </button>
              ) : null
            }
            value={
              paymentOverview
                ? paymentOverview.pending_contracts.toString().padStart(2, "0")
                : "00"
            }
            bgTwColor={"bg-support-seafoam-pastel"}
            markerTwColor={"bg-support-seafoam"}
          />
        </div>
        <Tabs
          items={tabList}
          activeKey={activeTab}
          onChange={onChangeTab}
          destroyInactiveTabPane
        />
      </ContentSection>
    </>
  ) : null;
};

export default WorkspaceBillingPage;
