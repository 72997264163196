// Libraries
import { useParams } from "react-router-dom";
import {
  type ReactNode,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Tabs } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { createSearchParams, useSearchParams } from "react-router-dom";

// Resources
import { ReactComponent as StopCircleLine } from "#src/assets/images/icons/stop-circle-line.svg";
import { ReactComponent as QrScanLine } from "#src/assets/images/icons/qr-scan-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import TabButtonLabel from "#src/common/helper/antdProps/Tabs/TabButtonLabel";
import { ContentSection, StickySection } from "#src/layouts/content";
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import { useSyncSearchParam } from "#src/hooks/useSyncSearchParam";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// Children
import { ScanDetailsTabEnum } from "#src/components/scans/details/enums";
import ScanDetailsScannedAssets from "#src/components/scans/details/Tabs/ScannedAssetsTab";
import scansServices, { type IScanDetailItem } from "#src/services/scans";
import ScanDetailsOverview from "#src/components/scans/details/Tabs/OverviewTab";
import ScanDetailsBasicInfo from "#src/components/scans/details/BasicInfo";
import ScanDetailsVulnerabilities from "#src/components/scans/details/Tabs/VulnerabilitiesTab";
import ScanDetailsPorts from "#src/components/scans/details/Tabs/PortTab";
import ScanDetailsApplications from "#src/components/scans/details/Tabs/ApplicationTab";
import ScanDetailsCertificate from "#src/components/scans/details/Tabs/CertificateTab";
import ScanDetailsSubdomains from "#src/components/scans/details/Tabs/SubdomainTab";
// import ScanDetailsActivity from "#src/components/scans/all-scans/ScanTable/details/Tabs/ActivityTab";

const ScanDetails = () => {
  const { workspaceId, scan: scanId } = useParams<"workspaceId" | "scan">();

  const { t } = useTranslation("scans", { keyPrefix: "page.scanDetails" });
  const [searchParams, setSearchParams] = useSearchParams();

  const initTab = useMemo(() => searchParams.get("tab"), [searchParams]);

  const [scanDetails, setScanDetails] = useState<IScanDetailItem | null>(null);

  const [isLoading, setLoading] = useState<boolean>(false);

  // Initial state is the first key on tabList. Should be careful when changing.
  const [activeTab, setActiveTab] = useState<string>(
    initTab ? initTab : ScanDetailsTabEnum.OVERVIEW
  );

  const initSeverity = useMemo(
    () => searchParams.get("severity"),
    [searchParams]
  );

  const onChangeTab = (key: string) => {
    setActiveTab(key);

    // remove "severity" param each time the tab's changed, since it only exist on vuln tab
    if (key !== ScanDetailsTabEnum.VULNERABILITIES) {
      setSearchParams(createSearchParams({ tab: key }));
    }
  };

  const fetchScanDetails = useCallback(() => {
    if (workspaceId && scanId) {
      setLoading(true);
      scansServices
        .get_detail_scan(workspaceId, scanId)
        .then((response) => {
          setScanDetails(response);
          setLoading(false);
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId, scanId]);

  const tabList: { key: string; label: ReactNode; children: ReactNode }[] =
    scanDetails && workspaceId
      ? [
          {
            key: ScanDetailsTabEnum.OVERVIEW,
            label: <TabButtonLabel name={t("tab.overview.title")} />,
            children: (
              <ScanDetailsOverview
                scanDetails={scanDetails}
                onChangeTab={onChangeTab}
              />
            ),
          },
          {
            key: ScanDetailsTabEnum.SCANNED_ASSETS,
            label: <TabButtonLabel name={t("tab.scannedAssets.title")} />,
            children: (
              <ScanDetailsScannedAssets
                workspaceId={workspaceId}
                scanId={scanDetails.id}
                onChangeTab={onChangeTab}
              />
            ),
          },
          {
            key: ScanDetailsTabEnum.VULNERABILITIES,
            label: (
              <TabButtonLabel
                name={t("tab.vulnerabilities.title")}
                count={scanDetails.vulnerabilities.number_issues}
              />
            ),
            children: (
              <ScanDetailsVulnerabilities
                workspaceId={workspaceId}
                scanId={scanDetails.id}
                refreshScanDetails={fetchScanDetails}
                initSeverity={initSeverity}
                scannedAssetItems={scanDetails.scanned_assets.map((item) => ({
                  key: item.id.toString(),
                  value: item.id.toString(),
                  getLabel: () => item.address,
                }))}
              />
            ),
          },
          {
            key: ScanDetailsTabEnum.PORTS,
            label: <TabButtonLabel name={t("tab.ports.title")} />,
            children:
              scanDetails.scan_profile.assets.length > 0 ? (
                <ScanDetailsPorts
                  workspaceId={workspaceId}
                  scanId={scanDetails.id}
                  domainId={scanDetails.scan_profile.assets[0].asset_id}
                />
              ) : (
                <NoResultsState />
              ),
          },
          {
            key: ScanDetailsTabEnum.APPLICATIONS,
            label: <TabButtonLabel name={t("tab.applications.title")} />,
            children:
              scanDetails.scan_profile.assets.length > 0 ? (
                <ScanDetailsApplications
                  workspaceId={workspaceId}
                  scanId={scanDetails.id}
                  domainId={scanDetails.scan_profile.assets[0].asset_id}
                />
              ) : (
                <NoResultsState />
              ),
          },
          {
            key: ScanDetailsTabEnum.CERTIFICATES,
            label: <TabButtonLabel name={t("tab.certificates.title")} />,
            children:
              scanDetails.scan_profile.assets.length > 0 ? (
                <ScanDetailsCertificate
                  workspaceId={workspaceId}
                  scanId={scanDetails.id}
                  domainId={scanDetails.scan_profile.assets[0].asset_id}
                />
              ) : (
                <NoResultsState />
              ),
          },
          {
            key: ScanDetailsTabEnum.SUBDOMAINS,
            label: <TabButtonLabel name={t("tab.subdomains.title")} />,
            children: (
              <ScanDetailsSubdomains
                workspaceId={workspaceId}
                scanId={scanDetails.id}
                domainId={scanDetails.scan_profile.assets[0].asset_id}
              />
            ),
          },
          // {
          //   key: ScanDetailsTabEnum.ACTIVITY,
          //   label: <TabButtonLabel name={t("tab.activity.title")} />,
          //   children: <ScanDetailsActivity />,
          // },
        ]
      : [];

  const onReScan = () => {
    if (workspaceId && scanId && !isNaN(+scanId) && scanDetails) {
      scansServices
        .create_new_scan(workspaceId, {
          domains: scanDetails.scan_profile.assets.map((domain) => ({
            domain_id: +domain.asset_id,
            include_subdomains: domain.include_subdomains,
          })),
          schedule: scanDetails.scan_profile.schedule,
          activated_time:
            scanDetails.scan_profile.activated_time || dayjs().unix(),
          timezone: scanDetails.scan_profile.timezone,
          duration_type: scanDetails.scan_profile.duration_type,
          repeat_number: scanDetails.scan_profile.repeat_number,
          days: scanDetails.scan_profile.days,
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.rescan.fail"),
          });
        });
    }
  };

  const onDeleteScan = () => {
    if (workspaceId && scanId && !isNaN(+scanId)) {
      scansServices
        .delete_scan(workspaceId, +scanId)
        .then(() => {
          createToast({
            type: "success",
            message: t("notification.delete.success"),
          });
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.delete.fail"),
          });
        });
    }
  };

  const onStopScan = () => {
    if (workspaceId && scanId && !isNaN(+scanId)) {
      scansServices
        .stop_scan(workspaceId, +scanId)
        .then((response) => {
          if (response.success) {
            createToast({
              type: "success",
              message: t("notification.stop.success"),
            });
          }
        })
        .catch((error) => {
          apiErrorHandler(error, { toastMessage: t("notification.stop.fail") });
        });
    }
  };

  useSyncSearchParam("tab", activeTab);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchScanDetails();
    }

    return () => {
      ignore = true;
    };
  }, [fetchScanDetails]);

  return workspaceId ? (
    scanDetails === null ? (
      isLoading ? (
        <LoadingState />
      ) : (
        <NoResultsState />
      )
    ) : (
      <>
        <StickySection>
          <div className="flex items-center justify-between gap-3 w-full">
            <h1>
              {t("title")}{" "}
              <span className="text-primary">
                {scanDetails.scanned_assets.length > 0
                  ? scanDetails.scanned_assets[0].name !==
                    scanDetails.scanned_assets[0].address
                    ? scanDetails.scanned_assets[0].name +
                      " (" +
                      scanDetails.scanned_assets[0].address +
                      ")"
                    : scanDetails.scanned_assets[0].address
                  : "#" + scanDetails.id}
              </span>
            </h1>
            <div className="flex gap-1">
              {scanDetails.status === "running" ? (
                <Button variant="secondary" size="large" onClick={onStopScan}>
                  <StopCircleLine />
                  {t("button.stop")}
                </Button>
              ) : (scanDetails.status === "completed" &&
                  scanDetails.scan_profile.schedule) ||
                scanDetails.status === "stopped" ? (
                <Button variant="secondary" size="large" onClick={onReScan}>
                  <QrScanLine />
                  {t("button.reScan")}
                </Button>
              ) : null}
              <IconButton
                variant="warning"
                onClick={onDeleteScan}
                disabled={
                  scanDetails.status === "pending" ||
                  scanDetails.status === "running" ||
                  scanDetails.status === "stopping"
                }
              >
                <DeleteBin6Line />
              </IconButton>
            </div>
          </div>
        </StickySection>
        <ContentSection>
          <ScanDetailsBasicInfo
            workspaceId={workspaceId}
            scanDetails={scanDetails}
          />
          <Tabs
            items={tabList}
            activeKey={activeTab}
            onChange={onChangeTab}
            destroyInactiveTabPane
          />
        </ContentSection>
      </>
    )
  ) : null;
};

export default ScanDetails;
