export enum TrustCenterUpdateActionEnum {
  EDIT = "TRUST_CENTER_UPDATE_ACTION_ENUM_EDIT",
  DELETE = "TRUST_CENTER_UPDATE_ACTION_ENUM_DELETE",
  PUBLISH = "TRUST_CENTER_UPDATE_ACTION_ENUM_PUBLISH",
  DRAFT = "TRUST_CENTER_UPDATE_ACTION_ENUM_DRAFT",
}

export enum TrustCenterUpdateStatusValue {
  DRAFT = "draft",
  PUBLISHED = "published",
}
