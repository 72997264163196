import { ReactComponent as EmptyCloud } from "#src/assets/images/v2/empty-cloud.svg";
import { ReactComponent as BackgroundPatternGrid } from "#src/assets/images/v2/background-pattern-grid.svg";
import { ReactComponent as BackgroundPatternCircles } from "#src/assets/images/v2/background-pattern-circles.svg";
import { ReactNode } from "react";
import { SearchLg } from "@untitled-ui/icons-react";

interface IEmptyStateProps {
  title: string;
  supportingText?: string;
  type?: "featured-icon" | "illustration";
  buttons?: ReactNode;
}

/** A common empty state. Should be pretty easy to implement everywhere within our design system.
 * @param title string
 * @param supportingText string
 * @param type "featured-icon" | "illustration"
 * @param buttons ReactNode - technicallly here is where you should put actions for user, but you can put anything.
 */
export const EmptyStateV2 = ({
  title,
  supportingText,
  type = "illustration",
  buttons,
}: IEmptyStateProps) => {
  return (
    <div className="flex flex-col justify-center items-center gap-8 pt-12 pb-6 relative">
      {type === "illustration" ? (
        <BackgroundPatternGrid className="absolute -top-1/3 h-[30rem] w-[30rem]" />
      ) : (
        <BackgroundPatternCircles className="absolute -top-[10.55rem] h-[30rem] w-[30rem]" />
      )}
      <div className="flex flex-col items-center gap-5 z-10">
        {type === "illustration" ? (
          <EmptyCloud className="h-[8rem] w-[10.75rem]" />
        ) : (
          <div className="h-12 w-12 border border-gray-v2-200 shadow-xs rounded-lg bg-white flex items-center justify-center">
            <SearchLg className="h-6 w-6" />
          </div>
        )}
        <div className="flex flex-col gap-2 items-center text-center">
          <span className="text-lg font-semibold text-gray-v2-700">
            {title}
          </span>
          <span className="text-sm text-gray-v2">{supportingText}</span>
        </div>
      </div>
      <div className="flex gap-3">{buttons}</div>
    </div>
  );
};
