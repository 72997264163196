// Libraries
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router";

// General
import global from "#src/config/global";
import { pathname } from "#src/config/pathname";

// Components
import { TableHeader } from "#src/common/Table";
import type { ITableHeaderPropItem } from "#src/common/Table/TableHeader";
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { ITrustCenterUpdateItem } from "#src/services/trustCenter/update";
import trustCenterServices from "#src/services/trustCenter";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

// Children
import TrustCenterUpdateTableRow from "./TableRow";
import { trustCenterUpdateActionList } from "./actions";
import {
  TrustCenterUpdateActionEnum,
  TrustCenterUpdateStatusValue,
} from "../enum";

interface ITrustCenterUpdateTableGridProps {
  workspaceId: string;
  updateList: ITrustCenterUpdateItem[] | null;
  isLoading: boolean;
  onRefresh: () => void;
}

const TrustCenterUpdateTableGrid = ({
  workspaceId,
  updateList,
  isLoading,
  onRefresh,
}: ITrustCenterUpdateTableGridProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "update" });

  const navigate = useNavigate();

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.questions"), align: "left" },
    { name: t("headers.status"), align: "left" },
    { name: t("headers.actions"), align: "center" },
  ];

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  const getFilteredActionList = (data: ITrustCenterUpdateItem) => {
    if (
      currentWorkspace?.role !== WorkspaceMemberRoleValue.OWNER &&
      currentWorkspace?.role !== WorkspaceMemberRoleValue.ADMIN
    ) {
      return [];
    }
    if (data.status === TrustCenterUpdateStatusValue.DRAFT) {
      return trustCenterUpdateActionList.filter(
        (action) => action.key !== TrustCenterUpdateActionEnum.DRAFT
      );
    }
    if (data.status === TrustCenterUpdateStatusValue.PUBLISHED) {
      return trustCenterUpdateActionList.filter(
        (action) => action.key !== TrustCenterUpdateActionEnum.PUBLISH
      );
    }
    return [];
  };

  const onChangeStatus = (
    item: ITrustCenterUpdateItem,
    newStatus: TrustCenterUpdateStatusValue
  ) => {
    trustCenterServices
      .update_trust_center_update(workspaceId, item.id, {
        details: item.details,
        status: newStatus,
      })
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updateStatus.fail"),
        });
      });
  };

  const onDeleteQuestion = (item: ITrustCenterUpdateItem) => {
    trustCenterServices
      .delete_trust_center_update(workspaceId, item.id)
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteUpdate.fail"),
        });
      });
  };

  const onClickActionItems = (key: string, item: ITrustCenterUpdateItem) => {
    switch (key) {
      case TrustCenterUpdateActionEnum.EDIT:
        navigate(
          generatePath(pathname.EDIT_TRUST_CENTER_UPDATE, {
            workspaceId,
            update: item.id,
          })
        );
        return;
      case TrustCenterUpdateActionEnum.DRAFT:
        onChangeStatus(item, TrustCenterUpdateStatusValue.DRAFT);
        return;
      case TrustCenterUpdateActionEnum.PUBLISH:
        onChangeStatus(item, TrustCenterUpdateStatusValue.PUBLISHED);
        return;
      case TrustCenterUpdateActionEnum.DELETE:
        global.confirm(() => {
          onDeleteQuestion(item);
        });
        return;
    }
  };

  return (
    <>
      {isLoading || updateList === null ? (
        <LoadingState />
      ) : updateList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[6fr_2fr_1fr]">
          <TableHeader title="trustCenter-update" headerProps={headers} />
          {updateList.map((item) => (
            <TrustCenterUpdateTableRow
              key={`trustCenter/update-tr${item.id}`}
              workspaceId={workspaceId}
              data={item}
              actionList={getFilteredActionList(item)}
              onClickActionItems={onClickActionItems}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default TrustCenterUpdateTableGrid;
