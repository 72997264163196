import { Modal, Radio, Select, Popover, Input } from "@lockerpm/design";
import { ButtonV2 } from "#src/commonV2/Button";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DLPSchedulePeriod } from "#src/services/endpoint/dlp/enums";
import dlpService from "#src/services/endpoint/dlp";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import Loader from "#src/common/system/Loader";
import { createToast } from "#src/common/system/toasts";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { IDLPDrive } from "#src/services/endpoint/dlp/types";
import { DateTime } from "luxon";
import { IFilterItemV2 } from "#src/commonV2/Table/Filter/types";

type Props = {
  isOpen: boolean;
  workspaceId: string;
  driveIds: number[];
  closeModal: () => void;
  isSelectedDriveEnabled?: boolean;
  setDriveList: (value: SetStateAction<IDLPDrive[]>) => void;
  listFilter: IFilterItemV2 | null;
  reloadDriveList: () => void;
};

// Timezone data
const timezoneOptions = Intl.supportedValuesOf("timeZone").map((item) => {
  const offsetMinutes = DateTime.now().setZone(item).offset;
  const sign = offsetMinutes >= 0 ? "+" : "-";
  const absOffsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const absOffsetMinutes = Math.abs(offsetMinutes) % 60;

  const offset = `${sign}${String(absOffsetHours).padStart(2, "0")}:${String(absOffsetMinutes).padStart(2, "0")}`;

  return {
    label: `(UTC ${offset}) ${item}`,
    value: item,
    offset,
  };
});
const userTimezone = DateTime.local().zoneName;

const ScheduleBackupModal = (props: Props) => {
  const {
    isOpen,
    workspaceId,
    driveIds,
    closeModal,
    setDriveList,
    isSelectedDriveEnabled,
    listFilter,
    reloadDriveList,
  } = props;

  const { t } = useTranslation("dlp");
  const locale = i18next.language;

  // --------------- DATA ---------------

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [period, setPeriod] = useState<DLPSchedulePeriod>(
    DLPSchedulePeriod.DAILY
  );

  // Time selector
  const [hour, setHour] = useState<number>();
  const [hourIndicator, setHourIndicator] = useState<"AM" | "PM">("AM");
  const [dayInWeek, setDayInWeek] = useState<number>(0);
  const [dateInMonth, setDateInMonth] = useState<number>(1);
  const [repeatNumber, setRepeatNumber] = useState<number>(1);
  const [timezone, setTimezone] = useState<string>(userTimezone);

  // --------------- COMPUTED ---------------

  // Day in week picker options
  const daysInWeek = useMemo(() => {
    return Array.from({ length: 7 }, (_, i) => ({
      label: t("scheduleModal.dayInWeek.values", { returnObjects: true })[i],
      value: i,
    }));
  }, [t]);

  // Hour in day indicator picker options
  const hourIndicators = useMemo(
    () => [
      {
        value: "AM",
        label: renderLabel("AM"),
      },
      {
        value: "PM",
        label: renderLabel("PM"),
      },
    ],
    []
  );

  // Period picker options
  const periods = useMemo(() => {
    return Object.values(DLPSchedulePeriod).map((item) => ({
      value: item,
      label: renderLabel(t(`scheduleModal.period.${item}`)),
    }));
  }, [t]);

  // Repeat times picker options
  const recurOptions = useMemo(() => {
    return Array.from({ length: 3 }, (_, i) => ({
      label: `${i + 1} ${t("scheduleModal.recur.week", { count: i + 1 })}`,
      value: i + 1,
    }));
  }, [t]);

  // --------------- METHODS ---------------

  // Load schedule
  const loadSchedule = useCallback(async () => {
    // Don't load data if multiple drives are selected or selected drive does not have any schedule
    if (
      !workspaceId ||
      !isSelectedDriveEnabled ||
      driveIds.length === 0 ||
      driveIds.length > 1
    ) {
      setIsLoadingData(false);
      return;
    }

    try {
      const res = await dlpService.getDLPSchedule(workspaceId, driveIds[0]);
      setRepeatNumber(res.repeat_number);
      setTimezone(res.timezone);

      // Set day in week, return data is being converted to string -> need parse
      if (typeof res.days[0] === "string") {
        setDayInWeek(parseInt(res.days[0]));
      } else {
        setDayInWeek(res.days[0]);
      }

      // Parse time from timestamp (UTC)
      const timeObj = DateTime.fromSeconds(res.activated_time, {
        zone: res.timezone,
      });

      let _hour = timeObj.hour;
      let _indicator: "AM" | "PM" = "AM";

      if (_hour === 0) {
        _hour = 12;
        _indicator = "AM";
      } else if (_hour === 12) {
        _indicator = "PM";
      } else if (_hour > 12) {
        _hour -= 12;
        _indicator = "PM";
      }

      setHour(_hour);
      setHourIndicator(_indicator);
      setDateInMonth(timeObj.day);
    } catch (error) {
      apiErrorHandler(error);
    }

    setIsLoadingData(false);
  }, [workspaceId, isSelectedDriveEnabled, driveIds]);

  // Update schedule
  const updateSchedule = useCallback(async () => {
    if (!driveIds?.length || !hour) {
      return;
    }

    setIsUpdating(true);

    try {
      // Select the correct hour in day
      let _hour = hourIndicator === "PM" && hour < 12 ? hour + 12 : hour;
      if (hour === 12 && hourIndicator === "AM") {
        _hour = 0;
      }

      // Convert picked options to timestamp
      const activatedTime = DateTime.utc()
        .setZone(timezone)
        .set({
          day: dateInMonth,
          hour: _hour,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .toSeconds();

      await dlpService.bulkUpdateDLPSchedule(workspaceId, driveIds, {
        activated: true,
        duration_type: period,
        activated_time: activatedTime,
        days: [dayInWeek],
        repeat_number: repeatNumber,
        timezone,
      });

      // If current list only display drive without schedule -> reload list because data in current page will change
      if (listFilter?.key === "no") {
        reloadDriveList();
      } else {
        // Else change schedule status of drive in list local
        setDriveList((prev) =>
          prev.map((item) => {
            if (driveIds.includes(item.id)) {
              return {
                ...item,
                backup_enabled: true,
              };
            }
            return item;
          })
        );
      }

      closeModal();

      // Display toast
      createToast({
        type: "success",
        message: t("scheduleModal.updateSuccess"),
      });
    } catch (error) {
      apiErrorHandler(error);
    }
    setIsUpdating(false);
  }, [
    driveIds,
    hour,
    hourIndicator,
    timezone,
    dateInMonth,
    workspaceId,
    period,
    dayInWeek,
    repeatNumber,
    listFilter?.key,
    closeModal,
    t,
    reloadDriveList,
    setDriveList,
  ]);

  // --------------- EFFECTS ---------------

  useEffect(() => {
    let timeout: any = null;
    if (isOpen) {
      setIsLoadingData(true);
      timeout = setTimeout(loadSchedule, 200);
    }

    return () => {
      clearTimeout(timeout);
      setIsLoadingData(false);
      setIsUpdating(false);
      setHour(undefined);
      setHourIndicator("AM");
      setDayInWeek(0);
      setDateInMonth(1);
      setRepeatNumber(1);
      setTimezone(userTimezone);
    };
  }, [isOpen, loadSchedule]);

  // --------------- COMPONENTS ---------------

  // Date in month picker
  const renderDatePicker = useCallback(() => {
    const dates = Array.from({ length: 31 }, (_, i) => i + 1);
    return (
      <div
        className="py-2 px-3 rounded-xl flex flex-wrap gap-y-1"
        style={{ width: "19rem" }}
      >
        {dates.map((date) => (
          <div
            key={date}
            className={twMerge([
              "h-10 w-10 flex items-center justify-center rounded-full transition-colors cursor-pointer",
              date === dateInMonth
                ? "bg-brand-v2-600 hover:bg-brand-v2-600 text-white"
                : "hover:bg-gray-v2-50 text-gray-v2-700",
            ])}
            onClick={() => {
              setDateInMonth(date);
            }}
          >
            <p className="text-sm">{date}</p>
          </div>
        ))}
      </div>
    );
  }, [dateInMonth]);

  // Backup text
  const renderBackupText = useCallback(() => {
    if (!hour) {
      return null;
    }

    const timezoneOption = timezoneOptions.find(
      (item) => item.value === timezone
    );
    const timeText = `${hour}:00 ${hourIndicator} (UTC ${timezoneOption?.offset})`;

    // Weekly
    if (period === DLPSchedulePeriod.WEEKLY) {
      return (
        <p className="text-gray-v2-600 text-md">
          {t("scheduleModal.backupText.prefix")}
          <span className="font-semibold text-gray-v2-900">
            {timeText}
            {t("scheduleModal.backupText.weeklyPostfix", {
              day: daysInWeek.find((item) => item.value === dayInWeek)?.label,
            })}
            {recurOptions.find((item) => item.value === repeatNumber)?.label}
          </span>
        </p>
      );
    }

    // Monthly
    if (period === DLPSchedulePeriod.MONTHLY) {
      return (
        <>
          <p className="text-gray-v2-600 text-md">
            {t("scheduleModal.backupText.prefix")}
            <span className="font-semibold text-gray-v2-900">
              {timeText}
              {t("scheduleModal.backupText.monthlyPostfix", {
                date: renderDateWithSuffix(dateInMonth, locale),
              })}
            </span>
          </p>

          {/* Warning if month does not have date > 28 */}
          {dateInMonth > 28 && (
            <p className="text-gray-v2-600 text-sm italic mt-1">
              {t("scheduleModal.dateInMonth.will_use_last_day_warning", {
                date: renderDateWithSuffix(dateInMonth, locale),
              })}
            </p>
          )}
        </>
      );
    }

    // Daily
    return (
      <p className="text-gray-v2-600 text-md">
        {t("scheduleModal.backupText.prefixDaily")}
        <span className="font-semibold text-gray-v2-900">{timeText}</span>
      </p>
    );
  }, [
    dayInWeek,
    daysInWeek,
    hour,
    hourIndicator,
    period,
    recurOptions,
    repeatNumber,
    dateInMonth,
    t,
    locale,
    timezone,
  ]);

  // --------------- RENDER ---------------

  return (
    <Modal
      title={t("scheduleModal.title")}
      open={isOpen}
      footer={null}
      maskClosable={false}
      onCancel={closeModal}
      className="v2 max-w-[600px]"
      centered
      width="90%"
    >
      <div className="v2">
        {isLoadingData && (
          <div className="w-full flex justify-center py-6">
            <Loader baseSize={3} />
          </div>
        )}

        {!isLoadingData && (
          <div>
            <div className="flex flex-col sm:flex-row mb-5">
              {/* Period */}
              <div className="mb-6 sm:mb-0">
                <Radio.Group
                  value={period}
                  options={periods}
                  onChange={(e) => setPeriod(e.target.value)}
                  disabled={isUpdating}
                  className="gap-2 sm:gap-6 flex flex-col"
                />
              </div>
              {/* Period end */}

              <div className="flex items-start flex-wrap">
                {/* Day in week */}
                {period === DLPSchedulePeriod.WEEKLY && (
                  <div className="pl-6 mr-6 border-l border-gray-v2-200 mb-4 sm:mb-0">
                    <div className="mb-5 w-full">
                      <p className="text-sm font-medium text-gray-v2-700 mb-1.5">
                        {t("scheduleModal.recur.label")}
                      </p>
                      <Select
                        value={repeatNumber}
                        options={recurOptions}
                        onChange={(e) => setRepeatNumber(e)}
                        style={{ width: 162 }}
                        disabled={isUpdating}
                      />
                    </div>

                    <div>
                      <p className="text-sm font-medium text-gray-v2-700 mb-1.5">
                        {t("scheduleModal.dayInWeek.label")}
                      </p>
                      <Select
                        value={dayInWeek}
                        options={daysInWeek}
                        onChange={(e) => setDayInWeek(e)}
                        style={{ width: 162 }}
                        disabled={isUpdating}
                      />
                    </div>
                  </div>
                )}
                {/* Day in week end */}

                {/* Date in month */}
                {period === DLPSchedulePeriod.MONTHLY && (
                  <div className="pl-6 mr-6 border-l border-gray-v2-200 h-full mb-4 sm:mb-0">
                    <p className="text-sm font-medium text-gray-v2-700 mb-1.5">
                      {t("scheduleModal.dateInMonth.label")}
                    </p>
                    <Popover trigger="click" content={renderDatePicker}>
                      <div className="py-2.5 px-4 border border-gray-v2-300 rounded-lg cursor-pointer">
                        <p className="text-sm font-semibold text-gray-v2-700">
                          {dateInMonth}
                        </p>
                      </div>
                    </Popover>
                  </div>
                )}
                {/* Date in month end */}

                {/* Time in day */}
                <div className="flex items-start border-l border-gray-v2-200 pl-6 h-full">
                  <div className="flex items-center">
                    <div className="mr-6">
                      <p className="text-sm font-medium text-gray-v2-700 mb-1.5">
                        {t("scheduleModal.timeInDay.label")}
                      </p>
                      <Input
                        onChange={(e) => {
                          const textValue = e.target.value
                            .trim()
                            .replaceAll("+", "")
                            .replaceAll("-", "");
                          if (!textValue) {
                            setHour(undefined);
                            return;
                          }
                          if (textValue.length > 2) {
                            return;
                          }
                          if (textValue === "0" && hour === 1) {
                            return;
                          }
                          setHour(Math.min(parseInt(textValue), 12));
                        }}
                        onBlur={() => {
                          if (hour === 0) {
                            setHour(12);
                          }
                        }}
                        value={hour}
                        disabled={isUpdating}
                        placeholder="12"
                        className="h-16 rounded-xl text-display-sm font-medium py-3 px-2 w-20 text-center"
                        type="number"
                      />
                      <p className="text-sm text-gray-v2-600 mt-1.5">
                        {t("scheduleModal.timeInDay.hour")}
                      </p>
                    </div>

                    <Radio.Group
                      value={hourIndicator}
                      options={hourIndicators}
                      onChange={(e) => setHourIndicator(e.target.value)}
                      disabled={isUpdating}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    />
                  </div>
                </div>
                {/* Time in day end */}
              </div>
            </div>

            {/* Timezone */}
            <div className="mb-5">
              <p className="text-sm font-medium text-gray-v2-700 mb-1.5">
                {t("scheduleModal.timezone.label")}
              </p>
              <Select
                showSearch
                options={timezoneOptions}
                value={timezone}
                onChange={(e) => setTimezone(e)}
                className="w-full"
                disabled={isUpdating}
                filterOption={(input, option) =>
                  !!option?.label.toLowerCase().includes(input.toLowerCase())
                }
                virtual={false}
              />
            </div>
            {/* Timezone end */}

            {/* Back up desc */}
            {renderBackupText()}
            {/* Back up desc end */}
          </div>
        )}

        {/* Footer */}
        <div className="flex gap-2 justify-end mt-5">
          <ButtonV2
            variant="secondary"
            size="lg"
            color="gray"
            onClick={closeModal}
          >
            <p>{t("common.cancel")}</p>
          </ButtonV2>

          <ButtonV2
            variant="primary"
            size="lg"
            color="brand"
            pending={isUpdating}
            disabled={isLoadingData || !hour}
            onClick={updateSchedule}
          >
            <p>{t("common.schedule")}</p>
          </ButtonV2>
        </div>
        {/* Footer end */}
      </div>
    </Modal>
  );
};

export default ScheduleBackupModal;

// --------------------- SUPPORTING FUNCTIONS ---------------------

function renderDateWithSuffix(date: number, locale: string) {
  if (locale === "vi") {
    return `ngày ${date}`;
  }
  const suffixes = ["th", "st", "nd", "rd"];
  const value = date % 100;
  const suffix = suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
  return date + suffix;
}

function renderLabel(text: string) {
  return <p className="text-md font-medium text-gray-v2-700">{text}</p>;
}
