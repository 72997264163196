export const pathname = {
  HOME: "/",
  // Authenticate
  AUTHENTICATE: "/authenticate",
  LOGIN: "/login",

  // =============== Functional paths ===============

  // --------------- Asset ----------------
  DOMAINS: "/:workspaceId/assets/domains",
  DOMAIN_DETAILS: "/:workspaceId/assets/domains/:assetId",
  IP_ADDRESS: "/:workspaceId/assets/ip-address",
  IP_ADDRESS_DETAILS: "/:workspaceId/assets/ip-address/:assetId",
  CERTIFICATES: "/:workspaceId/assets/certificates",
  CERTIFICATE_DETAILS: "/:workspaceId/assets/certificates/:assetId",

  // --------------- Device ---------------
  CONTROL: "/:workspaceId/devices/control",

  // --------------- Vulnerability ---------------
  VULNERABILITIES: "/:workspaceId/vulnerabilities",

  // --------------- Scan ---------------
  ALL_SCANS: "/:workspaceId/scans/all-scans",
  SCAN_DETAILS: "/:workspaceId/scans/all-scans/details/:scan",
  SCHEDULES: "/:workspaceId/scans/schedules",

  // --------------- Pentest ---------------
  PENTEST: "/:workspaceId/pentest",
  PENTEST_DETAILS: "/:workspaceId/pentest/details/:pentest",
  ADD_PENTEST: "/:workspaceId/pentest/add",

  // --------------- Bugbounty ---------------
  BUG_BOUNTY_PROGRAMS: "/:workspaceId/bug-bounty",
  BUG_BOUNTY_DETAILS: "/:workspaceId/bug-bounty/details/:bugbounty",
  BUG_BOUNTY_DETAILS_INTEGRATION_SETTINGS:
    "/:workspaceId/bug-bounty/details/:bugbounty/integration/:integration",
  ADD_BUG_BOUNTY: "/:workspaceId/bug-bounty/add",

  // --------------- Data leak ---------------
  DATA_LEAK_OVERVIEW: "/:workspaceId/data-leak/overview",
  DATA_LEAK_FOUND_LEAKS: "/:workspaceId/data-leak/found-leaks",
  DATA_LEAK_ADD_KEYWORD: "/:workspaceId/data-leak/add-keyword",
  DATA_LEAK_ADD_KEYWORD_PAYMENT: "/:workspaceId/data-leak/add-keyword/payment",

  // --------------- Trust center ---------------
  // Policy
  TRUST_CENTER_POLICY: "/:workspaceId/trust-center/policy",
  ADD_TRUST_CENTER_POLICY: "/:workspaceId/trust-center/policy/add",
  EDIT_TRUST_CENTER_POLICY: "/:workspaceId/trust-center/policy/:policy/edit",

  TRUST_CENTER_COMPLIANCE: "/:workspaceId/trust-center/compliance",
  TRUST_CENTER_CONTROL: "/:workspaceId/trust-center/control",
  TRUST_CENTER_DOCUMENT: "/:workspaceId/trust-center/document",
  TRUST_CENTER_THIRD_PARTY: "/:workspaceId/trust-center/third-party",

  // FAQ
  TRUST_CENTER_FAQ: "/:workspaceId/trust-center/faq",
  ADD_TRUST_CENTER_FAQ: "/:workspaceId/trust-center/faq/add",
  EDIT_TRUST_CENTER_FAQ: "/:workspaceId/trust-center/faq/:faq/edit",

  // Update
  TRUST_CENTER_UPDATE: "/:workspaceId/trust-center/update",
  ADD_TRUST_CENTER_UPDATE: "/:workspaceId/trust-center/update/add",
  EDIT_TRUST_CENTER_UPDATE: "/:workspaceId/trust-center/update/:update/edit",

  TRUST_CENTER_CONFIG: "/:workspaceId/trust-center/config",
  TRUST_CENTER_REGISTER_PRIVATE_DOMAIN:
    "/:workspaceId/trust-center/register-private-domain",

  // --------------- Account settings ---------------
  ACCOUNT_SETTINGS: "/:workspaceId/account/account-settings",
  NOTIFICATION: "/:workspaceId/account/notification",

  // --------------- Workspace settings ---------------
  NEW_WORKSPACE: "/workspace/new",
  WORKSPACE_DETAILS: "/:workspaceId/workspace/details",
  WORKSPACE_MEMBERS: "/:workspaceId/workspace/members",
  WORKSPACE_ACTIVITY_LOGS: "/:workspaceId/workspace-activities",

  // Billing
  WORKSPACE_BILLING: "/:workspaceId/workspace/billing",
  WORKSPACE_BILLING_RENEW: "/:workspaceId/workspace/billing/renew",
  WORKSPACE_BILLING_UPGRADE_TRUST_CENTER:
    "/:workspaceId/workspace/billing/upgrade/trust_center",
  WORKSPACE_BILLING_UPGRADE_TC_CUSTOM_DOMAIN:
    "/:workspaceId/workspace/billing/upgrade/trust_center/custom_domain",

  // --------------- MSSP paths ---------------
  // Pentest
  MSSP_PENTEST: "/mssp/:workspaceId/pentest",
  MSSP_PENTEST_DETAILS: "/mssp/:workspaceId/pentest/details/:pentest",

  // Bug bounty
  MSSP_BUG_BOUNTY_PROGRAMS: "/mssp/:workspaceId/bug-bounty",
  MSSP_BUG_BOUNTY_DETAILS: "/mssp/:workspaceId/bug-bounty/details/:bugbounty",
  MSSP_BUG_BOUNTY_DETAILS_INTEGRATION_SETTINGS:
    "/mssp/:workspaceId/bug-bounty/details/:bugbounty/integration/:integration",

  // --------------- Solutions ---------------
  SOLUTIONS: "/:workspaceId/solutions",

  // --------------- Endpoint ---------------
  // These pathnames are unused. Uncomment it when you need :D
  ENDPOINT_OVERVIEW: "/:workspaceId/endpoint/overview",

  // Devices
  ENDPOINT_DEVICES: "/:workspaceId/endpoint/devices",
  // TODO: change the post params to something with Id suffix lol
  ENDPOINT_DEVICES_OVERVIEW:
    "/:workspaceId/endpoint/devices/details/:host/overview",
  ENDPOINT_DEVICES_SOFTWARE:
    "/:workspaceId/endpoint/devices/details/:host/software",
  ENDPOINT_DEVICES_POLICIES:
    "/:workspaceId/endpoint/devices/details/:host/policies",
  ENDPOINT_DEVICES_ACTIVITIES:
    "/:workspaceId/endpoint/devices/details/:host/activities",

  ENDPOINT_POLICIES: "/:workspaceId/endpoint/policies",

  // DLP
  ENDPOINT_DLP_BACKUP: "/:workspaceId/endpoint/dlp/backup",
  ENDPOINT_DLP_ACTIVITIES: "/:workspaceId/endpoint/dlp/activities",
} satisfies { [K in string]: string };

// Every pathname should start with a slash ("/"). Authenticated pathname should almost always have a workspace param ("/:workspaceId")
// TODO: might want to move this to a test file. This is just to help on debugging.
Object.values(pathname).forEach((value) => {
  if (value[0] !== "/") console.log("Invalid pathname: " + value);
});

// Used for layouting
export const helperPathname = {
  ASSETS: "/:workspaceId/assets",
  DEVICES: "/:workspaceId/devices",
  SCANS: "/:workspaceId/scans",
  DATA_LEAK: "/:workspaceId/data-leak",
  ENDPOINT: "/:workspaceId/endpoint",
  // Endpoint submenu
  ENDPOINT_DLP: "/:workspaceId/endpoint/dlp",
  ENDPOINT_DEVICE_DETAIL: "/:workspaceId/endpoint/devices/details",

  TRUST_CENTER: "/:workspaceId/trust-center",
  ACCOUNT: "/:workspaceId/account",
  WORKSPACE: "/:workspaceId/workspace",
  MSSP: "/mssp/:workspaceId",
} satisfies { [K in string]: string };
