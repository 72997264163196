// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// General
import { TAILWIND_COLORS } from "#src/utils/common";
import type { IChartPlotProps } from "#src/@types/chart";

// Components
import { Button } from "#src/common/system/Button";
import { SeverityLabel } from "#src/common/helper/labels/SeverityLabel";
import DonutChart from "#src/common/charts/Donut";
import ChartLegends from "#src/common/helper/charts/legends";
import { getChartTooltip } from "#src/common/helper/charts/tooltip";
import NoVulnerabilitiesState from "#src/common/states/NoVulnerabilitiesState";

const colorsByStatus: Record<string, string> = {
  new: TAILWIND_COLORS.sky,
  triaged: TAILWIND_COLORS.blue,
  resolved: TAILWIND_COLORS.green,
  duplicate: TAILWIND_COLORS.fire,
  unresolved: TAILWIND_COLORS.red,
  rejected: TAILWIND_COLORS["medium-grey"],
};

const colorsBySeverity: Record<string, string> = {
  CRITICAL: TAILWIND_COLORS.wine,
  HIGH: TAILWIND_COLORS.red,
  MEDIUM: TAILWIND_COLORS.fire,
  LOW: TAILWIND_COLORS.yellow,
  INFORMATION: TAILWIND_COLORS.blue,
};

interface IAssetDetailOverviewVulnCardProps {
  vulnData: {
    count: number;
    topVulns: { id: string; name: string; severity: string }[];
    byStatus: Record<string, number>;
    bySeverity: Record<string, number>;
  };
  onClickSeeAll: () => void;
}

const AssetDetailOverviewVulnCard = ({
  vulnData,
  onClickSeeAll,
}: IAssetDetailOverviewVulnCardProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.overview.card.vulnerabilities",
  });

  const getNameBySubstatus = (substatus: string) => {
    if (substatus === "new") return t("substatus.new");
    if (substatus === "triaged") return t("substatus.triaged");
    if (substatus === "resolved") return t("substatus.resolved");
    if (substatus === "duplicate") return t("substatus.duplicate");
    if (substatus === "unresolved") return t("substatus.unresolved");
    if (substatus === "rejected") return t("substatus.rejected");
    // Should not reach here.
    return "";
  };

  const getNameBySeverity = (severity: string) => {
    if (severity === "CRITICAL") return t("severity.critical");
    if (severity === "HIGH") return t("severity.high");
    if (severity === "MEDIUM") return t("severity.medium");
    if (severity === "LOW") return t("severity.low");
    if (severity === "INFORMATION") return t("severity.information");
    // Should not reach here.
    return "";
  };

  const donutBySubstatus: IChartPlotProps[] = Object.entries(
    colorsByStatus
  ).map(([key, value]) => {
    if (key === "rejected") {
      const count = [
        "out_of_scope",
        "not_reproducible",
        "wont_fix",
        "not_applicable",
        "spam",
      ].reduce((prev, cur) => prev + (vulnData.byStatus[cur] || 0), 0);
      return {
        id: "rejected",
        name: getNameBySubstatus("rejected"),
        value: count,
        fill: value,
      };
    } else {
      const count = vulnData.byStatus[key] || 0;
      return {
        id: key,
        name: getNameBySubstatus(key),
        value: count,
        fill: value,
      };
    }
  });

  const donutBySeverity: IChartPlotProps[] = Object.entries(
    colorsBySeverity
  ).map(([key, value]) => {
    const count = vulnData.bySeverity[key] || 0;
    return {
      id: key,
      name: getNameBySeverity(key),
      value: count,
      fill: value,
    };
  });

  // TODO: rework the responsive layout on this card?
  return (
    <>
      {/* This width below should work well when all data is available (1 top vuln section + 2 graphs) */}
      <div className="flex flex-col gap-4 flex-1 w-[calc((100%_-_4rem_-_2px)_/_3)]">
        <div className="flex items-center h-9 gap-3">
          <div className="flex justify-center items-center h-8 w-8 bg-label-blue rounded-md">
            <span className="font-bold-16 text-primary">{vulnData.count}</span>
          </div>
          <h2>{t("title")}</h2>
        </div>
        {vulnData.topVulns.slice(0, 5).map((vulnerability) => (
          <div
            key={`VulnerabilityCard_TopVuln_item-${vulnerability.id}`}
            className="flex gap-3 items-center"
          >
            <SeverityLabel severity={vulnerability.severity} mode="abbv" />
            <p className="overflow-hidden whitespace-nowrap text-ellipsis">
              {vulnerability.name}
            </p>
          </div>
        ))}
        {vulnData.count > 5 ? (
          <div className="mt-2 font-sm text-primary w-full flex justify-center items-center gap-2">
            <Button variant="text" onClick={onClickSeeAll}>
              {t("button.viewAll")}
              <ArrowRightUpLine className="h-4 w-4" />
            </Button>
          </div>
        ) : (
          <NoVulnerabilitiesState />
        )}
      </div>
      {vulnData.byStatus &&
      donutBySubstatus.reduce((prev, cur) => prev + cur.value, 0) > 0 ? (
        <>
          <hr className="border-l border-light-grey mx-3" />
          <div className="flex-1 flex flex-col justify-between">
            <div className="flex items-center justify-center gap-6">
              <DonutChart
                data={donutBySubstatus}
                tooltip={getChartTooltip}
                className="h-[14rem] w-[14rem]"
                unit={t("graph.ticket")}
              />
              <ChartLegends
                data={donutBySubstatus}
                keyTemplate="assetDetail-overviewVuln-byStatus"
              />
            </div>
            <h4 className="w-full flex justify-center mt-4">
              {t("graph.byStatus.name")}
            </h4>
          </div>
        </>
      ) : null}
      {vulnData.bySeverity &&
      donutBySeverity.reduce((prev, cur) => prev + cur.value, 0) > 0 ? (
        <>
          <hr className="border-l border-light-grey mx-3" />
          <div className="flex-1 flex flex-col justify-between">
            <div className="flex items-center justify-center gap-6">
              <DonutChart
                data={donutBySeverity}
                tooltip={getChartTooltip}
                className="h-[14rem] w-[14rem]"
                unit={t("graph.ticket")}
              />
              <ChartLegends
                data={donutBySeverity}
                keyTemplate="assetDetail-overviewVuln-bySeverity"
              />
            </div>
            <h4 className="w-full flex justify-center mt-4">
              {t("graph.bySeverity.name")}
            </h4>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AssetDetailOverviewVulnCard;
