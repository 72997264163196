// Libraries
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import { Dropdown } from "@lockerpm/design";
import dayjs from "dayjs";

// Resources
import { ReactComponent as Repeat2Line } from "#src/assets/images/icons/repeat-2-line.svg";
import { ReactComponent as BankCardLine } from "#src/assets/images/icons/bank-card-line.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";

// Components
import { StatusLabel } from "#src/common/helper/labels/StatusLabel";
import TableDataCell from "#src/common/Table/TableDataCell";
import { TableRowWrapperDiv } from "#src/common/Table/TableRowWrapper";
import { IconButton } from "#src/common/system/Button";

// API-related
import {
  PaymentMethodValue,
  ServiceStatusValue,
} from "#src/config/filter/workspace/value";
import type {
  IRecurringPrice,
  IWorkspacePaymentPlanItem,
} from "#src/services/payment";
import { expandPlanDuration } from "#src/utils/apiHelper";

interface IBillingServiceTableRowProps {
  data: IWorkspacePaymentPlanItem;
  actionList: ItemType[];
  onClickActionItems: (key: string, item: IWorkspacePaymentPlanItem) => void;
}

const BillingServiceTableRow = ({
  data,
  actionList,
  onClickActionItems,
}: IBillingServiceTableRowProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.tab.solution",
  });

  const locale = i18next.language;

  const findRemappedDuration = expandPlanDuration(data);

  return (
    <TableRowWrapperDiv>
      <TableDataCell>
        {locale === "vi" ? data.product.name.vi : data.product.name.en}
      </TableDataCell>
      <TableDataCell>
        {locale === "vi"
          ? data.product.category.name.vi
          : data.product.category.name.en}
      </TableDataCell>
      <TableDataCell>
        {locale === "vi"
          ? data.product_plan.name.vi
          : data.product_plan.name.en}
      </TableDataCell>
      <TableDataCell>
        {findRemappedDuration ? findRemappedDuration.getSpan() : data.duration}
      </TableDataCell>
      <TableDataCell>
        {/* Inline typeguard for recurring_price. Sorry. */}
        {((price): price is IRecurringPrice =>
          typeof price === "object" && Object.hasOwn(price, "vnd"))(
          data.product_plan.recurring_price
        ) && data.product_plan.recurring_price.vnd
          ? data.product_plan.recurring_price.vnd.toLocaleString()
          : "0"}{" "}
        VND
      </TableDataCell>
      <TableDataCell>
        {data.end_period
          ? dayjs.unix(data.end_period).format("DD.MM.YYYY")
          : "-"}
      </TableDataCell>
      <TableDataCell>
        {data.payment_method === PaymentMethodValue.BANKING ? (
          <StatusLabel
            name={t("labels.paymentMethod.banking")}
            color="bg-support-blue-pastel text-support-blue"
            icon={<Repeat2Line className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : data.payment_method === PaymentMethodValue.CARD ? (
          <StatusLabel
            name={t("labels.paymentMethod.card")}
            color="bg-white text-support-blue outline outline-1 outline-support-blue"
            icon={<BankCardLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : (
          "-"
        )}
      </TableDataCell>
      <TableDataCell>
        {data.status === ServiceStatusValue.ACTIVE ? (
          <StatusLabel
            name={t("labels.serviceStatus.active")}
            color="bg-support-seafoam-pastel text-support-seafoam"
            icon={<CheckboxCircleLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : data.status === ServiceStatusValue.EXPIRING ? (
          <StatusLabel
            name={t("labels.serviceStatus.expiring")}
            color="bg-support-fire-pastel text-policy-expire-in30days"
            icon={<TimeLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : data.status === ServiceStatusValue.EXPIRED ? (
          <StatusLabel
            name={t("labels.serviceStatus.expired")}
            color="bg-warning-red-10% text-warning-red-danger"
            icon={<InformationLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : null}
      </TableDataCell>
      <TableDataCell className="p-0 justify-center">
        <Dropdown
          menu={{
            items: actionList,
            onClick: ({ key, domEvent }) => {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              onClickActionItems(key, data);
            },
          }}
          trigger={["click"]}
        >
          <IconButton
            variant="ghost"
            size={9}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <MoreLine />
          </IconButton>
        </Dropdown>
      </TableDataCell>
    </TableRowWrapperDiv>
  );
};

export default BillingServiceTableRow;
