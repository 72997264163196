import { useCallback, useEffect, useMemo, useState } from "react";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { useTranslation } from "react-i18next";
import devicesServices from "#src/services/devices";
import { PolicyComplianceValue } from "#src/config/filter/device/value";
import { ArrowUpRight } from "@untitled-ui/icons-react";
import { TAILWIND_COLORS } from "#src/utils/common";
import { LoaderV2 } from "#src/commonV2/LoadingState";
import { generatePath, useNavigate } from "react-router-dom";
import { pathname } from "#src/config/pathname";
import { DonutChartV2 } from "#src/commonV2/charts/Donut";
import { ChartLegendsV2 } from "#src/commonV2/charts/helper/legends";
import { getChartTooltipV2 } from "#src/commonV2/charts/helper/tooltip";

type Props = {
  workspaceId: string;
  hostId: string;
};

export const PolicySection = (props: Props) => {
  const { workspaceId, hostId } = props;
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });
  const navigate = useNavigate();

  // --------------- STATE ---------------

  const [isLoading, setIsLoading] = useState(false);
  const [policyInfo, setPolicyInfo] = useState({
    passed: 0,
    failed: 0,
  });

  // --------------- METHOD ---------------

  const viewDetails = () => {
    navigate({
      pathname: generatePath(pathname.ENDPOINT_DEVICES_POLICIES, {
        workspaceId,
        host: hostId,
      }),
    });
  };

  // --------------- COMPUTED ---------------

  const isEmpty = policyInfo.passed === 0 && policyInfo.failed === 0;

  const chartData = useMemo(() => {
    return [
      {
        id: "passed",
        name: t("overview.policy.passed"),
        value: policyInfo.passed,
        percent: (
          (policyInfo.passed / (policyInfo.passed + policyInfo.failed)) *
          100
        ).toFixed(0),
        fill: TAILWIND_COLORS["success-v2-400"],
      },
      {
        id: "failed",
        name: t("overview.policy.failed"),
        value: policyInfo.failed,
        percent: (
          (policyInfo.failed / (policyInfo.passed + policyInfo.failed)) *
          100
        ).toFixed(0),
        fill: TAILWIND_COLORS["error-v2-400"],
      },
    ];
  }, [policyInfo, t]);

  // --------------- METHODS ---------------

  const fetchPolicyInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const [passed, failed] = await Promise.all([
        devicesServices.list_host_policies(workspaceId, +hostId, {
          policy_compliance_status: PolicyComplianceValue.PASSED,
          q: undefined,
          page: undefined,
          size: 1,
        }),
        devicesServices.list_host_policies(workspaceId, +hostId, {
          policy_compliance_status: PolicyComplianceValue.FAILED,
          q: undefined,
          page: undefined,
          size: 1,
        }),
      ]);

      setPolicyInfo({
        passed: passed.count || 0,
        failed: failed.count || 0,
      });
    } catch (error) {
      apiErrorHandler(error, {
        toastMessage: t("notification.fetchHostPolicies.fail"),
      });
    }
    setIsLoading(false);
  }, [workspaceId, hostId, t]);

  // --------------- EFFECTS ---------------

  useEffect(() => {
    fetchPolicyInfo();
  }, [fetchPolicyInfo]);

  // --------------- RENDERING ---------------

  return (
    <section className="w-full xl:w-4/12 section-container flex flex-col justify-between">
      <h3>{t("overview.policy.title")}</h3>

      {/* Content */}
      <div className="flex justify-start items-center gap-4 flex-wrap">
        {isLoading && (
          <div className="w-full h-40 flex items-center justify-center">
            <LoaderV2 baseSize={4} />
          </div>
        )}

        {!isLoading && isEmpty && (
          <div className="w-full h-40 flex items-center justify-center">
            <p className="text-gray-v2-400 text-sm">{t("common.no_data")}</p>
          </div>
        )}

        {!isLoading && !isEmpty && (
          <>
            <DonutChartV2
              height={"10rem"}
              width={"10rem"}
              tooltip={getChartTooltipV2}
              data={chartData}
            />

            <ChartLegendsV2 data={chartData} showPercentage />
          </>
        )}
      </div>
      {/* Content end */}

      {/* Footer */}
      <div className="flex justify-center w-full py-2.5 mt-4">
        <a
          className="link"
          onClick={(e) => {
            e.preventDefault();
            viewDetails();
          }}
        >
          <span>{t("button.view_details")}</span>{" "}
          <ArrowUpRight className="h-5 w-5 ml-1" />
        </a>
      </div>
      {/* Footer end */}
    </section>
  );
};
