// Libraries
import { Dropdown } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// Components
import { Input, TextArea } from "#src/common/system/Input";
import { TagEditor } from "#src/common/system/Tag/TagEditor";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";

// Children
import HostInput from "../../common/HostInput";
import { TagTypeValue } from "#src/config/filter/tag/value";

interface IAddControlIpAddressTabProps {
  workspaceId: string;
  controlActionItems: {
    key: string;
    value: string;
    label: string;
  }[];
  controlSourceItems: {
    key: string;
    value: string;
    label: string;
  }[];
  ipAddress: string;
  controlAction: {
    key: string;
    value: string;
    label: string;
  };
  controlSource: {
    key: string;
    value: string;
    label: string;
  };
  selectedHosts: { key: string; id: string; name: string; label: string }[];
  tagList: { name: string }[];
  note: string;
  onChangeIpAddress: (value: string) => void;
  onSelectControlAction: (key: string) => void;
  onSelectControlSource: (key: string) => void;
  onChangeHost: (newHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => void;
  onRemoveHost: (removedHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => void;
  onAddDefaultHost: () => void;
  onChangeTags: (tags: { name: string }[]) => void;
  onChangeNote: (value: string) => void;
}

const AddControlIpAddressTab = ({
  workspaceId,
  controlActionItems,
  controlSourceItems,
  ipAddress,
  controlAction,
  controlSource,
  selectedHosts,
  tagList,
  note,
  onChangeIpAddress,
  onSelectControlAction,
  onSelectControlSource,
  onChangeHost,
  onRemoveHost,
  onAddDefaultHost,
  onChangeTags,
  onChangeNote,
}: IAddControlIpAddressTabProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "control.drawers.addNewControl",
  });

  return (
    <div className="py-6 flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <h4>{t("field.ipAddress.label")}</h4>
        <Input
          placeholder={t("field.ipAddress.placeholder")}
          value={ipAddress}
          onChange={(e) => {
            onChangeIpAddress(e.currentTarget.value);
          }}
        />
      </div>
      <div className="flex gap-3">
        <div className="flex flex-col gap-3 flex-1">
          <h4>{t("field.source.label")}</h4>
          <Dropdown
            menu={{
              items: controlSourceItems.map((item) => ({
                key: item.key,
                label: (
                  <DropdownItemLabel selected={controlSource.key === item.key}>
                    {item.label}
                  </DropdownItemLabel>
                ),
              })),
              onClick: ({ key }) => {
                onSelectControlSource(key);
              },
            }}
            trigger={["click"]}
          >
            <button className="flex gap-2 items-center justify-between p-3 bg-bright-grey text-hard-grey rounded-md">
              {controlSource.label}
              <ArrowDownSLine />
            </button>
          </Dropdown>
        </div>
        <div className="flex flex-col gap-3 flex-1">
          <h4>{t("field.action.label")}</h4>
          <Dropdown
            menu={{
              items: controlActionItems.map((item) => ({
                key: item.key,
                label: (
                  <DropdownItemLabel selected={controlAction.key === item.key}>
                    {item.label}
                  </DropdownItemLabel>
                ),
              })),
              onClick: ({ key }) => {
                onSelectControlAction(key);
              },
            }}
            trigger={["click"]}
          >
            <button className="flex gap-2 items-center justify-between p-3 bg-bright-grey text-hard-grey rounded-md">
              {controlAction.label}
              <ArrowDownSLine />
            </button>
          </Dropdown>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <h4>{t("field.applyToTags.label")}</h4>
        <TagEditor
          workspaceId={workspaceId}
          tagList={tagList}
          onChangeTags={onChangeTags}
          type={TagTypeValue.HOST}
        />
      </div>
      <div className="flex flex-col gap-3">
        <h4>{t("field.applyToHostName.label")}</h4>
        {selectedHosts.map((host, index) => (
          <HostInput
            key={`devices/control/newControl-networkTab-hostInput-${host}-${index}`}
            workspaceId={workspaceId}
            selectedHost={host}
            onChangeHost={onChangeHost}
            onRemoveHost={onRemoveHost}
            autofocus
          />
        ))}
        <button
          className="p-3 bg-bright-grey text-medium-grey rounded-md text-left"
          onClick={(e) => {
            e.stopPropagation();
            onAddDefaultHost();
          }}
        >
          {t("field.applyToHostName.search.placeholder")}
        </button>
      </div>
      <div className="flex flex-col gap-3">
        <h4>{t("field.notes.label")}</h4>
        <TextArea
          placeholder={t("field.notes.placeholder")}
          value={note}
          onChange={(e) => {
            onChangeNote(e.currentTarget.value);
          }}
        />
      </div>
    </div>
  );
};

export default AddControlIpAddressTab;
