// Libraries
import { type Dispatch, type SetStateAction, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import OverviewMainCard from "#src/common/OverviewMainCard";
import OverviewSubCard from "#src/common/OverviewSubCard";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import assetsServices, { type IAssetItem } from "#src/services/assets";

// Children
import DomainFilter from "./Filter";
import DomainTableGrid from "./Table";
import AddDomainDrawer from "../drawers/AddDomainDrawer";

interface IDomainTableProps {
  workspaceId: string;
  openAddDomainDrawer: boolean;
  setOpenAddDomainDrawer: Dispatch<SetStateAction<boolean>>;
}

const DomainTable = ({
  workspaceId,
  openAddDomainDrawer,
  setOpenAddDomainDrawer,
}: IDomainTableProps) => {
  const { t } = useTranslation("assets", { keyPrefix: "domains.page.domains" });

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const [vulnerable, setVulnerable] = useState<IFilterItem | null>(null);
  const [verifiedOwnership, setVerifiedOwnership] =
    useState<IFilterItem | null>(null);
  const [selectedDomains, setSelectedDomains] = useState<IAssetItem[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const fetchParams = useMemo<Parameters<typeof assetsServices.list_domains>>(
    () => [
      workspaceId,
      {
        is_domain: 1,
        from: undefined,
        to: undefined,
        vulnerable: vulnerable?.value,
        verification: verifiedOwnership?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      vulnerable,
      verifiedOwnership,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const {
    list: domainList,
    count: domainCount,
    isLoading,
    updateData: updateDomainList,
    statistics,
  } = useFetchPaginated(
    assetsServices.list_domains,
    fetchParams,
    assetsServices.get_asset_statistics
  );

  const onChangeVulnerableSelection = (selected: typeof vulnerable) => {
    setVulnerable(selected);
    setSelectedPage(1);
  };

  const onChangeVerifiedOwnershipSelection = (
    selected: typeof verifiedOwnership
  ) => {
    setVerifiedOwnership(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
    setSelectedDomains([]);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
    setSelectedDomains([]);
  };

  const onChangeSelectedDomains = (selected: IAssetItem) => {
    if (selectedDomains.map((item) => item.id).includes(selected.id)) {
      setSelectedDomains((prev) => [
        ...prev.filter((item) => item.id !== selected.id),
      ]);
    } else {
      setSelectedDomains((prev) => [...prev, selected]);
    }
  };

  const onSelectAllDomains = () => {
    if (domainList) {
      setSelectedDomains(domainList);
    }
  };

  const onDeselectAllDomains = () => {
    setSelectedDomains([]);
  };

  const onDeleteSelectedDomains = () => {
    assetsServices
      .delete_multiple_assets(
        selectedDomains.map((item) => item.id),
        workspaceId
      )
      .then(() => {
        updateDomainList();
        createToast({
          type: "success",
          message: t("notification.deleteDomain.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteDomain.fail"),
        });
      });
    setSelectedDomains([]);
  };

  const vulnerablePercentage =
    statistics && statistics.total > 0
      ? (statistics?.vulnerable_domains / statistics?.total) * 100
      : 0;

  return (
    <>
      <div className="flex flex-wrap gap-6">
        <OverviewMainCard
          label={t("mainCard.totalDomains")}
          value={statistics?.total}
          variant="blue"
        />
        <OverviewMainCard
          label={t("mainCard.vulnerableDomains")}
          value={statistics ? vulnerablePercentage.toFixed() + "%" : undefined}
          variant={
            statistics
              ? vulnerablePercentage < 10
                ? "green"
                : vulnerablePercentage * 100 < 70
                  ? "orange"
                  : "red"
              : "green"
          }
        />

        <div className="flex flex-1 gap-6 whitespace-nowrap">
          <OverviewSubCard
            items={[
              {
                label: t("subCard.rootDomains"),
                value: statistics?.root_domains,
              },
              {
                label: t("subCard.subdomains"),
                value: statistics?.sub_domains,
              },
            ]}
          />
          <OverviewSubCard
            items={[
              {
                label: t("subCard.autoDiscover"),
                value: statistics?.auto_discover,
              },
              {
                label: t("subCard.unverifiedDomains"),
                value: statistics?.unverified_domains,
              },
            ]}
          />
        </div>
      </div>
      <DomainFilter
        vulnerable={vulnerable}
        verifiedOwnership={verifiedOwnership}
        searchKeyword={searchKeyword}
        totalCount={domainList ? domainList.length : 0}
        selectedCount={selectedDomains.length}
        onChangeVulnerableSelection={onChangeVulnerableSelection}
        onChangeVerifiedOwnershipSelection={onChangeVerifiedOwnershipSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
        onSelectAllDomains={onSelectAllDomains}
        onDeselectAllDomains={onDeselectAllDomains}
        onDeleteSelectedDomains={onDeleteSelectedDomains}
      />
      <DomainTableGrid
        workspaceId={workspaceId}
        domainList={domainList}
        selectedDomainIds={selectedDomains.map((item) => item.id)}
        isLoading={isLoading}
        onChangeSelectedDomains={onChangeSelectedDomains}
        refreshDomainList={updateDomainList}
      />
      <PaginationBar
        numOfResult={domainCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
      <AddDomainDrawer
        open={openAddDomainDrawer}
        onClose={() => {
          setOpenAddDomainDrawer(false);
        }}
        workspaceId={workspaceId}
        onRefresh={updateDomainList}
      />
    </>
  );
};

export default DomainTable;
