import { Dropdown } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoCyStack } from "#src/assets/images/logos/logo-cystack.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";

const AddPentestMssp = () => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.addPentest.mssp",
  });

  return (
    <div className="flex flex-col gap-4 w-1/2 min-w-[24rem]">
      <h2>{t("title")}</h2>
      <span className="text-hard-grey">{t("instruction")}</span>
      <Dropdown
        menu={{
          // Currently we don't have any MSSP outside of CyStack. Should fetch this from resource instead,
          items: [
            {
              key: "CyStack",
              label: (
                <DropdownItemLabel selected syncWidthId="mssp-dropdown">
                  <LogoCyStack className="h-[1.125rem] w-[1.125rem]" />
                  CyStack
                </DropdownItemLabel>
              ),
            },
          ],
          onClick: ({ domEvent }) => {
            domEvent.preventDefault();
            domEvent.stopPropagation();
            // onClickItems(key, data);
          },
        }}
        trigger={["click"]}
      >
        <DropdownTriggerButton id="mssp-dropdown">
          <div className="flex items-center gap-2">
            <LogoCyStack className="h-[1.125rem] w-[1.125rem]" />
            CyStack
          </div>
          <ArrowDownSLine />
        </DropdownTriggerButton>
      </Dropdown>
      <a>{t("getHelp")}</a>
    </div>
  );
};

export default AddPentestMssp;
