import {
  generatePath,
  Link,
  matchPath,
  useMatches,
  useParams,
} from "react-router-dom";
import {
  ChevronSelectorVertical,
  FlipBackward,
} from "@untitled-ui/icons-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoCyStackLettermark } from "#src/assets/images/logos/logo-cystack-lettermark.svg";
import { pathname } from "#src/config/pathname";
import { sidebarV2Items, sidebarV2SecondMenuItems } from "./config";
import { useAppDispatch, useAppSelector } from "#src/store/hooks";
import { HTMLAttributes, useEffect, useMemo, useRef, useState } from "react";
import authServices from "#src/services/auth";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { updateUserInfo } from "#src/store/slices/auth";
import { ObjectImage } from "#src/common/system/Object";
import { SidebarProductItemRender } from "./render/Product";
import { SidebarPageItemRender } from "./render/Page";
import { SidebarSettingMenu } from "./SettingMenu";
import { twMerge } from "tailwind-merge";
import { useClickOutside } from "#src/hooks/uiHelpers/useClickOutside";

export const LayoutV2Sidebar = ({
  openSidebar,
  className,
}: {
  openSidebar: boolean;
  className?: HTMLAttributes<HTMLDivElement>["className"];
}) => {
  const { t } = useTranslation("layout", { keyPrefix: "sidebar.v2" });

  const pathMatches = useMatches();

  const { workspaceId } = useParams<"workspaceId">();

  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.auth.userInfo);

  // --------------- DATA ---------------

  const settingButtonRef = useRef<HTMLLabelElement>(null);
  const menuContainerRef = useRef<HTMLDivElement>(null);

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  // --------------- EFFECTS ---------------
  useEffect(() => {
    const updateUser = async () => {
      const authInfo = await authServices.get_account().catch(apiErrorHandler);
      dispatch(updateUserInfo(authInfo));
    };

    if (!userInfo) {
      updateUser();
    }
  }, [userInfo, dispatch]);

  // Return Second Menu object if current path is matched and Second Menu should be shown, return undefined otherwise and show the Main Menu.
  const currentMenu = useMemo(() => {
    return sidebarV2SecondMenuItems.find((item) =>
      pathMatches.some((match) => matchPath(item.rootPath, match.pathname))
    );
  }, [pathMatches]);

  useClickOutside([settingButtonRef, menuContainerRef], () => {
    setOpenMenu(false);
  });

  // --------------- RENDER ---------------

  if (!workspaceId) return null;

  return (
    <div
      // className of this component is casted outside so that it can be controlled where it's easier to contextualize.
      className={twMerge(className, `${openSidebar ? "w-[18.5rem]" : "w-0"}`)}
    >
      {/* ---------- Upper half ---------- */}
      {/* Upper half should contain navigating items */}
      <div className="pt-6 flex flex-col gap-4 flex-1">
        {/* Trademark as home button */}
        <Link to={pathname.HOME} className="px-4">
          <LogoCyStackLettermark className="fill-black" />
        </Link>
        {/* Trademark as home button end */}

        {/* Route list */}
        {/* I'm the root div to animate 2 menus around. */}
        <div className="relative flex-1">
          {/* Main Menu, which should contain most of our product */}
          <div
            // If the label below is enabled, this should have gap-4
            className={`absolute px-4 flex flex-col h-full w-full transition-all ease-out overflow-auto ${currentMenu ? "left-[calc(-100%_-_2rem)]" : "left-0"}`}
          >
            {sidebarV2Items.map((group) => (
              <div key={`sidebar-group-${group.key}`} className="flex flex-col">
                {/* This should be shown but the clowns writing the specs just can't decide themselves. */}
                {/* <span className="px-3 py-1 text-xs font-semibold text-gray-v2-400">
                  {group.getLabel()}
                </span> */}
                {group.products.map((product, productIndex) => {
                  if (product === "SEPARATOR") {
                    return (
                      <div
                        key={`sidebar-product-separator-${productIndex}`}
                        className="my-2 border-t border-gray-v2-200"
                      />
                    );
                  }

                  return (
                    <SidebarProductItemRender
                      key={`sidebar-item-product-${product.path}`}
                      product={product}
                      workspaceId={workspaceId}
                      pathMatches={pathMatches}
                    />
                  );
                })}
              </div>
            ))}
          </div>
          {/* Main menu end */}

          {/* Second menu, will sometimes show in replace of the Main Menu */}
          <div
            className={`absolute px-4 flex flex-col w-full transition-all ease-out overflow-auto ${currentMenu ? "left-0" : "left-[calc(-100%_-_2rem)]"}`}
          >
            <Link
              to={generatePath(currentMenu?.backTo.path ?? ".", {
                workspaceId,
              })}
            >
              <button className="flex items-center gap-1.5 w-fit py-2.5 rounded-lg transition-colors font-semibold text-md text-brand-v2-700 hover:text-brand-v2-800">
                <FlipBackward />
                {t("button.backTo") + currentMenu?.backTo.getLabel()}
              </button>
            </Link>
            {currentMenu
              ? currentMenu.children.map((subpage) => (
                  <SidebarPageItemRender
                    key={`sidebar-item-subpage-${subpage.path}`}
                    path={subpage.path}
                    getLabel={subpage.getLabel}
                    pathMatches={pathMatches}
                  />
                ))
              : null}
          </div>
          {/* Second menu end */}
        </div>
        {/* Route list end */}
      </div>
      {/* ---------- Upper half end ---------- */}

      {/* ---------- Lower half ---------- */}
      {/* Lower half should have general items like settings */}
      <div className="flex flex-col px-4 pb-6 gap-4">
        {/* General options, for example: Notification settings & Support */}
        <div></div>
        {/* General options end */}

        {/* User profile */}
        <label
          ref={settingButtonRef}
          className="bg-white border border-gray-v2-200 shadow-xs rounded-xl p-3 flex gap-2 relative cursor-pointer"
        >
          <ObjectImage
            data={userInfo?.avatar}
            className="h-10 w-10 rounded-full"
          />
          <div className="flex flex-col">
            <span className="font-semibold text-sm">{userInfo?.full_name}</span>
            <span className="text-sm">{userInfo?.email}</span>
          </div>
          <button
            className="absolute top-1.5 right-1.5 h-8 w-8"
            onClick={() => {
              setOpenMenu((prev) => !prev);
            }}
          >
            <ChevronSelectorVertical className="h-5 w-5" />
          </button>
          <SidebarSettingMenu
            containerRef={menuContainerRef}
            open={openMenu}
            onChangeOpen={(value) => {
              setOpenMenu(value);
            }}
            workspaceId={workspaceId}
          />
        </label>
        {/* User profile end */}
      </div>
      {/* Lower half end */}
    </div>
  );
};
