export enum DLPSchedulePeriod {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export enum DLPAction {
  BACKUP = "snapshot_backup",
  RESTORE = "snapshot_restore",
}

export enum DLPActionStatus {
  SUCCESS = "successful",
  FAIL = "failed",
}

export enum DLPDriveBackupStatus {
  IDLE = "",
  BACKING_UP = "backing_up",
  PENDING_RESTORE = "pending_restore",
  RESTORING = "restoring",
}

export enum DLPDriveOnlineStatus {
  ONLINE = "online",
  OFFLINE = "offline",
}

export enum DLPWebSocketEvent {
  BACKUP_STATUS_CHANGED = "restore_vdisk",
}
