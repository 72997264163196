// Libraries
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown, Tooltip } from "@lockerpm/design";
import { useMemo, useState } from "react";
import i18next from "i18next";

// Resources
import { ReactComponent as SaveLine } from "#src/assets/images/icons/save-line.svg";
import { ReactComponent as CornerLeftUpLine } from "#src/assets/images/icons/corner-left-up-line.svg";
import { ReactComponent as PlusIcon } from "#src/assets/images/icons/plus.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// General
import { pathname } from "#src/config/pathname";
import { useAppSelector } from "#src/store/hooks";

// Components
import NoResultsState from "#src/common/states/NoResultsState";
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/common/system/Button";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { ObjectImage } from "#src/common/system/Object";
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import type { FormErrorItem } from "#src/common/helper/wrapper/InputField";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import resourceServices, {
  type ITrustCenterSupportedCompliance,
} from "#src/services/resource";
import {
  TrustCenterPlanValue,
  TrustCenterPolicyStatusValue,
} from "#src/config/filter/trustCenter/value";

// Children
import { AddPolicyLanguageItem } from "#src/components/trustCenter/policy/addPolicy/PolicyLangItem";

const AddTrustCenterPolicy = () => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "policy.addPolicy",
  });

  const locale = i18next.language;

  const navigate = useNavigate();

  const { workspaceId } = useParams<"workspaceId">();

  const trustCenterPlan = useAppSelector((state) => state.trustCenter.plan);

  const [selectedStandards, setSelectedStandards] = useState<
    ITrustCenterSupportedCompliance[]
  >([]);
  const [policyDetail, setPolicyDetail] = useState<
    { lang: string; name: string; description: string }[]
  >([{ lang: locale, name: "", description: "" }]);
  const [errorObj, setErrorObj] = useState<{
    policyName: FormErrorItem;
    policyDescription: FormErrorItem;
  }>({ policyName: undefined, policyDescription: undefined });

  const [pending, setPending] = useState<boolean>(false);

  if (!workspaceId || workspaceId === "null") {
    return <NoResultsState />;
  }

  const onCreatePolicy = (policyStatus: TrustCenterPolicyStatusValue) => {
    setPending(true);
    trustCenterServices
      .create_trust_center_policy(workspaceId, {
        compliance: selectedStandards.map((standard) => standard.id),
        details: policyDetail,
        status: policyStatus,
      })
      .then(() => {
        navigate(generatePath(pathname.TRUST_CENTER_POLICY, { workspaceId }));
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.createPolicy.fail"),
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  const onClickAddAnotherLang = () => {
    if (policyDetail.every((item) => item.lang !== "en")) {
      setPolicyDetail((prev) => [
        ...prev,
        { lang: "en", name: "", description: "" },
      ]);
      return;
    }
    if (policyDetail.every((item) => item.lang !== "vi")) {
      setPolicyDetail((prev) => [
        ...prev,
        { lang: "vi", name: "", description: "" },
      ]);
      return;
    }
  };

  const uselessMemo = useMemo(() => [], []);

  const { list: supportedComplianceList } = useFetchNoPaging(
    resourceServices.list_trust_center_supported_compliance,
    uselessMemo
  );

  const invalidPolicyNotification: string | null = useMemo(() => {
    for (const item of policyDetail) {
      // Policy name should not be empty
      if (item.name.length === 0) return t("errorMessage.policyName.isEmpty");

      // Policy name should not exceed max length
      if (item.name.length > 255)
        return t("errorMessage.policyName.exceedMaxLength");

      // Policy description should not exceed max length, which depends on Trust center plan
      if (
        trustCenterPlan === TrustCenterPlanValue.FREE &&
        item.description.length > 4096
      )
        return t("errorMessage.policyDescription.exceedMaxLength");
      if (
        trustCenterPlan === TrustCenterPlanValue.ENTERPRISE &&
        item.description.length > 8192
      )
        return t("errorMessage.policyDescription.exceedMaxLength");
      8;
    }

    return null;
  }, [policyDetail, trustCenterPlan, t]);

  return (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        <div className="flex gap-1">
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              navigate(
                generatePath(pathname.TRUST_CENTER_POLICY, {
                  workspaceId,
                })
              );
            }}
          >
            {t("button.cancel")}
          </Button>
          <Tooltip
            title={
              invalidPolicyNotification === null ? null : (
                <div className="font-medium-12 whitespace-nowrap">
                  {invalidPolicyNotification}
                </div>
              )
            }
            placement="top"
          >
            {/* Antd just can't detect custom elements, so we have to add a div here. */}
            <div>
              <Button
                size="large"
                variant="secondary"
                disabled={invalidPolicyNotification !== null}
                pending={pending}
                onClick={() => {
                  onCreatePolicy(TrustCenterPolicyStatusValue.DRAFT);
                }}
              >
                <SaveLine />
                {t("button.saveAsDraft")}
              </Button>
            </div>
          </Tooltip>
          <Tooltip
            title={
              invalidPolicyNotification === null ? null : (
                <div className="font-medium-12 whitespace-nowrap">
                  {invalidPolicyNotification}
                </div>
              )
            }
            placement="top"
          >
            <div>
              <Button
                size="large"
                disabled={invalidPolicyNotification !== null}
                pending={pending}
                onClick={() => {
                  onCreatePolicy(TrustCenterPolicyStatusValue.PUBLISHED);
                }}
              >
                <CornerLeftUpLine />
                {t("button.publishNow")}
              </Button>
            </div>
          </Tooltip>
        </div>
      </StickySection>
      <ContentSection className="gap-12">
        <div className="flex flex-col gap-6">
          <h2>{t("standard.title")}</h2>
          <div className="flex flex-wrap gap-2">
            {selectedStandards.map((standard) => (
              <div
                key={`trustCenter-addPolicy-standard-${standard.id}`}
                className="rounded-full px-3 py-2 border border-grey bg-bright-grey-subline w-fit flex items-center gap-3"
              >
                <ObjectImage
                  data={standard.logo}
                  className="h-6 w-6 min-w-[1.5rem] rounded-md"
                />
                {locale === "vi" ? standard.name.vi : standard.name.en}
                <CloseLine
                  className="h-5 w-5 fill-hard-grey cursor-pointer"
                  onClick={() => {
                    setSelectedStandards((prev) =>
                      prev.filter((s) => s.id !== standard.id)
                    );
                  }}
                />
              </div>
            ))}
            <Dropdown
              menu={{
                items: supportedComplianceList
                  ? supportedComplianceList
                      .filter(
                        (compliance) =>
                          !selectedStandards
                            .map((standard) => standard.id)
                            .includes(compliance.id)
                      )
                      .map((compliance) => ({
                        key: compliance.id,
                        label: (
                          <DropdownItemLabel selected={false}>
                            <ObjectImage
                              data={compliance.logo}
                              className="h-6 w-6 min-w-[1.5rem] rounded-md"
                            />
                            {locale === "vi"
                              ? compliance.name.vi
                              : compliance.name.en}
                          </DropdownItemLabel>
                        ),
                      }))
                  : [],
                onClick: ({ key }) => {
                  const findStandard = supportedComplianceList?.find(
                    (compliance) => compliance.id === key
                  );
                  if (findStandard) {
                    setSelectedStandards((prev) => [...prev, findStandard]);
                  }
                },
              }}
              trigger={["click"]}
            >
              <button className="rounded-full px-3 py-2 border border-grey bg-bright-grey-subline text-hard-grey w-fit flex items-center gap-3">
                <div className="h-6 w-6 flex items-center justify-center">
                  <PlusIcon className="h-5 w-5 fill-hard-grey" />
                </div>
                {t("standard.addStandard")}
              </button>
            </Dropdown>
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-6">
            <h2>{t("policy.title")}</h2>
            {policyDetail.map((policyLangItem) => {
              return (
                <AddPolicyLanguageItem
                  key={`policyByLanguage-${policyLangItem.lang}`}
                  workspaceId={workspaceId}
                  policyLangItem={policyLangItem}
                  policyDetail={policyDetail}
                  setPolicyDetail={setPolicyDetail}
                  errorObj={errorObj}
                  setErrorObj={setErrorObj}
                />
              );
            })}
          </div>
          {/* If every languages are used, hide the button. */}
          {["en", "vi"].every((lang) =>
            policyDetail.some((item) => item.lang === lang)
          ) ? null : (
            <Button variant="text" size="large" onClick={onClickAddAnotherLang}>
              <AddLine />
              {t("policy.button.addAnotherLanguage")}
            </Button>
          )}
        </div>
      </ContentSection>
    </>
  );
};

export default AddTrustCenterPolicy;
