import { Modal } from "@lockerpm/design";
import { useState } from "react";
import { Power01 } from "@untitled-ui/icons-react";
import { ButtonV2 } from "#src/commonV2/Button";
import devicesServices from "#src/services/devices";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  deviceName: string;
  workspaceId: string;
  hostId: number;
};

export const ShutDownModal = (props: Props) => {
  const { isOpen, onClose, deviceName, workspaceId, hostId } = props;

  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- DATA ---------------

  const [isLoading, setIsLoading] = useState(false);

  // --------------- METHODS ---------------

  const shutdownDevice = async () => {
    setIsLoading(true);
    try {
      await devicesServices.shutdown_host(workspaceId, hostId);
      onClose();
    } catch (error) {
      apiErrorHandler(error, {
        toastMessage: t("notification.shutdownHost.fail"),
      });
    }
    setIsLoading(false);
  };

  // --------------- RENDER ---------------

  return (
    <Modal
      title={
        <div className="pl-14">
          <p className="relative z-10 text-lg font-semibold">
            {t("modals.shutDown.title", { deviceName })}
          </p>
        </div>
      }
      open={isOpen}
      footer={null}
      maskClosable={false}
      onCancel={onClose}
      className="v2 max-w-[550px] overflow-hidden relative"
      centered
      width="90%"
    >
      <div className="absolute -left-9 -top-11 z-0">
        <div className="border border-gray-v2-50 rounded-full p-5">
          <div className="border border-gray-v2-100 rounded-full p-5">
            <div className="border border-gray-v2-100 rounded-full p-5">
              <div className="rounded-full bg-error-v2-100 h-10 w-10 flex items-center justify-center border-4 border-error-v2-50">
                <Power01 className="h-6 w-6 text-error-v2-600" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative v2 pl-14 z-10">
        {/* Desc */}
        <p className="text-sm text-gray-v2-600 mb-8">
          {t("modals.shutDown.desc", { deviceName })}
        </p>
        {/* Desc end */}

        {/* Footer */}
        <div className="flex gap-2 justify-end mt-8">
          <ButtonV2
            variant="secondary"
            size="lg"
            color="gray"
            onClick={onClose}
          >
            <p>{t("button.cancel")}</p>
          </ButtonV2>

          <ButtonV2
            variant="primary"
            size="lg"
            color="error"
            pending={isLoading}
            onClick={shutdownDevice}
          >
            <p>{t("button.confirm")}</p>
          </ButtonV2>
        </div>
        {/* Footer end */}
      </div>
    </Modal>
  );
};
