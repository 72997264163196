// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from "react-router-dom";

// Resources
import { ReactComponent as ArrowUpLine } from "#src/assets/images/icons/arrow-up-line.svg";
import { ReactComponent as BankCardLine } from "#src/assets/images/icons/bank-card-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import { pathname } from "#src/config/pathname";

// Components
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import TableHeader, {
  type ITableHeaderPropItem,
} from "#src/common/Table/TableHeader";
import TableActionLabel from "#src/common/Table/TableActionLabel";

// API-related
import type { IWorkspacePaymentPlanItem } from "#src/services/payment";
import type { IWorkspaceItem } from "#src/services/workspace";

// Children
import BillingServiceTableRow from "./TableRow";
import BillingServiceUnsubModal from "./UnsubModal";

enum ServiceAction {
  UPGRADE_PLAN = "SERVICE_ACTION_ENUM_UPGRADE_PLAN",
  RENEW = "SERVICE_ACTION_ENUM_RENEW",
  UNSUBSCRIBE = "SERVICE_ACTION_ENUM_UNSUBSCRIBE",
}

const upgradeUrl: { [P in string]: string } = {
  tc_free: pathname.WORKSPACE_BILLING_UPGRADE_TRUST_CENTER,
  tc_custom_domain: pathname.WORKSPACE_BILLING_UPGRADE_TC_CUSTOM_DOMAIN,
};

interface IBillingServiceTableProps {
  workspaceId: string;
  serviceList: IWorkspacePaymentPlanItem[] | null;
  isLoading: boolean;
  currentWorkspace: IWorkspaceItem | null;
}

const BillingServiceTable = ({
  workspaceId,
  serviceList,
  isLoading,
  currentWorkspace,
}: IBillingServiceTableProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.tab.solution",
  });

  const [unsubbingService, setUnsubbingService] =
    useState<IWorkspacePaymentPlanItem | null>(null);

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.product"), align: "left" },
    { name: t("headers.solution"), align: "left" },
    { name: t("headers.subscription"), align: "left" },
    { name: t("headers.cycle"), align: "left" },
    { name: t("headers.price"), align: "left" },
    { name: t("headers.expiryDate"), align: "left" },
    { name: t("headers.paymentMethod"), align: "left" },
    { name: t("headers.status"), align: "left" },
    { name: "", align: "left" },
  ];

  const navigate = useNavigate();

  const actionList = [
    {
      key: ServiceAction.UPGRADE_PLAN,
      label: (
        <TableActionLabel
          icon={<ArrowUpLine />}
          text={t("actions.upgradePlan")}
        />
      ),
    },
    {
      key: ServiceAction.RENEW,
      label: (
        <TableActionLabel icon={<BankCardLine />} text={t("actions.renew")} />
      ),
    },
    {
      key: ServiceAction.UNSUBSCRIBE,
      label: (
        <TableActionLabel
          icon={<DeleteBin6Line />}
          text={t("actions.unsubscribe")}
          warning
        />
      ),
    },
  ];

  const onCloseUnsubModal = () => {
    setUnsubbingService(null);
  };

  const onClickActionItems = (key: string, item: IWorkspacePaymentPlanItem) => {
    switch (key) {
      case ServiceAction.UPGRADE_PLAN:
        if (Object.keys(upgradeUrl).includes(item.product_plan.id)) {
          navigate(
            generatePath(upgradeUrl[item.product_plan.id], {
              workspaceId,
            })
          );
        }
        return;
      case ServiceAction.RENEW:
        navigate({
          pathname: generatePath(pathname.WORKSPACE_BILLING_RENEW, {
            workspaceId,
          }),
          search: createSearchParams({
            product_id: item.product.id,
          }).toString(),
        });
        return;
      case ServiceAction.UNSUBSCRIBE:
        setUnsubbingService(item);
        return;
    }
  };

  return isLoading || serviceList === null ? (
    <LoadingState />
  ) : serviceList.length === 0 ? (
    <NoResultsState />
  ) : (
    <div className="grid grid-cols-[4fr_4fr_3fr_3fr_3fr_4fr_4fr_4fr_4rem]">
      <TableHeader title="workspace/billing/service" headerProps={headers} />
      {serviceList.map((item) => (
        <BillingServiceTableRow
          key={`workspace/billing/service-tr${item.product_plan.id}`}
          data={item}
          actionList={actionList.filter((action) => {
            if (action.key === ServiceAction.UNSUBSCRIBE) {
              // We don't let user unsubscribe this product since it causes changes on other products.
              if (item.product_plan.id === "tc_free") {
                return false;
              }
            }
            if (action.key === ServiceAction.RENEW) {
              // Free product cannot be renewed.
              if (Boolean(item.payment_method) === false) {
                return false;
              }
            }
            return true;
          })}
          onClickActionItems={onClickActionItems}
        />
      ))}
      <BillingServiceUnsubModal
        unsubbingService={unsubbingService}
        onClose={onCloseUnsubModal}
        currentWorkspace={currentWorkspace}
      />
    </div>
  );
};

export default BillingServiceTable;
