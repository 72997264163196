import { useState, useMemo, useEffect, ReactNode } from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import dlpService from "#src/services/endpoint/dlp";
import constants from "#src/config/constants";
import type { IDLPDrive } from "#src/services/endpoint/dlp/types";
import ManageSnapshotsModal from "#src/components/endpoint/dlp/ManageSnapshotsModal";
import ScheduleBackupModal from "#src/components/endpoint/dlp/ScheduleBackupModal";
import { ButtonV2 } from "#src/commonV2/Button";
import DeleteScheduleModal from "#src/components/endpoint/dlp/DeleteScheduleModal";
import EnableMultipleBackupConfirmModal from "#src/components/endpoint/dlp/EnableMultipleBackupConfirmModal";
import { useWorkspaceWebSocket } from "#src/components/endpoint/dlp/useWorkspaceWebSocket";
import { BreadcrumbV2 } from "#src/commonV2/Breadcrumb";
import { useTranslation } from "react-i18next";
import { DLPDriveBackupStatus } from "#src/services/endpoint/dlp/enums";
import { SearchboxV2 } from "#src/commonV2/Table/Searchbox";
import { FilterDropdownV2 } from "#src/commonV2/Table/Filter/FilterDropdown";
import { IFilterItemV2 } from "#src/commonV2/Table/Filter/types";
import { backupEnabledFilterItems } from "#src/components/endpoint/dlp/backup/filterItems";
import { TableActionLabelV2 } from "#src/commonV2/Table/TableActionLabel";
import {
  ClockPlus,
  ClockRewind,
  DotsVertical,
  Edit02,
  Repeat04,
  Transform,
  Trash03,
  UploadCloud01,
} from "@untitled-ui/icons-react";
import { pathname } from "#src/config/pathname";
import { TableV2 } from "#src/commonV2/Table";
import { Dropdown, Tooltip } from "@lockerpm/design";
import { DropdownRenderV2 } from "#src/commonV2/antdHelpers/DropdownRender";
import { byteToGigabyte } from "#src/utils/common";

export const DlpBackup = () => {
  const { t } = useTranslation("endpoint", { keyPrefix: "dlp.backup" });

  const { workspaceId } = useParams<"workspaceId">();

  if (!workspaceId) {
    return null;
  }

  // --------------- DATA ---------------

  const [driveList, setDriveList] = useState<IDLPDrive[]>([]);

  // Pagination
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  // Search/sort
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");

  // Filter
  const [backupEnabled, setBackupEnabled] = useState<IFilterItemV2 | null>(
    null
  );

  // Selection
  const [selectedDriveIds, setSelectedDriveIds] = useState<number[]>([]);

  // Select 1 drive
  const [selectedDrive, setSelectedDrive] = useState<IDLPDrive>();
  const [isSnapshotsModalOpen, setIsSnapshotsModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [
    isConfirmMultipleScheduleModalOpen,
    setIsConfirmMultipleScheduleModalOpen,
  ] = useState(false);
  const [isDeleteScheduleModalOpen, setIsDeleteScheduleModalOpen] =
    useState(false);

  // --------------- COMPUTED ---------------

  const fetchParams = useMemo<Parameters<typeof dlpService.listDLPDrives>>(
    () => [
      workspaceId,
      {
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
        sort: sortOrder ?? undefined,
        backup_enabled: backupEnabled?.value,
      },
    ],
    [
      workspaceId,
      searchKeyword,
      selectedPage,
      pageSize,
      sortOrder,
      backupEnabled,
    ]
  );

  // Drives already having an active schedule
  const conflictDriveIds = useMemo(() => {
    return driveList
      .filter(
        (item) => item.backup_enabled && selectedDriveIds.includes(item.id)
      )
      .map((item) => item.id);
  }, [driveList, selectedDriveIds]);

  // --------------- HOOKS ---------------

  const {
    list: driveListRes,
    count: driveCount,
    isLoading,
    updateData: reloadDriveList,
  } = useFetchPaginated(dlpService.listDLPDrives, fetchParams);

  // --------------- METHODS ---------------

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  // On change filter
  const onChangeBackupEnabled = (selected: typeof backupEnabled) => {
    setBackupEnabled(selected);
    setSelectedPage(1);
  };

  // Action list for kebab menu
  const getTableActions = (record: IDLPDrive) => {
    const actions: {
      key: string;
      label: ReactNode;
      action: () => void;
    }[] = [
      {
        key: "edit",
        label: record.backup_enabled ? (
          <TableActionLabelV2
            icon={<Edit02 className="h-5 w-5" />}
            text={t("table.action.editSchedule")}
          />
        ) : (
          <TableActionLabelV2
            icon={<ClockPlus className="h-5 w-5" />}
            text={t("table.action.enableBackup")}
          />
        ),
        action: () => {
          setSelectedDrive(record);
          setIsScheduleModalOpen(true);
        },
      },
    ];

    // Allow restore if it is not restoring has at least 1 snapshot
    if (record.snapshots > 0) {
      actions.push({
        key: "manage_snapshot",
        label: (
          <TableActionLabelV2
            icon={<Transform className="h-5 w-5" />}
            text={t("table.action.manageSnapshots")}
          />
        ),
        action: () => {
          setSelectedDrive(record);
          setIsSnapshotsModalOpen(true);
        },
      });
    }

    // Disable schedule
    if (record.backup_enabled) {
      actions.push({
        key: "disable",
        label: (
          <TableActionLabelV2
            icon={<Trash03 className="h-5 w-5" />}
            text={t("table.action.disableBackup")}
            warning
          />
        ),
        action: () => {
          setSelectedDrive(record);
          setIsDeleteScheduleModalOpen(true);
        },
      });
    }
    return actions;
  };

  const getBackupStatusIcon = (status: IDLPDrive["backup_status"]) => {
    if (status === DLPDriveBackupStatus.BACKING_UP) {
      return (
        <Tooltip title={t("table.tooltip.deviceBackingUp")}>
          <UploadCloud01 className="h-5 min-w-5 text-success-v2" />
        </Tooltip>
      );
    }
    if (status === DLPDriveBackupStatus.PENDING_RESTORE) {
      return (
        <Tooltip title={t("table.tooltip.deviceWaitingRestore")}>
          <ClockRewind className="h-5 min-w-5 text-gray-v2" />
        </Tooltip>
      );
    }
    if (status === DLPDriveBackupStatus.RESTORING) {
      return (
        <Tooltip title={t("table.tooltip.deviceRestoring")}>
          <Repeat04 className="h-5 min-w-5 text-orange-v2" />
        </Tooltip>
      );
    }
    return null;
  };

  // --------------- EFFECTS ---------------

  // Clone data to local state to update locally without having to recall API
  useEffect(() => {
    setDriveList(driveListRes || []);
  }, [driveListRes]);

  // Update data when receive events from websocket
  useWorkspaceWebSocket({
    setIsSnapshotsModalOpen,
    setDriveList,
    setSelectedDrive,
    selectedDrive,
  });

  // --------------- RENDER ---------------

  return (
    <>
      <div className="pt-5 pb-6 flex flex-col gap-5 min-h-screen">
        {/* Header (Breadcrumb + title) */}
        <div className="px-8 flex flex-col gap-4">
          <BreadcrumbV2
            levels={[
              {
                name: t("breadcrumb.levels.endpoint"),
                path: generatePath(pathname.ENDPOINT_OVERVIEW, { workspaceId }),
              },
            ]}
            currentPage={{
              name: t("breadcrumb.current.dataLossPrevention"),
              tab: t("breadcrumb.current.drives"),
            }}
          />
          <span className="text-display-xs font-semibold text-gray-v2-900">
            {t("title")}
          </span>
        </div>
        {/* Header (Breadcrumb + title) end */}

        <div className="px-8 flex flex-col gap-4 flex-1">
          <div className="flex justify-between items-center gap-2">
            {/* Filters */}
            <div className="flex gap-2">
              {selectedDriveIds.length === 0 ? (
                <FilterDropdownV2
                  label={t("table.filter.backupEnabled.label")}
                  options={backupEnabledFilterItems}
                  selected={backupEnabled}
                  onChangeSelection={onChangeBackupEnabled}
                />
              ) : null}
            </div>
            {/* Filters end */}

            <div className="flex gap-2">
              {/* Bulk actions */}
              {selectedDriveIds.length > 0 && (
                <div className="flex gap-2 v2">
                  <ButtonV2
                    variant="secondary"
                    size="md"
                    color="brand"
                    onClick={() => {
                      if (conflictDriveIds.length) {
                        setIsConfirmMultipleScheduleModalOpen(true);
                      } else {
                        setIsScheduleModalOpen(true);
                      }
                    }}
                  >
                    <p>{t("table.bulkAction.scheduleAll")}</p>
                  </ButtonV2>

                  <ButtonV2
                    variant="secondary"
                    size="md"
                    color="error"
                    onClick={() => {
                      setIsDeleteScheduleModalOpen(true);
                    }}
                  >
                    <p>{t("table.bulkAction.stopAll")}</p>
                  </ButtonV2>
                </div>
              )}
              {/* Bulk actions end  */}

              {/* Searchbox */}
              <SearchboxV2
                value={searchKeyword}
                onChange={(value) => {
                  setSearchKeyword(value);
                }}
                placeholder={t("table.filter.searchbox.placeholder")}
              />
              {/* Searchbox end */}
            </div>
          </div>

          {/* Drives Table */}
          <TableV2<IDLPDrive>
            data={driveList}
            getRowKey={(item) => item.id.toString()}
            isLoading={isLoading}
            columns={[
              {
                title:
                  selectedDriveIds.length === 0 ? (
                    t("table.header.device")
                  ) : (
                    <span className="text-brand-v2">
                      {selectedDriveIds.length +
                        " " +
                        t("table.header.selected")}
                    </span>
                  ),
                overwriteWrapperProps: {
                  className: "font-medium text-gray-v2-900",
                },
                render: (item) => (
                  <>
                    {item.name}
                    {getBackupStatusIcon(item.backup_status)}
                  </>
                ),
                gridTemplateValue: "6fr",
                sortingKey: { asc: "name_asc", desc: "name_desc" },
              },
              {
                title: t("table.header.drivePath"),
                render: (item) => item.drive_path,
                gridTemplateValue: "5fr",
                sortingKey: { asc: "drive_path_asc", desc: "drive_path_desc" },
              },
              {
                title: t("table.header.user"),
                render: (item) => item.email,
                gridTemplateValue: "6fr",
                sortingKey: { asc: "email_asc", desc: "email_desc" },
              },
              {
                title: t("table.header.backupEnabled"),
                titleAlign: "center",
                render: (item) => (
                  <div
                    className={`rounded-full border text-sm font-medium px-3 py-1 w-full flex items-center justify-center ${item.backup_enabled ? "border-success-v2-200 text-success-v2-600 bg-success-v2-50" : "border-error-v2-200 text-error-v2-600 bg-error-v2-50"}`}
                  >
                    {item.backup_enabled
                      ? t("table.enabled.yes")
                      : t("table.enabled.no")}
                  </div>
                ),
                gridTemplateValue: "10rem",
              },
              {
                title: t("table.header.snapshots"),
                render: (item) =>
                  t("table.snapshots", { count: item.snapshots }),
                gridTemplateValue: "3fr",
                sortingKey: { asc: "snapshots_asc", desc: "snapshots_desc" },
              },
              {
                title: t("table.header.snapshotTotalSize"),
                render: (item) =>
                  `${byteToGigabyte(item.snapshots_total_size).toFixed(1)} GB`,
                gridTemplateValue: "4fr",
                sortingKey: {
                  asc: "snapshots_total_size_asc",
                  desc: "snapshots_total_size_desc",
                },
              },
              {
                title: null,
                render: (item) => {
                  const actions = getTableActions(item);

                  return (
                    <Dropdown
                      menu={{
                        items: actions,
                        onClick: ({ key, domEvent }) => {
                          domEvent.preventDefault();
                          domEvent.stopPropagation();
                          actions
                            .find((action) => action.key === key)
                            ?.action();
                        },
                      }}
                      dropdownRender={(menu) => (
                        <DropdownRenderV2>{menu}</DropdownRenderV2>
                      )}
                      trigger={["click"]}
                      disabled={selectedDriveIds.length > 0}
                    >
                      <button>
                        <DotsVertical
                          className={`h-5 w-5 ${selectedDriveIds.length > 0 ? "text-gray-v2-200" : "text-gray-v2-400"} transition-colors duration-300`}
                        />
                      </button>
                    </Dropdown>
                  );
                },
                overwriteWrapperProps: { className: "justify-center" },
                gridTemplateValue: "2fr",
              },
            ]}
            rowSelection={{
              selectedRowKeys: selectedDriveIds.map((item) => item.toString()),
              onChange: (value) => {
                setSelectedDriveIds(value.map((item) => Number(item)));
              },
            }}
            sort={{
              value: sortOrder,
              onChange: (value) => {
                setSortOrder(value);
              },
            }}
            pagination={{
              count: driveCount,
              selected: selectedPage,
              onChange: onChangePage,
              size: { value: pageSize, onChange: onChangePageSize },
            }}
            options={{
              fitPageHeight: true,
              emptyState: {
                title: t("table.emptyState.title"),
                supportingText: t("table.emptyState.supportingText"),
                buttons: (
                  // TODO: link this to device
                  <Link
                    to={generatePath(pathname.ENDPOINT_OVERVIEW, {
                      workspaceId,
                    })}
                  >
                    <ButtonV2 variant="secondary" color="gray">
                      {t("table.emptyState.button.deployAgent")}
                    </ButtonV2>
                  </Link>
                ),
              },
            }}
          />
          {/* Drives Table end */}
        </div>
      </div>

      {/* Manage snapshots modal */}
      <ManageSnapshotsModal
        isOpen={isSnapshotsModalOpen}
        workspaceId={workspaceId}
        driveId={selectedDrive?.id}
        driveStatus={selectedDrive?.backup_status}
        closeModal={() => {
          setIsSnapshotsModalOpen(false);
          setSelectedDrive(undefined);
        }}
        setDriveList={setDriveList}
        setSelectedDrive={setSelectedDrive}
      />
      {/* Manage snapshots modal end */}

      {/* Confirm multiple schedule modal */}
      <EnableMultipleBackupConfirmModal
        isOpen={isConfirmMultipleScheduleModalOpen}
        conflictCount={conflictDriveIds.length}
        onDeselect={() => {
          if (conflictDriveIds.length === selectedDriveIds.length) {
            setSelectedDriveIds([]);
          } else {
            setSelectedDriveIds(
              selectedDriveIds.filter((id) => !conflictDriveIds.includes(id))
            );
            setIsScheduleModalOpen(true);
          }
        }}
        onReplaceExisting={() => {
          setIsScheduleModalOpen(true);
        }}
        closeModal={() => {
          setIsConfirmMultipleScheduleModalOpen(false);
        }}
      />
      {/* Confirm multiple schedule modal end */}

      {/* Schedule modal */}
      <ScheduleBackupModal
        isOpen={isScheduleModalOpen}
        workspaceId={workspaceId}
        driveIds={selectedDrive?.id ? [selectedDrive.id] : selectedDriveIds}
        isSelectedDriveEnabled={selectedDrive?.backup_enabled}
        closeModal={() => {
          setIsScheduleModalOpen(false);
          setSelectedDrive(undefined);
        }}
        setDriveList={setDriveList}
        listFilter={backupEnabled}
        reloadDriveList={reloadDriveList}
      />
      {/* Schedule modal end */}

      {/* Delete Schedule modal */}
      <DeleteScheduleModal
        isOpen={isDeleteScheduleModalOpen}
        workspaceId={workspaceId}
        driveIds={selectedDrive?.id ? [selectedDrive.id] : selectedDriveIds}
        closeModal={() => {
          setIsDeleteScheduleModalOpen(false);
          setSelectedDrive(undefined);
        }}
        setDriveList={setDriveList}
        listFilter={backupEnabled}
        reloadDriveList={reloadDriveList}
      />
      {/* Delete Schedule modal end */}
    </>
  );
};
