// Pathname layout config. Here's where you config which page you want to hide the sidebar, or which sidebar do you want to show...

import { helperPathname, pathname } from "#src/config/pathname";

/** A list of paths that should show no sidebar. */
export const noSidebarPaths = [
  pathname.VULNERABILITIES,
  pathname.NEW_WORKSPACE,
  pathname.DATA_LEAK_ADD_KEYWORD_PAYMENT,
  pathname.TRUST_CENTER_REGISTER_PRIVATE_DOMAIN,
  pathname.WORKSPACE_BILLING_RENEW,
  pathname.WORKSPACE_BILLING_UPGRADE_TRUST_CENTER,
  pathname.WORKSPACE_BILLING_UPGRADE_TC_CUSTOM_DOMAIN,
];

/** A list of paths that shows MSSP sidebar. */
export const msspPaths = [helperPathname.MSSP];

/** A list of paths that shows setting sidebar. */
export const accountSettingsPaths = [
  helperPathname.ACCOUNT,
  helperPathname.WORKSPACE,
];
