// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import global from "#src/config/global";
import type { IFilterItem } from "#src/@types/common";

// Components
import {
  FilterDropdown,
  SearchBox,
  SelectAllItemsDropdown,
} from "#src/common/Table/Filter";
import { IconButton } from "#src/common/system/Button";

// Children
import { scheduleEnableStatusItems } from "#src/config/filter/scan";

interface IScheduleFilterProps {
  domains: IFilterItem | null;
  enableStatus: IFilterItem | null;
  searchKeyword: string;
  totalCount: number;
  selectedCount: number;
  domainItems: IFilterItem[];
  onChangeSearchKeyword: (keyword: string) => void;
  onChangeDomainsSelection: (selected: IFilterItem) => void;
  onChangeEnableStatusSelection: (selected: IFilterItem) => void;
  onSelectAllSchedules: () => void;
  onDeselectAllSchedules: () => void;
  onDeleteSelectedSchedules: () => void;
  onEnableSelectedSchedules: () => void;
  onDisableSelectedSchedules: () => void;
}

const ScheduleFilter = ({
  domains,
  enableStatus,
  searchKeyword,
  totalCount,
  selectedCount,
  domainItems,
  onChangeDomainsSelection,
  onChangeEnableStatusSelection,
  onChangeSearchKeyword,
  onSelectAllSchedules,
  onDeselectAllSchedules,
  onDeleteSelectedSchedules,
  onEnableSelectedSchedules,
  onDisableSelectedSchedules,
}: IScheduleFilterProps) => {
  const { t } = useTranslation("scanSchedules", { keyPrefix: "filter" });

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <SelectAllItemsDropdown
          selectedCount={selectedCount}
          totalCount={totalCount}
          onSelectAll={onSelectAllSchedules}
          onDeselectAll={onDeselectAllSchedules}
        />
        <IconButton
          variant="ghost"
          disabled={selectedCount === 0}
          onClick={() => {
            global.confirm(() => onDeleteSelectedSchedules());
          }}
        >
          <DeleteBin6Line />
        </IconButton>
        <button
          className="h-11 rounded-md flex justify-center items-center text-hard-grey disabled:text-button-disabled"
          disabled={selectedCount === 0}
          onClick={() => {
            onEnableSelectedSchedules();
          }}
        >
          <span className="p-2">{t("button.enable")}</span>
        </button>
        <button
          className="h-11 rounded-md flex justify-center items-center text-hard-grey disabled:text-button-disabled"
          disabled={selectedCount === 0}
          onClick={() => {
            onDisableSelectedSchedules();
          }}
        >
          <span className="p-2">{t("button.disable")}</span>
        </button>
        <FilterDropdown
          label={t("domains.label")}
          selected={domains}
          options={domainItems}
          onChangeSelection={onChangeDomainsSelection}
        />
        <FilterDropdown
          label={t("enableStatus.label")}
          selected={enableStatus}
          options={scheduleEnableStatusItems}
          onChangeSelection={onChangeEnableStatusSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default ScheduleFilter;
