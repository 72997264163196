// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import TimePopover from "#src/common/TimePopover";
import type { TimeConditionEnum } from "#src/common/TimePopover/TimePopoverContent";
import { FilterDropdown, SearchBox } from "#src/common/Table/Filter";

// API-related
import { protocolItems } from "#src/config/filter/asset";

interface IPortFilterProps {
  searchKeyword: string;
  protocol: IFilterItem | null;
  onChangeTimeConditionValue: (
    condition: string,
    from: number | null,
    to: number | null
  ) => void;
  onChangeProtocolSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
}

const PortFilter = ({
  searchKeyword,
  protocol,
  onChangeTimeConditionValue,
  onChangeProtocolSelection,
  onChangeSearchKeyword,
}: IPortFilterProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.ports.filter",
  });

  const [timeCondition, setTimeCondition] = useState<TimeConditionEnum | null>(
    null
  );
  const [timePopoverOpen, setTimePopoverOpen] = useState<boolean>(false);
  const [timeFilterText, setTimeFilterText] = useState<string>("");

  const onChangeTimeCondition = (
    text: string,
    condition: TimeConditionEnum | null,
    from: number | null,
    to: number | null
  ) => {
    setTimeCondition(condition);
    setTimeFilterText(text);
    onChangeTimeConditionValue(text, from, to);
  };

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <TimePopover
          label={t("timePopover.label")}
          timeCondition={timeCondition}
          textDisplay={timeFilterText}
          open={timePopoverOpen}
          setOpen={(value) => setTimePopoverOpen(value)}
          onChangeTime={onChangeTimeCondition}
        />
        <FilterDropdown
          label={t("protocol.label")}
          selected={protocol}
          options={protocolItems}
          onChangeSelection={onChangeProtocolSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default PortFilter;
