// Libraries
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as CheckLine } from "#src/assets/images/icons/check-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import { Input } from "#src/common/system/Input";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import Loader from "#src/common/system/Loader";

// API-related
import {
  pentestScopeScopeItems,
  pentestScopeTypeItems,
} from "#src/config/filter/pentest";

// Children
import type { IPentestScopeInputItem } from "../../AddPentest/Scope";

interface IPentestDetailsScopeTableProps {
  scope: IPentestScopeInputItem[];
  invalidAssets: boolean;
  loadingScope: string[];
  activateEditScope: (id: string) => void;
  deactivateEditScope: (id: string) => void;
  removeScope: (id: string) => void;
  addScope: () => void;
  onChangeScopeAssetType: (id: string, key: string) => void;
  onChangeScopeAssets: (id: string, value: string) => void;
  onChangeScopeScope: (id: string, key: string) => void;
}

const PentestDetailsScopeTable = ({
  scope,
  invalidAssets,
  loadingScope,
  activateEditScope,
  deactivateEditScope,
  removeScope,
  addScope,
  onChangeScopeAssetType,
  onChangeScopeAssets,
  onChangeScopeScope,
}: IPentestDetailsScopeTableProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.addPentest.scope",
  });

  return (
    <>
      <div className="grid grid-cols-[6rem_1fr_1fr_1fr_8rem] gap-y-6">
        <div className="grid grid-cols-subgrid col-span-full gap-x-6 bg-bright-grey text-hard-grey py-5 px-9">
          <div>#</div>
          <div>{t("header.assetType")}</div>
          <div>{t("header.assets")}</div>
          <div>{t("header.scope")}</div>
          <div></div>
        </div>
        {scope.map((scopeItem, scopeItemId) => (
          <div
            key={`scope-${scopeItem.id}`}
            className="grid grid-cols-subgrid col-span-full gap-x-6 px-9 h-11"
          >
            <div className="flex items-center">{scopeItemId + 1}</div>
            <div className="flex items-center">
              {scopeItem.editing ? (
                <Dropdown
                  menu={{
                    items: pentestScopeTypeItems.map((item) => ({
                      key: item.key,
                      label: (
                        <DropdownItemLabel
                          syncWidthId="pentest-scope-type-dropdown"
                          selected={scopeItem.assetType.key === item.key}
                        >
                          {item.getLabel()}
                        </DropdownItemLabel>
                      ),
                    })),
                    onClick: ({ key }) => {
                      onChangeScopeAssetType(scopeItem.id, key);
                    },
                  }}
                  trigger={["click"]}
                >
                  <DropdownTriggerButton
                    id="pentest-scope-type-dropdown"
                    className="w-full"
                  >
                    {scopeItem.assetType.getLabel()}
                    <ArrowDownSLine />
                  </DropdownTriggerButton>
                </Dropdown>
              ) : (
                scopeItem.assetType.getLabel()
              )}
            </div>
            <div className="flex items-center">
              {scopeItem.editing ? (
                <Input
                  value={scopeItem.assets}
                  onChange={(e) => {
                    onChangeScopeAssets(scopeItem.id, e.currentTarget.value);
                  }}
                />
              ) : (
                scopeItem.assets
              )}
            </div>
            <div className="flex items-center">
              {scopeItem.editing ? (
                <Dropdown
                  menu={{
                    items: pentestScopeScopeItems.map((item) => ({
                      key: item.key,
                      label: (
                        <DropdownItemLabel
                          syncWidthId="pentest-scope-scope-dropdown"
                          selected={scopeItem.scope.key === item.key}
                        >
                          {item.getLabel()}
                        </DropdownItemLabel>
                      ),
                    })),
                    onClick: ({ key }) => {
                      onChangeScopeScope(scopeItem.id, key);
                    },
                  }}
                  trigger={["click"]}
                >
                  <DropdownTriggerButton
                    id="pentest-scope-scope-dropdown"
                    className="w-full"
                  >
                    {scopeItem.scope.getLabel()}
                    <ArrowDownSLine />
                  </DropdownTriggerButton>
                </Dropdown>
              ) : (
                scopeItem.scope.getLabel()
              )}
            </div>
            <div className="flex items-center justify-center gap-1">
              {loadingScope.includes(scopeItem.id) ? (
                <Loader baseSize={2} />
              ) : (
                <>
                  {scopeItem.editing ? (
                    <IconButton
                      variant={
                        !scopeItem.assets && invalidAssets
                          ? "warning"
                          : "secondary"
                      }
                      size={9}
                      onClick={() => {
                        deactivateEditScope(scopeItem.id);
                      }}
                    >
                      <CheckLine className="h-5 w-5" />
                    </IconButton>
                  ) : (
                    <IconButton
                      variant="secondary"
                      size={9}
                      onClick={() => {
                        activateEditScope(scopeItem.id);
                      }}
                    >
                      <EditLine className="h-5 w-5" />
                    </IconButton>
                  )}
                  <IconButton
                    variant="warning"
                    size={9}
                    onClick={() => {
                      removeScope(scopeItem.id);
                    }}
                  >
                    <CloseLine className="h-5 w-5" />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      {scope.some((s) => !s.assets) && invalidAssets ? (
        <div className="text-warning font-regular-14 flex items-center gap-1">
          <InformationLine className="h-5 w-5" />
          {"Invalid asset name"}
        </div>
      ) : null}
      <div className="flex justify-center">
        <Button variant="text" onClick={addScope}>
          <AddLine />
          {t("button.addScope")}
        </Button>
      </div>
    </>
  );
};

export default PentestDetailsScopeTable;
