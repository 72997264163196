import { useRef } from "react";
import { XClose } from "@untitled-ui/icons-react";
import { Tooltip } from "@lockerpm/design";
import { TAILWIND_COLORS } from "#src/utils/common";

type Props = {
  tags: string[];
  setTags: (tags: string[]) => void;
  disabled?: boolean;
};

export const TagsInput = (props: Props) => {
  const { tags, setTags, disabled } = props;

  // ----------------------------- DATA -----------------------------

  const inputRef = useRef<HTMLInputElement>(null);

  // ----------------------------- METHODS -----------------------------

  const removeTag = (index: number) => {
    if (disabled) return;
    setTags(tags.filter((_, i) => i !== index));
  };

  // ----------------------------- RENDER -----------------------------

  return (
    <div
      className="v2 tags-input"
      onClick={(e) => {
        // If the clicked element is not an input or tag, focus the input
        if (e.target === e.currentTarget) {
          inputRef.current?.focus();
        }
      }}
    >
      {tags.map((tag, index) => (
        <div key={index} className="tag">
          {tag.length > 12 ? (
            <Tooltip
              color={TAILWIND_COLORS["gray-v2-900"]}
              title={
                <p className="text-xs text-white font-semibold text-center">
                  {tag}
                </p>
              }
            >
              <p>{tag}</p>
            </Tooltip>
          ) : (
            <p>{tag}</p>
          )}

          <XClose
            className="cursor-pointer text-gray-v2-400"
            height={"1rem"}
            width={"1rem"}
            onClick={() => removeTag(index)}
          />
        </div>
      ))}

      <input
        type="text"
        disabled={disabled}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setTags([...tags, inputRef.current?.value || ""]);
            inputRef.current!.value = "";
          }
        }}
      />
    </div>
  );
};
