import request from "../utils/request";
import endpoint from "../config/endpoint";

export interface ITagItem {
  id: number;
  name: string;
  archived: boolean;
  is_default: boolean;
  created_time: number;
  description: {
    en: string;
    vi: string;
  };
  // There's 2 types for now: Domain & Host
  type: string;
}

function listTagsNoPaging(workspace_id: string, params?: { type?: string }) {
  return request.get<never, ITagItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/tags`,
    { params: { paging: 0, strictly: 1, type: params?.type } }
  );
}

export const tagService = { listTagsNoPaging };
