// Libraries
import { useTranslation } from "react-i18next";

// Components
import { TableHeader } from "#src/common/Table";
import LoadingState from "#src/common/system/LoadingState";
import { NoVulnerabilitiesState } from "#src/common/states";
import type { ITableHeaderPropItem } from "#src/common/Table/TableHeader";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import assetsServices from "#src/services/assets";
import type { IAssetVulnerabilityItem } from "#src/services/assets/vulnerabilities";

// Children
import VulnerabilityTableRow from "./TableRow";

interface IVulnerabilityTableProps {
  workspaceId: string;
  isLoading: boolean;
  vulnerabilityList: IAssetVulnerabilityItem[] | null;
  selectedVulnerabilityIds: number[];
  onChangeSelectedVulnerabilities: (selected: IAssetVulnerabilityItem) => void;
  refreshVulnerabilityList: () => void;
}

const VulnerabilityTableGrid = ({
  workspaceId,
  isLoading,
  vulnerabilityList,
  selectedVulnerabilityIds,
  onChangeSelectedVulnerabilities,
  refreshVulnerabilityList,
}: IVulnerabilityTableProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.vulnerabilities",
  });

  const headers: ITableHeaderPropItem[] = [
    { name: "", align: "left" },
    { name: t("headers.severity"), align: "left" },
    { name: t("headers.name"), align: "left" },
    { name: t("headers.cvssScore"), align: "left" },
    { name: t("headers.status"), align: "left" },
  ];

  const onChangeVulnerabilityStatus = (
    vulnerabilityId: number,
    subStatus: string
  ) => {
    assetsServices
      .update_vulnerability(vulnerabilityId, workspaceId, subStatus)
      .then(() => {
        createToast({
          type: "success",
          message: t("notification.statusUpdated.success"),
        });
        refreshVulnerabilityList();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.statusUpdated.fail"),
        });
      });
  };

  return isLoading || vulnerabilityList === null ? (
    <LoadingState />
  ) : vulnerabilityList.length === 0 ? (
    <NoVulnerabilitiesState />
  ) : (
    <div className="grid grid-cols-[2.75rem_3fr_8fr_3fr_4fr]">
      <TableHeader
        title="assets/details-vulnerabilitiesTab"
        headerProps={headers}
      />
      {vulnerabilityList.map((item) => (
        <VulnerabilityTableRow
          key={`tassets/details-vulnerabilitiesTab-tr${item.id}`}
          workspaceId={workspaceId}
          data={item}
          selectedVulnerabilityIds={selectedVulnerabilityIds}
          onChangeSelectedVulnerabilities={onChangeSelectedVulnerabilities}
          onChangeVulnerabilityStatus={onChangeVulnerabilityStatus}
        />
      ))}
    </div>
  );
};

export default VulnerabilityTableGrid;
