import { useCallback, useEffect, useState } from "react";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import workspaceServices from "#src/services/workspace";
import { updateWorkspaceList } from "#src/store/slices/workspace";
import { useNavigate } from "react-router-dom";
import { pathname } from "#src/config/pathname";
import { useAppDispatch } from "#src/store/hooks";

export const useWorkspaceList = () => {
  const [workspaceList, setWorkspaceList] = useState<
    Awaited<ReturnType<typeof workspaceServices.list_workspaces>>
  >([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchWorkspaceList = useCallback(() => {
    workspaceServices
      .list_workspaces()
      .then((response) => {
        if (response.length === 0) {
          navigate(pathname.NEW_WORKSPACE);
        }
        setWorkspaceList(response);
        dispatch(updateWorkspaceList(response));
      })
      .catch(apiErrorHandler);
  }, [navigate, dispatch]);

  useEffect(() => {
    fetchWorkspaceList();
  }, [fetchWorkspaceList]);

  return { workspaceList, update: fetchWorkspaceList };
};
