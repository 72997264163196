// Libraries
import { Dropdown } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// Components
import { Input } from "#src/common/system/Input";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";

const authMethodItems = [
  { key: "authMethod1", value: "basic", label: "Basic Auth" },
  { key: "authMethod2", value: "cookie", label: "Cookie" },
];

interface IEditAuthenticationProps {
  method: "basic" | "cookie" | null;
  authSettings: {
    cookie: string | null;
    username: string | null;
    password: string | null;
  } | null;
  onChangeAuthentication: ({
    method,
    authSettings,
  }: {
    method: "basic" | "cookie" | null;
    authSettings: {
      cookie: string | null;
      username: string | null;
      password: string | null;
    };
  }) => void;
}

export const EditAuthentication = ({
  method,
  authSettings,
  onChangeAuthentication,
}: IEditAuthenticationProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.drawer.editSettings.section.scanProfile.authentication",
  });

  return (
    <div className="flex flex-col gap-3 pt-3 pb-6 px-6">
      <h4>{t("method.label")}</h4>
      <Dropdown
        menu={{
          items: authMethodItems.map((item) => ({
            key: item.key,
            value: item.value,
            label: (
              <DropdownItemLabel
                selected={method === item.value}
                syncWidthId="dropdown-auth_method"
              >
                {item.label}
              </DropdownItemLabel>
            ),
          })),
          onClick: (obj) => {
            const newSelected = authMethodItems.find(
              (item) => item.key === obj.key
            );
            if (
              newSelected?.value === "basic" ||
              newSelected?.value === "cookie"
            )
              onChangeAuthentication({
                method: newSelected.value,
                authSettings: {
                  cookie: null,
                  username: null,
                  password: null,
                },
              });
          },
        }}
        placement="bottomRight"
        arrow
        trigger={["click"]}
      >
        <DropdownTriggerButton id="dropdown-auth_method">
          {authMethodItems.find((item) => item.value === method)?.label}
          <ArrowDownSLine
            width={"1rem"}
            height={"1rem"}
            className="fill-hard-grey"
          />
        </DropdownTriggerButton>
      </Dropdown>
      {method === "basic" ? (
        <>
          <h4>{t("username.label")}</h4>
          <Input
            placeholder={t("username.placeholder")}
            value={authSettings?.username || ""}
            onChange={(e) => {
              onChangeAuthentication({
                method,
                authSettings: authSettings
                  ? { ...authSettings, username: e.target.value }
                  : { cookie: null, password: "", username: e.target.value },
              });
            }}
            autoComplete="new-password"
          />
          <h4>{t("password.label")}</h4>
          <Input
            type="password"
            placeholder={t("password.placeholder")}
            value={authSettings?.password || ""}
            onChange={(e) => {
              onChangeAuthentication({
                method,
                authSettings: authSettings
                  ? { ...authSettings, password: e.target.value }
                  : { cookie: null, username: "", password: e.target.value },
              });
            }}
          />
        </>
      ) : (
        <>
          <h4>{t("cookie.label")}</h4>
          <Input
            placeholder={t("cookie.placeholder")}
            value={authSettings?.cookie || ""}
            onChange={(e) => {
              onChangeAuthentication({
                method,
                authSettings: authSettings
                  ? { ...authSettings, cookie: e.target.value }
                  : { username: null, password: null, cookie: e.target.value },
              });
            }}
          />
        </>
      )}
    </div>
  );
};
