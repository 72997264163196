// Libraries
import { Dropdown } from "@lockerpm/design";
import type { ReactNode } from "react";
import { Plus, XClose } from "@untitled-ui/icons-react";

// Components
import { ButtonV2 } from "#src/commonV2/Button";
import { DropdownRenderV2 } from "#src/commonV2/antdHelpers/DropdownRender";
import {
  DropdownItemRenderV2,
  type IDropdownItemRenderV2Props,
} from "#src/commonV2/antdHelpers/DropdownItemRender";

// Children
import type { IFilterItemV2 } from "./types";

interface IFilterDropdownProps {
  type?: "normal" | "hidden";
  label: string;
  selected: IFilterItemV2 | null;
  options: IFilterItemV2[];
  onChangeSelection: (selected: IFilterItemV2 | null) => void;
  CustomLabel?: (props: IDropdownItemRenderV2Props) => ReactNode;
  DropdownRender?: (originNode: ReactNode) => ReactNode;
}

export const FilterDropdownV2 = ({
  type = "normal",
  label,
  selected,
  options,
  onChangeSelection,
  CustomLabel,
  DropdownRender,
}: IFilterDropdownProps) => {
  return type === "hidden" && selected === null ? null : (
    <Dropdown
      menu={{
        items: options.map((item) => ({
          key: item.key,
          value: item.value,
          label: CustomLabel ? (
            <CustomLabel item={item} selected={selected} />
          ) : (
            <DropdownItemRenderV2 item={item} selected={selected} />
          ),
        })),
        onClick: (obj) => {
          const newSelected = options.find((item) => item.key === obj.key);
          onChangeSelection(newSelected ? newSelected : null);
        },
      }}
      dropdownRender={
        DropdownRender
          ? DropdownRender
          : (menu) => <DropdownRenderV2>{menu}</DropdownRenderV2>
      }
      placement="bottomRight"
      trigger={["click"]}
    >
      <ButtonV2
        variant="secondary"
        color={type === "normal" ? "gray" : "brand"}
        className="outline outline-2 outline-offset-2 outline-transparent focus:outline-brand-v2-400"
      >
        {selected ? (
          <div className="flex items-center gap-1">
            <span>{label}:</span>
            {selected.getLabel()}
            <XClose
              className="h-5 w-5"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChangeSelection(null);
              }}
            />
          </div>
        ) : (
          <>
            <span>{label}</span>
            <Plus className="h-5 w-5 min-w-5" />
          </>
        )}
      </ButtonV2>
    </Dropdown>
  );
};
