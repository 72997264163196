// Libraries
import { useTranslation } from "react-i18next";

// General
import type { IFilterItem } from "#src/@types/common";
import TimePopover from "#src/common/TimePopover";

// Components
import { FilterDropdown, SearchBox } from "#src/common/Table/Filter";

// API-related
import {
  algorithmOptions,
  expirationItems,
  keySizeOptions,
} from "#src/config/filter/asset";
import { useState } from "react";
import { TimeConditionEnum } from "#src/common/TimePopover/TimePopoverContent";

interface ICertificateFilterProps {
  expiration: IFilterItem | null;
  algorithm: IFilterItem | null;
  keySize: IFilterItem | null;
  searchKeyword: string;
  onChangeExpiration: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
  onChangeAlgorithm: (selected: IFilterItem | null) => void;
  onChangeKeySize: (selected: IFilterItem | null) => void;
  onChangeTimeConditionValue: (
    condition: string,
    from: number | null,
    to: number | null
  ) => void;
}

const CertificateFilter = ({
  expiration,
  algorithm,
  keySize,
  searchKeyword,
  onChangeExpiration,
  onChangeSearchKeyword,
  onChangeAlgorithm,
  onChangeKeySize,
  onChangeTimeConditionValue,
}: ICertificateFilterProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "certificates.page.certificates.filter",
  });

  const [timeCondition, setTimeCondition] = useState<TimeConditionEnum | null>(
    null
  );
  const [timePopoverOpen, setTimePopoverOpen] = useState<boolean>(false);
  const [timeFilterText, setTimeFilterText] = useState<string>("");

  const onChangeTimeCondition = (
    text: string,
    condition: TimeConditionEnum | null,
    from: number | null,
    to: number | null
  ) => {
    setTimeCondition(condition);
    setTimeFilterText(text);
    onChangeTimeConditionValue(text, from, to);
  };

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 whitespace-nowrap flex-wrap">
        <FilterDropdown
          label={t("expiration.label")}
          selected={expiration}
          options={expirationItems}
          onChangeSelection={onChangeExpiration}
        />
        <FilterDropdown
          label={t("algorithm.label")}
          selected={algorithm}
          options={algorithmOptions}
          onChangeSelection={onChangeAlgorithm}
        />
        <FilterDropdown
          label={t("key_size.label")}
          selected={keySize}
          options={keySizeOptions}
          onChangeSelection={onChangeKeySize}
        />
        <TimePopover
          label={t("last_found.label")}
          timeCondition={timeCondition}
          textDisplay={timeFilterText}
          open={timePopoverOpen}
          setOpen={(value) => setTimePopoverOpen(value)}
          onChangeTime={onChangeTimeCondition}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default CertificateFilter;
