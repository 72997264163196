// Libraries
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";
import { Tooltip } from "@lockerpm/design";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// General
import constants from "#src/config/constants";
import { useAppSelector } from "#src/store/hooks";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/common/system/Button";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import trustCenterServices from "#src/services/trustCenter";

// Children
import TrustCenterDocumentTableGrid from "#src/components/trustCenter/document/DocumentTable/Table";
import AddDocumentModal from "#src/components/trustCenter/document/AddDocumentModal";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";
import { TrustCenterPlanValue } from "#src/config/filter/trustCenter/value";

const TrustCenterDocument = () => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "document" });

  const { workspaceId } = useParams<"workspaceId">();

  const trustCenterPlan = useAppSelector((state) => state.trustCenter.plan);

  const [openAddDrawer, setOpenAddDrawer] = useState<boolean>(false);

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  const [fileSettings, setFileSettings] =
    useState<
      Awaited<
        ReturnType<
          typeof trustCenterServices.retrieve_trust_center_file_settings
        >
      >
    >();
  const fetchParams = useMemo<
    Parameters<typeof trustCenterServices.list_trust_center_document>
  >(
    () => [
      workspaceId ?? "",
      { q: undefined, page: 1, size: constants.DEFAULT_PAGE_SIZE },
    ],
    [workspaceId]
  );

  const {
    list: documentList,
    count: documentCount,
    isLoading,
    updateData: updateDocumentList,
  } = useFetchPaginated(
    trustCenterServices.list_trust_center_document,
    fetchParams
  );

  const isDisabledAddButton =
    documentList === null ||
    (trustCenterPlan === TrustCenterPlanValue.FREE && documentCount >= 10);

  useEffect(() => {
    if (workspaceId) {
      trustCenterServices
        .retrieve_trust_center_file_settings(workspaceId)
        .then((response) => {
          setFileSettings(response);
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId]);

  return workspaceId ? (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        {currentWorkspace?.role === WorkspaceMemberRoleValue.ADMIN ||
        currentWorkspace?.role === WorkspaceMemberRoleValue.OWNER ? (
          <Tooltip
            title={
              isDisabledAddButton
                ? t("errorMessage.documentAmountLimitReached")
                : null
            }
          >
            <div>
              <Button
                size="large"
                onClick={() => {
                  setOpenAddDrawer(true);
                }}
                disabled={isDisabledAddButton}
              >
                <AddLine className="h-5 w-5" />
                {t("button.addDocument")}
              </Button>
            </div>
          </Tooltip>
        ) : null}
      </StickySection>
      <ContentSection>
        <TrustCenterDocumentTableGrid
          workspaceId={workspaceId}
          documentList={documentList}
          isLoading={isLoading}
          onRefresh={updateDocumentList}
          maxFileSize={fileSettings ? fileSettings.file.max_file_size : null}
        />
        <AddDocumentModal
          workspaceId={workspaceId}
          open={openAddDrawer}
          onClose={() => {
            setOpenAddDrawer(false);
          }}
          onRefresh={updateDocumentList}
          maxFileSize={fileSettings ? fileSettings.file.max_file_size : null}
        />
      </ContentSection>
    </>
  ) : null;
};

export default TrustCenterDocument;
