// Libraries
import { Checkbox, Drawer } from "@lockerpm/design";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import { DataLeakTypeValue } from "#src/config/filter/dataLeak/value";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { createToast } from "#src/common/system/toasts";

// General
import dataLeakService from "#src/services/dataLeak";

interface IDataLeakExportDrawerProps extends IDrawerAdapterProps {}

const DataLeakExportDrawer = ({
  open,
  onClose,
  onRefresh,
  workspaceId,
}: IDataLeakExportDrawerProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "foundLeaks.exportDrawer",
  });

  const [selectedTypes, setSelectedTypes] = useState<DataLeakTypeValue[]>([
    DataLeakTypeValue.ACCOUNT,
    DataLeakTypeValue.COOKIE,
    DataLeakTypeValue.EMAIL,
    DataLeakTypeValue.SOURCE_CODE,
  ]);

  const onCloseDrawerAndCleanup = () => {
    setSelectedTypes([
      DataLeakTypeValue.ACCOUNT,
      DataLeakTypeValue.COOKIE,
      DataLeakTypeValue.EMAIL,
      DataLeakTypeValue.SOURCE_CODE,
    ]);
    onClose();
  };

  const onClickExport = () => {
    dataLeakService
      .export_data_leak(workspaceId, selectedTypes)
      .then(() => {
        createToast({
          type: "success",
          message: t("notification.exportSuccess.message"),
          detail: t("notification.exportSuccess.detail"),
        });
        onRefresh();
        onCloseDrawerAndCleanup();
      })
      .catch(apiErrorHandler);
  };

  return (
    <Drawer
      open={open}
      onClose={onCloseDrawerAndCleanup}
      closable={false}
      title={
        <h2>
          {t("title")} <span className="text-primary"></span>
        </h2>
      }
      extra={
        <IconButton
          variant="label-grey"
          size={9}
          onClick={onCloseDrawerAndCleanup}
        >
          <CloseLine />
        </IconButton>
      }
      footer={
        <div className="flex flex-col gap-3">
          <Button
            className="w-full"
            size="large"
            // pending={pending}
            onClick={onClickExport}
          >
            {t("button.export")}
          </Button>
          <Button
            variant="secondary"
            className="w-full"
            size="large"
            onClick={onCloseDrawerAndCleanup}
          >
            {t("button.cancel")}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-6 p-6">
        <p className="font-regular-16-body text-hard-grey">
          {t("instruction")}
        </p>
        <label className="flex items-center gap-3 font-regular-18-body">
          <Checkbox
            checked={selectedTypes.includes(DataLeakTypeValue.ACCOUNT)}
            onClick={() => {
              if (selectedTypes.includes(DataLeakTypeValue.ACCOUNT)) {
                setSelectedTypes((prev) =>
                  prev.filter((item) => item !== DataLeakTypeValue.ACCOUNT)
                );
              } else {
                setSelectedTypes((prev) => [
                  ...prev,
                  DataLeakTypeValue.ACCOUNT,
                ]);
              }
            }}
          />
          {t("options.account")}
        </label>
        <label className="flex items-center gap-3 font-regular-18-body">
          <Checkbox
            checked={selectedTypes.includes(DataLeakTypeValue.COOKIE)}
            onClick={() => {
              if (selectedTypes.includes(DataLeakTypeValue.COOKIE)) {
                setSelectedTypes((prev) =>
                  prev.filter((item) => item !== DataLeakTypeValue.COOKIE)
                );
              } else {
                setSelectedTypes((prev) => [...prev, DataLeakTypeValue.COOKIE]);
              }
            }}
          />
          {t("options.cookie")}
        </label>
        <label className="flex items-center gap-3 font-regular-18-body">
          <Checkbox
            checked={selectedTypes.includes(DataLeakTypeValue.EMAIL)}
            onClick={() => {
              if (selectedTypes.includes(DataLeakTypeValue.EMAIL)) {
                setSelectedTypes((prev) =>
                  prev.filter((item) => item !== DataLeakTypeValue.EMAIL)
                );
              } else {
                setSelectedTypes((prev) => [...prev, DataLeakTypeValue.EMAIL]);
              }
            }}
          />
          {t("options.email")}
        </label>
        <label className="flex items-center gap-3 font-regular-18-body">
          <Checkbox
            checked={selectedTypes.includes(DataLeakTypeValue.SOURCE_CODE)}
            onClick={() => {
              if (selectedTypes.includes(DataLeakTypeValue.SOURCE_CODE)) {
                setSelectedTypes((prev) =>
                  prev.filter((item) => item !== DataLeakTypeValue.SOURCE_CODE)
                );
              } else {
                setSelectedTypes((prev) => [
                  ...prev,
                  DataLeakTypeValue.SOURCE_CODE,
                ]);
              }
            }}
          />
          {t("options.sourceCode")}
        </label>
      </div>
    </Drawer>
  );
};

export default DataLeakExportDrawer;
