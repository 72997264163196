// Libraries
import { Trans, useTranslation } from "react-i18next";
import { Tabs, Tooltip } from "@lockerpm/design";
import { useMemo, useState } from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// Resources
import { ReactComponent as ExportLine } from "#src/assets/images/icons/export-line.svg";
import { ReactComponent as QrScanLine } from "#src/assets/images/icons/qr-scan-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";
import { ReactComponent as ShieldCheckLine } from "#src/assets/images/icons/shield-check-line.svg";
import { ReactComponent as NoDocumentIllustration } from "#src/assets/images/illustrations/no-document.svg";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";
import { pathname } from "#src/config/pathname";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import TabButtonLabel from "#src/common/helper/antdProps/Tabs/TabButtonLabel";
import PaginationBar from "#src/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import useFetch from "#src/hooks/useFetch";
import { Button } from "#src/common/system/Button";
import Loader from "#src/common/system/Loader";
import LoadingState from "#src/common/system/LoadingState";
import EmptyState from "#src/common/helper/wrapper/EmptyState";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// Children
import dataLeakServices, {
  type ILeakedDataEmailDetails,
  type ILeakedDataSourceCodeDetails,
  type ILeakedDataAccountDetails,
  type ILeakedDataCookieDetails,
  type ILeakedDataItem,
} from "#src/services/dataLeak";
import DataLeakFilter from "#src/components/dataLeak/views/Filter";
import DataLeakAccountTable from "#src/components/dataLeak/views/Tabs/Account";
import DataLeakCookieTable from "#src/components/dataLeak/views/Tabs/Cookie";
import DataLeakEmailTable from "#src/components/dataLeak/views/Tabs/Email";
import DataLeakSourceCodeTable from "#src/components/dataLeak/views/Tabs/SourceCode";
import { vulnerabilityStatusItems } from "#src/config/filter/vulnerability";
import DataLeakExportDrawer from "#src/components/dataLeak/views/ExportDrawer";
import { DataLeakTypeValue } from "#src/config/filter/dataLeak/value";

dayjs.extend(relativeTime);

const FoundLeaksTabEnum = {
  ACCOUNT: "FOUND_LEAKS_TAB_ENUM_ACCOUNT",
  COOKIE: "FOUND_LEAKS_TAB_ENUM_COOKIE",
  EMAIL: "FOUND_LEAKS_TAB_ENUM_EMAIL",
  SOURCE_CODE: "FOUND_LEAKS_TAB_ENUM_SOURCE_CODE",
};

const DataLeakFoundLeaks = () => {
  const { t } = useTranslation("dataLeak", { keyPrefix: "foundLeaks" });

  const { workspaceId } = useParams<"workspaceId">();

  const dataLeakTabItems: IFilterItem[] = [
    {
      key: FoundLeaksTabEnum.ACCOUNT,
      value: DataLeakTypeValue.ACCOUNT,
      getLabel: () => t("tabs.account.label"),
    },
    {
      key: FoundLeaksTabEnum.COOKIE,
      value: DataLeakTypeValue.COOKIE,
      getLabel: () => t("tabs.cookie.label"),
    },
    {
      key: FoundLeaksTabEnum.EMAIL,
      value: DataLeakTypeValue.EMAIL,
      getLabel: () => t("tabs.email.label"),
    },
    {
      key: FoundLeaksTabEnum.SOURCE_CODE,
      value: DataLeakTypeValue.SOURCE_CODE,
      getLabel: () => t("tabs.sourceCode.label"),
    },
  ];

  const [activeTab, setActiveTab] = useState<IFilterItem>(dataLeakTabItems[0]);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);
  const [severity, setSeverity] = useState<IFilterItem | null>(null);
  const [status, setStatus] = useState<IFilterItem[] | null>(null);
  const [selectedKeyword, setSelectedKeyword] = useState<IFilterItem | null>(
    null
  );
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);

  const [openExportDrawer, setOpenExportDrawer] = useState<boolean>(false);

  const fetchParams = useMemo<
    Parameters<typeof dataLeakServices.list_data_leak>
  >(
    () => [
      workspaceId ?? "",
      {
        from: from ? from : undefined,
        to: to ? to : undefined,
        filter_type: activeTab.value,
        filter_severity: severity?.value,
        filter_status: undefined,
        filter_sub_status:
          status && status.length > 0
            ? status.map((item) => item.value || "").join(",")
            : undefined,
        filter_keyword_ids: selectedKeyword?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      activeTab,
      from,
      to,
      severity,
      status,
      selectedKeyword,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const statisticParams = useMemo<
    Parameters<typeof dataLeakServices.statistics_data_leak>
  >(
    () => [
      workspaceId ?? "",
      {
        from: from ? from : undefined,
        to: to ? to : undefined,
        filter_type: undefined,
        filter_severity: severity?.value,
        filter_status: undefined,
        filter_sub_status:
          status && status.length > 0
            ? status.map((item) => item.value || "").join(",")
            : undefined,
        filter_keyword_ids: selectedKeyword?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      from,
      to,
      severity,
      status,
      selectedKeyword,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangeTimeConditionValue = (
    condition: string,
    from: number,
    to: number
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
    setSelectedPage(1);
  };

  const onChangeSeveritySelection = (selected: typeof severity) => {
    setSeverity(selected);
    setSelectedPage(1);
  };

  const onChangeStatusSelection = (
    selected: IFilterItem[] | IFilterItem | null
  ) => {
    if (selected !== null && !Array.isArray(selected)) {
      if (typeof selected.value !== "string") {
        selected = vulnerabilityStatusItems.map((item) => item.children).flat();
      } else {
        selected = [selected];
      }
    }
    setStatus(selected);
    setSelectedPage(1);
  };

  const onChangeSelectedKeywordSelection = (
    selected: typeof selectedKeyword
  ) => {
    setSelectedKeyword(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const onChangeTab = (key: typeof activeTab) => {
    setActiveTab(key);
  };

  const onClickRescan = () => {
    if (workspaceId) {
      dataLeakServices
        .rescan_data_leak(workspaceId)
        .then(() => {
          updateDataLeakScanStatus();
          updateDataLeakList();
        })
        .catch(apiErrorHandler);
    }
  };

  const { result: dataLeakStatistics } = useFetch(
    dataLeakServices.statistics_data_leak,
    statisticParams
  );

  const onlyWorkspaceIdParam = useMemo<
    Parameters<typeof dataLeakServices.retrieve_data_leak_scan_status>
  >(() => [workspaceId ?? ""], [workspaceId]);

  const { result: dataLeakScanStatus, updateData: updateDataLeakScanStatus } =
    useFetch(
      dataLeakServices.retrieve_data_leak_scan_status,
      onlyWorkspaceIdParam
    );

  const keywordListParam = useMemo<
    Parameters<typeof dataLeakServices.list_data_leak_overview_keywords>
  >(() => [workspaceId ?? "", { from: null, to: null }], [workspaceId]);

  const { result: dataLeakKeywordList } = useFetch(
    dataLeakServices.list_data_leak_overview_keywords,
    keywordListParam
  );

  const {
    list: dataLeakList,
    count: dataLeakCount,
    isLoading,
    updateData: updateDataLeakList,
  } = useFetchPaginated(dataLeakServices.list_data_leak, fetchParams);

  const tabList = workspaceId
    ? [
        {
          key: FoundLeaksTabEnum.ACCOUNT,
          label: (
            <TabButtonLabel
              name={t("tabs.account.label")}
              count={dataLeakStatistics?.account}
            />
          ),
        },
        {
          key: FoundLeaksTabEnum.COOKIE,
          label: (
            <TabButtonLabel
              name={t("tabs.cookie.label")}
              count={dataLeakStatistics?.cookie}
            />
          ),
        },
        {
          key: FoundLeaksTabEnum.EMAIL,
          label: (
            <TabButtonLabel
              name={t("tabs.email.label")}
              count={dataLeakStatistics?.email}
            />
          ),
        },
        {
          key: FoundLeaksTabEnum.SOURCE_CODE,
          label: (
            <TabButtonLabel
              name={t("tabs.sourceCode.label")}
              count={dataLeakStatistics?.source_code}
            />
          ),
        },
      ]
    : [];

  return workspaceId ? (
    <>
      <StickySection className="flex-col gap-6">
        <div className="flex justify-between gap-6 w-full">
          <div className="flex flex-col gap-3">
            <h1>{t("title")}</h1>
            <span className="text-hard-grey">{t("description")}</span>
          </div>
          <div className="flex gap-1">
            <Tooltip
              title={
                dataLeakScanStatus && dataLeakScanStatus.exported_number >= 3
                  ? t("tooltip.reachedExportLimit")
                  : null
              }
            >
              <div>
                <Button
                  size="large"
                  variant="secondary"
                  onClick={() => {
                    setOpenExportDrawer(true);
                  }}
                  disabled={
                    dataLeakScanStatus === null ||
                    dataLeakScanStatus.exported_number >= 3
                  }
                >
                  <ExportLine className="h-5 w-5 min-w-[1.25rem]" />
                  {t("button.export")}
                </Button>
              </div>
            </Tooltip>
            <Tooltip
              title={
                dataLeakScanStatus && dataLeakScanStatus.scanned_number >= 3
                  ? t("tooltip.reachedScanLimit")
                  : null
              }
            >
              <div>
                <Button
                  size="large"
                  onClick={onClickRescan}
                  disabled={
                    dataLeakScanStatus === null ||
                    dataLeakScanStatus.scanned_number >= 3
                  }
                >
                  <QrScanLine className="h-5 w-5 min-w-[1.25rem]" />
                  {t("button.scan")}
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
        {dataLeakScanStatus ? (
          dataLeakScanStatus.is_running ? (
            <div className="w-full px-6 py-3 bg-support-blue-pastel text-support-blue flex gap-3 rounded-md">
              <Loader baseSize={1.25} />
              <Trans
                t={t}
                i18nKey={"scanStatus.scanning"}
                components={{ b: <b className="contents" /> }}
              />
            </div>
          ) : dataLeakScanStatus.last_scan_number > 0 ? (
            <div className="w-full px-6 py-3 bg-warning-red-5% text-warning-red-danger flex gap-3 rounded-md">
              <InformationLine className="h-5 w-5 min-w-[1.25rem]" />
              <Trans
                t={t}
                i18nKey={"scanStatus.leakFound"}
                components={{ b: <b className="contents" /> }}
                count={dataLeakScanStatus.last_scan_number}
                values={{
                  date: dayjs.unix(dataLeakScanStatus.last_scan_time).fromNow(),
                }}
              />
            </div>
          ) : dataLeakScanStatus.last_scan_time !== null ? (
            <div className="w-full px-6 py-3 bg-support-seafoam-pastel text-support-seafoam flex gap-3 rounded-md">
              <ShieldCheckLine className="h-5 w-5 min-w-[1.25rem]" />
              <Trans
                t={t}
                i18nKey={"scanStatus.noLeak"}
                components={{ b: <b className="contents" /> }}
                values={{
                  date: dayjs.unix(dataLeakScanStatus.last_scan_time).fromNow(),
                }}
              />
            </div>
          ) : null
        ) : null}
      </StickySection>
      <ContentSection>
        <Tabs
          items={tabList}
          activeKey={activeTab.key}
          onChange={(key) => {
            const selected = dataLeakTabItems.find((item) => item.key === key);
            if (selected) {
              onChangeTab(selected);
            }
          }}
          destroyInactiveTabPane
        />
        <DataLeakFilter
          workspaceId={workspaceId}
          severity={severity}
          status={status}
          selectedKeyword={selectedKeyword}
          searchKeyword={searchKeyword}
          onChangeTimeConditionValue={onChangeTimeConditionValue}
          onChangeSeveritySelection={onChangeSeveritySelection}
          onChangeStatusSelection={onChangeStatusSelection}
          onChangeSelectedKeywordSelection={onChangeSelectedKeywordSelection}
          onChangeSearchKeyword={onChangeSearchKeyword}
        />
        {dataLeakList === null || isLoading ? (
          <LoadingState />
        ) : dataLeakList.length === 0 ? (
          dataLeakKeywordList === null || dataLeakScanStatus === null ? (
            <LoadingState />
          ) : dataLeakKeywordList.some((item) => item.verification) ? (
            dataLeakScanStatus?.is_running ? (
              <EmptyState
                text={t("noResult.scanning.text")}
                illustration={<NoDocumentIllustration />}
              />
            ) : (
              <div className="flex flex-col items-center">
                <EmptyState
                  text={t("noResult.startScan.text")}
                  illustration={<NoDocumentIllustration />}
                />
                <Button size="large" onClick={onClickRescan}>
                  {t("noResult.startScan.button")}
                </Button>
              </div>
            )
          ) : (
            <div className="flex flex-col items-center">
              <EmptyState
                text={t("noResult.needToVerify.text")}
                illustration={<NoDocumentIllustration />}
              />
              <Link
                to={generatePath(pathname.DATA_LEAK_OVERVIEW, {
                  workspaceId,
                })}
              >
                <Button size="large">
                  {t("noResult.needToVerify.button")}
                </Button>
              </Link>
            </div>
          )
        ) : (
          <>
            {activeTab.key === FoundLeaksTabEnum.ACCOUNT ? (
              <DataLeakAccountTable
                workspaceId={workspaceId}
                dataLeakList={
                  dataLeakList as ILeakedDataItem<ILeakedDataAccountDetails>[]
                }
                isLoading={
                  isLoading ||
                  dataLeakList.some(
                    (item) => item.type !== DataLeakTypeValue.ACCOUNT
                  )
                }
                updateDataLeakList={updateDataLeakList}
              />
            ) : null}
            {activeTab.key === FoundLeaksTabEnum.COOKIE ? (
              <DataLeakCookieTable
                workspaceId={workspaceId}
                dataLeakList={
                  dataLeakList as ILeakedDataItem<ILeakedDataCookieDetails>[]
                }
                isLoading={
                  isLoading ||
                  dataLeakList.some(
                    (item) => item.type !== DataLeakTypeValue.COOKIE
                  )
                }
              />
            ) : null}
            {activeTab.key === FoundLeaksTabEnum.EMAIL ? (
              <DataLeakEmailTable
                workspaceId={workspaceId}
                dataLeakList={
                  dataLeakList as ILeakedDataItem<ILeakedDataEmailDetails>[]
                }
                isLoading={
                  isLoading ||
                  dataLeakList.some(
                    (item) => item.type !== DataLeakTypeValue.EMAIL
                  )
                }
                updateDataLeakList={updateDataLeakList}
              />
            ) : null}
            {activeTab.key === FoundLeaksTabEnum.SOURCE_CODE ? (
              <DataLeakSourceCodeTable
                workspaceId={workspaceId}
                dataLeakList={
                  dataLeakList as ILeakedDataItem<ILeakedDataSourceCodeDetails>[]
                }
                isLoading={
                  isLoading ||
                  dataLeakList.some(
                    (item) => item.type !== DataLeakTypeValue.SOURCE_CODE
                  )
                }
                updateDataLeakList={updateDataLeakList}
              />
            ) : null}
          </>
        )}
        <PaginationBar
          numOfResult={dataLeakCount}
          selectedPage={selectedPage}
          pageSize={pageSize}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
        />
      </ContentSection>
      <DataLeakExportDrawer
        open={openExportDrawer}
        onClose={() => {
          setOpenExportDrawer(false);
        }}
        onRefresh={updateDataLeakScanStatus}
        workspaceId={workspaceId}
      />
    </>
  ) : null;
};

export default DataLeakFoundLeaks;
