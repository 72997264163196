/** READING ARTICLES:
 * https://stackoverflow.com/questions/60581285/execcommand-is-now-obsolete-whats-the-alternative
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
 * tl;dr: browsers don't really support editing rich texts, so a lot of problems in this file are just not available to be fixed at the moment. Last update: 06/04/2024.
 * Avoiding using rich text editor libraries can be justified by the state of maintenance of those libraries. Most of them are no longer being maintenanced anymore, and they're likely to be incompatible/deprecated with current browsers' standards.
 */

// Resources
import { ReactComponent as HeadingIcon } from "#src/assets/images/icons/heading.svg";
import { ReactComponent as BoldIcon } from "#src/assets/images/icons/bold.svg";
import { ReactComponent as ItalicIcon } from "#src/assets/images/icons/italic.svg";
import { ReactComponent as DoubleQuotesL } from "#src/assets/images/icons/double-quotes-l.svg";
import { ReactComponent as CodeSSlashLine } from "#src/assets/images/icons/code-s-slash-line.svg";
import { ReactComponent as EditLinkIcon } from "#src/assets/images/icons/edit-link.svg";
import { ReactComponent as ImageAddLine } from "#src/assets/images/icons/image-add-line.svg";
import { ReactComponent as ListUnorderedIcon } from "#src/assets/images/icons/list-unordered.svg";
import { ReactComponent as ListOrderedIcon } from "#src/assets/images/icons/list-ordered.svg";

// Children
import { EditorButton } from "./EditorButton";
import {
  onClickBoldButton,
  onClickCodeButton,
  onClickHeadingButton,
  onClickImageButton,
  onClickItalicButton,
  onClickLinkButton,
  onClickOrderedListButton,
  onClickQuoteButton,
  onClickUnorderedListButton,
} from "./actions";

const EditorTools = ({
  mode,
  inputUuid,
}: {
  mode: "write" | "preview";
  inputUuid?: string;
}) => {
  const onClickToolButton = (editorAction: (...arg: unknown[]) => void) => {
    const getTarget = inputUuid ? document.getElementById(inputUuid) : null;
    if (getTarget === null) {
      return;
    }
    const editor = getTarget as HTMLTextAreaElement;
    const selectionStart = editor.selectionStart;
    const selectionEnd = editor.selectionEnd;
    const selectionText = editor.value.substring(selectionStart, selectionEnd);

    editorAction(editor, selectionStart, selectionEnd, selectionText);

    // Reading article: React's events were not always doing the same thing as native events. https://stackoverflow.com/a/46012210/16758013
    editor.dispatchEvent(new Event("change", { bubbles: true }));
  };

  return (
    <div className="flex items-center gap-1 text-hard-grey">
      <EditorButton
        disabled={mode !== "write"}
        inputUuid={inputUuid}
        onClick={() => {
          onClickToolButton(onClickHeadingButton);
        }}
      >
        <HeadingIcon />
      </EditorButton>
      <EditorButton
        disabled={mode !== "write"}
        inputUuid={inputUuid}
        onClick={() => {
          onClickToolButton(onClickBoldButton);
        }}
      >
        <BoldIcon />
      </EditorButton>
      <EditorButton
        disabled={mode !== "write"}
        inputUuid={inputUuid}
        onClick={() => {
          onClickToolButton(onClickItalicButton);
        }}
      >
        <ItalicIcon />
      </EditorButton>
      <EditorButton
        disabled={mode !== "write"}
        inputUuid={inputUuid}
        onClick={() => {
          onClickToolButton(onClickQuoteButton);
        }}
      >
        <DoubleQuotesL />
      </EditorButton>
      <EditorButton
        disabled={mode !== "write"}
        inputUuid={inputUuid}
        onClick={() => {
          onClickToolButton(onClickCodeButton);
        }}
      >
        <CodeSSlashLine />
      </EditorButton>
      <EditorButton
        disabled={mode !== "write"}
        inputUuid={inputUuid}
        onClick={() => {
          onClickToolButton(onClickLinkButton);
        }}
      >
        <EditLinkIcon />
      </EditorButton>
      <EditorButton
        disabled={mode !== "write"}
        inputUuid={inputUuid}
        onClick={() => {
          onClickToolButton(onClickImageButton);
        }}
      >
        <ImageAddLine />
      </EditorButton>
      <EditorButton
        disabled={mode !== "write"}
        inputUuid={inputUuid}
        onClick={() => {
          onClickToolButton(onClickUnorderedListButton);
        }}
      >
        <ListUnorderedIcon />
      </EditorButton>
      <EditorButton
        disabled={mode !== "write"}
        inputUuid={inputUuid}
        onClick={() => {
          onClickToolButton(onClickOrderedListButton);
        }}
      >
        <ListOrderedIcon />
      </EditorButton>
    </div>
  );
};

export default EditorTools;
