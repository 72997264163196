// Libraries
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ExpiredBadge } from "#src/assets/images/icons/expired-badge.svg";
import { ReactComponent as ValidBadge } from "#src/assets/images/icons/valid-badge.svg";
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import NoResultsState from "#src/common/states/NoResultsState";

// API-related
import type { IAssetDetailItem } from "#src/services/assets";

// Children
import { AssetDetailTabEnum } from "../enums";

const AssetsDetailsOverviewCertificates = ({
  address,
  certificateDetails,
  onChangeTab,
}: {
  address: string;
  certificateDetails: IAssetDetailItem["certificates"];
  onChangeTab: (key: string) => void;
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.overview.card.certificates",
  });

  if (certificateDetails === null) return <NoResultsState />;

  /**Time difference from now to expire timestamp.
   * If this returns positive, the certificate is not expired
   */
  const expireDiff = dayjs
    .unix(certificateDetails?.not_after)
    .diff(dayjs(), "day");

  return (
    <div className="flex items-center justify-between gap-6">
      <div className="flex gap-6 items-center w-full rounded-md bg-bright-grey">
        {expireDiff > 0 ? <ValidBadge /> : <ExpiredBadge />}
        <div className="flex flex-col gap-1">
          <span className="font-medium-20">{address}</span>
          <div>
            {expireDiff > 0 ? (
              <p className="text-hard-grey">
                {t("expiresIn")}{" "}
                <span className="text-dark-blue">
                  {t("expireTimestamp", { numOfDays: expireDiff })}
                </span>{" "}
                {t("by")}{" "}
                <span className="text-dark-blue">
                  {dayjs
                    .unix(certificateDetails.not_after)
                    .format("HH:mm DD.MM.YYYY")}
                </span>
              </p>
            ) : (
              <p className="text-hard-grey">
                {t("expiredAt")}{" "}
                <span className="text-dark-blue">
                  {dayjs
                    .unix(certificateDetails.not_after)
                    .format("HH:mm DD.MM.YYYY")}
                </span>
              </p>
            )}
            {certificateDetails.issuer.name ? (
              <p className="text-hard-grey">
                {t("issuedBy")}{" "}
                <span className="text-dark-blue">
                  {certificateDetails.issuer.name}
                </span>
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <Button
        variant="text"
        size="medium"
        onClick={() => {
          onChangeTab(AssetDetailTabEnum.CERTIFICATES);
        }}
      >
        {t("button.viewAll")}
        <ArrowRightUpLine className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default AssetsDetailsOverviewCertificates;
