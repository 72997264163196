// Libraries
import { useTranslation } from "react-i18next";

// API-related
import type { IMsspBugBountyDetails } from "#src/services/mssp/bugBounty";
import {
  pentestScopeScopeItems,
  pentestScopeTypeItems,
} from "#src/config/filter/pentest";
import type { IBugBountyScopeInputItem } from "#src/components/bugBounty/AddBugBounty/Scope";

// Children
import MsspBugBountyDetailsScopeTable from "./Table";

const targetToScopeEditItem = (
  target: IMsspBugBountyDetails["target"][number]
): IBugBountyScopeInputItem => {
  return {
    id: target.id.toString(),
    assetType: pentestScopeTypeItems.find(
      (item) => item.value === target.type
    ) ?? {
      key: target.type,
      value: target.type,
      getLabel: () => target.type,
    },
    assets: target.name,
    scope: pentestScopeScopeItems.find(
      (item) => item.value === target.scope
    ) ?? {
      key: target.scope,
      value: target.scope,
      getLabel: () => target.scope,
    },
    editing: false,
  };
};

interface IMsspBugBountyDetailsScopeProps {
  bugBountyDetails: IMsspBugBountyDetails;
}

const MsspBugBountyDetailsScope = ({
  bugBountyDetails,
}: IMsspBugBountyDetailsScopeProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.scope",
  });

  return (
    <section className="flex flex-col gap-6 pt-6 w-2/3">
      <div className="flex flex-col gap-4">
        <h2>{t("title")}</h2>
        <span className="text-hard-grey">{t("info")}</span>
      </div>
      <MsspBugBountyDetailsScopeTable
        scope={bugBountyDetails.target.map(targetToScopeEditItem)}
      />
    </section>
  );
};

export default MsspBugBountyDetailsScope;
