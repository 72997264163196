import { ReactNode } from "react";

interface IEmptyStateProps {
  illustration: ReactNode;
  text: string;
}

const EmptyState = ({ illustration, text }: IEmptyStateProps) => {
  return (
    <div className="flex justify-center items-center py-12">
      <div className="flex flex-col items-center">
        {illustration}
        <span className="whitespace-pre-line text-center font-regular-18-body text-hard-grey">
          {text}
        </span>
      </div>
    </div>
  );
};

export default EmptyState;
