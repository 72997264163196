// Libraries
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as LogoCyStack } from "#src/assets/images/logos/logo-cystack.svg";
import { ReactComponent as LogoLocker } from "#src/assets/images/logos/logo-locker.svg";
import { ReactComponent as LogoWhitehub } from "#src/assets/images/logos/logo-whitehub.svg";
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// Components
import { Button } from "#src/common/system/Button";

export enum EToolKeys {
  CYSTACK_ASSETS = "cystack_assets",
  CYSTACK_ENDPOINT = "cystack_endpoint",
  CYSTACK_DATA_LEAK_DETECTION = "cystack_data_leak_detection",
  CYSTACK_VULNSCAN = "cystack_vulnscan",
  WHITEHUB_BUG_BOUNTY = "whitehub",
  CYSTACK_TRUST_CENTER = "cystack_trust_center",
  CYSTACK_PLATFORM = "cystack_platform",
  LOCKER_PASSWORD = "locker_password",
  LOCKER_SECRET = "locker_secret",
  SAFE_CHAIN = "safe_chain",
}

export interface ITool {
  key: EToolKeys;
  logo: ReactNode;
  title: string;
  description: string;
}

export const ToolCard = ({
  tool,
}: {
  tool: {
    id: string;
    name: string;
    url: string;
    description: string;
  };
}) => {
  const { t } = useTranslation("solutions");
  const { t: tTrustCenterControl } = useTranslation("trustCenter", {
    keyPrefix: "control",
  });

  const defaultTools: Omit<ITool, "enabled">[] = useMemo(
    () => [
      {
        key: EToolKeys.CYSTACK_ASSETS,
        logo: <LogoCyStack className="fill-white w-10" />,
        title: "",
        description: "",
      },
      {
        key: EToolKeys.CYSTACK_PLATFORM,
        logo: <LogoCyStack className="fill-white w-10" />,
        title: "",
        description: "",
      },
      {
        key: EToolKeys.LOCKER_SECRET,
        logo: <LogoLocker className="fill-white w-10" />,
        title: t("solution.secrets_manager.title"),
        description: t("solution.secrets_manager.description"),
      },
      {
        key: EToolKeys.LOCKER_PASSWORD,
        logo: <LogoLocker className="fill-white w-10" />,
        title: t("solution.password_manager.title"),
        description: t("solution.password_manager.description"),
      },
      {
        key: EToolKeys.CYSTACK_ENDPOINT,
        logo: <LogoCyStack className="fill-white w-10" />,
        title: t("solution.endpoint.title"),
        description: t("solution.endpoint.description"),
      },
      {
        key: EToolKeys.CYSTACK_DATA_LEAK_DETECTION,
        logo: <LogoCyStack className="fill-white w-10" />,
        title: t("solution.data_leak.title"),
        description: t("solution.data_leak.description"),
      },
      {
        key: EToolKeys.CYSTACK_VULNSCAN,
        logo: <LogoCyStack className="fill-white w-10" />,
        title: t("solution.vuln_scan.title"),
        description: t("solution.vuln_scan.description"),
      },
      {
        key: EToolKeys.CYSTACK_TRUST_CENTER,
        logo: <LogoCyStack className="fill-white w-10" />,
        title: t("solution.trust_center.title"),
        description: t("solution.trust_center.description"),
      },
      {
        key: EToolKeys.SAFE_CHAIN,
        logo: <LogoCyStack className="fill-white w-10" />,
        title: t("solution.blockchain_protocol_audit.title"),
        description: t("solution.blockchain_protocol_audit.description"),
      },
      {
        key: EToolKeys.WHITEHUB_BUG_BOUNTY,
        logo: <LogoWhitehub className="fill-white w-10" />,
        title: t("solution.whitehub_bug_bounty.title"),
        description: t("solution.whitehub_bug_bounty.description"),
      },
    ],
    [t]
  );

  const currentTool = useMemo(() => {
    return defaultTools.find((s: ITool) => s.key === tool.id);
  }, [tool, defaultTools]);

  return (
    <div className="rounded-md bg-bright-grey flex flex-col gap-3 p-6">
      <div className="flex items-center gap-3">
        <div className="h-[4.25rem] w-[4.25rem] rounded-md flex items-center justify-center bg-white border">
          {currentTool?.logo}
        </div>
        <p className="font-medium-18">{tool.name || currentTool?.title}</p>
      </div>
      <p className="font-regular-16-body text-hard-grey">
        {currentTool?.description}
      </p>
      <div className="flex justify-end">
        <a href={tool.url} target="_blank" rel="noreferrer">
          <Button variant="secondary" size="medium">
            {tTrustCenterControl("modal.getStartedNow")}
            <ArrowRightUpLine className="h-4 w-4" />
          </Button>
        </a>
      </div>
    </div>
  );
};
