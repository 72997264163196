// Libraries
import { useAppDispatch } from "#src/store/hooks";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import i18next from "i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as PaletteLine } from "#src/assets/images/icons/palette-line.svg";
import { ReactComponent as SaveLine } from "#src/assets/images/icons/save-line.svg";
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";
import { ReactComponent as WarningDiamond } from "#src/assets/images/icons/warning-diamond.svg";

// General
import { updateWorkspaceList } from "#src/store/slices/workspace";

// Components
import { Button } from "#src/common/system/Button";
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import { createToast } from "#src/common/system/toasts";
import { ContentSection } from "#src/layouts/content";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { FileUploadActionValue, uploadFile } from "#src/utils/uploadFile";
import { ObjectImage } from "#src/common/system/Object";

// API-related
import WorkspaceAdditionalInformation, {
  type IWorkspaceAdditionalForm,
} from "#src/components/workspace/details/AdditionalInformation";
import WorkspaceBasicInformation, {
  type IWorkspaceFormBasic,
} from "#src/components/workspace/details/BasicInformation";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";
import workspaceServices, {
  type IWorkspaceItem,
} from "#src/services/workspace";
import DeleteWorkspaceModal from "#src/components/workspace/details/DeleteModal";
import ConfigSectionWrapper from "#src/common/helper/wrapper/ConfigSection";

const WorkspaceDetailsPage = () => {
  const { t } = useTranslation("workspace", { keyPrefix: "detail" });
  const { workspaceId } = useParams<"workspaceId">();

  const dispatch = useAppDispatch();

  const [isEditing, setEditing] = useState<boolean>(false);

  const [currentWorkspace, setCurrentWorkspace] =
    useState<IWorkspaceItem | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [openConfirmDeleteWorkspace, setOpenConfirmDeleteWorkspace] =
    useState<boolean>(false);
  const [color, setColor] = useState<string>("");
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  const onUpdateLogo = (files: File[]) => {
    if (currentWorkspace)
      for (const file of files) {
        const fileType = file.type.split("/")[0];
        if (fileType === "image") {
          uploadFile(file, FileUploadActionValue.WORKSPACE_LOGO, {
            workspace_id: currentWorkspace.id,
          })
            .then((signedResponse) => {
              setAvatarUrl(signedResponse.signed_url);
              workspaceServices
                .update_workspace_logo(
                  currentWorkspace.id,
                  signedResponse.signed_url
                )
                .then((response) => {
                  console.log(response);
                })
                .catch((error) => {
                  apiErrorHandler(error, {
                    toastMessage: t("notification.updateLogo.fail"),
                  });
                });
            })
            .catch((error) => {
              apiErrorHandler(error, {
                toastMessage: t("notification.uploadFile.fail"),
              });
            });
        }
      }
  };

  const validationSchema = z.object({
    name: z
      .string()
      .max(
        255,
        i18next.t("common:input.error.maxLengthMessage", { length: 255 })
      )
      .min(1, i18next.t("common:input.error.required")),
    short_name: z
      .string()
      .max(
        255,
        i18next.t("common:input.error.maxLengthMessage", { length: 255 })
      )
      .optional(),
    description: z.object({
      vi: z
        .string()
        .max(
          1024,
          i18next.t("common:input.error.maxLengthMessage", { length: 1024 })
        )
        .optional(),
      en: z
        .string()
        .max(
          1024,
          i18next.t("common:input.error.maxLengthMessage", { length: 1024 })
        )
        .optional(),
    }),
    website: z.string().min(1, i18next.t("common:input.error.required")),
  });

  const [initBasicInformation, setInitBasicInformation] =
    useState<IWorkspaceFormBasic>();
  const [initAdditionalInformation, setInitAdditionalInformation] =
    useState<IWorkspaceAdditionalForm>();

  // Destructuring the reset to pass it to useEffects. Should have destructured every attributes here, but I kept the rest intact so that we can pass them to children "cleaner" (less text to read, more ambiguity)
  const { reset: resetBasic, ...formBasicInformation } =
    useForm<IWorkspaceFormBasic>({
      resolver: zodResolver(validationSchema) as any,
      mode: "onBlur",
    });
  const { reset: resetAdditional, ...formAdditionalInformation } =
    useForm<IWorkspaceAdditionalForm>();

  const fetchWorkspace = useCallback(
    (workspaceId: string) => {
      setLoading(true);
      workspaceServices
        .retrieve_workspace(workspaceId)
        .then((response) => {
          const basicInformation = {
            name: response.name,
            email: response.email || "",
            short_name: response.short_name || "",
            phone: response.phone,
            vat_tax_code: response.vat_tax_code || "",
            address: response.address || "",
            website: response.website,
            description: response.description,
            country_code: response.country_code,
          };
          const stringifiedBasicInfo = {
            name: basicInformation.name,
            email: basicInformation.email,
            short_name: basicInformation.short_name,
            phone: basicInformation.phone ? basicInformation.phone : "",
            vat_tax_code: basicInformation.vat_tax_code,
            address: basicInformation.address,
            website: basicInformation.website ? basicInformation.website : "",
            description: basicInformation.description,
            country_code: basicInformation.country_code,
          } satisfies typeof initBasicInformation;
          const additionalInformation = {
            social: response.social,
            employee_size: response.employee_size || "",
            industry: response.industry || "",
            technologies: response.technologies.join(","),
          };
          setInitBasicInformation(stringifiedBasicInfo);
          setInitAdditionalInformation(additionalInformation);

          resetBasic(stringifiedBasicInfo);
          resetAdditional(additionalInformation);

          setCurrentWorkspace(response);
          setColor(response.color);
          setAvatarUrl(response.logo);
          setLoading(false);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadWorkspace.fail"),
          });
        });
    },
    [resetBasic, resetAdditional, t]
  );

  const onResetWorkspaceForm = () => {
    // The condition check on currentWorkspace is just to make ts happy, this can only be reach
    if (currentWorkspace) {
      setEditing(false);
      resetBasic(initBasicInformation);
      resetAdditional(initAdditionalInformation);
    }
  };

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspace(workspaceId);
    }
  }, [workspaceId, fetchWorkspace]);

  const onSaveChanges = async () => {
    // TODO: check valid email
    if (workspaceId) {
      setLoading(true);
      let formBasic: IWorkspaceFormBasic | undefined;
      let formAdditional: IWorkspaceAdditionalForm | undefined;

      await formBasicInformation.handleSubmit((data) => {
        formBasic = { ...data };
      })();
      await formAdditionalInformation.handleSubmit((data) => {
        formAdditional = { ...data };
      })();
      if (!formBasic || !formAdditional) return;
      const {
        name,
        short_name,
        address,
        description,
        email,
        phone,
        vat_tax_code,
        website,
        country_code,
      } = formBasic;

      const { social, industry, technologies, employee_size } = formAdditional;

      workspaceServices
        .update_workspace(workspaceId, {
          name,
          short_name,
          address,
          email,
          country_code,
          phone,
          vat_tax_code,
          website,
          description,
          industry,
          technologies: technologies.trim().length
            ? technologies.trim().split(",")
            : [],
          employee_size,
          social,
          color,
        })
        .then((response) => {
          fetchWorkspace(response.id);
          workspaceServices
            .list_workspaces()
            .then((response) => {
              dispatch(updateWorkspaceList(response));
            })
            .catch(apiErrorHandler);
          setLoading(false);
          createToast({
            type: "success",
            message: t("notification.saveChanges.success"),
          });
          setEditing(false);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data.code === "0004"
          ) {
            const details = error.response.data.details;
            Object.keys(details).map((item: any) => {
              formBasicInformation.setError(item, {
                type: "manual",
                message: details[item][0],
              });
            });
          }

          apiErrorHandler(error, {
            toastMessage: t("notification.saveChanges.fail"),
          });
          formBasicInformation.trigger();
        });
    }
  };

  const onCloseModalConfirmDelete = () => {
    setOpenConfirmDeleteWorkspace(false);
  };

  return workspaceId ? (
    currentWorkspace ? (
      <ContentSection>
        <div className="flex justify-between">
          <h1>
            {t("title")}{" "}
            <span className="text-primary">
              {currentWorkspace.short_name
                ? currentWorkspace.short_name
                : currentWorkspace.name}
            </span>
          </h1>
          <div className="flex gap-1">
            {isEditing ? (
              <Button
                size="large"
                variant="warning"
                onClick={onResetWorkspaceForm}
              >
                <CloseLine />
                {t("button.cancel")}
              </Button>
            ) : null}
            {currentWorkspace.role === WorkspaceMemberRoleValue.OWNER ||
            currentWorkspace.role === WorkspaceMemberRoleValue.ADMIN ? (
              <Button
                size="large"
                onClick={() => {
                  if (isEditing) {
                    onSaveChanges();
                  } else {
                    setEditing(true);
                  }
                }}
              >
                {isEditing ? <SaveLine /> : <EditLine />}
                {isEditing ? t("button.saveChanges") : t("button.edit")}
              </Button>
            ) : null}
          </div>
        </div>
        <div className="flex gap-6">
          <div className="flex-1 space-y-6">
            <WorkspaceBasicInformation
              methods={{ reset: resetBasic, ...formBasicInformation }}
              isEditing={isEditing}
            />
            <WorkspaceAdditionalInformation
              methods={{ reset: resetAdditional, ...formAdditionalInformation }}
              isEditing={isEditing}
            />
            {currentWorkspace.role === WorkspaceMemberRoleValue.OWNER ? (
              <ConfigSectionWrapper
                icon={<WarningDiamond className="h-7 w-7" />}
                headerTitle={t("deleteWorkspace.title")}
                headerButton={
                  <Button
                    variant="danger"
                    onClick={() => setOpenConfirmDeleteWorkspace(true)}
                    disabled={
                      currentWorkspace.role !== WorkspaceMemberRoleValue.OWNER
                    }
                  >
                    {t("button.delete")}
                  </Button>
                }
                danger
              >
                <div className="p-6 font-regular-16-body">
                  {t("deleteWorkspace.description")}
                </div>
              </ConfigSectionWrapper>
            ) : null}
          </div>
          <div className="max-w-[420px] w-full flex-shrink-0">
            <div className="flex flex-col items-center gap-3 p-6">
              <div className="relative h-44 w-full mb-5">
                <div
                  style={{ backgroundColor: color }}
                  className="h-28 w-full rounded-md"
                ></div>
                {!isEditing ? null : (
                  <label className="w-9 h-9 cursor-pointer absolute right-3 top-3 bg-button-secondary text-primary flex items-center justify-center rounded-full">
                    <input
                      type="color"
                      value={color}
                      onChange={(e) => {
                        setColor(e.currentTarget.value);
                      }}
                      className="hidden"
                    />
                    <PaletteLine className="h-5 w-5" />
                  </label>
                )}
                <div className="absolute left-0 right-0 bottom-0 mx-auto h-fit w-fit p-1">
                  <div>
                    <ObjectImage
                      data={avatarUrl}
                      className="h-28 w-28 min-w-[7rem] rounded-md bg-white outline outline-3 outline-white"
                    />
                  </div>
                  {!isEditing &&
                  (currentWorkspace.role === WorkspaceMemberRoleValue.OWNER ||
                    currentWorkspace.role ===
                      WorkspaceMemberRoleValue.ADMIN) ? (
                    <label className="h-9 w-9 bg-button-secondary rounded-full absolute -bottom-2 -right-2 flex items-center justify-center text-primary cursor-pointer">
                      <input
                        id="avatar_uploader"
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                          if (e.currentTarget.files) {
                            onUpdateLogo(Array.from(e.currentTarget.files));
                          }
                        }}
                      />
                      <EditLine className="h-5 w-5" />
                    </label>
                  ) : null}
                </div>
              </div>
              <span className="flex items-center gap-1 font-regular-12 text-hard-grey">
                <TimeLine className="h-3.5 w-3.5" />
                {t("workspaceInfo.created")}{" "}
                {dayjs.unix(currentWorkspace.created_time).fromNow()}
              </span>
            </div>
          </div>
        </div>
        <DeleteWorkspaceModal
          workspaceId={workspaceId}
          open={openConfirmDeleteWorkspace}
          onClose={onCloseModalConfirmDelete}
          currentWorkspace={currentWorkspace}
        />
      </ContentSection>
    ) : isLoading ? (
      <LoadingState />
    ) : (
      <NoResultsState />
    )
  ) : null;
};

export default WorkspaceDetailsPage;
