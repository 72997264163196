import { Modal } from "@lockerpm/design";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { ReactComponent as FailedIllustration } from "#src/assets/images/illustrations/failed.svg";
import { pathname } from "#src/config/pathname";
import { Button } from "#src/common/system/Button";
import { skipToContentTop } from "#src/utils/common";

interface ITrustCenterVerifyInfoModalProps {
  workspaceId: string;
  open: boolean;
  onClose: () => void;
}

const TrustCenterVerifyInfoModal = ({
  workspaceId,
  open,
  onClose,
}: ITrustCenterVerifyInfoModalProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "compliance.verifyInfoModal",
  });

  const onCloseAndCleanup = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={onCloseAndCleanup}
      title={null}
      centered
      footer={null}
      width="44rem"
    >
      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col items-center text-center whitespace-pre-line gap-3">
          <FailedIllustration />
          <h2>{t("title")}</h2>
          <p className="text-hard-grey">
            <Trans
              t={t}
              i18nKey={"pleasePayUs"}
              components={{ b: <b className="text-dark-blue" /> }}
            />
          </p>
        </div>
        <Link
          to={generatePath(pathname.WORKSPACE_BILLING_UPGRADE_TRUST_CENTER, {
            workspaceId,
          })}
          onClick={skipToContentTop}
        >
          <Button className="w-fit">{t("seePlans")}</Button>
        </Link>
      </div>
    </Modal>
  );
};

export default TrustCenterVerifyInfoModal;
