// Libraries
import { type Dispatch, type SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

// Resources
import { ReactComponent as LinkIcon } from "#src/assets/images/icons/link.svg";
import { ReactComponent as GlobalLine } from "#src/assets/images/icons/global-line.svg";
import { ReactComponent as ToolsIcon } from "#src/assets/images/icons/tools.svg";
import { ReactComponent as Progress2Line } from "#src/assets/images/icons/progress-2-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { ReactComponent as Check2Line } from "#src/assets/images/icons/check-2line.svg";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Button } from "#src/common/system/Button";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";
import EditDomainModal from "./modals/EditDomainModal";
import DnsInstructionModal from "./modals/DnsInstructionModal";
import ConfigSectionWrapper from "#src/common/helper/wrapper/ConfigSection";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

interface ITrustCenterConfigWebsiteDomainProps {
  workspaceId: string;
  originalSettings: ITrustCenterConfigDetails;
  onRefresh: () => void;
  domain: string;
  setDomain: Dispatch<SetStateAction<string>>;
}

const TrustCenterConfigWebsiteDomain = ({
  workspaceId,
  originalSettings,
  onRefresh,
  domain,
  setDomain,
}: ITrustCenterConfigWebsiteDomainProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.tab.website",
  });

  const [openDomainModal, setOpenDomainModal] = useState<boolean>(false);
  const [openDnsInstructionModal, setOpenDnsInstructionModal] =
    useState<boolean>(false);

  const [pending, setPending] = useState<boolean>(false);

  const onChangeTrustCenterDomain = (newDomain: string | null) => {
    setPending(true);
    trustCenterServices
      .update_trust_center_config_domain(workspaceId, {
        domain: newDomain,
      })
      .then(() => {
        if (newDomain) {
          setOpenDomainModal(false);
          setOpenDnsInstructionModal(true);
        }
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.changeTrustCenterDomain.fail"),
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  const getBgByDomainStatus = (
    status: ITrustCenterConfigDetails["domain_status"]
  ) => {
    if (status === "processing") {
      return "bg-support-fire-pastel";
    }
    if (status === "activated") {
      return "bg-support-seafoam-pastel";
    }
    return "bg-bright-grey";
  };

  const getDomainStatusLabel = (
    status: ITrustCenterConfigDetails["domain_status"]
  ) => {
    if (status === "processing") {
      return (
        <div
          className={`flex items-center h-full gap-2 px-4 rounded-md bg-policy-expire-in30days text-white font-medium-14`}
        >
          <Progress2Line className="h-4 w-4 min-w-[1rem]" />
          {t("customDomain.verifying")}
        </div>
      );
    }
    if (status === "activated") {
      return (
        <div
          className={`flex items-center h-full gap-2 px-4 rounded-md bg-support-seafoam text-white font-medium-14`}
        >
          <Check2Line className="h-4 w-4 min-w-[1rem]" />
          {t("customDomain.activated")}
        </div>
      );
    }
    return (
      <div
        className={`flex items-center h-full gap-2 px-4 rounded-md bg-dark-blue text-white font-medium-14`}
      >
        {status}
      </div>
    );
  };

  return (
    <>
      <ConfigSectionWrapper
        icon={<GlobalLine className="h-7 w-7" />}
        headerTitle={t("customDomain.title")}
        headerButton={
          originalSettings.domain_plan ? (
            <Link
              to={generatePath(pathname.WORKSPACE_BILLING, {
                workspaceId,
              })}
            >
              <Button>{t("customDomain.button.manageSubscription")}</Button>
            </Link>
          ) : (
            <Link
              to={generatePath(pathname.TRUST_CENTER_REGISTER_PRIVATE_DOMAIN, {
                workspaceId,
              })}
            >
              <Button>{t("customDomain.button.register")}</Button>
            </Link>
          )
        }
      >
        {originalSettings.domain ? (
          <div className="flex flex-col gap-6 p-6">
            <span className="text-regular-16-body">
              {t("customDomain.configDomainForTrustCenter")}{" "}
              <span className="text-primary">trust.yourcompany.com</span>
            </span>
            <div className="flex justify-between flex-wrap gap-1">
              <div
                className={`flex items-center rounded-md h-[2.25rem] text-primary ${getBgByDomainStatus(
                  originalSettings.domain_status
                )}`}
              >
                {getDomainStatusLabel(originalSettings.domain_status)}
                <span className="pl-2 pr-4 flex items-center gap-2">
                  <LinkIcon className="h-5 w-5" />
                  <a
                    href={"//" + originalSettings.domain}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {originalSettings.domain}
                  </a>
                </span>
              </div>
              <div className="flex gap-1">
                {/* TODO: if custom domain is not bought, disable this? */}
                <Button
                  variant="secondary"
                  disabled={!originalSettings.domain_plan}
                  onClick={() => {
                    setOpenDnsInstructionModal(true);
                  }}
                >
                  <ToolsIcon />
                  {t("customDomain.button.dnsConfig")}
                </Button>
                <Button
                  variant="warning"
                  pending={pending}
                  onClick={() => {
                    onChangeTrustCenterDomain(null);
                  }}
                >
                  <DeleteBin6Line />
                  {t("customDomain.button.remove")}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-between items-start gap-6 p-6">
            <span className="text-regular-16-body">
              {t("customDomain.configDomainForTrustCenter")}{" "}
              <span className="text-primary">trust.yourcompany.com</span>
            </span>
            <Button
              variant="secondary"
              disabled={!originalSettings.domain_plan}
              onClick={() => {
                setOpenDomainModal(true);
              }}
            >
              <ToolsIcon />
              {t("customDomain.button.config")}
            </Button>
          </div>
        )}
      </ConfigSectionWrapper>
      <EditDomainModal
        open={openDomainModal}
        onClose={() => {
          setOpenDomainModal(false);
          setDomain(originalSettings.domain ?? "");
        }}
        onClickNext={onChangeTrustCenterDomain}
        domain={domain}
        setDomain={setDomain}
        pending={pending}
      />
      <DnsInstructionModal
        open={openDnsInstructionModal}
        onClose={() => {
          setOpenDnsInstructionModal(false);
        }}
        domain={originalSettings.domain}
        verificationInfo={originalSettings.domain_verification}
      />
    </>
  );
};

export default TrustCenterConfigWebsiteDomain;
