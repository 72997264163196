// Libraries
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

// General
import { getLoginUrl } from "#src/utils/common";

// Components
import LoadingState from "#src/common/system/LoadingState";

const Login = () => {
  const [searchParams] = useSearchParams();

  /** We use window.location.replace since we're trying to go outside of this site (in this case, we're going to our login page)
   * We can't use Navigate since they don't support external navigation.
   * Ref: https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
   */
  useEffect(() => {
    window.location.replace(getLoginUrl());
  }, [searchParams]);

  return <LoadingState />;
};

export default Login;
