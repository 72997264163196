// Libraries
import { useEffect, useMemo, useRef, useState } from "react";
import { Tabs } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { useParams, useSearchParams } from "react-router-dom";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import TabButtonLabel from "#src/common/helper/antdProps/Tabs/TabButtonLabel";
import { disallowConcurrency } from "#src/utils/common";
import { NoVulnerabilitiesState } from "#src/common/states";
import { useSyncSearchParam } from "#src/hooks/useSyncSearchParam";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// Children
import Filter from "#src/components/vulnerabilities/Filter";
import VulnerabilityList from "#src/components/vulnerabilities/VulnerabilityList";
import DetailSection from "#src/components/vulnerabilities/DetailSection";
import { sanitizeVulnSearchParams } from "#src/components/vulnerabilities/utils";
import vulnerabilitiesServices, {
  type IVulnerabilitySummary,
  type IVulnerabilityItem,
} from "#src/services/vulnerabilities";
import {
  assessmentTypeItems,
  AssessmentTypeEnum,
  bountyItems,
  visibilityItems,
  archivedItems,
} from "#src/config/filterConstants";
import {
  severityItems,
  vulnerabilityStatusItems,
} from "#src/config/filter/vulnerability";
import { VulnerabilityStatusEnum } from "#src/config/filter/vulnerability/enum";
import { VulnerabilityStatusResponseValue } from "#src/config/filter/vulnerability/value";

const VulnerabilitiesDashboard = () => {
  const { t } = useTranslation("translation");

  const { workspaceId } = useParams<"workspaceId">();
  const [searchParams] = useSearchParams();

  const initVulnId = useMemo(() => searchParams.get("vuln_id"), [searchParams]);

  const initParams = useMemo(
    () => sanitizeVulnSearchParams(searchParams),
    [searchParams]
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const [vulnerabilities, setVulnerabilities] = useState<
    IVulnerabilityItem[] | null
  >(null);
  const [vulnSummary, setVulnSummary] = useState<IVulnerabilitySummary | null>(
    null
  );

  const [loadedVulnPages, setLoadedVulnPages] = useState<number>(0);
  const [totalVulnCount, setTotalVulnCount] = useState<number>(0);

  const [selectedVulnId, setSelectedVulnId] = useState<number | null>(
    initVulnId && !isNaN(+initVulnId) ? +initVulnId : null
  );

  // filters
  const [activeTab, setActiveTab] = useState<string>(
    initParams.filter_status ? initParams.filter_status : ""
  );
  const [createdFrom, setCreatedFrom] = useState<number | null>(
    initParams.from ? initParams.from : null
  );
  const [createdTo, setCreatedTo] = useState<number | null>(
    initParams.to ? initParams.to : null
  );
  const [updatedFrom, setUpdatedFrom] = useState<number | null>(
    initParams.updated_time_from ? initParams.updated_time_from : null
  );
  const [updatedTo, setUpdatedTo] = useState<number | null>(
    initParams.updated_time_to ? initParams.updated_time_to : null
  );
  const [assessmentType, setAssessmentType] = useState<IFilterItem | null>(
    initParams.assessment_type
      ? assessmentTypeItems.find(
          (item) => item.value === initParams.assessment_type
        ) || null
      : null
  );
  // TODO: put initParams.program into this
  const [program, setProgram] = useState<IFilterItem | null>(null);
  const [bounty, setBounty] = useState<IFilterItem | null>(
    initParams.rewarded
      ? bountyItems.find((item) => item.value === initParams.rewarded) || null
      : null
  );
  const [pentest, setPentest] = useState<IFilterItem | null>(
    initParams.pentest
      ? {
          key: initParams.pentest,
          value: initParams.pentest,
          getLabel: () => initParams.pentest,
        }
      : null
  );
  const [visibility, setVisibility] = useState<IFilterItem | null>(
    initParams.visibility
      ? visibilityItems.find((item) => item.value === initParams.visibility) ||
          null
      : null
  );
  const [archived, setArchived] = useState<IFilterItem | null>(
    initParams.archived
      ? archivedItems.find((item) => item.value === initParams.archived) || null
      : null
  );
  const [subStatus, setSubStatus] = useState<IFilterItem[] | null>(
    initParams.filter_sub_status
      ? vulnerabilityStatusItems
          .map((item) => item.children)
          .flat()
          .filter(
            (item) =>
              item.value &&
              item.value !== "" &&
              initParams.filter_sub_status?.split(",").includes(item.value)
          )
      : vulnerabilityStatusItems.find(
          (item) => item.key === VulnerabilityStatusEnum.OPEN
        )?.children || []
  );
  const [severity, setSeverity] = useState<IFilterItem | null>(
    initParams.filter_severity
      ? severityItems.find(
          (item) => item.value === initParams.filter_severity
        ) || null
      : null
  );
  const [selectedScanId, setSelectedScanId] = useState<IFilterItem | null>(
    initParams.scan_history_id
      ? {
          key: initParams.scan_history_id,
          value: initParams.scan_history_id,
          getLabel: () => initParams.scan_history_id,
        }
      : null
  );
  const [keyword, setKeyword] = useState<string>(
    initParams.q ? initParams.q : ""
  );

  const [isLoadingList, setLoadingList] = useState<boolean>(false);

  const tabList = [
    {
      key: VulnerabilityStatusResponseValue.OPEN,
      label: (
        <TabButtonLabel
          name={t("filter.vulnerabilityStatus.open")}
          count={vulnSummary?.open}
        />
      ),
    },
    {
      key: VulnerabilityStatusResponseValue.ACCEPTED,
      label: (
        <TabButtonLabel
          name={t("filter.vulnerabilityStatus.accepted")}
          count={vulnSummary?.accepted}
        />
      ),
    },
    {
      key: VulnerabilityStatusResponseValue.REJECTED,
      label: (
        <TabButtonLabel
          name={t("filter.vulnerabilityStatus.rejected")}
          count={vulnSummary?.rejected}
        />
      ),
    },
    {
      key: "all",
      label: <TabButtonLabel name={t("filter.all")} count={vulnSummary?.all} />,
    },
  ];

  const onChangeSelectedVulnId = (id: number) => {
    setSelectedVulnId(id);
  };

  const fetchVulnerabilityList = (
    params: Parameters<typeof vulnerabilitiesServices.list_vulnerabilities>[1]
  ) => {
    if (workspaceId) {
      try {
        vulnerabilitiesServices
          .vulnerabilities_summary(workspaceId, {
            ...params,
            filter_status: undefined,
            filter_sub_status: undefined,
          })
          .then((response) => {
            setVulnSummary(response);
          });
        vulnerabilitiesServices
          .list_vulnerabilities(workspaceId, params)
          .then((response) => {
            setVulnerabilities(response.results);
            setLoadedVulnPages(1);
            setTotalVulnCount(response.count);
          });
      } catch (error) {
        apiErrorHandler(error);
      }
    }
  };

  const defaultParams = useMemo<
    Parameters<typeof fetchVulnerabilityList>[0]
  >(() => {
    const evaluateStatus = vulnerabilityStatusItems
      .filter(
        (status) =>
          // Check if selected values are included all substatuses in this status
          status.children
            .map((item) => item.value)
            .every((item) => subStatus?.map((s) => s.value).includes(item)) &&
          // Check if all substatuses on this status are included in selected values
          subStatus?.every((item) =>
            status.children.map((s) => s.value).includes(item.value)
          )
      )
      .map((status) => status.value || "")
      .join(",");

    const statusParam =
      evaluateStatus && evaluateStatus.length
        ? evaluateStatus
        : activeTab && activeTab !== "all"
          ? activeTab
          : undefined;
    const subStatusParam =
      evaluateStatus && evaluateStatus.length
        ? undefined
        : subStatus?.map((item) => item.value).join(",");

    return {
      from: createdFrom ? createdFrom : undefined,
      to: createdTo ? createdTo : undefined,
      updated_time_from: updatedFrom ? updatedFrom : undefined,
      updated_time_to: updatedTo ? updatedTo : undefined,
      assessment_type: assessmentType?.value,
      program: program?.value,
      rewarded: bounty?.value,
      pentest: pentest?.value,
      visibility: visibility?.value,
      filter_status: statusParam,
      filter_sub_status: subStatusParam,
      filter_severity: severity?.value,
      archived: archived?.value,
      scan_history_id: selectedScanId?.value,
      sort: undefined,
      q: keyword,
      page: undefined,
      size: constants.DEFAULT_PAGE_SIZE,
    };
  }, [
    createdFrom,
    createdTo,
    updatedFrom,
    updatedTo,
    assessmentType,
    program,
    bounty,
    pentest,
    visibility,
    activeTab,
    subStatus,
    severity,
    archived,
    selectedScanId,
    keyword,
  ]);

  const debouncedFetchVulnerabilityList = useDebouncedCallback(
    fetchVulnerabilityList,
    constants.DEBOUNCE_TIME
  );

  const loadMoreVulnerabilities = disallowConcurrency(
    (callback: () => void) => {
      if (workspaceId) {
        vulnerabilitiesServices
          .list_vulnerabilities(workspaceId, {
            ...defaultParams,
            page: loadedVulnPages + 1,
          })
          .then((response) => {
            setVulnerabilities((prev) =>
              prev !== null
                ? [...prev, ...response.results]
                : [...response.results]
            );
            setLoadedVulnPages((prev) => prev + 1);
            setTotalVulnCount(response.count);
            callback();
          })
          .catch(apiErrorHandler);
      }
    }
  );

  const onChangeCreatedTimeConditionValue = (
    condition: string,
    from: number,
    to: number
  ) => {
    if (condition) {
      setCreatedFrom(from);
      setCreatedTo(to);
    } else {
      setCreatedFrom(null);
      setCreatedTo(null);
    }
    fetchVulnerabilityList({ ...defaultParams, from: from, to: to });
  };

  const onChangeUpdatedTimeConditionValue = (
    condition: string,
    from: number,
    to: number
  ) => {
    if (condition) {
      setUpdatedFrom(from);
      setUpdatedTo(to);
    } else {
      setUpdatedFrom(null);
      setUpdatedTo(null);
    }
    fetchVulnerabilityList({
      ...defaultParams,
      updated_time_from: from,
      updated_time_to: to,
    });
  };

  const onChangeAssessmentTypeSelection = (selected: typeof assessmentType) => {
    setAssessmentType(selected);
    if (selected?.key !== AssessmentTypeEnum.BUG_BOUNTY_PROGRAM) {
      setProgram(null);
      setBounty(null);
    }
    if (selected?.key !== AssessmentTypeEnum.AUTOMATION_SCANS) {
      setSelectedScanId(null);
    }
    fetchVulnerabilityList({
      ...defaultParams,
      assessment_type: selected?.value,
      scan_history_id:
        selected?.key === AssessmentTypeEnum.AUTOMATION_SCANS
          ? defaultParams.scan_history_id
          : undefined,
      rewarded:
        selected?.key === AssessmentTypeEnum.BUG_BOUNTY_PROGRAM
          ? defaultParams.rewarded
          : undefined,
      program:
        selected?.key === AssessmentTypeEnum.BUG_BOUNTY_PROGRAM
          ? defaultParams.program
          : undefined,
    });
  };

  const onChangeProgramSelection = (selected: typeof program) => {
    setProgram(selected);
    fetchVulnerabilityList({ ...defaultParams, program: selected?.value });
  };

  const onChangeBountySelection = (selected: typeof bounty) => {
    setBounty(selected);
    fetchVulnerabilityList({ ...defaultParams, rewarded: selected?.value });
  };

  const onChangePentestSelection = (selected: typeof pentest) => {
    setPentest(selected);
    fetchVulnerabilityList({ ...defaultParams, pentest: selected?.value });
  };

  const onChangeVisibilitySelection = (selected: typeof visibility) => {
    setVisibility(selected);
    fetchVulnerabilityList({ ...defaultParams, visibility: selected?.value });
  };

  const onChangeSubStatusSelection = (selected: typeof subStatus) => {
    setSubStatus(selected);

    const evaluateStatus = vulnerabilityStatusItems
      .filter(
        (status) =>
          // Check if selected values are included all substatuses in this status
          status.children
            .map((item) => item.value)
            .every((item) => selected?.map((s) => s.value).includes(item)) &&
          // Check if all substatuses on this status are included in selected values
          selected?.every((item) =>
            status.children.map((s) => s.value).includes(item.value)
          )
      )
      .map((status) => status.value || "")
      .join(",");

    const statusParam =
      evaluateStatus && evaluateStatus.length ? evaluateStatus : undefined;
    const subStatusParam =
      evaluateStatus && evaluateStatus.length
        ? undefined
        : selected?.map((item) => item.value).join(",");

    if (!statusParam && !subStatusParam) {
      setActiveTab("all");
    } else {
      setActiveTab(statusParam || "");
    }

    fetchVulnerabilityList({
      ...defaultParams,
      filter_status: statusParam,
      filter_sub_status: subStatusParam,
    });
  };

  const onChangeSeveritySelection = (selected: typeof severity) => {
    setSeverity(selected);
    fetchVulnerabilityList({
      ...defaultParams,
      filter_severity: selected?.value,
    });
  };

  const onChangeArchivedSelection = (selected: typeof archived) => {
    setArchived(selected);
    fetchVulnerabilityList({ ...defaultParams, archived: selected?.value });
  };

  const onChangeScanSelection = (selected: typeof selectedScanId) => {
    setSelectedScanId(selected);
    fetchVulnerabilityList({
      ...defaultParams,
      scan_history_id: selected?.value,
    });
  };

  const onChangeSearchKeyword = (newKeyword: typeof keyword) => {
    setKeyword(newKeyword);
    debouncedFetchVulnerabilityList({ ...defaultParams, q: newKeyword });
  };

  const onChangeStatusTab = (selected: typeof activeTab) => {
    setActiveTab(selected);
    setSubStatus(null);
    fetchVulnerabilityList({
      ...defaultParams,
      filter_status: selected === "all" ? undefined : selected,
      filter_sub_status: undefined,
    });
  };

  // Looks like a lot of thing to sync, because it IS a lot
  useSyncSearchParam("from", createdFrom ? createdFrom.toString() : "");
  useSyncSearchParam("to", createdTo ? createdTo.toString() : "");
  useSyncSearchParam("updated_from", updatedFrom ? updatedFrom.toString() : "");
  useSyncSearchParam("updated_to", updatedTo ? updatedTo.toString() : "");
  useSyncSearchParam("assessment_type", assessmentType?.value ?? "");
  useSyncSearchParam("pentest", pentest?.value ?? "");
  useSyncSearchParam("visibility", visibility?.value ?? "");
  useSyncSearchParam("filter_status", activeTab === "all" ? "" : activeTab);
  useSyncSearchParam(
    "filter_sub_status",
    subStatus && !activeTab ? subStatus.map((item) => item.value).join(",") : ""
  );
  useSyncSearchParam("filter_severity", severity?.value ?? "");
  useSyncSearchParam("archived", archived?.value ?? "");
  useSyncSearchParam("scan_history_id", selectedScanId?.value ?? "");
  useSyncSearchParam("q", keyword);
  useSyncSearchParam(
    "vuln_id",
    selectedVulnId ? selectedVulnId.toString() : ""
  );

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      if (workspaceId) {
        try {
          setLoadingList(true);
          vulnerabilitiesServices
            .vulnerabilities_summary(workspaceId, {
              ...initParams,
              filter_status: undefined,
              filter_sub_status: undefined,
            })
            .then((response) => {
              setVulnSummary(response);
            });
          vulnerabilitiesServices
            .list_vulnerabilities(workspaceId, initParams)
            .then((response) => {
              setVulnerabilities(response.results);
              if (
                !initVulnId &&
                response.results[0] &&
                initVulnId !== response.results[0].id.toString()
              ) {
                onChangeSelectedVulnId(response.results[0].id);
              }
              setLoadedVulnPages(1);
              setTotalVulnCount(response.count);
              setLoadingList(false);
            });
        } catch (error) {
          apiErrorHandler(error);
        }
      }
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, initVulnId, initParams]);

  return workspaceId ? (
    <div className="h-full w-full pt-3">
      <div className="px-4">
        <Filter
          tabSelectedStatus={activeTab}
          assessmentType={assessmentType}
          bugBountyProgram={program}
          bounty={bounty}
          pentest={pentest}
          visibility={visibility}
          subStatus={subStatus}
          severity={severity}
          archived={archived}
          selectedScanId={selectedScanId}
          onChangeCreatedTimeConditionValue={onChangeCreatedTimeConditionValue}
          onChangeUpdatedTimeConditionValue={onChangeUpdatedTimeConditionValue}
          onChangeAssessmentTypeSelection={onChangeAssessmentTypeSelection}
          onChangeBugBountyProgramSelection={onChangeProgramSelection}
          onChangeBountySelection={onChangeBountySelection}
          onChangePentestProgramSelection={onChangePentestSelection}
          onChangeVisibilitySelection={onChangeVisibilitySelection}
          onChangeSubStatusSelection={onChangeSubStatusSelection}
          onChangeSeveritySelection={onChangeSeveritySelection}
          onChangeArchivedSelection={onChangeArchivedSelection}
          onChangeScanSelection={onChangeScanSelection}
        />
        <Tabs
          items={tabList}
          activeKey={activeTab}
          onChange={onChangeStatusTab}
        />
      </div>
      <div
        ref={containerRef}
        className="flex gap-6 px-4 pt-6 bg-bright-grey justify-between"
      >
        <VulnerabilityList
          workspaceId={workspaceId}
          containerRef={containerRef}
          isLoading={isLoadingList}
          vulnerabilities={vulnerabilities}
          loadedPages={loadedVulnPages}
          totalVulnCount={totalVulnCount}
          selectedVulnId={selectedVulnId}
          onSelectVuln={(vulnId) => {
            onChangeSelectedVulnId(vulnId);
          }}
          searchKeyword={keyword}
          onChangeSearchKeyword={onChangeSearchKeyword}
          loadMoreVulnerabilityList={loadMoreVulnerabilities}
        />
        {!isLoadingList &&
        vulnerabilities?.length === 0 &&
        selectedVulnId === null ? (
          <div className="flex-1">
            <NoVulnerabilitiesState />
          </div>
        ) : (
          <DetailSection
            workspaceId={workspaceId}
            containerRef={containerRef}
            vulnerabilityId={selectedVulnId}
            refreshVulnerabilityList={() => {
              fetchVulnerabilityList(defaultParams);
            }}
          />
        )}
      </div>
    </div>
  ) : null;
};

export default VulnerabilitiesDashboard;
