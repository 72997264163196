// Libraries
import { useTranslation } from "react-i18next";

// Components
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import TableHeader, {
  type ITableHeaderPropItem,
} from "#src/common/Table/TableHeader";

// API-related
import paymentService from "#src/services/payment";

// Children
import BillingOrderTableRow from "./TableRow";

interface IBillingOrderTableProps {
  orderList:
    | Awaited<ReturnType<typeof paymentService.list_payments>>["results"]
    | null;
  isLoading: boolean;
}

const BillingOrderTable = ({
  orderList,
  isLoading,
}: IBillingOrderTableProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.tab.order",
  });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.paymentId"), align: "left" },
    { name: t("headers.service"), align: "left" },
    { name: t("headers.subscriptionPlan"), align: "left" },
    { name: t("headers.cycle"), align: "left" },
    { name: t("headers.price"), align: "left" },
    { name: t("headers.nextPayment"), align: "left" },
    { name: t("headers.paymentMethod"), align: "left" },
    { name: t("headers.status"), align: "left" },
    { name: "", align: "left" },
  ];

  return isLoading || orderList === null ? (
    <LoadingState />
  ) : orderList.length === 0 ? (
    <NoResultsState />
  ) : (
    <div className="grid grid-cols-[3fr_3fr_3fr_2fr_4fr_3fr_3fr_3fr_4rem]">
      <TableHeader title="workspace/billing/order" headerProps={headers} />
      {orderList.map((item) => (
        <BillingOrderTableRow
          key={`workspace/billing/order-tr${item.payment_id}`}
          data={item}
        />
      ))}
    </div>
  );
};

export default BillingOrderTable;
