// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as NoResultsIllustration } from "#src/assets/images/illustrations/no-data.svg";

/** @deprecated Please use EmptyState. */
const NoDataState = () => {
  const { t } = useTranslation("common", { keyPrefix: "state.noData" });

  return (
    <div className="flex justify-center items-center py-12">
      <div className="flex flex-col items-center">
        <NoResultsIllustration />
        <h4>{t("thereAreNoData")}</h4>
      </div>
    </div>
  );
};

export default NoDataState;
