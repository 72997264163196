import { generatePath, useParams } from "react-router";
import { BreadcrumbV2 } from "#src/commonV2/Breadcrumb";
import { pathname } from "#src/config/pathname";
import { useTranslation } from "react-i18next";
import { SearchboxV2 } from "#src/commonV2/Table/Searchbox";
import { useMemo, useState } from "react";
import constants from "#src/config/constants";
import { IFilterItemV2 } from "#src/commonV2/Table/Filter/types";
import { TableV2 } from "#src/commonV2/Table";
import { endpointService } from "#src/services/endpoint";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { IEndpointDevice } from "#src/services/endpoint/devices/types";
import { getOsIcon } from "#src/components/endpoint/utils";
import { Popover, Tooltip, Typography } from "@lockerpm/design";
import { AlertCircle, Plus } from "@untitled-ui/icons-react";
import { DeviceAssetType, DeviceStatus } from "#src/services/endpoint/enums";
import { Severity } from "#src/services/vulnerabilities/enums";
import { EndpointDeviceTableRowExpandContent } from "#src/components/endpoint/devices/RowExpandContent";
import { ButtonV2 } from "#src/commonV2/Button";
import { operatingSystemFilterItems } from "#src/components/endpoint/policies/filterItems";
import { assetTypeFilterItems } from "#src/components/endpoint/devices/filterItems";
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";
import { tagService } from "#src/services/tags";
import { EndpointDeviceTableActionCell } from "#src/components/endpoint/devices/ActionCell";
import { useSearchParams } from "react-router-dom";
import i18next from "i18next";
import { useFilter } from "#src/commonV2/Table/Filter/useFilter";
import { TagV2 } from "#src/commonV2/Tag";

export enum EndpointDeviceSearchParamKey {
  OPERATING_SYSTEM = "os",
  ASSET_TYPE = "type",
  TAG = "tag",
  FAILED_POLICY = "failed_policy_id",
  SOFTWARE = "program_id",
}

export const EndpointDevices = () => {
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  const { workspaceId } = useParams<"workspaceId">();
  const [searchParams] = useSearchParams();

  const locale = i18next.language;

  if (!workspaceId) {
    return null;
  }

  // --------------- INITIALIZATION ---------------

  // Tag list
  const fetchTagParams = useMemo<
    Parameters<typeof tagService.listTagsNoPaging>
  >(() => [workspaceId, {}], [workspaceId]);

  const { list: tagList } = useFetchNoPaging(
    tagService.listTagsNoPaging,
    fetchTagParams
  );

  const tagFilterItems: IFilterItemV2[] = useMemo(() => {
    if (tagList === null) return [];
    return tagList.map((tag) => ({
      key: tag.id.toString(),
      value: tag.name,
      getLabel: () => tag.name,
    }));
  }, [tagList]);

  // Policy list
  const fetchPolicyParams = useMemo(
    () =>
      [
        workspaceId,
        {
          sort: undefined,
          platform: undefined,
          enabled: undefined,
          host_ids: undefined,
          tag_ids: undefined,
        },
      ] satisfies Parameters<typeof endpointService.listPoliciesNoPaging>,
    [workspaceId]
  );

  const { list: policyList } = useFetchNoPaging(
    endpointService.listPoliciesNoPaging,
    fetchPolicyParams
  );

  // Selections on filter
  const failedPolicyItems: IFilterItemV2[] = useMemo(() => {
    if (policyList === null) return [];
    return policyList.map((policy) => ({
      key: policy.id.toString(),
      value: policy.id.toString(),
      getLabel: () =>
        locale === "vi" ? policy.policy.name.vi : policy.policy.name.en,
    }));
  }, [policyList, locale]);

  // --------------- DATA ---------------

  // Pagination
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  // Search/sort
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");

  // Also filter, but controlled by search params
  const softwareId = searchParams.get(EndpointDeviceSearchParamKey.SOFTWARE);

  const { value: operatingSystem, Render: OperatingSystemFilterRender } =
    useFilter({
      label: t("table.filter.operatingSystem.label"),
      selectionList: operatingSystemFilterItems,
      options: {
        searchParamKey: EndpointDeviceSearchParamKey.OPERATING_SYSTEM,
        sideEffectOnChange: () => {
          setSelectedPage(1);
        },
      },
    });

  const { value: assetType, Render: AssetTypeFilterRender } = useFilter({
    label: t("table.filter.assetType.label"),
    selectionList: assetTypeFilterItems,
    options: {
      searchParamKey: EndpointDeviceSearchParamKey.ASSET_TYPE,
      sideEffectOnChange: () => {
        setSelectedPage(1);
      },
    },
  });

  const { value: tagFilter, Render: TagFilterRender } = useFilter({
    label: t("table.filter.tag.label"),
    selectionList: tagFilterItems,
    options: {
      searchParamKey: EndpointDeviceSearchParamKey.TAG,
      sideEffectOnChange: () => {
        setSelectedPage(1);
      },
    },
  });

  const { value: failedPolicy, Render: FailedPolicyFilterRender } = useFilter({
    label: t("table.filter.failedPolicy.label"),
    selectionList: failedPolicyItems,
    options: {
      searchParamKey: EndpointDeviceSearchParamKey.FAILED_POLICY,
      sideEffectOnChange: () => {
        setSelectedPage(1);
      },
      hiddenFilter: true,
    },
  });

  // --------------- PARAMETERS ---------------

  const fetchParams = useMemo(
    () =>
      [
        workspaceId,
        {
          q: searchKeyword,
          page: selectedPage,
          size: pageSize,
          sort: sortOrder ?? undefined,
          os: operatingSystem,
          host_status: undefined,
          tag: tagFilter,
          policy_compliance: undefined,
          policy_compliance_id: failedPolicy,
          policy_compliance_status: failedPolicy ? "failed" : undefined,
          program_id: softwareId ?? undefined,
          asset_type: assetType,
        },
      ] satisfies Parameters<typeof endpointService.listDevices>,
    [
      workspaceId,
      searchKeyword,
      selectedPage,
      pageSize,
      sortOrder,
      operatingSystem,
      assetType,
      tagFilter,
      failedPolicy,
      softwareId,
    ]
  );

  // TODO: fetch software list
  // const fetchSoftwareParams = useMemo(
  //   () =>
  //     [
  //       workspaceId,
  //       {
  //         sort: undefined,
  //         platform: operatingSystem?.value,
  //         enabled: undefined,
  //       },
  //     ] satisfies Parameters<typeof endpointService.listPoliciesNoPaging>,
  //   [workspaceId, operatingSystem]
  // );

  // --------------- HOOKS ---------------

  const {
    list: deviceList,
    count: deviceCount,
    isLoading,
    updateData: updateDeviceList,
  } = useFetchPaginated(endpointService.listDevices, fetchParams);

  // --------------- METHODS ---------------

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  // --------------- EFFECTS ---------------

  // --------------- RENDER ---------------

  return (
    <>
      <div className="pt-5 pb-6 flex flex-col gap-5 min-h-screen">
        {/* Header (Breadcrumb + title) */}
        <div className="px-8 flex flex-col gap-4">
          <BreadcrumbV2
            levels={[
              {
                name: t("breadcrumb.levels.endpoint"),
                path: generatePath(pathname.ENDPOINT_OVERVIEW, { workspaceId }),
              },
            ]}
            currentPage={{
              name: t("breadcrumb.current.devices"),
            }}
          />
          <div className="flex justify-between gap-4">
            <span className="text-display-xs font-semibold text-gray-v2-900">
              {t("title")}
            </span>
            {/* TODO: add onClick open install drawer */}
            <ButtonV2 size="md">
              <Plus className="h-5 w-5 min-w-5" />
              {t("button.installAgent")}
            </ButtonV2>
          </div>
        </div>
        {/* Header (Breadcrumb + title) end */}

        <div className="px-8 flex flex-col gap-4 flex-1">
          <div className="flex justify-between items-start gap-2">
            {/* Filters */}
            <div className="flex gap-2 flex-wrap">
              {OperatingSystemFilterRender}
              {AssetTypeFilterRender}
              {TagFilterRender}
              {FailedPolicyFilterRender}
              {/* TODO: add software filter */}
            </div>
            {/* Filters end */}

            {/* Searchbox */}
            <SearchboxV2
              value={searchKeyword}
              onChange={(value) => {
                setSearchKeyword(value);
              }}
              placeholder={t("table.filter.searchbox.placeholder")}
            />
            {/* Searchbox end */}
          </div>

          {/* Table */}
          <TableV2<IEndpointDevice>
            data={deviceList ?? []}
            getRowKey={(item) => item.id.toString()}
            isLoading={isLoading}
            columns={[
              {
                title: t("table.header.name"),
                render: (item) => (
                  <>
                    <div
                      className={`h-2.5 w-2.5 min-w-2.5 rounded-full ${
                        item.is_managed
                          ? item.status === DeviceStatus.ONLINE
                            ? "bg-success-v2"
                            : item.status === DeviceStatus.OFFLINE
                              ? "bg-error-v2"
                              : "bg-gray-v2-300"
                          : "bg-gray-v2-300"
                      }`}
                    />
                    <span
                      className={`font-medium ${item.is_managed ? "text-gray-v2-900" : "text-gray-v2-400"}`}
                    >
                      {item.name}
                    </span>
                  </>
                ),
                gridTemplateValue: "3fr",
              },
              {
                title: t("table.header.os"),
                render: (item) =>
                  item.os && item.os.family ? getOsIcon(item.os.family) : "-",
                gridTemplateValue: "1fr",
                titleAlign: "center",
                overwriteWrapperProps: { className: "justify-center" },
              },
              {
                title: t("table.header.osVersion"),
                render: (item) => (
                  <Typography.Text
                    ellipsis={{
                      tooltip: (
                        <span className="text-sm">
                          {item.os && item.os.name ? item.os.name : "---"}
                        </span>
                      ),
                    }}
                  >
                    {item.os && item.os.name ? item.os.name : "---"}
                  </Typography.Text>
                ),
                gridTemplateValue: "minmax(11.5rem, 2fr)",
              },
              {
                title: t("table.header.issues"),
                render: (item) => (
                  <>
                    {item.issues.failed_policy_compliance +
                      (item.issues.vulns[Severity.CRITICAL] ?? 0)}
                    <Tooltip
                      title={
                        <div className="text-xs font-semibold text-center">
                          {t("table.issues.tooltip.criticalVulns")} (
                          {item.issues.vulns[Severity.CRITICAL] ?? 0})
                          <br />
                          {t("table.issues.tooltip.failingPolicies")} (
                          {item.issues.failed_policy_compliance})
                        </div>
                      }
                    >
                      <AlertCircle className="h-5 w-5 min-w-5 text-gray-v2" />
                    </Tooltip>
                  </>
                ),
                gridTemplateValue: "1fr",
                overwriteWrapperProps: { className: "justify-between" },
              },
              {
                title: t("table.header.owner"),
                render: (item) => item.owner,
                gridTemplateValue: "2fr",
              },
              {
                title: t("table.header.assetType"),
                render: (item) =>
                  item.asset_type === DeviceAssetType.COMPANY_OWNED
                    ? t("table.assetType.companyOwned")
                    : item.asset_type === DeviceAssetType.PERSONAL
                      ? t("table.assetType.personal")
                      : item.asset_type,
                gridTemplateValue: "2fr",
              },
              {
                title: t("table.header.privateIp"),
                render: (item) => item.internal_ip,
                gridTemplateValue: "2fr",
              },
              {
                title: t("table.header.tags"),
                render: (item) => (
                  <>
                    {item.tags.slice(0, 2).map((tag) => (
                      <TagV2 key={tag.id} name={tag.name} />
                    ))}
                    {item.tags.length > 2 ? (
                      <Popover
                        placement="bottomLeft"
                        content={
                          <div className="flex gap-x-1 gap-y-2 max-w-[40rem] flex-wrap">
                            {item.tags.slice(2).map((item, index) => {
                              return (
                                <TagV2
                                  key={`${item.name}-${index}`}
                                  {...item}
                                />
                              );
                            })}
                          </div>
                        }
                        trigger="hover"
                      >
                        <div>
                          <TagV2
                            name={"+" + (item.tags.length - 2)}
                            color="gray"
                          />
                        </div>
                      </Popover>
                    ) : null}
                  </>
                ),
                gridTemplateValue: "2fr",
                overwriteWrapperProps: { className: "gap-1" },
              },
              {
                title: null,
                render: (item) => (
                  <EndpointDeviceTableActionCell
                    workspaceId={workspaceId}
                    item={item}
                    updateDeviceList={updateDeviceList}
                  />
                ),
                overwriteWrapperProps: { className: "justify-center" },
                gridTemplateValue: "1fr",
              },
            ]}
            sort={{
              value: sortOrder,
              onChange: (value) => {
                setSortOrder(value);
              },
            }}
            pagination={{
              count: deviceCount,
              selected: selectedPage,
              onChange: onChangePage,
              size: { value: pageSize, onChange: onChangePageSize },
            }}
            options={{
              fitPageHeight: true,
            }}
            expandContent={(item) => (
              <EndpointDeviceTableRowExpandContent
                workspaceId={workspaceId}
                item={item}
              />
            )}
          />
          {/* Table end */}
        </div>
      </div>
    </>
  );
};
