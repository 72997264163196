import { useOutletContext } from "react-router-dom";
import "#src/components/devices/hosts/details/v2/overview/overview.scss";
import {
  PolicySection,
  SoftwareSection,
  AboutSection,
  ActivitiesSection,
  LocationSection,
  UsersSection,
} from "#src/components/devices/hosts/details/v2/overview";
import { IEndpointDevice } from "#src/services/endpoint/devices/types";

const HostOverview = () => {
  const { hostDetails, workspaceId, hostId } = useOutletContext<{
    hostDetails: IEndpointDevice;
    workspaceId: string;
    hostId: string;
  }>();

  // --------------- COMPUTED ---------------

  // --------------- RENDER ---------------

  if (!hostDetails) return null;

  return (
    <div className="flex flex-wrap justify-between gap-y-10">
      {/* Policy compliance */}
      <PolicySection workspaceId={workspaceId} hostId={hostId} />
      {/* Policy compliance end */}

      {/* Software vuln */}
      <SoftwareSection workspaceId={workspaceId} hostId={hostId} />
      {/* Software vuln end */}

      {/* About */}
      <AboutSection details={hostDetails} />
      {/* About end */}

      {/* Activities */}
      <ActivitiesSection details={hostDetails} />
      {/* Activities end */}

      {/* Location */}
      <LocationSection details={hostDetails} />
      {/* Location end */}

      {/* Users */}
      <UsersSection details={hostDetails} />
      {/* Users end */}
    </div>
  );
};

export default HostOverview;
