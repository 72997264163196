// Libraries
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { type ReactNode } from "react";
import { Dropdown } from "@lockerpm/design";
import { Link } from "react-router-dom";

// Resources
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";
import { ReactComponent as ShieldStarLine } from "#src/assets/images/icons/shield-star-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import { Input } from "#src/common/system/Input";
import { useAppSelector } from "#src/store/hooks";
import { ContentSection } from "#src/layouts/content";

interface IInfoFieldProps {
  title: string;
  input: ReactNode;
}
const InfoField = ({ title, input }: IInfoFieldProps) => {
  return (
    <div className="flex flex-col gap-4">
      <span className="font-medium-18">{title}</span>
      {input}
    </div>
  );
};

/** Account Setting page. This page actually don't let user change anything. User must go to idcystack to change their profile. */
const AccountSettingPage = () => {
  const { t } = useTranslation("accountSettings");

  const userInfo = useAppSelector((state) => state.auth.userInfo);

  return userInfo ? (
    <ContentSection>
      <h1>{t("title")}</h1>
      <div className="grid grid-cols-3 gap-6">
        <div className="p-6 flex flex-col items-center gap-6">
          <div className="flex flex-col items-center gap-3">
            <img
              src={userInfo?.avatar}
              className="rounded-md object-contain h-24 w-24 m-4"
            />
            <span className="flex items-center gap-1 font-regular-12 text-hard-grey">
              <TimeLine className="h-3.5 w-3.5" />
              {t("userInfo.joined")}{" "}
              {dayjs.unix(userInfo?.registered_time).fromNow()}
            </span>
          </div>
          <Link
            to={"https://id.cystack.net/security/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="secondary" size="large">
              <ShieldStarLine />
              {t("button.editSecuritySettings")}
            </Button>
          </Link>
        </div>
        <div className="flex flex-col gap-6 p-6">
          <InfoField
            title={t("userInfo.fullName")}
            input={<Input value={userInfo.full_name} disabled />}
          />
          <InfoField
            title={t("userInfo.username")}
            input={<Input value={userInfo.username} disabled />}
          />
          {/* Currently there's no biography field in data. Should ask backend for this. Last updated: 01/02/2024 */}
          <InfoField
            title={t("userInfo.biography")}
            input={<Input value={"---"} disabled />}
          />
          {/* TODO: Get country list to complete this dropdown. */}
          <InfoField
            title={t("userInfo.country")}
            input={
              <Dropdown menu={{ items: [] }} trigger={["click"]} disabled>
                <button className="h-11 px-3 w-full rounded-md border-2 text-left bg-bright-grey disabled:border-none disabled:text-medium-grey focus:border-primary focus:bg-white">
                  {userInfo.country}
                </button>
              </Dropdown>
            }
          />
        </div>
        <div className="flex flex-col gap-6 p-6">
          <InfoField
            title={t("userInfo.phoneNumber")}
            input={<Input value={userInfo.phone} disabled />}
          />
          {/* Changing email is not supported */}
          <InfoField
            title={t("userInfo.email")}
            input={<Input value={userInfo.email} disabled />}
          />
          {/* Currently there's no social profiles field in data. Should ask backend for this. Last updated: 01/02/2024 */}
          <InfoField
            title={t("userInfo.socialProfile")}
            input={<Input value={"---"} disabled />}
          />
        </div>
      </div>
    </ContentSection>
  ) : null;
};

export default AccountSettingPage;
