import { useTranslation } from "react-i18next";
import { IChartPlotProps } from "#src/@types/chart";
import { EndpointOverviewGraphWrapper } from "./wrappers/GraphWrapper";
import { EndpointOverviewStatWrapper } from "./wrappers/StatWrapper";
import { IEndpointOverviewResponse } from "#src/services/endpoint/types";
import { TAILWIND_COLORS } from "#src/utils/common";

export const EndpointOverviewStatsAndGraphs = ({
  data,
}: {
  data: IEndpointOverviewResponse["count"];
}) => {
  const { t } = useTranslation("endpoint", { keyPrefix: "overview" });

  // --------------- COMPUTED ---------------

  const devicesByStatusChartProps: IChartPlotProps[] = [
    {
      id: "managed",
      name: t("graphs.devicesByStatus.managed"),
      fill: TAILWIND_COLORS["brand-v2-400"],
      value: data.manage.find((item) => item.managed === "managed")?.count ?? 0,
    },
    {
      id: "unmanaged",
      name: t("graphs.devicesByStatus.unmanaged"),
      fill: TAILWIND_COLORS["green-light-v2-400"],
      value:
        data.manage.find((item) => item.managed === "unmanaged")?.count ?? 0,
    },
    {
      id: "unknown",
      name: t("graphs.devicesByStatus.unknown"),
      fill: TAILWIND_COLORS["yellow-v2-400"],
      value: data.manage.find((item) => item.managed === "unknown")?.count ?? 0,
    },
  ];

  const managedDevicesByOsChartProps: IChartPlotProps[] = [
    {
      id: "windows",
      name: t("graphs.managedDevicesByOs.windows"),
      fill: TAILWIND_COLORS["brand-v2-400"],
      value: data.os.find((item) => item.os === "windows")?.count ?? 0,
    },
    {
      id: "macos",
      name: t("graphs.managedDevicesByOs.macOs"),
      fill: TAILWIND_COLORS["green-light-v2-400"],
      value: data.os.find((item) => item.os === "darwin")?.count ?? 0,
    },
    {
      id: "linux",
      name: t("graphs.managedDevicesByOs.linux"),
      fill: TAILWIND_COLORS["yellow-v2-400"],
      value: data.os.find((item) => item.os === "ubuntu")?.count ?? 0,
    },
  ];

  const failedPoliciesByOsChartProps: IChartPlotProps[] = [
    {
      id: "windows",
      name: t("graphs.failedPoliciesByOs.windows"),
      fill: TAILWIND_COLORS["brand-v2-400"],
      value:
        data.failed_policies.find((item) => item.os === "windows")?.count ?? 0,
    },
    {
      id: "macos",
      name: t("graphs.failedPoliciesByOs.macOs"),
      fill: TAILWIND_COLORS["green-light-v2-400"],
      value:
        data.failed_policies.find((item) => item.os === "darwin")?.count ?? 0,
    },
    {
      id: "linux",
      name: t("graphs.failedPoliciesByOs.linux"),
      fill: TAILWIND_COLORS["yellow-v2-400"],
      value:
        data.failed_policies.find((item) => item.os === "ubuntu")?.count ?? 0,
    },
  ];

  const appsWithVulnsByOsChartProps: IChartPlotProps[] = [
    {
      id: "windows",
      name: t("graphs.appsWithVulnsByOs.windows"),
      fill: TAILWIND_COLORS["brand-v2-400"],
      value:
        data.vuln_programs.find((item) => item.os === "windows")?.count ?? 0,
    },
    {
      id: "macos",
      name: t("graphs.appsWithVulnsByOs.macOs"),
      fill: TAILWIND_COLORS["green-light-v2-400"],
      value:
        data.vuln_programs.find((item) => item.os === "darwin")?.count ?? 0,
    },
    {
      id: "linux",
      name: t("graphs.appsWithVulnsByOs.linux"),
      fill: TAILWIND_COLORS["yellow-v2-400"],
      value:
        data.vuln_programs.find((item) => item.os === "ubuntu")?.count ?? 0,
    },
  ];

  // --------------- RENDER ---------------

  return (
    <div className="px-8 grid grid-cols-4 gap-4">
      <EndpointOverviewStatWrapper
        title={t("statistics.devices")}
        amount={data.devices}
      />
      <EndpointOverviewStatWrapper
        title={t("statistics.software")}
        amount={data.applications}
      />
      <EndpointOverviewStatWrapper
        title={t("statistics.departments")}
        amount={data.departments}
      />
      <EndpointOverviewStatWrapper
        title={t("statistics.users")}
        amount={data.users}
      />
      <EndpointOverviewGraphWrapper
        title={t("graphs.devicesByStatus.title")}
        chartData={devicesByStatusChartProps}
        getUnit={(count: number) =>
          t("graphs.devicesByStatus.device", { count })
        }
      />
      <EndpointOverviewGraphWrapper
        title={t("graphs.managedDevicesByOs.title")}
        chartData={managedDevicesByOsChartProps}
        getUnit={(count: number) =>
          t("graphs.managedDevicesByOs.device", { count })
        }
      />
      <EndpointOverviewGraphWrapper
        title={t("graphs.failedPoliciesByOs.title")}
        chartData={failedPoliciesByOsChartProps}
        getUnit={(count: number) =>
          t("graphs.failedPoliciesByOs.policy", { count })
        }
      />
      <EndpointOverviewGraphWrapper
        title={t("graphs.appsWithVulnsByOs.title")}
        chartData={appsWithVulnsByOsChartProps}
        getUnit={(count: number) =>
          t("graphs.appsWithVulnsByOs.app", { count })
        }
      />
    </div>
  );
};
