import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import type { MultilingualText } from "#src/@types/common";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface ITrustCenterComplianceItem {
  id: string;
  status: string;
  compliance: {
    id: string;
    name: MultilingualText;
    logo: string;
    description: MultilingualText;
    url: string;
  };
}

export function list_trust_center_compliance(
  workspace_id: string,
  params: { status: string | undefined } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<ITrustCenterComplianceItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/compliance`,
    { params }
  );
}

export interface ITrustCenterComplianceDetails {
  cert_id: string;
  cert_publish_time: number;
  attachments: any[];
}

export function retrieve_trust_center_compliance(
  workspace_id: string,
  compliance_id: string
) {
  return request.get<never, ITrustCenterComplianceDetails>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/compliance/${compliance_id}`
  );
}

export function update_trust_center_compliance(
  workspace_id: string,
  compliance_id: string,
  data:
    | {
        cert_id: string;
        cert_publish_time: number;
        attachments: any[];
      }
    | { status: string }
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/compliance/${compliance_id}`,
    data
  );
}

export function retrieve_compliance_verification_status(workspace_id: string) {
  return request.get<never, { verified: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/compliance/verification`
  );
}
