// Libraries
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { z } from "zod";
import { AxiosError } from "axios";

// General
import { pathname } from "#src/config/pathname";
import { updatePlan } from "#src/store/slices/trustCenter";
import { useAppDispatch } from "#src/store/hooks";

// Components
import ContactSupportForm from "#src/common/helper/contactSupport";
import type { FormErrorItem } from "#src/common/helper/wrapper/InputField";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";

// Children
import ContactSuccessModal from "./SuccessModal";

interface ITrustCenterOnboardContactFormProps {
  workspaceId: string;
  open: boolean;
  onClose: () => void;
}

const TrustCenterOnboardContactForm = ({
  workspaceId,
  open,
  onClose,
}: ITrustCenterOnboardContactFormProps) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [errorObj, setErrorObj] = useState<{
    fullname: FormErrorItem;
    email: FormErrorItem;
    phone_number: FormErrorItem;
    organization_name: FormErrorItem;
    industry: FormErrorItem;
  }>({
    fullname: undefined,
    email: undefined,
    phone_number: undefined,
    organization_name: undefined,
    industry: undefined,
  });

  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const requestEnterprisePlan = (form: {
    pronoun: string;
    fullName: string;
    jobTitle: string;
    email: string;
    phoneNumber: string;
    industry: string;
    employeeSize: string;
    companyName: string;
  }) => {
    trustCenterServices
      .request_trust_center_plan(workspaceId, {
        product_plan_id: "tc_enterprise",
        gender: form.pronoun,
        fullname: form.fullName,
        email: form.email,
        phone_number: form.phoneNumber,
        job_title: form.jobTitle,
        organization_name: form.companyName,
        industry: form.industry,
        employee_size: form.employeeSize,
      })
      .then((response) => {
        dispatch(updatePlan(response.product_plan_id));
        setOpenSuccessModal(true);
        onClose();
      })
      .catch((error) => {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 400
        ) {
          const getErrorData = parseBadRequest(
            error.response.data,
            z.object({
              fullname: z.optional(z.array(z.string())),
              email: z.optional(z.array(z.string())),
              phone_number: z.optional(z.array(z.string())),
              organization_name: z.optional(z.array(z.string())),
              industry: z.optional(z.array(z.string())),
            })
          );
          setErrorObj((prev) => ({
            ...prev,
            fullname: getErrorData.details.fullname
              ? () => (getErrorData.details.fullname as string[])[0]
              : undefined,
            email: getErrorData.details.email
              ? () => (getErrorData.details.email as string[])[0]
              : undefined,
            phone_number: getErrorData.details.phone_number
              ? () => (getErrorData.details.phone_number as string[])[0]
              : undefined,
            organization_name: getErrorData.details.organization_name
              ? () => (getErrorData.details.organization_name as string[])[0]
              : undefined,
            industry: getErrorData.details.industry
              ? () => (getErrorData.details.industry as string[])[0]
              : undefined,
          }));
        } else {
          apiErrorHandler(error);
        }
      });
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    navigate(generatePath(pathname.TRUST_CENTER_POLICY, { workspaceId }));
  };

  return (
    <>
      <ContactSupportForm
        workspaceId={workspaceId}
        open={open}
        onClose={onClose}
        onSendRequest={(value) => {
          requestEnterprisePlan(value);
        }}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
      />
      <ContactSuccessModal
        open={openSuccessModal}
        onClose={onCloseSuccessModal}
      />
    </>
  );
};

export default TrustCenterOnboardContactForm;
