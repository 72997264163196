import type { Dispatch, ReactNode, SetStateAction } from "react";
import { Dropdown } from "@lockerpm/design";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { CommentTypeEnum, commentTypeDropdownItems } from "./constants";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";

interface IEditorTypeSwitchProps {
  commentType: { key: string; getLabel: () => ReactNode };
  setCommentType: Dispatch<
    SetStateAction<{ key: string; getLabel: () => ReactNode }>
  >;
}

const EditorTypeSwitch = ({
  commentType,
  setCommentType,
}: IEditorTypeSwitchProps) => {
  const onChangeCommentType = (key: string) => {
    const selectedType = commentTypeDropdownItems.find(
      (item) => item.key === key
    );
    if (selectedType) {
      setCommentType(selectedType);
    }
  };

  return (
    <Dropdown
      menu={{
        items: commentTypeDropdownItems.map((item) => ({
          key: item.key,
          label: (
            <DropdownItemLabel
              selected={commentType.key === item.key}
              syncWidthId="dropdown-commentType"
            >
              {item.getLabel()}
            </DropdownItemLabel>
          ),
        })),
        onClick: ({ key }) => {
          onChangeCommentType(key);
        },
      }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <button
        id="dropdown-commentType"
        className={`flex justify-between items-center p-3 w-60 rounded-md ${
          commentType.key === CommentTypeEnum.COMMENT
            ? "bg-label-blue"
            : commentType.key === CommentTypeEnum.NOTE
              ? "bg-note"
              : "bg-bright-grey"
        }`}
      >
        {commentType.getLabel()}
        <div className="w-4 h-4">
          <ArrowDownSLine className="w-4 h-4" />
        </div>
      </button>
    </Dropdown>
  );
};

export default EditorTypeSwitch;
