// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

// General
import { useAppDispatch, useAppSelector } from "#src/store/hooks";
import { pathname } from "#src/config/pathname";
import {
  selectWorkspace,
  updateWorkspaceList,
} from "#src/store/slices/workspace";

// Components
import { Modal } from "#src/common/system/Modal";
import { Button } from "#src/common/system/Button";
import { Input } from "#src/common/system/Input";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import workspaceServices, {
  type IWorkspaceItem,
} from "#src/services/workspace";

interface IConfirmDeleteWorkspaceModalProps {
  workspaceId: string;
  open: boolean;
  onClose: () => void;
  currentWorkspace: IWorkspaceItem;
}

const ConfirmDeleteWorkspaceModal = ({
  workspaceId,
  open,
  onClose,
  currentWorkspace,
}: IConfirmDeleteWorkspaceModalProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "detail.deleteWorkspace.modal.confirm",
  });

  const workspaceList = useAppSelector((state) => state.workspace.workspaces);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [inputConfirmDelete, setInputConfirmDelete] = useState<string>("");
  const [pending, setPending] = useState<boolean>(false);

  const onCloseAndCleanup = () => {
    onClose();
    setInputConfirmDelete("");
  };

  const onDeleteWorkspace = () => {
    // TODO: make a custom confirm modal
    if (workspaceId) {
      setPending(true);
      const newWorkspaceList = workspaceList.filter(
        (ws) => ws.id !== workspaceId
      );

      workspaceServices
        .delete_workspace(workspaceId)
        .then(() => {
          dispatch(updateWorkspaceList(newWorkspaceList));
          dispatch(selectWorkspace(newWorkspaceList[0].id));
          if (newWorkspaceList.length > 0) {
            navigate(pathname.HOME);
          } else {
            navigate(pathname.NEW_WORKSPACE);
          }
          onCloseAndCleanup();
          createToast({
            type: "success",
            message: t("notification.workspaceDeleted.success", {
              name: currentWorkspace?.name,
              id: workspaceId,
            }),
          });
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.workspaceDeleted.fail"),
          });
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  const displayWorkspaceName = currentWorkspace.short_name
    ? currentWorkspace.short_name
    : currentWorkspace.name;

  return (
    <Modal open={open} onChangeOpen={onCloseAndCleanup}>
      <div className="flex flex-col gap-3">
        <h2 className="text-warning">
          {t("title")} <b>{displayWorkspaceName}</b>?
        </h2>
        <p className="text-hard-grey">{t("toConfirm")}</p>
        <Input
          value={inputConfirmDelete}
          onChange={(e) => {
            setInputConfirmDelete(e.target.value);
          }}
        />
      </div>
      <div className="flex justify-end gap-1">
        <Button variant="secondary" onClick={onCloseAndCleanup}>
          {t("button.cancel")}
        </Button>
        <Button
          variant="danger"
          disabled={inputConfirmDelete !== displayWorkspaceName}
          onClick={onDeleteWorkspace}
          pending={pending}
        >
          {t("button.delete")}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteWorkspaceModal;
