import { forwardRef, type ForwardedRef, type HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

const tailwindString =
  "grid grid-cols-subgrid col-span-full relative bg-white text-current transition-colors duration-300 hover:bg-gray-v2-50 last:overflow-hidden last:rounded-b-xl";

export const TableRowWrapperV2 = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  return (
    <div
      ref={ref as ForwardedRef<HTMLDivElement>}
      {...props}
      className={twMerge(tailwindString, props.className)}
    />
  );
});

TableRowWrapperV2.displayName = "TableRowWrapper";
