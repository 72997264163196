import { Link } from "react-router-dom";
import { HomeLine, ChevronRight } from "@untitled-ui/icons-react";
import { pathname } from "#src/config/pathname";
import { Fragment } from "react";

interface IBreadcrumbV2Props {
  levels: { name: string; path: string }[];
  currentPage: { name: string; tab?: string };
}

export const BreadcrumbV2 = ({ levels, currentPage }: IBreadcrumbV2Props) => {
  return (
    <div className="flex items-center gap-2">
      {/* Origin */}
      <Link to={pathname.HOME}>
        <HomeLine className="h-5 w-5 text-gray-v2" />
      </Link>
      {/* Origin end */}

      {/* Levels */}
      {levels.map((levelItem) => (
        <Fragment key={`breadcrumb-level-${levelItem.name}`}>
          <ChevronRight className="h-4 w-4 text-gray-v2-300" />
          <Link
            to={levelItem.path}
            className="text-sm font-semibold text-gray-v2-600 no-underline"
          >
            {levelItem.name}
          </Link>
        </Fragment>
      ))}
      {/* Levels end */}

      {/* Current page */}
      <ChevronRight className="h-4 w-4 text-gray-v2-300" />
      <span className="text-sm font-semibold text-brand-v2-700">
        {currentPage.name}
        {currentPage.tab ? (
          <>
            {" "}
            <span className="text-brand-v2-300">/</span> {currentPage.tab}
          </>
        ) : null}{" "}
      </span>
      {/* Current page end */}
    </div>
  );
};
