import { useCallback, useEffect, useMemo, useState } from "react";
import endpoint from "#src/config/endpoint";
import authServices from "#src/services/auth";
import useWebSocket from "react-use-websocket";
import { useTranslation } from "react-i18next";
import devicesServices from "#src/services/devices";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { createToast } from "#src/common/system/toasts";
import {
  PendingRemoteAccessModal,
  RemoteAccessInfoDrawer,
  ShutDownModal,
  RestartModal,
  UninstallAgentModal,
  EditDeviceModal,
} from "../actionModals";
import { DeviceAssetType } from "#src/services/endpoint/enums";

// --------------- SOCKET TYPES ---------------

enum RemoteAccessErrorCode {
  NOT_FOUND = "remote_control_not_found",
  DENIED = "remote_control_client_deny",
}

type SuccessMessage = {
  command: string;
  status: "success";
  result: { username: string; password: string };
};

type ErrorMessage = {
  command: string;
  status: "error";
  error: { code: RemoteAccessErrorCode; detail: string };
};

// --------------- PROPS TYPES ---------------

type Props = {
  workspaceId: string;
  hostId: number;
  deviceName: string;
  deviceTags: string[];
  deviceAssetType: DeviceAssetType;
  refreshHostDetails: () => void;
};

export const useHostControl = (props: Props) => {
  const {
    workspaceId,
    hostId,
    deviceName,
    deviceTags,
    deviceAssetType,
    refreshHostDetails,
  } = props;

  const accessToken = authServices.access_token() || "";
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- DATA ---------------

  const [isPendingRemoteAccess, setIsPendingRemoteAccess] = useState(false);
  const [isRemoteDeviceInfoOpen, setIsRemoteDeviceInfoOpen] = useState(false);
  const [isShutdownModalOpen, setIsShutdownModalOpen] = useState(false);
  const [isRestartModalOpen, setIsRestartModalOpen] = useState(false);
  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [remoteDeviceInfo, setRemoteDeviceInfo] = useState<{
    username: string;
    password: string;
  } | null>(null);

  // --------------- HOOKS ---------------

  const { lastJsonMessage } = useWebSocket<SuccessMessage | ErrorMessage>(
    `${endpoint.WEBSOCKET}/hosts/${hostId}?token=${accessToken}`
  );

  // --------------- METHODS ---------------

  const remoteToDevice = useCallback(async () => {
    try {
      const res = await devicesServices.remote_host(workspaceId, hostId);
      if (res.success) {
        setIsPendingRemoteAccess(true);
      }
    } catch (error) {
      apiErrorHandler(error, {
        toastMessage: t("notification.onRemoteToDevice.fail"),
      });
    }
  }, [hostId, t, workspaceId]);

  const shutdownDevice = useCallback(() => {
    setIsShutdownModalOpen(true);
  }, []);

  const restartDevice = useCallback(() => {
    setIsRestartModalOpen(true);
  }, []);

  const uninstallAgentOnDevice = useCallback(() => {
    setIsUninstallModalOpen(true);
  }, []);

  const editDevice = useCallback(() => {
    setIsEditModalOpen(true);
  }, []);

  // --------------- EFFECTS ---------------

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }
    const { status, command } = lastJsonMessage;

    if (
      status === "success" &&
      command === "remote_device" &&
      // Only open the drawer if user is waiting for it. Don't randomly open every time WebSocket return something.
      isPendingRemoteAccess
    ) {
      const { result } = lastJsonMessage as SuccessMessage;
      setRemoteDeviceInfo(result);
      setIsPendingRemoteAccess(false);
      setIsRemoteDeviceInfoOpen(true);
    }
    if (lastJsonMessage.status === "error") {
      const { error } = lastJsonMessage as ErrorMessage;
      setIsPendingRemoteAccess(false);
      // TODO: These 2 notifications should not be fade off by themselves. Require user interaction here.
      if (error?.code === RemoteAccessErrorCode.NOT_FOUND) {
        createToast({
          type: "error",
          message: RemoteAccessErrorCode.NOT_FOUND,
        });
      }
      if (error?.code === RemoteAccessErrorCode.NOT_FOUND) {
        createToast({
          type: "error",
          message: RemoteAccessErrorCode.NOT_FOUND,
        });
      }
    }
  }, [lastJsonMessage, isPendingRemoteAccess]);

  // --------------- RENDER ---------------

  const RemoteAccessModals = useMemo(() => {
    return (
      <>
        {/* Pending modal */}
        <PendingRemoteAccessModal
          isOpen={isPendingRemoteAccess}
          onClose={() => setIsPendingRemoteAccess(false)}
          deviceName={deviceName}
        />
        {/* Pending modal end */}

        {/* Remote info drawer */}
        <RemoteAccessInfoDrawer
          isOpen={isRemoteDeviceInfoOpen}
          onClose={() => setIsRemoteDeviceInfoOpen(false)}
          deviceName={deviceName}
          workspaceId={workspaceId}
          hostId={hostId}
          remoteDeviceInfo={remoteDeviceInfo}
        />
        {/* Remote info drawer end */}
      </>
    );
  }, [
    deviceName,
    hostId,
    isPendingRemoteAccess,
    isRemoteDeviceInfoOpen,
    remoteDeviceInfo,
    workspaceId,
  ]);

  const ShutdownHostModal = useMemo(() => {
    return (
      <ShutDownModal
        workspaceId={workspaceId}
        hostId={hostId}
        isOpen={isShutdownModalOpen}
        onClose={() => setIsShutdownModalOpen(false)}
        deviceName={deviceName}
      />
    );
  }, [deviceName, hostId, isShutdownModalOpen, workspaceId]);

  const RestartHostModal = useMemo(() => {
    return (
      <RestartModal
        workspaceId={workspaceId}
        hostId={hostId}
        isOpen={isRestartModalOpen}
        onClose={() => setIsRestartModalOpen(false)}
        deviceName={deviceName}
      />
    );
  }, [deviceName, hostId, isRestartModalOpen, workspaceId]);

  const UninstallHostModal = useMemo(() => {
    return (
      <UninstallAgentModal
        workspaceId={workspaceId}
        hostId={hostId}
        isOpen={isUninstallModalOpen}
        onClose={() => setIsUninstallModalOpen(false)}
        deviceName={deviceName}
      />
    );
  }, [deviceName, hostId, isUninstallModalOpen, workspaceId]);

  const EditHostModal = useMemo(() => {
    return (
      <EditDeviceModal
        workspaceId={workspaceId}
        hostId={hostId}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        deviceAssetType={deviceAssetType}
        deviceTags={deviceTags}
        refreshHostDetails={refreshHostDetails}
      />
    );
  }, [
    deviceAssetType,
    deviceTags,
    hostId,
    isEditModalOpen,
    workspaceId,
    refreshHostDetails,
  ]);

  // --------------- RETURN ---------------

  return {
    remoteToDevice,
    RemoteAccessModals,
    shutdownDevice,
    ShutdownHostModal,
    restartDevice,
    RestartHostModal,
    uninstallAgentOnDevice,
    UninstallHostModal,
    editDevice,
    EditHostModal,
  };
};
