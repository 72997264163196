// Libraries
import { Drawer } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import { useState, type Dispatch, type SetStateAction } from "react";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";
import constants from "#src/config/constants";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import { Input } from "#src/common/system/Input";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";

interface IEditPathDrawerProps extends IDrawerAdapterProps {
  access: { isPublic: boolean; alias: string };
  setAccess: Dispatch<SetStateAction<{ isPublic: boolean; alias: string }>>;
  originalSettings: ITrustCenterConfigDetails;
}

const EditPathDrawer = ({
  workspaceId,
  open,
  onClose,
  onRefresh,
  access,
  setAccess,
  originalSettings,
}: IEditPathDrawerProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.editPathDrawer",
  });

  const [pending, setPending] = useState<boolean>(false);

  const onCloseAndCleanup = () => {
    setAccess({
      isPublic: originalSettings.access.is_public,
      alias: originalSettings.access.alias,
    });
    onClose();
  };

  const onSavePath = () => {
    if (originalSettings.access.alias === access.alias) {
      onCloseAndCleanup();
      return;
    }
    setPending(true);
    trustCenterServices
      .update_trust_center_config_access(workspaceId, {
        is_public: originalSettings.access.is_public,
        alias: access.alias,
      })
      .then(() => {
        onRefresh();
        onCloseAndCleanup();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updateAccess.fail"),
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Drawer
      open={open}
      onClose={onCloseAndCleanup}
      closable={false}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onCloseAndCleanup}>
          <CloseLine />
        </IconButton>
      }
      footer={
        <div className="flex flex-col gap-3">
          <Button
            className="w-full"
            size="large"
            pending={pending}
            onClick={onSavePath}
          >
            {t("button.save")}
          </Button>
          <Button
            variant="secondary"
            className="w-full"
            size="large"
            onClick={onCloseAndCleanup}
          >
            {t("button.cancel")}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-3 p-6">
        <h4>{t("path")}</h4>
        <div className="flex items-center gap-1">
          <span className="whitespace-nowrap">
            {constants.TRUST_CENTER_URL_BASE}
          </span>
          <Input
            value={access.alias}
            onChange={(e) => {
              setAccess((prev) => ({ ...prev, alias: e.target.value }));
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default EditPathDrawer;
