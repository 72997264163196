// Libraries
import { Popover } from "@lockerpm/design";
import { useEffect, useMemo, useState, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import {
  generatePath,
  matchPath,
  useMatches,
  useNavigate,
  useParams,
} from "react-router-dom";

// Resources
import { ReactComponent as AppsLine } from "#src/assets/images/icons/apps-line.svg";
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";
import { ReactComponent as LogoCyStack } from "#src/assets/images/logos/logo-cystack.svg";
import { ReactComponent as LogoLocker } from "#src/assets/images/logos/logo-locker.svg";
import { ReactComponent as LogoWhitehub } from "#src/assets/images/logos/logo-whitehub.svg";

// General
import { helperPathname, pathname } from "#src/config/pathname";

// Components
import { Button } from "#src/common/system/Button";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";

// API-related
import { type ISolutionFromApi, list_solutions } from "#src/services/solutions";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

// Children
import { noSidebarPaths } from "../pathConfig";
import LayoutSidebarAccountMenu from "./AccountMenu";
import SidebarHeader from "./Header";
import LayoutSidebarMainMenu from "./MainMenu";
import {
  ESolutionKeys,
  type ISolution,
} from "#src/components/solutions/SolutionCard";
import { SolutionLink } from "#src/components/solutions/const";

const SolutionCard = ({
  logo,
  title,
  url,
  isExternal = true,
}: {
  logo: ReactNode;
  title: string;
  url: string;
  isExternal?: boolean;
}) => {
  return (
    <a
      href={url}
      rel="noreferrer"
      target={isExternal ? "_blank" : "_self"}
      className="no-underline"
    >
      <div className="flex items-center gap-3 rounded-md hover:bg-button-secondary-hover hover:text-primary text-dark-blue bg-bright-grey p-3 h-full">
        <div className="w-8 h-8 flex items-center justify-center flex-shrink-0 bg-white rounded-md border border-solid border-light-grey">
          {logo}
        </div>
        <span className="font-regular-16 flex-1">{title}</span>
      </div>
    </a>
  );
};

export const LayoutSidebar = () => {
  const { t } = useTranslation("layout", { keyPrefix: "sidebar" });
  const { t: tSolution } = useTranslation("solutions");
  const pathMatches = useMatches();
  const navigate = useNavigate();
  const [solutions, setSolutions] = useState<ISolutionFromApi[]>([]);

  const { workspaceId } = useParams<"workspaceId">();

  // TODO: move the solution popover elsewhere. This makes the code convoluted.
  useEffect(() => {
    const fetchSolution = async () => {
      if (!workspaceId) return;
      const response = await list_solutions(workspaceId);
      setSolutions(response);
    };
    fetchSolution();
  }, [workspaceId]);

  const defaultSolutions: Omit<ISolution, "enabled" | "description">[] =
    useMemo(
      () => [
        {
          key: ESolutionKeys.SECRETS_MANAGER,
          logo: <LogoLocker />,
          title: tSolution("solution.secrets_manager.sidebarTitle"),
          url: SolutionLink.LOCKER_SECRET_MANAGER,
        },
        {
          key: ESolutionKeys.PASSWORD_MANAGER,
          logo: <LogoLocker />,
          title: tSolution("solution.password_manager.sidebarTitle"),
          url: SolutionLink.LOCKER_PASSWORD_MANAGER,
        },
        {
          key: ESolutionKeys.ENDPOINT,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.endpoint.sidebarTitle"),
          url: SolutionLink.ENDPOINT,
          comingSoon: true,
        },
        {
          key: ESolutionKeys.DATA_LEAK,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.data_leak.sidebarTitle"),
          url: workspaceId
            ? generatePath(helperPathname.DATA_LEAK, { workspaceId })
            : pathname.HOME,
          isExternal: false,
        },
        {
          key: ESolutionKeys.VULN_SCAN,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.vuln_scan.sidebarTitle"),
          url: SolutionLink.VULNSCAN,
          comingSoon: true,
        },
        {
          key: ESolutionKeys.TRUST_CENTER,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.trust_center.sidebarTitle"),
          url: workspaceId
            ? generatePath(helperPathname.TRUST_CENTER, {
                workspaceId,
              })
            : pathname.HOME,
          isExternal: false,
          isCreate: true,
        },
        {
          key: ESolutionKeys.PENETRATION_TESTING,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.penetration_testing.sidebarTitle"),
          url: SolutionLink.PENETRATION_TESTING,
          isCreate: true,
        },
        {
          key: ESolutionKeys.RED_TEAMING,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.red_teaming.sidebarTitle"),
          url: SolutionLink.RED_TEAM,
          isCreate: true,
        },
        {
          key: ESolutionKeys.CLOUD_SECURITY_AUDIT,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.cloud_security_audit.sidebarTitle"),
          url: SolutionLink.INFRASTRUCTURE_AUDIT,
          isCreate: true,
        },
        {
          key: ESolutionKeys.SOURCE_CODE_AUDIT,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.source_code_audit.sidebarTitle"),
          url: SolutionLink.SOURCE_CODE_AUDIT,
          isCreate: true,
        },
        {
          key: ESolutionKeys.BLOCKCHAIN_PROTOCOL_AUDIT,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.blockchain_protocol_audit.sidebarTitle"),
          url: SolutionLink.BLOCKCHAIN_AUDIT,
          isCreate: true,
        },
        {
          key: ESolutionKeys.WHITEHUB_BUG_BOUNTY,
          logo: <LogoWhitehub className="w-5 h-5" />,
          title: tSolution("solution.whitehub_bug_bounty.sidebarTitle"),
          url: SolutionLink.WHITEHUB_BUG_BOUNTY,
          isCreate: true,
        },
        {
          key: ESolutionKeys.SECURITY_COMPLIANCE,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.security_compliance.sidebarTitle"),
          url: SolutionLink.COMPLIANCE_AUDIT_SERVICE,
          isCreate: true,
        },
        {
          key: ESolutionKeys.SECURITY_OPERATIONS_CENTER,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.security_operations_center.sidebarTitle"),
          url: SolutionLink.SOC,
          isCreate: true,
        },
        {
          key: ESolutionKeys.VULNERABILITY_MANAGEMENT,
          logo: <LogoCyStack className="w-5 h-5" />,
          title: tSolution("solution.vulnerability_management.sidebarTitle"),
          url: SolutionLink.VULNERABILITY_MANAGEMENT,
          isCreate: true,
        },
      ],
      [tSolution, workspaceId]
    );

  const isUserUsingProduct = useMemo(() => {
    return !!solutions.find(
      (item) => item.enabled && item.solution.type === "product"
    );
  }, [solutions]);
  const isUserUsingService = useMemo(() => {
    return !!solutions.find(
      (item) => item.enabled && item.solution.type === "service"
    );
  }, [solutions]);
  const userProducts = useMemo(() => {
    const products = defaultSolutions.filter((item) =>
      solutions.find(
        (solution) =>
          solution.solution.id === item.key &&
          solution.enabled &&
          solution.solution.type === "product"
      )
    );
    return products.length
      ? products
      : defaultSolutions.filter((item) =>
          [
            ESolutionKeys.TRUST_CENTER,
            ESolutionKeys.DATA_LEAK,
            ESolutionKeys.PASSWORD_MANAGER,
            ESolutionKeys.SECRETS_MANAGER,
          ].includes(item.key)
        );
  }, [solutions, defaultSolutions]);
  const userServices = useMemo(() => {
    const services = defaultSolutions.filter((item) =>
      solutions.find(
        (solution) =>
          solution.solution.id === item.key &&
          solution.enabled &&
          solution.solution.type === "service"
      )
    );
    return services.length
      ? services
      : defaultSolutions.filter((item) =>
          [
            ESolutionKeys.PENETRATION_TESTING,
            ESolutionKeys.WHITEHUB_BUG_BOUNTY,
            ESolutionKeys.SOURCE_CODE_AUDIT,
            ESolutionKeys.SECURITY_COMPLIANCE,
          ].includes(item.key)
        );
  }, [solutions, defaultSolutions]);

  const noSidebarMode = pathMatches.some((match) =>
    noSidebarPaths.some((path) => matchPath(path, match.pathname))
  );

  const isNotShowSolution = pathMatches.some(
    (match) =>
      matchPath(helperPathname.ACCOUNT, match.pathname) ||
      matchPath(helperPathname.WORKSPACE, match.pathname)
  );

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  const authorized: boolean =
    currentWorkspace?.role === WorkspaceMemberRoleValue.OWNER;

  return noSidebarMode ? null : (
    <div
      id="whitehub-sidebar"
      // If this width changes, please do remember to check and fix related widths on ./index.tsx
      className={`relative flex flex-col z-[1] h-full shadow-lg flex-1 lg:max-w-[22rem] max-w-[16.75rem] transition-all`}
    >
      <SidebarHeader />
      <div
        className={`${
          isNotShowSolution ? "h-[calc(100%_-_4rem)]" : "h-[calc(100%_-_7rem)]"
        } flex `}
      >
        <LayoutSidebarMainMenu />
        <LayoutSidebarAccountMenu />
      </div>
      {workspaceId && !isNotShowSolution ? (
        <Popover
          placement="rightTop"
          content={
            authorized ? (
              <div className="max-w-[35.5rem] p-3 rounded-md">
                <div className="w-full space-y-4">
                  {isUserUsingProduct || isUserUsingService ? (
                    <div className="space-y-4">
                      {userProducts.length && (
                        <span className="font-bold-16 text-dark-blue inline-block">
                          {tSolution("yourProduct")}
                        </span>
                      )}
                      <div className="grid grid-cols-2 gap-[0.375rem]">
                        {userProducts.map((item) => (
                          <div className="col-span-1" key={item.key}>
                            <SolutionCard
                              logo={item.logo}
                              title={item.title}
                              isExternal={item.isExternal}
                              url={item.url}
                            />
                          </div>
                        ))}
                      </div>
                      {userServices.length && (
                        <span className="font-bold-16 text-dark-blue inline-block">
                          {tSolution("yourService")}
                        </span>
                      )}
                      <div className="grid grid-cols-2 gap-[0.375rem]">
                        {userServices.map((item) => (
                          <div className="col-span-1" key={item.key}>
                            <SolutionCard
                              logo={item.logo}
                              title={item.title}
                              isExternal={item.isExternal}
                              url={item.url}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-4">
                      <span className="font-bold-16 text-dark-blue inline-block">
                        {tSolution("productForUser")}
                      </span>
                      <div className="grid grid-cols-2 gap-[0.375rem]">
                        {userProducts.map((item) => (
                          <div className="col-span-1" key={item.key}>
                            <SolutionCard
                              logo={item.logo}
                              title={item.title}
                              isExternal={item.isExternal}
                              url={item.url}
                            />
                          </div>
                        ))}
                      </div>
                      <span className="font-bold-16 text-dark-blue inline-block">
                        {tSolution("serviceForUser")}
                      </span>
                      <div className="grid grid-cols-2 gap-[0.375rem]">
                        {userServices.map((item) => (
                          <div className="col-span-1" key={item.key}>
                            <SolutionCard
                              logo={item.logo}
                              title={item.title}
                              isExternal={item.isExternal}
                              url={item.url}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <Button
                  onClick={() => {
                    navigate(
                      generatePath(pathname.SOLUTIONS, {
                        workspaceId,
                      })
                    );
                  }}
                  variant="text"
                  className="flex items-center gap-2 mx-auto mt-6 text-primary"
                >
                  <span>{tSolution("exploreSolution")}</span>
                  <ArrowRightUpLine className="w-4 h-4" />
                </Button>
              </div>
            ) : null
          }
          trigger="hover"
        >
          <div
            onClick={() => {
              navigate(
                generatePath(pathname.SOLUTIONS, {
                  workspaceId,
                })
              );
            }}
            className={`absolute left-0 right-0 z-50 bottom-0 flex justify-between bg-dark-blue items-center py-5 text-white cursor-pointer hover:bg-grey-blue px-6 border-t border-solid border-grey-blue font-medium-16 ${
              pathMatches.some((match) =>
                matchPath(pathname.SOLUTIONS, match.pathname)
              ) && "bg-hard-grey hover:bg-hard-grey"
            }`}
          >
            <div className="flex items-center gap-3">
              <AppsLine />
              <span>{t("allSolutions")}</span>
            </div>
            <ArrowRightUpLine />
          </div>
        </Popover>
      ) : null}
    </div>
  );
};
