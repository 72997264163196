// Libraries
// import { Dropdown } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

// Resources
// import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// Components
import { TextArea } from "#src/common/system/Input";
import { TagEditor } from "#src/common/system/Tag/TagEditor";

// Children
import HostInput from "../../common/HostInput";
import SoftwareInput from "../../common/SoftwareInput";
import { TagTypeValue } from "#src/config/filter/tag/value";

interface IAddControlRestrictionTabProps {
  workspaceId: string;
  // controlActionItems: {
  //   key: string;
  //   value: string;
  //   label: string;
  // }[];
  selectedSoftware: { key: string; value: string; label: string } | null;
  // controlAction: {
  //   key: string;
  //   value: string;
  //   label: string;
  // };
  selectedHosts: { key: string; id: string; name: string; label: string }[];
  tagList: { name: string }[];
  note: string;
  onChangeSoftware: (value: {
    key: string;
    value: string;
    label: string;
  }) => void;
  // onSelectControlAction: (key: string) => void;
  onChangeHost: (newHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => void;
  onRemoveHost: (removedHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => void;
  onAddDefaultHost: () => void;
  onChangeTags: (tags: { name: string }[]) => void;
  onChangeNote: (value: string) => void;
}

const AddControlRestrictionTab = ({
  workspaceId,
  // controlActionItems,
  selectedSoftware,
  // controlAction,
  selectedHosts,
  tagList,
  note,
  onChangeSoftware,
  // onSelectControlAction,
  onChangeHost,
  onRemoveHost,
  onAddDefaultHost,
  onChangeTags,
  onChangeNote,
}: IAddControlRestrictionTabProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "control.drawers.addNewControl",
  });

  return (
    <div className="py-6 flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <h4>{t("field.software.label")}</h4>
        <SoftwareInput
          workspaceId={workspaceId}
          selectedSoftware={selectedSoftware}
          onChangeSoftware={onChangeSoftware}
        />
      </div>
      {/* <div className="flex flex-col gap-3">
        <h4>
          {t("field.action.label")}
        </h4>
        <Dropdown
          menu={{
            items: controlActionItems.map((item) => ({
              key: item.key,
              label: (
                <DropdownItemLabel selected={controlAction.key === item.key}>
                  {item.label}
                </DropdownItemLabel>
              ),
            })),
            onClick: ({ key }) => {
              onSelectControlAction(key);
            },
          }}
          trigger={["click"]}
        >
          <button className="flex gap-2 items-center justify-between p-3 bg-bright-grey text-hard-grey rounded-md">
            {controlAction.label}
            <ArrowDownSLine />
          </button>
        </Dropdown>
      </div> */}
      <div className="flex flex-col gap-3">
        <h4>{t("field.applyToTags.label")}</h4>
        <TagEditor
          workspaceId={workspaceId}
          tagList={tagList}
          onChangeTags={onChangeTags}
          type={TagTypeValue.HOST}
        />
      </div>
      <div className="flex flex-col gap-3">
        <h4>{t("field.applyToHostName.label")}</h4>
        {selectedHosts.map((host, index) => (
          <HostInput
            key={`devices/control/newControl-restrictionTab-hostInput-${host}-${index}`}
            workspaceId={workspaceId}
            selectedHost={host}
            onChangeHost={onChangeHost}
            onRemoveHost={onRemoveHost}
            autofocus
          />
        ))}
        <button
          className="p-3 bg-bright-grey text-medium-grey rounded-md text-left"
          onClick={(e) => {
            e.stopPropagation();
            onAddDefaultHost();
          }}
        >
          {t("field.applyToHostName.search.placeholder")}
        </button>
      </div>
      <div className="flex flex-col gap-3">
        <h4>{t("field.notes.label")}</h4>
        <TextArea
          placeholder={t("field.notes.placeholder")}
          value={note}
          onChange={(e) => {
            onChangeNote(e.currentTarget.value);
          }}
        />
      </div>
    </div>
  );
};

export default AddControlRestrictionTab;
