// Libraries
import type { ReactNode } from "react";
import { Dropdown, Typography } from "@lockerpm/design";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";
import { ReactComponent as CloseCircleLine } from "#src/assets/images/icons/close-circle-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as ErrorWarningLine } from "#src/assets/images/icons/error-warning-line.svg";

// Components
import { TableDataCell, TableRowWrapper } from "#src/common/Table";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { SourceLabel } from "#src/common/helper/labels/SourceLabel";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { forceNormalCase } from "#src/utils/common";

// API-related
import type { ITrustCenterControlItem } from "#src/services/trustCenter/control";
import trustCenterServices from "#src/services/trustCenter";

// Children
import { complianceStatusItems } from "#src/config/filter/trustCenter";
import { ComplianceStatusValue } from "#src/config/filter/trustCenter/value";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

const getColorByComplianceStatus: {
  [K: string]: string;
} = {
  [ComplianceStatusValue.NON_COMPLIANCE]:
    "border-red text-red bg-red bg-opacity-10",
  [ComplianceStatusValue.COMPLIANCE]:
    "border-support-seafoam text-support-seafoam bg-support-seafoam bg-opacity-10",
  [ComplianceStatusValue.UNKNOWN]:
    "border-severity-none text-severity-none bg-severity-none-bg",
};

const getIconByComplianceStatus: { [K: string]: ReactNode } = {
  [ComplianceStatusValue.NON_COMPLIANCE]: (
    <CloseCircleLine className="h-5 w-5 min-h-[1.25rem] min-w-[1.25rem]" />
  ),
  [ComplianceStatusValue.COMPLIANCE]: (
    <CheckboxCircleLine className="h-5 w-5 min-h-[1.25rem] min-w-[1.25rem]" />
  ),
  [ComplianceStatusValue.UNKNOWN]: (
    <InformationLine className="h-5 w-5 min-h-[1.25rem] min-w-[1.25rem]" />
  ),
};

interface ITrustCenterControlTableItemProps {
  workspaceId: string;
  data: ITrustCenterControlItem;
  workspaceRole: string | undefined;
  onRefresh: () => void;
  onClickShowRefs: (refs: ITrustCenterControlItem["refs"]) => void;
  onClickShowGuidance: (item: ITrustCenterControlItem) => void;
}

const TrustCenterControlTableItem = ({
  workspaceId,
  data,
  workspaceRole,
  onRefresh,
  onClickShowRefs,
  onClickShowGuidance,
}: ITrustCenterControlTableItemProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "control" });

  const locale = i18next.language;

  const currentStatus = complianceStatusItems.find(
    (item) => item.value === data.status
  );

  const onChangeStatus = (newStatus: string) => {
    trustCenterServices
      .update_trust_center_control(workspaceId, data.id, {
        status: newStatus,
      })
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updateStatus.fail"),
        });
      });
  };

  return (
    <TableRowWrapper
      className="cursor-pointer"
      onClick={() => {
        onClickShowGuidance(data);
      }}
    >
      <TableDataCell>
        <div className="flex flex-col gap-1.5">
          <span className="flex items-center gap-3 font-bold-16">
            <ErrorWarningLine className="h-5 w-5 min-w-[1.25rem]" />
            {forceNormalCase(
              locale === "vi" ? data.procedure.name.vi : data.procedure.name.en
            )}
          </span>
          <span className="font-regular-14-body text-hard-grey">
            {locale === "vi"
              ? data.procedure.summary.vi
              : data.procedure.summary.en}
          </span>
        </div>
      </TableDataCell>
      <TableDataCell>
        {data.refs.length > 0 ? (
          <SourceLabel>
            <Typography.Text
              ellipsis={{
                tooltip: data.refs[0].id,
              }}
            >
              {data.refs[0].id}
            </Typography.Text>
          </SourceLabel>
        ) : null}
        {data.refs.length > 1 ? (
          <SourceLabel>
            <Typography.Text
              ellipsis={{
                tooltip: data.refs[1].id,
              }}
            >
              {data.refs[1].id}
            </Typography.Text>
          </SourceLabel>
        ) : null}
        {data.refs.length > 2 ? (
          <SourceLabel
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickShowRefs(data.refs);
            }}
          >
            +{data.refs.length - 2}
          </SourceLabel>
        ) : null}
      </TableDataCell>
      <TableDataCell>
        {workspaceRole === WorkspaceMemberRoleValue.OWNER ||
        workspaceRole === WorkspaceMemberRoleValue.ADMIN ? (
          <Dropdown
            menu={{
              items: complianceStatusItems
                .filter((item) => item.value)
                .map((item) => ({
                  key: item.key,
                  label: (
                    <DropdownItemLabel
                      selected={item.value === data.status}
                      syncWidthId="control_status-dropdown"
                    >
                      {item.getLabel()}
                    </DropdownItemLabel>
                  ),
                  onClick: ({ domEvent }) => {
                    domEvent.stopPropagation();
                    if (item.value) {
                      onChangeStatus(item.value);
                    }
                  },
                })),
            }}
            trigger={["click"]}
          >
            <div
              id="control_status-dropdown"
              className={
                "p-3 flex items-center justify-between gap-3 font-medium-16 rounded-md w-full cursor-pointer " +
                getColorByComplianceStatus[data.status]
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="flex items-center gap-3">
                {getIconByComplianceStatus[data.status]}
                {currentStatus?.getLabel()}
              </div>
              <ArrowDownSLine className="h-5 w-5 min-h-[1.25rem] min-w-[1.25rem]" />
            </div>
          </Dropdown>
        ) : (
          <div
            id="control_status-dropdown"
            className={
              "p-3 flex items-center justify-between gap-3 font-medium-16 rounded-md w-full " +
              getColorByComplianceStatus[data.status]
            }
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="flex items-center gap-3">
              {getIconByComplianceStatus[data.status]}
              {currentStatus?.getLabel()}
            </div>
          </div>
        )}
      </TableDataCell>
    </TableRowWrapper>
  );
};

export default TrustCenterControlTableItem;
