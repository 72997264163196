// Libraries
import { useEffect, useState } from "react";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { AxiosError } from "axios";

// Resources
import { ReactComponent as ArrowRightLine } from "#src/assets/images/icons/arrow-right-line.svg";
import { ReactComponent as ArrowLeftLine } from "#src/assets/images/icons/arrow-left-line.svg";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Button } from "#src/common/system/Button";
import StickySection from "#src/layouts/content/StickySection";
import ContentSection from "#src/layouts/content/ContentSection";
import TabProgress, { type IFormProgressStep } from "#src/common/TabProgress";
import { skipToContentTop } from "#src/utils/common";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";
import {
  type ContactFormSelection,
  jobTitleList,
} from "#src/common/helper/contactSupport/contactDetailFields";
import type { FormErrorItem } from "#src/common/helper/wrapper/InputField";
import { createToast } from "#src/common/system/toasts";

// API-related
import { PaymentMethodValue } from "#src/config/filter/workspace/value";
import paymentService, {
  type IPricingInput,
  type IPaymentForm,
  type IPromotionCodeResponse,
  type IValidPromotionCode,
} from "#src/services/payment";

// Children
import DataLeakKeywordFillForm from "#src/components/dataLeak/addKeyword/payment/FillForm";
import PaymentCompletePayment from "#src/components/payment/CompletePayment";

// WIP: all this page is not done.
const DataLeakAddKeywordPayment = () => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "addKeyword.paymentPage",
  });
  const { t: registerDomainT } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.fillForm.others",
  });
  const { t: commonT } = useTranslation("common", { keyPrefix: "input" });
  const { t: systemT } = useTranslation();

  const navigate = useNavigate();

  const { workspaceId } = useParams<"workspaceId">();
  const { state } = useLocation();

  const [defaultPaymentSettings, setDefaultPaymentSettings] = useState<Awaited<
    ReturnType<typeof paymentService.retrieve_workspace_payment_settings>
  > | null>(null);

  // Current step of form filling
  const [registerProgress, setRegisterProgress] = useState<number>(0);

  // Tab's states
  // Fill Form tab
  const [pricingForm, setPricingForm] = useState<IPricingInput>({
    currency: "VND",
    product_plans: [],
  });
  const [paymentForm, setPaymentForm] = useState<IPaymentForm>({
    payment_method: PaymentMethodValue.BANKING,
    customer: {
      full_name: "",
      phone_number: "",
      email: "",
      job_title: jobTitleList[0].value,
    },
    product_plans: [],
  });
  const [jobTitle, setJobTitle] = useState<ContactFormSelection>(
    jobTitleList[0]
  );

  const [pricingInfo, setPricingInfo] = useState<Awaited<
    ReturnType<typeof paymentService.calculate_pricing>
  > | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<Awaited<
    ReturnType<typeof paymentService.create_subscription_banking>
  > | null>(null);

  const [errorObj, setErrorObj] = useState<{
    full_name: FormErrorItem;
    phone_number: FormErrorItem;
    email: FormErrorItem;
  }>({
    full_name: undefined,
    phone_number: undefined,
    email: undefined
  });

  const [invoiceErrorObj, setInvoiceErrorObj] = useState<{
    name: FormErrorItem;
    tax_code: FormErrorItem;
    identity_card: FormErrorItem;
    address: FormErrorItem;
  }>({
    name: undefined,
    tax_code: undefined,
    identity_card: undefined,
    address: undefined
  });

  const getPricingInfo = async (form: typeof pricingForm) => {
    if (workspaceId) {
      await paymentService
        .calculate_pricing(workspaceId, form)
        .then((response) => {
          // TODO: check error_promo
          setPricingInfo(response);
        })
        .catch(apiErrorHandler);
    }
  };

  // // Typeguard for promotion code
  function isPromoValid(
    promo: IPromotionCodeResponse
  ): promo is IValidPromotionCode {
    return promo.error_promo === null;
  }

  const onApplyPromocode = async (value: string) => {
    if (!workspaceId) return false;

    return paymentService
      .check_promotion_code(workspaceId, { promo_code: value })
      .then((response) => {
        if (isPromoValid(response)) {
          const newPricingForm: typeof pricingForm = {
            ...pricingForm,
            product_plans: pricingForm.product_plans.map((plan) =>
              response.only_plan.includes(plan.product_plan_id) &&
              !plan.promo_code
                ? {
                    ...plan,
                    promo_code: value,
                  }
                : plan
            ),
          };
          setPricingForm(newPricingForm);
          getPricingInfo(newPricingForm);
          return true;
        }
        return false;
      })
      .catch(apiErrorHandler);
  };

  const onRemovePromocode = (removeId: String) => {
    const newPricingForm: typeof pricingForm = {
      ...pricingForm,
      product_plans: pricingForm.product_plans.map((plan) =>
        plan.product_plan_id === removeId
          ? {
              ...plan,
              promo_code: undefined,
            }
          : plan
      ),
    };
    setPricingForm(newPricingForm);
    getPricingInfo(newPricingForm);
  };

  // // For the edit modal in the step 2 (FillForm)
  const onEditPlan = (value: {
    keyword: string;
    product_plan_id: string;
    duration: string;
  }) => {
    const newProductPlans = pricingForm.product_plans.map((item) =>
      item.keyword === value.keyword
        ? {
            keyword: item.keyword,
            product_plan_id: value.product_plan_id,
            duration: value.duration,
            promo_code: item.promo_code,
          }
        : item
    );
    setPricingForm((prev) => ({ ...prev, product_plans: newProductPlans }));
    getPricingInfo({ ...pricingForm, product_plans: newProductPlans });
    setPaymentForm((prev) => ({ ...prev, product_plans: newProductPlans }));
  };

  // TODO
  const validateFillFormOptions = () => {
    if (paymentForm.vat_invoice) {
      const invoiceError = {
        name: !paymentForm.vat_information?.name?.trim() ? () => commonT("error.required") : undefined,
        address: !paymentForm.vat_information?.address?.trim() ? () => commonT("error.required") : undefined,
        tax_code: paymentForm.vat_information?.type === 'enterprise' && !paymentForm.vat_information?.tax_code?.trim() ? () => commonT("error.required") : undefined,
        identity_card: paymentForm.vat_information?.type === 'personal' && !paymentForm.vat_information?.identity_card?.trim() ? () => commonT("error.required") : undefined,
      }
      setInvoiceErrorObj(invoiceError)
      if (
        !paymentForm.vat_information?.name?.trim() ||
        !paymentForm.vat_information?.address?.trim() ||
        (paymentForm.vat_information?.type === 'enterprise' && !paymentForm.vat_information?.tax_code?.trim()) ||
        (paymentForm.vat_information?.type === 'personal' && !paymentForm.vat_information?.identity_card?.trim())
      ) {
        let message = "";
        if (invoiceError.name) {
          if (paymentForm.vat_information?.type === 'enterprise') {
            message = systemT('validation.required', { name: registerDomainT('wantInvoice.fields.businessName.label') })
          } else {
            message = systemT('validation.required', { name: registerDomainT('wantInvoice.fields.fullName.label') })
          }
        } else if (invoiceError.address) {
          message = systemT('validation.required', { name: registerDomainT('wantInvoice.fields.address.label') })
        } else if (invoiceError.tax_code) {
          message = systemT('validation.required', { name: registerDomainT('wantInvoice.fields.taxCode.label') })
        } else if (invoiceError.identity_card) {
          message = systemT('validation.required', { name: registerDomainT('wantInvoice.fields.citizenId.label') })
        }
        createToast({
          type: "error",
          message: message,
        });
        return false
      }
      return true
    }
    return true
  }

  const onClickNextToCompletePayment = () => {
    if (
      workspaceId &&
      // TODO: better validate, send error to correspondent fields
      paymentForm.customer.full_name &&
      paymentForm.customer.email &&
      paymentForm.customer.phone_number && 
      validateFillFormOptions()
    ) {
      paymentService
        .create_subscription_banking(workspaceId, paymentForm)
        .then((response) => {
          setRegisterProgress(1);
          skipToContentTop();
          setPaymentInfo(response);
        })
        .catch((error) => {
          if (
            error instanceof AxiosError &&
            error.response &&
            error.response.status === 400
          ) {
            const getErrorData = parseBadRequest(
              error.response.data,
              z.object({
                customer: z.object({
                  full_name: z.optional(z.array(z.string())),
                  phone_number: z.optional(z.array(z.string())),
                  email: z.optional(z.array(z.string())),
                }),
              })
            );
            setErrorObj((prev) => ({
              ...prev,
              full_name: getErrorData.details.customer.full_name
                ? () => (getErrorData.details.customer.full_name as string[])[0]
                : undefined,
              phone_number: getErrorData.details.customer.phone_number
                ? () =>
                    (getErrorData.details.customer.phone_number as string[])[0]
                : undefined,
              email: getErrorData.details.customer.email
                ? () => (getErrorData.details.customer.email as string[])[0]
                : undefined,
            }));
          } else {
            apiErrorHandler(error);
          }
        });
    } else {
      // This should never happen since if this happens the button will be disabled already.
      console.log("Must fill the information to continue");
    }
  };

  const registerSteps: IFormProgressStep[] = workspaceId
    ? [
        {
          title: t("fillForm.tabLabel"),
          element: (
            <DataLeakKeywordFillForm
              workspaceId={workspaceId}
              pricingInfo={pricingInfo}
              paymentForm={paymentForm}
              setPaymentForm={setPaymentForm}
              jobTitle={jobTitle}
              setJobTitle={setJobTitle}
              defaultPaymentSettings={defaultPaymentSettings}
              onEditPlan={onEditPlan}
              onApplyPromocode={onApplyPromocode}
              onRemovePromocode={onRemovePromocode}
              errorObj={errorObj}
              setErrorObj={setErrorObj}
              invoiceErrorObj={invoiceErrorObj}
              setInvoiceErrorObj={setInvoiceErrorObj}
            />
          ),
        },
        {
          title: t("payment.tabLabel"),
          element: (
            <PaymentCompletePayment
              workspaceId={workspaceId}
              paymentInfo={paymentInfo}
              settingPath={pathname.DATA_LEAK_OVERVIEW}
              paymentType="addKeyword"
            />
          ),
        },
      ]
    : [];

  useEffect(() => {
    const parsedKeywordState = z
      .array(
        z.object({
          product_plan_id: z.string(),
          duration: z.string(),
          keyword: z.string(),
        })
      )
      .safeParse(state);

    if (parsedKeywordState.success && workspaceId) {
      setPricingForm({
        currency: "VND",
        product_plans: parsedKeywordState.data,
      });
      setPaymentForm((prev) => ({
        ...prev,
        product_plans: parsedKeywordState.data,
      }));
      paymentService
        .calculate_pricing(workspaceId, {
          currency: "VND",
          product_plans: parsedKeywordState.data,
        })
        .then((response) => {
          setPricingInfo(response);
        })
        .catch(apiErrorHandler);
    } else {
      navigate(generatePath(pathname.DATA_LEAK_ADD_KEYWORD, { workspaceId }));
    }
  }, [workspaceId, state, navigate]);

  useEffect(() => {
    if (workspaceId) {
      paymentService
        .retrieve_workspace_payment_settings(workspaceId)
        .then((response) => {
          setPaymentForm((prev) => ({
            ...prev,
            customer: {
              full_name: response.customer_full_name,
              phone_number: response.customer_phone_number,
              email: response.customer_email,
              job_title: response.customer_job_title,
            },
          }));
          // TODO: when triggering things, update them with this default value
          setDefaultPaymentSettings(response);
          const matchedJob = jobTitleList.find(
            (title) => title.value === response.customer_job_title
          );
          if (matchedJob) {
            setJobTitle(matchedJob);
          }
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId]);

  return (
    <>
      <StickySection className="py-10 items-center max-w-[96rem]">
        <h1>{t("title")}</h1>
        <div className="flex gap-1">
          {registerProgress === 0 ? (
            <>
              <Button
                size="large"
                variant="secondary"
                onClick={() => {
                  navigate(
                    generatePath(pathname.DATA_LEAK_ADD_KEYWORD, {
                      workspaceId,
                    }),
                    { state: pricingForm.product_plans }
                  );
                }}
              >
                <ArrowLeftLine className="h-5 w-5" />
                {t("button.back")}
              </Button>
              <Button
                size="large"
                disabled={
                  !paymentForm.customer.full_name ||
                  !paymentForm.customer.email ||
                  !paymentForm.customer.phone_number
                }
                onClick={onClickNextToCompletePayment}
              >
                {t("button.continue")}
                <ArrowRightLine className="h-5 w-5" />
              </Button>
            </>
          ) : null}
        </div>
      </StickySection>
      <ContentSection className="max-w-[96rem]">
        <TabProgress steps={registerSteps} progress={registerProgress} />
      </ContentSection>
    </>
  );
};

export default DataLeakAddKeywordPayment;
