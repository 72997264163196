// Libraries
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

// Resources
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import global from "#src/config/global";
import type { IFilterItem } from "#src/@types/common";

// Components
import {
  FilterDropdown,
  SearchBox,
  SelectAllItemsDropdown,
} from "#src/common/Table/Filter";
import { IconButton } from "#src/common/system/Button";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import devicesServices from "#src/services/devices";
import { tagService } from "#src/services/tags";

// Children
import {
  controlNetworkActionItems,
  controlPortActionItems,
} from "#src/config/filterConstants";
import { ControlTableTabEnum } from "../enums";

interface IControlFilterProps {
  workspaceId: string;
  activeTab: string;
  totalCount: number;
  selectedCount: number;
  searchKeyword: string;
  controlAction: IFilterItem | null;
  host: IFilterItem | null;
  tag: IFilterItem | null;
  onChangeSearchKeyword: (keyword: string) => void;
  onChangeControlActionSelection: (selected: IFilterItem | null) => void;
  onChangeHostSelection: (selected: IFilterItem | null) => void;
  onChangeTagSelection: (selected: IFilterItem | null) => void;
  onSelectAllControl: () => void;
  onDeselectAllControl: () => void;
  onDeleteSelectedControls: () => void;
}

const ControlFilter = ({
  workspaceId,
  activeTab,
  totalCount,
  selectedCount,
  searchKeyword,
  controlAction,
  host,
  tag,
  onChangeSearchKeyword,
  onChangeControlActionSelection,
  onChangeHostSelection,
  onChangeTagSelection,
  onSelectAllControl,
  onDeselectAllControl,
  onDeleteSelectedControls,
}: IControlFilterProps) => {
  const { t } = useTranslation("devices", { keyPrefix: "control.filter" });

  const [hostItems, setHostItems] = useState<IFilterItem[]>([]);
  const [tagItems, setTagItems] = useState<IFilterItem[]>([]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      const fetchHosts = () => {
        devicesServices
          .list_hosts_shortly(workspaceId)
          .then((res) => {
            setHostItems(
              res.map((item) => ({
                key: item.id.toString(),
                value: item.id.toString(),
                getLabel: () => item.name,
              }))
            );
          })
          .catch(apiErrorHandler);
      };
      fetchHosts();
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      const fetchTags = () => {
        tagService
          .listTagsNoPaging(workspaceId)
          .then((res) => {
            setTagItems(
              res.map((item) => ({
                key: item.id.toString(),
                value: item.id.toString(),
                getLabel: () => item.name,
              }))
            );
          })
          .catch(apiErrorHandler);
      };
      fetchTags();
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId]);

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <SelectAllItemsDropdown
          selectedCount={selectedCount}
          totalCount={totalCount}
          onSelectAll={onSelectAllControl}
          onDeselectAll={onDeselectAllControl}
        />
        <IconButton
          variant="ghost"
          disabled={selectedCount === 0}
          onClick={() => {
            global.confirm(() => onDeleteSelectedControls());
          }}
        >
          <DeleteBin6Line />
        </IconButton>
        {activeTab === ControlTableTabEnum.NETWORK ||
        activeTab === ControlTableTabEnum.PORT ? (
          <FilterDropdown
            label={t("action")}
            selected={controlAction}
            options={
              activeTab === ControlTableTabEnum.NETWORK
                ? controlNetworkActionItems
                : activeTab === ControlTableTabEnum.PORT
                  ? controlPortActionItems
                  : []
            }
            onChangeSelection={onChangeControlActionSelection}
          />
        ) : null}
        <FilterDropdown
          label={t("applyToTag")}
          selected={tag}
          options={tagItems}
          onChangeSelection={onChangeTagSelection}
        />
        <FilterDropdown
          label={t("applyToHost")}
          selected={host}
          options={hostItems}
          onChangeSelection={onChangeHostSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default ControlFilter;
