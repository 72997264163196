// Libraries
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as GitRepositoryLine } from "#src/assets/images/icons/git-repository-line.svg";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";

// Children
import DataLeakAddKeywordFindAndSelect from "#src/components/dataLeak/addKeyword/FindAndSelect";
import DataLeakAddKeywordPricingTable from "#src/components/dataLeak/addKeyword/Pricing";

const DataLeakAddKeyword = () => {
  const { t } = useTranslation("dataLeak", { keyPrefix: "addKeyword" });

  const { workspaceId } = useParams<"workspaceId">();

  return workspaceId ? (
    <>
      <StickySection className="flex-col mb-8">
        <h1>{t("title")}</h1>
        <span className="font-regular-18-body text-hard-grey">
          {t("description")}
        </span>
        <div className="flex gap-2">
          <GitRepositoryLine className="h-5 w-5 fill-primary" />
          <a>{t("link.learnMore")}</a>
        </div>
      </StickySection>
      <ContentSection className="gap-12">
        <DataLeakAddKeywordFindAndSelect workspaceId={workspaceId} />
        <DataLeakAddKeywordPricingTable workspaceId={workspaceId} />
      </ContentSection>
    </>
  ) : null;
};

export default DataLeakAddKeyword;
