import { useMemo, useState } from "react";
import {
  Edit02,
  Modem02,
  RefreshCcw01,
  Power01,
  Trash01,
  DotsVertical,
} from "@untitled-ui/icons-react";
import { Popover } from "@lockerpm/design";
import { LoaderV2 } from "#src/commonV2/LoadingState";
import { useHostControl } from "./hooks/useHostControl";
import { IEndpointDevice } from "#src/services/endpoint/devices/types";
import { useTranslation } from "react-i18next";
import { TagV2 } from "#src/commonV2/Tag";

type Props = {
  workspaceId: string;
  details: IEndpointDevice;
  selectedTabLabel: string;
  refreshHostDetails: () => void;
};

const HostDetailsProfileSection = ({
  workspaceId,
  details,
  selectedTabLabel,
  refreshHostDetails,
}: Props) => {
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // ----------------------------- DATA -----------------------------

  const [isActionOpen, setIsActionOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // ----------------------------- HOOKS -----------------------------

  // Remote access
  const {
    remoteToDevice,
    RemoteAccessModals,
    shutdownDevice,
    ShutdownHostModal,
    restartDevice,
    RestartHostModal,
    uninstallAgentOnDevice,
    UninstallHostModal,
    editDevice,
    EditHostModal,
  } = useHostControl({
    workspaceId,
    hostId: details.id,
    deviceName: details.name,
    deviceAssetType: details.asset_type,
    deviceTags: details.tags.map((tag) => tag.name),
    refreshHostDetails,
  });

  // ----------------------------- COMPUTED -----------------------------

  const actions = useMemo(() => {
    return [
      {
        icon: <Edit02 className="h-5 w-5 text-gray-v2-700" />,
        text: t("table.action.edit"),
        action: editDevice,
      },
      {
        icon: <Modem02 className="h-5 w-5 text-gray-v2-700" />,
        text: "Remote access",
        action: async () => {
          setIsLoading(true);
          await remoteToDevice();
          setIsLoading(false);
        },
      },
      {
        icon: <RefreshCcw01 className="h-5 w-5 text-gray-v2-700" />,
        text: t("table.action.restart"),
        action: restartDevice,
      },
      {
        icon: <Power01 className="h-5 w-5 text-gray-v2-700" />,
        text: t("table.action.shutDown"),
        action: shutdownDevice,
      },
      {
        icon: <Trash01 className="h-5 w-5 text-error-v2-700" />,
        text: t("table.action.uninstallAgent"),
        color: "error",
        action: uninstallAgentOnDevice,
      },
    ];
  }, [
    remoteToDevice,
    shutdownDevice,
    restartDevice,
    uninstallAgentOnDevice,
    editDevice,
    t,
  ]);

  // ----------------------------- RENDER -----------------------------

  return (
    <>
      <div className="flex gap-3 justify-between w-full v2">
        <div className="flex flex-col gap-4">
          {/* Title */}
          <h2 className="text-display-xs font-semibold text-gray-v2-900">
            {details.name}
            <span className="text-gray-v2-300 mx-2">/</span>
            {selectedTabLabel}
          </h2>
          {/* Title end */}

          {/* Tags */}
          {details.tags.length > 0 && (
            <div className="flex gap-2 flex-wrap">
              {details.tags.map((tag) => (
                <TagV2 key={tag.id} name={tag.name} size="lg" />
              ))}
            </div>
          )}
          {/* Tags end */}
        </div>

        {/* Actions */}
        <div>
          <Popover
            trigger="click"
            placement="bottomLeft"
            className={isLoading ? "pointer-events-none" : ""}
            open={isActionOpen}
            onOpenChange={setIsActionOpen}
            content={
              <div style={{ margin: -6 }}>
                {actions.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setIsActionOpen(false);
                      item.action();
                      return;
                    }}
                    className="cursor-pointer px-2.5 py-2 rounded-md hover:bg-gray-v2-50 transition-colors flex gap-2 items-center min-w-40"
                  >
                    {item.icon}
                    <p
                      className={`text-md font-medium ${item.color ? `text-${item.color}-v2-700` : "text-gray-v2-700"}`}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            }
          >
            <div
              className="cursor-pointer border border-brand-v2-300 border-solid rounded-lg h-10 w-10 flex items-center justify-center transition-shadow"
              style={{
                boxShadow: isActionOpen
                  ? `0px 0px 0px 1px rgba(10, 13, 18, 0.18) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.05), 0px 0px 0px 2px #FFF, 0px 0px 0px 4px #528BFF`
                  : undefined,
              }}
            >
              {isLoading ? (
                <LoaderV2 baseSize={1} />
              ) : (
                <DotsVertical className="h-5 w-5 text-brand-v2-700" />
              )}
            </div>
          </Popover>
        </div>
        {/* Actions end */}
      </div>

      {/* Modals */}
      {RemoteAccessModals}
      {ShutdownHostModal}
      {RestartHostModal}
      {UninstallHostModal}
      {EditHostModal}
      {/* Modals end */}
    </>
  );
};

export default HostDetailsProfileSection;
