import type { IChartTooltipGetter } from "#src/@types/chart";

export const getChartTooltip: IChartTooltipGetter = ({
  chartPlot,
  totalValue,
  unit,
}) => {
  return chartPlot ? (
    <div
      style={{ backgroundColor: chartPlot.fill }}
      className="rounded-md p-3 shadow-lg whitespace-pre text-white font-medium-14"
    >
      <p>{chartPlot.name}</p>
      <p>
        {`${chartPlot.value} ${unit} (${((chartPlot.value / totalValue) * 100).toFixed()}%)`}
      </p>
    </div>
  ) : null;
};
