// Libraries
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

// Components
import { NoDataState } from "#src/common/states";

import { type IDataLeakOverviewTopKeyword } from "#src/services/dataLeak";

interface IOverviewTopLeakedKeywordsProps {
  overviewTopKeywords: Array<IDataLeakOverviewTopKeyword>;
}

const OverviewTopLeakedKeywords = ({
  overviewTopKeywords,
}: IOverviewTopLeakedKeywordsProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "overview.topLeakedKeywords",
  });

  const groupCount: number = 2;

  const topKeywordGroups = useMemo(() => {
    const groups = [];
    for (let i = 0; i < overviewTopKeywords.length; i = i + groupCount) {
      groups.push(overviewTopKeywords.slice(i, i + groupCount));
    }
    return groups;
  }, [overviewTopKeywords]);

  return (
    <div className="rounded-md bg-bright-grey px-6 py-4 w-full flex flex-col gap-6">
      <h2 className="font-medium-20">{t("title")}</h2>
      {!overviewTopKeywords?.length ? (
        <NoDataState />
      ) : (
        <div className={`grid grid-cols-${groupCount} gap-12`}>
          {topKeywordGroups.map((items, index) => (
            <div key={index} className="flex flex-col gap-6 w-full">
              {items.map((item, itemIndex) => (
                <div
                  key={item.id}
                  className="flex items-center justify-between border-b border-b-solid border-b-grey w-full py-3 last:border-none gap-3"
                >
                  <div className="flex gap-1 items-center flex-1">
                    <p className="font-bold-16 text-dark-blue">
                      {index * groupCount + itemIndex + 1}.
                    </p>
                    <p className="font-medium-16 text-primary truncate">
                      # {item.keyword}
                    </p>
                  </div>
                  <div className="flex items-center justify-end gap-1">
                    <p className="font-bold-16 text-dark-blue">{item.count}</p>
                    <p className="font-regular-16 text-hard-grey">
                      {t("leaks")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OverviewTopLeakedKeywords;
