// Libraries
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/common/system/Button";

// Children
import ControlTable from "#src/components/devices/control/ControlTable";

const DeviceControl = () => {
  const { t } = useTranslation("devices", { keyPrefix: "control" });

  const { workspaceId } = useParams<"workspaceId">();

  const [openAddControlDrawer, setOpenAddControlDrawer] =
    useState<boolean>(false);

  const onOpenAddControlDrawer = () => {
    setOpenAddControlDrawer(true);
  };

  const onCloseAddControlDrawer = () => {
    setOpenAddControlDrawer(false);
  };

  return workspaceId ? (
    <>
      {/* This stacking context has 2 sections, the "header" one should have a higher z-index (1) to make sure every elements on the "content" section are not covering the "header". Note that drawers are in a different context, so it should not be a relevant thing here. */}
      <StickySection>
        <h1>{t("title")}</h1>
        <Button size="large" onClick={onOpenAddControlDrawer}>
          <AddLine />
          {t("button.newControl")}
        </Button>
      </StickySection>
      <ContentSection>
        <ControlTable
          workspaceId={workspaceId}
          openAddControlDrawer={openAddControlDrawer}
          onCloseAddControlDrawer={onCloseAddControlDrawer}
        />
      </ContentSection>
    </>
  ) : null;
};

export default DeviceControl;
