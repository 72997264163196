// Libraries
import DOMPurify from "dompurify";
import { marked } from "marked";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// Resources
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";

// Components
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";

// API-related
import msspPentestServices from "#src/services/mssp/pentest";

interface IMsspPentestDetailsUpdatesProps {
  workspaceId: string;
  pentestAlias: string;
}

const MsspPentestDetailsUpdates = ({
  workspaceId,
  pentestAlias,
}: IMsspPentestDetailsUpdatesProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.updates",
  });

  const fetchParams = useMemo<
    Parameters<typeof msspPentestServices.list_mssp_pentest_project_history>
  >(
    // TODO: load more
    () => [workspaceId, pentestAlias, { page: undefined }],
    [workspaceId, pentestAlias]
  );

  const { list: historyList, isLoading } = useFetchPaginated(
    msspPentestServices.list_mssp_pentest_project_history,
    fetchParams
  );

  return (
    <section className="flex flex-col gap-12 pt-6">
      <div className="flex flex-col gap-4">
        <h2>{t("title")}</h2>
        <span className="text-hard-grey">{t("info")}</span>
      </div>
      <div className="px-7 flex flex-col gap-12">
        {isLoading || historyList === null ? (
          <LoadingState />
        ) : historyList.length === 0 ? (
          <NoResultsState />
        ) : (
          historyList.map((item) => (
            <div key={`history-${item.id}`} className="flex flex-col gap-6">
              <span className="font-medium-18">
                {dayjs.unix(item.created_time).format("MMMM D, YYYY")}
              </span>
              <div
                className="markdown-body pentest-updates-md"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    marked.parse(item.description.en) as string
                    // https://github.com/markedjs/marked/issues/3101 Marked cannot tell Typescript the async false option. Last update 17/01/2024
                  ),
                }}
              ></div>
              <div className="flex items-center gap-1 font-medium-12 text-hard-grey">
                <TimeLine className="h-3.5 w-3.5" />
                {t("updateBy")}
                <span className="text-primary">{item.editor}</span>{" "}
                {dayjs.unix(item.created_time).fromNow()}
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
};

export default MsspPentestDetailsUpdates;
