// Libraries
import {
  type Dispatch,
  forwardRef,
  type SetStateAction,
  useCallback,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

// Resources
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import { pathname } from "#src/config/pathname";
import global from "#src/config/global";

// Components
import { Button } from "#src/common/system/Button";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import bugBountyServices, {
  type IBugBountyDetails,
} from "#src/services/bugBounty";
import { BugBountyStatusResponseValue } from "#src/config/filter/bugbounty/value";

// Children
import AddBugBountyProgramType from "../../AddBugBounty/ProgramType";
import AddBugBountyProgramName from "../../AddBugBounty/ProgramName";
import AddBugBountyDescription from "../../AddBugBounty/Description";
import AddBugBountyRewardRange, {
  type IBugBountyRewardRangeItem,
} from "../../AddBugBounty/RewardRange";
import { BugBountyProgramTypeEnum } from "../../enum";
import AddBugBountyColorAndLogo from "../../AddBugBounty/ColorAndLogo";

export interface IBugBountyProgramBriefStates {
  programType: (typeof BugBountyProgramTypeEnum)[keyof typeof BugBountyProgramTypeEnum];
  briefName: string;
  alias: string;
  description: string;
  currency: "VND" | "USD";
  rewardRange: IBugBountyRewardRangeItem[];
  status: string;
  color: string;
  avatarUrl: string | null;
}

interface IBugBountyDetailsProgramBriefRef {
  onSaveChanges: () => void;
}

interface IBugBountyDetailsProgramBriefProps {
  workspaceId: string;
  bugBountyAlias: string;
  bugBountyDetails: IBugBountyDetails;
  programBriefStates: IBugBountyProgramBriefStates;
  setProgramBriefStates: Dispatch<SetStateAction<IBugBountyProgramBriefStates>>;
  isEditing: boolean;
  onRefresh: () => void;
}

const BugBountyDetailsProgramBrief = forwardRef<
  IBugBountyDetailsProgramBriefRef,
  IBugBountyDetailsProgramBriefProps
>(
  (
    {
      workspaceId,
      bugBountyAlias,
      bugBountyDetails,
      programBriefStates,
      setProgramBriefStates,
      isEditing,
      onRefresh,
    },
    ref
  ) => {
    const { t } = useTranslation("bugBounty", {
      keyPrefix: "page.bugBountyDetails.tab.programBrief",
    });

    const navigate = useNavigate();

    const onChangeProgramType = (
      value: typeof programBriefStates.programType
    ) => {
      setProgramBriefStates((prev) => ({ ...prev, programType: value }));
    };

    const onChangeBriefName = (value: typeof programBriefStates.briefName) => {
      setProgramBriefStates((prev) => ({ ...prev, briefName: value }));
    };

    const onChangeAlias = (value: typeof programBriefStates.alias) => {
      setProgramBriefStates((prev) => ({ ...prev, alias: value }));
    };

    const onChangeDescription = (
      value: typeof programBriefStates.description
    ) => {
      setProgramBriefStates((prev) => ({ ...prev, description: value }));
    };

    const onChangeCurrency = (value: typeof programBriefStates.currency) => {
      setProgramBriefStates((prev) => ({ ...prev, currency: value }));
    };

    const onChangeRewardRangeType = (
      severity: (typeof programBriefStates.rewardRange)[number]["severity"],
      type: (typeof programBriefStates.rewardRange)[number]["type"]
    ) => {
      setProgramBriefStates((prev) => ({
        ...prev,
        rewardRange: prev.rewardRange.map((range) =>
          range.severity === severity
            ? type.value === "point"
              ? { ...range, type, from: 0, to: 0 }
              : { ...range, type }
            : range
        ),
      }));
    };

    const onChangeRewardRangeFrom = (
      severity: (typeof programBriefStates.rewardRange)[number]["severity"],
      from: (typeof programBriefStates.rewardRange)[number]["from"]
    ) => {
      setProgramBriefStates((prev) => ({
        ...prev,
        rewardRange: prev.rewardRange.map((range) =>
          range.severity === severity ? { ...range, from } : range
        ),
      }));
    };

    const onChangeRewardRangeTo = (
      severity: (typeof programBriefStates.rewardRange)[number]["severity"],
      to: (typeof programBriefStates.rewardRange)[number]["to"]
    ) => {
      setProgramBriefStates((prev) => ({
        ...prev,
        rewardRange: prev.rewardRange.map((range) =>
          range.severity === severity ? { ...range, to } : range
        ),
      }));
    };

    const onChangeColor = (value: typeof programBriefStates.color) => {
      setProgramBriefStates((prev) => ({ ...prev, color: value }));
    };

    const onChangeAvatarUrl = (value: typeof programBriefStates.avatarUrl) => {
      setProgramBriefStates((prev) => ({ ...prev, avatarUrl: value }));
    };

    const onSubmitProgram = () => {
      bugBountyServices
        .submit_program(workspaceId, bugBountyAlias)
        .then(() => {
          onRefresh();
          createToast({
            type: "success",
            message: t("notification.submitProgram.success.title"),
            detail: t("notification.submitProgram.success.message"),
          });
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.submitProgram.fail"),
          });
        });
    };

    const onDeleteProgram = () => {
      bugBountyServices
        .delete_program(workspaceId, bugBountyAlias)
        .then(() => {
          navigate({
            pathname: generatePath(pathname.BUG_BOUNTY_PROGRAMS, {
              workspaceId,
            }),
          });
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.deleteProgram.fail"),
          });
        });
    };

    const onSaveChanges = useCallback(() => {
      bugBountyServices
        .update_program(workspaceId, bugBountyAlias, {
          name: programBriefStates.briefName
            ? programBriefStates.briefName
            : "",
          alias: programBriefStates.alias,
          type: bugBountyDetails.type,
          metadata: bugBountyDetails.metadata,
          description: {
            en: programBriefStates.description,
            vi: programBriefStates.description,
          },
          currency: programBriefStates.currency,
          reward: programBriefStates.rewardRange.map((range) => ({
            type: range.type.value ?? "",
            range_from: range.from,
            range_to: range.to,
            severity: range.severity,
            point_value: range.point,
          })),
          color: programBriefStates.color,
          logo: programBriefStates.avatarUrl
            ? programBriefStates.avatarUrl
            : undefined,
        })
        .then((response) => {
          if (response.success) {
            return onRefresh();
          }
        });
    }, [
      workspaceId,
      bugBountyAlias,
      bugBountyDetails,
      programBriefStates,
      onRefresh,
    ]);

    // WARNING: This is using an escape hatch. Not the ideal way to do things.
    useImperativeHandle(ref, () => {
      return {
        onSaveChanges,
      };
    }, [onSaveChanges]);

    return (
      <section className="flex gap-6 pt-6">
        <div className="flex flex-col gap-6 w-2/3">
          <AddBugBountyProgramType
            programType={programBriefStates.programType}
            onChangeProgramType={onChangeProgramType}
            disabled
          />
          <div className="flex flex-col gap-6">
            <AddBugBountyProgramName
              briefName={programBriefStates.briefName}
              alias={programBriefStates.alias}
              onChangeBriefName={onChangeBriefName}
              onChangeAlias={onChangeAlias}
              disabled={!isEditing}
            />
            <AddBugBountyDescription
              description={programBriefStates.description}
              onChangeDescription={onChangeDescription}
              disabled={!isEditing}
            />
          </div>
          <AddBugBountyRewardRange
            status={programBriefStates.status}
            currency={programBriefStates.currency}
            rewardRange={programBriefStates.rewardRange}
            onChangeCurrency={onChangeCurrency}
            onChangeRewardRangeType={onChangeRewardRangeType}
            onChangeRewardRangeFrom={onChangeRewardRangeFrom}
            onChangeRewardRangeTo={onChangeRewardRangeTo}
            disabled={!isEditing}
          />
        </div>
        <div className="w-1/3 flex flex-col gap-6">
          <AddBugBountyColorAndLogo
            programAlias={programBriefStates.alias}
            programName={programBriefStates.briefName}
            description={programBriefStates.description}
            color={programBriefStates.color}
            avatarUrl={programBriefStates.avatarUrl}
            onChangeColor={onChangeColor}
            onChangeAvatarUrl={onChangeAvatarUrl}
            disabled={!isEditing}
          />
          {/* <div className="border border-grey rounded-md p-6 flex flex-col gap-3">
            <h2>{t("previewLink.title")}</h2>
            <span className="text-primary flex gap-2">
              <LinkIcon height={"1.25rem"} width={"1.25rem"} />
              <a
                href={`https://whitehub.net/programs/${bugBountyAlias}`}
                target="_blank"
                rel="noreferrer"
              >
                {t("previewLink.link")}
              </a>
            </span>
          </div> */}
          {programBriefStates.status === BugBountyStatusResponseValue.DRAFT ? (
            <div className="border border-grey rounded-md p-6 flex flex-col gap-6">
              <div className="flex flex-col gap-4">
                <h2>{t("demoMode.title")}</h2>
                <span className="text-hard-grey">
                  {t("demoMode.submitYourProgram")}
                </span>
              </div>
              <div className="flex flex-wrap gap-3">
                <Button
                  variant="secondary"
                  size="large"
                  className="flex-1"
                  onClick={onSubmitProgram}
                >
                  <ArrowRightUpLine />
                  {t("demoMode.button.submit")}
                </Button>
                <Button
                  variant="warning"
                  size="large"
                  className="flex-1"
                  onClick={() => {
                    global.confirm(onDeleteProgram);
                  }}
                >
                  <DeleteBin6Line />
                  {t("demoMode.button.delete")}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    );
  }
);

BugBountyDetailsProgramBrief.displayName = "BugBountyDetailsProgramBrief";

export default BugBountyDetailsProgramBrief;
