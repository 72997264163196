// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ExportLine } from "#src/assets/images/icons/export-line.svg";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import LoadingState from "#src/common/system/LoadingState";

// API-related
import paymentService from "#src/services/payment";
import { PaymentMethodValue } from "#src/config/filter/workspace/value";

// Children
import PaymentConfirmModal from "./ConfirmModal";

interface IPaymentCompletePaymentProps {
  workspaceId: string;
  paymentInfo: Awaited<
    ReturnType<typeof paymentService.create_subscription_banking>
  > | null;
  settingPath: string;
  paymentType?: string;
}

/**
 * @param settingPath - a path for success case, since after a payment succeeded, we have to redirect user back to the "home page" of the product.
 */
const PaymentCompletePayment = ({
  workspaceId,
  paymentInfo,
  settingPath,
  paymentType,
}: IPaymentCompletePaymentProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.payment",
  });

  const [openPaymentConfirm, setOpenPaymentConfirm] = useState<boolean>(false);

  return paymentInfo ? (
    paymentInfo.payment_method === PaymentMethodValue.BANKING ? (
      <div className="flex flex-col gap-6">
        <h2 className="text-center">{t("banking.title")}</h2>
        <div className="grid grid-cols-[2fr_3fr] gap-6">
          {/* TODO: make prettier background */}
          <div className="rounded-xl bg-primary p-6">
            <div className="w-full h-full p-10 rounded-xl bg-white flex flex-col items-center gap-6">
              <div className="flex flex-col gap-3 text-center">
                <span className="font-medium-18">
                  {t("banking.qrCode.scanThis")}
                </span>
                <span className="font-bold-24">CYSTACK JSC</span>
                <span className="font-bold-24">888310000</span>
              </div>
              <img src={paymentInfo.bank_qr_url} className="h-80 w-80" />
              <span className="font-bold-28 text-primary">
                {paymentInfo.total_price.toLocaleString()}{" "}
                {paymentInfo.currency}
              </span>
              <IconButton variant="secondary">
                <ExportLine className="h-5 w-5" />
              </IconButton>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="px-20 py-12 grid grid-cols-[2fr_3fr] gap-8 h-fit">
              <div className="grid grid-cols-subgrid col-span-full">
                <span className="font-medium-18">
                  {t("banking.accountInfo.accountNumber")}
                </span>
                <span className="font-bold-18">888310000</span>
              </div>
              <div className="grid grid-cols-subgrid col-span-full">
                <span className="font-medium-18">
                  {t("banking.accountInfo.accountName")}
                </span>
                <span className="font-bold-18">CYSTACK JSC</span>
              </div>
              <div className="grid grid-cols-subgrid col-span-full">
                <span className="font-medium-18">
                  {t("banking.accountInfo.bank")}
                </span>
                <div className="flex flex-col gap-1.5">
                  <span className="font-bold-18">MB Bank</span>
                  <span className="font-medium-18">Ngân hàng Quân Đội</span>
                </div>
              </div>
              <div className="grid grid-cols-subgrid col-span-full">
                <span className="font-medium-18">
                  {t("banking.accountInfo.total")}
                </span>
                <span className="font-bold-28 text-primary">
                  {paymentInfo.total_price.toLocaleString()}{" "}
                  {paymentInfo.currency}
                </span>
              </div>
              <div className="grid grid-cols-subgrid col-span-full">
                <span className="font-medium-18">
                  {t("banking.accountInfo.transferMessage")}
                </span>
                <span className="font-bold-18">{paymentInfo.payment_id}</span>
              </div>
            </div>
            <div className="bg-bright-grey-subline rounded-md p-6 flex flex-col gap-6">
              <div className="font-regular-16-body text-hard-grey whitespace-pre-line">
                <b className="text-dark-blue">{t("banking.note.note")}</b>
                <br />
                {t("banking.note.pleaseWrite")}
                <b className="text-dark-blue">{paymentInfo.payment_id}</b>
                {t("banking.note.whenTransfer")}
                <br />
                {t("banking.note.afterTransferClick")}
                <b className="text-dark-blue">
                  {t("banking.note.button.confirmTransfer")}
                </b>
                {t("banking.note.toConfirm")}
              </div>
              <div className="flex justify-center">
                <Button
                  size="large"
                  className="w-80"
                  onClick={() => {
                    setOpenPaymentConfirm(true);
                  }}
                >
                  {t("banking.note.button.confirmTransfer")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* TODO: this modal is common for both banking and card payment. */}
        <PaymentConfirmModal
          workspaceId={workspaceId}
          open={openPaymentConfirm}
          onChangeOpen={(value) => {
            setOpenPaymentConfirm(value);
          }}
          paymentInfo={paymentInfo}
          settingPath={settingPath}
          paymentType={paymentType}
        />
      </div>
    ) : (
      // TODO: card transferring
      <div></div>
    )
  ) : (
    <LoadingState />
  );
};

export default PaymentCompletePayment;
