// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// General
import type { IFilterItem } from "#src/@types/common";
// Components
import TimePopover from "#src/common/TimePopover";
import type { TimeConditionEnum } from "#src/common/TimePopover/TimePopoverContent";
import { FilterDropdown, SearchBox } from "#src/common/Table/Filter";

// Children
import { programTypeItems } from "#src/config/filterConstants";
import { bugBountyStatusItems } from "#src/config/filter/bugbounty";

interface IMsspBugBountyFilterProps {
  programStatus: IFilterItem | null;
  programType: IFilterItem | null;
  searchKeyword: string;
  onChangeTimeConditionValue: (
    condition: string,
    from: number,
    to: number
  ) => void;
  onChangeProgramStatusSelection: (selected: IFilterItem | null) => void;
  onChangeProgramTypeSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
}

const MsspBugBountyFilter = ({
  programStatus,
  programType,
  searchKeyword,
  onChangeTimeConditionValue,
  onChangeProgramStatusSelection,
  onChangeProgramTypeSelection,
  onChangeSearchKeyword,
}: IMsspBugBountyFilterProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyList.filter",
  });

  const [timeCondition, setTimeCondition] = useState<TimeConditionEnum | null>(
    null
  );
  const [timePopoverOpen, setTimePopoverOpen] = useState<boolean>(false);
  const [timeFilterText, setTimeFilterText] = useState<string>("");

  const onChangeTimeCondition = (
    text: string,
    condition: TimeConditionEnum,
    from: number,
    to: number
  ) => {
    setTimeCondition(condition);
    setTimeFilterText(text);
    onChangeTimeConditionValue(text, from, to);
  };

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 whitespace-nowrap flex-wrap">
        <TimePopover
          label={t("timePopover.label")}
          timeCondition={timeCondition}
          textDisplay={timeFilterText}
          open={timePopoverOpen}
          setOpen={(value) => setTimePopoverOpen(value)}
          onChangeTime={onChangeTimeCondition}
        />
        <FilterDropdown
          label={t("status.label")}
          selected={programStatus}
          options={bugBountyStatusItems}
          onChangeSelection={onChangeProgramStatusSelection}
        />
        <FilterDropdown
          label={t("type.label")}
          selected={programType}
          options={programTypeItems}
          onChangeSelection={onChangeProgramTypeSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default MsspBugBountyFilter;
