import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useLayoutEffect, useRef, useState } from "react";
import { IEndpointDevice } from "#src/services/endpoint/devices/types";
import { useTranslation } from "react-i18next";

// Config marker icon
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;

type Props = {
  details: IEndpointDevice;
};

export const LocationSection = (props: Props) => {
  const { details } = props;
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- DATA ---------------

  const [mapHeight, setMapHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  // --------------- COMPUTED ---------------

  const isEmtpy = !details?.location?.latitude && !details?.location?.longitude;

  // --------------- EFFECTS ---------------

  useLayoutEffect(() => {
    if (containerRef.current && headerRef.current && !mapHeight) {
      setMapHeight(
        Math.max(
          containerRef.current.clientHeight - headerRef.current.clientHeight,
          400
        )
      );
    }
  }, [mapHeight]);

  // --------------- RENDER ---------------

  return (
    <section
      ref={containerRef}
      className="w-full xl:w-6/12 border border-gray-v2-200 shadow-xs rounded-xl"
    >
      <div ref={headerRef} className="p-4">
        <h3 className="text-lg font-semibold text-gray-v2-900 normal-case mb-0">
          {t("overview.location.title")}
        </h3>
      </div>

      <div className="rounded-xl overflow-hidden">
        {isEmtpy && (
          <div
            style={{ height: mapHeight }}
            className="flex items-center justify-center"
          >
            <p className="text-sm text-gray-v2-400">{t("common.no_data")}</p>
          </div>
        )}

        {!isEmtpy && !!mapHeight && (
          <MapContainer
            center={[
              details?.location?.latitude!,
              details?.location?.longitude!,
            ]}
            zoom={10}
            scrollWheelZoom={false}
            style={{ height: mapHeight, width: "100%", zIndex: 0 }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              position={[
                details?.location?.latitude!,
                details?.location?.longitude!,
              ]}
            >
              <Popup>
                <p className="text-sm text-gray-v2-900">
                  {details?.location?.city_country}
                </p>
              </Popup>
            </Marker>
          </MapContainer>
        )}
      </div>
    </section>
  );
};
