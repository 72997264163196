// Libraries
import { useMemo, useState } from "react";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";

// API-related
import msspPentestServices from "#src/services/mssp/pentest";

// Children
import MsspPentestFilter from "./Filter";
import MsspPentestTableGrid from "./Table";

interface IMsspPentestListProps {
  workspaceId: string;
}

const MsspPentestList = ({ workspaceId }: IMsspPentestListProps) => {
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);
  const [pentestStatus, setPentestStatus] = useState<IFilterItem | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const fetchParams = useMemo<
    Parameters<typeof msspPentestServices.list_mssp_pentest_projects>
  >(
    () => [
      workspaceId,
      {
        from: from ? from : undefined,
        to: to ? to : undefined,
        status: pentestStatus ? pentestStatus.value : undefined,
        q: searchKeyword ? searchKeyword : undefined,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      from,
      to,
      pentestStatus,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangeTimeConditionValue = (
    condition: string,
    from: number,
    to: number
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
    setSelectedPage(1);
  };

  const onChangePentestStatusSelection = (selected: typeof pentestStatus) => {
    setPentestStatus(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const {
    list: pentestList,
    count: pentestCount,
    isLoading,
    updateData: updatePentestList,
  } = useFetchPaginated(
    msspPentestServices.list_mssp_pentest_projects,
    fetchParams
  );

  return (
    <>
      <MsspPentestFilter
        pentestStatus={pentestStatus}
        searchKeyword={searchKeyword}
        onChangeTimeConditionValue={onChangeTimeConditionValue}
        onChangePentestStatusSelection={onChangePentestStatusSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <MsspPentestTableGrid
        workspaceId={workspaceId}
        pentestList={pentestList}
        isLoading={isLoading}
        refreshProjectList={updatePentestList}
      />
      <PaginationBar
        numOfResult={pentestCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </>
  );
};

export default MsspPentestList;
