import { type RefObject, useEffect } from "react";

/**
 *
 * @param refs - refs of components that won't trigger the callback on click.
 * @param callback - the function that will be called on click outside.
 */
export const useClickOutside = (
  refs: RefObject<HTMLElement>[],
  callback: Function
) => {
  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (
        refs.every(
          (ref) => !ref.current || !ref.current.contains(e.target as Node)
        )
      ) {
        callback();
      }
    }

    addEventListener("mousedown", handleClickOutside);

    return () => {
      removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs, callback]);
};
