import useWebSocket from "react-use-websocket";
import { useParams } from "react-router-dom";
import authServices from "#src/services/auth";
import endpoint from "#src/config/endpoint";
import {
  IDLPDrive,
  IDriveBackupStatusSocketMessage,
} from "#src/services/endpoint/dlp/types";
import { SetStateAction, useEffect, useCallback } from "react";
import {
  DLPDriveBackupStatus,
  DLPWebSocketEvent,
} from "#src/services/endpoint/dlp/enums";
import { createToast } from "#src/common/system/toasts";
import { useTranslation } from "react-i18next";

type Props = {
  setIsSnapshotsModalOpen: (value: SetStateAction<boolean>) => void;
  setDriveList: (value: SetStateAction<IDLPDrive[]>) => void;
  setSelectedDrive: (value: SetStateAction<IDLPDrive>) => void;
  selectedDrive?: IDLPDrive;
};

export const useWorkspaceWebSocket = (props: Props) => {
  const {
    setIsSnapshotsModalOpen,
    setDriveList,
    setSelectedDrive,
    selectedDrive,
  } = props;

  const { t } = useTranslation("dlp");

  const { workspaceId } = useParams<"workspaceId">();
  const accessToken = authServices.access_token() || "";

  // ------------------- HOOKS -------------------

  const { lastJsonMessage } = useWebSocket<IDriveBackupStatusSocketMessage>(
    `${endpoint.WEBSOCKET}/workspaces/${workspaceId}?token=${accessToken}`
  );

  // ------------------ METHODS ------------------

  // Change drives backup status when receive event from websocket
  const onDrivesBackupStatusChanged = useCallback(
    (payload: IDriveBackupStatusSocketMessage) => {
      // Update all matching items in list and display status toast
      setDriveList((prev) =>
        prev.map((item) => {
          const matchingId = payload.host_ids.find((id) => id === item.id);
          const newStatus = payload.backup_status;
          const oldStatus = item.backup_status;

          // Keep the orignal item if id does not match or status does not change
          if (!matchingId || newStatus === oldStatus) {
            return item;
          }

          // If request restore is accepted
          if (
            oldStatus === DLPDriveBackupStatus.PENDING_RESTORE &&
            newStatus === DLPDriveBackupStatus.RESTORING
          ) {
            createToast({
              type: "success",
              message: t("snapshotsModal.requestSuccess.title"),
              detail: t("snapshotsModal.requestSuccess.desc"),
            });

            // Dont allow restoring drive to manage snapshots -> close opening modal if selected drive is match
            if (selectedDrive?.id === item.id) {
              setIsSnapshotsModalOpen(false);
            }
          }

          // If request restore is rejected
          if (
            oldStatus === DLPDriveBackupStatus.PENDING_RESTORE &&
            newStatus === DLPDriveBackupStatus.IDLE
          ) {
            createToast({
              type: "error",
              message: t("snapshotsModal.requestFail.title"),
              detail: t("snapshotsModal.requestFail.desc"),
            });
          }

          // If restore is complete
          if (
            oldStatus === DLPDriveBackupStatus.RESTORING &&
            newStatus === DLPDriveBackupStatus.IDLE
          ) {
            createToast({
              type: "success",
              message: t("snapshotsModal.restoreDone.title"),
              detail: t("snapshotsModal.restoreDone.desc"),
            });
          }

          // Update the item with new status
          return {
            ...item,
            backup_status: newStatus,
          };
        })
      );

      // Check if selected drive need to update
      if (selectedDrive) {
        const newUpdate = payload.host_ids.find(
          (id) => id === selectedDrive.id
        );
        if (
          newUpdate &&
          payload.backup_status !== selectedDrive.backup_status
        ) {
          setSelectedDrive({
            ...selectedDrive,
            backup_status: payload.backup_status,
          });
        }
      }
    },
    [selectedDrive, setDriveList, setIsSnapshotsModalOpen, setSelectedDrive, t]
  );

  // ------------------ EFFECTS ------------------

  useEffect(() => {
    // TODO: remove later
    console.log("lastJsonMessage", lastJsonMessage);

    if (!lastJsonMessage) {
      return;
    }
    if (lastJsonMessage.command === DLPWebSocketEvent.BACKUP_STATUS_CHANGED) {
      onDrivesBackupStatusChanged(lastJsonMessage);
    }
  }, [lastJsonMessage, onDrivesBackupStatusChanged]);
};
