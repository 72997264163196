// Libraries
import { Dropdown } from "@lockerpm/design";
import { useState, type ReactNode, useLayoutEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

// Resources
import { ReactComponent as DeleteBack2Line } from "#src/assets/images/icons/delete-back-2-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as PlusIcon } from "#src/assets/images/icons/plus.svg";
import { ReactComponent as Search2Line } from "#src/assets/images/icons/search-2-line.svg";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";

const Searchbox = ({
  keyword,
  onChangeKeyword,
}: {
  keyword: string;
  onChangeKeyword: (keyword: string) => void;
}) => {
  return (
    <div className="flex items-center justify-between w-full p-3 gap-3">
      <input
        className="outline-none caret-primary text-hard-grey bg-transparent p-3 w-40"
        autoFocus
        value={keyword}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          onChangeKeyword(e.currentTarget.value);
        }}
      />
      <button className="w-4 h-4 flex items-center">
        <Search2Line className="fill-hard-grey w-4 h-4" />
      </button>
    </div>
  );
};

const DropdownComponent = ({
  open,
  onOpenChange,
  selected,
  options,
  onChangeSelection,
  children,
}: Pick<IFilterDropdownProps, "selected" | "options" | "onChangeSelection"> & {
  children: ReactNode;
  open: boolean;
  onOpenChange: (currentOpen: boolean) => void;
}) => {
  return (
    <Dropdown
      open={open}
      onOpenChange={onOpenChange}
      menu={{
        items: options.map((item) => ({
          key: item.key,
          value: item.value,
          label: (
            <DropdownItemLabel selected={selected?.key === item.key}>
              {item.getLabel()}
            </DropdownItemLabel>
          ),
        })),
        onClick: (obj) => {
          const newSelected = options.find((item) => item.key === obj.key);
          onChangeSelection(newSelected ? newSelected : null);
          onOpenChange(false);
        },
      }}
      placement="bottomRight"
      arrow
      trigger={["click"]}
    >
      {children}
    </Dropdown>
  );
};

interface IFilterDropdownProps {
  label: string;
  selected: IFilterItem | null;
  options: IFilterItem[];
  onChangeSelection: (selected: IFilterItem | null) => void;
  loadedPages: number;
  totalCount: number;
  fetchMoreItems: () => void;
  isLoadingMore: boolean;
  setLoading: (value: boolean) => void;
  keyword: string;
  onChangeKeyword: (keyword: string) => void;
}

const FilterDropdownSearch = ({
  label,
  selected,
  options,
  onChangeSelection,
  loadedPages,
  totalCount,
  fetchMoreItems,
  isLoadingMore,
  setLoading,
  keyword,
  onChangeKeyword,
}: IFilterDropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const onScrollEvent = useDebouncedCallback((e: Event) => {
    const target = e.target as HTMLUListElement;

    if (
      target &&
      target.clientHeight + target.scrollTop > target.scrollHeight - 10 &&
      !isLoadingMore &&
      totalCount &&
      totalCount > loadedPages * constants.DEFAULT_PAGE_SIZE
    ) {
      setLoading(true);
      fetchMoreItems();
    }
  });

  const onOpenChange = (currentOpen: boolean) => {
    setOpen(currentOpen);
    if (currentOpen === false && keyword !== "") onChangeKeyword("");
  };

  // WARNING: This is a bad way to do onScroll event, but we don't have any choices since AntD Dropdown doesn't expose onScroll event and the component did not even show up until the open state has changed AND React has rerendered once more.
  // --- This would definitely hurt performance ---
  useLayoutEffect(() => {
    const menuList = document.getElementsByClassName("ant-dropdown-menu");
    if (menuList.length > 0) {
      const menu = menuList.item(0);

      if (open) {
        if (menu !== null) {
          menu.addEventListener("scroll", onScrollEvent);
        }
      } else {
        if (menu !== null) {
          menu.removeEventListener("scroll", onScrollEvent);
        }
      }
    }
  }, [open, onScrollEvent]);

  return selected ? (
    <div className="border border-light-grey text-hard-grey rounded-md flex items-center h-11">
      <button
        className="ml-3 h-4 w-4 text-inherit"
        onClick={() => onChangeSelection(null)}
      >
        <DeleteBack2Line width={"1rem"} height={"1rem"} />
      </button>
      <span className="px-3 border-r border-light-grey whitespace-nowrap">
        {label}
      </span>
      <DropdownComponent
        open={open}
        onOpenChange={onOpenChange}
        selected={selected}
        options={options}
        onChangeSelection={onChangeSelection}
      >
        {open ? (
          <Searchbox keyword={keyword} onChangeKeyword={onChangeKeyword} />
        ) : (
          <button className="text-primary font-medium-16 p-3 flex gap-2 items-center justify-between w-full">
            <span className="whitespace-nowrap max-w-[16rem] text-ellipsis overflow-hidden">
              {selected.getLabel ? selected.getLabel() : null}
            </span>
            <ArrowDownSLine
              width={"1rem"}
              height={"1rem"}
              className="fill-hard-grey"
            />
          </button>
        )}
      </DropdownComponent>
    </div>
  ) : (
    <DropdownComponent
      open={open}
      onOpenChange={onOpenChange}
      selected={selected}
      options={options}
      onChangeSelection={onChangeSelection}
    >
      {open ? (
        <div className="border border-light-grey text-hard-grey rounded-md flex items-center h-11">
          <button
            className="ml-3 h-4 w-4 text-inherit"
            onClick={() => onChangeSelection(null)}
          >
            <DeleteBack2Line width={"1rem"} height={"1rem"} />
          </button>
          <span className="px-3 border-r border-light-grey whitespace-nowrap">
            {label}
          </span>
          <Searchbox keyword={keyword} onChangeKeyword={onChangeKeyword} />
        </div>
      ) : (
        <button
          className="border border-dashed border-light-grey text-hard-grey rounded-md flex gap-3 p-3 items-center h-11"
          onClick={() => {
            setOpen(true);
          }}
        >
          <PlusIcon width={"1rem"} height={"1rem"} />
          <span className="whitespace-nowrap">{label}</span>
        </button>
      )}
    </DropdownComponent>
  );
};

export default FilterDropdownSearch;
