// Libraries
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";
import { type RefObject, useState } from "react";

// Resources
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";
import { ReactComponent as GiftLine } from "#src/assets/images/icons/gift-line.svg";
import { ReactComponent as ArchiveLine } from "#src/assets/images/icons/archive-line.svg";

// General
import constants from "#src/config/constants";

// Components
import vulnerabilitiesServices, {
  type IVulnerabilityItem,
} from "#src/services/vulnerabilities";
import { getTwColorByStatus } from "#src/common/variants/byStatus";
import { SearchBox } from "#src/common/Table/Filter";
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// Children
import { autoScanReporter, dataLeakReporter } from "./utils";

dayjs.extend(relativeTime);

export const twMarkerBySeverity: {
  [key in string]: string;
} = {
  CRITICAL:
    "bg-severity-critical border-severity-critical text-severity-critical",
  HIGH: "bg-severity-high border-severity-high text-severity-high",
  MEDIUM: "bg-severity-medium border-severity-medium text-severity-medium",
  LOW: "bg-severity-low border-severity-low text-severity-low",
  INFORMATION:
    "bg-severity-information border-severity-information text-severity-information",
  NONE: "bg-severity-none border-severity-none text-severity-none",
};

interface IVulnarbilityCardProps {
  data: IVulnerabilityItem;
  workspaceId: string;
  selectedVulnId: number | null;
  onSelectVuln: (vulnId: number) => void;
}

const VulnerabilityCard = ({
  data,
  workspaceId,
  selectedVulnId,
  onSelectVuln,
}: IVulnarbilityCardProps) => {
  const { t } = useTranslation("vulnerabilities", { keyPrefix: "list" });
  const { t: tCommon } = useTranslation("translation");

  const [showArchiveButton, setShowArchiveButton] = useState<boolean>(false);

  const textByStatus: { [S in string]: string } = {
    new: tCommon("filter.vulnerabilityStatus.new"),
    triaged: tCommon("filter.vulnerabilityStatus.triaged"),
    resolved: tCommon("filter.vulnerabilityStatus.resolved"),
    unresolved: tCommon("filter.vulnerabilityStatus.unresolved"),
    duplicate: tCommon("filter.vulnerabilityStatus.duplicate"),
    out_of_scope: tCommon("filter.vulnerabilityStatus.outOfScope"),
    not_reproducible: tCommon("filter.vulnerabilityStatus.notReproducible"),
    wont_fix: tCommon("filter.vulnerabilityStatus.wontFix"),
    not_applicable: tCommon("filter.vulnerabilityStatus.notApplicable"),
    spam: tCommon("filter.vulnerabilityStatus.spam"),
  };

  const onArchiveVulnerability = (
    workspaceId: string,
    vulnerabilityId: number
  ) => {
    // TODO: add confirm modal
    vulnerabilitiesServices
      .archive_vulnerability(workspaceId, vulnerabilityId)
      .then(() => {
        createToast({
          type: "success",
          message: t("notification.archive.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.archive.fail"),
        });
      });
  };

  return (
    <div
      className={`flex relative rounded-md ${
        selectedVulnId === data.id
          ? "bg-label-blue"
          : "bg-white hover:bg-light-grey"
      } ease-in duration-150 hover:cursor-pointer`}
      onClick={() => {
        onSelectVuln(data.id);
      }}
      onMouseEnter={() => {
        setShowArchiveButton(true);
      }}
      onMouseLeave={() => {
        setShowArchiveButton(false);
      }}
    >
      <div
        className={`absolute left-0 top-1/2 -translate-y-1/2 h-[3.5rem] min-w-[5px] rounded-e-full ${
          twMarkerBySeverity[data.severity.toUpperCase()]
        }`}
      />
      <div className="w-full flex flex-col gap-1.5 px-4 py-2 text-hard-grey">
        <div className="flex justify-between">
          <div className="flex items-center gap-1 font-regular-12">
            <TimeLine className="h-3.5 w-3.5" />
            {dayjs.unix(data.created_time).fromNow()}
          </div>
          <div className="flex items-center gap-1 font-regular-14">
            <div
              className={`h-2 w-2 rounded-full ${getTwColorByStatus(
                data.sub_status.toLowerCase()
              )}`}
            />
            {textByStatus[data.sub_status]}
          </div>
        </div>
        <div className="flex gap-1">
          <span className="font-regular-14 text-hard-grey">#{data.id}</span>
          <div className="font-medium-14 text-dark-blue overflow-hidden whitespace-nowrap text-ellipsis">
            {data.title}
          </div>
        </div>
        <div className="flex justify-between h-5">
          <p className="font-regular-14">
            {/* TODO: fix this. reporter name should be colored as primary (and maybe it should be a link?) */}
            {t("card.by", { reporter: data.reporter?.full_name })}
          </p>
          {data.is_rewarded ? <GiftLine className="fill-primary" /> : null}
        </div>
      </div>
      <button
        className={`absolute ${
          showArchiveButton ? "opacity-100" : "opacity-0"
        } bottom-1 right-1 w-9 h-9 bg-medium-grey hover:bg-primary duration-300 ease-out flex items-center justify-center rounded-full`}
        onClick={() => onArchiveVulnerability(workspaceId, data.id)}
      >
        <ArchiveLine className="fill-white" />
      </button>
    </div>
  );
};

const aggregateReporter = (vulnList: IVulnerabilityItem[]) => {
  return vulnList.map((item) => {
    if (item.vuln_source === "auto_scan") {
      return {
        ...item,
        reporter: autoScanReporter,
      };
    }
    if (item.vuln_source === "data_leak") {
      return {
        ...item,
        reporter: dataLeakReporter,
      };
    }
    return item;
  });
};

interface IVulnerabilityListProps {
  workspaceId: string;
  containerRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
  vulnerabilities: IVulnerabilityItem[] | null;
  loadedPages: number;
  totalVulnCount: number | undefined;
  selectedVulnId: number | null;
  onSelectVuln: (vulnId: number) => void;
  searchKeyword: string;
  onChangeSearchKeyword: (newKeyword: string) => void;
  loadMoreVulnerabilityList: (callback: () => void) => void;
}

const VulnerabilityList = ({
  workspaceId,
  containerRef,
  isLoading,
  vulnerabilities,
  loadedPages,
  totalVulnCount,
  selectedVulnId,
  onSelectVuln,
  searchKeyword,
  onChangeSearchKeyword,
  loadMoreVulnerabilityList,
}: IVulnerabilityListProps) => {
  const [loadingMoreVulns, setLoadingMoreVulns] = useState<boolean>(false);

  // Calculate the position of this element, so that we can fix its height by screen size
  const calculatedTop: number = containerRef.current
    ? containerRef.current?.getBoundingClientRect().top +
      parseFloat(window.getComputedStyle(containerRef.current).paddingTop)
    : // 180 is just a fallback value that looks close to the actual calculated value
      180;

  return (
    <div
      style={{
        height: `calc(100vh - ${calculatedTop}px)`,
      }}
      className="w-1/4 max-w-[24rem] pb-1.5 flex flex-col gap-3"
    >
      <div className="pr-2">
        <SearchBox
          searchKeyword={searchKeyword}
          onChangeSearchKeyword={onChangeSearchKeyword}
          stretchWidth
        />
      </div>
      {vulnerabilities === null || isLoading ? (
        <LoadingState />
      ) : totalVulnCount === 0 ? (
        <NoResultsState />
      ) : (
        <>
          <div
            className="flex flex-col gap-1.5 pb-3 overflow-auto pr-2"
            onScroll={(e) => {
              if (
                e.currentTarget.clientHeight + e.currentTarget.scrollTop >
                  e.currentTarget.scrollHeight - 100 &&
                !loadingMoreVulns &&
                totalVulnCount &&
                totalVulnCount > loadedPages * constants.DEFAULT_PAGE_SIZE
              ) {
                setLoadingMoreVulns(true);
                loadMoreVulnerabilityList(() => {
                  setLoadingMoreVulns(false);
                });
              }
            }}
          >
            {aggregateReporter(vulnerabilities).map((item) => (
              <VulnerabilityCard
                key={`vulnerability-list_card_${item.id}`}
                workspaceId={workspaceId}
                data={item}
                selectedVulnId={selectedVulnId}
                onSelectVuln={onSelectVuln}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default VulnerabilityList;
