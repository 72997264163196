import type { ReactNode } from "react";

const PricingTableRowCellWrapper = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="p-6 text-hard-grey flex items-center justify-center text-center">
      {children}
    </div>
  );
};

const PricingTableRowFirstCellWrapper = ({
  children,
}: {
  children?: ReactNode;
}) => {
  return <div className="p-6 font-bold-16 flex items-center">{children}</div>;
};

const PricingTableRow = ({
  rowTitle,
  content,
}: {
  rowTitle: string;
  content: ReactNode[];
}) => {
  return (
    <div className="grid grid-cols-subgrid col-span-full even:bg-bright-grey">
      <PricingTableRowFirstCellWrapper>
        {rowTitle}
      </PricingTableRowFirstCellWrapper>
      {content.map((item, index) => (
        <PricingTableRowCellWrapper
          key={`pricingTable-row${rowTitle}-${index}`}
        >
          {item}
        </PricingTableRowCellWrapper>
      ))}
    </div>
  );
};

export default PricingTableRow;
