import i18next from "i18next";

const formTFunc = i18next.getFixedT(
  null,
  "trustCenter",
  "onboard.contactModal"
);

export interface ContactFormSelection {
  key: string;
  value: string;
  getLabel: () => string;
}

export const pronounList: ContactFormSelection[] = [
  { key: "mr", value: "mr", getLabel: () => formTFunc("pronoun.selection.mr") },
  { key: "ms", value: "ms", getLabel: () => formTFunc("pronoun.selection.ms") },
];

export const jobTitleList: ContactFormSelection[] = [
  {
    key: "ceo",
    value: "ceo",
    getLabel: () => formTFunc("position.selection.ceo"),
  },
  {
    key: "director",
    value: "director",
    getLabel: () => formTFunc("position.selection.director"),
  },
  {
    key: "manager",
    value: "manager",
    getLabel: () => formTFunc("position.selection.manager"),
  },
  {
    key: "staff",
    value: "staff",
    getLabel: () => formTFunc("position.selection.staff"),
  },
  {
    key: "other",
    value: "other",
    getLabel: () => formTFunc("position.selection.other"),
  },
];

export const employeeSizeList: ContactFormSelection[] = [
  {
    key: "1to15",
    value: "<15",
    getLabel: () => formTFunc("employeeSize.selection.1to15"),
  },
  {
    key: "16to30",
    value: "<30",
    getLabel: () => formTFunc("employeeSize.selection.16to30"),
  },
  {
    key: "31to60",
    value: "<60",
    getLabel: () => formTFunc("employeeSize.selection.31to60"),
  },
  {
    key: "61to100",
    value: "<100",
    getLabel: () => formTFunc("employeeSize.selection.61to100"),
  },
  {
    key: "101to250",
    value: "<250",
    getLabel: () => formTFunc("employeeSize.selection.101to250"),
  },
  {
    key: "251to500",
    value: "<500",
    getLabel: () => formTFunc("employeeSize.selection.251to500"),
  },
  {
    key: "501to1000",
    value: "<1000",
    getLabel: () => formTFunc("employeeSize.selection.501to1000"),
  },
  {
    key: "moreThan1000",
    value: ">1000",
    getLabel: () => formTFunc("employeeSize.selection.moreThan1000"),
  },
];
