// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import global from "#src/config/global";
import type { IFilterItem } from "#src/@types/common";

// Components
import { IconButton } from "#src/common/system/Button";
import {
  FilterDropdown,
  SearchBox,
  SelectAllItemsDropdown,
} from "#src/common/Table/Filter";

// Children
import { memberRoleItems } from "#src/config/filter/workspace";

interface IMemberFilterProps {
  totalCount: number;
  selectedCount: number;
  memberRole: IFilterItem | null;
  searchKeyword: string;
  onChangeMemberRoleSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
  onSelectAllMembers: () => void;
  onDeselectAllMembers: () => void;
  onDeleteSelectedMembers: () => void;
}

const MemberFilter = ({
  totalCount,
  selectedCount,
  memberRole,
  searchKeyword,
  onChangeMemberRoleSelection,
  onChangeSearchKeyword,
  onSelectAllMembers,
  onDeselectAllMembers,
  onDeleteSelectedMembers,
}: IMemberFilterProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "member.filter",
  });
  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 whitespace-nowrap flex-wrap">
        <SelectAllItemsDropdown
          selectedCount={selectedCount}
          totalCount={totalCount}
          onSelectAll={onSelectAllMembers}
          onDeselectAll={onDeselectAllMembers}
        />
        <IconButton
          variant="ghost"
          disabled={selectedCount === 0}
          onClick={() => {
            global.confirm(() => onDeleteSelectedMembers());
          }}
        >
          <DeleteBin6Line />
        </IconButton>
        <FilterDropdown
          label={t("role.label")}
          selected={memberRole}
          options={memberRoleItems}
          onChangeSelection={onChangeMemberRoleSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default MemberFilter;
