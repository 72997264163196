// Libraries
import i18next from "i18next";
import { useState } from "react";
import { matchPath, useMatches } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@lockerpm/design";

// Resources
// import { ReactComponent as QuestionLine } from "#src/assets/images/icons/question-line.svg";
import { ReactComponent as Notification2Line } from "#src/assets/images/icons/notification-2-line.svg";
import { ReactComponent as LogOutBoxLine } from "#src/assets/images/icons/logout-box-line.svg";

// General
import urls from "#src/config/urls";

// API-related
import authServices from "#src/services/auth";

// Children
import { noSidebarPaths } from "../pathConfig";
import { SelectLanguageModal } from "./SelectLanguageModal";

export const LayoutTopbarRightGroup = () => {
  const { t } = useTranslation("layout", { keyPrefix: "topbar.rightGroup" });

  const pathMatches = useMatches();

  const noSidebarMode = pathMatches.some((match) =>
    noSidebarPaths.some((path) => matchPath(path, match.pathname))
  );

  const [openLanguageModal, setOpenLanguageModal] = useState<boolean>(false);

  // const handleShowChat = () => {
  //   if (window.$chatwoot) {
  //     window.$chatwoot.toggle();
  //   }
  // };
  return (
    <>
      <div className="flex">
        <button
          className={`w-16 h-16 flex justify-center items-center ${
            noSidebarMode ? "text-grey-blue" : "text-button-disabled "
          }`}
          disabled
        >
          <Tooltip title={t("tooltip.comingSoon")}>
            <Notification2Line />
          </Tooltip>
        </button>
        {/* <button
          className={`w-16 h-16 flex justify-center items-center ${
            noSidebarMode ? "text-white" : "text-hard-grey"
          }`}
          onClick={handleShowChat}
        >
          <QuestionLine />
        </button> */}
        <button
          className={`w-16 h-16 ${
            noSidebarMode ? "text-white" : "text-hard-grey"
          }`}
          onClick={() => {
            setOpenLanguageModal(true);
          }}
        >
          <Tooltip title={t("tooltip.changeLanguage")}>
            {i18next.language.toUpperCase()}
          </Tooltip>
        </button>
        <button
          className={`w-16 h-16 flex justify-center items-center ${
            noSidebarMode ? "text-white" : "text-hard-grey"
          }`}
          onClick={() => {
            authServices.userLogout().then(() => {
              Cookies.remove("access_token");
            });
            // TODO: add a catch here, even though it's funny to fail to log out
            window.location.assign(`${urls.ID_CYSTACK_URL}/login/explore`);
          }}
        >
          <Tooltip title={t("tooltip.logOut")}>
            <LogOutBoxLine />
          </Tooltip>
        </button>
      </div>
      <SelectLanguageModal
        open={openLanguageModal}
        setOpen={setOpenLanguageModal}
      />
    </>
  );
};
