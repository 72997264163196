// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@lockerpm/design";

// Resources
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { ReactComponent as QrScanLine } from "#src/assets/images/icons/qr-scan-line.svg";

// General
import type { IFilterItem } from "#src/@types/common";
import global from "#src/config/global";

// Components
import TimePopover from "#src/common/TimePopover";
import type { TimeConditionEnum } from "#src/common/TimePopover/TimePopoverContent";
import {
  FilterDropdown,
  SearchBox,
  SelectAllItemsDropdown,
} from "#src/common/Table/Filter";
import { IconButton } from "#src/common/system/Button";
import { createToast } from "#src/common/system/toasts";

// Children
import {
  scanScheduleTypeItems,
  scanStatusItems,
} from "#src/config/filter/scan";

interface IScanFilterProps {
  domains: IFilterItem | null;
  status: IFilterItem | null;
  schedule: IFilterItem | null;
  // vulnerable: IFilterItem | null;
  searchKeyword: string;
  totalCount: number;
  selectedCount: number;
  scannable: boolean;
  domainItems: IFilterItem[];
  onChangeTimeConditionValue: (
    condition: string,
    from: number,
    to: number
  ) => void;
  onChangeSearchKeyword: (keyword: string) => void;
  onChangeDomainsSelection: (selected: IFilterItem) => void;
  onChangeStatusSelection: (selected: IFilterItem) => void;
  onChangeScheduleSelection: (selected: IFilterItem) => void;
  // onChangeVulnerableSelection: (selected: IFilterItem) => void;
  onSelectAllScans: () => void;
  onDeselectAllScans: () => void;
  onDeleteSelectedScans: () => void;
  onRescanSelectedScans: () => void;
}

const ScanFilter = ({
  domains,
  status,
  schedule,
  // vulnerable,
  searchKeyword,
  totalCount,
  selectedCount,
  scannable,
  domainItems,
  onChangeTimeConditionValue,
  onChangeDomainsSelection,
  onChangeStatusSelection,
  onChangeScheduleSelection,
  // onChangeVulnerableSelection,
  onChangeSearchKeyword,
  onSelectAllScans,
  onDeselectAllScans,
  onDeleteSelectedScans,
  onRescanSelectedScans,
}: IScanFilterProps) => {
  const { t } = useTranslation("scans", {
    keyPrefix: "page.allScans.filter",
  });

  const [timeCondition, setTimeCondition] = useState<TimeConditionEnum | null>(
    null
  );
  const [timePopoverOpen, setTimePopoverOpen] = useState<boolean>(false);
  const [timeFilterText, setTimeFilterText] = useState<string>("");

  const onChangeTimeCondition = (
    text: string,
    condition: TimeConditionEnum,
    from: number,
    to: number
  ) => {
    setTimeCondition(condition);
    setTimeFilterText(text);
    onChangeTimeConditionValue(text, from, to);
  };

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <SelectAllItemsDropdown
          selectedCount={selectedCount}
          totalCount={totalCount}
          onSelectAll={onSelectAllScans}
          onDeselectAll={onDeselectAllScans}
        />
        <IconButton
          variant="ghost"
          disabled={selectedCount === 0}
          onClick={() => {
            global.confirm(() => onDeleteSelectedScans());
          }}
        >
          <DeleteBin6Line />
        </IconButton>
        <Tooltip
          title={
            scannable || selectedCount === 0
              ? null
              : "Selected items cannot be scanned"
          }
        >
          <div>
            <IconButton
              variant="ghost"
              disabled={selectedCount === 0 || !scannable}
              onClick={() => {
                global.confirm(() => {
                  // TODO: remove unnecessary scan -> send post
                  createToast({
                    type: "info",
                    message: "re-scanning...",
                  });
                  onRescanSelectedScans();
                });
              }}
            >
              <QrScanLine />
            </IconButton>
          </div>
        </Tooltip>
        <TimePopover
          label={t("timePopover.label")}
          timeCondition={timeCondition}
          textDisplay={timeFilterText}
          open={timePopoverOpen}
          setOpen={(value) => setTimePopoverOpen(value)}
          onChangeTime={onChangeTimeCondition}
        />
        <FilterDropdown
          label={t("domains.label")}
          selected={domains}
          options={domainItems}
          onChangeSelection={onChangeDomainsSelection}
        />
        <FilterDropdown
          label={t("status.label")}
          selected={status}
          options={scanStatusItems}
          onChangeSelection={onChangeStatusSelection}
        />
        <FilterDropdown
          label={t("schedule.label")}
          selected={schedule}
          options={scanScheduleTypeItems}
          onChangeSelection={onChangeScheduleSelection}
        />
        {/* TODO: It said `Vulnerabilities`, not `Vulnerable` */}
        {/* <FilterDropdown
          label={t("vulnerable.label")}
          selected={vulnerable}
          options={vulnerableItems}
          onChangeSelection={onChangeVulnerableSelection}
        /> */}
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default ScanFilter;
