// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import TimePopover from "#src/common/TimePopover";
import type { TimeConditionEnum } from "#src/common/TimePopover/TimePopoverContent";
import { FilterDropdown, SearchBox } from "#src/common/Table/Filter";

// Children
import { pentestStatusItems } from "#src/config/filter/pentest";
import { PentestStatusEnum } from "#src/config/filter/pentest/enum";

interface IMsspPentestFilterProps {
  pentestStatus: IFilterItem | null;
  searchKeyword: string;
  onChangeTimeConditionValue: (
    condition: string,
    from: number,
    to: number
  ) => void;
  onChangePentestStatusSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
}

const MsspPentestFilter = ({
  pentestStatus,
  searchKeyword,
  onChangeTimeConditionValue,
  onChangePentestStatusSelection,
  onChangeSearchKeyword,
}: IMsspPentestFilterProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestList.filter",
  });

  const [timeCondition, setTimeCondition] = useState<TimeConditionEnum | null>(
    null
  );
  const [timePopoverOpen, setTimePopoverOpen] = useState<boolean>(false);
  const [timeFilterText, setTimeFilterText] = useState<string>("");

  const onChangeTimeCondition = (
    text: string,
    condition: TimeConditionEnum,
    from: number,
    to: number
  ) => {
    setTimeCondition(condition);
    setTimeFilterText(text);
    onChangeTimeConditionValue(text, from, to);
  };

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 whitespace-nowrap flex-wrap">
        <TimePopover
          label={t("timePopover.label")}
          timeCondition={timeCondition}
          textDisplay={timeFilterText}
          open={timePopoverOpen}
          setOpen={(value) => setTimePopoverOpen(value)}
          onChangeTime={onChangeTimeCondition}
        />
        <FilterDropdown
          label={t("pentestStatus.label")}
          selected={pentestStatus}
          options={pentestStatusItems.filter(
            (item) => item.key !== PentestStatusEnum.DRAFT
          )}
          onChangeSelection={onChangePentestStatusSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default MsspPentestFilter;
