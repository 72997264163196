// Libraries
import dayjs from "dayjs";
import { Dropdown } from "@lockerpm/design";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";

// Components
import { ObjectImage } from "#src/common/system/Object";
import { TableRowWrapperDiv } from "#src/common/Table/TableRowWrapper";
import TableDataCell from "#src/common/Table/TableDataCell";
import TableCheckbox from "#src/common/Table/TableCheckbox";
import { toPascalCase } from "#src/utils/common";
import { IconButton } from "#src/common/system/Button";

// API-related
import { type IWorkspaceMember } from "#src/services/workspace";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

interface IMemberTableRowProps {
  index: number;
  data: IWorkspaceMember;
  isCurrentUser: boolean;
  actionList: ItemType[];
  onClickActionItems: (key: string, item: IWorkspaceMember) => void;
  selectedMemberIds: number[];
  onChangeSelectedMembers: (selected: IWorkspaceMember) => void;
}

const MemberTableRow = ({
  index,
  data,
  isCurrentUser,
  actionList,
  onClickActionItems,
  selectedMemberIds,
  onChangeSelectedMembers,
}: IMemberTableRowProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "member",
  });

  return (
    <TableRowWrapperDiv>
      <TableDataCell className="p-0 justify-center">
        <TableCheckbox
          checked={selectedMemberIds.includes(data.id)}
          onClick={() => {
            onChangeSelectedMembers(data);
          }}
        />
      </TableDataCell>
      <TableDataCell>{index + 1}</TableDataCell>
      <TableDataCell>
        <ObjectImage
          data={data.user?.avatar}
          className="h-8 w-8 min-w-[2rem] rounded-full"
        />
        <p className="font-medium-16 whitespace-nowrap">
          {data.user ? data.user.full_name : data.email}
        </p>
        {isCurrentUser ? (
          <div className="bg-subcard-blue text-white px-3 py-1.5 rounded-md font-medium-12">
            {t("label.you")}
          </div>
        ) : null}
      </TableDataCell>
      <TableDataCell>
        {data.user && data.user.email ? data.user.email : data.email}
      </TableDataCell>
      <TableDataCell>
        <div
          className={`${
            data.role === "owner"
              ? "bg-dark-blue"
              : data.role === "admin"
                ? "bg-support-teal"
                : data.role === "member"
                  ? "bg-support-pink"
                  : "bg-support-pink"
          } text-white px-3 py-1.5 rounded-md font-medium-12`}
        >
          {toPascalCase(data.role)}
        </div>
      </TableDataCell>
      <TableDataCell>
        {dayjs.unix(data.access_time).format("HH:mm DD.MM.YYYY")}
      </TableDataCell>
      <TableDataCell className="p-0 justify-center">
        <Dropdown
          menu={{
            items: actionList,
            onClick: ({ key, domEvent }) => {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              onClickActionItems(key, data);
            },
          }}
          trigger={["click"]}
          disabled={
            selectedMemberIds.length > 0 ||
            data.role === WorkspaceMemberRoleValue.OWNER ||
            actionList.length === 0
          }
        >
          <IconButton
            variant="ghost"
            size={9}
            onClick={(e) => {
              e.preventDefault();
            }}
            disabled={
              selectedMemberIds.length > 0 ||
              data.role === WorkspaceMemberRoleValue.OWNER ||
              actionList.length === 0
            }
          >
            <MoreLine />
          </IconButton>
        </Dropdown>
      </TableDataCell>
    </TableRowWrapperDiv>
  );
};

export default MemberTableRow;
