// Libraries
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import { Modal } from "#src/common/system/Modal";
import { Button } from "#src/common/system/Button";

// API-related
import paymentService from "#src/services/payment";

// Children
import KeywordCard, {
  type IKeywordPricingItem,
} from "../FindAndSelect/KeywordCard";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { durationItems, productPlanItems } from "../FindAndSelect/enum";

interface IDataLeakAddKeywordReselectPlanModalProps {
  workspaceId: string;
  open: boolean;
  onChangeOpen: (value: boolean) => void;
  editingItem: {
    keyword: string;
    product_plan_id: string;
    duration: string;
  } | null;
  onEditPlan: (value: {
    keyword: string;
    product_plan_id: string;
    duration: string;
  }) => void;
}

const DataLeakAddKeywordReselectPlanModal = ({
  workspaceId,
  open,
  onChangeOpen,
  editingItem,
  onEditPlan,
}: IDataLeakAddKeywordReselectPlanModalProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "addKeyword.paymentPage.fillForm.reselectModal",
  });

  const [selectedKeyword, setSelectedKeyword] =
    useState<IKeywordPricingItem | null>(null);

  const onCloseAndCleanup = () => {
    setSelectedKeyword(null);
    onChangeOpen(false);
  };

  const onRecalculate = useCallback(
    (productPlan: {
      keyword: string;
      product_plan_id: string;
      duration: string;
    }) => {
      paymentService
        .calculate_pricing(workspaceId, {
          currency: "VND",
          product_plans: [productPlan],
        })
        .then((response) => {
          const matchedPlan =
            productPlanItems.find(
              (item) => item.value === productPlan.product_plan_id
            ) ?? productPlanItems[0];
          const matchedDuration =
            durationItems.find((item) => item.value === productPlan.duration) ??
            durationItems[0];
          setSelectedKeyword({
            keyword: productPlan.keyword,
            productPlanItem: matchedPlan,
            durationItem: matchedDuration,
            price:
              response.product_plans[0].price.toLocaleString() +
              " " +
              response.currency,
            verification:
              response.product_plans[0].meta.workspace_keyword.verification,
          });
        })
        .catch(apiErrorHandler);
    },
    [workspaceId]
  );

  const onChangeKeywordInfo = (value: IKeywordPricingItem) => {
    setSelectedKeyword(value);
    onRecalculate({
      keyword: value.keyword,
      product_plan_id: value.productPlanItem.value,
      duration: value.durationItem.value,
    });
  };

  useEffect(() => {
    if (editingItem) {
      onRecalculate(editingItem);
    }
  }, [editingItem, onRecalculate]);

  return (
    <Modal open={open} onChangeOpen={onCloseAndCleanup} className="w-[64rem]">
      <h2>{t("title")}</h2>
      <div className="flex justify-center">
        <div className="w-[32rem]">
          <KeywordCard
            data={selectedKeyword}
            onChangeKeywordInfo={onChangeKeywordInfo}
            onRemoveKeyword={null}
          />
        </div>
      </div>
      <div className="flex gap-1 justify-end">
        <Button variant="secondary" onClick={onCloseAndCleanup}>
          {t("button.cancel")}
        </Button>
        <Button
          onClick={() => {
            if (selectedKeyword) {
              onEditPlan({
                keyword: selectedKeyword.keyword,
                product_plan_id: selectedKeyword.productPlanItem.value,
                duration: selectedKeyword.durationItem.value,
              });
              onCloseAndCleanup();
            }
          }}
        >
          {t("button.confirm")}
        </Button>
      </div>
    </Modal>
  );
};

export default DataLeakAddKeywordReselectPlanModal;
