import { forwardRef, type HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface ITableDataCellProps extends HTMLAttributes<HTMLDivElement> {}

/** A Table Cell made by adding default CSS to extend div Element. */
export const TableDataCellV2 = forwardRef<HTMLDivElement, ITableDataCellProps>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={twMerge(
          `bg-inherit p-3 text-sm text-gray-v2-600 flex items-center gap-x-2 justify-start text-left ${
            props.onClick ? "cursor-pointer" : ""
          }`,
          props.className
        )}
      >
        {props.children}
      </div>
    );
  }
);

TableDataCellV2.displayName = "TableDataCell";
