import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import type { IEndpointOverviewResponse } from "./types";
import {
  listPolicies,
  listPoliciesNoPaging,
  requestToComply,
  retrieveDetailPolicy,
  updatePolicy,
} from "./policies";
import { listDevices, getDeviceDetails } from "./devices";

async function retrieveHostsOverview(
  workspaceId: string
): Promise<IEndpointOverviewResponse> {
  return await request.get<never, IEndpointOverviewResponse>(
    `${endpoint.WORKSPACES}/${workspaceId}/hosts/overview`
  );
}

export const endpointService = {
  retrieveHostsOverview,

  // Devices
  listDevices,
  getDeviceDetails,

  // Policies
  listPolicies,
  listPoliciesNoPaging,
  retrieveDetailPolicy,
  updatePolicy,
  requestToComply,
};
