import { useEffect } from "react";
import assetsServices from "#src/services/assets";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { useParams } from "react-router-dom";
import { ContentSection, StickySection } from "#src/layouts/content";
import CertificateTable from "#src/components/assets/certificates/CertificateTable";
import { useTranslation } from "react-i18next";

const AssetsCertificates = () => {
  const { t } = useTranslation("assets", {
    keyPrefix: "certificates.page.certificates",
  });
  const { workspaceId, assetId } = useParams<"workspaceId" | "assetId">();

  useEffect(() => {
    if (!workspaceId || !assetId || isNaN(+assetId)) return;
    let ignore = false;

    if (!ignore) {
      assetsServices
        .get_asset_certificate(workspaceId, +assetId)
        .then((response) => {
          console.log(response);
        })
        .catch(apiErrorHandler);
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, assetId]);
  return (
    <>
      <StickySection>
        <div className="flex flex-col gap-4">
          <h1>{t("title")}</h1>
        </div>
      </StickySection>
      {workspaceId ? (
        <ContentSection>
          <CertificateTable
            workspaceId={workspaceId}
            key={"certificate-table"}
          />
        </ContentSection>
      ) : null}
    </>
  );
};

export default AssetsCertificates;
