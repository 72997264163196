import { IHostSoftwareItem } from "#src/services/devices/softwares";
import { Modal } from "@lockerpm/design";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  generatePath,
  useNavigate,
  useParams,
  createSearchParams,
} from "react-router-dom";
import { pathname } from "#src/config/pathname";
import { TableV2 } from "#src/commonV2/Table";
import { ArrowUpRight } from "@untitled-ui/icons-react";
import { DateTime } from "luxon";
import { TAILWIND_COLORS } from "#src/utils/common";
import devicesServices from "#src/services/devices";
import { LoaderV2 } from "#src/commonV2/LoadingState";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  info: IHostSoftwareItem | null;
};

export const ListVulnModal = (props: Props) => {
  const { isOpen, closeModal, info } = props;
  const navigate = useNavigate();
  const params = useParams<"workspaceId">();
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- DATA ---------------

  const [sortOrder, setSortOrder] = useState<string>("");
  const [deviceCount, setDeviceCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // --------------- COMPUTED ---------------

  const sortedList = useMemo(() => {
    if (!info) return [];

    return info.program.vulnerabilities.sort((a, b) => {
      if (sortOrder === "severity_asc") {
        return a.cvss_score - b.cvss_score;
      }

      if (sortOrder === "severity_desc") {
        return b.cvss_score - a.cvss_score;
      }

      if (sortOrder === "prob_asc") {
        return a.epss_probability - b.epss_probability;
      }

      if (sortOrder === "prob_desc") {
        return b.epss_probability - a.epss_probability;
      }

      return 0;
    });
  }, [info, sortOrder]);

  // --------------- METHODS ---------------

  // Load device with this program installed count
  const loadDeviceCount = useCallback(async () => {
    if (!info || !params?.workspaceId) {
      return;
    }

    setIsLoading(true);
    try {
      const res = await devicesServices.count_hosts(params.workspaceId, {
        program_id: info.program.id.toString(),
      });
      setDeviceCount(res.count);
    } catch (error) {
      //
    }
    setIsLoading(false);

    return info.program.vulnerabilities.length;
  }, [info, params?.workspaceId]);

  // View all devices with the this software installed
  const onClickViewAllHost = useCallback(() => {
    if (!info) return;
    navigate(
      {
        pathname: generatePath(pathname.ENDPOINT_DEVICES, params),
        search: createSearchParams({
          program_id: info.program.id.toString(),
        }).toString(),
      },
      { state: { program_name: info.program.name } }
    );
  }, [info, navigate, params]);

  // --------------- EFFECTS ---------------

  useEffect(() => {
    loadDeviceCount();
  }, [loadDeviceCount]);

  // --------------- RENDER ---------------

  return (
    <Modal
      title={t("software.vuln_modal.title")}
      open={isOpen}
      footer={null}
      maskClosable={false}
      onCancel={closeModal}
      className="v2 max-w-[700px]"
      centered
      width="90%"
    >
      {!!info && (
        <div className="v2">
          {/* Software info */}
          <div className="mb-5">
            <div className="bg-brand-v2-50 rounded-xl py-1.5 px-3">
              <p className="text-sm font-medium text-gray-v2-900 h-10 flex items-center">
                {info.program.name}
              </p>
            </div>

            <div className="flex justify-between items-center flex-wrap gap-2 mt-4">
              <div className="flex items-center gap-8 text-md text-gray-v2-600">
                <p>
                  {t("software.version")}{" "}
                  <span className="font-medium text-gray-v2-900">
                    {info.program.version}
                  </span>
                </p>
                <div className="flex items-center gap-1">
                  <p>{t("software.vuln_modal.device_count")}</p>
                  {isLoading ? (
                    <LoaderV2 baseSize={1} />
                  ) : (
                    <p className="font-medium text-gray-v2-900">
                      {deviceCount}
                    </p>
                  )}
                </div>
              </div>

              <div
                onClick={onClickViewAllHost}
                className="text-sm font-semibold text-brand-v2-700 cursor-pointer flex items-center gap-1"
              >
                <p>{t("software.vuln_modal.view_all")}</p>
                <ArrowUpRight className="h-5" />
              </div>
            </div>
          </div>
          {/* Software info end */}

          {/* Table */}
          <TableV2<IHostSoftwareItem["program"]["vulnerabilities"][0]>
            data={sortedList}
            getRowKey={(item) => item.cve}
            sort={{ value: sortOrder, onChange: setSortOrder }}
            options={{
              maxHeight: "40vh",
            }}
            columns={[
              {
                title: t("software.vuln_modal.vuln"),
                render: (item) => (
                  <a
                    href={item.details_link}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center no-underline text-sm font-medium text-brand-v2-700"
                  >
                    <p>{item.cve}</p>
                    <ArrowUpRight className="h-5" />
                  </a>
                ),
                gridTemplateValue: "2.5fr",
              },
              {
                title: t("software.vuln_modal.severity"),
                gridTemplateValue: "2fr",
                sortingKey: { asc: "severity_asc", desc: "severity_desc" },
                render: (item) => {
                  const colors = STATUS_COLOR[item.severity];
                  if (!colors) {
                    return "---";
                  }

                  return (
                    <div
                      className="border text-center w-full rounded-md p-1"
                      style={{
                        borderColor: colors.border,
                        backgroundColor: colors.bg,
                        color: colors.text,
                      }}
                    >
                      <p className="text-sm font-semibold">
                        {item.severity[0]}
                        {item.severity.slice(1).toLowerCase()} (
                        {item.cvss_score || 0})
                      </p>
                    </div>
                  );
                },
              },
              {
                title: t("software.vuln_modal.prob"),
                sortingKey: { asc: "prob_asc", desc: "prob_desc" },
                render: (item) => (
                  <p className="text-sm text-gray-v2-600">
                    {item.epss_probability}%
                  </p>
                ),
              },
              {
                title: t("software.vuln_modal.published"),
                gridTemplateValue: "2.5fr",
                render: (item) => {
                  if (!item.cve_published) {
                    return <p className="text-sm text-gray-v2-600">---</p>;
                  }

                  return (
                    <p className="text-sm text-gray-v2-600">
                      {DateTime.fromISO(item.cve_published).toFormat(
                        "HH:mm dd/MM/yyyy"
                      )}
                    </p>
                  );
                },
              },
              {
                // TODO: no data
                title: t("software.vuln_modal.detected"),
                render: () => <p className="text-sm text-gray-v2-600">---</p>,
              },
            ]}
          />
          {/* Table end */}
        </div>
      )}
    </Modal>
  );
};

const STATUS_COLOR: Record<
  string,
  { border: string; bg: string; text: string }
> = {
  LOW: {
    border: TAILWIND_COLORS["yellow-v2-300"],
    bg: TAILWIND_COLORS["yellow-v2-50"],
    text: TAILWIND_COLORS["yellow-v2-600"],
  },
  MEDIUM: {
    border: TAILWIND_COLORS["orange-v2-300"],
    bg: TAILWIND_COLORS["orange-v2-50"],
    text: TAILWIND_COLORS["orange-v2-600"],
  },
  HIGH: {
    border: TAILWIND_COLORS["error-v2-300"],
    bg: TAILWIND_COLORS["error-v2-50"],
    text: TAILWIND_COLORS["error-v2-600"],
  },
  CRITICAL: {
    border: TAILWIND_COLORS["rose-v2-400"],
    bg: TAILWIND_COLORS["rose-v2-100"],
    text: TAILWIND_COLORS["rose-v2-700"],
  },
  NONE: {
    border: TAILWIND_COLORS["gray-v2-300"],
    bg: TAILWIND_COLORS["gray-v2-50"],
    text: TAILWIND_COLORS["gray-v2-600"],
  },
};
