// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ShieldCheckLine } from "#src/assets/images/icons/shield-check-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { ReactComponent as QrScanLine } from "#src/assets/images/icons/qr-scan-line.svg";

// General
import global from "#src/config/global";

// Components
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import { TableActionLabel, TableHeader } from "#src/common/Table";
import type { ITableHeaderPropItem } from "#src/common/Table/TableHeader";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import assetsServices, { type IAssetItem } from "#src/services/assets";

// Children
import AddScanDrawer from "#src/components/scans/all-scans/AddScanDrawer";
import EditSettingDrawer from "../drawers/EditSettingDrawer";
import VerifyOwnershipDrawer from "../drawers/VerifyOwnershipDrawer";
import DomainTableRow from "./TableRow";

interface IDomainTableProps {
  workspaceId: string;
  domainList: IAssetItem[] | null;
  selectedDomainIds: number[];
  isLoading: boolean;
  onChangeSelectedDomains: (selected: IAssetItem) => void;
  refreshDomainList: () => void;
}

const DomainTableGrid = ({
  workspaceId,
  domainList,
  selectedDomainIds,
  isLoading,
  onChangeSelectedDomains,
  refreshDomainList,
}: IDomainTableProps) => {
  const { t } = useTranslation("assets", { keyPrefix: "domains.page.domains" });

  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);
  const [openVerifyDrawer, setOpenVerifyDrawer] = useState<boolean>(false);
  const [openScanDrawer, setOpenScanDrawer] = useState<boolean>(false);

  // Item that is chosen to edit or to verify
  const [activeItem, setActiveItem] = useState<IAssetItem | null>(null);

  /** Asset Domain Table's header list */
  const headers: ITableHeaderPropItem[] = [
    { name: "", align: "left" },
    { name: t("headers.name"), align: "left" },
    { name: t("headers.scans"), align: "left" },
    { name: t("headers.subdomains"), align: "left" },
    { name: t("headers.vulnerabilities"), align: "left" },
    { name: t("headers.lastScan"), align: "left" },
    { name: t("headers.tags"), align: "left" },
    { name: t("headers.actions"), align: "center" },
  ];

  const actionList = [
    {
      key: "verify-ownership",
      label: (
        <TableActionLabel
          icon={<ShieldCheckLine />}
          text={t("actions.verifyOwnership")}
        />
      ),
    },
    {
      key: "edit-settings",
      label: (
        <TableActionLabel
          icon={<EditLine />}
          text={t("actions.editSettings")}
        />
      ),
    },
    {
      key: "launch-new-scan",
      label: (
        <TableActionLabel
          icon={<QrScanLine />}
          text={t("actions.launchNewScan")}
        />
      ),
    },
    {
      key: "delete-domain",
      label: (
        <TableActionLabel
          icon={<DeleteBin6Line />}
          text={t("actions.deleteDomain")}
          warning
        />
      ),
    },
  ];

  const onDeleteDomain = (id: number) => {
    assetsServices
      .delete_asset(id, workspaceId)
      .then(() => {
        refreshDomainList();
        createToast({
          type: "success",
          message: t("notification.deleteDomain.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteDomain.fail"),
        });
      });
  };

  const onClickActionItems = (key: string, item: IAssetItem) => {
    switch (key) {
      case "verify-ownership":
        setOpenVerifyDrawer(true);
        setActiveItem(item);
        return;
      case "edit-settings":
        setOpenEditDrawer(true);
        setActiveItem(item);
        return;
      case "delete-domain":
        global.confirm(() => onDeleteDomain(item.id));
        return;
      case "launch-new-scan":
        setOpenScanDrawer(true);
        setActiveItem(item);
        return;
    }
  };

  return (
    <>
      {isLoading || domainList === null ? (
        <LoadingState />
      ) : domainList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[2.75rem_8fr_3fr_3fr_4fr_5fr_4fr_3fr]">
          <TableHeader title="assets/domains" headerProps={headers} />
          {domainList.map((item) => (
            <DomainTableRow
              key={`tassets/domains-tr${item.id}`}
              data={item}
              actionList={actionList.filter((action) => {
                if (item.verification) {
                  return action.key !== "verify-ownership";
                } else {
                  return action.key !== "launch-new-scan";
                }
              })}
              onClickActionItems={onClickActionItems}
              selectedDomainIds={selectedDomainIds}
              onChangeSelectedDomains={onChangeSelectedDomains}
            />
          ))}
        </div>
      )}
      {activeItem ? (
        <>
          <EditSettingDrawer
            selectedItemId={activeItem.id}
            workspaceId={workspaceId}
            open={openEditDrawer}
            onClose={() => {
              setOpenEditDrawer(false);
              setActiveItem(null);
            }}
            onRefresh={refreshDomainList}
          />
          <VerifyOwnershipDrawer
            open={openVerifyDrawer}
            onClose={() => {
              setOpenVerifyDrawer(false);
              setActiveItem(null);
            }}
            workspaceId={workspaceId}
            onRefresh={() => {}}
            assetName={activeItem.name}
            assetId={activeItem.id}
          />
          <AddScanDrawer
            open={openScanDrawer}
            workspaceId={workspaceId}
            onClose={() => {
              setOpenScanDrawer(false);
              setActiveItem(null);
            }}
            onRefresh={refreshDomainList}
            assetInit={{
              key: activeItem.id.toString(),
              id: activeItem.id.toString(),
              value: activeItem.id.toString(),
              address: activeItem.detail.address,
              label: activeItem.detail.address,
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default DomainTableGrid;
