import { Dropdown } from "@lockerpm/design";
import { ChevronDown } from "@untitled-ui/icons-react";
import { useTranslation } from "react-i18next";
import { ButtonV2 } from "../Button";
import { DropdownRenderV2 } from "../antdHelpers/DropdownRender";

interface IPaginationV2Props {
  // `id` lets us sync the height of the table.
  id: string;
  numOfResult: number;
  selectedPage: number;
  onChangePage: (page: number) => void;
  pageSize: number;
  onChangePageSize?: (size: number) => void;
}

/** Pagination bar that goes with the table component. */
export const PaginationV2 = ({
  id,
  numOfResult,
  selectedPage,
  onChangePage,
  pageSize,
  onChangePageSize,
}: IPaginationV2Props) => {
  const { t } = useTranslation("common", { keyPrefix: "pagination" });

  const numOfPages = Math.floor(Number(numOfResult) / pageSize) + 1;

  const pageSizeItems = [10, 25, 50, 100].map((item) => ({
    key: item,
    label: (
      <div
        className={`flex items-center gap-2 p-2 text-sm font-semibold bg-white ${item === pageSize ? "text-gray-v2-700 bg-gray-v2-50" : "text-gray-v2 hover:bg-gray-v2-50"}`}
      >
        {t("perPage", { count: item })}
      </div>
    ),
  }));

  return (
    // h-[3.625rem] is the height of this div when buttons are shown. Set it here to make sure when there's no button the height won't change.
    <div
      id={id}
      className="flex items-center justify-between px-3 py-2 h-[3.625rem] border-t border-gray-v2-200"
    >
      <div className="text-sm font-medium text-gray-v2-700">
        <b>{t("numOfResults", { count: numOfResult })} |</b>{" "}
        {t("pageNumber", { current: selectedPage, total: numOfPages })}
      </div>
      <div className="flex items-center gap-2">
        {selectedPage > 1 ? (
          <ButtonV2
            variant="secondary"
            color="gray"
            size="sm"
            onClick={() => {
              onChangePage(selectedPage - 1);
            }}
          >
            {t("button.previous")}
          </ButtonV2>
        ) : null}
        {selectedPage < numOfPages ? (
          <ButtonV2
            variant="secondary"
            color="gray"
            size="sm"
            onClick={() => {
              onChangePage(selectedPage + 1);
            }}
          >
            {t("button.next")}
          </ButtonV2>
        ) : null}
        {onChangePageSize ? (
          <Dropdown
            menu={{
              items: pageSizeItems,
              onClick: ({ key }) => {
                onChangePageSize(Number(key));
              },
            }}
            dropdownRender={(menu) => (
              <DropdownRenderV2>{menu}</DropdownRenderV2>
            )}
            trigger={["click"]}
          >
            <ButtonV2 variant="secondary" color="gray" size="sm">
              {t("perPage", { count: pageSize })}
              <ChevronDown className="h-4 w-4 text-gray-v2-400" />
            </ButtonV2>
          </Dropdown>
        ) : null}
      </div>
    </div>
  );
};
