// Libraries
import { useEffect } from "react";
import {
  generatePath,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
} from "react-router";

// General
import { helperPathname, pathname } from "#src/config/pathname";
import { useAppDispatch } from "#src/store/hooks";
import { dataLeakDispatchAction } from "#src/store/slices/dataLeak";

// Components
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import dataLeakService from "#src/services/dataLeak";

export const DataLeakIndexPage = () => {
  const { workspaceId } = useParams<"workspaceId">();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAtOnboarding = useMatch(helperPathname.DATA_LEAK);

  useEffect(() => {
    if (workspaceId) {
      dataLeakService
        .retrieve_data_leak_plan_existed(workspaceId)
        .then((response) => {
          // If user has never used Data Leak, redirect to Data Leak (aka. Onboard) page
          if (response.existed === false) {
            navigate(
              generatePath(pathname.DATA_LEAK_ADD_KEYWORD, {
                workspaceId,
              })
            );
          }

          // If user has used Data Leak, guide them to a functional page
          if (isAtOnboarding && response.existed === true) {
            navigate(
              generatePath(pathname.DATA_LEAK_OVERVIEW, {
                workspaceId,
              })
            );
          }
          dispatch(dataLeakDispatchAction.updatePlan(response.existed));
        })
        .catch(apiErrorHandler);
    } else {
      throw Error("Invalid workspace parameter");
    }
  }, [workspaceId, dispatch, isAtOnboarding, navigate]);

  return <Outlet />;
};
