import { ReactNode } from "react";

export const EndpointOverviewTableWrapper = ({
  title,
  description,
  table,
}: {
  title: string;
  description: string;
  table: ReactNode;
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <span className="text-lg font-semibold text-gray-v2-900">{title}</span>
        <span className="text-md text-gray-v2-600">{description}</span>
      </div>
      {table}
    </div>
  );
};
