// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ExportLine } from "#src/assets/images/icons/export-line.svg";
import { ReactComponent as ArrowExpandDownLine } from "#src/assets/images/icons/arrow-expand-down-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import { CopyToClipboardButton } from "#src/common/system/CopyToClipboardButton";

// APi-related
import type { ICertificateItem } from "#src/services/assets/certificates";

const CertificateRaw = ({ certificate }: { certificate: ICertificateItem }) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.certificates",
  });

  const [viewMore, setViewMore] = useState<boolean>(false);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <h2>{t("raw")}</h2>
        <div className="flex gap-1">
          <CopyToClipboardButton value={certificate.raw} variant="secondary" />
          <Button variant="secondary" size="large">
            <ExportLine />
            {t("button.download")}
          </Button>
        </div>
      </div>
      <div className="p-6 bg-bright-blue rounded-md font-regular-14-body flex flex-col items-center gap-6">
        <div className={viewMore ? "" : "line-clamp-[5]"}>
          {certificate.raw}
        </div>
        {/* TODO: The raw is probably longer than 5 lines, but this should've been done better */}
        <Button
          variant="text"
          onClick={() => {
            setViewMore((prev) => !prev);
          }}
          className="w-fit"
        >
          {viewMore ? t("button.collapse") : t("button.viewMore")}
          <ArrowExpandDownLine className={viewMore ? "rotate-180" : ""} />
        </Button>
      </div>
    </div>
  );
};

export default CertificateRaw;
