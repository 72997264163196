// Libraries
import { useState, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// Resources
import { ReactComponent as ArrowExpandDownLine } from "#src/assets/images/icons/arrow-expand-down-line.svg";

// Components
import { Button } from "#src/common/system/Button";

// API-related
import type { ICertificateItem } from "#src/services/assets/certificates";

const InfoTable = ({ children }: { children: ReactNode }) => {
  return <div className="flex flex-col gap-6">{children}</div>;
};

const InfoContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="bg-bright-grey p-6 rounded-md h-full">
      <div className="grid grid-cols-2 gap-y-4">{children}</div>
    </div>
  );
};

const InfoField = ({ name, value }: { name: string; value: string }) => {
  return (
    <div className="grid grid-cols-subgrid col-span-full">
      <div className="text-hard-grey">{name}</div>
      {/* TODO: show full when hovering or st */}
      <div className="overflow-hidden text-ellipsis">
        {value ? value : "---"}
      </div>
    </div>
  );
};

const CertificateDetails = ({
  certificate,
}: {
  certificate: ICertificateItem;
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.certificates",
  });

  const [viewMoreAltNames, setViewMoreAltNames] = useState<boolean>(false);

  return (
    <div className="grid grid-cols-3 gap-x-6 gap-y-12">
      <InfoTable>
        <h2>{t("issuedTo.title")}</h2>
        <InfoContainer>
          <InfoField
            name={t("issuedTo.name")}
            value={certificate.subject.name}
          />
          <InfoField
            name={t("issuedTo.organization")}
            value={certificate.subject.org}
          />
          <InfoField
            name={t("issuedTo.city")}
            value={certificate.subject.location}
          />
          <InfoField
            name={t("issuedTo.state")}
            value={certificate.subject.state}
          />
          <InfoField
            name={t("issuedTo.country")}
            value={certificate.subject.country}
          />
        </InfoContainer>
      </InfoTable>
      <InfoTable>
        <h2>{t("issuedBy.title")}</h2>
        <InfoContainer>
          <InfoField
            name={t("issuedTo.name")}
            value={certificate.issuer.name}
          />
          <InfoField
            name={t("issuedTo.organization")}
            value={certificate.issuer.org}
          />
          <InfoField
            name={t("issuedTo.country")}
            value={certificate.issuer.country}
          />
        </InfoContainer>
      </InfoTable>
      <InfoTable>
        <h2>{t("certificateDetails.title")}</h2>
        <InfoContainer>
          <InfoField
            name={t("certificateDetails.serialNumber")}
            value={certificate.serial_number}
          />
          <InfoField
            name={t("certificateDetails.key")}
            value={certificate.key_alg + " " + certificate.key_size}
          />
          <InfoField
            name={t("certificateDetails.signatureAlgorithm")}
            value={certificate.sig_alg}
          />
          <InfoField
            name={t("certificateDetails.lastFound")}
            value={dayjs.unix(certificate.last_found).format("MMMM D, YYYY")}
          />
        </InfoContainer>
      </InfoTable>
      <InfoTable>
        <h2>{t("fingerprints.title")}</h2>
        <InfoContainer>
          <InfoField
            name={t("fingerprints.fingerprints")}
            value={certificate.fingerprint}
          />
          <InfoField
            name={t("fingerprints.parentFingerprints")}
            value={certificate.parent_fingerprint}
          />
        </InfoContainer>
      </InfoTable>
      <InfoTable>
        <h2>{t("subjectAlternativeNames.title")}</h2>
        <InfoContainer>
          {(viewMoreAltNames
            ? certificate.alt_names
            : certificate.alt_names.slice(0, 5)
          ).map((altName) => (
            <InfoField
              key={`certificate-altName-${altName}`}
              name={"DNS"}
              value={altName}
            />
          ))}
          {certificate.alt_names.length > 5 ? (
            <div className="col-span-2 flex justify-center">
              <Button
                variant="text"
                onClick={() => {
                  setViewMoreAltNames((prev) => !prev);
                }}
              >
                {viewMoreAltNames ? t("button.collapse") : t("button.viewMore")}
                <ArrowExpandDownLine
                  className={viewMoreAltNames ? "rotate-180" : ""}
                />
              </Button>
            </div>
          ) : null}
        </InfoContainer>
      </InfoTable>
      <InfoTable>
        <h2>{t("validity.title")}</h2>
        <InfoContainer>
          <InfoField
            name={t("validity.validFrom")}
            value={dayjs.unix(certificate.not_before).format("MMMM D, YYYY")}
          />
          <InfoField
            name={t("validity.validTo")}
            value={dayjs.unix(certificate.not_after).format("MMMM D, YYYY")}
          />
        </InfoContainer>
      </InfoTable>
    </div>
  );
};

export default CertificateDetails;
