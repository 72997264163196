import { Fragment } from "react";
import { generatePath, Link, useMatches, useParams } from "react-router-dom";
import { z } from "zod";

export const LayoutTopbarBreadcrumb = () => {
  const params = useParams();

  const pathMatches = useMatches();
  const crumbs = pathMatches
    .map((match) =>
      z
        .object({
          crumb: z.function().returns(
            z.object({
              getText: z.function().returns(z.string()),
              path: z.string(),
            })
          ),
        })
        .safeParse(match.handle)
        .data?.crumb(params)
    )
    .filter((result): result is { getText: () => string; path: string } =>
      Boolean(result)
    );

  return (
    <div className="flex gap-3 items-center">
      {crumbs.map((item, index) => {
        return (
          <Fragment key={`breadcrumb-${item.path}${index}`}>
            {index !== 0 ? "/" : null}
            {index === crumbs.length - 1 ? (
              <span className="font-medium-14-forced text-dark-blue">
                {item.getText()}
              </span>
            ) : (
              <Link
                to={generatePath(item.path, params)}
                className={`font-medium-14-forced text-medium-grey hover:text-medium-grey no-underline`}
              >
                {item.getText()}
              </Link>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
