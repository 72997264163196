import { useCallback, useEffect, useMemo, useState } from "react";
import { ArrowUpRight } from "@untitled-ui/icons-react";
import { TAILWIND_COLORS } from "#src/utils/common";
import devicesServices from "#src/services/devices";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { LoaderV2 } from "#src/commonV2/LoadingState";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { pathname } from "#src/config/pathname";
import { DonutChartV2 } from "#src/commonV2/charts/Donut";
import { getChartTooltipV2 } from "#src/commonV2/charts/helper/tooltip";
import { ChartLegendsV2 } from "#src/commonV2/charts/helper/legends";

type Props = {
  workspaceId: string;
  hostId: string;
};

export const SoftwareSection = (props: Props) => {
  const { workspaceId, hostId } = props;
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });
  const navigate = useNavigate();

  // --------------- STATE ---------------

  const [isLoading, setIsLoading] = useState(false);
  const [softwareInfo, setSoftwareInfo] = useState({
    total: 0,
    totalVuln: 0,
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    actualTotalVuln: 0,
  });

  // --------------- METHOD ---------------

  const viewDetails = () => {
    navigate({
      pathname: generatePath(pathname.ENDPOINT_DEVICES_SOFTWARE, {
        workspaceId,
        host: hostId,
      }),
    });
  };

  // --------------- COMPUTED ---------------

  const isEmpty = softwareInfo.total === 0;
  const isSafe = softwareInfo.totalVuln === 0;

  const chartData = useMemo(() => {
    return [
      {
        id: "critical",
        name: "Critical",
        value: softwareInfo.critical,
        percent: (
          (softwareInfo.critical / softwareInfo.actualTotalVuln) *
          100
        ).toFixed(0),
        fill: TAILWIND_COLORS["rose-v2-800"],
      },
      {
        id: "high",
        name: "High",
        value: softwareInfo.high,
        percent: (
          (softwareInfo.high / softwareInfo.actualTotalVuln) *
          100
        ).toFixed(0),
        fill: TAILWIND_COLORS["error-v2-500"],
      },
      {
        id: "medium",
        name: "Medium",
        value: softwareInfo.medium,
        percent: (
          (softwareInfo.medium / softwareInfo.actualTotalVuln) *
          100
        ).toFixed(0),
        fill: TAILWIND_COLORS["orange-v2-400"],
      },
      {
        id: "low",
        name: "Low",
        value: softwareInfo.low,
        percent: (
          (softwareInfo.low / softwareInfo.actualTotalVuln) *
          100
        ).toFixed(0),
        fill: TAILWIND_COLORS["yellow-v2-400"],
      },
    ];
  }, [softwareInfo]);

  // --------------- METHODS ---------------

  const fetchSoftwareInfo = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await devicesServices.get_host_softwares_statistic(
        workspaceId,
        hostId
      );
      const newData = {
        total: res.total || 0,
        totalVuln: res.vulnerable || 0,
        critical: res.severity.CRITICAL || 0,
        high: res.severity.HIGH || 0,
        medium: res.severity.MEDIUM || 0,
        low: res.severity.LOW || 0,
      };
      setSoftwareInfo({
        ...newData,
        actualTotalVuln:
          newData.critical + newData.high + newData.medium + newData.low,
      });
    } catch (error) {
      apiErrorHandler(error);
    }

    setIsLoading(false);
  }, [workspaceId, hostId]);

  // --------------- EFFECTS ---------------

  useEffect(() => {
    fetchSoftwareInfo();
  }, [fetchSoftwareInfo]);

  // --------------- RENDERING ---------------

  return (
    <div className="w-full xl:w-8/12 pl-0 xl:pl-4">
      <section className="w-full section-container flex flex-col justify-between">
        <h3>{t("overview.software.title")}</h3>

        {/* Content */}
        <div className="flex items-center flex-col md:flex-row gap-4">
          {isLoading && (
            <div className="w-full h-40 flex items-center justify-center">
              <LoaderV2 baseSize={4} />
            </div>
          )}

          {!isLoading && isEmpty && (
            <div className="w-full h-40 flex items-center justify-center">
              <p className="text-gray-v2-400 text-sm">{t("common.no_data")}</p>
            </div>
          )}

          {/* Total */}
          {!isLoading && !isEmpty && (
            <div className="flex-1 flex justify-center items-center h-full md:border-r px-4">
              <div className="md:min-h-40 flex justify-center flex-col">
                <p className="text-display-sm font-semibold text-brand-v2-600 mb-2">
                  {softwareInfo.totalVuln}/{softwareInfo.total}
                </p>
                <p className="text-md text-gray-v2-600">
                  {t("overview.software.has_vuln")}
                </p>
              </div>
            </div>
          )}
          {/* Total end */}

          {/* Chart */}
          {!isLoading && !isEmpty && !isSafe && (
            <div className="flex-1 flex items-center justify-center">
              <div className="flex flex-wrap gap-4 items-center justify-center px-4">
                <DonutChartV2
                  height={"10rem"}
                  width={"10rem"}
                  tooltip={getChartTooltipV2}
                  data={chartData}
                />

                <ChartLegendsV2 data={chartData} showPercentage />
              </div>
            </div>
          )}
          {/* Chart end */}
        </div>
        {/* Content end */}

        {/* Footer */}
        <div className="flex justify-center w-full py-2.5 mt-4">
          <a
            className="link"
            onClick={(e) => {
              e.preventDefault();
              viewDetails();
            }}
          >
            <span>{t("button.view_details")}</span>{" "}
            <ArrowUpRight className="h-5 w-5 ml-1" />
          </a>
        </div>
        {/* Footer end */}
      </section>
    </div>
  );
};
