// Libraries
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import OverviewMainCard from "#src/common/OverviewMainCard";

// API-related
import assetsServices from "#src/services/assets";
import type { ICertificateItemTableData } from "#src/services/assets/certificates";

// Children
import CertificateTableGrid from "./Table";
import CardStatistics from "../CardStatistics";
import CertificateFilter from "./Filter";
import ListLinkedDomainsDrawer from "../../drawers/ListLinkedDomains";

interface ICertificateTableProps {
  workspaceId: string;
}

const CertificateTable = ({ workspaceId }: ICertificateTableProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "certificates.page.certificates",
  });

  const [expiration, setExpiration] = useState<IFilterItem | null>(null);
  const [algorithm, setAlgorithm] = useState<IFilterItem | null>(null);
  const [keySize, setKeySize] = useState<IFilterItem | null>(null);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);
  const [isOpenLinkedDomains, setIsOpenLinkedDomains] =
    useState<boolean>(false);
  const [selectedCertificate, setSelectedCertificate] = useState<
    ICertificateItemTableData | undefined
  >();

  useState<IFilterItem | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const fetchParams = useMemo<
    Parameters<typeof assetsServices.list_certificates>
  >(
    () => [
      workspaceId,
      {
        expiration: expiration?.value,
        last_found_from: from ? from : undefined,
        last_found_to: to ? to : undefined,
        sig_alg: algorithm?.value,
        key_size: keySize?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      searchKeyword,
      selectedPage,
      pageSize,
      keySize,
      algorithm,
      from,
      to,
      expiration,
    ]
  );

  const {
    list: certificateList,
    count: domainCount,
    isLoading,
    statistics,
  } = useFetchPaginated(
    assetsServices.list_certificates,
    fetchParams,
    assetsServices.get_certificate_statistics
  );

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangeExpiration = (data: IFilterItem) => {
    setExpiration(data);
    setSelectedPage(1);
  };

  const onChangeAlgorithm = (data: IFilterItem) => {
    setAlgorithm(data);
    setSelectedPage(1);
  };

  const onChangeKeySize = (data: IFilterItem) => {
    setKeySize(data);
    setSelectedPage(1);
  };

  const onChangeTimeConditionValue = (
    condition: string,
    from: number | null,
    to: number | null
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
    setSelectedPage(1);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const newExpireIn = useMemo(() => {
    if (!statistics?.expire_in) {
      return {};
    }

    return statistics.expire_in.reduce<Record<string, { count: number }>>(
      (acc, item) => {
        acc[item.expire_in] = { count: item.count };
        return acc;
      },
      {}
    );
  }, [statistics]);

  const onViewLinkedDomain = (value: ICertificateItemTableData) => {
    setSelectedCertificate(value);
    setIsOpenLinkedDomains(true);
  };

  return (
    <>
      <div className="flex flex-wrap gap-6">
        <div className="max-h-[112px]">
          <OverviewMainCard
            label={t("main_card.label")}
            value={statistics?.total || 0}
            variant="blue"
            className="h-full"
          />
        </div>
        <div className="flex-1">
          <div className="rounded-md py-3 px-6 bg-support-yellow-pastel flex justify-between mb-3">
            <span className="inline-block text-dark-blue font-medium-20">
              {t("sub_card.expiring_certificates.label")}
            </span>
            <div className="flex items-center gap-6">
              <span className="font-medium-18 text-support-green">
                {t("timeline_expiring.in_months", { month: 3 })}
              </span>
              <span className="font-bold-24 text-dark-blue">
                {newExpireIn?.in_90_days?.count || 0}
              </span>
            </div>
            <div className="flex items-center gap-6">
              <span className="font-medium-18 text-policy-expire-in2months">
                {t("timeline_expiring.in_months", { month: 2 })}
              </span>
              <span className="font-bold-24 text-dark-blue">
                {newExpireIn?.in_60_days?.count || 0}
              </span>
            </div>
            <div className="flex items-center gap-6">
              <span className="font-medium-18 text-policy-expire-in30days">
                {t("timeline_expiring.in_days", { day: 30 })}
              </span>
              <span className="font-bold-24 text-dark-blue">
                {newExpireIn?.in_30_days?.count || 0}
              </span>
            </div>
            <div className="flex items-center gap-6">
              <span className="font-medium-18 text-warning-dark-red">
                {t("timeline_expiring.expired")}
              </span>
              <span className="font-bold-24 text-dark-blue">
                {newExpireIn?.expired?.count || 0}
              </span>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-3">
            <div className="col-span-1">
              <CardStatistics
                title={t("sub_card.algorithm.label")}
                keyItem="sig_alg"
                content={statistics?.sig_alg}
              />
            </div>
            <div className="col-span-1">
              <div className="rounded-md">
                <CardStatistics
                  title={t("sub_card.key_size.label")}
                  keyItem="key_size"
                  content={statistics?.key_size}
                />
              </div>
            </div>
            <div className="col-span-2">
              <CardStatistics
                title={t("sub_card.authority.label")}
                keyItem="issuer_cn"
                content={statistics?.issuer_cn}
              />
            </div>
          </div>
        </div>
      </div>
      <CertificateFilter
        expiration={expiration}
        algorithm={algorithm}
        keySize={keySize}
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
        onChangeExpiration={onChangeExpiration}
        onChangeAlgorithm={onChangeAlgorithm}
        onChangeKeySize={onChangeKeySize}
        onChangeTimeConditionValue={onChangeTimeConditionValue}
      />
      <CertificateTableGrid
        certificateList={certificateList}
        onViewLinkedDomain={onViewLinkedDomain}
        isLoading={isLoading}
      />
      <PaginationBar
        numOfResult={domainCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
      <ListLinkedDomainsDrawer
        open={isOpenLinkedDomains}
        workspaceId={workspaceId}
        selectedCertificate={selectedCertificate}
        onClose={() => setIsOpenLinkedDomains(false)}
        onRefresh={() => {}}
      />
    </>
  );
};

export default CertificateTable;
