import { Drawer } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import devicesServices from "#src/services/devices";
import { useCallback, useState } from "react";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { ButtonV2 } from "#src/commonV2/Button";
import { CopyToClipboardButton } from "#src/common/system/CopyToClipboardButton";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  deviceName: string;
  workspaceId: string;
  hostId: number;
  remoteDeviceInfo: { username: string; password: string } | null;
};

export const RemoteAccessInfoDrawer = (props: Props) => {
  const { isOpen, onClose, deviceName, workspaceId, hostId, remoteDeviceInfo } =
    props;

  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- DATA ---------------

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // --------------- METHODS ---------------

  const closeConnection = useCallback(async () => {
    setIsLoading(true);
    try {
      await devicesServices.remote_host(workspaceId, hostId, { close: true });
      onClose();
    } catch (error) {
      apiErrorHandler(error, {
        toastMessage: t("notification.onCloseConnection.fail"),
      });
    }
    setIsLoading(false);
  }, [hostId, onClose, t, workspaceId]);

  // --------------- RENDER ---------------

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      closable={false}
      className="v2"
      title={
        <p className="text-display-xs font-semibold text-gray-v2-900">
          {t("modals.remoteAccess.remoteInfo.title")}
          <span className="text-brand-v2-600">{deviceName}</span>
        </p>
      }
      extra={null}
      footer={
        <div className="flex flex-col gap-3">
          <ButtonV2
            className="w-full"
            size="lg"
            onClick={closeConnection}
            color="error"
            pending={isLoading}
          >
            <span>{t("modals.remoteAccess.remoteInfo.close")}</span>
          </ButtonV2>
        </div>
      }
    >
      <div className="p-6 flex flex-col gap-6">
        <p className="text-md text-gray-v2-600">
          {t("modals.remoteAccess.remoteInfo.desc", { deviceName })}
        </p>

        <div className="flex flex-col gap-2">
          <p className="text-sm font-semibold text-gray-v2-900">ID</p>
          <div className="flex items-center justify-between gap-1">
            <div className="bg-bright-grey flex items-center h-11 rounded-md flex-1 p-3">
              {remoteDeviceInfo?.username}
            </div>
            <CopyToClipboardButton value={remoteDeviceInfo?.username} />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-sm font-semibold text-gray-v2-900">
            {t("modals.remoteAccess.remoteInfo.password")}
          </p>
          <div className="flex items-center justify-between gap-1">
            <div className="bg-bright-grey flex items-center h-11 rounded-md flex-1 p-3">
              {remoteDeviceInfo?.password}
            </div>
            <CopyToClipboardButton value={remoteDeviceInfo?.password} />
          </div>
        </div>
      </div>
    </Drawer>
  );
};
