// Libraries
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// Components
import { IconButton } from "#src/common/system/Button";
import { ObjectImage } from "#src/common/system/Object";
import { toPascalCase } from "#src/utils/common";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import { RoleLabel } from "#src/common/helper/labels/RoleLabel";

// API-related
import type { IUserInfo } from "#src/services/auth";
import type { IBugBountyProgramMember } from "#src/services/bugBounty/members";

// Children
import { bugBountyMemberRoleItems } from "#src/config/filter/bugbounty";

interface IMemberItemProps {
  currentUser: IUserInfo | null;
  member: IBugBountyProgramMember;
  actionList: ItemType[];
  onChangeMemberRole: (key: string, item: IBugBountyProgramMember) => void;
  onClickActionItem: (key: string, item: IBugBountyProgramMember) => void;
}

const MemberItem = ({
  currentUser,
  member,
  actionList,
  onChangeMemberRole,
  onClickActionItem,
}: IMemberItemProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.members",
  });

  return (
    <div className="border border-grey rounded-md p-6 flex justify-between gap-2">
      <div className="flex items-center gap-2">
        <ObjectImage
          data={member.user?.avatar}
          className="h-11 w-11 min-w-[2.75rem] rounded-full"
        />
        <div className="flex flex-col">
          <div className="font-medium-16 flex gap-2 items-center">
            {member.user ? member.user.full_name : member.email}
            <div className="flex gap-1">
              {member.role === "owner" ? (
                <RoleLabel
                  name={t("label.projectOwner")}
                  color={"bg-hard-grey"}
                />
              ) : null}
              {member.role === "developer" ? (
                <RoleLabel
                  name={t("label.developer")}
                  color={"bg-support-blue"}
                />
              ) : null}
              {member.role === "viewer" ? (
                <RoleLabel name={t("label.viewer")} color={"bg-hard-grey"} />
              ) : null}
              {member.role === "researcher" ? (
                <RoleLabel
                  name={t("label.researcher")}
                  color={"bg-hard-grey"}
                />
              ) : null}
              {member.default_assign ? (
                <RoleLabel name={t("label.autoAssign")} color={"bg-yellow"} />
              ) : null}
            </div>
          </div>
          <span className="font-regular-14 text-primary">
            {"@" + (member.user ? member.user.username : member.email)}
          </span>
        </div>
      </div>
      <div className="flex gap-1">
        {member.email === currentUser?.email ||
        member.user?.email === currentUser?.email ? null : (
          <Dropdown
            menu={{
              items: bugBountyMemberRoleItems.map((item) => ({
                key: item.key,
                label: (
                  <DropdownItemLabel selected={item.value === member.role}>
                    {item.getLabel()}
                  </DropdownItemLabel>
                ),
              })),
              onClick: ({ key, domEvent }) => {
                domEvent.preventDefault();
                domEvent.stopPropagation();
                onChangeMemberRole(key, member);
              },
            }}
            trigger={["click"]}
          >
            <DropdownTriggerButton id="dropdown-frequency">
              {toPascalCase(member.role)}
              <ArrowDownSLine />
            </DropdownTriggerButton>
          </Dropdown>
        )}
        <Dropdown
          menu={{
            items: actionList,
            onClick: ({ key, domEvent }) => {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              onClickActionItem(key, member);
            },
          }}
          trigger={["click"]}
          disabled={actionList.length === 0}
        >
          <IconButton
            variant="ghost"
            size={9}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <MoreLine />
          </IconButton>
        </Dropdown>
      </div>
    </div>
  );
};

export default MemberItem;
