import { useTranslation } from "react-i18next";
import { Input } from "#src/common/system/Input";

interface IAddBugBountyProgramNameProps {
  briefName: string;
  alias: string;
  onChangeBriefName: (value: string) => void;
  onChangeAlias: (value: string) => void;
  disabled?: boolean;
}

const AddBugBountyProgramName = ({
  briefName,
  alias,
  onChangeBriefName,
  onChangeAlias,
  disabled = false,
}: IAddBugBountyProgramNameProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.addBugBounty.programName",
  });

  return (
    <div className="flex flex-col gap-4">
      <h2>
        <span className="text-primary">* </span>
        {t("title")}
      </h2>
      <div className="grid grid-cols-2 gap-6">
        <div className="p-6 flex flex-col gap-4">
          <span className="font-medium-18">{t("briefName.title")}</span>
          {disabled ? (
            <span className="break-all">{briefName ? briefName : "N/A"}</span>
          ) : (
            <>
              <Input
                value={briefName}
                onChange={(e) => onChangeBriefName(e.target.value)}
                placeholder={t("briefName.placeholder")}
              />
              <span className="text-hard-grey">
                {t("briefName.description")}
              </span>
            </>
          )}
        </div>
        <div className="p-6 flex flex-col gap-4">
          <span className="font-medium-18">{t("alias.title")}</span>
          {disabled ? (
            <span className="break-all">{alias}</span>
          ) : (
            <>
              <Input
                value={alias}
                onChange={(e) => onChangeAlias(e.target.value)}
                placeholder={t("alias.placeholder")}
              />
              <span className="text-hard-grey">{t("alias.description")}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddBugBountyProgramName;
