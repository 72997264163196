import { type ButtonHTMLAttributes, forwardRef } from "react";

interface IEditorButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  inputUuid?: string;
}

/** This acts as a template for all the features in the markdown editor. */
export const EditorButton = forwardRef<HTMLButtonElement, IEditorButtonProps>(
  ({ inputUuid, ...props }, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        className="h-8 w-8 flex items-center justify-center disabled:text-button-disabled"
        onMouseDown={(e) => {
          e.preventDefault();
          const textarea = inputUuid
            ? document.getElementById(inputUuid)
            : null;
          if (textarea) {
            textarea?.focus();
          }
        }}
      />
    );
  }
);

EditorButton.displayName = "EditorButton";
