// Libraries
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ForbidLine } from "#src/assets/images/icons/forbid-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import { Input } from "#src/common/system/Input";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import LoadingState from "#src/common/system/LoadingState";
import { ObjectImage } from "#src/common/system/Object";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import bugBountyServices from "#src/services/bugBounty";

interface IBugBountyDetailsBlockingProps {
  workspaceId: string;
  bugBountyAlias: string;
}

const BugBountyDetailsBlocking = ({
  workspaceId,
  bugBountyAlias,
}: IBugBountyDetailsBlockingProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.blocking",
  });

  const [username, setUsername] = useState<string>("");

  const onBlockUser = () => {
    if (username.length > 3) {
      bugBountyServices
        .blacklist_researcher(workspaceId, bugBountyAlias, { username })
        .then(() => {
          createToast({
            type: "success",
            message: t("notification.block.success"),
          });
          updateBlockedList();
          setUsername("");
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.block.fail"),
          });
        });
    }
  };

  const onUnblockUser = (blockId: number) => {
    bugBountyServices
      .unblock_researcher(workspaceId, bugBountyAlias, blockId)
      .then(() => {
        createToast({
          type: "success",
          message: t("notification.unblock.success"),
        });
        updateBlockedList();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.unblock.fail"),
        });
      });
  };

  const fetchParams = useMemo<
    Parameters<typeof bugBountyServices.list_program_blocked_researchers>
  >(
    () => [
      workspaceId,
      bugBountyAlias,
      {
        page: undefined,
        size: undefined,
      },
    ],
    [workspaceId, bugBountyAlias]
  );

  const {
    list: blockedList,
    count: blockedCount,
    updateData: updateBlockedList,
    isLoading,
  } = useFetchPaginated(
    bugBountyServices.list_program_blocked_researchers,
    fetchParams
  );

  return (
    <section className="pt-6 flex flex-col gap-6">
      <h2>{t("title")}</h2>
      <div className="flex gap-6 w-2/3">
        <div className="flex flex-col gap-6 p-6 flex-1">
          <div className="flex flex-col gap-4">
            <span className="font-medium-18">{t("username.label")}</span>
            <Input
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              placeholder={t("username.placeholder")}
            />
          </div>
          <Button variant="warning" size="large" onClick={onBlockUser}>
            <ForbidLine height={"1.25rem"} width={"1.25rem"} />
            {t("button.blockResearcher")}
          </Button>
        </div>
        <div className="flex-1 flex flex-col gap-4">
          <div className="flex justify-between items-center font-medium-18">
            <span>{t("blockedResearchers")}</span>
            {isLoading || blockedList === null ? null : (
              <span className="text-primary">{blockedCount}</span>
            )}
          </div>
          {isLoading || blockedList === null ? (
            <LoadingState />
          ) : blockedCount === 0 ? null : (
            blockedList.map((blocked) => (
              <div
                key={`bugBounty-blocking-${blocked.id}`}
                className="border border-grey rounded-md px-6 py-2 flex justify-between items-center"
              >
                <div className="flex items-center gap-2">
                  {/* TODO: change placeholder to UserLine */}
                  <ObjectImage
                    data={blocked.user.avatar}
                    className="h-11 w-11 min-w-[2.75rem] rounded-full"
                  />
                  <div className="flex flex-col gap-2">
                    <div className="font-medium-16">
                      {blocked.user.full_name}
                    </div>
                    <span className="font-regular-14 text-primary">
                      {"@" + blocked.user.username}
                    </span>
                  </div>
                </div>
                <Button
                  variant="secondary"
                  onClick={() => {
                    onUnblockUser(blocked.id);
                  }}
                >
                  {t("button.unblock")}
                </Button>
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default BugBountyDetailsBlocking;
