export const SolutionLink = {
  LOCKER_SECRET_MANAGER: "https://secrets.locker.io",
  LOCKER_SECRET_ADS: "https://locker.io/secrets",
  LOCKER_PASSWORD_MANAGER: "https://passwords.locker.io",
  SAFE_CHAIN: "https://safechain.org/",
  ENDPOINT: "https://cystack.net/endpoint",
  // The list has these 2, but internal links must be processed differently, so this is not needed.
  // TRUST_CENTER: helperPathname.TRUST_CENTER,
  // DATA_LEAK: helperPathname.DATA_LEAK_OVERVIEW,
  TRUST_CENTER_ADS: "https://cystack.net/trust-center",
  DATA_LEAK_ADS: "https://cystack.net/data-leak-detection",
  VULNSCAN: "https://cystack.net/vulnscan",
  SOURCE_CODE_AUDIT: "https://cystack.net/contact-sales",
  BLOCKCHAIN_AUDIT: "https://cystack.net/services/blockchain-protocol-audit",
  PENETRATION_TESTING: "https://cystack.net/services/penetration-testing",
  RED_TEAM: "https://cystack.net/red-teaming",
  INFRASTRUCTURE_AUDIT: "https://cystack.net/services/infrastructure",
  COMPLIANCE_AUDIT_SERVICE: "https://cystack.net/contact-sales",
  SOC: "https://cystack.net/contact-sales",
  VULNERABILITY_MANAGEMENT:
    "https://cystack.net/services/vulnerability-management",
  WHITEHUB_BUG_BOUNTY: "https://whitehub.net/",
};
