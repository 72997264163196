import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { OverflowEllipsis } from "#src/common/OverflowEllipsis";

const getTwColorBySeverity = (severity: string) => {
  const twBySeverity = {
    CRITICAL: "bg-severity-critical-bg text-severity-critical",
    HIGH: "bg-severity-high-bg text-severity-high",
    MEDIUM: "bg-severity-medium-bg text-severity-medium",
    LOW: "bg-severity-low-bg text-severity-low",
    INFORMATION: "bg-severity-information-bg text-severity-information",
    NONE: "bg-severity-none-bg text-severity-none",
  } satisfies Record<string, string>;

  const severityTypeguard = (
    arg: unknown
  ): arg is keyof typeof twBySeverity => {
    return typeof arg === "string" && arg in twBySeverity;
  };

  if (severityTypeguard(severity)) {
    return twBySeverity[severity];
  }

  console.log("Wrong severity! " + severity);
  return twBySeverity.NONE;
};

const useFullTextBySeverity = (severity: string) => {
  const { t } = useTranslation("translation");

  const fullTextLabel = {
    CRITICAL: t("severity.critical"),
    HIGH: t("severity.high"),
    MEDIUM: t("severity.medium"),
    LOW: t("severity.low"),
    INFORMATION: t("severity.information"),
    NONE: t("severity.none"),
  } satisfies Record<string, ReactNode>;

  const fullTextTypeguard = (
    arg: unknown
  ): arg is keyof typeof fullTextLabel => {
    return typeof arg === "string" && arg in fullTextLabel;
  };

  if (fullTextTypeguard(severity)) {
    return fullTextLabel[severity];
  }

  console.log("Wrong severity! " + severity);
  return fullTextLabel.NONE;
};

const getAbbreviatedText = (text: string) =>
  text.length > 0 ? text[0].toUpperCase() : "";

interface IExpSeverityLabelProps {
  severity: string;
  mode?: "normal" | "abbv" | "dropdown";
}

/** Provide a badge with centered bold text, rounded corner, border and background color mapped to Severity list.
 * @param severity - One of 6 severities, should be uppercased
 * @param mode - layout mode for this badge: abbv will print only the first letter, dropdown has different size
 */
export const SeverityLabel = ({
  severity,
  mode = "normal",
}: IExpSeverityLabelProps) => {
  const fullTextSeverity = useFullTextBySeverity(severity);

  if (mode === "abbv") {
    return (
      <div
        className={`flex justify-center items-center text-center h-6 w-8 min-w-[2rem] rounded font-bold-14 ${getTwColorBySeverity(
          severity
        )}`}
      >
        {getAbbreviatedText(severity)}
      </div>
    );
  }

  if (mode === "dropdown") {
    return (
      <div
        className={`flex justify-between items-center text-center gap-1 rounded w-full h-11 px-3 font-medium-16 ${getTwColorBySeverity(
          severity
        )}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <OverflowEllipsis>{fullTextSeverity}</OverflowEllipsis>
        <ArrowDownSLine
          height={"1rem"}
          width={"1rem"}
          className="min-h-[1rem] min-w-[1rem]"
        />
      </div>
    );
  }

  // mode === "normal"
  return (
    <div
      className={`flex justify-center items-center text-center whitespace-nowrap overflow-hidden rounded w-32 h-[2.125rem] font-medium-14 ${getTwColorBySeverity(
        severity
      )}`}
    >
      {fullTextSeverity}
    </div>
  );
};

SeverityLabel.spanAsString = ({ severity }: { severity: string }) => {
  return `<span class="flex justify-center items-center text-center rounded min-w-[5.5rem] h-[2.125rem] mx-3 px-1.5 font-medium-14 ${getTwColorBySeverity(
    severity
  )}">${useFullTextBySeverity(severity)}</span>`;
};
