// Libraries
import { useTranslation } from "react-i18next";

// Components
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import TableHeader, {
  type ITableHeaderPropItem,
} from "#src/common/Table/TableHeader";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { ITrustCenterThirdPartyItem } from "#src/services/trustCenter/thirdParty";
import trustCenterServices from "#src/services/trustCenter";

// Children
import TrustCenterThirdPartyTableRow from "./TableRow";

interface ITrustCenterThirdPartyTableGridProps {
  workspaceId: string;
  thirdPartyList: ITrustCenterThirdPartyItem[] | null;
  isLoading: boolean;
  onRefresh: () => void;
}

const TrustCenterThirdPartyTableGrid = ({
  workspaceId,
  thirdPartyList,
  isLoading,
  onRefresh,
}: ITrustCenterThirdPartyTableGridProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "thirdParty" });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.service"), align: "left" },
    { name: t("headers.purpose"), align: "left" },
    { name: t("headers.actions"), align: "center" },
  ];

  const onDeleteService = (service: ITrustCenterThirdPartyItem) => {
    trustCenterServices
      .delete_trust_center_third_party(workspaceId, service.id)
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteService.fail"),
        });
      });
  };

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  return (
    <>
      {isLoading || thirdPartyList === null ? (
        <LoadingState />
      ) : thirdPartyList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[3fr_3fr_1fr]">
          <TableHeader title="trustCenter-thirdParty" headerProps={headers} />
          {thirdPartyList.map((item) => (
            <TrustCenterThirdPartyTableRow
              key={`trustCenter/thirdParty-tr${item.id}`}
              data={item}
              workspaceRole={currentWorkspace?.role}
              onDeleteService={onDeleteService}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default TrustCenterThirdPartyTableGrid;
