// Libraries
import { useState } from "react";
import { Collapse, Drawer, Dropdown, Switch } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import dayjs, { type Dayjs } from "dayjs";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// General
import type { IDrawerAdapterProps, IFilterItem } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import { CollapsePanelHeading3 } from "#src/common/helper/antdProps/Collapse/PanelHeader";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import scansServices from "#src/services/scans";

// Children
import DomainInput, { type IScanDomainItem } from "./DomainInput";
import StartTimePopover from "./StartTimePopover";
import DaysOfWeekToggleGroup from "./DaysOfWeekToggleGroup";
import EditSettingsDrawer from "#src/components/assets/drawers/EditSettingDrawer";
import { getScheduleSummary } from "#src/components/scans/utils";
import { scanFrequencyItems } from "#src/config/filter/scan";
import { ScanFrequencyEnum } from "#src/config/filter/scan/enum";

const panelKeys = {
  domains: "domains",
  schedule: "schedule",
};

interface IAddScanDrawerProps extends IDrawerAdapterProps {
  assetInit?: Omit<IScanDomainItem, "includingSubdomains">;
  scheduleMode?: boolean;
}

/**
 * @param assetInit initial state for domain selects
 * @param scheduleMode on Schedule Mode, "On Demand" scan will be siabled
 */
const AddScanDrawer = ({
  open,
  workspaceId,
  onClose,
  onRefresh,
  assetInit,
  scheduleMode,
}: IAddScanDrawerProps) => {
  const { t } = useTranslation("scans", {
    keyPrefix: "drawer.addScan",
  });

  const defaultDomainItem: IScanDomainItem = {
    key: "0",
    id: "",
    value: "",
    address: "",
    label: "",
    includingSubdomains: false,
  };

  const [activeAuthPanel, setActiveAuthPanel] = useState<string[]>([]);
  const [selectedDomains, setSelectedDomains] = useState<IScanDomainItem[]>(
    assetInit
      ? [{ ...assetInit, includingSubdomains: false }]
      : [defaultDomainItem]
  );
  const [idCounter, setIdCounter] = useState<number>(0);
  const [frequency, setFrequency] = useState<IFilterItem>(
    scheduleMode
      ? scanFrequencyItems.find(
          (item) => item.key === ScanFrequencyEnum.DAILY
        ) || scanFrequencyItems[1]
      : scanFrequencyItems.find(
          (item) => item.key === ScanFrequencyEnum.ONCE
        ) || scanFrequencyItems[0]
  );
  const [repeatNumber, setRepeatNumber] = useState<number>(1); // repeat every 2 weeks -> repeatNumber is 2
  const [daysOfWeek, setDaysOfWeek] = useState<number[]>([]);
  const [startTime, setStartTime] = useState<Dayjs>(dayjs());

  const [editingItem, setEditingItem] = useState<IScanDomainItem | null>(null);
  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);

  const onCloseDrawerAndCleanup = () => {
    onClose();
    setSelectedDomains([defaultDomainItem]);
    setFrequency(scanFrequencyItems[0]);
    setRepeatNumber(1);
    setDaysOfWeek([]);
    setStartTime(dayjs());
  };

  const onOpenEditDrawer = (domain: IScanDomainItem) => {
    setEditingItem(domain);
    setOpenEditDrawer(true);
  };

  const onAddDefaultDomain = () => {
    setIdCounter((prev) => prev + 1);
    setSelectedDomains((prev) => [
      ...prev,
      {
        key: (idCounter + 1).toString(),
        id: "",
        value: "",
        address: "",
        label: "",
        includingSubdomains: false,
      },
    ]);
  };

  const onChangeDomain = (newDomain: IScanDomainItem) => {
    setSelectedDomains((prev) => {
      const changingDomain = prev.findIndex(
        (item) => item.key === newDomain.key
      );
      let newList = prev;
      newList[changingDomain] = newDomain;
      return [...newList];
    });
  };

  const onRemoveDomain = (removedDomain: IScanDomainItem) => {
    setSelectedDomains((prev) => {
      return prev.filter((domain) => domain.key !== removedDomain.key);
    });
  };

  const onSelectFrequency = (key: string) => {
    const chosenFrequency = scanFrequencyItems.find((item) => item.key === key);
    if (chosenFrequency) {
      setFrequency(chosenFrequency);
      setRepeatNumber(1);
      setDaysOfWeek([]);
    }
  };

  const onSelectRepeatNumber = (key: string) => {
    setRepeatNumber(+key);
  };

  const onToggleDayOfWeek = (key: number) => {
    if (daysOfWeek.includes(key)) {
      setDaysOfWeek((prev) => prev.filter((item) => item !== key));
    } else {
      setDaysOfWeek((prev) => [...prev, key]);
    }
  };

  const onChangeStartTime = (newStartTime: Dayjs) => {
    setStartTime(newStartTime);
  };

  const onClickLaunchScan = () => {
    scansServices
      .create_new_scan(workspaceId, {
        domains: selectedDomains.map((domain) => ({
          domain_id: +domain.id,
          include_subdomains: domain.includingSubdomains,
        })),
        schedule: true,
        activated_time: startTime.unix(),
        timezone: "UTC",
        // frequency items should always have value as string
        duration_type: frequency.value as string,
        repeat_number: repeatNumber,
        days: daysOfWeek.map((day) => day.toString()),
      })
      .then((response) => {
        if (response.success) {
          onRefresh();
          onCloseDrawerAndCleanup();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.addScan.fail"),
        });
      });
  };

  return (
    <>
      <Drawer
        open={open}
        onClose={onCloseDrawerAndCleanup}
        closable={false}
        title={<h2>{t("title")}</h2>}
        extra={
          <IconButton
            variant="label-grey"
            size={9}
            onClick={onCloseDrawerAndCleanup}
          >
            <CloseLine />
          </IconButton>
        }
        footer={
          <div className="flex flex-col gap-3">
            <Button
              className="w-full"
              size="large"
              onClick={onClickLaunchScan}
              disabled={selectedDomains.every(
                (domain) => domain.address === ""
              )}
            >
              {t("button.launch")}
            </Button>
            <Button
              variant="secondary"
              className="w-full"
              size="large"
              onClick={onCloseDrawerAndCleanup}
            >
              {t("button.cancel")}
            </Button>
          </div>
        }
      >
        <Collapse
          ghost
          defaultActiveKey={[panelKeys.schedule]}
          expandIcon={({ isActive }) => <Switch checked={isActive} />}
          expandIconPosition="end"
          onChange={(key) => {
            if (typeof key === "string") {
              key = [key];
            }

            setActiveAuthPanel(key);
          }}
        >
          <div className="flex flex-col gap-3 p-6 border border-b border-light-grey">
            <h4>{t("field.domains.title")}</h4>
            <div className="flex flex-col gap-6">
              {selectedDomains.map((domain, index) => (
                <DomainInput
                  key={`domain-${domain}-${index}`}
                  workspaceId={workspaceId}
                  selectedDomain={domain}
                  onChangeDomain={onChangeDomain}
                  onRemoveDomain={onRemoveDomain}
                  onOpenEditDrawer={onOpenEditDrawer}
                />
              ))}
            </div>
            <Button variant="text" size="large" onClick={onAddDefaultDomain}>
              <AddLine />
              {t("button.addDomain")}
            </Button>
          </div>
          <Collapse.Panel
            key={panelKeys.schedule}
            header={
              <CollapsePanelHeading3
                active={activeAuthPanel.includes(panelKeys.schedule)}
                title={t("panel.schedule")}
              />
            }
          >
            <div className="flex flex-col gap-6 p-6">
              <div className="flex flex-col gap-3">
                <h4>{t("field.summary")}</h4>
                <p className="font-regular-14 text-hard-grey">
                  {/* Just to make typescript happy, "frequency.value" should never be empty. */}
                  {frequency.value
                    ? getScheduleSummary(
                        true,
                        frequency.value,
                        repeatNumber,
                        dayjs(startTime).unix(),
                        daysOfWeek
                      )
                    : "Uh oh something is wrong."}
                </p>
              </div>
              <div className="flex flex-col gap-3">
                <h4>{t("field.frequency.title")}</h4>
                <Dropdown
                  menu={{
                    items: (scheduleMode
                      ? scanFrequencyItems.filter(
                          (item) => item.key !== ScanFrequencyEnum.ONCE
                        )
                      : scanFrequencyItems
                    ).map((item) => ({
                      key: item.key,
                      label: (
                        <DropdownItemLabel
                          selected={frequency.key === item.key}
                          syncWidthId="dropdown-frequency"
                        >
                          {item.getLabel()}
                        </DropdownItemLabel>
                      ),
                    })),
                    onClick: ({ key }) => {
                      onSelectFrequency(key);
                    },
                  }}
                  trigger={["click"]}
                >
                  <DropdownTriggerButton id="dropdown-frequency">
                    {frequency.getLabel()}
                    <ArrowDownSLine />
                  </DropdownTriggerButton>
                </Dropdown>
              </div>
              {frequency.key === ScanFrequencyEnum.WEEKLY ||
              frequency.key === ScanFrequencyEnum.MONTHLY ? (
                <div className="flex flex-col gap-3">
                  <h4>{t("field.repeatEvery.title")}</h4>
                  <Dropdown
                    menu={{
                      items: Array(...Array(20).keys())
                        .map((i) => ({ key: i + 1, label: `${i + 1} ` }))
                        .map((item) => ({
                          key: item.key,
                          label: (
                            <DropdownItemLabel
                              selected={repeatNumber === item.key}
                              syncWidthId="dropdown-repeatNumber"
                            >
                              {item.label +
                                (frequency.key === ScanFrequencyEnum.WEEKLY
                                  ? t("field.repeatEvery.week", {
                                      count: +item.key,
                                    })
                                  : frequency.key === ScanFrequencyEnum.MONTHLY
                                    ? t("field.repeatEvery.month", {
                                        count: +item.key,
                                      })
                                    : "")}
                            </DropdownItemLabel>
                          ),
                        })),
                      onClick: ({ key }) => {
                        onSelectRepeatNumber(key);
                      },
                    }}
                    trigger={["click"]}
                  >
                    <DropdownTriggerButton id="dropdown-repeatNumber">
                      {repeatNumber +
                        " " +
                        (frequency.key === ScanFrequencyEnum.WEEKLY
                          ? t("field.repeatEvery.week", { count: repeatNumber })
                          : frequency.key === ScanFrequencyEnum.MONTHLY
                            ? t("field.repeatEvery.month", {
                                count: repeatNumber,
                              })
                            : "")}
                      <ArrowDownSLine />
                    </DropdownTriggerButton>
                  </Dropdown>
                </div>
              ) : null}
              {frequency.key === ScanFrequencyEnum.WEEKLY ? (
                <div className="flex flex-col gap-3">
                  <h4>{t("field.repeatBy.title")}</h4>
                  <DaysOfWeekToggleGroup
                    daysOfWeek={daysOfWeek}
                    onToggleDayOfWeek={onToggleDayOfWeek}
                  />
                </div>
              ) : null}

              <div className="flex flex-col gap-3">
                <h4>{t("field.startTime")}</h4>
                <StartTimePopover
                  startTime={startTime}
                  onChangeStartTime={onChangeStartTime}
                />
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>
      </Drawer>

      {/* Settings drawer */}
      {editingItem ? (
        <EditSettingsDrawer
          selectedItemId={+editingItem.id}
          open={openEditDrawer}
          onClose={() => {
            setOpenEditDrawer(false);
          }}
          workspaceId={workspaceId}
          onRefresh={() => {}}
        />
      ) : null}
    </>
  );
};

export default AddScanDrawer;
