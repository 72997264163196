import i18next from "i18next";

const fixedTFunc = i18next.getFixedT(
  null,
  "endpoint",
  "dlp.backup.table.filter"
);

export const backupEnabledFilterItems = [
  {
    key: "yes",
    getLabel: () => (
      <span className="flex items-center gap-2">
        <span className="h-2 w-2 m-0.5 bg-success-v2 rounded-full" />
        {fixedTFunc("backupEnabled.yes")}
      </span>
    ),
    value: "1",
  },
  {
    key: "no",
    getLabel: () => (
      <span className="flex items-center gap-2">
        <span className="h-2 w-2 m-0.5 bg-error-v2 rounded-full" />
        {fixedTFunc("backupEnabled.no")}
      </span>
    ),
    value: "0",
  },
];
