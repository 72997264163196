import i18next from "i18next";
import { useCallback, useEffect, useState } from "react";
import systemService from "#src/services/system";
import { createToast } from "#src/common/system/toasts";
import constants from "#src/config/constants";
import { findLegitPathForChangingWorkspace } from "#src/layoutV2/sidebar/SettingMenu/utils";
import { generatePath } from "react-router-dom";
import { pathname } from "#src/config/pathname";

const commandChangeLang = () => {
  if (i18next.language === "en") {
    systemService.updateLanguage("vi");
  } else {
    systemService.updateLanguage("en");
  }
};

const toDemoWorkspace = () => {
  const legitPath = findLegitPathForChangingWorkspace();

  window.location.href = generatePath(legitPath ? legitPath : pathname.HOME, {
    workspaceId: "rf64hh",
  });
};

const popVersion = () => {
  createToast({
    type: "peach",
    message: "Current CSPF version:",
    detail: constants.CSPF_VERSION,
  });
};

const shortcutMap: { [name: string]: { code: string; action: Function } } = {
  CHANGE_LANG: { code: "l", action: commandChangeLang },
  TO_DEMO: { code: "rf64", action: toDemoWorkspace },
  POP_VERSION: { code: "v", action: popVersion },
} satisfies { [name: string]: { code: string; action: Function } };

const triggerSequence = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];

export const useKeyboardShortcut = () => {
  // Which step of the trigger sequence is the user at
  const [triggerStep, setTriggerStep] = useState<number>(0);
  // What command is being typed
  const [typingCode, setTypingCode] = useState<string>("");

  // When user types wrong key, reset the sequence
  const resetSequence = useCallback(() => {
    setTriggerStep(0);
    setTypingCode("");
  }, []);

  useEffect(() => {
    const onHandleKeyboardShortcut = (e: KeyboardEvent) => {
      if (triggerStep >= triggerSequence.length) {
        let shouldReset: boolean = true;
        Object.values(shortcutMap).forEach(({ code, action }) => {
          if (code.length > typingCode.length) {
            if (typingCode + e.key === code) {
              action();
              resetSequence();
              return;
            }
            if (e.key === code[typingCode.length]) {
              setTypingCode((prev) => prev + e.key);
              shouldReset = false;
            }
          }
        });
        if (shouldReset) {
          resetSequence();
        }
        return;
      }

      if (e.key === triggerSequence[triggerStep]) {
        setTriggerStep((prev) => prev + 1);
        return;
      }
      if (e.key === triggerSequence[0]) {
        setTriggerStep(1);
        return;
      }
      resetSequence();
      return;
    };

    document.addEventListener("keyup", onHandleKeyboardShortcut);

    return () => {
      document.removeEventListener("keyup", onHandleKeyboardShortcut);
    };
  }, [resetSequence, triggerStep, typingCode]);
};
