// Libraries
import { type ReactNode, useState } from "react";
import { useAppSelector } from "#src/store/hooks";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { marked } from "marked";

// Resources
import { ReactComponent as SendPlaneLine } from "#src/assets/images/icons/send-plane-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import { ObjectImage } from "#src/common/system/Object";
import { FileUploadActionValue, uploadFile } from "#src/utils/uploadFile";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import vulnerabilityCommentServices from "#src/services/vulnerabilities/comments";

// Children
import {
  COMMENT_EDITOR_TEXTAREA_ID,
  CommentTypeEnum,
  commentTypeDropdownItems,
} from "./constants";
import EditorTypeSwitch from "./TypeSwitch";
import EditorTools from "./Tools";
import EditorModeSwitch from "./ModeSwitch";
import EditorFileUploader from "./FileUploader";

interface IVulnerabilityCommentEditorProps {
  workspaceId: string;
  vulnerabilityId: number;
  refreshVulnerabilityDetail: () => void;
}

const VulnerabilityCommentEditor = ({
  workspaceId,
  vulnerabilityId,
  refreshVulnerabilityDetail,
}: IVulnerabilityCommentEditorProps) => {
  const { t } = useTranslation("vulnerabilities", {
    keyPrefix: "detailSection.commentEditor",
  });

  const [commentType, setCommentType] = useState<{
    key: string;
    getLabel: () => ReactNode;
  }>(commentTypeDropdownItems[0]);
  const [newComment, setNewComment] = useState<string>("");
  const [attachments, setAttachments] = useState<string[]>([]);

  const [mode, setMode] = useState<"write" | "preview">("write");

  const [pending, setPending] = useState<boolean>(false);

  const userInfo = useAppSelector((state) => state.auth.userInfo);

  const onUploadAttachments = (files: File[]) => {
    for (const file of files) {
      const fileType = file.type.split("/")[0];
      if (fileType === "image" || fileType === "video") {
        uploadFile(file, FileUploadActionValue.COMMENT_ATTACHMENT, {
          vuln_id: vulnerabilityId.toString(),
        })
          .then((signedResponse) => {
            setAttachments((prev) => [...prev, signedResponse.signed_url]);
            if (fileType === "image") {
              setNewComment(
                (prev) =>
                  prev +
                  `<img src="${signedResponse.signed_url}" alt="${file.name}" width="100%" height="100%" />`
              );
            }
            if (fileType === "video") {
              setNewComment(
                (prev) =>
                  prev +
                  `<video controls width="100%" height="100%"><source src="${signedResponse.signed_url}" type="${file.type}"/></video>`
              );
            }
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("notification.uploadFile.fail"),
            });
          });
      } else {
        createToast({
          type: "error",
          message: t("invalidFileType.message"),
          detail: t("invalidFileType.description"),
        });
      }
    }
  };

  const onPostNewComment = () => {
    if (commentType.key === CommentTypeEnum.COMMENT) {
      setPending(true);
      vulnerabilityCommentServices
        .create_new_comment(workspaceId, vulnerabilityId, {
          message: newComment,
          attachments: attachments,
        })
        .then(() => {
          refreshVulnerabilityDetail();
          setNewComment("");
          setAttachments([]);
          const fileInput = document.getElementById(
            "file_uploader"
          ) as HTMLInputElement;
          fileInput.value = "";
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.postComment.fail"),
          });
        })
        .finally(() => {
          setPending(false);
        });
      return;
    }
    if (commentType.key === CommentTypeEnum.NOTE) {
      setPending(true);
      vulnerabilityCommentServices
        .create_new_note(workspaceId, vulnerabilityId, {
          message: newComment,
          attachments: attachments,
        })
        .then(() => {
          refreshVulnerabilityDetail();
          setNewComment("");
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.postComment.fail"),
          });
        })
        .finally(() => {
          setPending(false);
        });
      return;
    }
  };

  return (
    <div className="xl:mt-20 mt-36 mb-20 bg-white w-full p-4 flex flex-col gap-3 rounded-md">
      <div className="flex justify-between flex-wrap">
        <div className="flex items-center gap-3">
          <ObjectImage
            data={userInfo?.avatar}
            className="h-11 w-11 min-w-[2.75rem] rounded-full"
          />
          <EditorTypeSwitch
            commentType={commentType}
            setCommentType={setCommentType}
          />
        </div>
        <div className="flex gap-6">
          <EditorTools mode={mode} />
          <EditorModeSwitch mode={mode} setMode={setMode} />
        </div>
      </div>
      {mode === "write" ? (
        <textarea
          id={COMMENT_EDITOR_TEXTAREA_ID}
          className={`resize-none w-full h-48 p-2 rounded-md outline outline-1 outline-light-grey focus:outline-primary ${
            commentType.key === CommentTypeEnum.COMMENT
              ? "bg-bright-blue"
              : commentType.key === CommentTypeEnum.NOTE
                ? "bg-support-fire-pastel"
                : ""
          }`}
          value={newComment}
          onChange={(e) => {
            setNewComment(e.target.value);
          }}
        />
      ) : (
        <div
          className="markdown-body w-full min-h-[12rem] p-2 overflow-x-auto"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(marked.parse(newComment) as string),
          }}
        />
      )}
      <div className="flex gap-3">
        <EditorFileUploader
          attachments={attachments}
          onUploadAttachments={onUploadAttachments}
        />
        <Button size="large" pending={pending} onClick={onPostNewComment}>
          <SendPlaneLine />
          {t("button.comment")}
        </Button>
      </div>
    </div>
  );
};

export default VulnerabilityCommentEditor;
