import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import {
  change_control_priority,
  create_control,
  delete_control,
  edit_control,
  get_control_details,
  list_controls,
  list_controls_shortly,
} from "./control";
import {
  list_host_policies,
  create_workspace_policy,
  delete_workspace_policy,
  delete_multiple_workspace_policy,
} from "./policies";
import {
  list_host_softwares,
  list_workspace_softwares,
  get_host_softwares_statistic,
} from "./softwares";

export enum HostAssetType {
  PERSONAL = "personal",
  COMPANY = "company_owned",
}

export interface IHostItem {
  id: number;
  name: string;
  description: string;
  created_time: number;
  updated_time: number | null;
  locked: boolean;
  public_ip: string | null;
  internal_ip: string;
  verification: boolean;
  asset_type: HostAssetType;
  last_seen: number | null;
  tags: {
    id: number;
    name: string;
  }[];
  os: {
    name: string;
    vendor: string;
    family: string;
    version: string;
    build: string;
    cpe: string;
  };
  owner: any;
  policy_compliance: string;
}

function list_hosts(
  workspace_id: string,
  params: {
    os: string | undefined;
    host_status: string | undefined;
    tag: string | undefined;
    policy_compliance: string | undefined;
    policy_compliance_id: string | undefined;
    policy_compliance_status: string | undefined;
    program_id: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IHostItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts`,
    { params }
  );
}

function list_hosts_shortly(workspace_id: string) {
  return request.get<never, IHostItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts`,
    { params: { paging: 0, strictly: 1 } }
  );
}

export interface IDeviceHostStatistics {
  total: number;
  status: { status: string; count: number }[];
  os: { os: string; count: number }[];
  policy_compliance: { policy_compliance: string; count: number }[];
}

/** @derecated Used by none */
function get_host_statistics(workspace_id: string) {
  return request.get<never, IDeviceHostStatistics>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts/statistics`
  );
}

export interface IHostDetailItem extends IHostItem {
  last_fetch: number | null;
  system: {
    cpu_type?: string;
    cpu_subtype?: string;
    cpu_brand?: string;
    cpu_physical_cores?: number;
    cpu_logical_cores?: number;
    hardware_vendor?: string;
    hardware_model?: string;
    hardware_version?: string;
    hardware_serial?: string;
    osquery_version?: string;
    cystack_version?: string;
    kernel_version?: string;
    batteries?: { cycle_count: number; health: string }[] | null;
    gigs_disk_space_available?: number;
    percent_disk_space_available?: number;
    disk_encryption_enabled?: boolean;
    memory?: number;
    mac_address?: string;
  };
  users: {
    last_found: number;
    username: string;
    type: string;
    uid: string;
    groupname: string;
    description: string;
    directory: string;
    shell: string;
  }[];
  location?: {
    city: string;
    continent_code: string;
    continent_name: string;
    country_code: string;
    country_name: string;
    dma_code: string | null;
    is_in_european_union: boolean;
    latitude: number;
    longitude: number;
    postal_code: number | null;
    region: string;
    time_zone: string;
    city_country: string;
  };
}

/** @deprecated Used by none */
function get_host_details(workspace_id: string, host_id: number) {
  return request.get<never, IHostDetailItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts/${host_id}`
  );
}

function update_host(
  workspace_id: string,
  host_id: number,
  data: { name?: string; tags?: string[]; asset_type?: string }
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts/${host_id}`,
    data
  );
}

function shutdown_host(workspace_id: string, host_id: number) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts/${host_id}/shutdown`
  );
}

function restart_host(workspace_id: string, host_id: number) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts/${host_id}/restart`
  );
}

function remote_host(
  workspace_id: string,
  host_id: number,
  data?: { close: true }
) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts/${host_id}/remote`,
    data
  );
}

function uninstall_host(workspace_id: string, host_id: number) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts/${host_id}/uninstall`
  );
}

function count_hosts(
  workspace_id: string,
  params: {
    program_id: string;
  }
) {
  return request.get<never, { count: number }>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts/count`,
    { params }
  );
}

export default {
  list_hosts,
  list_hosts_shortly,
  get_host_statistics,
  get_host_details,
  count_hosts,

  update_host,
  shutdown_host,
  restart_host,
  remote_host,
  uninstall_host,

  list_host_softwares,
  list_workspace_softwares,
  get_host_softwares_statistic,
  list_host_policies,
  create_workspace_policy,
  delete_workspace_policy,
  delete_multiple_workspace_policy,

  get_control_details,
  list_controls,
  list_controls_shortly,
  create_control,
  edit_control,
  change_control_priority,
  delete_control,
};
