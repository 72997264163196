import { generatePath, Outlet, useParams } from "react-router";
import { ContentSection, StickySection } from "#src/layouts/content";
import { useCallback, useEffect, useMemo, useState } from "react";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { useLocation } from "react-router-dom";
import HostDetailsProfileSection from "#src/components/devices/hosts/details/v2/ProfileSection";
import { LoaderV2 } from "#src/commonV2/LoadingState";
import { useTranslation } from "react-i18next";
import { endpointService } from "#src/services/endpoint";
import { IEndpointDevice } from "#src/services/endpoint/devices/types";
import { BreadcrumbV2 } from "#src/commonV2/Breadcrumb";
import { pathname } from "#src/config/pathname";

const HostDetailsLayout = () => {
  const { workspaceId, host: hostId } = useParams<"workspaceId" | "host">();
  const location = useLocation();
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- DATA ---------------

  const [hostDetails, setHostDetails] = useState<IEndpointDevice | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // --------------- METHODS ---------------

  // Fetch host details and load to state
  const fetchHostDetails = useCallback(async () => {
    if (!workspaceId || !hostId || isNaN(+hostId)) {
      return;
    }

    try {
      setIsLoading(true);
      const res = await endpointService.getDeviceDetails(workspaceId, +hostId);
      setHostDetails(res);
    } catch (error) {
      apiErrorHandler(error);
    }
    setIsLoading(false);
  }, [workspaceId, hostId]);

  // --------------- COMPUTED ---------------

  const selectedTabLabel = useMemo(() => {
    const currentPath = location.pathname.split("/").pop();
    return t(
      `breadcrumb.current.${currentPath || "overview"}` as any
    ) as string;
  }, [location.pathname, t]);

  // --------------- EFFECTS ---------------

  // Load host details on component mount
  useEffect(() => {
    fetchHostDetails();
  }, [fetchHostDetails]);

  // --------------- RENDER ---------------

  return isLoading ? (
    <div className="flex items-center justify-center h-screen w-full">
      <LoaderV2 />
    </div>
  ) : (
    <>
      {/* Header */}
      <StickySection className="flex-col">
        {/* Breadcrumb */}
        <BreadcrumbV2
          levels={[
            {
              name: t("breadcrumb.levels.endpoint"),
              path: generatePath(pathname.ENDPOINT_OVERVIEW, { workspaceId }),
            },
            {
              name: t("breadcrumb.current.devices"),
              path: generatePath(pathname.ENDPOINT_DEVICES, { workspaceId }),
            },
          ]}
          currentPage={{
            name: selectedTabLabel,
          }}
        />
        {/* Breadcrumb end */}

        {/* Host basic info */}
        {hostDetails && (
          <HostDetailsProfileSection
            details={hostDetails}
            selectedTabLabel={selectedTabLabel}
            workspaceId={workspaceId || ""}
            refreshHostDetails={fetchHostDetails}
          />
        )}
        {/* Host basic info end */}
      </StickySection>
      {/* Header end */}

      {/* Content */}
      <ContentSection className="v2">
        <Outlet context={{ hostDetails, workspaceId, hostId }} />
      </ContentSection>
      {/* Content end */}
    </>
  );
};

export default HostDetailsLayout;
