import { Modal } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

interface IRawCookieModalProps {
  open: boolean;
  onClose: () => void;
  rawCookie: string;
}

const RawCookieModal = ({ open, onClose, rawCookie }: IRawCookieModalProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "foundLeaks.tabs.cookie.rawCookieModal",
  });

  const onCloseAndCleanup = () => {
    onClose();
  };

  const reformatRawCookieWithTab = rawCookie
    .replaceAll("\\t", "\t")
    // if the character is escaped then we return the original
    .replaceAll("\\\t", "\\\\t");

  return (
    <Modal
      open={open}
      onCancel={onCloseAndCleanup}
      title={<h2>{t("title")}</h2>}
      centered
      footer={null}
      width="44rem"
    >
      <div className="bg-bright-blue mt-6 p-6 rounded-md break-all whitespace-pre-wrap overflow-hidden">
        <code>{reformatRawCookieWithTab}</code>
      </div>
    </Modal>
  );
};

export default RawCookieModal;
