import { forwardRef, type HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import { ChevronSelectorVertical } from "@untitled-ui/icons-react";
import { ReactComponent as ChevronSelectorVerticalUp } from "#src/assets/images/v2/chevron-selector-vertical-up.svg";
import { ReactComponent as ChevronSelectorVerticalDown } from "#src/assets/images/v2/chevron-selector-vertical-down.svg";

interface ITableHeaderCellProps extends HTMLAttributes<HTMLDivElement> {
  align?: "left" | "center" | "right";
  sort?: -1 | 0 | 1;
}

export const TableHeaderCellV2 = forwardRef<
  HTMLDivElement,
  ITableHeaderCellProps
>(({ align = "left", sort, ...props }, ref) => {
  const tailwindAlign =
    align === "left"
      ? "justify-start text-left"
      : align === "right"
        ? "justify-end text-right"
        : "justify-center text-center";

  return (
    <div
      {...props}
      ref={ref}
      className={twMerge(
        `bg-white text-xs font-semibold text-gray-v2 border-b border-gray-v2-200 p-3 flex items-center gap-1 ${
          sort !== undefined ? "cursor-pointer" : ""
        } ${tailwindAlign}`,
        props.className
      )}
    >
      {props.children}
      {sort === undefined ? null : (
        <div className="flex flex-col items-center">
          {sort === 1 ? (
            <ChevronSelectorVerticalUp className="h-3.5 w-3.5" />
          ) : sort === -1 ? (
            <ChevronSelectorVerticalDown className="h-3.5 w-3.5" />
          ) : (
            <ChevronSelectorVertical className="h-3.5 w-3.5" />
          )}
        </div>
      )}
    </div>
  );
});

TableHeaderCellV2.displayName = "TableHeaderCellV2";
