import { AxiosError } from "axios";
import { z } from "zod";
import { getLoginUrl } from "./common";
import { createToast } from "#src/common/system/toasts";

export const apiErrorHandler = (
  error: unknown,
  options?: { toastMessage?: string }
): never => {
  // Error 400 should be catched before this handler, and proceed specifically based on the form
  if (error instanceof AxiosError) {
    if (error.response) {
      if (error.response.status === 401) {
        // Redirect to login page
        // TODO: might want to turn this into a common util function
        // No toast since this toast doesn't provide any valuable information for user to take any actions.
        // createToast({
        //   type: "info",
        //   message: "Invalid token",
        //   detail: "Redirecting...",
        // });
        window.location.assign(getLoginUrl());
      } else {
        // TODO: verify with zod: error.response.data should have the shape of { code: string, message: string }
        createToast({
          type: "error",
          message: options?.toastMessage
            ? options.toastMessage
            : "Error code: " + error.response.data.code,
          detail: error.response.data.message,
        });
      }
    }
  }
  // TODO: do something when returning 403, 404, 500, 502, ...
  throw error;
};

export const parseBadRequest = <T extends z.ZodTypeAny>(
  response: unknown,
  schema: T
) => {
  return z
    .object({
      code: z.string(),
      details: schema,
      message: z.string(),
    })
    .parse(response);
};
