// Libraries
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import { FilterDropdown, SearchBox } from "#src/common/Table/Filter";
import TimePopover from "#src/common/TimePopover";
import { TimeConditionEnum } from "#src/common/TimePopover/TimePopoverContent";
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";

// API-related
import resourceServices from "#src/services/resource";
import { trustCenterPolicyStatusItems } from "#src/config/filter/trustCenter";

interface ITrustCenterPolicyFilterProps {
  compliance: IFilterItem | null;
  status: IFilterItem | null;
  searchKeyword: string;
  onChangeComplianceSelection: (selected: IFilterItem | null) => void;
  onChangeStatusSelection: (selected: IFilterItem | null) => void;
  onChangeTimeConditionValue: (
    condition: string,
    from: number | null,
    to: number | null
  ) => void;
  onChangeSearchKeyword: (keyword: string) => void;
}

const TrustCenterPolicyFilter = ({
  compliance,
  status,
  searchKeyword,
  onChangeComplianceSelection,
  onChangeStatusSelection,
  onChangeTimeConditionValue,
  onChangeSearchKeyword,
}: ITrustCenterPolicyFilterProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "policy.filter",
  });

  const locale = i18next.language;

  const [timeCondition, setTimeCondition] = useState<TimeConditionEnum | null>(
    null
  );
  const [timePopoverOpen, setTimePopoverOpen] = useState<boolean>(false);
  const [timeFilterText, setTimeFilterText] = useState<string>("");

  const onChangeTimeCondition = (
    text: string,
    condition: TimeConditionEnum | null,
    from: number | null,
    to: number | null
  ) => {
    setTimeCondition(condition);
    setTimeFilterText(text);
    onChangeTimeConditionValue(text, from, to);
  };

  const uselessParam = useMemo(() => [], []);

  const { list: complianceList } = useFetchNoPaging(
    resourceServices.list_trust_center_supported_compliance,
    uselessParam
  );

  const complianceItems = complianceList
    ? complianceList.map((item) => ({
        key: item.id,
        value: item.id,
        getLabel: () => (locale === "vi" ? item.name.vi : item.name.en),
      }))
    : [];

  return (
    <>
      <div className="flex justify-between gap-1">
        <div className="flex gap-1 whitespace-nowrap flex-wrap">
          <FilterDropdown
            label={t("compliance.label")}
            selected={compliance}
            options={complianceItems}
            onChangeSelection={onChangeComplianceSelection}
          />
          <FilterDropdown
            label={t("status.label")}
            selected={status}
            options={trustCenterPolicyStatusItems}
            onChangeSelection={onChangeStatusSelection}
          />
          <TimePopover
            label={t("createdDate.label")}
            timeCondition={timeCondition}
            textDisplay={timeFilterText}
            open={timePopoverOpen}
            setOpen={(value) => setTimePopoverOpen(value)}
            onChangeTime={onChangeTimeCondition}
          />
        </div>

        <SearchBox
          searchKeyword={searchKeyword}
          onChangeSearchKeyword={onChangeSearchKeyword}
        />
      </div>
    </>
  );
};

export default TrustCenterPolicyFilter;
