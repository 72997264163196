// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import global from "#src/config/global";
import type { IFilterItem } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import TimePopover from "#src/common/TimePopover";
import type { TimeConditionEnum } from "#src/common/TimePopover/TimePopoverContent";
import {
  FilterDropdown,
  SearchBox,
  SelectAllItemsDropdown,
} from "#src/common/Table/Filter";

// API-related
import { assetVulnerableStatusItems } from "#src/config/filter/asset";

interface ISubdomainFilterProps {
  searchKeyword: string;
  vulnerable: IFilterItem | null;
  totalCount: number;
  selectedCount: number;
  onChangeTimeConditionValue: (
    condition: string,
    from: number,
    to: number
  ) => void;
  onChangeVulnerableSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
  onSelectAllSubdomains: () => void;
  onDeselectAllSubdomains: () => void;
  onDeleteSelectedSubdomains: () => void;
  onOpenAddSubdomainDrawer: () => void;
}

const SubdomainFilter = ({
  searchKeyword,
  vulnerable,
  totalCount,
  selectedCount,
  onChangeTimeConditionValue,
  onChangeVulnerableSelection,
  onChangeSearchKeyword,
  onSelectAllSubdomains,
  onDeselectAllSubdomains,
  onDeleteSelectedSubdomains,
  onOpenAddSubdomainDrawer,
}: ISubdomainFilterProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.subdomains.filter",
  });

  const [timeCondition, setTimeCondition] = useState<TimeConditionEnum | null>(
    null
  );
  const [timePopoverOpen, setTimePopoverOpen] = useState<boolean>(false);
  const [timeFilterText, setTimeFilterText] = useState<string>("");

  const onChangeTimeCondition = (
    text: string,
    condition: TimeConditionEnum,
    from: number,
    to: number
  ) => {
    setTimeCondition(condition);
    setTimeFilterText(text);
    onChangeTimeConditionValue(text, from, to);
  };

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <SelectAllItemsDropdown
          selectedCount={selectedCount}
          totalCount={totalCount}
          onSelectAll={onSelectAllSubdomains}
          onDeselectAll={onDeselectAllSubdomains}
        />
        <IconButton
          variant="ghost"
          disabled={selectedCount === 0}
          onClick={() => {
            global.confirm(() => onDeleteSelectedSubdomains());
          }}
        >
          <DeleteBin6Line />
        </IconButton>
        <TimePopover
          label={t("timePopover.label")}
          timeCondition={timeCondition}
          textDisplay={timeFilterText}
          open={timePopoverOpen}
          setOpen={(value) => setTimePopoverOpen(value)}
          onChangeTime={onChangeTimeCondition}
        />
        <FilterDropdown
          label={t("vulnerable.label")}
          selected={vulnerable}
          options={assetVulnerableStatusItems}
          onChangeSelection={onChangeVulnerableSelection}
        />
      </div>

      <div className="flex gap-2 h-fit">
        <Button variant="text" size="large" onClick={onOpenAddSubdomainDrawer}>
          <AddLine />
          {t("button.addSubdomain")}
        </Button>
        <SearchBox
          searchKeyword={searchKeyword}
          onChangeSearchKeyword={onChangeSearchKeyword}
        />
      </div>
    </div>
  );
};

export default SubdomainFilter;
