// Libraries
import { useTranslation } from "react-i18next";
import { useState } from "react";
import i18next from "i18next";

// Components
import { TableHeader } from "#src/common/Table";
import type { ITableHeaderPropItem } from "#src/common/Table/TableHeader";
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import { SourceLabel } from "#src/common/helper/labels/SourceLabel";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";
import { ToolCard } from "#src/common/ToolCard";
import { Modal } from "#src/common/system/Modal";

// API-related
import type { ITrustCenterControlItem } from "#src/services/trustCenter/control";

// Children
import TrustCenterControlTableRow from "./TableRow";

interface ITrustCenterControlTableGridProps {
  workspaceId: string;
  controlList: any[] | null;
  isLoading: boolean;
  onRefresh: () => void;
}

const TrustCenterControlTableGrid = ({
  workspaceId,
  controlList,
  isLoading,
  onRefresh,
}: ITrustCenterControlTableGridProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "control" });

  const locale = i18next.language;

  const [showingRefs, setShowingRefs] = useState<
    ITrustCenterControlItem["refs"] | null
  >(null);
  const [showingGuidance, setShowingGuidance] =
    useState<ITrustCenterControlItem | null>(null);

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.category"), align: "left" },
    { name: t("headers.references"), align: "left" },
    { name: t("headers.compliance"), align: "left" },
  ];

  const onClickShowGuidance = (item: ITrustCenterControlItem) => {
    setShowingGuidance(item);
  };

  const onClickShowRefs = (refs: ITrustCenterControlItem["refs"]) => {
    setShowingRefs(refs);
  };

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  return (
    <>
      {isLoading || controlList === null ? (
        <LoadingState />
      ) : controlList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[5fr_4fr_2fr]">
          <TableHeader title="trustCenter-control" headerProps={headers} />
          {controlList.map((item) => (
            <TrustCenterControlTableRow
              key={`trustCenter/control-tr${item.id}`}
              workspaceId={workspaceId}
              data={item}
              workspaceRole={currentWorkspace?.role}
              onRefresh={onRefresh}
              onClickShowRefs={onClickShowRefs}
              onClickShowGuidance={onClickShowGuidance}
            />
          ))}
        </div>
      )}
      <Modal
        open={showingGuidance !== null}
        onChangeOpen={() => {
          setShowingGuidance(null);
        }}
      >
        <div className="flex flex-col gap-6">
          <div className="font-medium-24">
            {locale === "vi"
              ? showingGuidance?.procedure.name.vi
              : showingGuidance?.procedure.name.en}
          </div>
          <div className="flex flex-col gap-3">
            <p className="font-medium-18">{t("modal.summary")}</p>
            <p className="font-regular-14-body text-hard-grey">
              {locale === "vi"
                ? showingGuidance?.procedure.summary.vi
                : showingGuidance?.procedure.summary.en}
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="font-medium-18">{t("modal.documents")}</p>
            <div className="pl-6">
              <ul className="list-disc font-regular-14-body text-hard-grey">
                {(locale === "vi"
                  ? showingGuidance?.procedure.docs.vi
                  : showingGuidance?.procedure.docs.en
                )?.map((doc, index) => <li key={index}>{doc}</li>)}
              </ul>
            </div>
          </div>
          {(showingGuidance?.procedure?.tools || []).length > 0 && (
            <div className="flex flex-col gap-3">
              <p className="font-medium-18">{t("modal.suggestedSolutions")}</p>
              {showingGuidance?.procedure.tools?.map((tool, index: number) => (
                <ToolCard key={index} tool={tool} />
              ))}
            </div>
          )}
        </div>
      </Modal>
      <Modal
        open={showingRefs !== null}
        onChangeOpen={() => {
          setShowingRefs(null);
        }}
      >
        <div className="flex flex-wrap gap-2">
          {showingRefs?.map((ref) => (
            <SourceLabel
              key={`trustCenter-control-refList-${ref.id}`}
              noEllipsis
            >
              {ref.id}
            </SourceLabel>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default TrustCenterControlTableGrid;
