// Libraries
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@lockerpm/design";
import dayjs from "dayjs";

// Resources
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";

// Components
import { SourceLabel } from "#src/common/helper/labels/SourceLabel";
import { StatusLabel } from "#src/common/helper/labels/StatusLabel";
import TableDataCell from "#src/common/Table/TableDataCell";
import { TableRowWrapperDiv } from "#src/common/Table/TableRowWrapper";

// API-related
import {
  ContractStatusValue,
  ContractTypeValue,
} from "#src/config/filter/workspace/value";
import paymentService from "#src/services/payment";

interface IBillingContractTableRowProps {
  data: Awaited<
    ReturnType<typeof paymentService.list_contracts>
  >["results"][number];
}

const BillingContractTableRow = ({ data }: IBillingContractTableRowProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.tab.contract",
  });

  const locale = i18next.language;

  return (
    <TableRowWrapperDiv>
      <TableDataCell>
        <Tooltip title={data.id}>
          <div>
            <SourceLabel noEllipsis>{data.id.split("-").slice(-1)}</SourceLabel>
          </div>
        </Tooltip>
      </TableDataCell>
      <TableDataCell>
        <Tooltip title={data.payment_id}>
          <div>
            <SourceLabel noEllipsis>{data.payment_id.slice(-6)}</SourceLabel>
          </div>
        </Tooltip>
      </TableDataCell>
      <TableDataCell>
        {locale === "vi" ? data.product.name.vi : data.product.name.en}
      </TableDataCell>
      <TableDataCell>
        {data.contract_type === ContractTypeValue.DIRECT
          ? t("labels.contractType.direct")
          : data.contract_type === ContractTypeValue.ESIGN
            ? t("labels.contractType.esign")
            : null}
      </TableDataCell>
      <TableDataCell>
        {data.signed_time
          ? dayjs.unix(data.signed_time).format("DD.MM.YYYY")
          : "-"}
      </TableDataCell>
      <TableDataCell>
        {data.status === ContractStatusValue.NOT_SIGNED ? (
          <Tooltip
            title={
              <span className="font-medium-12">
                {t("contactSupportForUnsigned")}
              </span>
            }
          >
            {/* Antd Tooltip is not smart enough to accept custom React components. */}
            <span>
              <StatusLabel
                name={t("labels.contractStatus.notSigned")}
                color="bg-warning-red-10% text-warning-red-danger"
                icon={<InformationLine className="h-4 w-4 min-w-[1rem]" />}
              />
            </span>
          </Tooltip>
        ) : data.status === ContractStatusValue.SIGNED ? (
          <StatusLabel
            name={t("labels.contractStatus.signed")}
            color="bg-support-seafoam-pastel text-support-seafoam"
            icon={<CheckboxCircleLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : null}
      </TableDataCell>
    </TableRowWrapperDiv>
  );
};

export default BillingContractTableRow;
