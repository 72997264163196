// Libraries
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

// Resources
import { ReactComponent as SuccessIllustration } from "#src/assets/images/illustrations/success.svg";

// Components
import { Button } from "#src/common/system/Button";
import { Modal } from "#src/common/system/Modal";

interface PaymentSuccessModalProps {
  workspaceId: string;
  open: boolean;
  onChangeOpen: (value: boolean) => void;
  settingPath: string;
  paymentType?: string;
}

const PaymentSuccessModal = ({
  workspaceId,
  open,
  onChangeOpen,
  settingPath,
  paymentType
}: PaymentSuccessModalProps) => {
  const translationKey = paymentType === 'addKeyword' ? "dataLeak" : "trustCenter";
  const keyPrefix = paymentType === 'addKeyword' ? "addKeyword.payment.successModal" : "registerDomain.payment.successModal";

  const { t } = useTranslation(translationKey, {
    keyPrefix: keyPrefix,
  })

  const navigate = useNavigate();

  return (
    <Modal open={open} onChangeOpen={onChangeOpen}>
      <div className="flex flex-col gap-6 items-center">
        <div className="flex flex-col gap-3 items-center text-center">
          <SuccessIllustration />
          <h2>{t("paymentSuccess")}</h2>
          <span className="font-regular-16-body">{t("instruction")}</span>
        </div>
        <Button
          size="large"
          className="w-fit"
          onClick={() => {
            navigate(
              generatePath(settingPath, {
                workspaceId,
              })
            );
          }}
        >
          {t("button.backToConfig")}
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentSuccessModal;
