// Libraries
import { Modal } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as FileCopyLine } from "#src/assets/images/icons/file-copy-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import { createToast } from "#src/common/system/toasts";

// API-related
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";

interface IDnsInstructionModalProps {
  open: boolean;
  onClose: () => void;
  domain: ITrustCenterConfigDetails["domain"];
  verificationInfo: ITrustCenterConfigDetails["domain_verification"];
}

const DnsInstructionModal = ({
  open,
  onClose,
  domain,
  verificationInfo,
}: IDnsInstructionModalProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.dnsInstructionModal",
  });

  const onCloseAndCleanup = () => {
    onClose();
  };

  const steps = [t("steps.step1", { domain: domain }), t("steps.step2")];

  return (
    <Modal
      open={open}
      onCancel={onCloseAndCleanup}
      title={<h2>{t("title")}</h2>}
      centered
      footer={null}
      width="44rem"
    >
      <div className="flex flex-col pt-6 gap-6">
        <div className="flex flex-col gap-1">
          {steps.map((step, index) => (
            <div
              key={`domainVerification-step${index}`}
              className="bg-bright-grey px-6 py-3 flex items-center gap-3"
            >
              <div className="bg-dark-blue text-white rounded-xl h-9 w-9 font-bold-16 flex items-center justify-center">
                {index + 1}
              </div>
              <span>{step}</span>
            </div>
          ))}
        </div>
        {verificationInfo.map((item) => (
          <div
            key={`domainVerification-record-${item.host}`}
            className="px-6 flex flex-col gap-6"
          >
            <span className="font-bold-16 text-primary">{item.host}</span>
            <div className="flex flex-col gap-3">
              <div className="flex gap-6">
                <div className="w-[4.5rem] text-hard-grey">{t("type")}</div>
                <span>{item.type}</span>
              </div>
              <div className="flex justify-between gap-2">
                <div className="flex gap-6">
                  <div className="w-[4.5rem] text-hard-grey">{t("value")}</div>
                  <span>{item.value}</span>
                </div>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(item.value);
                    createToast({
                      type: "info",
                      message: t("copied.message"),
                      detail: item.value,
                    });
                  }}
                  className="text-hard-grey"
                >
                  <FileCopyLine />
                </button>
              </div>
              <div className="flex gap-6">
                <div className="w-[4.5rem] text-hard-grey">{t("ttl")}</div>
                <span>{item.TTL}</span>
              </div>
            </div>
          </div>
        ))}
        <div className="flex justify-end gap-1">
          <Button onClick={onCloseAndCleanup}>{t("button.done")}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default DnsInstructionModal;
