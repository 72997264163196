import { pathname } from "#src/config/pathname";
import { matchPath } from "react-router-dom";

/** Changing workspace will trigger a redirect. With some paths, this will cause invalid parameters, so we want to remove all other params except workspaceId. */
export const findLegitPathForChangingWorkspace = () => {
  // We will remove params on current location one by one
  let splittedLocationPath = location.pathname.split("/");

  while (splittedLocationPath.length > 0) {
    const matched = Object.values(pathname).find((path) =>
      matchPath(path, splittedLocationPath.join("/"))
    );

    // Check if matched pattern have any params that is not workspaceId
    if (
      matched &&
      matched
        .split("/")
        // Remove workspaceId from the check
        .slice(2)
        .every((part) => !part.includes(":"))
    ) {
      // If there's no other params, we can return this path as result
      return matched;
    }

    splittedLocationPath.pop();
  }
  return null;
};
