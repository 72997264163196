import { Modal, Table, Popconfirm } from "@lockerpm/design";
import { useState, useCallback, useEffect, SetStateAction } from "react";
import dlpService from "#src/services/endpoint/dlp";
import { IDLPDrive, IDLPSnapshot } from "#src/services/endpoint/dlp/types";
import { ButtonV2 } from "#src/commonV2/Button";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { DLPDriveBackupStatus } from "#src/services/endpoint/dlp/enums";
import { createToast } from "#src/common/system/toasts";
import { useTranslation } from "react-i18next";
import Trash01 from "@untitled-ui/icons-react/build/esm/Trash01";
import { byteToGigabyte } from "#src/utils/common";
import { LoaderV2 } from "#src/commonV2/LoadingState";

type Props = {
  isOpen: boolean;
  workspaceId: string;
  driveId?: number;
  driveStatus?: DLPDriveBackupStatus;
  closeModal: () => void;
  setDriveList: (value: SetStateAction<IDLPDrive[]>) => void;
  setSelectedDrive: (value: SetStateAction<IDLPDrive>) => void;
};

const ManageSnapshotsModal = (props: Props) => {
  const {
    isOpen,
    workspaceId,
    driveId,
    driveStatus,
    closeModal,
    setDriveList,
    setSelectedDrive,
  } = props;

  const { t } = useTranslation("dlp");

  // --------------- DATA ---------------

  // Snapshot
  const [snapshots, setSnapshots] = useState<IDLPSnapshot[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  // Action
  const [restoringSnapshotId, setRestoringSnapshotId] = useState("");
  const [deletetingSnapshotId, setDeletetingSnapshotId] = useState("");

  // --------------- COMPUTED ---------------

  const isDrivePendingRestore =
    driveStatus === DLPDriveBackupStatus.PENDING_RESTORE;
  const isDriveRestoring = driveStatus === DLPDriveBackupStatus.RESTORING;

  // --------------- METHODS ---------------

  // Load snapshots list
  const loadSnapshots = useCallback(async () => {
    if (!workspaceId || !driveId || isDrivePendingRestore) {
      setIsLoadingData(false);
      return;
    }

    try {
      const res = await dlpService.listDLPSnapshot(workspaceId, driveId, {
        page: 1,
        q: "",
        size: 10,
      });
      setSnapshots(res.results);
    } catch (error) {
      apiErrorHandler(error);
    }

    setIsLoadingData(false);
  }, [workspaceId, driveId, isDrivePendingRestore]);

  // Send restore request
  const sendRestoreRequest = useCallback(
    async (snapshotId: string) => {
      if (!driveId) {
        return;
      }

      setRestoringSnapshotId(snapshotId);

      try {
        await dlpService.restoreDLPSnapshot(workspaceId, driveId, snapshotId);

        // Update status of drive in list
        setDriveList((prev) =>
          prev.map((item) => {
            if (item.id === driveId) {
              return {
                ...item,
                backup_status: DLPDriveBackupStatus.PENDING_RESTORE,
              };
            }
            return item;
          })
        );

        // Update status of selected drive (if match)
        setSelectedDrive((prev) => {
          if (!prev || prev.id !== driveId) {
            return prev;
          }
          return {
            ...prev,
            backup_status: DLPDriveBackupStatus.PENDING_RESTORE,
          };
        });
      } catch (error) {
        apiErrorHandler(error);
      }

      setRestoringSnapshotId("");
    },
    [workspaceId, driveId, setDriveList, setSelectedDrive]
  );

  // Delete snapshot
  const deleteSnapshot = useCallback(
    async (snapshotId: string) => {
      if (!driveId) {
        return;
      }

      setDeletetingSnapshotId(snapshotId);
      try {
        await dlpService.deleteDLPSnapshot(workspaceId, driveId, snapshotId);
        setSnapshots((prev) => prev.filter((item) => item.id !== snapshotId));
        createToast({
          type: "success",
          message: t("snapshotsModal.deleteSuccess"),
        });
      } catch (error) {
        apiErrorHandler(error);
      }
      setDeletetingSnapshotId("");
    },
    [workspaceId, driveId, t]
  );

  // --------------- EFFECTS ---------------

  useEffect(() => {
    let timeout: any = null;
    if (isOpen) {
      setIsLoadingData(true);
      timeout = setTimeout(loadSnapshots, 200);
    }

    return () => {
      setIsLoadingData(false);
      setSnapshots([]);
      setRestoringSnapshotId("");
      setDeletetingSnapshotId("");
      clearTimeout(timeout);
    };
  }, [isOpen, loadSnapshots]);

  // --------------- RENDER ---------------

  return (
    <Modal
      title={
        isDrivePendingRestore
          ? t("snapshotsModal.restore.title")
          : t("snapshotsModal.title")
      }
      open={isOpen}
      footer={null}
      maskClosable={false}
      onCancel={closeModal}
      className="v2 max-w-[600px]"
      centered
      width="90%"
    >
      <div className="v2">
        {/* If: Awaiting confirmation */}
        {isDrivePendingRestore && (
          <>
            <p className="text-md text-gray-v2-600 mb-3">
              {t("snapshotsModal.restore.desc1")}
            </p>
            <p className="text-md text-gray-v2-600">
              {t("snapshotsModal.restore.desc2")}
            </p>
            <div className="flex justify-center mt-5">
              <LoaderV2 baseSize={3} />
            </div>
            <div className="flex justify-end mt-5">
              <ButtonV2
                variant="secondary"
                size="lg"
                color="gray"
                onClick={closeModal}
              >
                <p>{t("snapshotsModal.restore.btn")}</p>
              </ButtonV2>
            </div>
          </>
        )}
        {/* Awaiting confirmation end */}

        {/* Else: Select snapshot */}
        {!isDrivePendingRestore && (
          <>
            <p className="text-md text-gray-v2-600 mb-5">
              {isDriveRestoring
                ? t("snapshotsModal.desc_restoring")
                : t("snapshotsModal.desc")}
            </p>

            {/* Table */}
            <Table<IDLPSnapshot>
              className="border border-gray-v2-100 rounded-xl overflow-hidden"
              loading={isLoadingData}
              dataSource={snapshots}
              rowKey={(record) => record.id}
              pagination={false}
              size="small"
              columns={[
                {
                  title: (
                    <p className="text-xs font-semibold text-gray-v2-500">
                      {t("snapshotsModal.version")}
                    </p>
                  ),
                  key: "version",
                  render(_, record) {
                    return (
                      <p className="text-sm text-gray-v2-600">{record.name}</p>
                    );
                  },
                },
                {
                  title: (
                    <p className="text-xs font-semibold text-gray-v2-500">
                      {t("snapshotsModal.size")}
                    </p>
                  ),
                  key: "size",
                  render(_, record) {
                    return (
                      <p className="text-sm text-gray-v2-600">
                        {byteToGigabyte(record.size).toFixed(1)}
                      </p>
                    );
                  },
                },
                {
                  key: "action",
                  render: (_, record) => {
                    // Dont allow restore if the snapshot is deleting/pending restore or the drive is restoring
                    const isRestoreDisabled =
                      isDriveRestoring ||
                      (restoringSnapshotId &&
                        restoringSnapshotId !== record.id) ||
                      deletetingSnapshotId === record.id;

                    // Dont allow delete if the snapshot is waiting to be restored, or the drive is restoring
                    const isDeleteDisabled =
                      isDriveRestoring || restoringSnapshotId === record.id;

                    return (
                      <div className="flex gap-2 justify-end">
                        {/* Restore */}
                        <ButtonV2
                          size="sm"
                          variant="secondary"
                          color="brand"
                          loadingStyle="overlay"
                          onClick={() => sendRestoreRequest(record.id)}
                          pending={restoringSnapshotId === record.id}
                          disabled={isRestoreDisabled}
                        >
                          <p>{t("common.restore")}</p>
                        </ButtonV2>
                        {/* Restore end */}

                        {/* Delete */}
                        <Popconfirm
                          title={
                            <p className="text-md font-semibold text-gray-v2-900">
                              {t("snapshotsModal.confirmDelete.title")}
                            </p>
                          }
                          description={
                            <p className="text-md text-gray-v2-600 mb-2">
                              {t("snapshotsModal.confirmDelete.desc")}
                            </p>
                          }
                          cancelText={t("common.cancel")}
                          okText={t("common.confirm")}
                          icon={null}
                          okType="danger"
                          onConfirm={() => {
                            deleteSnapshot(record.id);
                            return;
                          }}
                        >
                          <ButtonV2
                            size="sm"
                            variant="secondary"
                            color="error"
                            loadingStyle="overlay"
                            pending={deletetingSnapshotId === record.id}
                            disabled={isDeleteDisabled}
                          >
                            <Trash01 className="text-error-v2-700 h-4 w-4" />
                          </ButtonV2>
                        </Popconfirm>
                        {/* Delete end */}
                      </div>
                    );
                  },
                },
              ]}
            />
            {/* Table end */}
          </>
        )}
        {/* Select snapshot end */}
      </div>
    </Modal>
  );
};

export default ManageSnapshotsModal;
