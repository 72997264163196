import type { RouteObject } from "react-router-dom";
import i18next from "i18next";
import { helperPathname, pathname } from "#src/config/pathname";

import AccountSettingPage from "#src/pages/accountSettings";
import NotificationSettingPage from "#src/pages/accountSettings/notification";

const breadcrumbTFunc = i18next.getFixedT(null, "layout", "topbar.breadcrumb");

export const accountRoute: RouteObject = {
  path: helperPathname.ACCOUNT,
  children: [
    {
      path: pathname.ACCOUNT_SETTINGS,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("accountSettings.label"),
          path: pathname.ACCOUNT_SETTINGS,
        }),
      },
      element: <AccountSettingPage />,
    },
    {
      path: pathname.NOTIFICATION,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("notification.label"),
          path: pathname.NOTIFICATION,
        }),
      },
      element: <NotificationSettingPage />,
    },
  ],
};
