// Libraries
import { type ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";
import { useDebouncedCallback } from "use-debounce";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as Settings4Line } from "#src/assets/images/icons/settings-4-line.svg";

// General
import urls from "#src/config/urls";
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import { IconButton } from "#src/common/system/Button";
import { ObjectImage } from "#src/common/system/Object";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import assetsServices from "#src/services/assets";

export interface IScanDomainItem {
  key: string;
  id: string;
  value: string;
  address: string;
  label: ReactNode;
  includingSubdomains: boolean;
}

interface IFilterAssetItem extends IFilterItem {
  address: string;
}

interface IDomainInputProps {
  workspaceId: string;
  selectedDomain: IScanDomainItem;
  onChangeDomain: (domain: IScanDomainItem) => void;
  onRemoveDomain: (domain: IScanDomainItem) => void;
  onOpenEditDrawer: (domain: IScanDomainItem) => void;
  disabled?: boolean;
}

const DomainInput = ({
  workspaceId,
  selectedDomain,
  onChangeDomain,
  onRemoveDomain,
  onOpenEditDrawer,
  disabled,
}: IDomainInputProps) => {
  const { t } = useTranslation("scans", {
    keyPrefix: "drawer.addScan",
  });

  const [open, setOpen] = useState<boolean>(false);

  const [domainList, setDomainList] = useState<IFilterAssetItem[]>([]);

  const fetchDomainList = useCallback(
    (keyword: string | null) => {
      if (workspaceId) {
        assetsServices
          .list_domains_shortly(workspaceId, {
            from: undefined,
            to: undefined,
            vulnerable: undefined,
            verification: undefined,
            q: keyword ? keyword : undefined,
          })
          .then((response) => {
            setDomainList(
              response.map((domain) => ({
                key: domain.id.toString(),
                value: domain.id.toString(),
                getLabel: () => domain.name,
                address: domain.detail.address,
              }))
            );
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("notification.loadDomainList.fail"),
            });
          });
      }
    },
    [workspaceId, t]
  );

  const debouncedFetchDomainList = useDebouncedCallback(
    fetchDomainList,
    constants.DEBOUNCE_TIME
  );

  const onChangeKeyword = (keyword: string) => {
    debouncedFetchDomainList(keyword);
  };

  const onChooseDomain = (key: string) => {
    const chosenDomain = domainList.find((domain) => domain.key === key);

    if (chosenDomain && chosenDomain.value) {
      onChangeDomain({
        key: selectedDomain.key,
        id: chosenDomain.key,
        value: chosenDomain.value,
        address: chosenDomain.address,
        label: chosenDomain.getLabel(),
        includingSubdomains: false,
      });
    }
  };

  useEffect(() => {
    let ignore = false;

    // WARNING: this may cause problem if we can toggle the disabled option
    if (!ignore && !disabled) {
      fetchDomainList(null);
    }

    return () => {
      ignore = true;
    };
  }, [fetchDomainList, disabled]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-1">
        <Dropdown
          open={open}
          onOpenChange={(currentOpen) => {
            setOpen(currentOpen);
          }}
          menu={{
            items: domainList.map((domain) => ({
              key: domain.key,
              label: (
                <DropdownItemLabel selected={domain.key === selectedDomain.id}>
                  {domain.value ? (
                    <ObjectImage
                      data={
                        urls.IMAGE_LOGO_SERVER_URL.replace(
                          ":address",
                          domain.address
                        ) + "?size=36&v=1"
                      }
                      className="h-6 w-6 min-w-[1.5rem]"
                    />
                  ) : null}
                  {domain.getLabel()}
                </DropdownItemLabel>
              ),
            })),
            onClick: ({ key }) => {
              onChooseDomain(key);
              setOpen(false);
            },
          }}
          trigger={["click"]}
          disabled={disabled}
        >
          <div
            className={`flex p-2 text-left items-center justify-between ${
              open ? "outline outline-2 outline-primary" : "bg-bright-grey"
            } rounded-md w-full`}
          >
            <div className="flex items-center gap-2">
              {selectedDomain.address ? (
                <ObjectImage
                  data={
                    urls.IMAGE_LOGO_SERVER_URL.replace(
                      ":address",
                      selectedDomain.address
                    ) + "?size=36&v=1"
                  }
                  className="h-6 w-6 min-w-[1.5rem]"
                />
              ) : null}
              {open ? (
                <input
                  className="w-full outline-none caret-primary bg-transparent"
                  autoFocus
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onChangeKeyword(e.currentTarget.value);
                  }}
                />
              ) : (
                <span
                  className={
                    selectedDomain.label ? "text-primary" : "text-medium-grey"
                  }
                >
                  {selectedDomain.label
                    ? selectedDomain.label
                    : t("field.domains.search.placeholder")}
                </span>
              )}
            </div>
            {disabled ? null : open ? (
              <button
                className="w-4 h-4 flex items-center"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseLine className="fill-hard-grey w-4 h-4" />
              </button>
            ) : (
              <div className="w-4 h-4 flex items-center">
                <ArrowDownSLine className="fill-hard-grey w-4 h-4" />
              </div>
            )}
          </div>
        </Dropdown>
        <IconButton
          variant="secondary"
          onClick={() => {
            onOpenEditDrawer(selectedDomain);
          }}
          disabled={selectedDomain.address === ""}
        >
          <Settings4Line />
        </IconButton>
        {disabled ? null : (
          <IconButton
            variant="warning"
            onClick={() => {
              onRemoveDomain(selectedDomain);
            }}
          >
            <CloseLine />
          </IconButton>
        )}
      </div>
      {selectedDomain.address !== "" ? (
        <label className="pl-3 flex gap-2">
          <input
            type="checkbox"
            checked={selectedDomain.includingSubdomains}
            onChange={() => {
              onChangeDomain({
                key: selectedDomain.key,
                id: selectedDomain.id,
                value: selectedDomain.value,
                address: selectedDomain.address,
                label: selectedDomain.label,
                includingSubdomains: !selectedDomain.includingSubdomains,
              });
            }}
            disabled={selectedDomain.address === "" || disabled}
          />
          <span className="text-hard-grey select-none cursor-pointer">
            {t("field.domains.includingSubdomains")}
          </span>
        </label>
      ) : null}
    </div>
  );
};

export default DomainInput;
