import type { ReactNode, MouseEventHandler } from "react";

interface ISourceLabelProps {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  noEllipsis?: boolean;
}

export const SourceLabel = ({
  children,
  onClick,
  noEllipsis = false,
}: ISourceLabelProps) => {
  return (
    <span
      className={`flex gap-3 px-3 py-2 bg-bright-grey-subline border border-grey rounded-3xl overflow-hidden ${
        noEllipsis ? "" : "whitespace-nowrap text-ellipsis max-w-[12rem]"
      } ${onClick ? "cursor-pointer" : ""}`}
      onClick={onClick}
    >
      {children}
    </span>
  );
};
