import { ReactNode, useMemo } from "react";
import { Dropdown } from "@lockerpm/design";
import { DropdownRenderV2 } from "#src/commonV2/antdHelpers/DropdownRender";
import { IEndpointDevice } from "#src/services/endpoint/devices/types";
import {
  DotsVertical,
  Edit02,
  Modem02,
  Power01,
  RefreshCcw01,
  Trash01,
} from "@untitled-ui/icons-react";
import { useHostControl } from "#src/components/devices/hosts/details/v2/hooks/useHostControl";
import { TableActionLabelV2 } from "#src/commonV2/Table/TableActionLabel";
import { useTranslation } from "react-i18next";

export const EndpointDeviceTableActionCell = ({
  workspaceId,
  item,
  updateDeviceList,
}: {
  workspaceId: string;
  item: IEndpointDevice;
  updateDeviceList: () => void;
}) => {
  const { t } = useTranslation("endpoint", {
    keyPrefix: "devices.table.action",
  });

  // --------------- HOOKS ---------------
  const {
    remoteToDevice,
    RemoteAccessModals,
    shutdownDevice,
    ShutdownHostModal,
    restartDevice,
    RestartHostModal,
    uninstallAgentOnDevice,
    UninstallHostModal,
    editDevice,
    EditHostModal,
  } = useHostControl({
    workspaceId,
    hostId: item.id,
    deviceName: item.name,
    deviceAssetType: item.asset_type,
    deviceTags: item.tags.map((tag) => tag.name),
    refreshHostDetails: updateDeviceList,
  });

  // --------------- COMPUTED ---------------
  const actionList: { key: string; label: ReactNode; action: () => void }[] =
    useMemo(
      () => [
        {
          key: "edit",
          label: (
            <TableActionLabelV2
              icon={<Edit02 className="h-5 w-5" />}
              text={t("edit")}
            />
          ),
          action: editDevice,
        },
        {
          key: "remote-access",
          label: (
            <TableActionLabelV2
              icon={<Modem02 className="h-5 w-5" />}
              text={t("remoteAccess")}
            />
          ),
          action: async () => {
            // setIsLoading(true);
            await remoteToDevice();
            // setIsLoading(false);
          },
        },
        {
          key: "restart",
          label: (
            <TableActionLabelV2
              icon={<RefreshCcw01 className="h-5 w-5" />}
              text={t("restart")}
            />
          ),
          action: restartDevice,
        },
        {
          key: "shutdown",
          label: (
            <TableActionLabelV2
              icon={<Power01 className="h-5 w-5" />}
              text={t("shutDown")}
            />
          ),
          action: shutdownDevice,
        },
        {
          key: "uninstall-agent",
          label: (
            <TableActionLabelV2
              icon={<Trash01 className="h-5 w-5" />}
              text={t("uninstallAgent")}
              warning
            />
          ),
          action: uninstallAgentOnDevice,
        },
      ],
      [
        editDevice,
        remoteToDevice,
        restartDevice,
        shutdownDevice,
        uninstallAgentOnDevice,
        t,
      ]
    );

  // TODO: disable if user doesn't hvae permission
  const disabled = false;

  // --------------- RENDER ---------------
  return (
    <>
      <Dropdown
        menu={{
          items: actionList,
          onClick: ({ key, domEvent }) => {
            domEvent.preventDefault();
            domEvent.stopPropagation();
            actionList.find((action) => action.key === key)?.action();
          },
        }}
        dropdownRender={(menu) => <DropdownRenderV2>{menu}</DropdownRenderV2>}
        trigger={["click"]}
        disabled={disabled}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <DotsVertical
            className={`h-5 w-5 ${disabled ? "text-gray-v2-200" : "text-gray-v2-400"} transition-colors duration-300`}
          />
        </button>
      </Dropdown>
      {/* Prevent event bubbling to avoid toggling the "expand row" function. */}
      <div
        className="contents"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {/* Modals */}
        {RemoteAccessModals}
        {ShutdownHostModal}
        {RestartHostModal}
        {UninstallHostModal}
        {EditHostModal}
        {/* Modals end */}
      </div>
    </>
  );
};
