import { useEffect, type ReactNode } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";
import { XClose } from "@untitled-ui/icons-react";

interface IModalProps {
  open: boolean;
  onChangeOpen: (value: boolean) => void;
  children?: ReactNode;
  className?: string;
}

export const ModalV2 = ({
  open,
  onChangeOpen,
  children,
  className,
}: IModalProps) => {
  useEffect(() => {
    const onEscPress = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onChangeOpen(false);
      }
    };
    addEventListener("keydown", onEscPress);

    return () => removeEventListener("keydown", onEscPress);
  }, [onChangeOpen]);

  return (
    <>
      {createPortal(
        <div
          className={`v2 fixed top-0 left-0 h-screen w-screen flex items-center justify-center z-10 duration-150 transition-all bg-dark-blue bg-opacity-[48%] ${
            open ? "" : "invisible opacity-0"
          }`}
          onClick={() => {
            onChangeOpen(false);
          }}
        >
          <div
            className={twMerge(
              `relative w-[36rem] p-6 rounded-xl bg-white flex flex-col gap-5 overflow-hidden transition-all duration-150 ${
                open ? "" : "scale-50"
              }`,
              className
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
            <div className="absolute right-0 top-0 p-4">
              <button
                className="p-2.5"
                onClick={() => {
                  onChangeOpen(false);
                }}
              >
                <XClose className="h-6 w-6 min-w-6 text-gray-v2-400" />
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </>
  );
};
