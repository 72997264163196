// Libraries
import { useState } from "react";
import { DatePicker, Input, Radio, Select } from "@lockerpm/design";
import dayjs, { type Dayjs } from "dayjs";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CornerDownRightLine } from "#src/assets/images/icons/corner-down-right-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import { isStringAndNotEmpty } from "#src/utils/common";

export enum TimeConditionEnum {
  LAST_X_UNITS = "is in the last",
  EQUAL_TO = "is equal to",
  BETWEEN = "is between",
  AFTER = "is after",
  STARTS_FROM = "is on or after",
  BEFORE = "is before",
  ENDS_AT = "is before or on",
}

const acceptedDateUnit = ["day", "month"] as const;

const timeConditionList: {
  value: TimeConditionEnum;
  getLabel: () => string;
}[] = [
  {
    value: TimeConditionEnum.LAST_X_UNITS,
    getLabel: () => i18next.t("timePopover.enum.isInTheLast", { ns: "common" }),
  },
  {
    value: TimeConditionEnum.EQUAL_TO,
    getLabel: () => i18next.t("timePopover.enum.isEqualTo", { ns: "common" }),
  },
  {
    value: TimeConditionEnum.BETWEEN,
    getLabel: () => i18next.t("timePopover.enum.isBetween", { ns: "common" }),
  },
  {
    value: TimeConditionEnum.AFTER,
    getLabel: () => i18next.t("timePopover.enum.isAfter", { ns: "common" }),
  },
  {
    value: TimeConditionEnum.STARTS_FROM,
    getLabel: () => i18next.t("timePopover.enum.isOnOrAfter", { ns: "common" }),
  },
  {
    value: TimeConditionEnum.BEFORE,
    getLabel: () => i18next.t("timePopover.enum.isBefore", { ns: "common" }),
  },
  {
    value: TimeConditionEnum.ENDS_AT,
    getLabel: () =>
      i18next.t("timePopover.enum.isBeforeOrOn", { ns: "common" }),
  },
] as const;

const TimeConditionLabel = ({
  item,
  selected,
}: {
  item: string;
  selected: string;
}) => {
  return (
    <div
      className={`font-medium-14 ${
        selected === item ? "text-hard-grey" : "text-medium-grey"
      }`}
    >
      {item}
    </div>
  );
};

interface ITimePopoverContentProps {
  defaultCondition: TimeConditionEnum | null;
  applyChangeTime: (
    text: string,
    condition: TimeConditionEnum,
    from: number | null,
    to: number | null
  ) => void;
  closePopover: () => void;
}

const TimePopoverContent = ({
  defaultCondition,
  applyChangeTime,
  closePopover,
}: ITimePopoverContentProps) => {
  const { t } = useTranslation("common", { keyPrefix: "timePopover" });

  const [timeCondition, setTimeCondition] = useState<string>(
    defaultCondition ? defaultCondition : TimeConditionEnum.LAST_X_UNITS
  );
  const [datePicker, setDatePicker] = useState<{
    date: Dayjs | null;
    dateString: string;
  } | null>(null);
  const [rangePicker, setRangePicker] = useState<{
    date: [Dayjs | null, Dayjs | null] | null;
    dateString: string[];
  } | null>(null);
  const [timezone, setTimezone] = useState<string>("GMT+7");
  // TODO: this is wrong. Need to sync with  selected value
  const [amount, setAmount] = useState<number>(1);
  const [unit, setUnit] = useState<(typeof acceptedDateUnit)[number]>("day");

  // TODO: Error handling
  const onClickApply = () => {
    switch (timeCondition) {
      case TimeConditionEnum.LAST_X_UNITS: {
        if (isNaN(amount)) {
          console.log("amount is not a number:", amount);
        }
        if (!Number.isInteger(+amount) || amount <= 0) {
          console.log("amount is invalid:", amount);
        } else {
          const timestampFrom = dayjs()
            .startOf("day")
            .subtract(amount, unit)
            .unix();
          applyChangeTime(
            "Last " + amount + " " + unit + (amount !== 1 ? "s" : ""),
            timeCondition,
            timestampFrom,
            null
          );
          closePopover();
        }
        return;
      }
      case TimeConditionEnum.EQUAL_TO: {
        if (!datePicker || !isStringAndNotEmpty(datePicker.dateString)) {
          console.log(
            "Invalid date. You have to pick a date before applying filter:",
            datePicker
          );
        } else {
          applyChangeTime(
            datePicker.dateString,
            timeCondition,
            datePicker.date ? datePicker.date.startOf("day").unix() : null,
            datePicker.date ? datePicker.date.endOf("day").unix() : null
          );
          closePopover();
        }
        return;
      }
      case TimeConditionEnum.BETWEEN: {
        if (
          !rangePicker ||
          !isStringAndNotEmpty(rangePicker.dateString[0]) ||
          !isStringAndNotEmpty(rangePicker.dateString[1])
        ) {
          console.log(
            "Invalid date. You have to pick a date before applying filter:",
            rangePicker
              ? rangePicker.dateString[0] + rangePicker.dateString[1]
              : rangePicker
          );
        } else {
          applyChangeTime(
            rangePicker.dateString[0] + " - " + rangePicker.dateString[1],
            timeCondition,
            rangePicker.date && rangePicker.date[0]
              ? rangePicker.date[0].startOf("day").unix()
              : null,
            rangePicker.date && rangePicker.date[1]
              ? rangePicker.date[1].endOf("day").unix()
              : null
          );
          closePopover();
        }
        return;
      }
      case TimeConditionEnum.AFTER: {
        if (!datePicker || !isStringAndNotEmpty(datePicker.dateString)) {
          console.log(
            "Invalid date. You have to pick a date before applying filter:",
            datePicker
          );
        } else {
          applyChangeTime(
            "After " + datePicker.dateString,
            timeCondition,
            datePicker.date ? datePicker.date.endOf("day").unix() : null,
            null
          );
          closePopover();
        }
        return;
      }
      case TimeConditionEnum.STARTS_FROM: {
        if (!datePicker || !isStringAndNotEmpty(datePicker.dateString)) {
          console.log(
            "Invalid date. You have to pick a date before applying filter:",
            datePicker
          );
        } else {
          applyChangeTime(
            "Starting from " + datePicker.dateString,
            timeCondition,
            datePicker.date ? datePicker.date.startOf("day").unix() : null,
            null
          );
          closePopover();
        }
        return;
      }
      case TimeConditionEnum.BEFORE: {
        if (!datePicker || !isStringAndNotEmpty(datePicker.dateString)) {
          console.log(
            "Invalid date. You have to pick a date before applying filter:",
            datePicker
          );
        } else {
          applyChangeTime(
            "Before " + datePicker.dateString,
            timeCondition,
            null,
            datePicker.date ? datePicker.date.startOf("day").unix() : null
          );
          closePopover();
        }
        return;
      }
      case TimeConditionEnum.ENDS_AT: {
        if (!datePicker || !isStringAndNotEmpty(datePicker.dateString)) {
          console.log(
            "Invalid date. You have to pick a date before applying filter:",
            datePicker
          );
        } else {
          applyChangeTime(
            "Ending on " + datePicker.dateString,
            timeCondition,
            null,
            datePicker.date ? datePicker.date.endOf("day").unix() : null
          );
          closePopover();
        }
        return;
      }
      default:
        console.log("Something is wrong");
        return;
    }
  };

  const onChangeDatePicker = (date: Dayjs | null, dateString: string) => {
    setDatePicker({ date, dateString });
  };

  const onChangeRangePicker = (
    date: [Dayjs | null, Dayjs | null] | null,
    dateString: [string, string]
  ) => {
    setRangePicker({ date, dateString });
  };

  return (
    <div className="m-1">
      <h4 className="font-medium-14 text-dark-blue mb-3">{t("title")}</h4>
      <Select
        className="w-full mb-1"
        onChange={(value) => {
          setTimeCondition(value);
        }}
        defaultValue={timeCondition}
        options={timeConditionList.map((item) => ({
          value: item.value,
          label: (
            <TimeConditionLabel
              item={item.getLabel()}
              selected={timeCondition}
            />
          ),
        }))}
      />
      <div className="flex items-center gap-1.5 mb-3">
        <CornerDownRightLine
          width={"1rem"}
          height={"1rem"}
          className="fill-primary"
        />
        {timeCondition === TimeConditionEnum.LAST_X_UNITS ? (
          <>
            <Input
              type="text"
              inputMode="numeric"
              className="w-16"
              value={amount}
              onChange={(e) => {
                if (!isNaN(Number(e.currentTarget.value))) {
                  setAmount(Number(e.currentTarget.value));
                }
              }}
            />
            <Select
              className="w-24"
              onChange={(value) => {
                // We enforced the values on `options` part so we can use `as` here safely
                setUnit(value as (typeof acceptedDateUnit)[number]);
              }}
              defaultValue={acceptedDateUnit[0] + "s"}
              options={acceptedDateUnit.map((item) => ({
                value: item,
                label: (
                  <span
                    className={`font-medium-14 ${
                      unit === item ? "text-hard-grey" : "text-medium-grey"
                    }`}
                  >
                    {item + "s"}
                  </span>
                ),
              }))}
            />
          </>
        ) : null}
        {[
          TimeConditionEnum.EQUAL_TO as string,
          TimeConditionEnum.AFTER as string,
          TimeConditionEnum.STARTS_FROM as string,
          TimeConditionEnum.BEFORE as string,
          TimeConditionEnum.ENDS_AT as string,
        ].includes(timeCondition) ? (
          // TODO: styles for DatePicker
          <DatePicker onChange={onChangeDatePicker} format={"DD/MM/YYYY"} />
        ) : null}
        {timeCondition === TimeConditionEnum.BETWEEN ? (
          // TODO: styles for DatePicker
          <DatePicker.RangePicker
            onChange={onChangeRangePicker}
            format={"DD/MM/YYYY"}
          />
        ) : null}
      </div>
      <div className="flex mb-4">
        <p className="font-regular-14 text-hard-grey mr-5">Time zone:</p>
        <Radio.Group
          value={timezone}
          onChange={(e) => setTimezone(e.target.value)}
        >
          <Radio value="GMT+7">GMT+7</Radio>
          <Radio value="UTC">UTC</Radio>
        </Radio.Group>
      </div>
      <Button onClick={onClickApply} size="medium" className="w-full">
        Apply
      </Button>
    </div>
  );
};

export default TimePopoverContent;
