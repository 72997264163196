// Libraries
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Modal } from "@lockerpm/design";
import i18next from "i18next";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import { useAppSelector } from "#src/store/hooks";
import patterns from "#src/config/patterns";

// Components
import { Button } from "#src/common/system/Button";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import { InputFieldWrapper } from "#src/common/composed/divWrappers/InputFieldWrapper";
import {
  type FormErrorItem,
  InputField,
} from "#src/common/helper/wrapper/InputField";

// API-related
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import workspaceServices from "#src/services/workspace";

// Children
import {
  type ContactFormSelection,
  jobTitleList,
  employeeSizeList,
  pronounList,
} from "./contactDetailFields";

interface IContactSupportFormProps {
  workspaceId: string;
  open: boolean;
  onClose: () => void;
  onSendRequest: (form: {
    pronoun: string;
    fullName: string;
    jobTitle: string;
    email: string;
    phoneNumber: string;
    industry: string;
    employeeSize: string;
    companyName: string;
  }) => void;
  errorObj: {
    fullname: FormErrorItem;
    email: FormErrorItem;
    phone_number: FormErrorItem;
    organization_name: FormErrorItem;
    industry: FormErrorItem;
  };
  setErrorObj: Dispatch<
    SetStateAction<{
      fullname: FormErrorItem;
      email: FormErrorItem;
      phone_number: FormErrorItem;
      organization_name: FormErrorItem;
      industry: FormErrorItem;
    }>
  >;
}

const ContactSupportForm = ({
  workspaceId,
  open,
  onClose,
  onSendRequest,
  errorObj,
  setErrorObj,
}: IContactSupportFormProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "onboard.contactModal",
  });

  const userInfo = useAppSelector((state) => state.auth.userInfo);

  const [pronoun, setPronoun] = useState<ContactFormSelection>(pronounList[0]);
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<ContactFormSelection>(
    jobTitleList[0]
  );
  const [industry, setIndustry] = useState<string>("");
  const [employeeSize, setEmployeeSize] = useState<ContactFormSelection>(
    employeeSizeList[0]
  );

  useEffect(() => {
    workspaceServices
      .retrieve_workspace(workspaceId)
      .then((response) => {
        setCompanyName(response.name);
        setIndustry(response.industry);
        const getEmployeeSizeItem = employeeSizeList.find(
          (item) => item.value === response.employee_size
        );
        if (getEmployeeSizeItem) setEmployeeSize(getEmployeeSizeItem);
      })
      .catch(apiErrorHandler);
  }, [workspaceId]);

  useEffect(() => {
    if (userInfo) {
      setFullName(userInfo.full_name);
      setEmail(userInfo.email);
      setPhoneNumber(userInfo.phone);
    }
  }, [userInfo]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={<h2>{t("title")}</h2>}
      centered
      footer={null}
      width="60rem"
    >
      <div className="flex flex-col gap-6">
        <p className="font-regular-14 text-hard-grey">{t("instruction")}</p>
        <div className="grid grid-cols-2 gap-x-6 gap-y-3">
          <div className="flex gap-3">
            <InputFieldWrapper
              title={t("pronoun.label")}
              requiredMark
              input={
                <Dropdown
                  menu={{
                    items: pronounList.map((p) => ({
                      key: p.key,
                      label: (
                        <DropdownItemLabel
                          selected={pronoun.key === p.key}
                          syncWidthId="pronoun-dropdown"
                        >
                          {p.getLabel()}
                        </DropdownItemLabel>
                      ),
                      onClick: ({ key }) => {
                        const clickedItem = pronounList.find(
                          (p) => p.key === key
                        );
                        if (clickedItem) {
                          setPronoun(clickedItem);
                        }
                      },
                    })),
                  }}
                  trigger={["click"]}
                >
                  <DropdownTriggerButton
                    id="pronoun-dropdown"
                    className="min-w-[6rem]"
                  >
                    {pronoun.getLabel()}
                    <ArrowDownSLine
                      width={"1rem"}
                      height={"1rem"}
                      className="fill-hard-grey"
                    />
                  </DropdownTriggerButton>
                </Dropdown>
              }
            />
            <InputField
              className="w-full"
              title={t("fullName.label")}
              placeholder={t("fullName.placeholder")}
              value={fullName}
              onChangeValue={(value) => {
                setFullName(value);
              }}
              error={errorObj.fullname}
              onChangeError={(value) => {
                setErrorObj((prev) => ({ ...prev, fullname: value }));
              }}
              validation={{ required: true }}
            />
          </div>
          <InputFieldWrapper
            title={t("position.label")}
            requiredMark
            input={
              <Dropdown
                menu={{
                  items: jobTitleList.map((p) => ({
                    key: p.key,
                    label: (
                      <DropdownItemLabel
                        selected={jobTitle.key === p.key}
                        syncWidthId="position-dropdown"
                      >
                        {p.getLabel()}
                      </DropdownItemLabel>
                    ),
                    onClick: ({ key }) => {
                      const clickedItem = jobTitleList.find(
                        (p) => p.key === key
                      );
                      if (clickedItem) {
                        setJobTitle(clickedItem);
                      }
                    },
                  })),
                }}
                trigger={["click"]}
              >
                <DropdownTriggerButton id="position-dropdown">
                  {jobTitle.getLabel()}
                  <ArrowDownSLine
                    width={"1rem"}
                    height={"1rem"}
                    className="fill-hard-grey"
                  />
                </DropdownTriggerButton>
              </Dropdown>
            }
          />
          <InputField
            title={t("email.label")}
            placeholder={t("email.placeholder")}
            value={email}
            onChangeValue={(value) => {
              setEmail(value);
            }}
            error={errorObj.email}
            onChangeError={(value) => {
              setErrorObj((prev) => ({ ...prev, email: value }));
            }}
            validation={{
              required: true,
              pattern: {
                value: patterns.EMAIL,
                errorMessage: () => i18next.t("common:validateWarning.email"),
              },
            }}
          />
          <InputField
            title={t("phoneNumber.label")}
            placeholder={t("phoneNumber.placeholder")}
            value={phoneNumber}
            onChangeValue={(value) => {
              setPhoneNumber(value);
            }}
            error={errorObj.phone_number}
            onChangeError={(value) => {
              setErrorObj((prev) => ({ ...prev, phone_number: value }));
            }}
            validation={{
              required: true,
              pattern: {
                value: patterns.PHONE,
                errorMessage: () => i18next.t("common:validateWarning.phone"),
              },
            }}
          />
          <InputField
            title={t("industry.label")}
            placeholder={t("industry.placeholder")}
            value={industry}
            onChangeValue={(value) => {
              setIndustry(value);
            }}
            error={errorObj.industry}
            onChangeError={(value) => {
              setErrorObj((prev) => ({ ...prev, industry: value }));
            }}
            validation={{ required: true }}
          />
          <InputFieldWrapper
            title={t("employeeSize.label")}
            requiredMark
            input={
              <Dropdown
                menu={{
                  items: employeeSizeList.map((p) => ({
                    key: p.key,
                    label: (
                      <DropdownItemLabel
                        selected={employeeSize.key === p.key}
                        syncWidthId="employeeSize-dropdown"
                      >
                        {p.getLabel()}
                      </DropdownItemLabel>
                    ),
                    onClick: ({ key }) => {
                      const clickedItem = employeeSizeList.find(
                        (p) => p.key === key
                      );
                      if (clickedItem) {
                        setEmployeeSize(clickedItem);
                      }
                    },
                  })),
                }}
                trigger={["click"]}
              >
                <DropdownTriggerButton id="employeeSize-dropdown">
                  {employeeSize.getLabel()}
                  <ArrowDownSLine
                    width={"1rem"}
                    height={"1rem"}
                    className="fill-hard-grey"
                  />
                </DropdownTriggerButton>
              </Dropdown>
            }
          />
        </div>
        <div className="flex justify-end">
          <Button
            onClick={() => {
              onSendRequest({
                pronoun: pronoun.value,
                fullName,
                jobTitle: jobTitle.value,
                email,
                phoneNumber,
                industry,
                employeeSize: employeeSize.value,
                companyName,
              });
            }}
            disabled={
              fullName.length === 0 ||
              email.length === 0 ||
              phoneNumber.length === 0 ||
              companyName.length === 0 ||
              industry.length === 0
            }
          >
            {t("button.sendRequest")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ContactSupportForm;
