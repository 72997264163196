// Libraries
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// General
import { pathname } from "#src/config/pathname";
import type { IFilterItem } from "#src/@types/common";
import constants from "#src/config/constants";
import { useAppSelector } from "#src/store/hooks";
import { Tooltip } from "@lockerpm/design";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/common/system/Button";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import PaginationBar from "#src/common/helper/PaginationBar";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import { TrustCenterPlanValue } from "#src/config/filter/trustCenter/value";

// Children
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";
import TrustCenterPolicyFilter from "#src/components/trustCenter/policy/PolicyTable/Filter";
import TrustCenterPolicyTableGrid from "#src/components/trustCenter/policy/PolicyTable/Table";

const TrustCenterPolicy = () => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "policy" });

  const { workspaceId } = useParams<"workspaceId">();

  const trustCenterPlan = useAppSelector((state) => state.trustCenter.plan);

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  const [compliance, setCompliance] = useState<IFilterItem | null>(null);
  const [status, setStatus] = useState<IFilterItem | null>(null);
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");

  const onChangeComplianceSelection = (selected: typeof compliance) => {
    setCompliance(selected);
    setSelectedPage(1);
  };

  const onChangeStatusSelection = (selected: typeof status) => {
    setStatus(selected);
    setSelectedPage(1);
  };

  const onChangeTimeConditionValue = (
    condition: string,
    from: number | null,
    to: number | null
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
    setSelectedPage(1);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangeSortOrder = (order: string) => {
    setSortOrder(order);
    setSelectedPage(1);
  };

  const fetchParams = useMemo<
    Parameters<typeof trustCenterServices.list_trust_center_policy>
  >(
    () => [
      workspaceId ?? "",
      {
        published_time_from: from ?? undefined,
        published_time_to: to ?? undefined,
        compliance_id: compliance?.value,
        status: status?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
        sort: sortOrder ?? undefined,
      },
    ],
    [
      workspaceId,
      from,
      to,
      compliance,
      status,
      searchKeyword,
      selectedPage,
      pageSize,
      sortOrder,
    ]
  );

  const {
    list: policyList,
    count: policyCount,
    isLoading,
    updateData: updatePolicyList,
  } = useFetchPaginated(
    trustCenterServices.list_trust_center_policy,
    fetchParams
  );

  const isDisabledAddButton =
    policyList === null ||
    (trustCenterPlan === TrustCenterPlanValue.FREE && policyCount >= 30);

  return workspaceId ? (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        {currentWorkspace?.role === WorkspaceMemberRoleValue.ADMIN ||
        currentWorkspace?.role === WorkspaceMemberRoleValue.OWNER ? (
          <Tooltip
            title={
              isDisabledAddButton
                ? t("errorMessage.policyAmountLimitReached")
                : null
            }
          >
            <Link
              to={generatePath(pathname.ADD_TRUST_CENTER_POLICY, {
                workspaceId,
              })}
            >
              <Button size="large" disabled={isDisabledAddButton}>
                <AddLine />
                {t("button.addPolicy")}
              </Button>
            </Link>
          </Tooltip>
        ) : null}
      </StickySection>
      <ContentSection>
        <TrustCenterPolicyFilter
          compliance={compliance}
          status={status}
          searchKeyword={searchKeyword}
          onChangeComplianceSelection={onChangeComplianceSelection}
          onChangeStatusSelection={onChangeStatusSelection}
          onChangeTimeConditionValue={onChangeTimeConditionValue}
          onChangeSearchKeyword={onChangeSearchKeyword}
        />
        <TrustCenterPolicyTableGrid
          workspaceId={workspaceId}
          isLoading={isLoading}
          policyList={policyList}
          onRefresh={updatePolicyList}
          sortOrder={sortOrder}
          onChangeSortOrder={onChangeSortOrder}
        />
        <PaginationBar
          numOfResult={policyCount}
          selectedPage={selectedPage}
          pageSize={pageSize}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
        />
      </ContentSection>
    </>
  ) : null;
};

export default TrustCenterPolicy;
