import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import { MultilingualText } from "#src/@types/common";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface ITrustCenterPolicyItem {
  id: number;
  name: MultilingualText;
  compliance: string[];
  status: string;
  created_time: number;
  updated_time: number | null;
  published_time: number | null;
  languages: string[];
}

export interface ITrustCenterPolicyDetail extends ITrustCenterPolicyItem {
  description: MultilingualText;
  details: { lang: string; name: string; description: string }[];
}

export function list_trust_center_policy(
  workspace_id: string,
  params: {
    published_time_from: number | undefined;
    published_time_to: number | undefined;
    compliance_id: string | undefined;
    status: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<ITrustCenterPolicyItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/policies`,
    { params }
  );
}

export function retrieve_trust_center_policy(
  workspace_id: string,
  policy_id: number
) {
  return request.get<never, ITrustCenterPolicyDetail>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/policies/${policy_id}`
  );
}

export function create_trust_center_policy(
  workspace_id: string,
  data: {
    compliance: string[];
    details: { lang: string; name: string; description: string }[];
    status: string;
  }
) {
  return request.post<never, { id: number }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/policies`,
    data
  );
}

export function update_trust_center_policy(
  workspace_id: string,
  policy_id: number,
  data: {
    compliance: string[];
    details?: { lang: string; name: string; description: string }[];
    status: string;
  }
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/policies/${policy_id}`,
    data
  );
}

export function delete_trust_center_policy(
  workspace_id: string,
  policy_id: number
) {
  return request.delete(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/policies/${policy_id}`
  );
}
