import type { ReactNode } from "react";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as CloseCircleLine } from "#src/assets/images/icons/close-circle-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";

export type ToastType = "error" | "warning" | "success" | "info" | "peach";

export const styleToastType: { [S in ToastType]: string } = {
  success: "bg-white border-warning-safe",
  error: "bg-white border-warning-red-danger",
  warning: "bg-white border-status-duplicate",
  info: " bg-white border-status-triaged",
  peach: "bg-gradient-to-br from-[#d74874] to-[#da805f]",
};

export const iconByToastType: { [S in ToastType]: ReactNode } = {
  success: (
    <CheckboxCircleLine className="fill-warning-safe h-6 w-6 min-w-[1.5rem]" />
  ),
  error: (
    <CloseCircleLine className="fill-warning-red-danger h-6 w-6 min-w-[1.5rem]" />
  ),
  warning: (
    <InformationLine className="fill-status-duplicate h-6 w-6 min-w-[1.5rem]" />
  ),
  info: (
    <InformationLine className="fill-status-triaged h-6 w-6 min-w-[1.5rem]" />
  ),
  peach: <InformationLine className="fill-white h-6 w-6 min-w-[1.5rem]" />,
};
