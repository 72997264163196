// Libraries
import { type Dispatch, type SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Radio } from "@lockerpm/design";

// Resources
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";

// Components
import { MultipleChoiceBlockWrapper } from "#src/common/composed/divWrappers/MultipleChoiceBlockWrapper";
import { InputField } from "#src/common/helper/wrapper/InputField";
import { Button, IconButton } from "#src/common/system/Button";

// Children
import type { IUpdatePaymentSettingsErrorObj } from "./helper";

interface IBillingConfigInvoiceProps {
  vatInvoice: {
    name: string;
    taxCode: string;
    identityCard: string;
    address: string;
    type: "enterprise" | "personal";
  };
  setVatInvoice: Dispatch<
    SetStateAction<{
      name: string;
      taxCode: string;
      identityCard: string;
      address: string;
      type: "enterprise" | "personal";
    }>
  >;
  errorObj: IUpdatePaymentSettingsErrorObj;
  setErrorObj: Dispatch<SetStateAction<IUpdatePaymentSettingsErrorObj>>;
  enableFields: boolean;
  onClickEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
}

const BillingConfigInvoice = ({
  vatInvoice,
  setVatInvoice,
  errorObj,
  setErrorObj,
  enableFields,
  onClickEdit,
  onSave,
  onCancel,
}: IBillingConfigInvoiceProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.tab.config.vatInvoiceInfo",
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <h2 className="flex justify-between items-center">
          {t("title")}
          {enableFields ? (
            <div className="flex gap-1 h-9">
              <Button variant="secondary" onClick={onCancel}>
                {t("button.cancel")}
              </Button>
              <Button
                disabled={
                  !(
                    (vatInvoice.name.length > 0 &&
                      vatInvoice.taxCode.length > 0 &&
                      vatInvoice.address.length > 0) ||
                    (vatInvoice.name.length > 0 &&
                      vatInvoice.identityCard.length > 0 &&
                      vatInvoice.address.length > 0)
                  )
                }
                onClick={onSave}
              >
                {t("button.save")}
              </Button>
            </div>
          ) : (
            <IconButton variant="secondary" size={9} onClick={onClickEdit}>
              <EditLine className="h-5 w-5 min-w-5" />
            </IconButton>
          )}
        </h2>
        <span className="text-hard-grey font-regular-16-body">{t("info")}</span>
      </div>
      <div className="px-6 py-3 flex flex-col gap-6">
        <div className="grid grid-cols-2 gap-6">
          <MultipleChoiceBlockWrapper
            selected={vatInvoice.type === "enterprise"}
            onSelect={() => {
              setVatInvoice({
                name: "",
                taxCode: "",
                identityCard: "",
                address: "",
                type: "enterprise",
              });
            }}
            disabled={!enableFields}
          >
            <div className="flex items-center gap-3">
              {enableFields ? (
                <Radio checked={vatInvoice.type === "enterprise"} />
              ) : null}
              {t("enterprise")}
            </div>
          </MultipleChoiceBlockWrapper>
          <MultipleChoiceBlockWrapper
            selected={vatInvoice.type === "personal"}
            onSelect={() => {
              setVatInvoice({
                name: "",
                taxCode: "",
                identityCard: "",
                address: "",
                type: "personal",
              });
            }}
            disabled={!enableFields}
          >
            <div className="flex items-center gap-3">
              {enableFields ? (
                <Radio checked={vatInvoice.type === "personal"} />
              ) : null}
              {t("personal")}
            </div>
          </MultipleChoiceBlockWrapper>
        </div>
        <div className="grid grid-cols-2 py-3 gap-x-6 gap-y-3">
          {vatInvoice.type === "enterprise" ? (
            <>
              <InputField
                title={t("businessName.label")}
                disabled={!enableFields}
                placeholder={t("businessName.placeholder")}
                value={vatInvoice.name}
                onChangeValue={(value) => {
                  setVatInvoice((prev) => ({
                    ...prev,
                    name: value,
                  }));
                }}
                error={errorObj.vat_name}
                onChangeError={(value) => {
                  setErrorObj((prev) => ({
                    ...prev,
                    vat_name: value,
                  }));
                }}
                validation={{ required: true }}
              />
              <InputField
                title={t("taxCode.label")}
                disabled={!enableFields}
                placeholder={t("taxCode.placeholder")}
                value={vatInvoice.taxCode}
                onChangeValue={(value) => {
                  setVatInvoice((prev) => ({
                    ...prev,
                    taxCode: value,
                  }));
                }}
                error={errorObj.vat_tax_code}
                onChangeError={(value) => {
                  setErrorObj((prev) => ({
                    ...prev,
                    vat_tax_code: value,
                  }));
                }}
                validation={{ required: true }}
              />
              <InputField
                title={t("address.label")}
                disabled={!enableFields}
                placeholder={t("address.placeholder")}
                value={vatInvoice.address}
                onChangeValue={(value) => {
                  setVatInvoice((prev) => ({
                    ...prev,
                    address: value,
                  }));
                }}
                error={errorObj.vat_address}
                onChangeError={(value) => {
                  setErrorObj((prev) => ({
                    ...prev,
                    vat_address: value,
                  }));
                }}
                validation={{ required: true }}
              />
            </>
          ) : (
            <>
              <InputField
                title={t("fullName.label")}
                disabled={!enableFields}
                placeholder={t("fullName.placeholder")}
                value={vatInvoice.name}
                onChangeValue={(value) => {
                  setVatInvoice((prev) => ({
                    ...prev,
                    name: value,
                  }));
                }}
                error={errorObj.vat_name}
                onChangeError={(value) => {
                  setErrorObj((prev) => ({
                    ...prev,
                    vat_name: value,
                  }));
                }}
                validation={{ required: true }}
              />
              <InputField
                title={t("identityCard.label")}
                disabled={!enableFields}
                placeholder={t("identityCard.placeholder")}
                value={vatInvoice.identityCard}
                onChangeValue={(value) => {
                  setVatInvoice((prev) => ({
                    ...prev,
                    identityCard: value,
                  }));
                }}
                error={errorObj.vat_identity_card}
                onChangeError={(value) => {
                  setErrorObj((prev) => ({
                    ...prev,
                    vat_identity_card: value,
                  }));
                }}
                validation={{ required: true }}
              />
              <InputField
                title={t("address.label")}
                disabled={!enableFields}
                placeholder={t("address.placeholder")}
                value={vatInvoice.address}
                onChangeValue={(value) => {
                  setVatInvoice((prev) => ({
                    ...prev,
                    address: value,
                  }));
                }}
                error={errorObj.vat_address}
                onChangeError={(value) => {
                  setErrorObj((prev) => ({
                    ...prev,
                    vat_address: value,
                  }));
                }}
                validation={{ required: true }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingConfigInvoice;
