import { Modal } from "@lockerpm/design";
import { SetStateAction, useCallback, useState } from "react";
import { ButtonV2 } from "#src/commonV2/Button";
import dlpService from "#src/services/endpoint/dlp";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { createToast } from "#src/common/system/toasts";
import { useTranslation } from "react-i18next";
import Trash01 from "@untitled-ui/icons-react/build/esm/Trash01";
import { IDLPDrive } from "#src/services/endpoint/dlp/types";
import { IFilterItemV2 } from "#src/commonV2/Table/Filter/types";

type Props = {
  isOpen: boolean;
  workspaceId: string;
  driveIds: number[];
  closeModal: () => void;
  setDriveList: (value: SetStateAction<IDLPDrive[]>) => void;
  listFilter: IFilterItemV2 | null;
  reloadDriveList: () => void;
};

const DeleteScheduleModal = (props: Props) => {
  const {
    isOpen,
    workspaceId,
    driveIds,
    closeModal,
    setDriveList,
    listFilter,
    reloadDriveList,
  } = props;

  const { t } = useTranslation("dlp");

  // --------------- DATA ---------------

  const [isLoading, setIsLoading] = useState(false);

  // --------------- COMPUTED ---------------

  // --------------- METHODS ---------------

  // Delete schedule
  const deleteSchedule = useCallback(async () => {
    if (!driveIds?.length) {
      return;
    }

    setIsLoading(true);
    try {
      await dlpService.bulkRemoveDLPSchedule(workspaceId, driveIds);

      // If current list only display drive with schedule -> reload list because data in current page will change
      if (listFilter?.key === "yes") {
        reloadDriveList();
      } else {
        // Change status of drive in list local
        setDriveList((prev) =>
          prev.map((item) => {
            if (driveIds.includes(item.id)) {
              return {
                ...item,
                backup_enabled: false,
              };
            }
            return item;
          })
        );
      }

      closeModal();

      // Display success toast
      createToast({
        type: "success",
        message: t("disableModal.deleteSuccess"),
      });
    } catch (error) {
      apiErrorHandler(error);
    }

    setIsLoading(false);
  }, [
    driveIds,
    workspaceId,
    listFilter?.key,
    closeModal,
    t,
    reloadDriveList,
    setDriveList,
  ]);

  // --------------- EFFECTS ---------------

  // --------------- RENDER ---------------

  return (
    <Modal
      title={
        <div className="pl-14">
          <p className="relative z-10">{t("disableModal.title")}</p>
        </div>
      }
      open={isOpen}
      footer={null}
      maskClosable={false}
      onCancel={closeModal}
      className="v2 max-w-[600px] overflow-hidden relative"
      centered
      width="90%"
    >
      <div className="absolute -left-9 -top-11 z-0">
        <div className="border border-gray-v2-50 rounded-full p-5">
          <div className="border border-gray-v2-100 rounded-full p-5">
            <div className="border border-gray-v2-100 rounded-full p-5">
              <div className="rounded-full bg-error-v2-100 h-10 w-10 flex items-center justify-center border-4 border-error-v2-50">
                <Trash01 className="h-6 w-6 text-error-v2-600" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative v2 pl-14 z-10">
        {/* Desc */}
        <p className="text-md text-gray-v2-600 mb-3">
          {t("disableModal.desc1", { count: driveIds.length })}
        </p>
        <p className="text-md text-gray-v2-600">
          {t("disableModal.desc2", { count: driveIds.length })}
        </p>
        {/* Desc end */}

        {/* Footer */}
        <div className="flex gap-2 justify-end mt-8">
          <ButtonV2
            variant="secondary"
            size="md"
            color="gray"
            onClick={closeModal}
          >
            <p>{t("common.cancel")}</p>
          </ButtonV2>

          <ButtonV2
            variant="primary"
            size="md"
            color="error"
            pending={isLoading}
            onClick={deleteSchedule}
          >
            <p>{t("common.confirm")}</p>
          </ButtonV2>
        </div>
        {/* Footer end */}
      </div>
    </Modal>
  );
};

export default DeleteScheduleModal;
