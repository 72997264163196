// Libraries
import { type Dispatch, type SetStateAction } from "react";

// API-related
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";

// Children
import WebsiteAccess from "./AccessPermission";
import WebsiteGoogleTag from "./GoogleTag";
import WebsiteDomain from "./CustomDomain";
// import WebsiteDangerZone from "./DangerZone";

interface ITrustCenterConfigWebsiteProps {
  workspaceId: string;
  originalSettings: ITrustCenterConfigDetails;
  onRefresh: () => void;
  access: { isPublic: boolean; alias: string };
  setAccess: Dispatch<SetStateAction<{ isPublic: boolean; alias: string }>>;
  domain: string;
  setDomain: Dispatch<SetStateAction<string>>;
  analytic: { custom_ga_tag_id: string };
  setAnalytic: Dispatch<SetStateAction<{ custom_ga_tag_id: string }>>;
}

const TrustCenterConfigWebsite = ({
  workspaceId,
  originalSettings,
  onRefresh,
  access,
  setAccess,
  domain,
  setDomain,
  analytic,
  setAnalytic,
}: ITrustCenterConfigWebsiteProps) => {
  // Temporarily disabled
  // const onChangePublicState = () => {
  //   trustCenterServices
  //     .update_trust_center_config_access(workspaceId, {
  //       is_public: !originalSettings.access.is_public,
  //       alias: originalSettings.access.alias,
  //     })
  //     .then(() => {
  //       onRefresh();
  //     })
  //     .catch((error) => {
  //       apiErrorHandler(error, {
  //         toastMessage: t("notification.updateAccess.fail"),
  //       });
  //     });
  // };

  return (
    <>
      <div className="flex flex-col gap-6 w-2/3">
        <WebsiteAccess
          workspaceId={workspaceId}
          originalSettings={originalSettings}
          onRefresh={onRefresh}
          access={access}
          setAccess={setAccess}
        />
        <WebsiteDomain
          workspaceId={workspaceId}
          originalSettings={originalSettings}
          onRefresh={onRefresh}
          domain={domain}
          setDomain={setDomain}
        />
        <WebsiteGoogleTag
          workspaceId={workspaceId}
          originalSettings={originalSettings}
          onRefresh={onRefresh}
          analytic={analytic}
          setAnalytic={setAnalytic}
        />
        {/* DangerZone is removed temporarily until there's new specs. */}
        {/* <WebsiteDangerZone
          workspaceId={workspaceId}
          originalSettings={originalSettings}
        /> */}
      </div>
    </>
  );
};

export default TrustCenterConfigWebsite;
