import { TableV2 } from "#src/commonV2/Table";

type Props = {
  hostActivityList: any[];
  isLoading: boolean;
  hostActivityCount: number;
  selectedPage: number;
  setSelectedPage: (value: number) => void;
  pageSize: number;
  setPageSize: (value: number) => void;
};

export const ActivityTable = (props: Props) => {
  const {
    hostActivityList,
    isLoading,
    hostActivityCount,
    selectedPage,
    setSelectedPage,
    pageSize,
    setPageSize,
  } = props;

  // --------------- METHODS ---------------

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  // --------------- RENDER ---------------

  return (
    <TableV2<any>
      options={{
        fitPageHeight: true,
      }}
      data={hostActivityList}
      getRowKey={(item) => item.id.toString()}
      isLoading={isLoading}
      columns={[
        // Activity
        {
          title: "Activity",
          render: (item) => (
            <p className="text-sm font-medium text-gray-v2-900">
              {item.activity}
            </p>
          ),
        },

        // Actor
        {
          title: "Actor",
          render: (item) => (
            <p className="text-sm text-gray-v2-600 font-normal">{item.actor}</p>
          ),
        },

        // Time
        {
          title: "Time",
          render: (item) => (
            <p className="text-sm text-gray-v2-600 font-normal">{item.time}</p>
          ),
        },
      ]}
      pagination={{
        count: hostActivityCount,
        selected: selectedPage,
        onChange: onChangePage,
        size: { value: pageSize, onChange: onChangePageSize },
      }}
    />
  );
};
