// Libraries
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import i18next from "i18next";
import { AxiosError } from "axios";
import { z } from "zod";
import { Tooltip } from "@lockerpm/design";

// Resources
import { ReactComponent as SaveLine } from "#src/assets/images/icons/save-line.svg";
import { ReactComponent as CornerLeftUpLine } from "#src/assets/images/icons/corner-left-up-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import usFlag from "#src/assets/images/flags/us.png";
import vnFlag from "#src/assets/images/flags/vn.png";

// General
import { pathname } from "#src/config/pathname";
import { useAppSelector } from "#src/store/hooks";

// Components
import NoResultsState from "#src/common/states/NoResultsState";
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/common/system/Button";
import {
  type FormErrorItem,
  InputField,
} from "#src/common/helper/wrapper/InputField";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";
import { Flag } from "#src/common/Flag";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import { TrustCenterFaqStatusValue } from "#src/components/trustCenter/faq/enum";
import { TrustCenterPlanValue } from "#src/config/filter/trustCenter/value";

const AddTrustCenterFaq = () => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "faq.addFaq",
  });

  const locale = i18next.language;

  const navigate = useNavigate();

  const trustCenterPlan = useAppSelector((state) => state.trustCenter.plan);

  const { workspaceId } = useParams<"workspaceId">();

  const [faqDetail, setFaqDetail] = useState<
    { lang: string; question: string; answer: string }[]
  >([{ lang: locale, question: "", answer: "" }]);
  const [errorObj, setErrorObj] = useState<{
    question: FormErrorItem;
    answer: FormErrorItem;
  }>({ question: undefined, answer: undefined });

  const [pending, setPending] = useState<boolean>(false);

  if (!workspaceId || workspaceId === "null") {
    return <NoResultsState />;
  }

  const onCreateFaq = (faqStatus: TrustCenterFaqStatusValue) => {
    setPending(true);
    trustCenterServices
      .create_trust_center_faq(workspaceId, {
        details: faqDetail,
        status: faqStatus,
      })
      .then(() => {
        navigate(generatePath(pathname.TRUST_CENTER_FAQ, { workspaceId }));
      })
      .catch((error) => {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 400
        ) {
          const getErrorData = parseBadRequest(
            error.response.data,
            z.object({
              details: z.array(
                z.object({
                  question: z.optional(z.array(z.string())),
                  answer: z.optional(z.array(z.string())),
                })
              ),
            })
          );
          setErrorObj((prev) => ({
            ...prev,
            question:
              getErrorData.details.details.length > 0 &&
              getErrorData.details.details[0].question
                ? () =>
                    (getErrorData.details.details[0].question as string[])[0]
                : undefined,
            answer:
              getErrorData.details.details.length > 0 &&
              getErrorData.details.details[0].answer
                ? () => (getErrorData.details.details[0].answer as string[])[0]
                : undefined,
          }));
        } else {
          apiErrorHandler(error, {
            toastMessage: t("notification.createFaq.fail"),
          });
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  const onClickAddAnotherLang = () => {
    if (faqDetail.every((item) => item.lang !== "en")) {
      setFaqDetail((prev) => [
        ...prev,
        { lang: "en", question: "", answer: "" },
      ]);
      return;
    }
    if (faqDetail.every((item) => item.lang !== "vi")) {
      setFaqDetail((prev) => [
        ...prev,
        { lang: "vi", question: "", answer: "" },
      ]);
      return;
    }
  };

  const invalidFaqNotification: string | null = useMemo(() => {
    for (const item of faqDetail) {
      // FAQ question should not be empty
      if (item.question.length === 0) return t("errorMessage.question.isEmpty");

      // FAQ question should not exceed max length
      if (item.question.length > 1024)
        return t("errorMessage.question.exceedMaxLength");

      // FAQ answer should not exceed max length, which depends on Trust center plan
      if (
        trustCenterPlan === TrustCenterPlanValue.FREE &&
        item.answer.length > 2048
      )
        return t("errorMessage.answer.exceedMaxLength");
      if (
        trustCenterPlan === TrustCenterPlanValue.ENTERPRISE &&
        item.answer.length > 4096
      )
        return t("errorMessage.answer.exceedMaxLength");
      8;
    }

    return null;
  }, [faqDetail, trustCenterPlan, t]);

  return (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        <div className="flex gap-1">
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              navigate(
                generatePath(pathname.TRUST_CENTER_FAQ, {
                  workspaceId,
                })
              );
            }}
          >
            {t("button.cancel")}
          </Button>
          <Tooltip
            title={
              invalidFaqNotification === null ? null : (
                <div className="font-medium-12 whitespace-nowrap">
                  {invalidFaqNotification}
                </div>
              )
            }
            placement="top"
          >
            <div>
              <Button
                size="large"
                variant="secondary"
                disabled={invalidFaqNotification !== null}
                pending={pending}
                onClick={() => {
                  onCreateFaq(TrustCenterFaqStatusValue.DRAFT);
                }}
              >
                <SaveLine />
                {t("button.saveAsDraft")}
              </Button>
            </div>
          </Tooltip>
          <Tooltip
            title={
              invalidFaqNotification === null ? null : (
                <div className="font-medium-12 whitespace-nowrap">
                  {invalidFaqNotification}
                </div>
              )
            }
            placement="top"
          >
            <div>
              <Button
                size="large"
                disabled={invalidFaqNotification !== null}
                pending={pending}
                onClick={() => {
                  onCreateFaq(TrustCenterFaqStatusValue.PUBLISHED);
                }}
              >
                <CornerLeftUpLine />
                {t("button.publishNow")}
              </Button>
            </div>
          </Tooltip>
        </div>
      </StickySection>
      <ContentSection className="gap-8">
        {faqDetail.map((faqLangItem) => {
          return (
            <div
              className="flex flex-col gap-6"
              key={`faqByLanguage-${faqLangItem.lang}`}
            >
              {/* h-6 to fit the close icon so when adding new language this div's height won't change. */}
              <div className="flex justify-between items-center h-6">
                <p className="text-hard-grey flex gap-2">
                  {faqLangItem.lang === "en" ? (
                    <>
                      <Flag pngProp={usFlag} />
                      {t("language.english")}
                    </>
                  ) : faqLangItem.lang === "vi" ? (
                    <>
                      <Flag pngProp={vnFlag} />
                      {t("language.vietnamese")}
                    </>
                  ) : null}
                </p>
                {faqDetail.length > 1 ? (
                  <button
                    className="h-6 w-6 text-hard-grey"
                    onClick={() => {
                      setFaqDetail((prev) =>
                        prev.filter((item) => item.lang !== faqLangItem.lang)
                      );
                    }}
                  >
                    <CloseLine />
                  </button>
                ) : null}
              </div>
              <div className="flex flex-col gap-6 border border-light-grey p-6 rounded-md">
                <InputField
                  title={t("question.title")}
                  placeholder={t("question.placeholder")}
                  value={faqLangItem.question}
                  onChangeValue={(value) => {
                    setFaqDetail((prev) =>
                      prev.map((item) =>
                        item.lang === faqLangItem.lang
                          ? { ...item, question: value }
                          : item
                      )
                    );
                  }}
                  error={errorObj.question}
                  onChangeError={(value) => {
                    setErrorObj((prev) => ({ ...prev, question: value }));
                  }}
                  validation={{ required: true, maxLength: 1024 }}
                />
                <InputField
                  title={t("answer.title")}
                  type="markdown"
                  placeholder={t("answer.placeholder")}
                  value={faqLangItem.answer}
                  onChangeValue={(value) => {
                    setFaqDetail((prev) =>
                      prev.map((item) =>
                        item.lang === faqLangItem.lang
                          ? { ...item, answer: value }
                          : item
                      )
                    );
                  }}
                  error={errorObj.answer}
                  onChangeError={(value) => {
                    setErrorObj((prev) => ({ ...prev, answer: value }));
                  }}
                  validation={{
                    required: true,
                    maxLength:
                      trustCenterPlan === TrustCenterPlanValue.ENTERPRISE
                        ? 4096
                        : 2048,
                  }}
                />
              </div>
            </div>
          );
        })}
        {/* If every languages are used, hide the button. */}
        {["en", "vi"].every((lang) =>
          faqDetail.some((item) => item.lang === lang)
        ) ? null : (
          <Button variant="text" size="large" onClick={onClickAddAnotherLang}>
            <AddLine />
            {t("button.addAnotherLanguage")}
          </Button>
        )}
      </ContentSection>
    </>
  );
};

export default AddTrustCenterFaq;
