import { useMemo } from "react";
import { InfoCircle } from "@untitled-ui/icons-react";
import { Tooltip } from "@lockerpm/design";
import { byteToGigabyte, TAILWIND_COLORS } from "#src/utils/common";
import { DateTime } from "luxon";
import { assetTypeFilterItems } from "#src/components/endpoint/devices/filterItems";
import { IEndpointDevice } from "#src/services/endpoint/devices/types";
import { useTranslation } from "react-i18next";

type Props = {
  details: IEndpointDevice;
};

export const AboutSection = (props: Props) => {
  const { details } = props;
  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- COMPUTED ---------------

  const groups: {
    label: string;
    value: string | number | null | undefined;
    tooltip?: string;
  }[][] = useMemo(() => {
    const col1 = [
      {
        label: t("overview.about.device_id"),
        value: details.id,
      },
      {
        label: t("overview.about.os"),
        value: details.os?.name,
      },
      {
        label: t("overview.about.owner"),
        value: details.owner,
      },
      {
        label: t("overview.about.asset_type"),
        value: assetTypeFilterItems
          .find((item) => item.value === details.asset_type)
          ?.getLabel(),
        tooltip: t("overview.about.asset_type_desc"),
      },
      {
        label: t("overview.about.agent_installed_date"),
        value:
          details.created_time &&
          DateTime.fromSeconds(details.created_time).toFormat(
            "HH:mm dd/MM/yyyy"
          ),
      },
      {
        label: t("overview.about.agent_version"),
        value: details.system?.cystack_version,
      },
    ];
    const col2 = [
      {
        label: t("overview.about.manufacturer"),
        value: details.system?.hardware_vendor,
      },
      {
        label: t("overview.about.hardware_model"),
        value: details.system?.hardware_model,
      },
      {
        label: t("overview.about.serial_number"),
        value: details.system?.hardware_serial,
      },
      {
        label: t("overview.about.cpu_type"),
        value: details.system?.cpu_type,
      },
      {
        label: t("overview.about.cpu_brand"),
        value: details.system?.cpu_brand,
      },
      {
        label: t("overview.about.memory"),
        value:
          details.system?.memory &&
          `${byteToGigabyte(details.system.memory).toFixed(1)} GB`,
      },
    ];
    const col3 = [
      {
        label: t("overview.about.disk_encryption"),
        value: details.system?.disk_encryption_enabled ? "On" : "Off",
      },
      {
        label: t("overview.about.disk_space"),
        value:
          details.system?.gigs_disk_space_available &&
          `${details.system?.gigs_disk_space_available} GB available`,
      },
      {
        label: t("overview.about.mac_address"),
        value: details.system?.mac_address,
      },
      {
        label: t("overview.about.private_ip"),
        value: details.internal_ip,
      },
      {
        label: t("overview.about.public_ip"),
        value: details.public_ip,
      },
      {
        label: t("overview.about.hostname"),
        value: details.hostname,
      },
    ];

    return [col1, col2, col3];
  }, [details, t]);

  // --------------- RENDER ---------------

  return (
    <section className="w-full section-container">
      <h3>{t("overview.about.title")}</h3>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
        {groups.map((col, index) => (
          <div key={index} className="flex flex-col gap-4 xl:border-r">
            {col.map((section, index) => (
              <div key={index} className="flex gap-2">
                <p className="text-sm text-gray-v2-600 flex-1">
                  {section.label}
                  <span>
                    {section.tooltip && (
                      <Tooltip
                        color={TAILWIND_COLORS["gray-v2-900"]}
                        title={
                          <p className="text-xs text-white font-semibold text-center">
                            {section.tooltip}
                          </p>
                        }
                      >
                        <InfoCircle className="h-4 w-4 ml-2 translate-y-1 text-gray-v2-400" />
                      </Tooltip>
                    )}
                  </span>
                </p>
                <p className="flex-1 text-sm font-medium text-gray-v2-900">
                  {section.value || "---"}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};
