// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as LinkIcon } from "#src/assets/images/icons/link.svg";
import { ReactComponent as ImageCircleFill } from "#src/assets/images/icons/image-circle-fill.svg";

// Components
import { ObjectImage } from "#src/common/system/Object";

// API-related
import type { IPentestProjectDetails } from "#src/services/pentest";

interface IMsspPentestDetailsOrganizationProps {
  pentestDetails: IPentestProjectDetails;
}

const MsspPentestDetailsOrganization = ({
  pentestDetails,
}: IMsspPentestDetailsOrganizationProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.organization",
  });
  return (
    <section className="flex flex-col gap-6 pt-6">
      <h2>{t("basicInformation.title")}</h2>
      <div className="bg-bright-grey rounded-md py-6 flex">
        <div className="flex-1 p-6 flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <div className="h-[10.875rem] relative">
              <div
                className="h-28 rounded-md"
                style={{ backgroundColor: pentestDetails.org.color }}
              />
              <div className="p-4 absolute bottom-0 left-1/2 -translate-x-1/2">
                <div className="border-[3px] border-white rounded-md bg-bright-grey h-28 w-28 flex items-center justify-center">
                  <ObjectImage
                    data={pentestDetails.org.logo}
                    className="h-[5.5rem] w-[5.5rem]"
                  >
                    <ImageCircleFill className="h-[2.75rem] w-[2.75rem] fill-medium-grey" />
                  </ObjectImage>
                </div>
              </div>
            </div>
            {/* <div className="flex items-center justify-center text-hard-grey gap-1">
              <TimeLine height={"0.875rem"} width={"0.875rem"} />
              <span className="font-regular-12">
                {t("basicInformation.joined")}{" "}
                {dayjs.unix(pentestDetails.org.created_time).fromNow()}
              </span>
            </div> */}
          </div>
        </div>
        <div className="flex-1 p-6 flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <span className="text-hard-grey">
              {t("basicInformation.companyName")}
            </span>
            <span className="font-medium-16">{pentestDetails.org.name}</span>
          </div>
          {/* <div className="flex flex-col gap-3">
            <span className="text-hard-grey">
              {t("basicInformation.websiteUrl")}
            </span>
            <span className="flex items-center gap-2 text-primary">
              <LinkIcon height={"1.25rem"} width={"1.25rem"} />
              {pentestDetails.org.address}
            </span>
          </div> */}
          {/* <div className="flex flex-col gap-3">
            <span className="text-hard-grey">
              {t("basicInformation.contactUs")}
            </span>
            <span className="flex items-center gap-2 text-primary">
              <LinkIcon height={"1.25rem"} width={"1.25rem"} />
              {pentestDetails.org.email}
            </span>
          </div> */}
          <div className="flex flex-col gap-3">
            <span className="text-hard-grey">
              {t("basicInformation.companyOwnerEmail")}
            </span>
            {pentestDetails.org.email ? (
              <span className="flex items-center gap-2 text-primary">
                <LinkIcon height={"1.25rem"} width={"1.25rem"} />
                {pentestDetails.org.email}
              </span>
            ) : (
              <span className="font-medium-16">N/A</span>
            )}
          </div>
        </div>
        <div className="flex-1 p-6 flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <span className="text-hard-grey">
              {t("basicInformation.description")}
            </span>
            <span className="font-medium-16">
              {pentestDetails.org.description.en
                ? pentestDetails.org.description.en
                : "N/A"}
            </span>
          </div>
          {/* {pentestDetails.org.technologies.length === 0 ? null : (
              <div className="flex flex-col gap-3">
                <span className="text-hard-grey">
                  {t("basicInformation.technologies")}
                </span>
                <div className="flex gap-1">
                  {pentestDetails.org.technologies.map((technology) => (
                    <RoundedTag
                      key={`bugBounty-mssp-technology-${technology}`}
                      name={technology}
                      legendId={getTagColor(technology)}
                    />
                  ))}
                </div>
              </div>
            )} */}
        </div>
      </div>
    </section>
  );
};

export default MsspPentestDetailsOrganization;
