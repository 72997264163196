// Libraries
import type { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Radio } from "@lockerpm/design";

// Resources
import { ReactComponent as ExportLine } from "#src/assets/images/icons/export-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import { Accordion } from "#src/common/system/Accordion";
import { MultipleChoiceBlockWrapper } from "#src/common/composed/divWrappers/MultipleChoiceBlockWrapper";
import { type FormErrorItem, InputField } from "#src/common/helper/wrapper/InputField";

// API-related
import paymentService, { type IPaymentForm } from "#src/services/payment";
import { ContractTypeValue } from "#src/config/filter/workspace/value";

// Children
import { NoteWrapper } from "#src/components/payment/uiHelper/NoteWrapper";

interface IPaymentFillFormOptionsProps {
  paymentForm: IPaymentForm;
  setPaymentForm: Dispatch<SetStateAction<IPaymentForm>>;
  defaultPaymentSettings: Awaited<
    ReturnType<typeof paymentService.retrieve_workspace_payment_settings>
  > | null;
  firstPayment: boolean;
  invoiceErrorObj?: {
    name: FormErrorItem;
    tax_code: FormErrorItem;
    identity_card: FormErrorItem;
    address: FormErrorItem;
  };
  setInvoiceErrorObj?: Dispatch<
    SetStateAction<{
      name: FormErrorItem;
      tax_code: FormErrorItem;
      identity_card: FormErrorItem;
      address: FormErrorItem;
    }>
  >;
}

const PaymentFillFormOptions = ({
  paymentForm,
  setPaymentForm,
  defaultPaymentSettings,
  firstPayment,
  invoiceErrorObj = {
    name: undefined,
    tax_code: undefined,
    identity_card: undefined,
    address: undefined
  },
  setInvoiceErrorObj = () => {},
}: IPaymentFillFormOptionsProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.fillForm.others",
  });

  const onChangeIncludeContract = (
    value: (typeof paymentForm)["is_sign_contract"]
  ) => {
    setPaymentForm((prev) =>
      value
        ? {
            ...prev,
            is_sign_contract: true,
            contract_type: ContractTypeValue.ESIGN,
          }
        : {
            ...prev,
            is_sign_contract: false,
            contract_type: undefined,
          }
    );
  };

  const onChangeContractType = (
    value: (typeof paymentForm)["contract_type"]
  ) => {
    setPaymentForm((prev) => ({
      ...prev,
      contract_type: value,
    }));
  };

  const onChangeIncludeInvoice = (
    value: (typeof paymentForm)["vat_invoice"]
  ) => {
    setPaymentForm((prev) =>
      value
        ? {
            ...prev,
            vat_invoice: true,
            vat_information: {
              type: "enterprise",
              name: defaultPaymentSettings?.vat_name ?? "",
              tax_code: defaultPaymentSettings?.vat_tax_code ?? "",
              address: defaultPaymentSettings?.vat_address ?? "",
            },
          }
        : {
            ...prev,
            vat_invoice: false,
            vat_information: undefined,
          }
    );
  };

  const onChangeInvoiceType = (
    value: Exclude<(typeof paymentForm)["vat_information"], undefined>["type"]
  ) => {
    setPaymentForm((prev) =>
      value === "enterprise"
        ? {
            ...prev,
            vat_information: {
              type: "enterprise",
              name: defaultPaymentSettings?.vat_name ?? "",
              tax_code: defaultPaymentSettings?.vat_tax_code ?? "",
              address: defaultPaymentSettings?.vat_address ?? "",
            },
          }
        : {
            ...prev,
            vat_information: {
              type: "personal",
              name: defaultPaymentSettings?.vat_name ?? "",
              address: defaultPaymentSettings?.vat_address ?? "",
              identity_card: defaultPaymentSettings?.vat_identity_card ?? "",
            },
          }
    );
  };

  const onChangeInvoiceInfo = (
    value: (typeof paymentForm)["vat_information"]
  ) => {
    setPaymentForm((prev) => ({ ...prev, vat_information: value }));
  };

  return (
    <div className="border-t border-light-grey flex flex-col gap-6 p-6">
      {firstPayment ? (
        <Accordion
          label={
            <div className="flex justify-between items-center gap-3">
              <label className="flex items-center gap-3 cursor-pointer">
                <div className="flex items-center justify-center h-5 w-5">
                  <Checkbox
                    checked={paymentForm.is_sign_contract}
                    onClick={(e) => {
                      // using onClick since onChange's default cannot be prevented and it will trigger other tags under
                      e.preventDefault();
                      onChangeIncludeContract(!paymentForm.is_sign_contract);
                    }}
                  />
                </div>
                <span>{t("wantContract.title")}</span>
              </label>
              <Button variant="text" size="large">
                {t("wantContract.seeContract")}
                <ExportLine className="h-5 w-5" />
              </Button>
            </div>
          }
          expanded={Boolean(paymentForm.is_sign_contract)}
        >
          <Accordion
            label={
              <div className="mt-6 flex gap-6">
                <MultipleChoiceBlockWrapper
                  selected={
                    paymentForm.contract_type === ContractTypeValue.ESIGN
                  }
                  onSelect={() => {
                    onChangeContractType(ContractTypeValue.ESIGN);
                  }}
                >
                  <div className="flex items-center gap-3">
                    <Radio
                      checked={
                        paymentForm.contract_type === ContractTypeValue.ESIGN
                      }
                    />
                    {t("wantContract.eContract")}
                  </div>
                </MultipleChoiceBlockWrapper>
                <MultipleChoiceBlockWrapper
                  selected={
                    paymentForm.contract_type === ContractTypeValue.DIRECT
                  }
                  onSelect={() => {
                    onChangeContractType(ContractTypeValue.DIRECT);
                  }}
                >
                  <div className="flex items-center gap-3">
                    <Radio
                      checked={
                        paymentForm.contract_type === ContractTypeValue.DIRECT
                      }
                    />
                    {t("wantContract.paperContract")}
                  </div>
                </MultipleChoiceBlockWrapper>
              </div>
            }
            expanded={paymentForm.contract_type === ContractTypeValue.ESIGN}
          >
            <NoteWrapper>
              {t("wantContract.moreInfo.econtractWillBeSent")}
              <span className="font-bold-16">
                {t("wantContract.moreInfo.docusign")}
              </span>
              {t("wantContract.moreInfo.pleaseFollowInstructions")}
            </NoteWrapper>
          </Accordion>
        </Accordion>
      ) : null}
      <Accordion
        label={
          <label className="py-3 flex items-center gap-3 cursor-pointer">
            <div className="flex items-center justify-center h-5 w-5">
              <Checkbox
                checked={paymentForm.vat_invoice}
                onClick={(e) => {
                  // using onClick since onChange's default cannot be prevented and it will trigger other tags under
                  e.preventDefault();
                  onChangeIncludeInvoice(!paymentForm.vat_invoice);
                }}
              />
            </div>
            <span>{t("wantInvoice.title")}</span>
          </label>
        }
        expanded={Boolean(paymentForm.vat_invoice)}
      >
        <div className="mt-6 flex gap-6">
          <MultipleChoiceBlockWrapper
            selected={paymentForm.vat_information?.type === "enterprise"}
            onSelect={() => {
              onChangeInvoiceType("enterprise");
            }}
          >
            <div className="flex items-center gap-3">
              <Radio
                checked={paymentForm.vat_information?.type === "enterprise"}
              />
              {t("wantInvoice.business")}
            </div>
          </MultipleChoiceBlockWrapper>
          <MultipleChoiceBlockWrapper
            selected={paymentForm.vat_information?.type === "personal"}
            onSelect={() => {
              onChangeInvoiceType("personal");
            }}
          >
            <div className="flex items-center gap-3">
              <Radio
                checked={paymentForm.vat_information?.type === "personal"}
              />
              {t("wantInvoice.personal")}
            </div>
          </MultipleChoiceBlockWrapper>
        </div>
        <div className="mt-6 grid grid-cols-2 gap-6">
          {paymentForm.vat_information ? (
            paymentForm.vat_information.type === "enterprise" ? (
              <>
                <InputField
                  title={t("wantInvoice.fields.businessName.label")}
                  placeholder={t("wantInvoice.fields.businessName.placeholder")}
                  value={paymentForm.vat_information.name}
                  onChangeValue={(value) => {
                    // Assertion here since typescript is not clever enough. We've already narrowed this invoice info above.
                    onChangeInvoiceInfo({
                      ...(paymentForm.vat_information as Extract<
                        (typeof paymentForm)["vat_information"],
                        { type: "enterprise" }
                      >),
                      name: value,
                    });
                  }}
                  error={invoiceErrorObj.name}
                  onChangeError={(value) => {
                    setInvoiceErrorObj((prev) => ({ ...prev, name: value }));
                  }}
                  validation={{ required: true }}
                />
                <InputField
                  title={t("wantInvoice.fields.taxCode.label")}
                  placeholder={t("wantInvoice.fields.taxCode.placeholder")}
                  value={paymentForm.vat_information.tax_code}
                  onChangeValue={(value) => {
                    // Assertion here since typescript is not clever enough. We've already narrowed this invoice info above.
                    onChangeInvoiceInfo({
                      ...(paymentForm.vat_information as Extract<
                        (typeof paymentForm)["vat_information"],
                        { type: "enterprise" }
                      >),
                      tax_code: value,
                    });
                  }}
                  error={invoiceErrorObj.tax_code}
                  onChangeError={(value) => {
                    setInvoiceErrorObj((prev) => ({ ...prev, tax_code: value }));
                  }}
                  validation={{ required: true }}
                />
                <InputField
                  title={t("wantInvoice.fields.address.label")}
                  placeholder={t("wantInvoice.fields.address.placeholder")}
                  value={paymentForm.vat_information.address}
                  onChangeValue={(value) => {
                    // Assertion here since typescript is not clever enough. We've already narrowed this invoice info above.
                    onChangeInvoiceInfo({
                      ...(paymentForm.vat_information as Extract<
                        (typeof paymentForm)["vat_information"],
                        { type: "enterprise" }
                      >),
                      address: value,
                    });
                  }}
                  error={invoiceErrorObj.address}
                  onChangeError={(value) => {
                    setInvoiceErrorObj((prev) => ({ ...prev, address: value }));
                  }}
                  validation={{ required: true }}
                />
              </>
            ) : (
              <>
                <InputField
                  title={t("wantInvoice.fields.fullName.label")}
                  placeholder={t("wantInvoice.fields.fullName.placeholder")}
                  value={paymentForm.vat_information.name}
                  onChangeValue={(value) => {
                    // Assertion here since typescript is not clever enough. We've already narrowed this invoice info above.
                    onChangeInvoiceInfo({
                      ...(paymentForm.vat_information as Extract<
                        (typeof paymentForm)["vat_information"],
                        { type: "personal" }
                      >),
                      name: value,
                    });
                  }}
                  error={invoiceErrorObj.name}
                  onChangeError={(value) => {
                    setInvoiceErrorObj((prev) => ({ ...prev, name: value }));
                  }}
                  validation={{ required: true }}
                />
                <InputField
                  title={t("wantInvoice.fields.citizenId.label")}
                  placeholder={t("wantInvoice.fields.citizenId.placeholder")}
                  value={paymentForm.vat_information.identity_card}
                  onChangeValue={(value) => {
                    // Assertion here since typescript is not clever enough. We've already narrowed this invoice info above.
                    onChangeInvoiceInfo({
                      ...(paymentForm.vat_information as Extract<
                        (typeof paymentForm)["vat_information"],
                        { type: "personal" }
                      >),
                      identity_card: value,
                    });
                  }}
                  error={invoiceErrorObj.identity_card}
                  onChangeError={(value) => {
                    setInvoiceErrorObj((prev) => ({ ...prev, identity_card: value }));
                  }}
                  validation={{ required: true }}
                />
                <InputField
                  title={t("wantInvoice.fields.address.label")}
                  placeholder={t("wantInvoice.fields.address.placeholder")}
                  value={paymentForm.vat_information.address}
                  onChangeValue={(value) => {
                    // Assertion here since typescript is not clever enough. We've already narrowed this invoice info above.
                    onChangeInvoiceInfo({
                      ...(paymentForm.vat_information as Extract<
                        (typeof paymentForm)["vat_information"],
                        { type: "personal" }
                      >),
                      address: value,
                    });
                  }}
                  error={invoiceErrorObj.address}
                  onChangeError={(value) => {
                    setInvoiceErrorObj((prev) => ({ ...prev, address: value }));
                  }}
                  validation={{ required: true }}
                />
              </>
            )
          ) : // Should never reach this null state
          null}
        </div>
      </Accordion>
    </div>
  );
};

export default PaymentFillFormOptions;
