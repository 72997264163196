// Libraries
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useState } from "react";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import NoResultsState from "#src/common/states/NoResultsState";
import { Button } from "#src/common/system/Button";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";

// Children
import TrustCenterThirdPartyTable from "#src/components/trustCenter/thirdParty/ThirdPartyTable";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

const TrustCenterThirdParty = () => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "thirdParty" });

  const [openAddDrawer, setOpenAddDrawer] = useState<boolean>(false);

  const { workspaceId } = useParams<"workspaceId">();

  if (!workspaceId || workspaceId === "null") {
    return <NoResultsState />;
  }

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  return (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        {currentWorkspace?.role === WorkspaceMemberRoleValue.ADMIN ||
        currentWorkspace?.role === WorkspaceMemberRoleValue.OWNER ? (
          <Button
            size="large"
            onClick={() => {
              setOpenAddDrawer(true);
            }}
          >
            <AddLine className="h-5 w-5" />
            {t("button.addService")}
          </Button>
        ) : null}
      </StickySection>
      <ContentSection>
        <TrustCenterThirdPartyTable
          workspaceId={workspaceId}
          openAddDrawer={openAddDrawer}
          setOpenAddDrawer={setOpenAddDrawer}
        />
      </ContentSection>
    </>
  );
};

export default TrustCenterThirdParty;
