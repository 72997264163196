import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IKeywordItem {
  id: number;
  created_time: number;
  keyword: string;
  updated_time: number | null;
}

export function list_keywords(workspace_id: string, params: IPaginateParams) {
  return request.get<never, IPaginatedResponse<IKeywordItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/keywords`,
    { params }
  );
}

export function list_keywords_shortly(workspace_id: string) {
  return request.get<never, IKeywordItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/keywords`,
    { params: { paging: 0 } }
  );
}

export function add_multiple_keywords(
  workspace_id: string,
  data: { keywords: string[] }
) {
  return request.post(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/keywords/multiple`,
    { ...data }
  );
}

export function remove_multiple_keywords(
  workspace_id: string,
  data: { ids: number[] }
) {
  return request.post(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/keywords/delete_multiple`,
    { ...data }
  );
}
