import { forwardRef, type HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import { ReactComponent as ArrowUpSFill } from "#src/assets/images/icons/arrow-up-s-fill.svg";
import { ReactComponent as ArrowDownSFill } from "#src/assets/images/icons/arrow-down-s-fill.svg";

interface ITableHeaderCellProps extends HTMLAttributes<HTMLDivElement> {
  align?: "left" | "center" | "right";
  sort?: -1 | 0 | 1;
}

const TableHeaderCell = forwardRef<HTMLDivElement, ITableHeaderCellProps>(
  ({ align = "left", sort, ...props }, ref) => {
    const tailwindAlign =
      align === "left"
        ? "justify-start text-left"
        : align === "right"
          ? "justify-end text-right"
          : "justify-center text-center";

    return (
      <div
        {...props}
        ref={ref}
        className={twMerge(
          `bg-neutral-100 text-slate-500 py-5 px-4 flex items-center gap-2.5 first:rounded-l-md last:rounded-r-md ${
            sort !== undefined ? "cursor-pointer" : ""
          } ${tailwindAlign}`,
          props.className
        )}
      >
        {props.children}
        {sort === undefined ? null : (
          <div className="relative h-full">
            <ArrowUpSFill
              className={`absolute -top-[0.1875rem] left-0 h-5 w-5 min-w-[1.25rem] ${
                sort === 1 ? "fill-primary" : "fill-medium-grey"
              }`}
            />
            <ArrowDownSFill
              className={`absolute top-[0.1875rem] left-0 h-5 w-5 min-w-[1.25rem] ${
                sort === -1 ? "fill-primary" : "fill-medium-grey"
              }`}
            />
          </div>
        )}
      </div>
    );
  }
);

TableHeaderCell.displayName = "TableHeaderCell";

export interface ITableHeaderPropItem {
  name: string;
  align: "left" | "center" | "right";
  sortingKey?: { asc: string; desc: string };
}

/** Same as name
 * @param title - table title, used for React's keys
 * @param headerProps - list of header props, which will be displayed inside this header component
 * @param cellClassName - custom classname for header cells. Might need to be refactored.
 */
const TableHeader = ({
  title,
  headerProps,
  cellClassName,
  sortOrder,
  onChangeSortOrder,
}: {
  title: string;
  headerProps: ITableHeaderPropItem[];
  cellClassName?: string;
  sortOrder?: string;
  onChangeSortOrder?: (order: string) => void;
}) => {
  return (
    <div className="grid grid-cols-subgrid col-span-full">
      {headerProps.map((header, index) => {
        const calcSort: 1 | 0 | -1 | undefined = header.sortingKey
          ? header.sortingKey.asc === sortOrder
            ? 1
            : header.sortingKey.desc === sortOrder
              ? -1
              : 0
          : undefined;

        return (
          <TableHeaderCell
            key={`t${title}-th${header.name}id${index}`}
            align={header.align}
            className={cellClassName ? cellClassName : undefined}
            sort={calcSort}
            onClick={() => {
              if (header.sortingKey && onChangeSortOrder) {
                if (calcSort === 0) {
                  onChangeSortOrder(header.sortingKey.asc);
                  return;
                }
                if (calcSort === 1) {
                  onChangeSortOrder(header.sortingKey.desc);
                  return;
                }
                if (calcSort === -1) {
                  onChangeSortOrder("");
                  return;
                }
              }
            }}
          >
            {header.name}
          </TableHeaderCell>
        );
      })}
    </div>
  );
};

export default TableHeader;
