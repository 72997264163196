// Libraries
import { Collapse, Drawer, Dropdown, Switch } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import { type Dispatch, type SetStateAction, useState } from "react";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import type { IDrawerAdapterProps, IFilterItem } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import { CollapsePanelHeading3 } from "#src/common/helper/antdProps/Collapse/PanelHeader";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import pentestServices from "#src/services/pentest";

export const pentestManagementPanelKeys = {
  MANAGED_PROVIDER: "PENTEST_MANAGEMENT_PANEL_MANAGED_PROVIDER",
  SELF_MANAGED: "PENTEST_MANAGEMENT_PANEL_SELF_MANAGED",
};

const FooterButtons = ({
  onSave,
  onCancel,
}: {
  onSave: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.mssp.drawer",
  });

  return (
    <div className="flex flex-col gap-3">
      <Button className="w-full" size="large" onClick={onSave}>
        {t("button.save")}
      </Button>
      <Button
        variant="secondary"
        className="w-full"
        size="large"
        onClick={onCancel}
      >
        {t("button.cancel")}
      </Button>
    </div>
  );
};

interface IPentestMsspManagementDrawerProps extends IDrawerAdapterProps {
  pentestAlias: string;
  activePanel: string[];
  setActivePanel: Dispatch<SetStateAction<string[]>>;
}

const PentestMsspManagementDrawer = ({
  workspaceId,
  pentestAlias,
  open,
  onClose,
  onRefresh,
  activePanel,
  setActivePanel,
}: IPentestMsspManagementDrawerProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.mssp.drawer",
  });

  const providers = [
    {
      key: "whitehub",
      value: "whitehub",
      getLabel: () => "Whitehub",
    },
  ];

  // TODO: get from resources
  const [selectedMssp, setSelectedMssp] = useState<IFilterItem | null>({
    key: "whitehub",
    value: "bshp7g",
    getLabel: () => "Whitehub",
  });

  const onChangeMsspProvider = (value: IFilterItem) => {
    setSelectedMssp(value);
  };

  const onSave = () => {
    const newMssp =
      activePanel.includes(pentestManagementPanelKeys.MANAGED_PROVIDER) &&
      selectedMssp?.value
        ? selectedMssp.value
        : null;
    pentestServices
      .update_pentest_mssp(workspaceId, pentestAlias, {
        mssp_id: newMssp,
      })
      .then((response) => {
        if (response.success) {
          onRefresh();
          onClose();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.changeMssp.fail"),
        });
      });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      closable={false}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onClose}>
          <CloseLine />
        </IconButton>
      }
      footer={<FooterButtons onSave={onSave} onCancel={onClose} />}
      destroyOnClose={true}
    >
      <section>
        {/* TODO: maybe this can be replaced by Accordion  */}
        <Collapse
          ghost
          accordion
          expandIcon={({ isActive }) => <Switch checked={isActive} />}
          expandIconPosition="end"
          activeKey={activePanel}
          onChange={(key) => {
            if (typeof key === "string") {
              key = [key];
            }

            if (key.length === 0) {
              // Since this Collapse has been set to Accordion, the activePanel should only have 1 element
              if (
                activePanel[0] === pentestManagementPanelKeys.MANAGED_PROVIDER
              ) {
                setActivePanel([pentestManagementPanelKeys.SELF_MANAGED]);
              } else {
                setActivePanel([pentestManagementPanelKeys.MANAGED_PROVIDER]);
              }
            } else {
              setActivePanel(key);
            }
          }}
        >
          <Collapse.Panel
            key={pentestManagementPanelKeys.MANAGED_PROVIDER}
            header={
              <CollapsePanelHeading3
                active={activePanel.includes(
                  pentestManagementPanelKeys.MANAGED_PROVIDER
                )}
                title={t("panel.managedProvider.title")}
              />
            }
          >
            <div className="flex flex-col gap-3 p-6">
              <h4>{t("panel.managedProvider.chooseProvider")}</h4>
              <Dropdown
                menu={{
                  items: providers.map((item) => ({
                    key: item.key,
                    label: (
                      <DropdownItemLabel
                        syncWidthId="pentest-mssp-management-dropdown"
                        selected={item.key === selectedMssp?.key}
                      >
                        {item.getLabel()}
                      </DropdownItemLabel>
                    ),
                  })),
                  onClick: ({ key }) => {
                    const selected = providers.find((item) => item.key === key);
                    if (selected) {
                      onChangeMsspProvider(selected);
                    }
                  },
                }}
                trigger={["click"]}
              >
                <DropdownTriggerButton
                  id="pentest-mssp-management-dropdown"
                  className="w-full"
                >
                  {selectedMssp?.getLabel()}
                  <ArrowDownSLine />
                </DropdownTriggerButton>
              </Dropdown>
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            key={pentestManagementPanelKeys.SELF_MANAGED}
            header={
              <CollapsePanelHeading3
                active={activePanel.includes(
                  pentestManagementPanelKeys.SELF_MANAGED
                )}
                title={t("panel.selfManaged.title")}
              />
            }
          >
            <div className="flex flex-col gap-6 p-6 font-regular-14 text-hard-grey">
              {t("panel.selfManaged.info")}
            </div>
          </Collapse.Panel>
        </Collapse>
      </section>
    </Drawer>
  );
};

export default PentestMsspManagementDrawer;
