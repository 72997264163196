import i18next from "i18next";
import { IFilterItemV2 } from "../types";

const fixedTFunc = i18next.getFixedT(null, "common", "v2.filterByDate.enum");

export enum DateRangeTypeEnum {
  LAST_X_UNITS = "IS_IN_THE_LAST",
  EQUAL_TO = "IS_EQUAL_TO",
  BETWEEN = "IS_BETWEEN",
  AFTER = "IS_AFTER",
  STARTS_FROM = "IS_AFTER_OR_ON",
  BEFORE = "IS_BEFORE",
  ENDS_AT = "IS_BEFORE_OR_ON",
}

export const dateRangeTypeList: IFilterItemV2[] = [
  {
    key: DateRangeTypeEnum.LAST_X_UNITS,
    value: DateRangeTypeEnum.LAST_X_UNITS,
    getLabel: () => fixedTFunc("isInTheLast"),
  },
  {
    key: DateRangeTypeEnum.EQUAL_TO,
    value: DateRangeTypeEnum.EQUAL_TO,
    getLabel: () => fixedTFunc("isEqualTo"),
  },
  {
    key: DateRangeTypeEnum.BETWEEN,
    value: DateRangeTypeEnum.BETWEEN,
    getLabel: () => fixedTFunc("isBetween"),
  },
  {
    key: DateRangeTypeEnum.AFTER,
    value: DateRangeTypeEnum.AFTER,
    getLabel: () => fixedTFunc("isAfter"),
  },
  {
    key: DateRangeTypeEnum.STARTS_FROM,
    value: DateRangeTypeEnum.STARTS_FROM,
    getLabel: () => fixedTFunc("isOnOrAfter"),
  },
  {
    key: DateRangeTypeEnum.BEFORE,
    value: DateRangeTypeEnum.BEFORE,
    getLabel: () => fixedTFunc("isBefore"),
  },
  {
    key: DateRangeTypeEnum.ENDS_AT,
    value: DateRangeTypeEnum.ENDS_AT,
    getLabel: () => fixedTFunc("isBeforeOrOn"),
  },
];

export enum AcceptedDateUnit {
  DAYS = "days",
  WEEKS = "weeks",
}

export const acceptedDateUnitList: IFilterItemV2[] = [
  {
    key: AcceptedDateUnit.DAYS,
    value: AcceptedDateUnit.DAYS,
    getLabel: () => fixedTFunc("day"),
  },
  {
    key: AcceptedDateUnit.WEEKS,
    value: AcceptedDateUnit.WEEKS,
    getLabel: () => fixedTFunc("week"),
  },
];
