// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@lockerpm/design";

// Resources
import { ReactComponent as ErrorWarningLine } from "#src/assets/images/icons/error-warning-line.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";

// Components
import { Button } from "#src/common/system/Button";

// Children
import ContactForm from "./ContactForm";
import { PlanCell, PlanHeader, SubgridRow } from "./helperWrapper";

interface ITrustCenterOnboardPricingPlanProps {
  workspaceId: string;
  requestFreePlan: () => void;
}

const TrustCenterOnboardPricingPlan = ({
  workspaceId,
  requestFreePlan,
}: ITrustCenterOnboardPricingPlanProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "onboard" });

  const [openContactForm, setOpenContactForm] = useState<boolean>(false);

  return (
    <>
      <div className="grid grid-cols-3">
        <SubgridRow className="border-b border-grey">
          <div />
          <div className="pt-6 px-6 pb-4 font-bold-20">
            {t("freePlan.title")}
          </div>
          <div className="pt-6 px-6 pb-4 font-bold-20">
            {t("enterprisePlan.title")}
          </div>
        </SubgridRow>
        <SubgridRow>
          <div />
          <PlanCell className="text-dark-blue pb-0">
            <div className="flex gap-1.5">
              <h1>{t("freePlan.pricing.value")}</h1>
              <span className="font-medium-20">
                {t("freePlan.pricing.currency")}
              </span>
            </div>
          </PlanCell>
          <PlanCell className="flex-col items-start gap-2 text-dark-blue pb-0">
            <div className="flex items-center gap-4">
              <span className="text-hard-grey-font-medium-16">
                {t("enterprisePlan.pricing.from")}
              </span>
              <span className="flex items-start gap-1.5">
                <h1>{t("enterprisePlan.pricing.value")}</h1>
                <span className="font-medium-20">
                  {t("enterprisePlan.pricing.currency")}
                </span>
              </span>
            </div>
            <span className="font-medium-16 text-hard-grey">
              {t("enterprisePlan.pricing.onceOffPayment")}
            </span>
          </PlanCell>
        </SubgridRow>
        <SubgridRow>
          <div />
          <PlanCell className="items-start font-medium-16 pt-4 pb-0">
            {t("freePlan.description")}
          </PlanCell>
          <PlanCell className="items-start font-medium-16 pt-4 pb-0">
            {t("enterprisePlan.description")}
          </PlanCell>
        </SubgridRow>
        <SubgridRow>
          <div />
          <PlanCell className="w-full">
            <Button size="large" className="w-full" onClick={requestFreePlan}>
              {t("freePlan.button.tryNow")}
            </Button>
          </PlanCell>
          <PlanCell className="w-full">
            <Button
              size="large"
              className="w-full"
              onClick={() => {
                setOpenContactForm(true);
              }}
            >
              {t("enterprisePlan.button.getConsulation")}
            </Button>
          </PlanCell>
        </SubgridRow>
      </div>
      <div className="grid grid-cols-3">
        <SubgridRow className="bg-bright-grey">
          <PlanHeader className="flex-col items-start gap-1">
            <span className="flex items-center gap-1">
              {t("headers.customDomain.buyCustomDomain")}
              <Tooltip title={t("headers.customDomain.tooltip")}>
                <ErrorWarningLine className="text-hard-grey w-4 h-4" />
              </Tooltip>
            </span>
            <span className="font-medium-12 text-hard-grey">
              {t("headers.customDomain.discountForYearly")}
            </span>
          </PlanHeader>
          <PlanCell>{t("freePlan.customDomain")}</PlanCell>
          <PlanCell>{t("enterprisePlan.customDomain")}</PlanCell>
        </SubgridRow>
        <SubgridRow>
          <PlanHeader>{t("headers.userAmount")}</PlanHeader>
          <PlanCell>{t("freePlan.userAmount")}</PlanCell>
          <PlanCell>{t("enterprisePlan.userAmount")}</PlanCell>
        </SubgridRow>
        <SubgridRow>
          <PlanHeader>{t("headers.overallEvaluate")}</PlanHeader>
          <PlanCell>{t("freePlan.overallEvaluate")}</PlanCell>
          <PlanCell>{t("enterprisePlan.overallEvaluate")}</PlanCell>
        </SubgridRow>
        <SubgridRow>
          <PlanHeader>{t("headers.publicBanner")}</PlanHeader>
          <PlanCell>{t("freePlan.publicBanner")}</PlanCell>
          <PlanCell>{t("enterprisePlan.publicBanner")}</PlanCell>
        </SubgridRow>
        <SubgridRow>
          <PlanHeader>{t("headers.totalCapacity")}</PlanHeader>
          <PlanCell>{t("freePlan.totalCapacity")}</PlanCell>
          <PlanCell>{t("enterprisePlan.totalCapacity")}</PlanCell>
        </SubgridRow>
        <SubgridRow>
          <PlanHeader>{t("headers.verifyInfo")}</PlanHeader>
          <PlanCell>-</PlanCell>
          <PlanCell>
            <CheckboxCircleLine className="fill-support-seafoam h-6 w-6" />
          </PlanCell>
        </SubgridRow>
        <SubgridRow>
          <PlanHeader>{t("headers.otherSupport")}</PlanHeader>
          <PlanCell>-</PlanCell>
          <PlanCell>
            <CheckboxCircleLine className="fill-support-seafoam h-6 w-6" />
          </PlanCell>
        </SubgridRow>
        <SubgridRow>
          <PlanHeader>{t("headers.integration")}</PlanHeader>
          <PlanCell>
            <CheckboxCircleLine className="fill-support-seafoam h-6 w-6" />
          </PlanCell>
          <PlanCell>
            <CheckboxCircleLine className="fill-support-seafoam h-6 w-6" />
          </PlanCell>
        </SubgridRow>
        <SubgridRow>
          <PlanHeader>{t("headers.dashboard")}</PlanHeader>
          <PlanCell>
            <CheckboxCircleLine className="fill-support-seafoam h-6 w-6" />
          </PlanCell>
          <PlanCell>
            <CheckboxCircleLine className="fill-support-seafoam h-6 w-6" />
          </PlanCell>
        </SubgridRow>
      </div>
      <ContactForm
        workspaceId={workspaceId}
        open={openContactForm}
        onClose={() => {
          setOpenContactForm(false);
        }}
      />
    </>
  );
};

export default TrustCenterOnboardPricingPlan;
