// Libraries
import { Dropdown } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// Components
import {
  TableDataCell,
  TableHeader,
  TableRowWrapper,
  TableCheckbox,
} from "#src/common/Table";
import { getTwColorByStatus } from "#src/common/variants/byStatus";
import type { ITableHeaderPropItem } from "#src/common/Table/TableHeader";
import { SeverityLabel } from "#src/common/helper/labels/SeverityLabel";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { IScanVulnerabilityItem } from "#src/services/scans/vulnerabilities";
import vulnerabilitiesServices from "#src/services/vulnerabilities";
import { vulnerabilityStatusItems } from "#src/config/filter/vulnerability";

interface IVulnerabilityRowProps {
  data: IScanVulnerabilityItem;
  workspaceId: string;
  scanId: number;
  selectedVulnerabilityIds: number[];
  onChangeSelectedVulnerabilities: (selected: IScanVulnerabilityItem) => void;
  onChangeVulnerabilityStatus: (
    vulnerabilityId: number,
    subStatus: string | undefined
  ) => void;
}

const VulnerabilityTableRow = ({
  data,
  workspaceId,
  scanId,
  selectedVulnerabilityIds,
  onChangeSelectedVulnerabilities,
  onChangeVulnerabilityStatus,
}: IVulnerabilityRowProps) => {
  return (
    <TableRowWrapper
      to={`/${workspaceId}/vulnerabilities?vuln_id=${data.id}&assessment_type=auto_scan&scan_history_id=${scanId}`}
    >
      <TableDataCell className="p-0 justify-center">
        <TableCheckbox
          checked={selectedVulnerabilityIds.includes(data.id)}
          onClick={() => {
            onChangeSelectedVulnerabilities(data);
          }}
        />
      </TableDataCell>
      <TableDataCell>
        <SeverityLabel severity={data.severity} />
      </TableDataCell>
      <TableDataCell>{data.vuln?.name}</TableDataCell>
      <TableDataCell>
        {data.vuln?.cvss_v3_score
          ? data.vuln.cvss_v3_score
          : data.vuln?.cvss_v2_score
            ? data.vuln.cvss_v2_score
            : null}
      </TableDataCell>
      <TableDataCell>{data.asset.address}</TableDataCell>
      <TableDataCell>
        <Dropdown
          menu={{
            items: vulnerabilityStatusItems.map((item) => ({
              key: item.key,
              value: item.value,
              type: item.type,
              label: (
                <div className="px-3 py-2 bg-bright-grey text-medium-grey font-medium-14-forced w-[13.5rem]">
                  {item.getLabel()}
                </div>
              ),
              children: item.children.map((childItem) => ({
                key: childItem.key,
                value: childItem.value,
                label: (
                  <div className="flex items-center gap-2 p-3 text-medium-grey font-medium-16 w-[13.5rem]">
                    <div
                      className={`${
                        childItem.value
                          ? getTwColorByStatus(childItem.value)
                          : ""
                      } h-2 w-2 rounded-full`}
                    />
                    {childItem.getLabel()}
                  </div>
                ),
              })),
            })),
            onClick: (obj) => {
              const newSelected = vulnerabilityStatusItems
                .map((item) => item.children)
                .flat()
                .find((item) => item.key === obj.key);
              if (newSelected)
                onChangeVulnerabilityStatus(data.id, newSelected.value);
            },
          }}
          placement="bottomRight"
          arrow
          trigger={["click"]}
        >
          <div
            className="flex gap-3 bg-bright-grey p-3 w-64 items-center justify-between rounded-md text-hard-grey cursor-pointer"
            onClick={(e) => {
              // Prevent the action of the link behind, which would hve redirect us into other page
              e.preventDefault();
            }}
          >
            <div className="flex gap-3 items-center">
              <div
                className={`w-2.5 h-2.5 rounded-full ${
                  data.sub_status ? getTwColorByStatus(data.sub_status) : ""
                }`}
              />
              {vulnerabilityStatusItems
                .map((item) => item.children)
                .flat()
                .find((item) => item.value === data.sub_status)
                ?.getLabel()}
            </div>
            <ArrowDownSLine />
          </div>
        </Dropdown>
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface IVulnerabilityTableProps {
  workspaceId: string;
  scanId: number;
  vulnerabilities: IScanVulnerabilityItem[];
  selectedVulnerabilityIds: number[];
  onChangeSelectedVulnerabilities: (selected: IScanVulnerabilityItem) => void;
  refreshVulnerabilityList: () => void;
}

const VulnerabilityTableGrid = ({
  workspaceId,
  scanId,
  vulnerabilities,
  selectedVulnerabilityIds,
  onChangeSelectedVulnerabilities,
  refreshVulnerabilityList,
}: IVulnerabilityTableProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.vulnerabilities",
  });

  const headers: ITableHeaderPropItem[] = [
    { name: "", align: "left" },
    { name: t("headers.severity"), align: "left" },
    { name: t("headers.name"), align: "left" },
    { name: t("headers.cvssScore"), align: "left" },
    { name: t("headers.asset"), align: "left" },
    { name: t("headers.status"), align: "left" },
  ];

  const onChangeVulnerabilityStatus = (
    vulnerabilityId: number,
    subStatus: string
  ) => {
    vulnerabilitiesServices
      .update_vulnerability(workspaceId, vulnerabilityId, {
        sub_status: subStatus,
      })
      .then(() => {
        refreshVulnerabilityList();
        createToast({
          type: "success",
          message: t("notification.statusUpdated.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.statusUpdated.fail"),
        });
      });
  };

  return (
    <div className="grid grid-cols-[2.75rem_3fr_8fr_2fr_4fr_4fr]">
      <TableHeader
        title="scanDetails-vulnerabilitiesTab"
        headerProps={headers}
      />
      {vulnerabilities.map((item) => (
        <VulnerabilityTableRow
          key={`tscanDetails-vulnerabilitiesTab-tr${item.id}`}
          data={item}
          workspaceId={workspaceId}
          scanId={scanId}
          selectedVulnerabilityIds={selectedVulnerabilityIds}
          onChangeSelectedVulnerabilities={onChangeSelectedVulnerabilities}
          onChangeVulnerabilityStatus={onChangeVulnerabilityStatus}
        />
      ))}
    </div>
  );
};

export default VulnerabilityTableGrid;
