// Libraries
import { useEffect, useState } from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { generatePath, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Resources
import { ReactComponent as NoDocumentSadIllustration } from "#src/assets/images/illustrations/no-document-sad.svg";
import usFlag from "#src/assets/images/flags/us.png";
import vnFlag from "#src/assets/images/flags/vn.png";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Modal } from "#src/common/system/Modal";
import { Flag } from "#src/common/Flag";
import LoadingState from "#src/common/system/LoadingState";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { Button } from "#src/common/system/Button";
import { simpleTokenParser } from "#src/utils/common";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";

// APi-related
import type {
  ITrustCenterPolicyDetail,
  ITrustCenterPolicyItem,
} from "#src/services/trustCenter/policy";
import trustCenterServices from "#src/services/trustCenter";

interface ILanguageSectionProps {
  workspaceId: string;
  policyId: number;
  details: ITrustCenterPolicyDetail["details"][number] | undefined;
  flagProp: string;
  companyShortName: string | undefined;
  titlePlaceholder: string;
  notAddedThisLanguage: string;
}

const LanguageSection = ({
  workspaceId,
  policyId,
  details,
  flagProp,
  companyShortName,
  titlePlaceholder,
  notAddedThisLanguage,
}: ILanguageSectionProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "policy.detailModal",
  });

  return (
    <div className="flex flex-col gap-3 flex-1">
      <div className="flex items-center gap-3 font-medium-16">
        <Flag pngProp={flagProp} />
        {details ? details.name : titlePlaceholder}
      </div>
      {details ? (
        <p
          className="markdown-body font-regular-14-body overflow-auto"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              marked.parse(
                companyShortName
                  ? simpleTokenParser(details.description, {
                      companyShortName,
                    })
                  : details.description
              ) as string
            ),
          }}
        />
      ) : (
        <div className="flex-1 flex flex-col items-center justify-center gap-6">
          <div className="flex flex-col items-center">
            <NoDocumentSadIllustration className="w-[9.25rem] h-[9.25rem]" />
            <h4>{notAddedThisLanguage}</h4>
          </div>
          <Link
            to={generatePath(pathname.EDIT_TRUST_CENTER_POLICY, {
              workspaceId,
              policy: policyId,
            })}
          >
            <Button>{t("button.addNow")}</Button>
          </Link>
        </div>
      )}
    </div>
  );
};

interface ITrustCenterPolicyDetailModalProps {
  workspaceId: string;
  open: boolean;
  onClose: () => void;
  selectedPolicy: ITrustCenterPolicyItem | null;
}

const TrustCenterPolicyDetailModal = ({
  workspaceId,
  open,
  onClose,
  selectedPolicy,
}: ITrustCenterPolicyDetailModalProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "policy.detailModal",
  });

  const locale = i18next.language;

  const [policyDetail, setPolicyDetail] =
    useState<ITrustCenterPolicyDetail | null>(null);

  const onCloseAndCleanup = () => {
    onClose();
    setPolicyDetail(null);
  };

  useEffect(() => {
    if (workspaceId && selectedPolicy) {
      trustCenterServices
        .retrieve_trust_center_policy(workspaceId, selectedPolicy.id)
        .then((response) => {
          setPolicyDetail(response);
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId, selectedPolicy]);

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  const companyShortName = currentWorkspace
    ? currentWorkspace.short_name
      ? currentWorkspace.short_name
      : currentWorkspace.name
    : undefined;

  const enDetails = policyDetail
    ? policyDetail.details.find((policyLang) => policyLang.lang === "en")
    : undefined;
  const viDetails = policyDetail
    ? policyDetail.details.find((policyLang) => policyLang.lang === "vi")
    : undefined;

  return (
    <Modal
      open={open}
      onChangeOpen={onCloseAndCleanup}
      className="w-[90rem] max-w-[80vw] h-[48rem] max-h-[90vh]"
    >
      {policyDetail ? (
        <>
          <h2>{t("title")}</h2>
          <div className="flex justify-between gap-6 overflow-hidden h-full">
            {/* Swap places depends on current language. */}
            {locale === "vi" ? (
              <>
                <LanguageSection
                  workspaceId={workspaceId}
                  policyId={policyDetail.id}
                  details={viDetails}
                  flagProp={vnFlag}
                  companyShortName={companyShortName}
                  titlePlaceholder={t("section.titlePlaceholder.vietnamese")}
                  notAddedThisLanguage={t(
                    "section.notAddedInThisLanguage.vietnamese"
                  )}
                />
                <hr className="border border-l-light-grey" />
                <LanguageSection
                  workspaceId={workspaceId}
                  policyId={policyDetail.id}
                  details={enDetails}
                  flagProp={usFlag}
                  companyShortName={companyShortName}
                  titlePlaceholder={t("section.titlePlaceholder.english")}
                  notAddedThisLanguage={t(
                    "section.notAddedInThisLanguage.english"
                  )}
                />
              </>
            ) : (
              <>
                <LanguageSection
                  workspaceId={workspaceId}
                  policyId={policyDetail.id}
                  details={enDetails}
                  flagProp={usFlag}
                  companyShortName={companyShortName}
                  titlePlaceholder={t("section.titlePlaceholder.english")}
                  notAddedThisLanguage={t(
                    "section.notAddedInThisLanguage.english"
                  )}
                />
                <hr className="border border-l-light-grey" />
                <LanguageSection
                  workspaceId={workspaceId}
                  policyId={policyDetail.id}
                  details={viDetails}
                  flagProp={vnFlag}
                  companyShortName={companyShortName}
                  titlePlaceholder={t("section.titlePlaceholder.vietnamese")}
                  notAddedThisLanguage={t(
                    "section.notAddedInThisLanguage.vietnamese"
                  )}
                />
              </>
            )}
          </div>
        </>
      ) : (
        <LoadingState />
      )}
    </Modal>
  );
};

export default TrustCenterPolicyDetailModal;
