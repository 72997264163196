import type { Params, RouteObject } from "react-router-dom";
import i18next from "i18next";
import { pathname } from "#src/config/pathname";

import MsspBugBounty from "#src/pages/mssp/bugBounty";
import MsspBugBountyDetails from "#src/pages/mssp/bugBounty/details";
import MsspBugBountyDetailsIntegrationSettings from "#src/pages/mssp/bugBounty/integrationSettings";

const breadcrumbTFunc = i18next.getFixedT(null, "layout", "topbar.breadcrumb");

export const msspBugBountyRoute: RouteObject = {
  path: pathname.MSSP_BUG_BOUNTY_PROGRAMS,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("mssp.bugBounty.label"),
      path: pathname.MSSP_BUG_BOUNTY_PROGRAMS,
    }),
  },
  children: [
    {
      index: true,
      element: <MsspBugBounty />,
    },
    {
      path: pathname.MSSP_BUG_BOUNTY_DETAILS,
      handle: {
        crumb: (params: Readonly<Params<string>>) => {
          return {
            getText: () =>
              Object.hasOwn(params, "bugbounty") ? params["bugbounty"] : "---",
            path: pathname.MSSP_BUG_BOUNTY_DETAILS,
          };
        },
      },
      children: [
        {
          index: true,
          element: <MsspBugBountyDetails />,
        },
        {
          path: pathname.MSSP_BUG_BOUNTY_DETAILS_INTEGRATION_SETTINGS,
          handle: {
            crumb: (params: Readonly<Params<string>>) => ({
              getText: () =>
                breadcrumbTFunc("mssp.bugBounty.integration.label") +
                ": " +
                (Object.hasOwn(params, "integration")
                  ? params["integration"]
                  : "---"),
              path: pathname.BUG_BOUNTY_DETAILS_INTEGRATION_SETTINGS,
            }),
          },
          element: <MsspBugBountyDetailsIntegrationSettings />,
        },
      ],
    },
  ],
};
