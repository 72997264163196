import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { ReactComponent as GeneralError } from "#src/assets/images/illustrations/error-general.svg";
import { isManuallyThrownData, isZodError } from "#src/utils/common";
import { useTranslation } from "react-i18next";
import { isAxiosError } from "axios";

const getErrorText = (error: unknown): string => {
  const { t } = useTranslation("common", {
    keyPrefix: "errors",
  });

  if (isZodError(error)) {
    return (
      "Zod Error:\n" +
      error.issues.map((issue) => JSON.stringify(issue)).join("\n")
    );
  }

  if (isRouteErrorResponse(error)) {
    return (
      error.status +
      " " +
      error.statusText +
      (isManuallyThrownData(error.data)
        ? error.data.error +
          (error.data.instruction ? ": " + error.data.instruction : "")
        : JSON.stringify(error.data))
    );
  }
  if (error && isAxiosError(error) && error.status === 401) {
    return t("tokenInvalid");
  }
  return "Unknown Error";
};

const GeneralErrorPage = () => {
  const error = useRouteError();
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center whitespace-pre-wrap">
      <GeneralError />
      {getErrorText(error)}
    </div>
  );
};

export default GeneralErrorPage;
