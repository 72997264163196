import { useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { IFilterItem } from "#src/@types/common";
import constants from "#src/config/constants";
import devicesServices, { type IHostDetailItem } from "#src/services/devices";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import {
  ListVulnModal,
  SoftwareFilter,
  SoftwareTable,
} from "#src/components/devices/hosts/details/v2/software";
import { IHostSoftwareItem } from "#src/services/devices/softwares";

const HostSoftware = () => {
  const { hostDetails, workspaceId } = useOutletContext<{
    hostDetails: IHostDetailItem;
    workspaceId: string;
    hostId: string;
  }>();

  if (!hostDetails) return null;

  // ----------------------------- DATA -----------------------------

  // Filtering
  const [vulnerable, setVulnerable] = useState<IFilterItem | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  // Pagination & sorting
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);
  const [sortOrder, setSortOrder] = useState<string>("");

  // Modal
  const [isVulnModalOpen, setIsVulnModalOpen] = useState<boolean>(false);
  const [selectedSoftware, setSelectedSoftware] =
    useState<IHostSoftwareItem | null>(null);

  // ----------------------------- COMPUTED -----------------------------

  const fetchParams = useMemo<
    Parameters<typeof devicesServices.list_host_softwares>
  >(
    () => [
      workspaceId,
      hostDetails.id,
      {
        vulnerable: vulnerable?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
        sort: sortOrder ?? undefined,
      },
    ],
    [
      workspaceId,
      hostDetails,
      vulnerable,
      searchKeyword,
      selectedPage,
      pageSize,
      sortOrder,
    ]
  );

  // ----------------------------- METHODS -----------------------------

  // ----------------------------- HOOKS -----------------------------

  const {
    list: hostSoftwareList,
    count: hostSoftwareCount,
    isLoading,
  } = useFetchPaginated(devicesServices.list_host_softwares, fetchParams);

  // --------------- RENDER ---------------

  return (
    <>
      {/* Filter */}
      <SoftwareFilter
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        setSelectedPage={setSelectedPage}
        vulnerable={vulnerable}
        setVulnerable={setVulnerable}
      />
      {/* Filter end */}

      {/* Table */}
      <SoftwareTable
        hostOsFamily={hostDetails.os.family}
        hostSoftwareList={hostSoftwareList || []}
        isLoading={isLoading}
        hostSoftwareCount={hostSoftwareCount}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        workspaceId={workspaceId}
        showVulnModal={(item: IHostSoftwareItem) => {
          setSelectedSoftware(item);
          setIsVulnModalOpen(true);
        }}
      />
      {/* Table end */}

      {/* Vuln modal */}
      <ListVulnModal
        isOpen={isVulnModalOpen}
        closeModal={() => setIsVulnModalOpen(false)}
        info={selectedSoftware}
      />
      {/* Vuln modal end */}
    </>
  );
};

export default HostSoftware;
