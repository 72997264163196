interface IDataLeakHardcodedPlan {
  planName: string;
  numberOfUser: "unlimited";
  typeOfKeyword: "domain" | "unlimited";
  // amountOfResult should say "x percentage" | "x%"
  amountOfResult: string;
  checkFalsePositive: boolean;
  optimisedKeyword: "self" | "supported";
  checkValidResult: "self" | "supported";
  periodicReport: boolean;
  providerMonitoring: boolean;
  wildcardSearch: boolean;
  realtimeNotification: boolean;
  exportResult: boolean;
}

export const pricingTableData: IDataLeakHardcodedPlan[] = [
  {
    planName: "free",
    numberOfUser: "unlimited",
    typeOfKeyword: "domain",
    amountOfResult: "30%",
    checkFalsePositive: false,
    optimisedKeyword: "self",
    checkValidResult: "self",
    periodicReport: false,
    providerMonitoring: false,
    wildcardSearch: true,
    realtimeNotification: true,
    exportResult: true,
  },
  {
    planName: "basic",
    numberOfUser: "unlimited",
    typeOfKeyword: "domain",
    amountOfResult: "100%",
    checkFalsePositive: false,
    optimisedKeyword: "self",
    checkValidResult: "self",
    periodicReport: false,
    providerMonitoring: true,
    wildcardSearch: true,
    realtimeNotification: true,
    exportResult: true,
  },
  {
    planName: "pro",
    numberOfUser: "unlimited",
    typeOfKeyword: "unlimited",
    amountOfResult: "100%",
    checkFalsePositive: true,
    optimisedKeyword: "supported",
    checkValidResult: "supported",
    periodicReport: true,
    providerMonitoring: true,
    wildcardSearch: true,
    realtimeNotification: true,
    exportResult: true,
  },
  {
    planName: "enterprise",
    numberOfUser: "unlimited",
    typeOfKeyword: "unlimited",
    amountOfResult: "100%",
    checkFalsePositive: true,
    optimisedKeyword: "supported",
    checkValidResult: "supported",
    periodicReport: true,
    providerMonitoring: true,
    wildcardSearch: true,
    realtimeNotification: true,
    exportResult: true,
  },
];
