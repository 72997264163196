// Libraries
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

// General
import { TAILWIND_COLORS } from "#src/utils/common";
import type { IChartPlotProps } from "#src/@types/chart";

// Components
import DonutChart from "#src/common/charts/Donut";
import ChartLegends from "#src/common/helper/charts/legends";
import { getChartTooltip } from "#src/common/helper/charts/tooltip";

import { type IDataLeakOverviewByStatus } from "#src/services/dataLeak";

interface IOverviewLeaksByStatusProps {
  overviewByStatus: IDataLeakOverviewByStatus;
}

const OverviewLeaksByStatus = ({
  overviewByStatus,
}: IOverviewLeaksByStatusProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "overview.leaksByStatus",
  });
  const { t: tCommon } = useTranslation();

  const donutChartData: IChartPlotProps[] = useMemo(() => {
    return [
      {
        id: "new",
        name: tCommon("filter.vulnerabilityStatus.new"),
        value: overviewByStatus?.new || 0,
        fill: TAILWIND_COLORS["status-new"],
      },
      {
        id: "triaged",
        name: tCommon("filter.vulnerabilityStatus.triaged"),
        value: overviewByStatus?.triaged || 0,
        fill: TAILWIND_COLORS["status-triaged"],
      },
      {
        id: "resolved",
        name: tCommon("filter.vulnerabilityStatus.resolved"),
        value: overviewByStatus?.resolved || 0,
        fill: TAILWIND_COLORS["status-resolved"],
      },
      {
        id: "unresolved",
        name: tCommon("filter.vulnerabilityStatus.unresolved"),
        value: overviewByStatus?.unresolved || 0,
        fill: TAILWIND_COLORS["status-unresolved"],
      },
      {
        id: "duplicate",
        name: tCommon("filter.vulnerabilityStatus.duplicate"),
        value: overviewByStatus?.duplicate || 0,
        fill: TAILWIND_COLORS["status-duplicate"],
      },
      {
        id: "rejected",
        name: tCommon("filter.vulnerabilityStatus.rejected"),
        value: overviewByStatus?.rejected || 0,
        fill: TAILWIND_COLORS["status-rejected"],
      },
    ];
  }, [overviewByStatus, tCommon]);

  return (
    <div className="rounded-md bg-bright-grey px-6 py-4 w-full flex flex-col gap-8">
      <h2 className="font-medium-20">{t("title")}</h2>
      <div className="flex items-center gap-12">
        <DonutChart
          data={donutChartData}
          tooltip={getChartTooltip}
          height={"10.25rem"}
          width={"10.25rem"}
          unit={t("ticket")}
        />
        <div className="flex flex-col items-center gap-3">
          <ChartLegends
            data={donutChartData}
            keyTemplate="trustCenter-control-overviewChart"
          />
        </div>
      </div>
    </div>
  );
};

export default OverviewLeaksByStatus;
