// Libraries
import { useState } from "react";
import { generatePath } from "react-router";
import { createSearchParams, Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Button } from "#src/common/system/Button";
import Loader from "#src/common/system/Loader";
import { Modal } from "#src/common/system/Modal";
import { useInterval } from "#src/hooks/useInterval";
import { Accordion } from "#src/common/system/Accordion";

// API-related
import paymentService from "#src/services/payment";
import { PaymentStatusValue } from "#src/config/filter/workspace/value";

// Children
import PaymentSuccessModal from "./SuccessModal";
import PaymentFailureModal from "./FailureModal";
import { BillingTabEnum } from "#src/pages/workspace/billing";

const WAIT_TIME = 60;
const RECHECK_EVERY = 6;

interface IPaymentConfirmModalProps {
  workspaceId: string;
  open: boolean;
  onChangeOpen: (value: boolean) => void;
  paymentInfo: Awaited<
    ReturnType<typeof paymentService.create_subscription_banking>
  > | null;
  settingPath: string;
  paymentType?: string;
}

const PaymentConfirmModal = ({
  workspaceId,
  open,
  onChangeOpen,
  paymentInfo,
  settingPath,
  paymentType,
}: IPaymentConfirmModalProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.payment.confirmModal",
  });

  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openFailureModal, setOpenFailureModal] = useState<boolean>(false);
  const [count, setCount] = useState<number>(WAIT_TIME);

  const onCloseAndCleanup = () => {
    onChangeOpen(false);
    // When we close the modal, reset the countdown back to 60
    setCount(WAIT_TIME);
  };

  const onCheckPayment = () => {
    if (paymentInfo) {
      paymentService
        .retrieve_payment(workspaceId, paymentInfo.payment_id)
        .then((response) => {
          if (response.status === PaymentStatusValue.PAID) {
            setTimeout(() => {
              setOpenSuccessModal(true);
              onChangeOpen(false);
            }, 1500);
            return;
          }
          if (response.status === PaymentStatusValue.PROCESSING) {
            return;
          }
          // TODO: Should throw error instead?
          console.log("unexpected response status", response.status);
        });
    }
  };

  useInterval(
    () => {
      setCount((prev) => (prev > 0 ? prev - 1 : 0));
      if ((WAIT_TIME - count) % RECHECK_EVERY === 0 && count > 0) {
        onCheckPayment();
      }
    },
    open ? 1000 : null
  );

  return (
    <>
      <Modal open={open} onChangeOpen={onCloseAndCleanup}>
        <div className="flex flex-col gap-6 items-center">
          <div className="flex flex-col gap-3 items-center text-center">
            <Loader />
            <h2>{t("checking")}</h2>
            <div className="font-regular-16-body whitespace-pre-line">
              <Trans
                t={t}
                i18nKey={"instruction.afterSomeSeconds"}
                count={count}
                components={{ b: <b className="text-dark-blue" /> }}
              />
              {t("instruction.ifNotRedirect")}
              <a>support@cystack.net</a>
            </div>
          </div>
          <Accordion expanded={count <= 0}>
            <Link
              to={{
                pathname: generatePath(pathname.WORKSPACE_BILLING, {
                  workspaceId,
                }),
                search: createSearchParams({
                  tab: BillingTabEnum.ORDER,
                }).toString(),
              }}
            >
              <Button size="large" className="w-fit">
                {t("button.backToHome")}
              </Button>
            </Link>
          </Accordion>
        </div>
      </Modal>
      <PaymentSuccessModal
        workspaceId={workspaceId}
        open={openSuccessModal}
        onChangeOpen={(value) => {
          setOpenSuccessModal(value);
        }}
        settingPath={settingPath}
        paymentType={paymentType}
      />
      <PaymentFailureModal
        open={openFailureModal}
        onChangeOpen={(value) => {
          setOpenFailureModal(value);
        }}
      />
    </>
  );
};

export default PaymentConfirmModal;
