// Libraries
import { useTranslation } from "react-i18next";
import { Dropdown, Tooltip } from "@lockerpm/design";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as ImageCircleFill } from "#src/assets/images/icons/image-circle-fill.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as SealWarningFill } from "#src/assets/images/icons/seal-warning-fill.svg";

// General
import urls from "#src/config/urls";
import patterns from "#src/config/patterns";

// Components
import { ObjectImage } from "#src/common/system/Object";
import DropdownTriggerButton from "#src/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import Loader from "#src/common/system/Loader";

// API-related
import {
  type DataLeakDurationValue,
  DataLeakPlanValue,
  durationItems,
  productPlanItems,
} from "./enum";

export interface IKeywordPricingItem {
  productPlanItem: {
    key: string;
    value: DataLeakPlanValue;
    getLabel: () => string;
  };
  durationItem: {
    key: string;
    value: DataLeakDurationValue;
    getLabel: () => string;
  };
  keyword: string;
  price: string | undefined;
  verification: boolean | undefined;
}

interface IDataLeakAddKeywordFindAndSelectKeywordCardProps {
  data: IKeywordPricingItem | null;
  onRemoveKeyword: ((selected: string) => void) | null;
  onChangeKeywordInfo: (changedKeyword: IKeywordPricingItem) => void;
}

const DataLeakAddKeywordFindAndSelectKeywordCard = ({
  data,
  onRemoveKeyword,
  onChangeKeywordInfo,
}: IDataLeakAddKeywordFindAndSelectKeywordCardProps) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "addKeyword.findAndSelect",
  });

  const logoSrc = data
    ? urls.IMAGE_LOGO_SERVER_URL.replace("size=35", "size=224").replace(
        ":address",
        data.keyword
      )
    : "";

  return (
    <div className="bg-bright-grey p-6 rounded-md flex flex-col gap-3">
      <div className="flex items-start justify-between gap-6">
        <div className="flex items-center gap-3">
          <ObjectImage
            data={logoSrc}
            key={logoSrc}
            className="h-[3.75rem] w-[3.75rem] min-w-[3.75rem] outline outline-1 outline-light-grey rounded-md"
          >
            <div className="h-[3.75rem] w-[3.75rem] min-w-[3.75rem] bg-white rounded-md flex items-center justify-center">
              <ImageCircleFill className="h-12 w-12 fill-medium-grey" />
            </div>
          </ObjectImage>
          <span className="font-medium-18 break-all">
            {data ? data.keyword : null}
          </span>
          {data === null || data.verification === undefined ? (
            <Loader baseSize={1.5} />
          ) : data.verification ? null : (
            <Tooltip
              title={
                <div className="font-medium-12 text-center">
                  {t("selectedKeywords.tooltip.unverifiedKeyword")}
                </div>
              }
            >
              <SealWarningFill className="h-6 w-6 min-w-[1.5rem] fill-severity-medium" />
            </Tooltip>
          )}
        </div>
        {onRemoveKeyword ? (
          <button
            className="m-1 h-6 w-6 min-w-[1.5rem] flex items-center justify-center border border-warning-red-40% bg-warning-red-5% rounded-full"
            onClick={() => {
              if (data) {
                onRemoveKeyword(data.keyword);
              }
            }}
          >
            <CloseLine className="w-4 h-4 fill-warning-red-danger" />
          </button>
        ) : null}
      </div>
      <div className="flex flex-wrap items-center justify-between gap-3">
        <div className="flex items-center justify-between gap-3 flex-1">
          <span className="text-hard-grey">
            {t("selectedKeywords.plan.label")}
          </span>
          {data && patterns.DOMAIN.test(data.keyword) ? (
            <Dropdown
              menu={{
                items: productPlanItems.map((planItem) => ({
                  key: planItem.key,
                  label: (
                    <DropdownItemLabel
                      selected={planItem.key === data.productPlanItem.key}
                      syncWidthId="plan-dropdown"
                    >
                      {planItem.getLabel()}
                    </DropdownItemLabel>
                  ),
                  onClick: () => {
                    onChangeKeywordInfo({ ...data, productPlanItem: planItem });
                  },
                })),
              }}
              trigger={["click"]}
            >
              <DropdownTriggerButton
                id="plan-dropdown"
                className="w-full max-w-[12rem] bg-bright-grey-subline"
              >
                {data.productPlanItem.getLabel()}
                <ArrowDownSLine />
              </DropdownTriggerButton>
            </Dropdown>
          ) : (
            <Tooltip
              title={
                <div className="font-medium-12 text-center">
                  {t("selectedKeywords.tooltip.canOnlyChoosePro")}
                </div>
              }
            >
              <div className="flex gap-2 text-left items-center justify-between px-4 my-0.5 h-11 text-dark-blue text-opacity-50 bg-bright-grey-subline rounded-md w-full max-w-[12rem]">
                {data ? data.productPlanItem.getLabel() : null}
              </div>
            </Tooltip>
          )}
        </div>
        {data === null ||
        data.productPlanItem.value === DataLeakPlanValue.FREE ? (
          <div className="flex-1 h-full min-w-[10rem]" />
        ) : (
          <div className="flex items-center justify-between gap-3 flex-1">
            <span className="whitespace-nowrap text-hard-grey">
              {t("selectedKeywords.duration.label")}
            </span>
            <Dropdown
              menu={{
                items: durationItems.map((durationItem) => ({
                  key: durationItem.key,
                  label: (
                    <DropdownItemLabel
                      selected={durationItem.key === data.durationItem.key}
                      syncWidthId="duration-dropdown"
                    >
                      {durationItem.getLabel()}
                    </DropdownItemLabel>
                  ),
                  onClick: () => {
                    onChangeKeywordInfo({ ...data, durationItem });
                  },
                })),
              }}
              trigger={["click"]}
            >
              <DropdownTriggerButton
                id="duration-dropdown"
                className="w-full max-w-[12rem] bg-bright-grey-subline whitespace-nowrap"
              >
                {data.durationItem.getLabel()}
                <ArrowDownSLine />
              </DropdownTriggerButton>
            </Dropdown>
          </div>
        )}
      </div>
      <div className="min-h-[2.75rem] flex flex-wrap items-center justify-between gap-3">
        <span className="text-hard-grey">
          {t("selectedKeywords.tempPrice")}
        </span>
        {data === null || data.price === undefined ? (
          <Loader baseSize={1.5} />
        ) : (
          <span className="font-medium-20 text-right">
            <b>{data.price}</b>
          </span>
        )}
      </div>
    </div>
  );
};

export default DataLeakAddKeywordFindAndSelectKeywordCard;
