import { Dropdown } from "@lockerpm/design";
import { ChevronDown, CornerDownRight } from "@untitled-ui/icons-react";
import { ButtonV2 } from "#src/commonV2/Button";
import { InputV2 } from "#src/commonV2/Input";
import { Dispatch, SetStateAction } from "react";
import { IFilterItemV2 } from "../types";
import { acceptedDateUnitList } from "./enums";
import { DropdownItemRenderV2 } from "#src/commonV2/antdHelpers/DropdownItemRender";

interface IFilterByDateLastXDaysProps {
  props: {
    count: number;
    selectedUnit: IFilterItemV2;
  };
  setProps: Dispatch<
    SetStateAction<{ count: number; selectedUnit: IFilterItemV2 }>
  >;
}

export const FilterByDateLastXDays = ({
  props: { count, selectedUnit },
  setProps,
}: IFilterByDateLastXDaysProps) => {
  return (
    <div className="flex items-center justify-between gap-2">
      <CornerDownRight className="h-6 w-6 min-w-[1.5rem] text-brand-v2" />
      <InputV2
        className="w-[4rem] flex-1 text-right"
        type="number"
        value={count}
        onChange={(e) => {
          setProps((prev) => ({ ...prev, count: parseInt(e.target.value) }));
        }}
      />
      <Dropdown
        menu={{
          items: acceptedDateUnitList.map((item) => ({
            key: item.key,
            value: item.value,
            label: <DropdownItemRenderV2 item={item} selected={selectedUnit} />,
          })),
          onClick: (obj) => {
            const newSelected = acceptedDateUnitList.find(
              (item) => item.key === obj.key
            );
            if (newSelected) {
              setProps((prev) => ({ ...prev, selectedUnit: newSelected }));
            }
          },
        }}
        placement="bottomRight"
        trigger={["click"]}
      >
        <ButtonV2
          variant="secondary"
          color="gray"
          className="outline outline-2 outline-offset-2 outline-transparent focus:outline-brand-v2-400 w-28"
        >
          <div className="flex justify-between items-center gap-2 w-full">
            <span>{selectedUnit.getLabel()}</span>
            <ChevronDown className="h-5 w-5 text-gray-v2-700" />
          </div>
        </ButtonV2>
      </Dropdown>
    </div>
  );
};
