import { generateKeyString } from "#src/utils/common";
import { ToastType } from "./enum";

type ToastStatus = "stable" | "dismissed" | "destroyed";

/** The name is Bon, Jambon. */
interface WrittenOnTheToast {
  type: ToastType;
  message: string;
  detail?: string;
}

export interface InternalToastProps extends WrittenOnTheToast {
  id: string;
  status: ToastStatus;
}

const observers: Function[] = [];
let toasts: InternalToastProps[] = [];

/** Observer object made for React useEffect: the subscribe function returns a clean up function which unsubscribes when React components unmount. */
export const ToastObservable = Object.freeze({
  notify: (data: InternalToastProps) => {
    observers.forEach((observer) => observer(data));
  },

  subscribe: (f: (typeof observers)[number]) => {
    observers.push(f);
    return () => {
      [...observers].forEach((observer, index) => {
        if (observer === f) {
          observers.splice(index, 1);
        }
      });
    };
  },
});

/** Toaster manager: we have a list of toasts, create, dismiss and remove function, all connected to an Observer */
export const ToastState = {
  createToast: (newToast: WrittenOnTheToast) => {
    const internalToast: InternalToastProps = {
      ...newToast,
      id: generateKeyString(),
      status: "stable",
    };
    toasts.push(internalToast);
    ToastObservable.notify(internalToast);
  },

  dismissToast: (dismissedToast: InternalToastProps) => {
    ToastObservable.notify({ ...dismissedToast, status: "dismissed" });
  },

  destroyToast: (destroyingToast: InternalToastProps) => {
    toasts = toasts.filter((t) => t.id === destroyingToast.id);
    ToastObservable.notify({ ...destroyingToast, status: "destroyed" });
  },
};
