import { useMemo, useState } from "react";
import { IHostDetailItem } from "#src/services/devices";
import { useOutletContext } from "react-router-dom";
import { IFilterItem } from "#src/@types/common";
import constants from "#src/config/constants";
import devicesServices from "#src/services/devices";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { PolicyTable } from "#src/components/devices/hosts/details/v2/policy/PolicyTable";
import { PolicyFilter } from "#src/components/devices/hosts/details/v2/policy/PolicyFilter";

const HostPolicies = () => {
  const { hostDetails, workspaceId } = useOutletContext<{
    hostDetails: IHostDetailItem;
    workspaceId: string;
    hostId: string;
  }>();

  if (!hostDetails) return null;

  // ----------------------------- DATA -----------------------------

  const [policyCompliance, setPolicyCompliance] = useState<IFilterItem | null>(
    null
  );

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  // --------------- COMPUTED ---------------

  const fetchParams = useMemo<
    Parameters<typeof devicesServices.list_host_policies>
  >(
    () => [
      workspaceId,
      hostDetails.id,
      {
        policy_compliance_status: policyCompliance?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      hostDetails,
      policyCompliance,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  // --------------- HOOKS ---------------

  const {
    list: hostPolicyList,
    count: hostPolicyCount,
    isLoading,
  } = useFetchPaginated(devicesServices.list_host_policies, fetchParams);

  // --------------- RENDER ---------------

  return (
    <>
      {/* Filter */}
      <PolicyFilter
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        setSelectedPage={setSelectedPage}
        policyCompliance={policyCompliance}
        setPolicyCompliance={setPolicyCompliance}
      />
      {/* Filter end */}

      {/* Table */}
      <PolicyTable
        hostPolicyList={hostPolicyList || []}
        isLoading={isLoading}
        hostPolicyCount={hostPolicyCount}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      {/* Table end */}
    </>
  );
};

export default HostPolicies;
