import type { Params, RouteObject } from "react-router-dom";
import i18next from "i18next";
import { helperPathname, pathname } from "#src/config/pathname";

import TrustCenterIndexPage from "#src/pages/trustCenter";
import TrustCenterPolicy from "#src/pages/trustCenter/policy";
import AddTrustCenterPolicy from "#src/pages/trustCenter/policy/add";
import EditTrustCenterPolicy from "#src/pages/trustCenter/policy/edit";
import TrustCenterCompliance from "#src/pages/trustCenter/compliance";
import TrustCenterControl from "#src/pages/trustCenter/control";
import TrustCenterDocument from "#src/pages/trustCenter/document";
import TrustCenterThirdParty from "#src/pages/trustCenter/thirdPartyService";
// This should NOT have the `/index` part, but CRA sucks and causes error without it (sometimes :D)
// @see https://github.com/facebook/create-react-app/discussions/11278#discussioncomment-1780169
import { TrustCenterFaq } from "#src/pages/trustCenter/faq/index";
import AddTrustCenterFaq from "#src/pages/trustCenter/faq/add";
import EditTrustCenterFaq from "#src/pages/trustCenter/faq/edit";
import TrustCenterUpdates from "#src/pages/trustCenter/update";
import AddTrustCenterUpdate from "#src/pages/trustCenter/update/add";
import EditTrustCenterUpdate from "#src/pages/trustCenter/update/edit";

import TrustCenterConfig from "#src/pages/trustCenter/configuration";
import TrustCenterRegisterDomain from "#src/pages/trustCenter/registerDomain";

const breadcrumbTFunc = i18next.getFixedT(null, "layout", "topbar.breadcrumb");

export const trustCenterRoute: RouteObject = {
  path: helperPathname.TRUST_CENTER,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("trustCenter.label"),
      path: pathname.TRUST_CENTER_POLICY,
    }),
  },
  element: <TrustCenterIndexPage />,
  children: [
    {
      path: pathname.TRUST_CENTER_POLICY,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("trustCenter.policy.label"),
          path: pathname.TRUST_CENTER_POLICY,
        }),
      },
      children: [
        {
          index: true,
          element: <TrustCenterPolicy />,
        },
        {
          path: pathname.ADD_TRUST_CENTER_POLICY,
          handle: {
            crumb: () => ({
              getText: () =>
                breadcrumbTFunc("trustCenter.policy.addPolicy.label"),
              path: pathname.ADD_TRUST_CENTER_POLICY,
            }),
          },
          element: <AddTrustCenterPolicy />,
        },
        {
          path: pathname.EDIT_TRUST_CENTER_POLICY,
          handle: {
            crumb: (params: Readonly<Params<string>>) => ({
              getText: () =>
                breadcrumbTFunc("trustCenter.policy.editPolicy.label") +
                ": " +
                (Object.hasOwn(params, "policy") ? params["policy"] : "---"),
              path: pathname.EDIT_TRUST_CENTER_POLICY,
            }),
          },
          element: <EditTrustCenterPolicy />,
        },
      ],
    },
    {
      path: pathname.TRUST_CENTER_COMPLIANCE,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("trustCenter.compliance.label"),
          path: pathname.TRUST_CENTER_COMPLIANCE,
        }),
      },
      element: <TrustCenterCompliance />,
    },
    {
      path: pathname.TRUST_CENTER_CONTROL,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("trustCenter.control.label"),
          path: pathname.TRUST_CENTER_CONTROL,
        }),
      },
      element: <TrustCenterControl />,
    },
    {
      path: pathname.TRUST_CENTER_DOCUMENT,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("trustCenter.document.label"),
          path: pathname.TRUST_CENTER_DOCUMENT,
        }),
      },
      element: <TrustCenterDocument />,
    },
    {
      path: pathname.TRUST_CENTER_THIRD_PARTY,
      handle: {
        crumb: () => ({
          getText: () =>
            breadcrumbTFunc("trustCenter.thirdPartyServices.label"),
          path: pathname.TRUST_CENTER_THIRD_PARTY,
        }),
      },
      element: <TrustCenterThirdParty />,
    },
    {
      path: pathname.TRUST_CENTER_FAQ,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("trustCenter.faq.label"),
          path: pathname.TRUST_CENTER_FAQ,
        }),
      },
      children: [
        {
          index: true,
          element: <TrustCenterFaq />,
        },
        {
          path: pathname.ADD_TRUST_CENTER_FAQ,
          handle: {
            crumb: () => ({
              getText: () => breadcrumbTFunc("trustCenter.faq.addFaq.label"),
              path: pathname.ADD_TRUST_CENTER_FAQ,
            }),
          },
          element: <AddTrustCenterFaq />,
        },
        {
          path: pathname.EDIT_TRUST_CENTER_FAQ,
          handle: {
            crumb: () => ({
              getText: () => breadcrumbTFunc("trustCenter.faq.editFaq.label"),
              path: pathname.EDIT_TRUST_CENTER_FAQ,
            }),
          },
          element: <EditTrustCenterFaq />,
        },
      ],
    },
    {
      path: pathname.TRUST_CENTER_UPDATE,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("trustCenter.update.label"),
          path: pathname.TRUST_CENTER_UPDATE,
        }),
      },
      children: [
        {
          index: true,
          element: <TrustCenterUpdates />,
        },
        {
          path: pathname.ADD_TRUST_CENTER_UPDATE,
          handle: {
            crumb: () => ({
              getText: () =>
                breadcrumbTFunc("trustCenter.update.addUpdate.label"),
              path: pathname.ADD_TRUST_CENTER_UPDATE,
            }),
          },
          element: <AddTrustCenterUpdate />,
        },
        {
          path: pathname.EDIT_TRUST_CENTER_UPDATE,
          handle: {
            crumb: () => ({
              getText: () =>
                breadcrumbTFunc("trustCenter.update.editUpdate.label"),
              path: pathname.EDIT_TRUST_CENTER_UPDATE,
            }),
          },
          element: <EditTrustCenterUpdate />,
        },
      ],
    },
    {
      path: pathname.TRUST_CENTER_CONFIG,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("trustCenter.configuration.label"),
          path: pathname.TRUST_CENTER_CONFIG,
        }),
      },
      element: <TrustCenterConfig />,
    },
    {
      path: pathname.TRUST_CENTER_REGISTER_PRIVATE_DOMAIN,
      element: <TrustCenterRegisterDomain />,
    },
  ],
};
