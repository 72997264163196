// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import { Button } from "#src/common/system/Button";
import { MarkdownEditor } from "#src/common/system/Input/MarkdownEditor";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import pentestServices, {
  type IPentestProjectDetails,
} from "#src/services/pentest";

interface IPentestDetailsPolicyProps {
  workspaceId: string;
  pentestAlias: string;
  pentestDetails: IPentestProjectDetails;
  onRefresh: (callback?: (result: IPentestProjectDetails) => void) => void;
}

const PentestDetailsPolicy = ({
  workspaceId,
  pentestAlias,
  pentestDetails,
  onRefresh,
}: IPentestDetailsPolicyProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.policy",
  });

  const [policy, setPolicy] = useState<string>(pentestDetails.metadata.en);

  const onSubmit = () => {
    pentestServices
      .update_pentest_project(workspaceId, pentestAlias, {
        metadata: { en: policy, vi: policy },
      })
      .then((response) => {
        if (response.success) {
          onRefresh((response) => {
            setPolicy(response.metadata.en);
          });
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updatePolicy.fail"),
        });
      });
  };

  const onRevert = () => {
    setPolicy(pentestDetails.metadata.en);
  };

  return (
    <section className="flex flex-col gap-6 pt-6">
      <div className="flex flex-col gap-4">
        <h2>{t("title")}</h2>
        <span className="text-hard-grey">{t("info")}</span>
      </div>
      <div className="w-3/4 flex flex-col gap-6">
        <MarkdownEditor
          className="font-regular-14-body bg-bright-blue h-[42rem]"
          value={policy}
          onChange={(e) => {
            setPolicy(e.currentTarget.value);
          }}
          defaultMode="preview"
        />
        <div className="flex justify-end gap-1">
          {pentestDetails.metadata.en !== policy ? (
            <Button variant="warning" onClick={onRevert}>
              {t("button.cancel")}
            </Button>
          ) : null}
          <Button
            variant="secondary"
            onClick={onSubmit}
            disabled={pentestDetails.metadata.en === policy}
          >
            {t("button.save")}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default PentestDetailsPolicy;
