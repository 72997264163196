// Libraries
import { useEffect, useMemo, useState } from "react";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import PaginationBar from "#src/common/helper/PaginationBar";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import trustCenterServices from "#src/services/trustCenter";

// Children
import TrustCenterControlFilter from "./Filter";
import TrustCenterControlTableGrid from "./Table";
import resourceServices, {
  type ITrustCenterControlDomain,
} from "#src/services/resource";

interface ITrustCenterControlTableProps {
  workspaceId: string;
  updateOverview: () => void;
}

const TrustCenterControlTable = ({
  workspaceId,
  updateOverview,
}: ITrustCenterControlTableProps) => {
  const [domainList, setDomainList] = useState<ITrustCenterControlDomain[]>([]);

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [reference, setReference] = useState<IFilterItem | null>(null);
  const [status, setStatus] = useState<IFilterItem | null>(null);
  const [domain, setDomain] = useState<ITrustCenterControlDomain | null>(null);

  const onChangeDomainSelection = (selected: typeof domain) => {
    setDomain(selected);
    setSelectedPage(1);
  };

  const onChangeReferenceSelection = (selected: typeof reference) => {
    setReference(selected);
    setSelectedPage(1);
  };

  const onChangeStatusSelection = (selected: typeof status) => {
    setStatus(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  useEffect(() => {
    const fetchDomainList = () => {
      resourceServices
        .list_trust_center_control_domains()
        .then((response) => {
          setDomainList(response);
          if (response.length >= 1) {
            setDomain(response[0]);
          }
        })
        .catch(apiErrorHandler);
    };
    fetchDomainList();
  }, []);

  const fetchParams = useMemo<
    Parameters<typeof trustCenterServices.list_trust_center_control>
  >(
    () => [
      workspaceId,
      {
        domain_id: domain?.id,
        status: status?.value,
        ref_id: undefined,
        compliance_id: reference?.value,
        policy_id: undefined,
        q: undefined,
        page: undefined,
        size: constants.DEFAULT_PAGE_SIZE,
      },
    ],
    [domain, status, reference, workspaceId]
  );

  const {
    list: controlList,
    count: controlCount,
    isLoading,
    updateData: updateControlList,
  } = useFetchPaginated(
    trustCenterServices.list_trust_center_control,
    fetchParams
  );

  return (
    <>
      <div className="flex flex-col gap-1">
        <TrustCenterControlFilter
          domainList={domainList}
          domain={domain}
          reference={reference}
          status={status}
          searchKeyword={searchKeyword}
          onChangeDomainSelection={onChangeDomainSelection}
          onChangeReferenceSelection={onChangeReferenceSelection}
          onChangeStatusSelection={onChangeStatusSelection}
          onChangeSearchKeyword={onChangeSearchKeyword}
        />
        <TrustCenterControlTableGrid
          workspaceId={workspaceId}
          controlList={controlList}
          isLoading={isLoading}
          onRefresh={() => {
            updateControlList();
            updateOverview();
          }}
        />
      </div>
      <PaginationBar
        numOfResult={controlCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </>
  );
};

export default TrustCenterControlTable;
