import { ButtonV2 } from "#src/commonV2/Button";
import { pathname } from "#src/config/pathname";
import { useWorkspaceList } from "#src/hooks/dataFetchers/useWorkspaceList";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";
import { LogOut01, Plus, Settings01, User01 } from "@untitled-ui/icons-react";
import { RefObject } from "react";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { MenuItemWrapper, WorkspaceItemWrapper } from "./itemWrappers";
import { findLegitPathForChangingWorkspace } from "./utils";
import authServices from "#src/services/auth";
import Cookies from "js-cookie";
import urls from "#src/config/urls";
import { useTranslation } from "react-i18next";

export const SidebarSettingMenu = ({
  containerRef,
  open,
  onChangeOpen,
  workspaceId,
}: {
  containerRef: RefObject<HTMLDivElement>;
  open: boolean;
  onChangeOpen: (value: boolean) => void;
  workspaceId: string;
}) => {
  const { t } = useTranslation("layout", {
    keyPrefix: "sidebar.v2.settingMenu",
  });

  const params = useParams();
  const navigate = useNavigate();

  // --------------- DATA ---------------

  const { workspaceList } = useWorkspaceList();
  const currentWorkspace = useCurrentWorkspace(workspaceId);

  // --------------- RENDER ---------------

  return (
    <div
      ref={containerRef}
      className={`w-full absolute bottom-0 left-[calc(100%_+_0.5rem)]
    shadow-lg border border-gray-v2-200 bg-gray-v2-50 rounded-xl
    transition-all ease-in-out origin-bottom-left max-h-[75vh] overflow-x-auto ${open ? "" : "scale-0"}`}
    >
      <div className="rounded-lg border border-gray-v2-200 bg-white overflow-hidden">
        <div className="p-1.5">
          <Link
            to={generatePath(pathname.ACCOUNT_SETTINGS, { workspaceId })}
            onClick={() => {
              onChangeOpen(false);
            }}
          >
            <MenuItemWrapper title={t("accountSettings")} icon={User01} />
          </Link>
          <Link
            to={generatePath(pathname.WORKSPACE_DETAILS, { workspaceId })}
            onClick={() => {
              onChangeOpen(false);
            }}
          >
            <MenuItemWrapper title={t("workspaceSettings")} icon={Settings01} />
          </Link>
        </div>
        <div className="border-t border-gray-v2-200 py-1.5">
          <div className="px-3 pt-1.5 pb-1 text-xs font-semibold text-gray-v2-600">
            {t("switchWorkspace")}
          </div>
          {/* Max height here is for 6 items. Each item is 3.25rem, gap 0.125rem -> max height = 3.25 * 6 + 0.125 * 5 = 20.125rem. */}
          <div className="px-1.5 flex flex-col gap-0.5 max-h-[10rem] lg:max-h-[13.375rem] xl:max-h-[16.75rem] 2xl:max-h-[20.125rem] overflow-x-auto">
            {currentWorkspace ? (
              <div className="sticky top-0 z-10 bg-white">
                <WorkspaceItemWrapper
                  workspace={currentWorkspace}
                  selected={true}
                />
              </div>
            ) : null}
            {workspaceList
              .filter((ws) => ws.id !== currentWorkspace?.id)
              .map((ws) => (
                <WorkspaceItemWrapper
                  key={ws.id}
                  workspace={ws}
                  selected={false}
                  onClick={() => {
                    const currentPath = findLegitPathForChangingWorkspace();

                    if (currentPath) {
                      navigate(
                        generatePath(currentPath, {
                          ...params,
                          workspaceId: ws.id,
                        })
                      );
                      onChangeOpen(false);
                    }
                  }}
                />
              ))}
          </div>
        </div>
        <Link
          to={pathname.NEW_WORKSPACE}
          className="p-2 pt-0.5 block"
          onClick={() => {
            onChangeOpen(false);
          }}
        >
          <ButtonV2
            variant="secondary"
            color="gray"
            size="sm"
            className="w-full"
          >
            <Plus className="h-5 w-5 min-w-5" />
            {t("button.addWorkspace")}
          </ButtonV2>
        </Link>
      </div>
      <div className="p-1.5 pt-1">
        <MenuItemWrapper
          title={t("signOut")}
          icon={LogOut01}
          onClick={() => {
            authServices.userLogout().then(() => {
              Cookies.remove("access_token");
            });
            // TODO: add a catch here, even though it's funny to fail to log out
            window.location.assign(`${urls.ID_CYSTACK_URL}/login/explore`);
            onChangeOpen(false);
          }}
        />
      </div>
    </div>
  );
};
