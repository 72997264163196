import { SearchBox } from "#src/common/Table/Filter";

interface ITrustCenterFaqFilterProps {
  searchKeyword: string;
  onChangeSearchKeyword: (keyword: string) => void;
}

const TrustCenterFaqFilter = ({
  searchKeyword,
  onChangeSearchKeyword,
}: ITrustCenterFaqFilterProps) => {
  return (
    <div className="flex justify-between gap-1">
      <div></div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default TrustCenterFaqFilter;
