// Libraries
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "#src/store/hooks";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import { SearchBox } from "#src/common/Table/Filter";
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import { TableActionLabel } from "#src/common/Table";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import pentestServices from "#src/services/pentest";
import type { IPentestProjectMember } from "#src/services/pentest/members";

// Children
import AddPentestMemberDrawer from "./AddMemberDrawer";
import { PentestMemberActionEnum } from "../enums";
import MemberItem from "./MemberItem";
import MsspMemberItem from "./MsspMemberItem";
import { pentestMemberRoleItems } from "#src/config/filter/pentest";
import { PentestMemberRoleEnum } from "#src/config/filter/pentest/enum";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

interface IPentestDetailsMembersProps {
  workspaceId: string;
  pentestAlias: string;
}

const PentestDetailsMembers = ({
  workspaceId,
  pentestAlias,
}: IPentestDetailsMembersProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.members",
  });

  const currentUser = useAppSelector((state) => state.auth.userInfo);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [openAddMemberDrawer, setOpenAddMemberDrawer] =
    useState<boolean>(false);

  const actionList = [
    {
      key: PentestMemberActionEnum.AUTO_ASSIGN_SUBMISSION,
      label: (
        <TableActionLabel
          icon={<EditLine />}
          text={t("actions.autoAssignSubmission")}
        />
      ),
    },
    {
      key: PentestMemberActionEnum.DELETE_MEMBER,
      label: (
        <TableActionLabel
          icon={<DeleteBin6Line />}
          text={t("actions.deleteMember")}
          warning
        />
      ),
    },
  ];

  const getActionListByRole = (member: IPentestProjectMember) => {
    return actionList.filter((action) => {
      if (action.key === PentestMemberActionEnum.AUTO_ASSIGN_SUBMISSION) {
        if (
          currentUserRole &&
          ["workspace_owner", "owner", "admin"].includes(currentUserRole) &&
          !member.default_assign
        ) {
          return true;
        }
        return false;
      }
      if (action.key === PentestMemberActionEnum.DELETE_MEMBER) {
        if (
          member.email === currentUser?.email ||
          member.user?.email === currentUser?.email
        ) {
          return false;
        }
        if (currentUserRole === "workspace_owner") {
          return true;
        }
        if (
          currentUserRole === "owner" &&
          ["admin", "developer"].includes(member.role)
        ) {
          return true;
        }
        if (currentUserRole === "admin" && member.role === "developer") {
          return true;
        }
        return false;
      }
      return false;
    });
  };

  const fetchParams = useMemo<
    Parameters<typeof pentestServices.list_pentest_project_members>
  >(
    () => [
      workspaceId,
      pentestAlias,
      {
        q: searchKeyword ? searchKeyword : undefined,
        page: undefined,
        size: undefined,
      },
    ],
    [workspaceId, pentestAlias, searchKeyword]
  );

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const onSetAutoAssign = (member: IPentestProjectMember) => {
    pentestServices
      .update_pentest_project_member(
        workspaceId,
        pentestAlias,
        member.id.toString(),
        { default_assign: true }
      )
      .then((response) => {
        if (response.success) {
          createToast({
            type: "success",
            message: t("notification.autoAssignSubmission.success.title"),
            detail: t("notification.autoAssignSubmission.success.message", {
              username: member.user ? member.user.username : member.email,
            }),
          });
          updateMemberList();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.autoAssignSubmission.fail"),
        });
      });
  };

  const onDeleteMember = (member: IPentestProjectMember) => {
    pentestServices
      .delete_pentest_project_member(
        workspaceId,
        pentestAlias,
        member.id.toString()
      )
      .then(() => {
        createToast({
          type: "success",
          message: t("notification.deleteMember.success.title"),
          detail: t("notification.deleteMember.success.message", {
            username: member.user ? member.user.username : member.email,
            userId: member.id,
          }),
        });
        updateMemberList();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteMember.fail"),
        });
      });
  };

  const onChangeMemberRole = (key: string, member: IPentestProjectMember) => {
    const selectedRole = pentestMemberRoleItems.find(
      (item) => item.key === key
    );

    if (selectedRole) {
      pentestServices
        .update_pentest_project_member(
          workspaceId,
          pentestAlias,
          member.id.toString(),
          { role: selectedRole.value }
        )
        .then((response) => {
          createToast({
            type: "success",
            message: t("notification.changeRole.success.title"),
            detail: t("notification.changeRole.success.message", {
              username: member.user ? member.user.username : member.email,
              role: selectedRole.getLabel(),
            }),
          });
          if (response.success) {
            updateMemberList();
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.changeRole.fail"),
          });
        });
    }
  };

  const onClickActionItem = (key: string, item: IPentestProjectMember) => {
    switch (key) {
      case PentestMemberActionEnum.AUTO_ASSIGN_SUBMISSION:
        onSetAutoAssign(item);
        return;
      case PentestMemberActionEnum.DELETE_MEMBER:
        onDeleteMember(item);
        return;
    }
  };

  const {
    list: memberList,
    count: memberCount,
    isLoading: isLoadingMember,
    updateData: updateMemberList,
  } = useFetchPaginated(
    pentestServices.list_pentest_project_members,
    fetchParams
  );

  const {
    list: msspMemberList,
    count: msspMemberCount,
    isLoading: isLoadingMsspMember,
  } = useFetchPaginated(
    pentestServices.list_pentest_project_mssp_members,
    fetchParams
  );

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  /** Should be one of these [`workspace_owner`, `owner`, `admin`, `developer`] */
  const currentUserRole =
    currentWorkspace?.role === WorkspaceMemberRoleValue.OWNER
      ? "workspace_owner"
      : memberList?.find(
          (member) =>
            member.email === currentUser?.email ||
            member.user?.email === currentUser?.email
        )?.role;

  return (
    <section className="flex flex-col gap-6 pt-6">
      <div className="flex justify-between">
        <div className="flex flex-col gap-4">
          <h2>{t("title")}</h2>
          <span className="text-hard-grey">{t("info")}</span>
        </div>
        <div className="flex gap-2">
          <SearchBox
            searchKeyword={searchKeyword}
            onChangeSearchKeyword={onChangeSearchKeyword}
          />
          <Button
            size="large"
            onClick={() => {
              setOpenAddMemberDrawer(true);
            }}
            disabled={
              !currentUserRole ||
              currentUserRole === PentestMemberRoleEnum.DEVELOPER
            }
          >
            <AddLine />
            {t("button.addMember")}
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col gap-4">
          {isLoadingMember || memberList === null ? (
            <LoadingState />
          ) : (
            <>
              <div className="bg-bright-grey font-medium-18 flex justify-between items-center px-6 h-10">
                {t("projectMembers")}
                <span className="text-primary">{memberCount}</span>
              </div>
              {memberList.length === 0 ? (
                <NoResultsState />
              ) : (
                memberList.map((member) => (
                  <MemberItem
                    key={`pentestDetails-member-${member.id}`}
                    currentUser={currentUser}
                    member={member}
                    actionList={getActionListByRole(member)}
                    onChangeMemberRole={onChangeMemberRole}
                    onClickActionItem={onClickActionItem}
                  />
                ))
              )}
            </>
          )}
        </div>
        <div className="flex flex-col gap-4">
          {isLoadingMsspMember || msspMemberList === null ? (
            <LoadingState />
          ) : (
            <>
              <div className="bg-bright-grey font-medium-18 flex justify-between items-center px-6 h-10">
                {t("msspTeam")}
                <span className="text-primary">{msspMemberCount}</span>
              </div>
              {msspMemberList.length === 0 ? (
                <NoResultsState />
              ) : (
                msspMemberList.map((member) => (
                  <MsspMemberItem
                    key={`pentestDetails-msspMember-${member.id}`}
                    member={member}
                  />
                ))
              )}
            </>
          )}
        </div>
      </div>
      <AddPentestMemberDrawer
        open={openAddMemberDrawer}
        onClose={() => {
          setOpenAddMemberDrawer(false);
        }}
        workspaceId={workspaceId}
        pentestAlias={pentestAlias}
        onRefresh={updateMemberList}
        currentUserRole={currentUserRole}
      />
    </section>
  );
};

export default PentestDetailsMembers;
