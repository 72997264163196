import type { ReactNode } from "react";

export const TableActionLabelV2 = ({
  icon,
  text,
  warning = false,
}: {
  icon: ReactNode;
  text: string;
  warning?: boolean;
}) => {
  return (
    <div
      className={`flex items-center gap-2 p-2 group bg-white hover:bg-gray-v2-50 ${warning ? "text-error-v2-700" : "text-gray-v2"}`}
    >
      {icon}
      <span
        className={`text-md font-medium ${warning ? "text-error-v2-700" : "text-gray-v2-700 group-hover:text-gray-v2-800"}`}
      >
        {text}
      </span>
    </div>
  );
};
