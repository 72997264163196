// Libraries
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { Modal } from "@lockerpm/design";
import i18next from "i18next";

// General
import global from "#src/config/global";
import { pathname } from "#src/config/pathname";

// Components
import LoadingState from "#src/common/system/LoadingState";
import NoResultsState from "#src/common/states/NoResultsState";
import TableHeader, {
  type ITableHeaderPropItem,
} from "#src/common/Table/TableHeader";
import { useCurrentWorkspace } from "#src/hooks/useCurrentWorkspace";
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";
import { ObjectImage } from "#src/common/system/Object";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { ITrustCenterPolicyItem } from "#src/services/trustCenter/policy";
import resourceServices, {
  type ITrustCenterSupportedCompliance,
} from "#src/services/resource";
import trustCenterServices from "#src/services/trustCenter";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";
import { TrustCenterPolicyStatusValue } from "#src/config/filter/trustCenter/value";

// Children
import TrustCenterPolicyTableRow from "./TableRow";
import { trustCenterPolicyActionList } from "./actions";
import { TrustCenterPolicyActionEnum } from "../enum";
import TrustCenterPolicyDetailModal from "./DetailModal";

interface ITrustCenterPolicyTableGridProps {
  workspaceId: string;
  isLoading: boolean;
  policyList: ITrustCenterPolicyItem[] | null;
  onRefresh: () => void;
  sortOrder: string;
  onChangeSortOrder: (order: string) => void;
}

const TrustCenterPolicyTableGrid = ({
  workspaceId,
  isLoading,
  policyList,
  onRefresh,
  sortOrder,
  onChangeSortOrder,
}: ITrustCenterPolicyTableGridProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "policy" });

  const navigate = useNavigate();
  const locale = i18next.language;

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.name"), align: "left" },
    { name: t("headers.policies"), align: "left" },
    {
      name: t("headers.status"),
      align: "left",
      sortingKey: { asc: "status_asc", desc: "status_desc" },
    },
    {
      name: t("headers.lastEdited"),
      align: "left",
      sortingKey: { asc: "updated_time_asc", desc: "updated_time_desc" },
    },
    { name: t("headers.actions"), align: "center" },
  ];

  const [complianceShowingList, setComplianceShowingList] = useState<
    ITrustCenterSupportedCompliance[] | null
  >(null);
  const [policyShowingItem, setPolicyShowingItem] =
    useState<ITrustCenterPolicyItem | null>(null);

  const supportedComplianceParams = useMemo(() => [], []);

  const { list: supportedComplianceList } = useFetchNoPaging(
    resourceServices.list_trust_center_supported_compliance,
    supportedComplianceParams
  );

  const currentWorkspace = useCurrentWorkspace(workspaceId);

  const getFilteredActionList = (data: ITrustCenterPolicyItem) => {
    if (
      currentWorkspace?.role !== WorkspaceMemberRoleValue.OWNER &&
      currentWorkspace?.role !== WorkspaceMemberRoleValue.ADMIN
    ) {
      return [];
    }
    if (data.status === TrustCenterPolicyStatusValue.DRAFT) {
      return trustCenterPolicyActionList.filter(
        (action) => action.key !== TrustCenterPolicyActionEnum.DRAFT
      );
    }
    if (data.status === TrustCenterPolicyStatusValue.PUBLISHED) {
      return trustCenterPolicyActionList.filter(
        (action) => action.key !== TrustCenterPolicyActionEnum.PUBLISH
      );
    }
    return [];
  };

  const onChangeStatus = (
    item: ITrustCenterPolicyItem,
    newStatus: TrustCenterPolicyStatusValue
  ) => {
    trustCenterServices
      .update_trust_center_policy(workspaceId, item.id, {
        compliance: item.compliance,
        status: newStatus,
      })
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updateStatus.fail"),
        });
      });
  };

  const onDeletePolicy = (item: ITrustCenterPolicyItem) => {
    trustCenterServices
      .delete_trust_center_policy(workspaceId, item.id)
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deletePolicy.fail"),
        });
      });
  };

  const onClickActionItems = (key: string, item: ITrustCenterPolicyItem) => {
    switch (key) {
      case TrustCenterPolicyActionEnum.EDIT:
        navigate(
          generatePath(pathname.EDIT_TRUST_CENTER_POLICY, {
            workspaceId,
            policy: item.id,
          })
        );
        return;
      case TrustCenterPolicyActionEnum.DRAFT:
        onChangeStatus(item, TrustCenterPolicyStatusValue.DRAFT);
        return;
      case TrustCenterPolicyActionEnum.PUBLISH:
        onChangeStatus(item, TrustCenterPolicyStatusValue.PUBLISHED);
        return;
      case TrustCenterPolicyActionEnum.DELETE:
        global.confirm(() => {
          onDeletePolicy(item);
        });
        return;
    }
  };

  const onClickViewCompliance = (
    complianceList: ITrustCenterSupportedCompliance[]
  ) => {
    setComplianceShowingList(complianceList);
  };

  const onClickViewDetail = (selectedPolicy: ITrustCenterPolicyItem) => {
    setPolicyShowingItem(selectedPolicy);
  };

  return (
    <>
      {isLoading || policyList === null ? (
        <LoadingState />
      ) : policyList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[6fr_5fr_minmax(10rem,_2fr)_minmax(12rem,_2fr)_minmax(8rem,_1fr)]">
          <TableHeader
            title="trustCenter/policy"
            headerProps={headers}
            sortOrder={sortOrder}
            onChangeSortOrder={onChangeSortOrder}
          />
          {policyList.map((item) => (
            <TrustCenterPolicyTableRow
              key={`trustCenter/policy-tr${item.id}`}
              data={item}
              actionList={getFilteredActionList(item)}
              onClickActionItems={onClickActionItems}
              supportedComplianceList={supportedComplianceList ?? []}
              onClickViewCompliance={onClickViewCompliance}
              onClickViewDetail={onClickViewDetail}
            />
          ))}
        </div>
      )}
      <Modal
        open={complianceShowingList !== null}
        onCancel={() => {
          setComplianceShowingList(null);
        }}
        title={null}
        centered
        footer={null}
        width="44rem"
      >
        <div className="flex flex-wrap gap-2">
          {complianceShowingList?.map((compliance) => (
            // SourceLabel but has a logo and changed some CSS
            <span
              key={`trustCenter-policy-complianceList-${compliance.id}`}
              className={`px-3 py-2 flex items-center gap-3 bg-bright-grey-subline border border-grey rounded-3xl`}
            >
              <ObjectImage
                data={compliance.logo}
                className="h-6 w-6 min-w-[1.5rem] rounded-md"
              />
              <span className="whitespace-nowrap text-ellipsis">
                {locale === "vi" ? compliance.name.vi : compliance.name.en}
              </span>
            </span>
          ))}
        </div>
      </Modal>
      <TrustCenterPolicyDetailModal
        workspaceId={workspaceId}
        open={policyShowingItem !== null}
        onClose={() => {
          setPolicyShowingItem(null);
        }}
        selectedPolicy={policyShowingItem}
      />
    </>
  );
};

export default TrustCenterPolicyTableGrid;
