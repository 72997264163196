// Libraries
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import { disallowConcurrency } from "#src/utils/common";
import TimePopover from "#src/common/TimePopover";
import type { TimeConditionEnum } from "#src/common/TimePopover/TimePopoverContent";
import { FilterDropdown, FilterDropdownSearch } from "#src/common/Table/Filter";
import VulnerabilityStatusFilterDropdown from "#src/common/Table/Filter/StatusFilterDropdown";
import {
  SeverityDropdownItemLabel,
  SeverityDropdownRender,
} from "#src/common/composed/SeverityDropdownHelper";

// Children
import {
  assessmentTypeItems,
  bountyItems,
  visibilityItems,
  archivedItems,
  AssessmentTypeEnum,
} from "#src/config/filterConstants";
import bugBountyServices from "#src/services/bugBounty";
import pentestServices from "#src/services/pentest";
import scansServices from "#src/services/scans";
import {
  severityItems,
  vulnerabilityStatusItems,
} from "#src/config/filter/vulnerability";

interface IVulnerabilityFilterProps {
  tabSelectedStatus: string;
  assessmentType: IFilterItem | null;
  bugBountyProgram: IFilterItem | null;
  bounty: IFilterItem | null;
  pentest: IFilterItem | null;
  visibility: IFilterItem | null;
  subStatus: IFilterItem[] | null;
  severity: IFilterItem | null;
  archived: IFilterItem | null;
  selectedScanId: IFilterItem | null;
  onChangeCreatedTimeConditionValue: (
    condition: string,
    from: number,
    to: number
  ) => void;
  onChangeUpdatedTimeConditionValue: (
    condition: string,
    from: number,
    to: number
  ) => void;
  onChangeAssessmentTypeSelection: (selected: IFilterItem | null) => void;
  onChangeBugBountyProgramSelection: (selected: IFilterItem | null) => void;
  onChangeBountySelection: (selected: IFilterItem | null) => void;
  onChangePentestProgramSelection: (selected: IFilterItem | null) => void;
  onChangeVisibilitySelection: (selected: IFilterItem | null) => void;
  onChangeSubStatusSelection: (selected: IFilterItem[] | null) => void;
  onChangeSeveritySelection: (selected: IFilterItem | null) => void;
  onChangeArchivedSelection: (selected: IFilterItem | null) => void;
  onChangeScanSelection: (selected: IFilterItem | null) => void;
}

const VulnerabilityFilter = ({
  assessmentType,
  bugBountyProgram,
  bounty,
  pentest,
  visibility,
  subStatus,
  severity,
  archived,
  selectedScanId,
  onChangeCreatedTimeConditionValue,
  onChangeUpdatedTimeConditionValue,
  onChangeAssessmentTypeSelection,
  onChangeBugBountyProgramSelection,
  onChangeBountySelection,
  onChangePentestProgramSelection,
  onChangeVisibilitySelection,
  onChangeSubStatusSelection,
  onChangeSeveritySelection,
  onChangeArchivedSelection,
  onChangeScanSelection,
}: IVulnerabilityFilterProps) => {
  const { t } = useTranslation("vulnerabilities", { keyPrefix: "filter" });
  const { workspaceId } = useParams<"workspaceId">();

  const [createdTimeCondition, setCreatedTimeCondition] =
    useState<TimeConditionEnum | null>(null);
  const [createdTimeOpen, setCreatedTimeOpen] = useState<boolean>(false);
  const [createdTimeText, setCreatedTimeText] = useState<string>("");

  const [updatedTimeCondition, setUpdatedTimeCondition] =
    useState<TimeConditionEnum | null>(null);
  const [updatedTimeOpen, setUpdatedTimeOpen] = useState<boolean>(false);
  const [updatedTimeText, setUpdatedTimeText] = useState<string>("");

  // Dropdown list for bug bounty program
  const [programItems, setProgramItems] = useState<IFilterItem[]>([]);

  // Dropdown list for pentest project
  const [pentestItems, setPentestItems] = useState<IFilterItem[]>([]);

  // Scan ID
  const [scanItemList, setScanItemList] = useState<IFilterItem[]>([]);
  const [loadedScanPages, setLoadedScanPages] = useState<number>(0);
  const [totalScanCount, setTotalScanCount] = useState<number>(0);
  const [isLoadingScan, setLoadingScan] = useState<boolean>(false);
  const [scanIdKeyword, setScanIdKeyword] = useState<string>("");

  const onChangeCreatedTimeCondition = (
    text: string,
    condition: TimeConditionEnum,
    from: number,
    to: number
  ) => {
    setCreatedTimeCondition(condition);
    setCreatedTimeText(text);
    onChangeCreatedTimeConditionValue(text, from, to);
  };

  const onChangeUpdatedTimeCondition = (
    text: string,
    condition: TimeConditionEnum,
    from: number,
    to: number
  ) => {
    setUpdatedTimeCondition(condition);
    setUpdatedTimeText(text);
    onChangeUpdatedTimeConditionValue(text, from, to);
  };

  const fetchMoreScans = disallowConcurrency(() => {
    if (workspaceId) {
      scansServices
        .list_scans(workspaceId, {
          from: undefined,
          to: undefined,
          domain_id: undefined,
          status: undefined,
          is_schedule: undefined,
          q: undefined,
          page: loadedScanPages + 1,
          size: constants.DEFAULT_PAGE_SIZE,
        })
        .then((response) => {
          setScanItemList((prev) => [
            ...prev,
            ...response.results.map((scan) => ({
              key: scan.id.toString(),
              value: scan.id.toString(),
              getLabel: () => scan.id.toString(),
            })),
          ]);
          setLoadedScanPages((prev) => prev + 1);
          setTotalScanCount(response.count);
          setLoadingScan(false);
        });
    }
  });

  const debouncedFetchMoreScans = useDebouncedCallback((keyword: string) => {
    if (workspaceId) {
      scansServices
        .list_scans(workspaceId, {
          from: undefined,
          to: undefined,
          domain_id: undefined,
          status: undefined,
          is_schedule: undefined,
          q: keyword,
          page: undefined,
          size: constants.DEFAULT_PAGE_SIZE,
        })
        .then((response) => {
          setScanItemList(
            response.results.map((scan) => ({
              key: scan.id.toString(),
              value: scan.id.toString(),
              getLabel: () => scan.id.toString(),
            }))
          );
          setLoadedScanPages(1);
          setTotalScanCount(response.count);
          setLoadingScan(false);
        });
    }
  }, constants.DEBOUNCE_TIME);

  const onChangeScanIdKeyword = (keyword: string) => {
    setScanIdKeyword(keyword);
    debouncedFetchMoreScans(keyword);
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      if (workspaceId) {
        bugBountyServices
          .list_programs(workspaceId, { page: undefined })
          .then((response) => {
            setProgramItems(
              response.results.map((item) => ({
                key: item.org.alias,
                value: item.org.alias,
                getLabel: () => item.org.name,
              }))
            );
          });
      }
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      if (workspaceId) {
        pentestServices
          .list_pentest_projects_shortly(workspaceId, {
            from: undefined,
            to: undefined,
            status: undefined,
            q: undefined,
          })
          .then((response) => {
            setPentestItems(
              response.map((item) => ({
                key: item.alias,
                value: item.alias,
                getLabel: () => item.alias,
              }))
            );
          });
      }
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId]);

  useEffect(() => {
    let ignore = false;

    if (!ignore && workspaceId) {
      scansServices
        .list_scans(workspaceId, {
          from: undefined,
          to: undefined,
          domain_id: undefined,
          status: undefined,
          is_schedule: undefined,
          q: undefined,
          page: undefined,
          size: constants.DEFAULT_PAGE_SIZE,
        })
        .then((response) => {
          setScanItemList(
            response.results.map((scan) => ({
              key: scan.id.toString(),
              value: scan.id.toString(),
              getLabel: () => scan.id.toString(),
            }))
          );
          setTotalScanCount(response.count);
          setLoadedScanPages(1);
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId]);

  return (
    <div className="mb-1.5 flex gap-1 flex-wrap">
      <TimePopover
        label={t("createdTime.label")}
        timeCondition={createdTimeCondition}
        textDisplay={createdTimeText}
        open={createdTimeOpen}
        setOpen={(value) => setCreatedTimeOpen(value)}
        onChangeTime={onChangeCreatedTimeCondition}
      />
      <TimePopover
        label={t("updatedTime.label")}
        timeCondition={updatedTimeCondition}
        textDisplay={updatedTimeText}
        open={updatedTimeOpen}
        setOpen={(value) => setUpdatedTimeOpen(value)}
        onChangeTime={onChangeUpdatedTimeCondition}
      />
      <FilterDropdown
        label={t("assessmentType.label")}
        selected={assessmentType}
        options={assessmentTypeItems}
        onChangeSelection={onChangeAssessmentTypeSelection}
      />
      {assessmentType?.key === AssessmentTypeEnum.BUG_BOUNTY_PROGRAM ? (
        <>
          <FilterDropdown
            label={t("bugBountyProgram.label")}
            selected={bugBountyProgram}
            options={programItems}
            onChangeSelection={onChangeBugBountyProgramSelection}
          />
          <FilterDropdown
            label={t("bounty.label")}
            selected={bounty}
            options={bountyItems}
            onChangeSelection={onChangeBountySelection}
          />
        </>
      ) : null}
      {assessmentType?.key === AssessmentTypeEnum.PENTEST_PROJECT ? (
        <FilterDropdown
          label={t("pentestProject.label")}
          selected={pentest}
          options={pentestItems}
          onChangeSelection={onChangePentestProgramSelection}
        />
      ) : null}
      <FilterDropdown
        label={t("visibility.label")}
        selected={visibility}
        options={visibilityItems}
        onChangeSelection={onChangeVisibilitySelection}
      />
      <VulnerabilityStatusFilterDropdown
        label={t("status.label")}
        selected={subStatus}
        options={vulnerabilityStatusItems}
        onChangeSelection={onChangeSubStatusSelection}
        // narrowTo={tabSelectedStatus}
      />
      <FilterDropdown
        label={t("severity.label")}
        selected={severity}
        options={severityItems}
        CustomLabel={SeverityDropdownItemLabel}
        DropdownRender={SeverityDropdownRender}
        onChangeSelection={onChangeSeveritySelection}
      />
      <FilterDropdown
        label={t("archived.label")}
        selected={archived}
        options={archivedItems}
        onChangeSelection={onChangeArchivedSelection}
      />
      {assessmentType?.key === AssessmentTypeEnum.AUTOMATION_SCANS ? (
        <FilterDropdownSearch
          label={t("scanId.label")}
          selected={selectedScanId}
          options={scanItemList}
          onChangeSelection={onChangeScanSelection}
          loadedPages={loadedScanPages}
          totalCount={totalScanCount}
          fetchMoreItems={fetchMoreScans}
          isLoadingMore={isLoadingScan}
          setLoading={setLoadingScan}
          keyword={scanIdKeyword}
          onChangeKeyword={onChangeScanIdKeyword}
        />
      ) : null}
    </div>
  );
};

export default VulnerabilityFilter;
