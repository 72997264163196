// References:
// https://api.slack.com/legacy/oauth

// Libraries
import { Drawer } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import Cookies from "js-cookie";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";
import { pathname } from "#src/config/pathname";
import urls from "#src/config/urls";
import constants from "#src/config/constants";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import { ObjectImage } from "#src/common/system/Object";
import { generateKeyString } from "#src/utils/common";

// API-related
import type { IIntegrationItem } from "#src/services/resource";

interface IMsspBugBountyAddIntegrationDrawerProps extends IDrawerAdapterProps {
  bugBountyAlias: string;
  integration: IIntegrationItem | null;
}

const MsspBugBountyAddIntegrationDrawer = ({
  open,
  onClose,
  workspaceId,
  bugBountyAlias,
  integration,
}: IMsspBugBountyAddIntegrationDrawerProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.integrations.drawer",
  });

  const randomWithCrypto = generateKeyString();

  const selectedIntegrationLink = (): string => {
    const currentLocation =
      location.protocol +
      "//" +
      location.host +
      generatePath(pathname.BUG_BOUNTY_DETAILS_INTEGRATION_SETTINGS, {
        workspaceId,
        bugbounty: bugBountyAlias,
        integration: integration?.alias ?? "",
      });

    const authLinkByIntegration: { [I: string]: string } = {
      Slack: `${urls.SLACK_OAUTH}?scope=incoming-webhook&client_id=${constants.SLACK_CLIENT_ID}&state=${randomWithCrypto}&redirect_uri=${currentLocation}`,
      Trello: ``,
    };

    return integration?.name &&
      Object.keys(authLinkByIntegration).includes(integration?.name)
      ? authLinkByIntegration[integration?.name]
      : "";
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      closable={false}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onClose}>
          <CloseLine />
        </IconButton>
      }
      footer={
        <div className="flex flex-col gap-3">
          <Link
            to={selectedIntegrationLink()}
            onClick={() => {
              Cookies.set("integration-state", randomWithCrypto);
            }}
          >
            <Button className="w-full" variant="secondary" size="large">
              <ObjectImage data={integration?.logo} className="h-5 w-5" />
              {t("button.addTo", { integration: integration?.name })}
              <AddLine height={"1.25rem"} width={"1.25rem"} />
            </Button>
          </Link>
          <Button
            variant="secondary"
            className="w-full"
            size="large"
            onClick={onClose}
          >
            {t("button.cancel")}
          </Button>
        </div>
      }
      destroyOnClose={true}
    >
      <div className="px-6">
        <div className="py-6 font-regular-14 text-hard-grey">
          {t("configNotificationToDisplayIn", {
            integration: integration?.name,
          })}
        </div>
        {integration ? (
          <div className="rounded-md border border-light-grey bg-bright-grey">
            <div className="h-[6.75rem] relative">
              <div className="h-[4.5rem] border-b border-light-grey"></div>
              <div className="absolute bottom-0 left-0 right-0 mx-auto h-[4.5rem] w-[4.5rem] border border-light-grey bg-white p-1 rounded-md flex items-center justify-center">
                <ObjectImage data={integration.logo} className="h-10 w-10" />
              </div>
            </div>
            <div className="p-8 pt-3 flex flex-col items-center gap-6">
              <span className="font-medium-20">{integration.name}</span>
              <span className="text-center">{integration.description.en}</span>
            </div>
          </div>
        ) : null}
      </div>
    </Drawer>
  );
};

export default MsspBugBountyAddIntegrationDrawer;
