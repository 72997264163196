import { ReactNode } from "react";
import {
  generatePath,
  Link,
  matchPath,
  UIMatch,
  useParams,
} from "react-router-dom";

export const SidebarPageItemRender = ({
  path,
  getLabel,
  pathMatches,
}: {
  path: string;
  getLabel: () => ReactNode;
  pathMatches: UIMatch[];
}) => {
  const active = Boolean(
    matchPath(path, pathMatches[pathMatches.length - 1].pathname)
  );

  const params = useParams();

  return (
    <Link
      to={generatePath(path, params)}
      className={`py-2 pl-[2.625rem] pr-3 my-0.5 no-underline text-md font-semibold rounded-md transition-all ${
        active
          ? "bg-brand-v2-50 text-brand-v2-700"
          : "hover:bg-gray-v2-50 text-gray-v2-700 hover:text-gray-v2-700"
      }`}
    >
      {getLabel()}
    </Link>
  );
};
