// Libraries
import { Trans, useTranslation } from "react-i18next";
import { useState, type Dispatch, type SetStateAction } from "react";
import { List, Tooltip } from "@lockerpm/design";
import { generatePath, useNavigate } from "react-router-dom";
import { pathname } from "#src/config/pathname";

// Components
import { SearchBox } from "#src/common/Table/Filter";
import { Button } from "#src/common/system/Button";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as Equalizer2Line } from "#src/assets/images/icons/equalizer-2-line.svg";
import { ReactComponent as ArrowRightLine } from "#src/assets/images/icons/arrow-right-line.svg";
import { ReactComponent as SealWarningFill } from "#src/assets/images/icons/seal-warning-fill.svg";
import { NoDataState } from "#src/common/states";
import VerifyOwnershipDrawer from "#src/components/assets/drawers/VerifyOwnershipDrawer";

// Children
import { BillingTabEnum } from "#src/pages/workspace/billing";

import { type IDataLeakOverviewKeyword } from "#src/services/dataLeak";

interface IOverviewKeywordsProps {
  workspaceId: string | undefined;
  overviewKeywords: Array<IDataLeakOverviewKeyword>;
  fetchOverviewKeywords: (workspaceId: string, keyword?: string) => void;
}

interface IOverviewPackageKeyword {
  overviewKeyword: IDataLeakOverviewKeyword;
  workspaceId: string | undefined;
  setSelectedKeyword: Dispatch<SetStateAction<IDataLeakOverviewKeyword>>;
  setOpenVerifyDrawer: Dispatch<SetStateAction<boolean>>;
}

const PackageKeyword = ({
  overviewKeyword,
  workspaceId,
  setSelectedKeyword,
  setOpenVerifyDrawer,
}: IOverviewPackageKeyword) => {
  const navigate = useNavigate();
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "overview.keywords",
  });

  const { t: tAddKeyword } = useTranslation("dataLeak", {
    keyPrefix: "addKeyword.pricingTable.headers",
  });

  const PlanInfoCard = ({ name }: { name: string }) => {
    if (name === "Free") {
      return (
        <Tooltip
          title={
            <Trans
              t={t}
              i18nKey={"planTooltips.free"}
              components={{
                span: (
                  <span
                    className="text-subcard-blue cursor-pointer"
                    onClick={() => {
                      navigate(
                        generatePath(pathname.DATA_LEAK_ADD_KEYWORD_PAYMENT, {
                          workspaceId,
                        })
                      );
                    }}
                  />
                ),
              }}
            />
          }
        >
          <div className="flex items-center gap-1 bg-severity-none-bg px-2 py-1 rounded-xl w-max">
            <div className="w-2 h-2 bg-severity-none rounded-full"></div>
            <span className="font-medium-12 text-dark-blue">
              {tAddKeyword("freePlan")}
            </span>
          </div>
        </Tooltip>
      );
    }
    if (name === "Basic") {
      return (
        <div className="flex items-center gap-1 bg-support-blue-pastel px-2 py-1 rounded-xl w-max">
          <div className="w-2 h-2 bg-support-blue rounded-full"></div>
          <span className="font-medium-12 text-dark-blue">
            {tAddKeyword("basicPlan")}
          </span>
        </div>
      );
    }
    return (
      <div className="flex items-center gap-1 bg-support-teal-pastel px-2 py-1 rounded-xl w-max">
        <div className="w-2 h-2 bg-support-teal rounded-full"></div>
        <span className="font-medium-12 text-dark-blue">
          {tAddKeyword("enterprisePlan")}
        </span>
      </div>
    );
  };

  return (
    <div className="flex justify-between w-full gap-6">
      <div className="flex flex-col gap-3">
        <div className="flex gap-1.5">
          <p className="text-primary font-medium-16">
            # {overviewKeyword.keyword}
          </p>
          {!overviewKeyword.verification && (
            <Tooltip title={t("verifyTooltips.notVerified")}>
              <SealWarningFill className="w-5 h-5 text-severity-medium" />
            </Tooltip>
          )}
        </div>
        <PlanInfoCard name={overviewKeyword.current_plan?.name?.en} />
      </div>
      {!overviewKeyword.verification && overviewKeyword.is_domain && (
        <Button
          variant="text"
          className="h-5 m-0 p-0"
          onClick={() => {
            if (overviewKeyword.asset_id) {
              setSelectedKeyword(overviewKeyword);
              setOpenVerifyDrawer(true);
            } else {
              navigate(generatePath(pathname.DOMAINS, { workspaceId }));
            }
          }}
        >
          {t("buttons.verifyOwnership")}
          <ArrowRightLine className="w-4 h-4" />
        </Button>
      )}
    </div>
  );
};

const OverviewKeywords = ({
  workspaceId,
  overviewKeywords,
  fetchOverviewKeywords,
}: IOverviewKeywordsProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation("dataLeak", {
    keyPrefix: "overview.keywords",
  });

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [openVerifyDrawer, setOpenVerifyDrawer] = useState<boolean>(false);
  const [selectedKeyword, setSelectedKeyword] =
    useState<IDataLeakOverviewKeyword | null>(null);

  const onChangeSearchKeyword = (value: string) => {
    setSearchKeyword(value);
    if (workspaceId) {
      fetchOverviewKeywords(workspaceId, value);
    }
  };

  return (
    <div className="rounded-md border border-bright-grey px-6 py-4 w-full flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <h2 className="font-medium-20">{t("title")}</h2>
        <p className="font-regular-16 text-hard-grey">
          <Trans
            t={t}
            i18nKey={"description"}
            components={{
              span: <span className="font-bold-16 text-dark-blue" />,
            }}
          />
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <SearchBox
          inputClassName="flex-1 m-0"
          buttonClassName="m-0"
          placeholder={t("search")}
          searchKeyword={searchKeyword}
          onChangeSearchKeyword={onChangeSearchKeyword}
        />
        <div className="grid grid-cols-2 gap-3">
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => {
              navigate(
                generatePath(pathname.DATA_LEAK_ADD_KEYWORD, {
                  workspaceId,
                })
              );
            }}
          >
            <AddLine />
            {t("buttons.add")}
          </Button>
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => {
              navigate(
                generatePath(
                  `${pathname.WORKSPACE_BILLING}?tab=${BillingTabEnum.ORDER}`,
                  {
                    workspaceId,
                  }
                )
              );
            }}
          >
            <Equalizer2Line />
            {t("buttons.manageSubscriptions")}
          </Button>
        </div>
      </div>
      <div>
        {!overviewKeywords?.length ? (
          <NoDataState />
        ) : (
          <List
            bordered={false}
            dataSource={overviewKeywords}
            renderItem={(item) => (
              <List.Item>
                <PackageKeyword
                  workspaceId={workspaceId}
                  overviewKeyword={item}
                  setSelectedKeyword={setSelectedKeyword}
                  setOpenVerifyDrawer={setOpenVerifyDrawer}
                />
              </List.Item>
            )}
          />
        )}
      </div>
      {workspaceId && selectedKeyword && selectedKeyword.asset_id && (
        <VerifyOwnershipDrawer
          open={openVerifyDrawer}
          workspaceId={workspaceId}
          assetName={selectedKeyword.keyword}
          assetId={selectedKeyword.asset_id}
          onRefresh={() => {}}
          onClose={() => {
            setOpenVerifyDrawer(false);
          }}
        />
      )}
    </div>
  );
};

export default OverviewKeywords;
