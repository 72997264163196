// Libraries
import { Dropdown, Tooltip } from "@lockerpm/design";
import { type ReactNode, useState } from "react";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { generatePath, Link } from "react-router-dom";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as DraftLine } from "#src/assets/images/icons/draft-line.svg";
import { ReactComponent as ArrowRightLine } from "#src/assets/images/icons/arrow-right-line.svg";
import usFlag from "#src/assets/images/flags/us.png";
import vnFlag from "#src/assets/images/flags/vn.png";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import { TableDataCell } from "#src/common/Table";
import { TableRowWrapperDiv } from "#src/common/Table/TableRowWrapper";
import { StatusLabel } from "#src/common/helper/labels/StatusLabel";
import { Flag } from "#src/common/Flag";

// API-related
import type { ITrustCenterUpdateItem } from "#src/services/trustCenter/update";

// Children
import {
  type TrustCenterUpdateActionEnum,
  TrustCenterUpdateStatusValue,
} from "../enum";

const StatusCard = ({ status }: { status: string }) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "update" });

  return status === TrustCenterUpdateStatusValue.DRAFT ? (
    <StatusLabel
      name={t("card.draft")}
      color="bg-button-disabled text-hard-grey"
      icon={<DraftLine className="h-4 w-4 min-w-[1rem]" />}
    />
  ) : status === TrustCenterUpdateStatusValue.PUBLISHED ? (
    <StatusLabel
      name={t("card.published")}
      color="bg-support-seafoam-pastel text-support-seafoam"
      icon={<CheckboxCircleLine className="h-4 w-4 min-w-[1rem]" />}
    />
  ) : null;
};

interface ITrustCenterUpdateTableRowProps {
  workspaceId: string;
  data: ITrustCenterUpdateItem;
  actionList: { key: TrustCenterUpdateActionEnum; getLabel: () => ReactNode }[];
  onClickActionItems: (key: string, item: ITrustCenterUpdateItem) => void;
}

const TrustCenterUpdateTableRow = ({
  workspaceId,
  data,
  actionList,
  onClickActionItems,
}: ITrustCenterUpdateTableRowProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "update" });

  const locale = i18next.language;

  const [expanded, setExpanded] = useState<boolean>(false);

  const priorityLang = data.languages.includes(locale)
    ? locale
    : data.languages.length > 0
      ? data.languages[0]
      : "en";

  const getTextByLanguage = (
    textObj: Record<string, string>,
    priorityLang: string
  ) => {
    if (textObj[priorityLang]) {
      return textObj[priorityLang];
    }
    return "";
  };

  return (
    <TableRowWrapperDiv
      onClick={() => {
        setExpanded((prev) => !prev);
      }}
      className={`cursor-pointer ${expanded ? "bg-bright-grey" : ""}`}
    >
      <TableDataCell>
        <div
          className={`grid ${
            expanded
              ? "grid-rows-[min-content_1fr]"
              : "grid-rows-[min-content_0fr]"
          } transition-all w-full`}
        >
          <div className="flex gap-3 items-center p-3 pt-1.5">
            <ArrowDownSLine
              className={`h-5 w-5 min-w-[1.25rem] ${
                expanded ? "fill-dark-blue rotate-180" : "fill-hard-grey"
              } transition-all`}
            />
            <span className="font-medium-18">
              {getTextByLanguage(data.question, priorityLang)}
            </span>
            {data.languages.length > 0 ? (
              <div className="flex gap-1.5">
                {data.languages.includes("en") ? (
                  <Tooltip title={t("tooltip.haveEnglish")}>
                    <div>
                      <Flag pngProp={usFlag} />
                    </div>
                  </Tooltip>
                ) : null}
                {data.languages.includes("vi") ? (
                  <Tooltip title={t("tooltip.haveVietnamese")}>
                    <div>
                      <Flag pngProp={vnFlag} />
                    </div>
                  </Tooltip>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="overflow-hidden pl-11 flex flex-col gap-3">
            <span
              className="font-regular-14-body text-hard-grey markdown-compact"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  marked.parse(
                    getTextByLanguage(data.answer, priorityLang)
                  ) as string
                  // https://github.com/markedjs/marked/issues/3101 Marked cannot tell Typescript the async false option. Last update 17/01/2024
                ),
              }}
            ></span>
            {data.languages
              .filter((lang) => lang !== priorityLang)
              .map((lang) => (
                <div key={`update-${lang}`} className="flex flex-col gap-3">
                  <hr className="border-b border-light-grey" />
                  <span className="font-medium-18">
                    {getTextByLanguage(data.question, lang)}
                  </span>
                  <span
                    className="font-regular-14-body text-hard-grey markdown-compact"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        marked.parse(
                          getTextByLanguage(data.answer, lang)
                        ) as string
                        // https://github.com/markedjs/marked/issues/3101 Marked cannot tell Typescript the async false option. Last update 17/01/2024
                      ),
                    }}
                  ></span>
                </div>
              ))}
            {locale !== priorityLang && ["en", "vi"].includes(priorityLang) ? (
              <div className="flex flex-col gap-3">
                <hr className="border-b border-light-grey" />
                <div className="flex items-center gap-1.5 font-regular-14-body">
                  {locale === "vi"
                    ? t("addLanguage.vietnamese")
                    : locale === "en"
                      ? t("addLanguage.english")
                      : null}
                  <Link
                    to={generatePath(pathname.EDIT_TRUST_CENTER_UPDATE, {
                      workspaceId,
                      update: data.id,
                    })}
                  >
                    <Button className="px-0" variant="text">
                      {t("button.addNow")}
                      <ArrowRightLine className="h-4 w-4 min-w-[1rem]" />
                    </Button>
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </TableDataCell>
      <TableDataCell className="items-start">
        <StatusCard status={data.status} />
      </TableDataCell>
      <TableDataCell className="justify-center items-start">
        <Dropdown
          menu={{
            items: actionList.map((item) => ({
              key: item.key,
              label: item.getLabel(),
            })),
            onClick: ({ key, domEvent }) => {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              onClickActionItems(key, data);
            },
          }}
          trigger={["click"]}
          disabled={actionList.length === 0}
        >
          <IconButton
            variant="ghost"
            size={9}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <MoreLine />
          </IconButton>
        </Dropdown>
      </TableDataCell>
    </TableRowWrapperDiv>
  );
};

export default TrustCenterUpdateTableRow;
