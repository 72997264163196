import i18next from "i18next";

const t = i18next.getFixedT(null, "dataLeak", "addKeyword.findAndSelect");

export enum DataLeakPlanValue {
  FREE = "data_leak_free",
  BASIC = "data_leak_basic",
  PRO = "data_leak_pro",
  ENTERPRISE = "data_leak_enterprise",
}

export enum DataLeakDurationValue {
  MONTHLY = "monthly",
  QUARTER_YEARLY = "quarter_yearly",
  HALF_YEARLY = "half_yearly",
  YEARLY = "yearly",
}

export const productPlanItems: {
  key: string;
  value: DataLeakPlanValue;
  getLabel: () => string;
}[] = [
  {
    key: "free",
    value: DataLeakPlanValue.FREE,
    getLabel: () => t("selectedKeywords.plan.free"),
  },
  {
    key: "basic",
    value: DataLeakPlanValue.BASIC,
    getLabel: () => t("selectedKeywords.plan.basic"),
  },
  {
    key: "pro",
    value: DataLeakPlanValue.PRO,
    getLabel: () => t("selectedKeywords.plan.pro"),
  },
];

export const durationItems: {
  key: string;
  value: DataLeakDurationValue;
  getLabel: () => string;
}[] = [
  {
    key: "monthly",
    value: DataLeakDurationValue.MONTHLY,
    getLabel: () => t("selectedKeywords.duration.monthly"),
  },
  {
    key: "quarter_yearly",
    value: DataLeakDurationValue.QUARTER_YEARLY,
    getLabel: () => t("selectedKeywords.duration.quarterYearly"),
  },
  {
    key: "half_yearly",
    value: DataLeakDurationValue.HALF_YEARLY,
    getLabel: () => t("selectedKeywords.duration.halfYearly"),
  },
  {
    key: "yearly",
    value: DataLeakDurationValue.YEARLY,
    getLabel: () => t("selectedKeywords.duration.yearly"),
  },
];
