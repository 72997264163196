// Libraries
import { Drawer, Dropdown } from "@lockerpm/design";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
// import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import { Input, TextArea } from "#src/common/system/Input";
import { TagEditor } from "#src/common/system/Tag/TagEditor";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import devicesServices from "#src/services/devices";
import { tagService } from "#src/services/tags";
import { TagTypeValue } from "#src/config/filter/tag/value";

// Children
import HostInput from "../common/HostInput";

interface IEditControlPortDrawer extends IDrawerAdapterProps {
  editingItemId: string | null;
}

const EditControlPortDrawer = ({
  open,
  onClose,
  workspaceId,
  onRefresh,
  editingItemId,
}: IEditControlPortDrawer) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "control.drawers.editPortControl",
  });

  const controlActionItems = useMemo(
    () => [
      {
        key: "controlAction-block",
        value: "block",
        label: t("field.action.block"),
      },
      {
        key: "controlAction-allow",
        value: "allow",
        label: t("field.action.allow"),
      },
    ],
    [t]
  );

  const controlSourceItems = [
    {
      key: "controlSource-inbound",
      value: "inbound",
      label: t("field.source.inbound"),
    },
    {
      key: "controlSource-outbound",
      value: "outbound",
      label: t("field.source.outbound"),
    },
  ];

  const [portString, setPortString] = useState<string>("");
  const [controlAction, setControlAction] = useState<{
    key: string;
    value: string;
    label: string;
  }>(controlActionItems[0]);
  const [controlSource, setControlSource] = useState<{
    key: string;
    value: string;
    label: string;
  }>(controlSourceItems[0]);
  const [selectedHosts, setSelectedHosts] = useState<
    { key: string; id: string; name: string; label: string }[]
  >([]);
  const [tagList, setTagList] = useState<{ name: string }[]>([]);
  const [note, setNote] = useState<string>("");

  const [hostIdCounter, setHostIdCounter] = useState(0);

  const [tagItems, setTagItems] = useState<
    { key: string; value: string; label: string }[]
  >([]);
  // TODO: is this necessary?

  const onChangePortString = (value: string) => {
    setPortString(value);
  };

  const onSelectControlAction = (key: string) => {
    const selectedAction = controlActionItems.find((item) => item.key === key);
    if (selectedAction) {
      setControlAction(selectedAction);
    }
  };

  const onSelectControlSource = (key: string) => {
    const selectedSource = controlSourceItems.find((item) => item.key === key);
    if (selectedSource) {
      setControlSource(selectedSource);
    }
  };

  const onAddDefaultHost = () => {
    setHostIdCounter((prev) => prev + 1);
    setSelectedHosts((prev) => [
      ...prev,
      {
        key: (hostIdCounter + 1).toString(),
        id: "",
        name: "",
        label: "",
      },
    ]);
  };

  const onChangeHost = (newHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => {
    setSelectedHosts((prev) => {
      const changingHost = prev.findIndex((item) => item.key === newHost.key);
      let newList = prev;
      newList[changingHost] = newHost;
      return [...newList];
    });
  };

  const onRemoveHost = (removedHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => {
    setSelectedHosts((prev) => {
      return prev.filter((host) => host.key !== removedHost.key);
    });
  };

  const onChangeTags = (tags: { name: string }[]) => {
    setTagList(tags);
  };

  const onChangeNote = (value: string) => {
    setNote(value);
  };

  const onEditControlPort = () => {
    if (editingItemId) {
      devicesServices
        .edit_control(workspaceId, editingItemId, {
          // source: controlSource.value,
          // action:
          //   controlAction.value === "block"
          //     ? "block_network_port"
          //     : controlAction.value === "allow"
          //     ? "allow_network_port"
          //     : "",
          description: note,
          matches: [portString],
          hosts_tags: [
            ...selectedHosts.map((host) => ({
              id: host.id,
              type: "host",
            })),
            ...tagList
              .map((tag) => {
                const tagId = tagItems.find(
                  (item) => item.label === tag.name
                )?.value;
                return {
                  id: typeof tagId === "string" ? tagId : "-1",
                  type: "tag",
                };
              })
              .filter((tag) => tag.id !== "-1"),
          ],
        })
        .then((response) => {
          if (response.id) {
            onRefresh();
            onCloseAndCleanup();
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.editControl.fail"),
          });
        });
    }
  };

  const onCleanup = () => {
    setPortString("");
    setControlAction(controlActionItems[0]);
    setControlSource(controlSourceItems[0]);
    setSelectedHosts([]);
    setTagList([]);
    setNote("");
    setHostIdCounter(0);
  };

  const onCloseAndCleanup = () => {
    onCleanup();
    onClose();
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      tagService
        .listTagsNoPaging(workspaceId)
        .then((response) => {
          setTagItems(
            response.map((item) => ({
              key: item.id.toString(),
              value: item.id.toString(),
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadTagList.fail"),
          });
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, t]);

  useEffect(() => {
    let ignore = false;

    if (!ignore && editingItemId) {
      devicesServices
        .get_control_details(workspaceId, editingItemId)
        .then((response) => {
          setPortString(response.matches[0]);
          if (response.action === "block_network_port") {
            const initControlAction = controlActionItems.find(
              (item) => item.value === "block"
            );
            if (initControlAction) {
              setControlAction(initControlAction);
            }
          }
          if (response.action === "allow_network_port") {
            const initControlAction = controlActionItems.find(
              (item) => item.value === "allow"
            );
            if (initControlAction) {
              setControlAction(initControlAction);
            }
          }
          setSelectedHosts(
            response.hosts_tags
              .filter((item) => item.type === "host")
              .map((host) => ({
                key: host.id.toString(),
                id: host.id.toString(),
                name: host.name,
                label: host.name,
              }))
          );
          setTagList(
            response.hosts_tags
              .filter((item) => item.type === "tag")
              .map((tag) => ({
                name: tag.name,
              }))
          );
          setNote(response.description);
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, editingItemId, controlActionItems]);

  return (
    <Drawer
      open={open}
      onClose={onCloseAndCleanup}
      closeIcon={null}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onCloseAndCleanup}>
          <CloseLine />
        </IconButton>
      }
      footer={
        <div className="flex flex-col gap-3">
          <Button className="w-full" size="large" onClick={onEditControlPort}>
            {t("button.saveChanges")}
          </Button>
          <Button
            variant="secondary"
            className="w-full"
            size="large"
            onClick={onCloseAndCleanup}
          >
            {t("button.cancel")}
          </Button>
        </div>
      }
    >
      <div className="p-6 flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <h4>{t("field.ports.label")}</h4>
          <Input
            placeholder={t("field.ports.placeholder")}
            value={portString}
            onChange={(e) => {
              onChangePortString(e.currentTarget.value);
            }}
          />
        </div>
        <div className="flex gap-3">
          <div className="flex flex-col gap-3 flex-1">
            <h4>{t("field.source.label")}</h4>
            <Dropdown
              menu={{
                items: controlSourceItems.map((item) => ({
                  key: item.key,
                  label: (
                    <DropdownItemLabel
                      selected={controlSource.key === item.key}
                    >
                      {item.label}
                    </DropdownItemLabel>
                  ),
                })),
                onClick: ({ key }) => {
                  onSelectControlSource(key);
                },
              }}
              trigger={["click"]}
              disabled
            >
              <button className="flex gap-2 items-center justify-between px-4 py-3 bg-bright-grey text-hard-grey rounded-md disabled:bg-button-disabled disabled:text-medium-grey">
                {controlSource.label}
                {/* <ArrowDownSLine /> */}
              </button>
            </Dropdown>
          </div>
          <div className="flex flex-col gap-3 flex-1">
            <h4>{t("field.action.label")}</h4>
            <Dropdown
              menu={{
                items: controlActionItems.map((item) => ({
                  key: item.key,
                  label: (
                    <DropdownItemLabel
                      selected={controlAction.key === item.key}
                    >
                      {item.label}
                    </DropdownItemLabel>
                  ),
                })),
                onClick: ({ key }) => {
                  onSelectControlAction(key);
                },
              }}
              trigger={["click"]}
              disabled
            >
              <button className="flex gap-2 items-center justify-between px-4 py-3 bg-bright-grey text-hard-grey rounded-md disabled:bg-button-disabled disabled:text-medium-grey">
                {controlAction.label}
                {/* <ArrowDownSLine /> */}
              </button>
            </Dropdown>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <h4>{t("field.applyToTags.label")}</h4>
          <TagEditor
            workspaceId={workspaceId}
            tagList={tagList}
            onChangeTags={onChangeTags}
            type={TagTypeValue.HOST}
          />
        </div>
        <div className="flex flex-col gap-3">
          <h4>{t("field.applyToHostName.label")}</h4>
          {selectedHosts.map((host, index) => (
            <HostInput
              key={`devices/control/newControl-networkTab-hostInput-${host}-${index}`}
              workspaceId={workspaceId}
              selectedHost={host}
              onChangeHost={onChangeHost}
              onRemoveHost={onRemoveHost}
              autofocus={!host.id}
            />
          ))}
          <button
            className="px-4 py-3 bg-bright-grey text-medium-grey rounded-md text-left"
            onClick={(e) => {
              e.stopPropagation();
              onAddDefaultHost();
            }}
          >
            {t("field.applyToHostName.search.placeholder")}
          </button>
        </div>
        <div className="flex flex-col gap-3">
          <h4>{t("field.notes.label")}</h4>
          <TextArea
            placeholder={t("field.notes.placeholder")}
            value={note}
            onChange={(e) => {
              onChangeNote(e.currentTarget.value);
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default EditControlPortDrawer;
