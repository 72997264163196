import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "#src/common/system/Button";
import DataLeakAddKeywordContactForm from "./ContactForm";

export const PricingTableTitles = ({
  workspaceId,
}: {
  workspaceId: string;
}) => {
  const { t } = useTranslation("dataLeak", {
    keyPrefix: "addKeyword.pricingTable.headers",
  });

  const [openContactForm, setOpenContactForm] = useState<boolean>(false);

  return (
    <>
      <div className="w-full grid grid-cols-5 py-6 my-6">
        <div className="grid grid-cols-subgrid col-span-5">
          <div></div>
          <div className="px-6 pb-4 border-b border-light-grey font-bold-20">
            {t("freePlan")}
          </div>
          <div className="px-6 pb-4 border-b border-light-grey font-bold-20">
            {t("basicPlan")}
          </div>
          <div className="px-6 pb-4 border-b border-light-grey font-bold-20">
            {t("proPlan")}
          </div>
          <div className="px-6 pb-4 border-b border-light-grey font-bold-20">
            {t("enterprisePlan")}
          </div>
        </div>
        <div className="grid grid-cols-subgrid col-span-5">
          <div></div>
          <div className="px-6 pt-8 flex flex-col gap-2">
            <span className="flex gap-1.5 font-bold-32">
              0<span className="font-medium-20">VND</span>
            </span>
          </div>
          <div className="px-6 pt-8 flex flex-col gap-2">
            <span className="flex gap-1.5 font-bold-32">
              499.000<span className="font-medium-20">VND</span>
            </span>
            <span className="font-medium-16 text-hard-grey">
              {t("keywordPerMonth")}
            </span>
          </div>
          <div className="px-6 pt-8 flex flex-col gap-2">
            <span className="flex gap-1.5 font-bold-32">
              4.999.000<span className="font-medium-20">VND</span>
            </span>
            <span className="font-medium-16 text-hard-grey">
              {t("keywordPerMonth")}
            </span>
          </div>
          <div className="px-6 pt-8 flex flex-col gap-2">
            <Button
              size="large"
              onClick={() => {
                setOpenContactForm(true);
              }}
            >
              {t("button.getConsulation")}
            </Button>
          </div>
        </div>
      </div>
      <DataLeakAddKeywordContactForm
        workspaceId={workspaceId}
        open={openContactForm}
        onClose={() => {
          setOpenContactForm(false);
        }}
      />
    </>
  );
};
