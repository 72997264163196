// Libraries
import { useState, type ReactNode } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// Components
import { Button } from "#src/common/system/Button";
import { ObjectImage } from "#src/common/system/Object";
import { toPascalCase } from "#src/utils/common";
import ProgressBar from "#src/common/charts/ProgressBar";

// API-related
import type { IScanDetailItem } from "#src/services/scans";

// Children
import { getScheduleSummary } from "../utils";
import ScanConfigModal from "./Modal/ScanConfigModal";

dayjs.extend(relativeTime);

const MarkedStatusAndProgress = ({ status }: { status: string }) => {
  const statusText = toPascalCase(status);
  const colorByStatus: { [status: string]: string } = {
    pending: "bg-sky",
    running: "bg-blue",
    stopping: "bg-yellow",
    stopped: "bg-red",
    completed: "bg-green",
  };

  return (
    <div className="flex items-center gap-2 whitespace-nowrap">
      <div className={`${colorByStatus[status]} h-2.5 w-2.5 rounded-full`} />
      {statusText}
    </div>
  );
};

const InfoItemLabel = ({ name, value }: { name: string; value: ReactNode }) => {
  return (
    <div className="text-hard-grey whitespace-nowrap flex items-center gap-2">
      {name}
      {value}
    </div>
  );
};

const ScanDetailsBasicInfo = ({
  workspaceId,
  scanDetails,
}: {
  workspaceId: string;
  scanDetails: IScanDetailItem;
}) => {
  const { t } = useTranslation("scans", {
    keyPrefix: "page.scanDetails.basicInfo",
  });

  const [openConfigModal, setOpenConfigModal] = useState<boolean>(false);

  return (
    <div className="relative p-6 border border-grey rounded-md flex flex-wrap gap-x-10 gap-y-4">
      <InfoItemLabel
        name={t("status")}
        value={
          <div className="flex gap-2">
            <MarkedStatusAndProgress status={scanDetails.status} />
            {scanDetails.status === "running" ? (
              <span className="w-36">
                <ProgressBar
                  id={scanDetails.id.toString()}
                  progress={scanDetails.progress || 0}
                />
              </span>
            ) : null}
          </div>
        }
      />
      <InfoItemLabel
        name={t("createdBy")}
        value={
          <span className="text-dark-blue font-medium-16 flex items-center gap-1">
            <ObjectImage
              data={scanDetails.created_by.avatar}
              className="h-6 w-6 min-w-[1.5rem] rounded-full"
            />
            {scanDetails.created_by.full_name}
          </span>
        }
      />
      <InfoItemLabel
        name={t("startTime")}
        value={
          <span className="text-dark-blue ml-2 font-medium-16">
            {dayjs.unix(scanDetails.created_time).format("HH:mm DD.MM.YYYY")}
          </span>
        }
      />
      <InfoItemLabel
        name={t("elapsedTime")}
        value={
          <span className="text-dark-blue ml-2 font-medium-16">
            {dayjs.unix(scanDetails.created_time).fromNow()}
          </span>
        }
      />
      {/* This should always be 1 */}
      <InfoItemLabel
        name={t("scannedDomains")}
        value={
          <span className="text-dark-blue ml-2 font-medium-16">
            {scanDetails.scanned_assets.length}
          </span>
        }
      />
      <InfoItemLabel
        name={t("scheduleType")}
        value={
          <span className="text-dark-blue ml-2 font-medium-16">
            {getScheduleSummary(
              scanDetails.scan_profile.schedule,
              scanDetails.scan_profile.duration_type,
              scanDetails.scan_profile.repeat_number,
              scanDetails.scan_profile.activated_time,
              scanDetails.scan_profile.days.map((item) => +item)
            )}
          </span>
        }
      />
      <Button
        variant="text"
        className="absolute right-0 bottom-4"
        onClick={() => {
          setOpenConfigModal(true);
        }}
      >
        {t("button.viewConfig")}
        <ArrowRightUpLine className="h-4 w-4" />
      </Button>
      <ScanConfigModal
        workspaceId={workspaceId}
        open={openConfigModal}
        onClose={() => {
          setOpenConfigModal(false);
        }}
        scanProfile={scanDetails.scan_profile}
      />
    </div>
  );
};

export default ScanDetailsBasicInfo;
