import { Modal } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import { ReactComponent as SuccessIllustration } from "#src/assets/images/illustrations/success.svg";
import { Button } from "#src/common/system/Button";

interface ITrustCenterOnboardContactSuccessModalProps {
  open: boolean;
  onClose: () => void;
}

const TrustCenterOnboardContactSuccessModal = ({
  open,
  onClose,
}: ITrustCenterOnboardContactSuccessModalProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "onboard.contactModal.successModal",
  });

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={null}
      centered
      footer={null}
      width="36rem"
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col items-center text-center gap-3">
          <SuccessIllustration />
          <h2>{t("title")}</h2>
          <p className="font-regular-14-body text-hard-grey">
            {t("weWillContactYou")}
          </p>
          <div className="flex flex-col">
            <p className="font-bold-14">{t("quickSupport")}</p>
            <a className="font-regular-14" href="mailto:support@cystack.net">
              support@cystack.net
            </a>
          </div>
        </div>
        <div className="flex justify-center">
          <Button onClick={onClose}>{t("button.useTheFreeVersion")}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default TrustCenterOnboardContactSuccessModal;
