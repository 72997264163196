import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../locale/en.json";
import enAssetsDomains from "../locale/en/assets/domains.json";
import enAssetsIpAddress from "../locale/en/assets/ipAddress.json";
import enAssetsCertificates from "../locale/en/assets/certificates.json";
import enDevicesControl from "../locale/en/devices/control.json";
import enVulnerabilities from "../locale/en/vulnerabilities.json";
import enScans from "../locale/en/scans/allScans.json";
import enScanSchedules from "../locale/en/scans/schedules.json";
import enPentest from "../locale/en/pentest.json";
import enBugBounty from "../locale/en/bugBounty.json";
import enDataLeakOverview from "../locale/en/dataLeak/overview.json";
import enDataLeakFoundLeaks from "../locale/en/dataLeak/foundLeaks.json";
import enDataLeakAddKeyword from "../locale/en/dataLeak/addKeyword.json";
import enEndpointOverview from "../locale/en/endpoint/overview.json";
import enEndpointDevices from "../locale/en/endpoint/devices.json";
import enEndpointPolicies from "../locale/en/endpoint/policies.json";
import enEndpointDlpBackup from "../locale/en/endpoint/dlp/backup.json";
import enEndpointDlpActivities from "../locale/en/endpoint/dlp/activities.json";
import enTrustCenterPolicy from "../locale/en/trustCenter/policy.json";
import enTrustCenterCompliance from "../locale/en/trustCenter/compliance.json";
import enTrustCenterControl from "../locale/en/trustCenter/control.json";
import enTrustCenterDocument from "../locale/en/trustCenter/document.json";
import enTrustCenterThirdParty from "../locale/en/trustCenter/thirdParty.json";
import enTrustCenterFaq from "../locale/en/trustCenter/faq.json";
import enTrustCenterUpdate from "../locale/en/trustCenter/update.json";
import enTrustCenterConfiguration from "../locale/en/trustCenter/configuration.json";
import enTrustCenterRegisterDomain from "../locale/en/trustCenter/registerDomain.json";
import enTrustCenterOnboard from "../locale/en/trustCenter/onboard.json";
import enCommon from "../locale/en/common.json";
import enAccountSettings from "../locale/en/settings/account.json";
import enNotification from "../locale/en/settings/notification.json";
import enWorkspaceDetail from "../locale/en/settings/workspace/detail.json";
import enWorkspaceMember from "../locale/en/settings/workspace/member.json";
import enWorkspaceNewWorkspace from "../locale/en/settings/workspace/newWorkspace.json";
import enWorkspaceBilling from "../locale/en/settings/workspace/billing.json";
import enSidebar from "../locale/en/layout/sidebar.json";
import enTopbar from "../locale/en/layout/topbar.json";
import enSolutions from "../locale/en/solutions.json";
import enDlp from "../locale/en/dlp.json";

import vi from "../locale/vi.json";
import viAssetsDomains from "../locale/vi/assets/domains.json";
import viAssetsIpAddress from "../locale/vi/assets/ipAddress.json";
import viAssetsCertificates from "../locale/vi/assets/certificates.json";
import viDevicesControl from "../locale/vi/devices/control.json";
import viVulnerabilities from "../locale/vi/vulnerabilities.json";
import viScans from "../locale/vi/scans/allScans.json";
import viScanSchedules from "../locale/vi/scans/schedules.json";
import viPentest from "../locale/vi/pentest.json";
import viBugBounty from "../locale/vi/bugBounty.json";
import viDataLeakOverview from "../locale/vi/dataLeak/overview.json";
import viDataLeakFoundLeaks from "../locale/vi/dataLeak/foundLeaks.json";
import viDataLeakAddKeyword from "../locale/vi/dataLeak/addKeyword.json";
import viEndpointOverview from "../locale/vi/endpoint/overview.json";
import viEndpointDevices from "../locale/vi/endpoint/devices.json";
import viEndpointPolicies from "../locale/vi/endpoint/policies.json";
import viEndpointDlpBackup from "../locale/vi/endpoint/dlp/backup.json";
import viEndpointDlpActivities from "../locale/vi/endpoint/dlp/activities.json";
import viTrustCenterPolicy from "../locale/vi/trustCenter/policy.json";
import viTrustCenterCompliance from "../locale/vi/trustCenter/compliance.json";
import viTrustCenterControl from "../locale/vi/trustCenter/control.json";
import viTrustCenterDocument from "../locale/vi/trustCenter/document.json";
import viTrustCenterThirdParty from "../locale/vi/trustCenter/thirdParty.json";
import viTrustCenterFaq from "../locale/vi/trustCenter/faq.json";
import viTrustCenterUpdate from "../locale/vi/trustCenter/update.json";
import viTrustCenterConfiguration from "../locale/vi/trustCenter/configuration.json";
import viTrustCenterRegisterDomain from "../locale/vi/trustCenter/registerDomain.json";
import viTrustCenterOnboard from "../locale/vi/trustCenter/onboard.json";
import viCommon from "../locale/vi/common.json";
import viAccountSettings from "../locale/vi/settings/account.json";
import viNotification from "../locale/vi/settings/notification.json";
import viWorkspaceDetail from "../locale/vi/settings/workspace/detail.json";
import viWorkspaceMember from "../locale/vi/settings/workspace/member.json";
import viWorkspaceNewWorkspace from "../locale/vi/settings/workspace/newWorkspace.json";
import viWorkspaceBilling from "../locale/vi/settings/workspace/billing.json";
import viSidebar from "../locale/vi/layout/sidebar.json";
import viTopbar from "../locale/vi/layout/topbar.json";
import viSolutions from "../locale/vi/solutions.json";
import viDlp from "../locale/vi/dlp.json";

import systemService from "../services/system";

export const resources = {
  en: {
    translation: en,
    assets: {
      domains: enAssetsDomains,
      ipAddress: enAssetsIpAddress,
      certificates: enAssetsCertificates,
    },
    devices: {
      control: enDevicesControl,
    },
    vulnerabilities: enVulnerabilities,
    scans: enScans,
    scanSchedules: enScanSchedules,
    pentest: enPentest,
    bugBounty: enBugBounty,
    dataLeak: {
      overview: enDataLeakOverview,
      foundLeaks: enDataLeakFoundLeaks,
      addKeyword: enDataLeakAddKeyword,
    },
    endpoint: {
      overview: enEndpointOverview,
      devices: enEndpointDevices,
      policies: enEndpointPolicies,
      dlp: {
        backup: enEndpointDlpBackup,
        activities: enEndpointDlpActivities,
      },
    },
    trustCenter: {
      policy: enTrustCenterPolicy,
      compliance: enTrustCenterCompliance,
      control: enTrustCenterControl,
      document: enTrustCenterDocument,
      thirdParty: enTrustCenterThirdParty,
      faq: enTrustCenterFaq,
      update: enTrustCenterUpdate,
      configuration: enTrustCenterConfiguration,
      registerDomain: enTrustCenterRegisterDomain,
      onboard: enTrustCenterOnboard,
    },
    common: enCommon,
    accountSettings: enAccountSettings,
    notification: enNotification,
    workspace: {
      detail: enWorkspaceDetail,
      member: enWorkspaceMember,
      newWorkspace: enWorkspaceNewWorkspace,
      billing: enWorkspaceBilling,
    },
    layout: {
      sidebar: enSidebar,
      topbar: enTopbar,
    },
    solutions: enSolutions,
    dlp: enDlp,
  },
  vi: {
    translation: vi,
    assets: {
      domains: viAssetsDomains,
      ipAddress: viAssetsIpAddress,
      certificates: viAssetsCertificates,
    },
    devices: {
      control: viDevicesControl,
    },
    vulnerabilities: viVulnerabilities,
    scans: viScans,
    scanSchedules: viScanSchedules,
    pentest: viPentest,
    bugBounty: viBugBounty,
    dataLeak: {
      overview: viDataLeakOverview,
      foundLeaks: viDataLeakFoundLeaks,
      addKeyword: viDataLeakAddKeyword,
    },
    endpoint: {
      overview: viEndpointOverview,
      devices: viEndpointDevices,
      policies: viEndpointPolicies,
      dlp: {
        backup: viEndpointDlpBackup,
        activities: viEndpointDlpActivities,
      },
    },
    trustCenter: {
      policy: viTrustCenterPolicy,
      compliance: viTrustCenterCompliance,
      control: viTrustCenterControl,
      document: viTrustCenterDocument,
      thirdParty: viTrustCenterThirdParty,
      faq: viTrustCenterFaq,
      update: viTrustCenterUpdate,
      configuration: viTrustCenterConfiguration,
      registerDomain: viTrustCenterRegisterDomain,
      onboard: viTrustCenterOnboard,
    },
    common: viCommon,
    accountSettings: viAccountSettings,
    notification: viNotification,
    workspace: {
      detail: viWorkspaceDetail,
      member: viWorkspaceMember,
      newWorkspace: viWorkspaceNewWorkspace,
      billing: viWorkspaceBilling,
    },
    layout: {
      sidebar: viSidebar,
      topbar: viTopbar,
    },
    solutions: viSolutions,
    dlp: viDlp,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: systemService.getLanguage(),
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
