import { Modal, Select, Tooltip } from "@lockerpm/design";
import { useEffect, useMemo, useState } from "react";
import { ButtonV2 } from "#src/commonV2/Button";
import devicesServices from "#src/services/devices";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { TAILWIND_COLORS } from "#src/utils/common";
import { InfoCircle } from "@untitled-ui/icons-react";
import { TagsInput } from "#src/commonV2/TagsInput";
import { DeviceAssetType } from "#src/services/endpoint/enums";
import { assetTypeFilterItems } from "#src/components/endpoint/devices/filterItems";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  deviceTags: string[];
  deviceAssetType: DeviceAssetType;
  workspaceId: string;
  hostId: number;
  refreshHostDetails: () => void;
};

export const EditDeviceModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    deviceTags,
    deviceAssetType,
    workspaceId,
    hostId,
    refreshHostDetails,
  } = props;

  const { t } = useTranslation("endpoint", { keyPrefix: "devices" });

  // --------------- DATA ---------------

  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [assetType, setAssetType] = useState<DeviceAssetType>(
    DeviceAssetType.PERSONAL
  );

  // --------------- COMPUTED ---------------

  const assetTypeOptions = useMemo(
    () =>
      assetTypeFilterItems.map((item) => ({
        ...item,
        label: (
          <p
            className={`text-sm font-semibold ${assetType === item.value ? "text-gray-v2-700" : "text-gray-v2-500"}`}
          >
            {item.getLabel()}
          </p>
        ),
      })),
    [assetType]
  );

  // --------------- METHODS ---------------

  const editDevice = async () => {
    setIsLoading(true);
    try {
      await devicesServices.update_host(workspaceId, hostId, {
        tags,
        asset_type: assetType,
      });
      onClose();
      refreshHostDetails();
    } catch (error) {
      apiErrorHandler(error);
    }
    setIsLoading(false);
  };

  // --------------- EFFECTS ---------------

  useEffect(() => {
    if (isOpen) {
      setTags(deviceTags);
      setAssetType(deviceAssetType);
    } else {
      setTags([]);
      setAssetType(DeviceAssetType.PERSONAL);
    }
  }, [deviceTags, deviceAssetType, isOpen]);

  // --------------- RENDER ---------------

  return (
    <Modal
      title={<p className="text-lg font-semibold">{t("modals.edit.title")}</p>}
      open={isOpen}
      footer={null}
      maskClosable={false}
      onCancel={onClose}
      className="v2 max-w-[650px]"
      centered
      width="90%"
    >
      <div className="v2">
        {/* Body */}
        <div className="flex gap-6 flex-col sm:flex-row w-full">
          {/* Tags */}
          <div style={{ flex: 2 }}>
            <p className="text-sm font-medium text-gray-v2-700 mb-1.5">
              {t("modals.edit.tags.label")}
            </p>

            <TagsInput tags={tags} setTags={setTags} disabled={isLoading} />

            <p
              className="text-sm text-gray-v2-600 mt-1.5"
              dangerouslySetInnerHTML={{ __html: t("modals.edit.tags.desc") }}
            />
          </div>
          {/* Tags end */}

          {/* Asset type */}
          <div className="flex-1">
            <p className="mb-1.5 text-sm text-gray-v2-700 font-medium">
              {t("modals.edit.assetType.label")}
              <span>
                <Tooltip
                  color={TAILWIND_COLORS["gray-v2-900"]}
                  title={
                    <p className="text-xs text-white font-semibold text-center">
                      {t("modals.edit.assetType.desc")}
                    </p>
                  }
                >
                  <InfoCircle className="h-4 w-4 ml-2 translate-y-1 text-gray-v2-400" />
                </Tooltip>
              </span>
            </p>

            <Select
              options={assetTypeOptions}
              value={assetType}
              onChange={(e) => setAssetType(e)}
              className="w-full"
              disabled={isLoading}
            />
          </div>
          {/* Asset type end */}
        </div>
        {/* Body end */}

        {/* Footer */}
        <div className="flex gap-2 justify-end mt-8">
          <ButtonV2
            variant="secondary"
            size="lg"
            color="gray"
            onClick={onClose}
          >
            <p>{t("button.cancel")}</p>
          </ButtonV2>

          <ButtonV2
            variant="primary"
            size="lg"
            color="brand"
            pending={isLoading}
            onClick={editDevice}
          >
            <p>{t("button.update")}</p>
          </ButtonV2>
        </div>
        {/* Footer end */}
      </div>
    </Modal>
  );
};
