import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

interface IDataLeakState {
  plan: boolean | undefined;
}

const initialState: IDataLeakState = { plan: undefined };

const dataLeakSlice = createSlice({
  name: "dataLeak",
  initialState,
  reducers: {
    updatePlan: (state, action: PayloadAction<typeof initialState.plan>) => ({
      ...state,
      plan: action.payload,
    }),
  },
});

export const dataLeakDispatchAction = dataLeakSlice.actions;

export const dataLeakReducer = dataLeakSlice.reducer;
