// Libraries
import { useTranslation } from "react-i18next";

// Children
import type { IBugBountyScopeInputItem } from "#src/components/bugBounty/AddBugBounty/Scope";

interface IMsspBugBountyScopeTableProps {
  scope: IBugBountyScopeInputItem[];
}

const MsspBugBountyDetailsScopeTable = ({
  scope,
}: IMsspBugBountyScopeTableProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.addBugBounty.scope",
  });

  return (
    <>
      <div className="grid grid-cols-[6rem_1fr_1fr_1fr] gap-y-6">
        <div className="grid grid-cols-subgrid col-span-full gap-x-6 bg-bright-grey text-hard-grey py-5 px-9">
          <div>#</div>
          <div>{t("headers.assetType")}</div>
          <div>{t("headers.assets")}</div>
          <div>{t("headers.scope")}</div>
        </div>
        {scope.map((scopeItem, scopeItemId) => (
          <div
            key={`scope-${scopeItem.id}`}
            className="grid grid-cols-subgrid col-span-full gap-x-6 px-9 h-11"
          >
            <div className="flex items-center">{scopeItemId + 1}</div>
            <div className="flex items-center">
              {scopeItem.assetType.getLabel()}
            </div>
            <div className="flex items-center">{scopeItem.assets}</div>
            <div className="flex items-center">
              {scopeItem.scope.getLabel()}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MsspBugBountyDetailsScopeTable;
