import i18next from "i18next";
import type { ReactNode } from "react";

import { ReactComponent as Artboard2Line } from "#src/assets/images/icons/artboard-2-line.svg";
import { ReactComponent as DeviceLine } from "#src/assets/images/icons/device-line.svg";
import { ReactComponent as BugLine } from "#src/assets/images/icons/bug-line.svg";
import { ReactComponent as QrScanLine } from "#src/assets/images/icons/qr-scan-line.svg";
import { ReactComponent as FileShield2Line } from "#src/assets/images/icons/file-shield-2-line.svg";
import { ReactComponent as SymbolWh } from "#src/assets/images/icons/symbol-wh.svg";
import { ReactComponent as DataLeakLine } from "#src/assets/images/icons/data-leak-line.svg";
import { ReactComponent as AwardLine } from "#src/assets/images/icons/award-line.svg";

import { helperPathname, pathname } from "#src/config/pathname";
import constants from "#src/config/constants";

const sidebarTFunc = i18next.getFixedT(null, "layout", "sidebar.menu");

interface IMenuLeafRoute {
  pathname: string;
  getLabel: () => string;
  devMode?: boolean;
  demoWs?: string[];
}

interface IMenuRoute extends IMenuLeafRoute {
  icon: ReactNode;
  children?: IMenuLeafRoute[];
  devMode?: boolean;
  demoWs?: string[];
}

interface IMenuGroup {
  key: string;
  getLabel: () => string;
  routes: IMenuRoute[];
  devMode?: boolean;
  demoWs?: string[];
}

const isShownRoute = (
  item: unknown & { devMode?: boolean; demoWs?: string[] },
  workspaceId: string | undefined
) => {
  if (constants.ENVIRONMENT !== "prod") return true;
  // Bypass for CSPF Demo.
  if (workspaceId === "rf64hh") return true;
  if (item.devMode) {
    if (workspaceId && item.demoWs?.includes(workspaceId)) {
      return true;
    }
    return false;
  }
  return true;
};

const filterDevRoutes = (
  groups: IMenuGroup[],
  workspaceId: string | undefined
) =>
  groups
    .filter((groupItem) => isShownRoute(groupItem, workspaceId))
    .map((groupItem) => ({
      ...groupItem,
      routes: groupItem.routes
        .filter((routeItem) => isShownRoute(routeItem, workspaceId))
        .map((routeItem) => ({
          ...routeItem,
          children: routeItem.children
            ? routeItem.children.filter((leafRoute) =>
                isShownRoute(leafRoute, workspaceId)
              )
            : undefined,
        })),
    }));

export const getMainMenuRoutes = ({
  dataLeakPlan,
  trustCenterPlan,
  workspaceId,
}: {
  dataLeakPlan: boolean | undefined;
  trustCenterPlan: undefined | null | string;
  workspaceId: string | undefined;
}): IMenuGroup[] =>
  filterDevRoutes(
    [
      {
        key: "attack_surface_management",
        getLabel: () => sidebarTFunc("headers.attackSurfaceManagement"),
        routes: [
          {
            pathname: helperPathname.ASSETS,
            icon: (
              <Artboard2Line className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
            ),
            getLabel: () => sidebarTFunc("assets.label"),
            children: [
              {
                pathname: pathname.DOMAINS,
                getLabel: () => sidebarTFunc("assets.domains"),
              },
              {
                pathname: pathname.IP_ADDRESS,
                getLabel: () => sidebarTFunc("assets.ipAddress"),
              },
              {
                pathname: pathname.CERTIFICATES,
                getLabel: () => sidebarTFunc("assets.certificates"),
              },
            ],
          },
          {
            pathname: helperPathname.DEVICES,
            icon: (
              <DeviceLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
            ),
            getLabel: () => sidebarTFunc("devices.label"),
            children: [
              {
                pathname: pathname.CONTROL,
                getLabel: () => sidebarTFunc("devices.control"),
              },
            ],
            devMode: true,
          },
          {
            pathname: pathname.VULNERABILITIES,
            icon: (
              <BugLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
            ),
            getLabel: () => sidebarTFunc("vulnerabilities"),
          },
        ],
      },
      {
        key: "security_testing_and_assessment",
        getLabel: () => sidebarTFunc("headers.securityTestingAndAssessment"),
        routes: [
          {
            pathname: helperPathname.SCANS,
            icon: (
              <QrScanLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
            ),
            getLabel: () => sidebarTFunc("scans.label"),
            children: [
              {
                pathname: pathname.ALL_SCANS,
                getLabel: () => sidebarTFunc("scans.allScans"),
              },
              {
                pathname: pathname.SCHEDULES,
                getLabel: () => sidebarTFunc("scans.schedules"),
              },
            ],
          },
        ],
        devMode: true,
        demoWs: ["qufjc1"],
      },
      {
        key: "managed_security_services",
        getLabel: () => sidebarTFunc("headers.managedSecurityServices"),
        routes: [
          {
            pathname: pathname.PENTEST,
            icon: (
              <FileShield2Line className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
            ),
            getLabel: () => sidebarTFunc("pentest"),
          },
          {
            pathname: pathname.BUG_BOUNTY_PROGRAMS,
            icon: (
              <SymbolWh className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
            ),
            getLabel: () => sidebarTFunc("bugBountyPrograms"),
          },
        ],
        devMode: true,
      },
      {
        key: "data_protection",
        getLabel: () => sidebarTFunc("headers.dataProtection"),
        routes: [
          {
            pathname: helperPathname.DATA_LEAK,
            icon: (
              <DataLeakLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
            ),
            getLabel: () => sidebarTFunc("dataLeak.label"),
            children:
              dataLeakPlan === false
                ? []
                : [
                    {
                      pathname: pathname.DATA_LEAK_OVERVIEW,
                      getLabel: () => sidebarTFunc("dataLeak.overview"),
                    },
                    {
                      pathname: pathname.DATA_LEAK_FOUND_LEAKS,
                      getLabel: () => sidebarTFunc("dataLeak.foundLeaks"),
                    },
                  ],
          },
          {
            pathname: helperPathname.ENDPOINT,
            icon: (
              <DeviceLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
            ),
            getLabel: () => sidebarTFunc("endpoint.label"),
            children: [
              {
                pathname: pathname.ENDPOINT_OVERVIEW,
                getLabel: () => sidebarTFunc("endpoint.overview"),
              },
              {
                pathname: pathname.ENDPOINT_DEVICES,
                getLabel: () => sidebarTFunc("endpoint.devices"),
              },
              {
                pathname: pathname.ENDPOINT_POLICIES,
                getLabel: () => sidebarTFunc("endpoint.policies"),
              },
              {
                pathname: pathname.ENDPOINT_DLP_BACKUP,
                getLabel: () => sidebarTFunc("endpoint.dlp"),
              },
            ],
            devMode: true,
          },
        ],
      },
      {
        key: "security_compliance",
        getLabel: () => sidebarTFunc("headers.securityCompliance"),
        routes: [
          {
            pathname: helperPathname.TRUST_CENTER,
            icon: (
              <AwardLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
            ),
            getLabel: () => sidebarTFunc("trustCenter.label"),
            children:
              trustCenterPlan === null
                ? []
                : [
                    {
                      pathname: pathname.TRUST_CENTER_POLICY,
                      getLabel: () => sidebarTFunc("trustCenter.policy"),
                    },
                    {
                      pathname: pathname.TRUST_CENTER_COMPLIANCE,
                      getLabel: () => sidebarTFunc("trustCenter.compliance"),
                    },
                    {
                      pathname: pathname.TRUST_CENTER_CONTROL,
                      getLabel: () => sidebarTFunc("trustCenter.control"),
                    },
                    {
                      pathname: pathname.TRUST_CENTER_DOCUMENT,
                      getLabel: () => sidebarTFunc("trustCenter.document"),
                    },
                    {
                      pathname: pathname.TRUST_CENTER_THIRD_PARTY,
                      getLabel: () =>
                        sidebarTFunc("trustCenter.thirdPartyService"),
                    },
                    {
                      pathname: pathname.TRUST_CENTER_FAQ,
                      getLabel: () => sidebarTFunc("trustCenter.faq"),
                    },
                    {
                      pathname: pathname.TRUST_CENTER_UPDATE,
                      getLabel: () => sidebarTFunc("trustCenter.update"),
                    },
                    {
                      pathname: pathname.TRUST_CENTER_CONFIG,
                      getLabel: () => sidebarTFunc("trustCenter.configuration"),
                    },
                  ],
          },
        ],
      },
    ],
    workspaceId
  );

export const msspMenuRoutes: IMenuGroup[] = [
  {
    key: "managed_security_services",
    getLabel: () => sidebarTFunc("headers.managedSecurityServices"),
    routes: [
      {
        pathname: pathname.MSSP_PENTEST,
        icon: (
          <FileShield2Line className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
        ),
        getLabel: () => sidebarTFunc("pentest"),
      },
      {
        pathname: pathname.MSSP_BUG_BOUNTY_PROGRAMS,
        icon: <SymbolWh className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />,
        getLabel: () => sidebarTFunc("bugBountyPrograms"),
      },
    ],
  },
];

export const accountMenuRoutes: IMenuLeafRoute[] = [
  {
    pathname: pathname.ACCOUNT_SETTINGS,
    getLabel: () => sidebarTFunc("accountSettings.personalDetails"),
  },
  {
    pathname: pathname.NOTIFICATION,
    getLabel: () => sidebarTFunc("accountSettings.notificationSettings"),
  },
];

export const workspaceMenuRoutes: IMenuLeafRoute[] = [
  {
    pathname: pathname.WORKSPACE_DETAILS,
    getLabel: () => sidebarTFunc("workspaceSettings.details"),
  },
  {
    pathname: pathname.WORKSPACE_MEMBERS,
    getLabel: () => sidebarTFunc("workspaceSettings.members"),
  },
  {
    pathname: pathname.WORKSPACE_BILLING,
    getLabel: () => sidebarTFunc("workspaceSettings.billing"),
  },
];
