// Libraries
import { useMemo, useState } from "react";
import { Drawer, Dropdown } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import type { IDrawerAdapterProps, IFilterItem } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import DropdownItemLabel from "#src/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { InputField } from "#src/common/helper/wrapper/InputField";
import { createToast } from "#src/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import bugBountyServices from "#src/services/bugBounty";
import { bugBountyMemberRoleItems } from "#src/config/filter/bugbounty";
import { BugBountyMemberRoleEnum } from "#src/config/filter/bugbounty/enum";

interface IAddBugBountyMemberDrawerProps extends IDrawerAdapterProps {
  bugBountyAlias: string;
  currentUserRole: string | undefined;
}

const AddBugBountyMemberDrawer = ({
  open,
  workspaceId,
  bugBountyAlias,
  onClose,
  onRefresh,
  currentUserRole,
}: IAddBugBountyMemberDrawerProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.members.drawer.addMember",
  });

  const [username, setUsername] = useState<string>("");
  const [role, setRole] = useState<IFilterItem>(
    bugBountyMemberRoleItems.find(
      (item) => item.key === BugBountyMemberRoleEnum.DEVELOPER
    ) ?? bugBountyMemberRoleItems[0]
  );

  const roleItems = useMemo<IFilterItem[]>(() => {
    if (currentUserRole === "workspace_owner") {
      return bugBountyMemberRoleItems;
    }

    if (currentUserRole === "owner") {
      return bugBountyMemberRoleItems.filter(
        (item) =>
          item.key === BugBountyMemberRoleEnum.VIEWER ||
          item.key === BugBountyMemberRoleEnum.DEVELOPER ||
          item.key === BugBountyMemberRoleEnum.RESEARCHER
      );
    }

    return [];
  }, [currentUserRole]);

  const onCloseDrawerAndCleanup = () => {
    onClose();
    setUsername("");
    setRole(
      bugBountyMemberRoleItems.find(
        (item) => item.key === BugBountyMemberRoleEnum.DEVELOPER
      ) ?? bugBountyMemberRoleItems[0]
    );
  };

  const onSelectRole = (key: string) => {
    const chosenRole = bugBountyMemberRoleItems.find(
      (item) => item.key === key
    );
    if (chosenRole) {
      setRole(chosenRole);
    }
  };

  const onClickAddMember = () => {
    // role.value should always be a string, not undefined. But we'll keep the condition here to make typescript happy and make sure no silly trouble happen.
    if (role.value) {
      bugBountyServices
        .create_program_member(workspaceId, bugBountyAlias, {
          members: [{ email: username, role: role.value }],
        })
        .then((response) => {
          if (response.success) {
            createToast({
              type: "success",
              message: t("notification.addMember.success.title"),
              detail: t("notification.addMember.success.message", { username }),
            });
            onRefresh();
            onCloseDrawerAndCleanup();
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.addMember.fail.title"),
          });
        });
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onCloseDrawerAndCleanup}
      closable={false}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton
          variant="label-grey"
          size={9}
          onClick={onCloseDrawerAndCleanup}
        >
          <CloseLine />
        </IconButton>
      }
      footer={
        <div className="flex flex-col gap-3">
          <Button className="w-full" size="large" onClick={onClickAddMember}>
            {t("button.addMember")}
          </Button>
          <Button
            variant="secondary"
            className="w-full"
            size="large"
            onClick={onCloseDrawerAndCleanup}
          >
            {t("button.cancel")}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-6 p-6">
        <InputField
          title={t("field.username.label")}
          placeholder={t("field.username.placeholder")}
          value={username}
          onChangeValue={(value) => {
            setUsername(value);
          }}
        />
        <div className="flex flex-col gap-3">
          <h4>{t("field.role.label")}</h4>
          <Dropdown
            menu={{
              items: roleItems.map((item) => ({
                key: item.key,
                label: (
                  <DropdownItemLabel
                    selected={role.key === item.key}
                    syncWidthId="bugBounty-member-roleSelect"
                  >
                    {item.getLabel()}
                  </DropdownItemLabel>
                ),
              })),
              onClick: ({ key }) => {
                onSelectRole(key);
              },
            }}
            trigger={["click"]}
          >
            <button
              id="bugBounty-member-roleSelect"
              className="flex gap-2 items-center justify-between p-3 bg-bright-grey text-hard-grey rounded-md"
            >
              {role.getLabel()}
              <ArrowDownSLine />
            </button>
          </Dropdown>
        </div>
      </div>
    </Drawer>
  );
};

export default AddBugBountyMemberDrawer;
