// Libraries
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { marked } from "marked";
import { type RefObject, useEffect, useState } from "react";
import { Tooltip } from "@lockerpm/design";
import i18next from "i18next";

// Components
import { VoteButtons } from "#src/common/VoteButtons";
import { isFulfilled } from "#src/utils/common";
import { CopyToClipboardButton } from "#src/common/system/CopyToClipboardButton";
import TooltipInnerWithCopy from "#src/common/TooltipInnerWithCopy";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import vulnerabilitiesServices, {
  type IVulnDataLeakDescription,
  type IVulnerabilityDetail,
} from "#src/services/vulnerabilities";
import vulnerabilityCommentServices, {
  type IVulnerabilityComment,
} from "#src/services/vulnerabilities/comments";
import attachmentsServices from "#src/services/vulnerabilities/attachments";

// Children
import VulnerabilityCommentItems from "./Comments";
import VulnerabilityCommentEditor from "./Editor";

const ParsedMarkdownField = ({
  title,
  markdownText,
  asCode = false,
}: {
  title: string;
  markdownText: string;
  asCode?: boolean;
}) => {
  return markdownText ? (
    <div className="flex flex-col gap-6">
      <div className="font-medium-20">{title}</div>
      <div
        className={
          asCode ? "markdown-body p-6 bg-bright-blue" : "markdown-body"
        }
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            marked.parse(markdownText, {
              async: false,
            }) as string
            // https://github.com/markedjs/marked/issues/3101 Marked cannot tell Typescript the async false option. Last update 17/01/2024
          ),
        }}
      />
    </div>
  ) : null;
};

interface IVulnerabilityContentSectionProps {
  workspaceId: string;
  containerRef: RefObject<HTMLDivElement>;
  vulnDetail: IVulnerabilityDetail;
  refreshVulnerabilityDetail: () => void;
}

const VulnerabilityContentSection = ({
  workspaceId,
  containerRef,
  vulnDetail,
  refreshVulnerabilityDetail,
}: IVulnerabilityContentSectionProps) => {
  const { t } = useTranslation("vulnerabilities", {
    keyPrefix: "detailSection",
  });

  const currentLanguage = i18next.language;

  const [comments, setComments] = useState<IVulnerabilityComment[]>([]);

  const onVoteVulnerability = (vote: boolean | null) => {
    if (workspaceId && vulnDetail) {
      vulnerabilitiesServices
        .vote_vulnerability(workspaceId, vulnDetail?.id, vote)
        .then(() => {
          refreshVulnerabilityDetail();
        })
        .catch(apiErrorHandler);
    }
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      if (workspaceId && vulnDetail) {
        // TODO: rewrite this: this is too complicated and hard to read
        const fetchComments = async () => {
          try {
            const vulnComments =
              await vulnerabilityCommentServices.list_vulnerability_comments(
                workspaceId,
                vulnDetail.id
              );

            const attachedResponse = (
              await Promise.allSettled(
                vulnComments.map(async (comment) => {
                  const attachmentStrings = comment.message.match(
                    /https:\/\/whitehub.net\/attachments\/\d+/g
                  );

                  if (!attachmentStrings) return comment;

                  const attachmentUrls = (
                    await Promise.allSettled(
                      attachmentStrings.map(async (attachmentStr) => {
                        const attachmentId = attachmentStr.split("/")[4];
                        const attachmentUrlResponse =
                          await attachmentsServices.retrieve_attachment(
                            +attachmentId
                          );
                        return {
                          key: attachmentStr,
                          value: attachmentUrlResponse.url,
                        };
                      })
                    )
                  )
                    .filter(isFulfilled)
                    .map((promise) => promise.value);
                  const attachedMessage = attachmentUrls.reduce(
                    (prev, curr) => {
                      return prev.replaceAll(curr.key, curr.value);
                    },
                    comment.message
                  );

                  return { ...comment, message: attachedMessage };
                })
              )
            )
              .filter(isFulfilled)
              .map((promise) => promise.value);

            setComments(attachedResponse);
          } catch (error) {
            apiErrorHandler(error);
          }
        };
        fetchComments();
      }
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, vulnDetail]);

  // Calculate the position of this element, so that we can fix its height by screen size
  const calculatedTop: number = containerRef.current
    ? containerRef.current?.getBoundingClientRect().top +
      parseFloat(window.getComputedStyle(containerRef.current).paddingTop)
    : // 180 is just a fallback value that looks close to the actual calculated value
      180;

  const dataLeakDetails =
    vulnDetail.vuln_source === "data_leak"
      ? (vulnDetail.description as IVulnDataLeakDescription).details
      : null;

  return (
    <div
      style={{
        height: `calc(100vh - ${calculatedTop}px)`,
      }}
      className="relative flex flex-col flex-[2] max-w-[80rem] gap-9 overflow-y-auto pr-4"
    >
      <h2>{vulnDetail.title}</h2>
      <div className="flex flex-col gap-3">
        <h4>
          {vulnDetail.vuln_source === "data_leak"
            ? t("matchedKeyword")
            : t("locationOfVulnerability")}
        </h4>
        <div className="flex gap-2 mr-20">
          <div
            className="px-6 py-3 bg-white rounded-md flex-1"
            style={{ wordBreak: "break-word" }}
          >
            {vulnDetail.vuln_source === "data_leak"
              ? (vulnDetail.description as IVulnDataLeakDescription).keyword
              : vulnDetail.vuln_location}
          </div>
          <CopyToClipboardButton value={vulnDetail.vuln_location} />
        </div>
      </div>
      {vulnDetail.vuln_source === "data_leak" ? (
        <>
          <div className="flex flex-col gap-3">
            <h4>{t("details")}</h4>
            {dataLeakDetails ? (
              <div className="flex flex-col gap-1">
                <div className="flex">
                  {Object.keys(dataLeakDetails).map((key) => (
                    <div
                      key={`dataleak-details-key-${key}`}
                      className="flex-1 bg-bright-grey text-hard-grey p-3"
                    >
                      {key}
                    </div>
                  ))}
                </div>
                <div className="flex">
                  {Object.entries(dataLeakDetails).map(([key, value]) => (
                    <Tooltip
                      key={`dataleak-details-values-${key}${value}`}
                      title={<TooltipInnerWithCopy value={value} />}
                    >
                      <div className="flex-1 bg-white whitespace-nowrap overflow-hidden text-ellipsis p-3">
                        {value}
                      </div>
                    </Tooltip>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-3">
            <h4>{t("source")}</h4>
            <div
              className="markdown-body px-4 py-6 bg-white rounded-md break-words overflow-y-auto"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  marked.parse(
                    (vulnDetail.description as IVulnDataLeakDescription).source,
                    {
                      async: false,
                    }
                  ) as string
                  // https://github.com/markedjs/marked/issues/3101 Marked cannot tell Typescript the async false option. Last update 17/01/2024
                ),
              }}
            ></div>
          </div>
        </>
      ) : vulnDetail.description ? (
        <div className="flex flex-col gap-3">
          <h4>{t("description")}</h4>
          <div className="flex flex-col gap-6 px-4 py-6 bg-white rounded-md break-words overflow-y-auto">
            <ParsedMarkdownField
              title={t("summary")}
              markdownText={vulnDetail.description as string}
            />
            <ParsedMarkdownField
              title={t("impact")}
              markdownText={vulnDetail.impact}
            />
            <ParsedMarkdownField
              title={t("stepsToReproduce")}
              markdownText={vulnDetail.steps}
              asCode
            />
            <ParsedMarkdownField
              title={t("recommendations")}
              markdownText={vulnDetail.recommendation}
            />
          </div>
        </div>
      ) : null}
      {/* <div className="flex flex-col gap-3">
        <h4>{t("rewards")}</h4>
        <div className="bg-white rounded-md"></div>
      </div> */}
      <div className="pb-1.5">
        {comments.map((comment) => {
          return (
            <VulnerabilityCommentItems
              key={`vulnerability_comment_${comment.id}`}
              comment={comment}
              currentLanguage={currentLanguage}
            />
          );
        })}
      </div>
      <VulnerabilityCommentEditor
        workspaceId={workspaceId}
        vulnerabilityId={vulnDetail.id}
        refreshVulnerabilityDetail={refreshVulnerabilityDetail}
      />
      <div className="absolute right-4 top-0">
        <VoteButtons
          userVote={vulnDetail.user_vote}
          voteCount={vulnDetail.votes}
          onUpvote={() => {
            onVoteVulnerability(true);
          }}
          onDownvote={() => {
            onVoteVulnerability(false);
          }}
          onUnvote={() => {
            onVoteVulnerability(null);
          }}
        />
      </div>
    </div>
  );
};

export default VulnerabilityContentSection;
