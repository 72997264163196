import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import OrgTreeComponent, { useTree } from "react-drag-hierarchy-tree";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { ButtonV2 } from "#src/commonV2/Button";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const TestPage = () => {
  const { treeRef } = useTree();

  const options: ApexOptions = {
    labels: ["Apples", "Oranges", "Bananas", "Berries"],
    legend: {
      position: "right",
      offsetY: 100,
      horizontalAlign: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  let data = {
    id: 1,
    label: "President",
    children: [
      {
        id: 2,
        label: "Administrative",
        children: [
          {
            id: 3,
            label: "Director",
            children: [],
          },
        ],
      },
    ],
  };

  const testTree = () => {
    console.log(1, data);
    console.log(2, treeRef.current?.data);
  };

  return (
    <div className="v2 p-8 mb-4">
      <h1 className="text-display-2xl font-bold text-black">test page</h1>

      {/* Donut */}
      <div className="flex items-center">
        <Chart
          type="donut"
          options={options}
          series={[44, 55, 41, 17]}
          height={500}
          width={500}
        />
      </div>
      {/* Donut end */}

      {/* Map */}
      <div>
        <MapContainer
          center={[51.505, -0.09]}
          zoom={13}
          scrollWheelZoom={false}
          style={{ height: "500px", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={[51.505, -0.09]}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </div>
      {/* Map end */}

      {/* Tree */}
      <div style={{ height: "500px", width: "100%" }}>
        <OrgTreeComponent data={data} ref={treeRef} />

        <ButtonV2 onClick={testTree}>Test</ButtonV2>
      </div>
      {/* Tree end */}
    </div>
  );
};

export default TestPage;
