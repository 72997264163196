// Libraries
import { useState } from "react";
import { Drawer } from "@lockerpm/design";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { z } from "zod";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as ErrorWarningLine } from "#src/assets/images/icons/error-warning-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";
import patterns from "#src/config/patterns";

// Components
import { Button, IconButton } from "#src/common/system/Button";
import {
  type FormErrorItem,
  InputField,
} from "#src/common/helper/wrapper/InputField";
import { createToast } from "#src/common/system/toasts";

// API-related
import assetsServices from "#src/services/assets";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";

// Children
import VerifyOwnershipDrawer from "#src/components/assets/drawers/VerifyOwnershipDrawer";

interface IAddDomainDrawerProps extends IDrawerAdapterProps {}

/** Drawer for adding domain
 * @param open - state of the drawer
 * @param workspaceId
 * @param onClose
 * @param onRefresh - a callback function that update data on display outside this drawer, usually called after data changed.
 */
const AddDomainDrawer = ({
  open,
  workspaceId,
  onClose,
  onRefresh,
}: IAddDomainDrawerProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.drawer.addDomain",
  });

  const [openVerifyDrawer, setOpenVerifyDrawer] = useState<boolean>(false);
  // Controls domain address input
  const [domainAddress, setDomainAddress] = useState<string>("");
  const [domainId, setDomainId] = useState<number | null>(null);
  const [errorObj, setErrorObj] = useState<{
    address: FormErrorItem;
  }>({ address: undefined });

  const [pending, setPending] = useState<boolean>(false);

  const onCloseDrawerAndCleanup = () => {
    onClose();
    setDomainAddress("");
    setDomainId(null);
    setErrorObj({ address: undefined });
  };

  // If user select "add and verify", pass `verify = true`.
  const addNewDomain = (verify: boolean) => {
    setPending(true);
    assetsServices
      .create_asset(workspaceId, {
        name: domainAddress,
        address: domainAddress,
        description: "",
      })
      .then((response) => {
        onRefresh();
        createToast({
          type: "success",
          message: t("notification.justAdd"),
        });
        if (verify) {
          setOpenVerifyDrawer(true);
          setDomainId(response.id);
        } else {
          onCloseDrawerAndCleanup();
        }
      })
      .catch((error: unknown) => {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 400
        ) {
          const getErrorData = parseBadRequest(
            error.response.data,
            z.object({
              address: z.optional(z.array(z.string())),
            })
          );
          setErrorObj((prev) => ({
            ...prev,
            address: getErrorData.details.address
              ? () => (getErrorData.details.address as string[])[0]
              : undefined,
          }));
        } else {
          apiErrorHandler(error);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <>
      {/* First Drawer */}
      <Drawer
        open={open}
        onClose={onCloseDrawerAndCleanup}
        closable={false}
        title={<h2>{t("title")}</h2>}
        extra={
          <IconButton
            variant="label-grey"
            size={9}
            onClick={onCloseDrawerAndCleanup}
          >
            <CloseLine />
          </IconButton>
        }
        footer={
          <div className="flex flex-col gap-3">
            <Button
              className="w-full"
              size="large"
              disabled={!domainAddress || !patterns.DOMAIN.test(domainAddress)}
              pending={pending}
              onClick={() => {
                addNewDomain(true);
              }}
            >
              {t("button.addAndVerify")}
            </Button>
            <Button
              variant="secondary"
              className="w-full"
              size="large"
              disabled={!domainAddress || !patterns.DOMAIN.test(domainAddress)}
              pending={pending}
              onClick={() => {
                addNewDomain(false);
              }}
            >
              {t("button.justAdd")}
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-6 p-6">
          <div className="flex flex-col gap-3">
            <InputField
              title={t("field.newDomain.label")}
              placeholder={t("field.newDomain.placeholder")}
              value={domainAddress}
              onChangeValue={(value) => {
                setDomainAddress(value);
              }}
              error={errorObj.address}
              onChangeError={(value) => {
                setErrorObj((prev) => ({ ...prev, address: value }));
              }}
              validation={{
                pattern: {
                  value: patterns.DOMAIN,
                  errorMessage: () => t("field.newDomain.error.invalid"),
                },
              }}
            />
            {errorObj.address ? null : (
              <div className={`flex gap-1 items-center text-hard-grey`}>
                <ErrorWarningLine className="w-5 h-5 max-w-[1.25rem]" />
                <p className="font-medium-14-forced">
                  {t("field.newDomain.error.onlyRootDomain")}
                </p>
              </div>
            )}
          </div>
        </div>
      </Drawer>

      <VerifyOwnershipDrawer
        open={openVerifyDrawer}
        onClose={() => {
          setOpenVerifyDrawer(false);
          onCloseDrawerAndCleanup();
        }}
        workspaceId={workspaceId}
        onRefresh={() => {}}
        assetName={domainAddress}
        assetId={domainId}
      />
    </>
  );
};

export default AddDomainDrawer;
